import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import moment from "moment";
import { StyledMainContent } from "../../../components/shared/styled";
import { formatterUSD } from "../../helpers/format-usd";
import { centToUsd } from "../../helpers/cent-to-usd";
import {
  DashboardSummarizeType as SummarizeType,
  TReportWallet as ReportWalletType,
  DashboardReportSummarized as ReportSummarized,
  DashboardWalletReportTitleMap as WalletReportTitleMap,
  DashboardWalletReport as WalletReport,
  DashboardFinancialReport as FinancialReport,
} from "../../../components/pages/dashboard"
import { addLoading, removeLoading } from "../../utils/loading";
import {
  exportFinancialComparisonReport,
  exportFinancialDetailReport,
  exportFinancialSummaryReport,
  exportWalletComparisonReport,
  exportWalletDetailReport,
  exportWalletSummaryReport,
  getFinancialComparisonReport,
  getFinancialDetailReport,
  getFinancialSummaryReport,
  getWalletComparisonReport,
  getWalletDetailReport,
  getWalletSummaryReport,
} from "../../../services/dashboard-service";
import { BE_DATE_FORMAT } from "../../utils/format/date-time-format";
import {RouteChildrenProps} from "react-router";
import { TDashboardTypeDetail, TReportFinancial, TReportWallet } from "../../../components/pages/dashboard/DashboardType";
import { TStringKey } from "../../../components/pages/dashboard/DashboardModel";
import { AxiosResponse } from "axios";
import CommissionReport from "../../../components/pages/dashboard/admin-commission/CommissionReport";
import { exportExcel } from "../../helpers/export-file/export-file";
import CommissionPayoutReport from "../../../components/pages/dashboard/commission-payout/CommissionPayoutReport";

const StyledReportNavBar = styled.div`
  width: fit-content;
  min-width: 210px;
  background: none;
  display: flex;
  justify-content: space-between;
`;

const StyledReportNavBarItem = styled.div`
  overflow: hidden;
  line-height: 16px;
  display: flex;
  justify-content: center;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 32px;

  :hover {
    background-color: #f7f7f8;
  }

  ${(props: { active: boolean }) =>
    props.active &&
    css`
      background-color: ${(props) => props.theme.infoColor};
      color: #fff;
      font-weight: bold;

      :hover {
        background-color: ${(props) => props.theme.infoColor};
        opacity: 0.8;
      }
    `}
`;

export const DashboardType: TStringKey<TDashboardTypeDetail> = {
  commission: {
    id: "commission",
    title: "Commission Report",
  },
  commissionPayout: {
    id: "commissionPayout",
    title: "Partner Transaction Report",
  },
  wallet: {
    id: "wallet",
    title: "Wallet Report",
  },
  financial: {
    id: "financial",
    title: "Financial Report",
  },
};

function Dashboard(props: RouteChildrenProps) {
  const [summarizedData, setSummarizedData] = useState<SummarizeType[]>([]);
  const [activeReportType, setActiveReportType] = useState<string>(
    Object.keys(DashboardType)[0]
  );

  useEffect(() => {
    fetchData().then();
  }, []);

  async function fetchData() {
    addLoading();
    const result = await getWalletSummaryReport(
      moment(new Date()).format(BE_DATE_FORMAT)
    );
    setSummarizedData(processSummarizedData(result.data));
    removeLoading();
  }

  function processSummarizedData(
    data: ReportWalletType
  ): SummarizeType[] {
    return Object.keys(data).map((key) => ({
      title: WalletReportTitleMap[key],
      value: WalletReportTitleMap[key].includes("Number")
        ? formatterUSD("decimal").format(Number(data[key]))
        : formatterUSD("currency", "USD").format(centToUsd(Number(data[key]))),
    }));
  }

  function handleChangeReportType(type: string) {
    setActiveReportType(type);
  }

  async function getWalletSummary(date: Date | string): Promise<TReportWallet<number>> {
    return await getWalletSummaryReport(moment(date).format(BE_DATE_FORMAT))
      .then(res => res.data);
  }


  async function getWalletComparison(fromDate: Date | string, toDate: Date | string): Promise<AxiosResponse> {
    return await getWalletComparisonReport(
      moment(fromDate).format(BE_DATE_FORMAT),
      moment(toDate).format(BE_DATE_FORMAT)
    );
  }

  async function getWalletDetail(fromDate: Date | string, toDate: Date | string): Promise<AxiosResponse> {
    return await getWalletDetailReport(
      moment(fromDate).format(BE_DATE_FORMAT),
      moment(toDate).format(BE_DATE_FORMAT)
    );
  }

  async function exportWalletSummary(date: Date | string) {
    const res = await exportWalletSummaryReport(moment(date).format(BE_DATE_FORMAT));
    if (res.status === 200) {
      exportExcel(res.data);
    }
  }

  async function exportWalletComparison(fromDate: Date | string, toDate: Date | string) {
    const res = await exportWalletComparisonReport(
      moment(fromDate).format(BE_DATE_FORMAT),
      moment(toDate).format(BE_DATE_FORMAT)
    );
    if (res.status === 200) {
      exportExcel(res.data);
    }
  }

  async function exportWalletDetail(fromDate: Date | string, toDate: Date | string) {
    const res = await exportWalletDetailReport(
      moment(fromDate).format(BE_DATE_FORMAT),
      moment(toDate).format(BE_DATE_FORMAT)
    );
    if (res.status === 200) {
      exportExcel(res.data);
    }
  }

  async function getFinancialSummary(date: Date | string): Promise<TReportFinancial<number>> {
    return await getFinancialSummaryReport(moment(date).format(BE_DATE_FORMAT)
    ).then(res => res.data);
  }

  async function getFinancialComparison(fromDate: Date | string, toDate: Date | string): Promise<AxiosResponse> {
    return await getFinancialComparisonReport(
      moment(fromDate).format(BE_DATE_FORMAT),
      moment(toDate).format(BE_DATE_FORMAT)
    );
  }

  async function getFinancialDetail(fromDate: Date | string, toDate: Date | string): Promise<AxiosResponse> {
    return await getFinancialDetailReport(
      moment(fromDate).format(BE_DATE_FORMAT),
      moment(toDate).format(BE_DATE_FORMAT)
    );
  }

  async function exportFinancialSummary(date: Date | string) {
    let res = await exportFinancialSummaryReport(moment(date).format(BE_DATE_FORMAT));
    if (res.status === 200) {
      exportExcel(res.data);
    }
  }

  async function exportFinancialComparison(fromDate: Date | string, toDate: Date | string) {
    const res = await exportFinancialComparisonReport(
      moment(fromDate).format(BE_DATE_FORMAT),
      moment(toDate).format(BE_DATE_FORMAT)
    );
    if (res.status === 200) {
      exportExcel(res.data);
    }
  }

  async function exportFinancialDetail(fromDate: Date | string, toDate: Date | string) {
    const res = await exportFinancialDetailReport(
      moment(fromDate).format(BE_DATE_FORMAT),
      moment(toDate).format(BE_DATE_FORMAT)
    );
    if (res.status === 200) {
      exportExcel(res.data);
    }
  }

  return (
    <StyledMainContent>
      <ReportSummarized summarizedList={summarizedData} />
      <div className="pt-5 mb-5">
        <DashboardNav
          activeReportType={activeReportType}
          handleChangeReportType={handleChangeReportType}
        />
      </div>
      {activeReportType === DashboardType.commission.id &&
        <CommissionReport />
      }
      {activeReportType === DashboardType.commissionPayout.id &&
        <CommissionPayoutReport />
      }
      {activeReportType === DashboardType.wallet.id &&
        <WalletReport
          getWalletSummaryReport={getWalletSummary}
          getWalletComparisonReport={getWalletComparison}
          getWalletDetailReport={getWalletDetail}
          exportWalletSummaryReport={exportWalletSummary}
          exportWalletComparisonReport={exportWalletComparison}
          exportWalletDetailReport={exportWalletDetail}
        />}
      {activeReportType === DashboardType.financial.id &&
        <FinancialReport
          getFinancialSummaryReport={getFinancialSummary}
          getWalletSummaryReport={getWalletSummary}
          getFinancialComparisonReport={getFinancialComparison}
          getWalletComparisonReport={getWalletComparison}
          getFinancialDetailReport={getFinancialDetail}
          getWalletDetailReport={getWalletDetail}
          exportFinancialSummaryReport={exportFinancialSummary}
          exportFinancialComparisonReport={exportFinancialComparison}
          exportFinancialDetailReport={exportFinancialDetail}
        />
      }
    </StyledMainContent>
  );
}

function DashboardNav(props: {
  activeReportType: string;
  handleChangeReportType: (type: string) => void;
}) {
  return (
    <StyledReportNavBar>
      {Object.keys(DashboardType).map((type: string) => (
        <div key={`report-nav-bar-item-${type}`}>
          <StyledReportNavBarItem
            active={type === props.activeReportType}
            onClick={() => props.handleChangeReportType(type)}
          >
            {DashboardType[type].title}
          </StyledReportNavBarItem>
        </div>
      ))}
    </StyledReportNavBar>
  );
}

export default Dashboard;

import React, {useEffect, useState} from "react";
import {StyledBigTitle, StyledButtonSubmit, StyledMainContent} from "../../../shared/styled";
import { RouteComponentProps } from "react-router-dom";
import {DetailInformation} from "../../../shared/detail-information/DetailInformation";
import {DetailInformationDataModel} from "../../partner-management/partner-detail/partner-detail-model";
import {BankingInformation} from "../../../shared/banking-information/BankingInformation";
import UpdateBankingInformationModal from "../../../shared/banking-information/BankingInformationModal";
import {PartnerAdminManagement} from "../../partner-management/partner-detail/admin-management/PartnerAdminManagement";
import {
  CommissionRateManagement
} from "../../partner-management/partner-detail/commission-rate/CommissionRateManagement";
import {
  PartnerAdminManagementModal
} from "../../partner-management/partner-detail/admin-management/PartnerAdminManagementModal";
import {BankingInfo} from "../../../shared/banking-information/BankingInformationType";
import { Provinces } from "../../company-management/company-management-type";
import {PagingInfo, setPagingInfo} from "../../../../cores/helpers/pagination/pagination";
import {
  getPartnerBankingInformation,
  getPartnerCommissionRates,
  getPartnerDetailByAdmin,
  getPartnerPortalRoles,
  getPartnerUsers
} from "../../../../services/partner-service";
import {
  defaultPartnerAdmin
} from "../../partner-management/partner-detail/admin-management/PartnerAdminManagementModel";
import {getInfoByToken, isAdminUser} from "../../../../cores/utils/helpers";
import EditPartnerModal from "../../partner-management/partner-modal/edit-partner-modal";
import {
  PartnerDetail,
  PartnerInformation, PartnerLocation,
  PartnerRole,
  PartnerUserData,
  SearchPartnerUserParam
} from "../../partner-management/partner-management-type";
import {addLoading, removeLoading} from "../../../../cores/utils/loading";
import {getProvinces} from "../../../../services/company-service";

function PartnerProfile(props: RouteComponentProps) {
    const partnerId = getInfoByToken().mainPartnerId; 
    const [partnerDetail, setPartnerDetail] = useState<PartnerDetail>({
      type: 'INDIVIDUAL',
      contactFirstName: '',
      contactLastName: '',
      contactEmail: '',
      socialInsuranceNumber: null,
      companyEmail: null,
      companyName: null,
      businessNumber: null,
      taxNumber: null,
      isLicensed: false,
      partnerLocation: {
        billingStreetAddress: '',
        billingProvinceId: null,
        billingPostcode: '',
        billingCityName: '',
        billingPhoneNumber: '',
        mailingStreetAddress: null,
        mailingProvinceId: null,
        mailingPostcode: null,
        mailingCityName: null,
        mailingPhoneNumber: null,
        isMailingSameAsBillingAddress: true
      },
      partnerLicenseAgentInformation: null,
    });
  const [bankingInfo, setBankingInfo] = useState<BankingInfo>({
    voidChequeUrl: "",
    eSignature: "",
    transitNumber: "",
    bankNumber: "",
    accountNumber: "",
    eSignatureDate: ""
  });
  const [partnerAdminList, setPartnerAdminList] = useState<PartnerUserData[]>([]);
  const [partnerAdminEdit, setPartnerAdminEdit] = useState<any>({});
  const [openEditPartnerAdminModal, setOpenEditPartnerAdminModal] = useState<boolean>(false);
  const [commissionRateList, setCommissionRateList] = useState<any[]>([
    {wallet: "HSA", beniplusSplit: 0.6, partnerSplit: 0.4},
    {wallet: "WSA", beniplusSplit: 0.6, partnerSplit: 0.4},
    {wallet: "Personal Insurance", beniplusSplit: 0.6, partnerSplit: 0.4},
    {wallet: "RRSP", beniplusSplit: 0.6, partnerSplit: 0.4},
  ]);
  const [openAddCommissionRateModal, setOpenAddCommissionRateModal] = useState<boolean>(false);
  const [provinces, setProvinces] = useState<Provinces[]>([]);
  const [roles, setRoles] = useState<PartnerRole[]>([]);
  const [partnerUserPagination, setPartnerUserPagination] = useState<PagingInfo>(setPagingInfo(1, 0, 10));
  const [searchPartnerUserParam, setSearchPartnerUserParam] = useState<SearchPartnerUserParam>({
    page: 1,
    perPage: 10
  });
  const [openEditPartnerModal, setOpenEditPartnerModal] = useState<boolean>(false);
  const [openUpdateBankingInformationModal, setOpenUpdateBankingInformationModal] = useState<boolean>(false);


  const isPartnerAdmin = isAdminUser

  useEffect(() => {
    addLoading();
    init().then(() => {
      removeLoading();
    });
  }, []);

  const init = async () => {
    if (partnerId) {
      await loadData(partnerId);
    }
  }

  const loadData = async (id: number) => {

    const [provinceData] = await Promise.all([getProvinces(), loadRoles()]);
    const provinces: Provinces[] = provinceData.data;
    setProvinces(provinces);

    await loadPartnerDataDetail(id, provinces);
    loadBankingInformation(id);
    loadPartnerCommissionRates(id);
  }

  const loadRoles = () => {
    return getPartnerPortalRoles()
      .then((res: any) => {
        setRoles(res.data);
      })
  }

  const loadBankingInformation = (id: number) => {
    // because this API can be return 404
    // and we accept that as the partner banking information has been not created
    // and dont want to show any error message when handleErrorAutomatic = true
    return getPartnerBankingInformation(id, false)
      .then(res => {
        if (res.status == 200) {
          setBankingInfo(res.data);
        }
    })
  }

  const loadPartnerAdminUser = (id: number, searchParam: SearchPartnerUserParam) => {
    return getPartnerUsers(id, searchParam)
      .then(res => {
        setPartnerAdminList(res.data.records);
        setPartnerUserPagination(setPagingInfo(
          searchParam.page,
          res.data.pageCount,
          searchParam.perPage
        ))
    })
  }

  const loadPartnerCommissionRates = (id: number) => {
    return getPartnerCommissionRates(id)
      .then(res => {
        setCommissionRateList(res.data.records);
      })
  }

  const loadPartnerDataDetail = async (id: number, provinces: Provinces[]) : Promise<PartnerDetail> => {
    return getPartnerDetailByAdmin(id)
      .then((res: any) => {
        let data: PartnerDetail = convertResponseDataToPartnerDetail(res.data);
        assignProvinceName(data, provinces);
        setPartnerDetail(data);
        return data;
      })
  }

  const assignProvinceName = (partnerData: PartnerDetail, provinces: Provinces[])=> {

    let billingProvince = provinces.find((f) => f.id == partnerData.partnerLocation.billingProvinceId);
    partnerData.partnerLocation.billingProvinceName = billingProvince ? billingProvince.name : "---";

    let mailingProvince = provinces.find((f) => f.id == partnerData.partnerLocation.mailingProvinceId);
    partnerData.partnerLocation.mailingProvinceName = mailingProvince ? mailingProvince.name : "---";

  }

  const convertResponseDataToPartnerDetail = (partnerDetailData: any): PartnerDetail => {

    const partnerInformation: PartnerInformation = partnerDetailData.partnerInformation;
    const partnerLocation: PartnerLocation = partnerInformation.partnerLocation;

    let partnerDetail: PartnerDetail = {
      id: partnerId,
      createdDate: partnerDetailData.createdDate,
      type: partnerInformation.type,
      contactFirstName: partnerInformation.contactFirstName,
      contactLastName: partnerInformation.contactLastName,
      contactEmail: partnerInformation.contactEmail,

      socialInsuranceNumber: partnerInformation.socialInsuranceNumber,

      companyEmail: partnerInformation.companyEmail,
      companyName: partnerInformation.companyName,
      businessNumber: partnerInformation.businessNumber,
      taxNumber: partnerInformation.taxNumber,

      isLicensed: partnerDetailData.isLicensed,
      partnerLocation: partnerLocation,
      partnerLicenseAgentInformation: partnerDetailData.licenseAgentInformation
    }

    return partnerDetail;
  }

  const handleOpenPartnerAdminModal = (partnerUserId: number) => {

    let partnerAdmin = partnerAdminList.find((item) => item.id === partnerUserId);
    if (partnerAdmin) {
      partnerAdmin.roleId = partnerAdmin.partnerRole.id;
    }

    setPartnerAdminEdit(partnerAdmin || {...defaultPartnerAdmin});
    setOpenEditPartnerAdminModal(true);
  }

  const handleClosePartnerAdminEditModal = () => {
    setOpenEditPartnerAdminModal(false);
  }

  const handleModifyPartnerUserSuccess = () => {
    addLoading();
    loadPartnerAdminUser(partnerId, searchPartnerUserParam);
    removeLoading();
  }

  const showEditPartnerModel = () => {
    setOpenEditPartnerModal(true);
  }

  const hideEditPartnerModal = () => {
    setOpenEditPartnerModal(false);
  }

  const showUpdateBankingInformationModal = () => {
    setOpenUpdateBankingInformationModal(true);
  }

  const hideUpdateBankingInformationModal = () => {
    setOpenUpdateBankingInformationModal(false);
  }

  const handleEditPartnerSuccess = () => {
    addLoading();
    loadPartnerDataDetail(partnerId, provinces);
    removeLoading();
  }

  const handleEditPartnerBankingSuccess = () => {
    addLoading();
    loadBankingInformation(partnerId);
    removeLoading();
  }

  const handleChangePartnerUserPage = (page: number) => {
    setSearchPartnerUserParam({...searchPartnerUserParam, page});
  }

  const handleChangePartnerUserPerPage = (perPage: any) => {
    const { value } = perPage.target;
    setSearchPartnerUserParam({...searchPartnerUserParam, page: 1, perPage: value});
  }

  return (
    <>
      <StyledMainContent>
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-lg-3 mt-3">
              <StyledBigTitle>{partnerDetail.companyName || ""}</StyledBigTitle>
            </div>
            <div className = "col-lg-9" />
          </div>
          <div className="row mt-3">
            <div className="col-lg-6">
              <DetailInformation
                data={partnerDetail}
                model={DetailInformationDataModel}
                title="Partner Information"
                showEditModal={showEditPartnerModel}
              />
            </div>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-12">
                  <BankingInformation
                    bankingInfo={bankingInfo}
                    showUpdateBankingInformationModal={showUpdateBankingInformationModal}
                  />
                </div>
                <div className="col-12 mt-3">
                  <PartnerAdminManagement
                    data={partnerAdminList}
                    partnerId={partnerId}
                    handleOpenPartnerAdminModal={handleOpenPartnerAdminModal}
                    fetchData={loadPartnerAdminUser}
                    pagingInfo={partnerUserPagination}
                    searchParam={searchPartnerUserParam}
                    handleChangePage={handleChangePartnerUserPage}
                    handleChangePerPage={handleChangePartnerUserPerPage}
                  />
                </div>
                <div className="col-12 mt-3">
                  <CommissionRateManagement
                    data={commissionRateList}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledMainContent>
      <EditPartnerModal
        open={openEditPartnerModal}
        successCallBack={handleEditPartnerSuccess}
        editPartner={partnerDetail}
        provinces={provinces}
        partnerId={partnerId}
        handleClose={hideEditPartnerModal}
      />
      <UpdateBankingInformationModal
        partnerId={partnerId}
        bankingInfoEdit={bankingInfo}
        successCallBack={handleEditPartnerBankingSuccess}
        open={openUpdateBankingInformationModal}
        handleClose={hideUpdateBankingInformationModal}
      />
      <PartnerAdminManagementModal
        open={openEditPartnerAdminModal}
        title={"Add/Update Partner Admin"}
        handleClose={handleClosePartnerAdminEditModal}
        successCallBack={handleModifyPartnerUserSuccess}
        model={partnerAdminEdit}
        roles={roles}
        partnerId={partnerId}
      />
    </>
  );
}

export default PartnerProfile;

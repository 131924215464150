import React from "react";
import CompanyManagementComponent from "./company-management-component"
import {RouteChildrenProps} from "react-router";
import {exportEmployersByPartnerId, getEmployersByPartnerId} from "../../../services/partner-service";
import {TableSortType} from "../../shared/BNPTable/BNPTableType";
import {getInfoByToken} from "../../../cores/utils/helpers";

type Props = RouteChildrenProps;
type State = {}

export default class PartnerCompanyManagement extends React.Component<Props, State> {

    partnerId = getInfoByToken().mainPartnerId;

    companyOptions = {
        allowSpecifyReferralPartner: false
    }

    render() {
        return (
            <CompanyManagementComponent
                defaultColumns={columnsHeaderTable}
                fetchData={(param) => getEmployersByPartnerId(this.partnerId, param)}
                exportExcel={(param) => exportEmployersByPartnerId(this.partnerId, param)}
                {...this.props}
                options={this.companyOptions}
            />
        );
    }
}

const columnsHeaderTable: TableSortType[] = [
    {
        columnId: "id",
        columnName: "ID",
        sortColumn: true,
        sortType: null,
        width: 10,
    },
    {
        columnId: "companyName",
        columnName: "Company name",
        sortColumn: true,
        sortType: null,
        width: 20,
    },
    {
        columnId: "companyEmail",
        columnName: "Email",
        sortColumn: true,
        sortType: null,
        width: 20,
    },
    {
        columnId: "noOfEmployees",
        columnName: "No of employees",
        sortColumn: true,
        sortType: null,
        width: 15,
    },
    {
        columnId: "joinedDate",
        columnName: "Joined Date",
        sortColumn: true,
        sortType: null,
        width: 15,
        type: 'DATE'
    },
    {
        columnId: "referralPartnerUserName",
        columnName: "Partner Team Member",
        sortColumn: true,
        sortType: null,
        width: 15,
    },
    {
        columnId: "status",
        columnName: "Status",
        sortColumn: true,
        sortType: null,
        width: 10,
    },
];

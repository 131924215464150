import React, {useMemo} from "react";

import styled from "styled-components";
import {Class} from "./employee-model";
import Pagination, {PagingInfo} from "../../../../../cores/helpers/pagination/pagination";
import {Employee} from "../../company-management-type";
import {
  companySearchParamDetailEmployeeDefault,
} from "../../../../../cores/constants/url-config";
import {findEmployeeStatusNameById} from "../../../../../cores/utils/helpers";
import EmployeeAction, {employeeActionEnum} from "../../../employees-management/employee-action";
import {
  closeConfirmModal,
  ConfirmEmployeeActionDialog,
  showConfirmModal
} from "../../../employees-management/confirm-employee-action-dialog";
import {TerminatingRequest} from "../../../../../../../src/components/pages/employer/employees/employee-type";
import {
  banEmployeeByAdmin,
  putSuspendEmployee,
  putUnSuspendEmployee,
  reActivateEmployee,
  terminatingEmployees
} from "../../../../../services/company-service";
import {publishMessage} from "../../../../../cores/utils/message";
import {capFirst} from "../../../../../cores/helpers/cap-first";
import BNPRawTable from "../../../../shared/BNPTable/BNPRawTable";
import {TableSortType} from "../../../../shared/BNPTable/BNPTableType";
import {StyledSpanStatus} from "../../../../shared/styled";
import {upperCase} from "lodash";
import { addLoading, removeLoading } from "../../../../../cores/utils/loading";
import {getStaticDateFromServerDate} from "../../../../../cores/utils/format/date-time-format";

const StyledSpanErrorColor = styled.span`
  font-weight: bold;
  color: ${props => props.theme.errorColor};
`;

const StyledSpanBold = styled.span`
  font-weight: bold;
`;

type Props = {
  employees: Employee[];
  getEmployees: () => void;
  pagingInfo: PagingInfo;
  columns: TableSortType[];
  changePage: (page: number) => void;
  sortTable: (columnId: string) => void;
  setRowsPerPage: (event: any) => void;
  classes: Class[];
  actionsHidden?: boolean;
  detailLink: string;
  disableItemClick?: boolean;
};
export default function EmployeeTable(props: Props) {
  const [id, setId] = React.useState<number>(0);
  const [name, setName] = React.useState<string>("");
  const [confirmingEmployee, setConfirmingEmployee] = React.useState<Employee | null>(null);
  const [confirmNumber, setConfirmNumber] = React.useState<employeeActionEnum>(employeeActionEnum.cancel);

  const showModalConfirm = (employee: Employee, confirm: employeeActionEnum) => {
    setId(employee.id);
    setName(`${employee.firstName} ${employee.lastName}`);
    setConfirmingEmployee(employee);
    setConfirmNumber(confirm);
    showConfirmModal();
  };

  const handleSubmitTerminatedEmployee = (model: TerminatingRequest) => {
    terminatingEmployees(model).then(() => {
      props.getEmployees();

      publishMessage({
        variant: "success",
        message: "Employee deactivation successfully.",
      });
    });
  };

  const handleReActivateEmployee = (id: number) => {
    addLoading();
    reActivateEmployee(id).then(() => {
      publishMessage({
        variant: "success",
        message: "Re-activate employee successfully.",
      });
    })
    .finally(() => {
      removeLoading();
      props.getEmployees();
    });
  }

  const handleSuspendEmployee = (id: number, fromDate: Date | string, toDate: Date | string) => {
    putSuspendEmployee(id, fromDate, toDate).then(() => {
      props.getEmployees();

      publishMessage({
        variant: "success",
        message: "Suspend employee successfully.",
      });
    });
  };
  const handleUnSuspendEmployee = (id: number) => {
    putUnSuspendEmployee(id).then(() => {
      props.getEmployees();

      publishMessage({
        variant: "success",
        message: "Un-suspend employee successfully.",
      });
    });
  };


  // delete employee
  const deleteEmployee = (employeeId: number) => {
    banEmployeeByAdmin(employeeId).then(() => {
      props.getEmployees();
    });
  };

  const handleDelete = () => {
    deleteEmployee(id);
    closeConfirmModal();
  };
  const handleSuspend = async (fromDate: Date, toDate: Date) => {
    await handleSuspendEmployee(id, fromDate, toDate);
    closeConfirmModal();
  };
  const handleUnSuspend = async () => {
    await handleUnSuspendEmployee(id);
    closeConfirmModal();
  };

  const handleCancelEmployee = async (fromDate: Date) => {
    await handleSubmitTerminatedEmployee({id, terminatedDate: fromDate, isTerminatedNow: false})
    closeConfirmModal();
  };

  const handleCancelEmployeeNow = async () => {
    await handleSubmitTerminatedEmployee({id,terminatedDate: new Date(), isTerminatedNow: true})
    closeConfirmModal();
  };

  const handleReActivate = async () => {
    await handleReActivateEmployee(id);
    closeConfirmModal();
  }

  const confirmModalData: {[key in employeeActionEnum]: {title: string; content: JSX.Element; secondButtonName?: string; action: any; secondAction?: any;}} = {
    [employeeActionEnum.cancel]: {
      title: "Benefit Cancellation Date",
      content: <span>You are about to <StyledSpanErrorColor>cancel the benefits </StyledSpanErrorColor>
        of {name}. Please fill in the <StyledSpanBold>Benefit Cancellation Date </StyledSpanBold>
        to specify the date you prefer.</span>,
      action: handleCancelEmployee,
      secondButtonName: "Deactivate Account Immediately",
      secondAction: () => {
        closeConfirmModal();
        confirmingEmployee && showModalConfirm(confirmingEmployee, employeeActionEnum.cancelNow)
      }
    },
    [employeeActionEnum.cancelNow]: {
      title: "Deactivate Confirmation",
      content: <span>The account of this employee will be deactivated immediately and the action cannot be reversed. Do you want to proceed?</span>,
      action: handleCancelEmployeeNow,
    },
    [employeeActionEnum.suspend]: {
      title: "Suspend",
      content: <span>You are about to <StyledSpanErrorColor>suspend</StyledSpanErrorColor> {name}. Please fill in the
                <StyledSpanBold> Start Date</StyledSpanBold> and <StyledSpanBold>End Date </StyledSpanBold>
                to specify the Suspension Period.</span>,
      action: handleSuspend,
    },
    [employeeActionEnum.unSuspend]: {
      title: "Un-suspend",
      content: <span>You are about to <StyledSpanErrorColor>un-suspend</StyledSpanErrorColor> {name}. Do you want to proceed?</span>,
      action: handleUnSuspend,
    },
    [employeeActionEnum.archive]: {
      title: "Archive",
      content: <span>You are about to <StyledSpanErrorColor>archive</StyledSpanErrorColor> {name}. Do you want to proceed?</span>,
      action: handleDelete,
    },
    [employeeActionEnum.reActivate]: {
      title: "Re-Activate",
      content: <span>You are about to <StyledSpanErrorColor>re-activate</StyledSpanErrorColor> {name}. Do you want to proceed?</span>,
      action: handleReActivate,
    },
  }

  const tableData = useMemo(() => {
    return props.employees.map((employee, index) => {
      const status = findEmployeeStatusNameById(employee.status, employee.t4Employee);
      return {
        id: employee.id,
        code: employee.code || "",
        fullName: employee.firstName + " " + employee.lastName || "",
        role: employee.role ? capFirst(employee.role[0]) : "",
        className: employee.className || "",
        eligibilityDate: employee.eligibilityDate ? getStaticDateFromServerDate(employee.eligibilityDate) : "",
        t4Employee: employee.t4Employee ? "TRUE" : "FALSE",
        status: <StyledSpanStatus title={upperCase(status)}>
          {status}
        </StyledSpanStatus>,
        actions: (
          <EmployeeAction
            employee={employee}
            showModalConfirm={showModalConfirm}
          />
        ),
        linkTo: !props.disableItemClick ?
          props.detailLink + companySearchParamDetailEmployeeDefault(employee.id) :
          undefined,
      }
    });
  }, [props.employees, props.classes]);

  return (
    <>
        <BNPRawTable
          columns={props.columns}
          tableWidth="100%"
          tableData={tableData}
          handleChangeSortColumn={props.sortTable}
          tableName={"Employee"}
        />
        <div className="row mt-4">
          <div className="col-12 text-center">
            <label>
              <Pagination
                pagerPagination={props.pagingInfo}
                getPage={props.changePage}
                onChangeRowsPerPage={props.setRowsPerPage}
              />
            </label>
          </div>
        </div>
      <ConfirmEmployeeActionDialog
        employee={confirmingEmployee}
        type={confirmNumber}
        title={confirmModalData[confirmNumber].title}
        content={confirmModalData[confirmNumber].content}
        handleOnOk={confirmModalData[confirmNumber].action}
        secondButtonName={confirmModalData[confirmNumber].secondButtonName}
        handleSecondAction={confirmModalData[confirmNumber].secondAction}
        searchEmployees={props.getEmployees}
      />
    </>
  );
}

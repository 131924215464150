import React, {useCallback, useEffect, useState} from "react";
import {
  StyledMainContent,
  StyledBigTitle,
  StyledContentBox,
  StyledHideForDesktop,
  StyledExpansionPanelSummary,
  StyledTitleExpansion,
  StyledExpansionPanelDetails,
  StyledHideForMobile,
  StyledSpanExpansionEdit,
  StyledExpansionLabel,
  StyledChangeDetail,
  StyledButtonSubmit
} from "../../shared/styled";
import BNPDatePicker from "../../../cores/helpers/date-picker/date-picker";
import { BNPSelect } from "../../../cores/helpers/select/select";
import { BNPInput } from "../../../cores/helpers/input/input";
import {
  StyledTable,
  StyledHeaderTable,
  StyledCellTable,
  StyledArrowDropDownIcon,
  StyledArrowDropUpIcon,
  StyledImportExportIcon,
  StyledRowBodyTable,
} from "../../shared/table-styled";
import SearchIcon from "@material-ui/icons/Search";
import styled from "styled-components";
import { ExpansionPanel } from "@material-ui/core";
import Pagination, {
  PagingInfo,
} from "../../../cores/helpers/pagination/pagination";
import { SortTable } from "../../../cores/models/SortTable";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import { partnerDetailURL } from "../../../cores/constants/url-config";
import { convertTimeZone } from "../../../cores/utils/helpers";
import { FE_DATE_FORMAT } from "../../../cores/utils/format/date-time-format";
import AddIcon from "@material-ui/icons/Add";
import DownloadIcon from '@material-ui/icons/GetApp';
import {DisablePartnerRequest, PartnerData, PartnerSearchParam, PartnerStatusOption} from "./partner-management-type";
import {Link} from "react-router-dom";
import DisablePartnerConfirmationModal, {
  hideDisablePartnerConfirmationModal,
  showDisablePartnerConfirmationModal
} from "./partner-modal/disable-partner-confirmation";
import {closeConfirmDialog, openConfirmDialog} from "../../../cores/utils/confirmDialog";

export const StyledIconSearch = styled(SearchIcon)`
  position: absolute;
  top: 13px;
  right: 14px;
  opacity: 0.7;
`;
export const StyledDivExportExcel = styled.div`
  color: #007540;
`;
export const StyledBNPInput = styled(BNPInput)`
  padding-right: 35px;
`;
export const StyledDivBoxTable = styled.div`
  min-height: 300px;
`;

type Props = {
  searchParam: PartnerSearchParam;
  pagingInfo: PagingInfo;
  partnerStatusOptions: PartnerStatusOption[];
  partnerData: PartnerData[];
  columns: SortTable[];

  handleChangeCreatedDate: (date: Date | null, name: string) => void;
  handleChangeStatus: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyUp: (event: any) => void;
  handleChangePage: (page: number) => void;
  sortTable: (columnId: string) => void;
  setRowsPerPage: (event: any) => void;
  handleAddNewPartner: () => void;
  handleExportExcel: () => void;
  handleEnablePartner: (partnerId: number) => Promise<void>;
  handleDisablePartner: (partnerId: number, request: DisablePartnerRequest) => Promise<void>;
};

const StyledDisableButton = styled(StyledButtonSubmit)`
  background-color: transparent;
  border: none;
  height: auto;
  color: ${(props) => props.theme.primaryColor};
`;

const StyledLink = styled(Link)`
  height: 60px;
  padding: 15px 21px;
  border-radius: 3px;
  color: ${(props) => props.theme.infoColor};

  :hover {
    color: ${(props) => props.theme.infoColor};
    text-decoration: underline;
  }
  width: 100%;
`;

export default function PartnerManagementView(props: Props) {
  const {handleDisablePartner, handleEnablePartner} = props
  const [disablingPartner, showDisablingPartner] = useState<(PartnerData &{name: string})|null>(null);
  const [enablingPartner, showEnablingPartner] = useState<(PartnerData &{name: string})|null>(null);

  const onDisablePartnerClick = useCallback((partner: PartnerData) => {
    showDisablingPartner({
      ...partner,
      name: partner.partnerName
    });
  },[]);

  // const onEnablePartnerConfirmed = useCallback(async () => {
  //   await enablePartner(enablingPartner!.id);
  // }, [enablingPartner])

  useEffect(() => {
    if (enablingPartner != null) {
      openConfirmDialog({
        id: "enablePartner",
        open: true,
        title: "Enable Partner",
        content: `Are you sure you want to enable ${enablingPartner.partnerName}?`,
        onClose: (confirmed) => {
          if (confirmed) {
            handleEnablePartner(enablingPartner.id).then(() => {
              closeConfirmDialog()
            })
          } else {
            showEnablingPartner(null)
            closeConfirmDialog()
          }
        },
      })
    }
  }, [enablingPartner, handleEnablePartner]);

  useEffect(() => {
    if (disablingPartner != null) {
      showDisablePartnerConfirmationModal()
    } else {
      hideDisablePartnerConfirmationModal()
    }
  }, [disablingPartner]);

  const onDisablePartnerConfirmed = useCallback(async (request: DisablePartnerRequest) => {
    await handleDisablePartner(disablingPartner!.id, request);
    showDisablingPartner(null); // to close the modal
  }, [disablingPartner, handleDisablePartner])

  const onEnablePartnerClick = (partner: PartnerData) => {
    showEnablingPartner({
      ...partner,
      name: partner.partnerName
    });
  };

  return (
    <StyledMainContent>
      <StyledBigTitle>Partner Management</StyledBigTitle>
      <div className="container-fluid p-0 mt-3">
        <StyledContentBox>
          <div className="row">
            <div className="col-lg-12 mt-4">
              <div className="row">
                <div className="col-lg-2 mt-4">
                  <BNPSelect
                    options={props.partnerStatusOptions}
                    value={props.searchParam.status}
                    name="status"
                    placeholder="Status"
                    onChange={props.handleChangeStatus}
                    tabIndex={1}
                  />
                </div>
                <div className="col-lg-2 mt-4">
                  <BNPDatePicker
                    onChange={props.handleChangeCreatedDate}
                    value={props.searchParam.createdDate ?
                      convertTimeZone("Canada/Eastern", props.searchParam.createdDate).toDate()
                      :
                      ""
                    }
                    placeholder="Created date"
                    name="createdDate"
                    tabIndex={2}
                  />
                </div>
                <div className="col-lg-2 mt-4">
                  <StyledIconSearch/>
                  <StyledBNPInput
                    placeholder="Search name or email"
                    onChange={props.handleChangeInput}
                    value={props.searchParam.searchName || ""}
                    onKeyUp={props.onKeyUp}
                    tabIndex={3}
                  />
                </div>
                <div className="col-lg-2"></div>
                <div className="col-lg-2 mt-4">
                  <StyledButtonSubmit
                    submit={true}
                    onClick={props.handleExportExcel}
                  >
                    <DownloadIcon className="icon" style={{ marginRight: "5px" }} />
                    Export To Excel
                  </StyledButtonSubmit>
                </div>
                <div className="col-lg-2 mt-4">
                  <StyledButtonSubmit
                    submit={true}
                    onClick={props.handleAddNewPartner}
                  >
                    <AddIcon className="icon"/>
                    Add New Partner
                  </StyledButtonSubmit>
                </div>
              </div>
            </div>

            <div className="col-lg-12 mt-4">
              <StyledHideForMobile>
                <StyledTable id="partnerTable">
                  <StyledHeaderTable>
                    {props.columns.map((column, index: number) => (
                      <StyledCellTable
                        width={column.width}
                        key={index}
                        className="cur"
                        onClick={() => props.sortTable(column.columnId)}
                      >
                        {column.columnName}
                        {column.sortColumn &&
                            <span>
                            {column.sortType ? (
                              column.sortType === "ASC" ? (
                                <StyledArrowDropDownIcon/>
                              ) : (
                                <StyledArrowDropUpIcon/>
                              )
                            ) : (
                              <StyledImportExportIcon/>
                            )
                            }
                          </span>
                        }
                      </StyledCellTable>
                    ))}
                  </StyledHeaderTable>
                  <div>
                    {props.partnerData.map((partner, i) => (
                      <StyledRowBodyTable
                        key={i}
                      >
                        <StyledCellTable width={10}>
                          {partner.id}
                        </StyledCellTable>
                        <StyledCellTable width={20}>
                          <StyledLink
                            to={`${partnerDetailURL}?id=${partner.id}`}
                          >
                            {partner.partnerName}
                          </StyledLink>
                        </StyledCellTable>
                        <StyledCellTable width={25}>
                          {partner.email}
                        </StyledCellTable>
                        <StyledCellTable width={18}>
                          {partner.createdDate
                            ? convertTimeZone("Canada/Eastern", partner.createdDate).format(FE_DATE_FORMAT)
                            : ""}
                        </StyledCellTable>
                        <StyledCellTable width={12}>
                          {partner.status}
                        </StyledCellTable>
                        <StyledCellTable width={10}>
                          {partner.status === "ACTIVE" && (<StyledDisableButton
                            onClick={() => onDisablePartnerClick(partner)}
                            submit={true}>
                            Disable
                          </StyledDisableButton>)}
                          {partner.status === "INACTIVE" && (
                            <StyledDisableButton
                              onClick={() => onEnablePartnerClick(partner)}
                              submit={true}>
                              Enable
                            </StyledDisableButton>
                          )}
                        </StyledCellTable>
                      </StyledRowBodyTable>
                    ))}
                  </div>
                  <div className="row mt-4">
                    <div className="col-12 text-center">
                      <label>
                        <Pagination
                          pagerPagination={props.pagingInfo}
                          getPage={props.handleChangePage}
                          onChangeRowsPerPage={props.setRowsPerPage}
                        />
                      </label>
                    </div>
                  </div>
                </StyledTable>
              </StyledHideForMobile>
            </div>

            <div className="col-lg-12 mt-4">
              <StyledHideForDesktop>
                {props.partnerData.map((partner, index) => (
                  <ExpansionPanel
                    square
                    defaultExpanded={false}
                    className="mt-2"
                    key={index}
                  >
                    <StyledExpansionPanelSummary
                      className="m-0"
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                      expandIcon={<ArrowDropUp/>}
                    >
                      <div className="w-100 ">
                        <StyledTitleExpansion>Partner</StyledTitleExpansion>
                        <div className="mt-2">{partner.id}</div>
                      </div>
                    </StyledExpansionPanelSummary>
                    <StyledExpansionPanelDetails>
                      <div className="w-100">
                        <StyledTitleExpansion>
                          Partner name
                        </StyledTitleExpansion>
                        <div className="mt-1">{partner.partnerName}</div>
                      </div>
                      <div className="w-100 mt-3">
                        <StyledTitleExpansion>
                          Email
                        </StyledTitleExpansion>
                        <div className="mt-1">{partner.email}</div>
                      </div>
                      <div className="w-100 mt-3">
                        <StyledTitleExpansion>
                          Created Date
                        </StyledTitleExpansion>
                        <div className="mt-1">
                          {partner.createdDate
                            ? convertTimeZone("Canada/Eastern", partner.createdDate).format(FE_DATE_FORMAT)
                            : ""}
                        </div>
                      </div>
                      <div className="w-100 mt-3">
                        <StyledTitleExpansion>Status</StyledTitleExpansion>
                        <div className="mt-1">{partner.status}</div>
                      </div>
                      <div className="w-100 mt-3">
                        {partner.status === "ACTIVE" && (<StyledButtonSubmit
                            onClick={() => onDisablePartnerClick(partner)}
                            submit={true}>
                            Disable
                          </StyledButtonSubmit>
                        )}
                        {partner.status === "DISABLED" && (
                          <StyledButtonSubmit
                            onClick={() => onEnablePartnerClick(partner)}
                            submit={true}>
                            Enable
                          </StyledButtonSubmit>
                        )}
                      </div>
                      <hr/>
                      <div className="text-center">
                        <StyledSpanExpansionEdit>
                          <StyledExpansionLabel>
                            <StyledChangeDetail
                              to={`${partnerDetailURL}?id=${partner.id}`}
                            >
                              Detail
                            </StyledChangeDetail>
                          </StyledExpansionLabel>
                        </StyledSpanExpansionEdit>
                      </div>
                    </StyledExpansionPanelDetails>
                  </ExpansionPanel>
                ))}
                <div className="row mt-4">
                  <div className="col-12 text-center">
                    <label>
                      <Pagination
                        pagerPagination={props.pagingInfo}
                        getPage={props.handleChangePage}
                        onChangeRowsPerPage={props.setRowsPerPage}
                      />
                    </label>
                  </div>
                </div>
              </StyledHideForDesktop>
            </div>
          </div>
        </StyledContentBox>
      </div>
      <div className="modal" id="disablePartnerModal">
        {disablingPartner != null &&
            <DisablePartnerConfirmationModal
                partner={disablingPartner!}
                handleSubmit={onDisablePartnerConfirmed}
                closePartnerModal={() => showDisablingPartner(null)}
            />}
      </div>
    </StyledMainContent>
  );
}

import { StatusClaim, Relationship, RejectType, ClaimType, DateFilterType } from "./claim-management-type";
import {TableSortType} from "../../shared/BNPTable/BNPTableType";
import {TableSortDataType} from "../../shared/BNPTable/BNPTableModel";

export const statusClaim: StatusClaim[] = [
  {
    id: "REQUESTED",
    name: "Requested",
  },
  {
    id: "APPROVED",
    name: "Approved",
  },
  {
    id: "REJECTED",
    name: "Declined",
  },
];

export const dateFilterType: DateFilterType[] = [
  {
    id: "submittedDate",
    name: "Submitted Date",
  },
  {
    id: "reviewDate",
    name: "Review Date",
  },
];

export const claimType: ClaimType[] = [
  {
    id: "Healthcare Spending Account",
    name: "Healthcare Spending Account",
  },
  {
    id: "Wellness Spending Account",
    name: "Wellness Spending Account",
  },
  {
    id: "Personal Insurance(.eg life, disability)",
    name: "Personal Insurance",
  },
  {
    id: "RRSP",
    name: "RRSP",
  },
  {
    id: "Charitable Giving",
    name: "Charitable Giving",
  },
  {
    id: "Group Insurance",
    name: "Group Insurance",
  },
];

export const columnsClaims = (): TableSortType[] => {
  return [
    {
      columnId: "id",
      columnName: "ID",
      sortColumn: true,
      sortType: null,
      width: 12,
    },
    {
      columnId: "employeeName",
      columnName: "Claimer",
      sortColumn: true,
      sortType: null,
      width: 15,
    },
    {
      columnId: "companyName",
      columnName: "Company name",
      sortColumn: true,
      sortType: null,
      width: 15,
    },
    {
      columnId: "totalAmount",
      columnName: "Total amount",
      sortColumn: true,
      sortType: null,
      width: 12,
      type: TableSortDataType.CURRENCY
    },
    {
      columnId: "submittedDate",
      columnName: "Submitted Date",
      sortColumn: true,
      sortType: null,
      width: 12,
      type: TableSortDataType.DATE
    },
    {
      columnId: "reviewDate",
      columnName: "Review Date",
      sortColumn: true,
      sortType: null,
      width: 12,
      type: TableSortDataType.DATE
    },
    {
      columnId: "claimGroupType",
      columnName: "Type",
      sortColumn: true,
      sortType: null,
      width: 18,
    },
    {
      columnId: "claimType",
      columnName: "Spending Account",
      sortColumn: true,
      sortType: null,
      width: 26,
    },
    {
      columnId: "partnerName",
      columnName: "partner",
      sortColumn: true,
      sortType: null,
      width: 15,
    },
    {
      columnId: "claimStatus",
      columnName: "Status",
      sortColumn: true,
      sortType: null,
      width: 8,
    },
  ];
};

export const employeeDetailClaimColumns = (): TableSortType[] => {
  return [
    {
      columnId: "id",
      columnName: "ID",
      sortColumn: true,
      sortType: null,
      width: 5,
    },
    {
      columnId: "employeeName",
      columnName: "Claimer",
      sortColumn: true,
      sortType: null,
      width: 12,
    },
    {
      columnId: "companyName",
      columnName: "Company name",
      sortColumn: true,
      sortType: null,
      width: 12,
    },
    {
      columnId: "totalAmount",
      columnName: "Total amount",
      sortColumn: true,
      sortType: null,
      width: 10,
      type: TableSortDataType.CURRENCY
    },
    {
      columnId: "submittedDate",
      columnName: "Submitted Date",
      sortColumn: true,
      sortType: null,
      width: 10,
      type: TableSortDataType.DATE
    },
    {
      columnId: "reviewDate",
      columnName: "Review Date",
      sortColumn: true,
      sortType: null,
      width: 10,
      type: TableSortDataType.DATE
    },
    {
      columnId: "claimType",
      columnName: "Spending Account",
      sortColumn: true,
      sortType: null,
      width: 25,
    },
    {
      columnId: "partnerName",
      columnName: "partner",
      sortColumn: true,
      sortType: null,
      width: 12,
    },
    {
      columnId: "claimStatus",
      columnName: "Status",
      sortColumn: true,
      sortType: null,
      width: 8,
    },
  ];
};


export const defaultSearchParam = () => {
  return {
    page: 1,
    from: null,
    to: null,
    status: null,
    searchName: null,
    columnName: null,
    sortType: null,
  };
};

export const relationships: Relationship[] = [
  { id: "WIFE", name: "Wife" },
  { id: "HUSBAND", name: "Husband" },
  { id: "PARTNER", name: "Partner" },
  { id: "CHILDREN", name: "Child" },
  { id: "OTHER", name: "Other" },
];

export const rejectOption: RejectType[] = [
  {
    id: "IMAGE_TOO_BLURRY",
    name: "Re-submit Image",
  },
  {
    id: "OVER_BUDGET",
    name: "Category Limit Reached",
  },
  {
    id: "INELIGIBLE_DATE",
    name: "Outside of Eligibility Date",
  },
  {
    id: "INELIGIBLE_EXPENSE",
    name: "Ineligible Expenses",
  },
  {
    id: "OTHER",
    name: "Other (Please include comments box)",
  },
];

import React, {useEffect, useMemo, useState} from 'react';
import styled from "styled-components";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import {StyledContentBox, StyledLargerTitle, StyledSpanStatus} from "../../../../shared/styled";
import BNPRawTable from "../../../../shared/BNPTable/BNPRawTable";
import {capFirst} from "../../../../../cores/helpers/cap-first";
import {PartnerAdminManagementColumns} from "./PartnerAdminManagementModel";
import { PartnerUserData, SearchPartnerUserParam } from '../../partner-management-type';
import Pagination, { PagingInfo } from '../../../../../cores/helpers/pagination/pagination';
import { putPartnerUserStatus } from '../../../../../services/partner-service';
import { publishMessage } from '../../../../../cores/utils/message';
import { closeConfirmDialog, openConfirmDialog } from '../../../../../cores/utils/confirmDialog';

export const StyledSpanEditInfo = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  float: right;
  cursor: pointer;
  font-family: ${(props) => props.theme.textFont};
  :hover {
    opacity: 0.7;
  }
  position: relative;
`;

type Props = {
  data: PartnerUserData[];
  partnerId: number;
  handleOpenPartnerAdminModal: (data: any) => void;
  fetchData: (id: number, searchParam: SearchPartnerUserParam) => void;
  pagingInfo: PagingInfo;
  searchParam: SearchPartnerUserParam;
  handleChangePage: (page: number) => void;
  handleChangePerPage: (perPage: number) => void;
};

export const PartnerAdminManagement = (props: Props) => {

  useEffect(() => {
    props.fetchData(props.partnerId, props.searchParam);
  }, [props.searchParam.page, props.searchParam.perPage]);

  const handleChangePartnerUserStatus = (partnerUserId: number, status: "ACTIVE" | "INACTIVE") => {
    openConfirmDialog({
      id: "change-partner-user-status",
      open: true,
      title: `${status === "ACTIVE" ? "Enable" : "Disable"} Partner User`,
      content: `Are you sure want to ${status === "ACTIVE" ? "enable" : "disable"} this partner user?`,
      onClose: (confirmed) => {
        if (confirmed) {
          handleUpdatePartnerUserStatus(partnerUserId, status);
        }
        closeConfirmDialog();
      }
    });
  }

  const handleUpdatePartnerUserStatus = (partnerUserId: number, status: "ACTIVE" | "INACTIVE") => {
    putPartnerUserStatus({ status }, props.partnerId, partnerUserId)
      .then(res => {
        if (res.status == 200) {
          publishMessage({
            message: "Update partner user status successfully.",
            variant: "success",
          });
          props.fetchData(props.partnerId, props.searchParam);
     }
   }) 
  }


  const tableData = useMemo(() => props.data.map(item => ({
    ...item,
    name: `${item.firstName} ${item.lastName}`,
    status:
      <StyledSpanStatus title={item.status.toString().toUpperCase()}>
        {capFirst(item.status)}
      </StyledSpanStatus>,
    role: `${item.partnerRole.name}`,
    actions:
      <div>
        {item.status === "ACTIVE" &&
          <>
            <StyledSpanEditInfo onClick={() => handleChangePartnerUserStatus(item.id, "INACTIVE")}>
              <DeleteIcon fontSize="small" color={"secondary"} />
            </StyledSpanEditInfo>
            <StyledSpanEditInfo onClick={() => props.handleOpenPartnerAdminModal(item.id)}>
              <CreateIcon fontSize="small" />
            </StyledSpanEditInfo>
          </>
        }
        {item.status === "INACTIVE" &&
          <>
            <StyledSpanEditInfo onClick={() => handleChangePartnerUserStatus(item.id, "ACTIVE")}>
              Enable
            </StyledSpanEditInfo>
          </>
        }
      </div>
  })), [props.data]);

  return (
    <>
      <StyledContentBox>
        <StyledLargerTitle>
          Partner Admin Management
          <StyledSpanEditInfo onClick={() => props.handleOpenPartnerAdminModal(-1)}>
            <CreateIcon fontSize="small" /> Add
          </StyledSpanEditInfo>
        </StyledLargerTitle>
        <BNPRawTable
          columns={PartnerAdminManagementColumns}
          tableWidth="100%"
          tableData={tableData}
        />
        <div className="row mt-4">
        <div className="col-12 text-center">
          <label>
            <Pagination
              pagerPagination={props.pagingInfo}
              getPage={props.handleChangePage}
              onChangeRowsPerPage={props.handleChangePerPage}
            />
          </label>
        </div>
      </div>
      </StyledContentBox>
    </>
  );
};


import React from "react";
import styled from "styled-components";
import {
  StyledHeaderModal,
  StyledHeaderTitleModal,
  StyledButtonCloseModal,
  StyledIconCloseModal,
  StyledInputMask,
  StyledButtonSubmit,
  StyledPTitle,
  StyledDivTitle,
} from "../../../shared/styled";
import { BNPInput } from "../../../../cores/helpers/input/input";
import { BNPSelect, Option } from "../../../../cores/helpers/select/select";
import { CheckBox } from "../../../../cores/helpers/checkbox/checkbox";
import { Provinces, CompanyDataEdit } from "../company-management-type";
import GoogleLocationInput, {
  ResponseLocation,
} from "../../../../cores/helpers/google-map-services/google-location";
import { BNPAutoComplete } from "../../../../cores/helpers/input/BNPAutoComplete";
import {RadioGroup} from "@material-ui/core";

type Props = {
  provinces: Provinces[];
  companyInformation: CompanyDataEdit;
  isValid: boolean;
  isAdditionalStreet: boolean;
  handleSubmit: () => void;
  handleChangInput: (event: any) => void;
  handleChangSelect: (event: any) => void;
  handleAdditionalStreet: (event: any) => void;
  //
  setAddress: (address: string) => void;
  setLocation: (location: ResponseLocation) => void;
  setAddress2: (address: string) => void;
  setLocation2: (location: ResponseLocation) => void;

  referralPartnerUsers: Option[];
  referralPartners: Option[];
  handleChangeReferralPartnerUser: (event: any, option: any) => void;
  handleChangeReferralPartner: (event: any, option: any) => void;
  allowEditReferralPartner: boolean;
  allowEditReferralPartnerUser: boolean;
};

const StyledModalDialog = styled.div`
  max-width: 800px;
  margin: 1.75rem auto;
  position: relative;
  width: auto;
  pointer-events: none;
`;

const StyledDescription = styled(RadioGroup)`
  .MuiFormControlLabel-label {
    font-size: 14px;
  }
  .MuiFormControlLabel-root {
    margin-right: 0;
    margin-bottom: 0;
  }
`;

export default function CompanyInformationModal(props: Props) {
  return (
    <div className="modal" id="companyInformationModal" style={{ display: "block !important" }}>
      <StyledModalDialog role="document">
        <div className="modal-content">
          <StyledHeaderModal className="modal-header">
            <StyledHeaderTitleModal>Company Information</StyledHeaderTitleModal>
            <StyledButtonCloseModal
              type="button"
              onClick={closeCompanyInformationModal}
            >
              <StyledIconCloseModal>&times;</StyledIconCloseModal>
            </StyledButtonCloseModal>
          </StyledHeaderModal>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-4 mb-4">
                <StyledPTitle className="mb-2">Company name</StyledPTitle>
                <BNPInput
                  value={props.companyInformation.companyName || ""}
                  name="companyName"
                  onChange={props.handleChangInput}
                  placeholder="Company name"
                  tabIndex={1}
                />
              </div>

              <div className="col-md-4 mb-4">
                <StyledPTitle className="mb-2">No of employees</StyledPTitle>
                <BNPInput
                  value={props.companyInformation.noOfEmployees || ""}
                  name="noOfEmployees"
                  onChange={props.handleChangInput}
                  maxLength={9}
                  type="number"
                  placeholder="No of employees"
                  tabIndex={2}
                />
              </div>
              <div className="col-md-4 mb-4">
                <StyledPTitle className="mb-2">Sole Proprietor</StyledPTitle>
                <BNPSelect
                  options={[
                    { id: 1, name: "Yes" },
                    { id: 0, name: "No" },
                  ]}
                  value={props.companyInformation.soleProprietor ? 1 : 0}
                  name="soleProprietor"
                  onChange={props.handleChangSelect}
                  tabIndex={3}
                />
              </div>


              <div className="col-md-4 mb-4">
                <StyledPTitle className="mb-2">Company email</StyledPTitle>
                <BNPInput
                    value={props.companyInformation.companyEmail || ""}
                    name="companyEmail"
                    onChange={props.handleChangInput}
                    placeholder="Company email"
                    tabIndex={4}
                />
              </div>
              <div className="col-md-4 mb-4">
                <StyledPTitle className="mb-2">First Name</StyledPTitle>
                <BNPInput
                    value={props.companyInformation.firstName || ""}
                    name="firstName"
                    onChange={props.handleChangInput}
                    placeholder="First Name"
                    tabIndex={5}
                />
              </div>
              <div className="col-md-4 mb-4">
                <StyledPTitle className="mb-2">Last Name</StyledPTitle>
                <BNPInput
                    value={props.companyInformation.lastName || ""}
                    name="lastName"
                    onChange={props.handleChangInput}
                    placeholder="Last Name"
                    tabIndex={6}
                />
              </div>
              <div className="col-12 mb-4">
                <StyledPTitle className="mb-2">Fund Related Notification Emails *</StyledPTitle>
                <StyledDescription>(Non Sufficient Funds, Deposit Requested, Deposit Approved)</StyledDescription>
                <BNPInput
                    value={props.companyInformation.fundRelatedEmails || ""}
                    name="fundRelatedEmails"
                    onChange={props.handleChangInput}
                    placeholder="Multiple emails separated by semicolon"
                    tabIndex={7}
                />
              </div>
              <div className="col-12 mb-4">
                <StyledPTitle className="mb-2">Employee Claim Related Notification Emails *</StyledPTitle>
                <StyledDescription>(Claim Paid, Monthly Claim Report, Group Insurance Monthly Auto Top Up Amount)</StyledDescription>
                <BNPInput
                    value={props.companyInformation.employeeClaimRelatedEmails || ""}
                    name="employeeClaimRelatedEmails"
                    onChange={props.handleChangInput}
                    placeholder="Multiple emails separated by semicolon"
                    tabIndex={7}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="mt-3 mb-3 fw-bold">Billing address</div>
              </div>
              <div className="col-md-12 mb-4">
                <StyledPTitle className="mb-2">Street address</StyledPTitle>
                <GoogleLocationInput
                  id="streetAddress"
                  address={props.companyInformation.streetAddress || ""}
                  setAddress={props.setAddress}
                  setLocation={props.setLocation}
                  placeholder="Street address"
                  tabIndex={8}
                />
              </div>
              <div className="col-md-3 mb-4">
                <StyledPTitle className="mb-2">Province</StyledPTitle>
                <BNPSelect
                  options={props.provinces.map((province: Provinces) => ({
                    id: province.id,
                    name: province.name,
                  }))}
                  placeholder="Province"
                  name="provinceId"
                  value={props.companyInformation.provinceId || "-1"}
                  onChange={props.handleChangSelect}
                  tabIndex={9}
                />
              </div>
              <div className="col-md-3 mb-4">
                <StyledPTitle className="mb-2">City</StyledPTitle>
                <BNPInput
                  value={props.companyInformation.cityName || ""}
                  name="cityName"
                  onChange={props.handleChangInput}
                  placeholder="City"
                  tabIndex={10}
                />
              </div>
              <div className="col-md-3 mb-4">
                <StyledPTitle className="mb-2">Postcode</StyledPTitle>
                <StyledInputMask
                  mask="*** ***"
                  className="text-up"
                  value={props.companyInformation.postcode || ""}
                  onChange={props.handleChangInput}
                  name="postcode"
                  placeholder="Postcode"
                  tabIndex={11}
                />
              </div>
              <div className="col-md-3 mb-4">
                <StyledPTitle className="mb-2">Phone number</StyledPTitle>
                <StyledInputMask
                  mask="999-999-9999"
                  value={props.companyInformation.companyPhoneNumber || ""}
                  onChange={props.handleChangInput}
                  name="companyPhoneNumber"
                  placeholder="Phone number"
                  tabIndex={12}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="mt-3 mb-4 fw-bold">Mailing address</div>
              </div>
              <div className="col-md-12 mb-3">
                <CheckBox
                  title="Is the same as the billing address"
                  checked={props.isAdditionalStreet}
                  onChange={props.handleAdditionalStreet}
                  tabIndex={13}
                />
              </div>
            </div>

            {!props.isAdditionalStreet && (
              <div className="row">
                <div className="col-md-12 mb-4">
                  <StyledPTitle className="mb-2">Street address</StyledPTitle>

                  <GoogleLocationInput
                    id="streetAddress2"
                    address={props.companyInformation.streetAddress2 || ""}
                    setAddress={props.setAddress2}
                    setLocation={props.setLocation2}
                    placeholder="Street address"
                    tabIndex={14}
                  />
                </div>
                <div className="col-md-3 mb-4">
                  <StyledPTitle className="mb-2">Province</StyledPTitle>
                  <BNPSelect
                    options={props.provinces.map((province: Provinces) => ({
                      id: province.id,
                      name: province.name,
                    }))}
                    placeholder="Province"
                    name="provinceId2"
                    value={props.companyInformation.provinceId2 || "-1"}
                    onChange={props.handleChangSelect}
                    tabIndex={15}
                  />
                </div>
                <div className="col-md-3 mb-4">
                  <StyledPTitle className="mb-2">City</StyledPTitle>
                  <BNPInput
                    value={props.companyInformation.cityName2 || ""}
                    name="cityName2"
                    onChange={props.handleChangInput}
                    placeholder="City"
                    tabIndex={16}
                  />
                </div>
                <div className="col-md-3 mb-4">
                  <StyledPTitle className="mb-2">Postcode</StyledPTitle>
                  <StyledInputMask
                    mask="*** ***"
                    className="text-up"
                    value={props.companyInformation.postcode2 || ""}
                    onChange={props.handleChangInput}
                    name="postcode2"
                    placeholder="Postcode"
                    tabIndex={17}
                  />
                </div>
                <div className="col-md-3 mb-4">
                  <StyledPTitle className="mb-2">Phone number</StyledPTitle>
                  <StyledInputMask
                    mask="999-999-9999"
                    value={props.companyInformation.companyPhoneNumber2 || ""}
                    onChange={props.handleChangInput}
                    name="companyPhoneNumber2"
                    placeholder="Phone number"
                    tabIndex={18}
                  />
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md-12">
                <StyledDivTitle className="mt-3 mb-3">
                  Other information
                </StyledDivTitle>
              </div>
              <div className="col-md-6 mb-4">
                <StyledPTitle className="mb-2">Referral Partner</StyledPTitle>
                {props.allowEditReferralPartner ?
                  <BNPAutoComplete
                    name="referralPartnerId"
                    options={props.referralPartners}
                    optionSelectedId={props.companyInformation.referralPartnerId || -1}
                    disableClearable={true}
                    handleChange={props.handleChangeReferralPartner} /> :
                  <BNPInput value={props.companyInformation.referralPartnerName} disabled />
                }
              </div>
              <div className="col-md-6 mb-4">
                <StyledPTitle className="mb-2">Referral Partner Team Member</StyledPTitle>
                {props.allowEditReferralPartnerUser ?
                  <BNPAutoComplete
                    name="referralPartnerUserId"
                    options={props.referralPartnerUsers}
                    optionSelectedId={props.companyInformation.referralPartnerUserId || -1}
                    disableClearable={true}
                    handleChange={props.handleChangeReferralPartnerUser} /> :
                  <BNPInput value={(props.companyInformation.referralPartnerUserId && props.companyInformation.referralPartnerUserId != -1) ? props.companyInformation.referralPartnerUserName : props.companyInformation.referralPartnerName} disabled />
                }
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="col-md-4 p-0">
              <StyledButtonSubmit
                disabled={!props.isValid}
                onClick={props.handleSubmit}
                submit={props.isValid}
                type="submit"
                tabIndex={20}
              >
                Save & Update
              </StyledButtonSubmit>
            </div>
          </div>
        </div>
      </StyledModalDialog>
    </div>
  );
}

export function showCompanyInformationModal() {
  ($("#companyInformationModal") as any).modal({
    show: true,
    keyboard: false,
    backdrop: "static",
  });
}
export function closeCompanyInformationModal() {
  ($("#companyInformationModal") as any).modal("hide");
}

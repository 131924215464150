import React from "react";
import styled from "styled-components";
import SetupDependentsService from "./setup-dependents-service";
import {
  StyledButtonCloseModal,
  StyledHeaderModal,
  StyledHeaderTitleModal,
} from "../../../shared/styled";

const StyledModalDialog = styled.div`
  text-align: left;
  max-width: 600px;
  margin: 1.75rem auto;
  width: 100%;
  pointer-events: none;
`;

export default class DependentModal extends React.Component<any, any> {

  render() {
    return (
      <div className="modal" id="addDependentModal">
        <StyledModalDialog role="document">
          <div className="modal-content">
            {/* header */}
            <StyledHeaderModal className="modal-header">
              <StyledHeaderTitleModal>
                Dependents
              </StyledHeaderTitleModal>
              <StyledButtonCloseModal
                name="btn-close"
                onClick={this.props.handleCloseDependentModal}
              >
                <StyledButtonCloseModal>&times;</StyledButtonCloseModal>
              </StyledButtonCloseModal>
            </StyledHeaderModal>
            {/* end header */}
            {/* body */}
            <div className="modal-body">
              <SetupDependentsService closeDependentModal={this.props.handleCloseDependentModal} />
            </div>
            {/* end body */}
          </div>
        </StyledModalDialog>
      </div>
    );
  }
}

export function showDependentModal() {
  ($("#addDependentModal") as any).modal({
    show: true,
    keyboard: false,
    backdrop: "static",
  });
}
export function closeDependentModal() {
  ($("#addDependentModal") as any).modal("hide");
}

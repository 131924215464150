import React from "react";
import styled, { css } from "styled-components";
import {
  StyledHeaderModal,
  StyledHeaderTitleModal,
  StyledButtonCloseModal,
  StyledIconCloseModal,
  StyledButtonSubmit,
} from "../../shared/styled";
import BNPDatePicker from "../../../cores/helpers/date-picker/date-picker";
import { TaxSearchParam } from "./tax-type";
import { convertTimeZone } from "../../../cores/utils/helpers";
import {FE_DETAIL_DATE_FORMAT} from "../../../cores/utils/format/date-time-format";

const StyledModalDialog = styled.div`
  max-width: 600px;
  margin: 1.75rem auto;
  position: relative;
  width: auto;
  pointer-events: none;
`;

const StyledModalBody = styled.div`
  min-height: 150px;
`;

type Props = {
  searchParam: TaxSearchParam;
  handleChangeDate: (date: Date | null, name: string) => void;

  handleOK: () => void;
  handleClose: () => void;
};

export default function TaxModal(props: Props) {
  let isValid =
    props.searchParam.from !== null && props.searchParam.to !== null;

  return (
    <div className="modal" id="taxModal">
      <StyledModalDialog role="document">
        <div className="modal-content">
          <StyledHeaderModal className="modal-header">
            <StyledHeaderTitleModal>Chang range date</StyledHeaderTitleModal>
            <StyledButtonCloseModal type="button" onClick={props.handleClose}>
              <StyledIconCloseModal>&times;</StyledIconCloseModal>
            </StyledButtonCloseModal>
          </StyledHeaderModal>
          <StyledModalBody className="modal-body">
            <div className="p-2">
              <div className="row">
                <div className="col-lg-6 mt-2">
                  <BNPDatePicker
                    onChange={props.handleChangeDate}
                    value={props.searchParam.from ?
                      convertTimeZone("Canada/Eastern", props.searchParam.from).format(FE_DETAIL_DATE_FORMAT)
                      :
                      ""
                    }
                    placeholder="From date"
                    name="from"
                  />
                </div>
                <div className="col-lg-6 mt-2">
                  <BNPDatePicker
                    onChange={props.handleChangeDate}
                    value={props.searchParam.to ?
                      convertTimeZone("Canada/Eastern", props.searchParam.to).format(FE_DETAIL_DATE_FORMAT)
                      :
                      ""
                    }
                    placeholder="To date"
                    name="to"
                  />
                </div>
              </div>
            </div>
          </StyledModalBody>
          <div className="modal-footer">
            <div className="col-md-4 p-0">
              <StyledButtonSubmit
                disabled={!isValid}
                onClick={props.handleOK}
                submit={isValid}
                type="submit"
              >
                OK
              </StyledButtonSubmit>
            </div>
          </div>
        </div>
      </StyledModalDialog>
    </div>
  );
}

export function showTaxModal() {
  ($("#taxModal") as any).modal({
    show: true,
    keyboard: false,
    backdrop: "static",
  });
}
export function closeTaxModal() {
  ($("#taxModal") as any).modal("hide");
}

import React from "react";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId
} from "react-google-places-autocomplete";
// If you want to use the provided css
import "react-google-places-autocomplete/dist/assets/index.css";
import { StyledInput } from "../../../components/shared/styled";
import { countrySearchAPI } from "../../config/config";

type ResultLocation = {
  long_name: string;
  short_name: string;
  types: string[];
};

export type ResponseLocation = {
  province: string;
  city: string;
  postcode: string | null;
  country: string;
  lat?: number;
  lng?: number;
};

type Terms = {
  offset: number;
  value: string;
};

type Props = {
  id: string;
  placeholder?: string;
  address: string;
  setAddress: (address: string) => void;
  setLocation: (location: ResponseLocation) => void;
  tabIndex?: number;
};

export default function GoogleLocationInput(props: Props) {
  const getAddressByPlaceId = (result: any) => {
    let street = "";
    let terms: Terms[] = result.terms;
    street += terms[0].value;
    street += " " + terms[1].value;

    props.setAddress(street);

    geocodeByPlaceId(result.place_id).then((results: any) => {
      let locations: ResultLocation[] = results[0].address_components;

      let newLocation: ResponseLocation = setLocation(locations);
      if (!!results[0].geometry && !!results[0].geometry.location) {
        newLocation = Object.assign(newLocation, {
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng()
        })
      }

      props.setLocation(newLocation);
    });
  };

  const setLocation = (locations: ResultLocation[]) => {
    let newLocation = {
      province: "",
      city: "",
      postcode: "",
      country: ""
    };

    locations.forEach(f => {
      switch (f.types[0]) {
        case "administrative_area_level_1":
          newLocation.province = f.long_name;
          break;
        case "locality":
          newLocation.city = f.long_name;
          break;
        case "postal_code":
          newLocation.postcode = f.long_name;
          break;
        case "country":
          newLocation.country = f.long_name;
          break;
        default:
          break;
      }
    });

    return newLocation;
  };

  return (
    <GooglePlacesAutocomplete
      onSelect={(result: any) => {
        getAddressByPlaceId(result);
      }}
      autocompletionRequest={{
        componentRestrictions: {
          country: countrySearchAPI
        }
      }}
      placeholder={props.placeholder ? props.placeholder : "Address"}
      renderInput={(propsInput: any) => (
        <div className="custom-wrapper">
          <StyledInput
            // Custom properties
            {...propsInput}
            onChange={event => {
              propsInput.onChange(event);
              props.setAddress(event.target.value);
            }}
            value={props.address}
            tabIndex={props.tabIndex ? props.tabIndex : 0}
            role="application"
            aria-hidden="true"
          />
        </div>
      )}
      idPrefix={props.id}
      debounce={1000}
    />
  );
}

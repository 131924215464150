import React, {useEffect, useMemo, useState} from "react";
import Logo from "../../../cores/config/component/images/white-logo.png";
import NavItem from "../../../components/shared/layout/nav-item";
import styled from "styled-components";
import { Link } from "react-router-dom";
import BusinessIcon from "@material-ui/icons/Business";
import Dashboard from "@material-ui/icons/Dashboard";
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAlt';
import { getInfoByToken, isPartnerUser } from "../../../cores/utils/helpers";
import { ROLE_PARTNER_ADMIN, ROLE_PARTNER_MEMBER } from "../../../cores/constants/string-constraint";
import { partnerCompanyManagementURL, 
    partnerDashboardURL, 
    partnerEmployeeManagementURL, 
    partnerProfileURL } from "../../../cores/constants/url-config";
import {getPermissions} from "../../../services/partner-service";

type EmployerSidebarProps = {
  handleDrawerToggle?: () => void;
  transactionCount?: number;
};

const StyledDivLogo = styled.div`
  height: 60px;
  padding: 15px 21px;
`;

const StyledImgLogo = styled.img`
  height: 50px;
  position: absolute;
  left: 28px;
  top: 8px;
  @media (max-width: 600px) {
    height: 40px;
  }
`;

const navItemList = [
    {
        to: partnerDashboardURL,
        label: "Dashboard",
        icon: <Dashboard />,
        authorities: [ROLE_PARTNER_ADMIN, ROLE_PARTNER_MEMBER]
    },
    {
        to: partnerCompanyManagementURL,
        label: "Company Management",
        icon: <BusinessIcon />,
        authorities: [ROLE_PARTNER_ADMIN, ROLE_PARTNER_MEMBER]
    },
    {
        to: partnerEmployeeManagementURL,
        label: "Employees Management",
        icon: <BusinessIcon />,
        authorities: [ROLE_PARTNER_ADMIN, ROLE_PARTNER_MEMBER],
        permissions: ["partner.employee:view"]
    },
    {
        to: partnerProfileURL,
        label: "My Profile",
        icon: <SentimentSatisfiedAltIcon />,
        authorities: [ROLE_PARTNER_ADMIN]
    },
]


export default function PartnerSideBar(props: EmployerSidebarProps) {

  const [userPermissions, setUserPermissions] = useState<string[]>([]);

  const navItemListMemo = useMemo(() => {
    if (!isPartnerUser()) return [];

    const authorities = getInfoByToken().authorities;

    const result = navItemList.filter(item => {
      const result = item.authorities.filter(au => {
        const idx = authorities.findIndex(a => a === au);
        return idx > -1;
      })
      return result && result.length > 0;
    })
      .filter(item => {
        if (item.permissions && item.permissions.length > 0) {
          return item.permissions.some(p => userPermissions.includes(p));
        }
        return true;

      });
    return result;
  }, [userPermissions]);

  useEffect(() => {
    getPermissions().then(res => setUserPermissions(res .data.records));
  }, []);

  return (
    <div>
      {/* logo */}
      <StyledDivLogo>
        <Link to={partnerDashboardURL}>
          <StyledImgLogo src={Logo} alt="logo" />
        </Link>
      </StyledDivLogo>

      {/* top nav */}
      <ul className="nav flex-column top-nav">
        {navItemListMemo.map(item => (
          <NavItem
            isPartnerPortalItem={true}
            to={item.to}
            handleDrawerToggle={props.handleDrawerToggle}
            label={item.label}
            iconClass={item.icon}
          />
        ))}
      </ul>
    </div>
  );

}

import React from "react";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Header from "./header";
import SideBar from "./side-bar";
import styled from "styled-components";
import { getToken, getInfoByToken, isAdminUser } from "../../../cores/utils/helpers";
import { Redirect } from "react-router";
import ChangePasswordModal, {
  closeChangePasswordModal,
} from "./change-password";
import {
  ChangePasswordType,
  putChangePassword,
} from "../../../services/auth-service";
import { message } from "../../../cores/helpers/message/message";
import { ValidatorForm } from "react-material-ui-form-validator";
import { signInURL } from "../../../cores/constants/url-config";
import {
  getAllRequestTransactionsPartnerCount
} from "../../../services/wallet-service";
import { BehaviorSubject } from "rxjs"

const drawerWidth = 240;

const pendingTransaction = new BehaviorSubject<number>(0)

export const toggleNoti = (value: number) => {
  pendingTransaction.next(value)
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: "calc(100% - 240px)",
    minHeight: "calc(100vh - 190px)",
  },
}));

const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    z-index: 300 !important;
    background-color: ${(props) => props.theme.sideBarColor[500]} !important;
    color: #ffffff !important;
    padding: 0;
    height: 100vh !important;
  }
`;

const EmployerLayout = ({ children }: any) => {
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isValid, setIsValid] = React.useState(false);
  const [currentPassword, setCurrentPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [reNewPassword, setReNewPassword] = React.useState("");
  const [transactionCount, setTransactionCount] = React.useState<number>(0);
  const [transactionInterval, setTransactionInterval] = React.useState<any>(null);


  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [children]);

  React.useEffect(() => {
    const subscribe = pendingTransaction.subscribe((value) => {
      setTransactionCount(value)
    })
    loadData()
    setTransactionInterval(
      setInterval(async () => {
        await loadData()
      }, 60000)
    );
    return () => {
      subscribe.unsubscribe()
    }
  }, []);

  const loadData = async () => {
    const info = getInfoByToken();
    if (!!info && info.permission === "super admin") {
      const res = await getAllRequestTransactionsPartnerCount()
      let count = 0 // default
      if (res.status === 200 && res.data) {
        count = res.data.count || 0
      }
      setTransactionCount(count)
    }
    if (info && info.permission !== "super admin") {
      clearInterval(transactionInterval);
    }
  }

  // custom rule will have name 'isPasswordMatch'
  ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
    if (value !== newPassword) {
      return false;
    }
    return true;
  });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const onChangeCurrentPassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCurrentPassword(event.target.value);
  };
  const onChangeNewPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(event.target.value);
  };
  const onChangeReNewPassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setReNewPassword(event.target.value);
  };

  const handleChangePassword = () => {
    let param: ChangePasswordType = {
      currentPassword: currentPassword,
      newPassword: newPassword,
    };

    putChangePassword(param).then(() => {
      closeChangePasswordModal();

      message("The password has been updated successfully.", "success");

      setIsValid(false);
      setCurrentPassword("");
      setNewPassword("");
      setReNewPassword("");
    });
  };

  const validate = (event: boolean) => {
    if (event && currentPassword && newPassword && reNewPassword) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  if (!getToken() || !isAdminUser()) {
    // redirect to login page if don't have token
    return <Redirect to={signInURL} />;
  }

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="default"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              <Header />
            </Typography>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer} aria-label="mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <StyledDrawer
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              <div>
                <div className={classes.toolbar} />
                <Divider />
                <SideBar
                  handleDrawerToggle={handleDrawerToggle}
                  transactionCount={transactionCount}
                />
              </div>
            </StyledDrawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <StyledDrawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              <div>
                <div className={classes.toolbar} />
                <Divider />
                <SideBar
                  transactionCount={transactionCount} />
              </div>
            </StyledDrawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <div>{children}</div>
        </main>
        <ChangePasswordModal
          isValid={isValid}
          currentPassword={currentPassword}
          newPassword={newPassword}
          reNewPassword={reNewPassword}
          onChangeCurrentPassword={onChangeCurrentPassword}
          handleChangePassword={handleChangePassword}
          onChangeNewPassword={onChangeNewPassword}
          onChangeReNewPassword={onChangeReNewPassword}
          validate={validate}
        />
      </div>
      {/* <Footer /> */}
    </>
  );
};
export default EmployerLayout;

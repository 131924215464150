// Create our number formatter.
/**
 * style:
 * "decimal" (plain number formatting, the default).
 * "currency" (currency formatting, what we're using).
 * "percent" (percent formatting).
 *
 * currency : The currency to use in currency formatting.
 *  Possible values are the ISO 4217 currency codes,
 *  such as "USD" for the US dollar, "EUR" for the euro,
 * or "CNY" for the Chinese RMB — see the Current currency & funds code list.
 *  There is no default value; if the style is "currency",
 *  the currency property must be provided.
 */
export const formatterUSD = (style: StyleNumberFormat, currency?: string) =>
  new Intl.NumberFormat("en-US", {
    style: style,
    currency: currency ? currency : "USD",
    minimumFractionDigits: style === "percent" ? 2 : undefined
  });

type StyleNumberFormat = "decimal" | "currency" | "percent";

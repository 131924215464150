import React, { Fragment, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import {
  StyledContentBox,
  StyledSpanLargerTitle,
} from "../../../../shared/styled";
import { StyledSpanEditInfo } from "../company-management-detail-view";
import CreateIcon from "@material-ui/icons/Create";
import BNPRawTable from "../../../../shared/BNPTable/BNPRawTable";
import { TableSortType } from "../../../../shared/BNPTable/BNPTableType";
import { Fade, IconButton, Menu, MenuItem } from "@material-ui/core";
import { formatterUSD } from "../../../../../cores/helpers/format-usd";
import moment from "moment";
import {
  BE_DATE_FORMAT,
  FE_DATE_FORMAT,
} from "../../../../../cores/utils/format/date-time-format";
import { CustomAdminFeeModal } from "./custom-admin-fee-modal";
import { Option } from "../../../../../cores/helpers/select/select";
import { EmployerEnrollment } from "../../company-management-type";
import { addLoading, removeLoading } from "../../../../../cores/utils/loading";
import {
  archiveCustomAdminFeeSetting,
  createCustomAdminFee,
  fetchCustomAdminFeeSettings,
} from "../../../../../services/company-service";
import {
  closeConfirmDialog,
  openConfirmDialog,
} from "../../../../../cores/utils/confirmDialog";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { getInfoByToken } from "../../../../../cores/utils/helpers";

const StyledIconButton = styled(IconButton)`
  padding: 0 !important;
`;

type Props = {
  employerId: number;
  enrollmentCoverages: EmployerEnrollment[];
  findEnrollmentById: (id: number) => string;
};

const CustomAdminFeeTableColumns: TableSortType[] = [
  {
    columnId: "enrollment",
    columnName: "Enrollment Coverage",
    sortType: null,
    width: 35,
    sortColumn: false,
  },
  {
    columnId: "adminFee",
    columnName: "Admin Fee",
    sortType: null,
    width: 20,
    sortColumn: false,
  },
  {
    columnId: "fromDate",
    columnName: "From Date",
    sortType: null,
    width: 20,
    sortColumn: false,
  },
  {
    columnId: "toDate",
    columnName: "To Date",
    sortType: null,
    width: 20,
    sortColumn: false,
  },
  {
    columnId: "actions",
    columnName: "",
    sortType: null,
    width: 5,
    sortColumn: false,
  },
];

const actionPermission = {
  view: ["super admin", "admin"],
  add: ["super admin"],
  archive: ["super admin"],
};

export function CustomAdminFee(props: Props) {
  const [data, setData] = useState<any[]>([]);
  const [actionItem, setActionItem] = useState<any | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [displayModal, setDisplayModal] = useState<boolean>(false);
  const role = getInfoByToken().permission;

  useEffect(() => {
    fetchData().then();
  }, [props.employerId]);

  const fetchData = async () => {
    if (props.employerId) {
      const result = await fetchCustomAdminFeeSettings(props.employerId);
      setData(result.data);
    }
  };

  const tableData = data.map((item) => ({
    enrollment: props.findEnrollmentById(item.enrollmentId),
    adminFee: formatterUSD("percent").format(Number(item.adminFee)),
    fromDate: moment(item.fromDate).format(FE_DATE_FORMAT),
    toDate: item.toDate ? moment(item.toDate).format(FE_DATE_FORMAT) : null,
    actions: actionPermission.archive.includes(role) ? (
      <StyledIconButton
        aria-controls="fade-menu"
        aria-haspopup="true"
        onClick={(event) => handleClick(event, item.id)}
      >
        <MoreHorizIcon style={{ color: "#003049" }} />
      </StyledIconButton>
    ) : (
      <></>
    ),
  }));

  const handleClick = (event: React.MouseEvent<HTMLElement>, id: number) => {
    setActionItem(data.find((item) => item.id === id) || undefined);
    setAnchorEl(event.currentTarget);
  };

  const enrollmentCoveragesOptions: Option[] = useMemo(() => {
    if (props.enrollmentCoverages && props.enrollmentCoverages.length > 0) {
      return props.enrollmentCoverages
        .filter(
          (ec) =>
            data.findIndex((item) => item.enrollmentId === ec.enrollmentId) === -1
        )
        .map((item) => ({
          id: item.enrollmentId,
          name: props.findEnrollmentById(item.enrollmentId),
        }));
    }
    return [];
  }, [props.enrollmentCoverages, data]);

  const handleSubmitCustomAdminFee = async (model: any) => {
    const modelToSubmit = {
      employerId: props.employerId,
      enrollmentId: model.enrollmentId,
      adminFee: Number(model.adminFeePercentage) / 100,
      fromDate: moment(model.fromDate).format(BE_DATE_FORMAT),
      toDate: model.toDate ? moment(model.toDate).format(BE_DATE_FORMAT) : null,
    };
    addLoading();
    await createCustomAdminFee(modelToSubmit);
    await fetchData();
    setDisplayModal(false);
    removeLoading();
  };

  const confirmDialogContent: { [key: string]: any } = {
    archive: {
      title: "Archive Custom Admin Fee",
      content:
        "Are you sure want to archive this Custom Admin Fee setting of this company?",
      handleOnOk: (isOk: boolean) =>
        isOk && actionItem && handleArchiveCustomAdminFeeSetting(actionItem.id),
    },
  };

  const showModalConfirm = (action: string) => {
    openConfirmDialog({
      id: "PremiumPaymentActionConfirm",
      open: true,
      title: confirmDialogContent[action].title,
      content: confirmDialogContent[action].content,
      onClose: (isOk: boolean) => {
        confirmDialogContent[action].handleOnOk(isOk);
        closeConfirmDialog();
      },
    });
  };

  const handleArchiveCustomAdminFeeSetting = async (id: number) => {
    addLoading();
    await archiveCustomAdminFeeSetting(id);
    await fetchData();
    removeLoading();
  };

  return (
    <Fragment>
      <StyledContentBox>
        <StyledSpanLargerTitle>
          Custom Admin Fee
          {actionPermission.add.includes(role) ? (
            <StyledSpanEditInfo onClick={() => setDisplayModal(true)}>
              <CreateIcon fontSize="small" /> Add
            </StyledSpanEditInfo>
          ) : (
            <></>
          )}
        </StyledSpanLargerTitle>
        <BNPRawTable
          tableName="custom_admin_fee"
          columns={CustomAdminFeeTableColumns}
          tableWidth="100%"
          tableData={tableData}
        />
      </StyledContentBox>
      <CustomAdminFeeActionMenu
        actionItem={actionItem}
        anchorEl={anchorEl}
        handleCloseMenu={() => setAnchorEl(null)}
        showModalConfirm={showModalConfirm}
      />
      <CustomAdminFeeModal
        enrollmentOptions={enrollmentCoveragesOptions}
        open={displayModal}
        setOpen={setDisplayModal}
        handleClose={() => setDisplayModal(false)}
        handleSubmit={handleSubmitCustomAdminFee}
      />
    </Fragment>
  );
}

type CustomAdminFeeActionMenuProps = {
  actionItem?: any;
  anchorEl: null | HTMLElement;
  handleCloseMenu: () => void;
  showModalConfirm: (action: string) => void;
};

const CustomAdminFeeActionMenu = (props: CustomAdminFeeActionMenuProps) => {
  const open = Boolean(props.anchorEl);
  return (
    <Menu
      id="fade-menu"
      key={props.actionItem && props.actionItem.productId}
      anchorEl={props.anchorEl}
      keepMounted
      open={open}
      onClose={props.handleCloseMenu}
      TransitionComponent={Fade}
    >
      <MenuItem
        onClick={() => {
          props.handleCloseMenu();
          props.showModalConfirm("archive");
        }}
        style={{ fontFamily: "Graphik Light", color: "red" }}
      >
        Archive
      </MenuItem>
    </Menu>
  );
};

import React, {useEffect} from 'react'
import AddIcon from "@material-ui/icons/Add";
import DownloadIcon from "@material-ui/icons/GetApp";
import {
  StyledBigTitle,
  StyledButtonSubmit,
  StyledContentBox,
  StyledMainContent,
  StyledOption,
  StyledSpanStatus
} from '../../shared/styled'
import { StyledIconSearch } from '../claim-management/claim-management-view'
import { BNPInput } from '../../../cores/helpers/input/input'
import BNPDatePicker from '../../../cores/helpers/date-picker/date-picker'
import { BNPSelect, Option } from '../../../cores/helpers/select/select'
import BNPRawTable from '../../shared/BNPTable/BNPRawTable';
import {getWorkingHourFromResponse, VendorListColumns} from './marketplace-model';
import Pagination, { setPagingInfo } from '../../../cores/helpers/pagination/pagination';
import AddVendorModal from './add-vendor/add-vendor-modal';
import {EditVendorType, Language, Vendor} from "./marketplace-type";
import {getProvinces} from "../../../services/company-service";
import {
  createVendor,
  getAllLanguages,
  getAllServices,
  getVendorDetail,
  updateVendor,
  changeVendorStatus,
  getAllVendorList,
  getExportVendorList
} from "../../../services/marketplace-service";
import {addLoading, removeLoading} from "../../../cores/utils/loading";
import { exportExcel } from '../../../cores/helpers/export-file/export-file';
import VendorAction from './vendor-action';
import { message } from '../../../cores/helpers/message/message';

import styled from "styled-components";
import {AxiosResponse} from "axios";
import {SortTable} from "../../../cores/models/SortTable";
import {TableSortType} from "../../shared/BNPTable/BNPTableType";
import {BNPAutoComplete} from "../../../cores/helpers/input/BNPAutoComplete";
import {closeConfirmDialog, openConfirmDialog} from "../../../cores/utils/confirmDialog";

const StyledActionButton = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 100%;
  padding-left: 8px;
`;

const StyledButtonOutlined = styled.button`
    width: 100%;
    position: relative;
    background-color: transparent;
    border: 1px solid ${(props: any) => props.theme.primaryColor};
    border-radius: 0.25rem;
    display: inline-block;
    font-weight: bold;
    color: ${(props: any) => props.theme.primaryColor};
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    padding: 0.375rem 0.75rem;
    font-size: 14px;
    line-height: 1.5;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    height: 50px;
    margin-right: 10px;
`;

function Marketplace() {
  const [open, setOpen] = React.useState<boolean>(false);
  const [isEditPopUp, setIsEditPopUp] = React.useState<boolean>(false);
  const [editData, setEditData] = React.useState<EditVendorType>({} as EditVendorType);
  const [input, setInput] = React.useState<string>("");
  const [searchParams, setSearchParams] = React.useState<any>({
    searchKey: "",
    fromDate: null,
    toDate: null,
    status: "",
    service: null,
    page: 1,
    perPage: 10,
  });
  const [vendorData, setVendorData] = React.useState<Vendor[]>([])
  const [provinces, setProvinces] = React.useState<any>([]);
  const [languages, setLanguages] = React.useState<Language[]>([]);
  const [services, setServices] = React.useState<any[]>([]);
  const [expenseTypes, setExpenseTypes] = React.useState<Option[]>([]);
  const [columns, setColumns] = React.useState<TableSortType[]>(VendorListColumns);

  useEffect(() => {
    addLoading();
    loadStaticData()
      .then(() => getVendorList())
      .then(() => removeLoading());
  }, [searchParams]);

  const loadStaticData = async () => {
    const [provincesRes, languagesRes, servicesRes] = await Promise.all([
      getProvinces(),
      getAllLanguages(),
      getAllServices()
    ]);

    setProvinces(provincesRes.data);
    setLanguages(languagesRes.data.map((item: Language) => ({...item, id: item.code})));
    setServices(servicesRes.data.map((item:any) => ({...item, name: `${item.enrollmentName} - ${item.name}`})) || []);
    setExpenseTypes(servicesRes.data || []);
  }

  const getVendorList = async () => {
    const result = await getAllVendorList(searchParams)
    setPagingInfo(
      searchParams.page,
      result.data.pageCount,
      searchParams.perPage
    );

    const vendorData = result.data.records.map((vendor: any) => ({
      ...vendor,
      status:
        <StyledSpanStatus title={vendor.status}>
          {vendor.status || ""}
        </StyledSpanStatus>,
      action:
          <VendorAction
              vendor={vendor}
              updateVendorStatus={showModalConfirmDisable}
              handleEditVendor={handleEditVendor}
          />
    }))
    setVendorData(vendorData || []);
  };

  const pagingInfo = setPagingInfo(searchParams.page, 1, searchParams.perPage)

  const handleChangeDate = (date: Date | null, name: string) => {
    let temp = Object.assign(searchParams);

    if (name === "from") {
      if (temp.toDate && date) {
        let currentFrom = new Date(date),
          from = new Date(currentFrom),
          to = new Date(temp.toDate);
        if (from > to) {
          message("The from date cannot be after the to date.", "error");
          return;
        }
      }
      temp.fromDate = date;
    } else {
      if (temp.fromDate && date) {
        let from = new Date(temp.fromDate),
          to = new Date(date);
        if (from > to) {
          message("The from date cannot be after the to date.", "error");
          return;
        }
      }
      temp.toDate = date;
    }
    temp.page = 1;
    setSearchParams({...temp});
  }

  const showModalConfirmDisable = (vendorId: number, status: string, vendorName: string) => {
    const title = status === "INACTIVE" ? "Disable Vendor" : "Enable Vendor";
    const content = status === "INACTIVE" ? `Are you sure want to disable ${vendorName}?` : `Are you sure want to enable ${vendorName}?`;
    openConfirmDialog({
      id: "VendorActionConfirm",
      open: true,
      title,
      content,
      onClose: (isOk: boolean) => {
        if (isOk) {
          updateVendorStatus(vendorId, status).then();
        }
        closeConfirmDialog();
      },
    });
  }

  const updateVendorStatus = async (vendorId: number, status: string) => {
    addLoading();
    await changeVendorStatus(vendorId, status);
    await getVendorList();
    removeLoading();
  }

  const handleChangeInput = (e: any) => {
    const { name, value } = e.target;

    if (name === "name") {
      setInput(value);
    }
  }

  const handleChangeStatus = (e: any) => {
    if (e.target.value === -1) {
      setSearchParams({...searchParams, status: ""})
      return
    }
    setSearchParams({...searchParams, status: e.target.value})
  }

  const handleChangeService = (event: any, option: Option) => {
    if (!option) {
      setSearchParams({...searchParams, service: null})
      return
    }
    setSearchParams({...searchParams, service: option.id})
  }

  const handleExportExcel = () => {
    getExportVendorList(searchParams).then(
      (result) => {
        exportExcel(result.data);
      }
    );
  }

  const onKeyUp = (e: any) => {
    if (e.keyCode === 13) {
      setSearchParams({...searchParams, searchKey: input, page: 1})
    }
  }

  const handleChangeSortColumn = (columnId: string) => {
    let columnsHeaderTable: SortTable[] = [...columns];
    let searchParamClone = Object.assign({}, searchParams);
    searchParamClone.columnName = columnId;
    let index = columnsHeaderTable.findIndex((column) => {
      return column.columnId === columnId;
    });

    columnsHeaderTable.forEach((column, i) => {
      if (i === index) {
        column.sortType = column.sortType === "ASC" ? "DESC" : "ASC";
        searchParamClone.sortType = column.sortType;
        return;
      }
      column.sortType = null;
    });

    setSearchParams(searchParamClone)
  };

  const handleChangePage = (page: number) => {
    setSearchParams({...searchParams, page})
  }
  const handleChangePerPage = (e: any) => {
    setSearchParams({...searchParams, perPage: e.target.value, page: 1})
  }

  const handleAddNewVendor = () => {
    setOpen(true)
    setIsEditPopUp(false)
    setEditData({} as EditVendorType)
  }

  const handleEditVendor = async (id: number) => {
    const res = await getVendorDetail(id);
    setEditData(convertEditData(res))
    setOpen(true)
    setIsEditPopUp(true)
  }

  const convertEditData = (res: AxiosResponse<any>): EditVendorType => {
    const {id, name, email, description, website, costFrom, costTo, affiliateLink, couponCode, status, publishTo, location,
      languageSpoken, serviceIds, workingHours, photos, coverImageUrl, discount} = res.data;

    return {
      id,
      name,
      email,
      description,
      website,
      costFrom,
      costTo,
      serviceIds,
      affiliateLink,
      couponCode,
      discount,
      publishTo,
      spokenLanguageIds: languageSpoken.map((item: any) => item.code),
      photos,
      coverImageUrl,
      workingHours: getWorkingHourFromResponse(workingHours),
      address: location.address,
      city: location.cityName,
      provinceId: location.provinceId,
      postcode: location.postcode,
      lat: location.latitude,
      lng: location.longitude
    }
  }

  const handleCloseModal = () => {
    setOpen(false)
  }

  const handleSubmitAddVendor = async (model: EditVendorType) => {
    addLoading();
    await createVendor(model);
    await getVendorList();
    removeLoading();
  }

  const handleSubmitEditVendor = async (model: EditVendorType) => {
    addLoading();
    await updateVendor(model);
    await getVendorList();
    removeLoading();
  }

  return (
    <StyledMainContent>
      <StyledBigTitle>Vendors</StyledBigTitle>
      <div className="container-fluid p-0 mt-3">
        <StyledContentBox>
          <div className="row">
            <div className="col-lg-12 mt-4">
              <div className="row">
                <div className={"col-xl-9"}>
                  <div className={"row"}>
                    <div className="col-xl-2 col-lg-3 col-md-6 mt-4">
                      <StyledIconSearch/>
                      <BNPInput
                        placeholder="Search name or email"
                        name="name"
                        onChange={handleChangeInput}
                        value={input}
                        onKeyUp={onKeyUp}
                        tabIndex={1}
                      />
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-6 mt-4">
                      <BNPDatePicker
                        onChange={handleChangeDate}
                        value={searchParams.fromDate}
                        placeholder="From date"
                        name="from"
                        tabIndex={2}
                      />
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-6 mt-4">
                      <BNPDatePicker
                        onChange={handleChangeDate}
                        value={searchParams.toDate}
                        placeholder="To date"
                        name="to"
                        tabIndex={2}
                      />
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-6 mt-4">
                      <BNPSelect
                        options={[
                          {id: "ACTIVE", name: "Active"},
                          {id: "INACTIVE", name: "Inactive"}
                        ]}
                        value={searchParams.status || -1}
                        name="status"
                        placeholder="Status"
                        onChange={handleChangeStatus}
                        tabIndex={3}
                      />
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-6 mt-4">
                      <BNPAutoComplete
                        name="service"
                        options={expenseTypes}
                        optionSelectedId={searchParams.service || -1}
                        handleChange={handleChangeService}
                        placeholder="Search service"
                        tabIndex={4}
                      />
                    </div>
                  </div>
                </div>
                <div className={"col-xl-3"}>
                  <div className={"row justify-content-end"}>
                    <div className="col-xl-6 col-lg-2 col-md-6 mt-4">
                      <StyledButtonOutlined
                        onClick={handleExportExcel}
                      >
                        <DownloadIcon className="icon" style={{marginRight: "5px"}}/>
                        Export To Excel
                      </StyledButtonOutlined>
                    </div>
                    <div className="col-xl-6 col-lg-2 col-md-4 col-md-6 mt-4">
                      <StyledButtonSubmit
                        submit={true}
                        onClick={handleAddNewVendor}
                      >
                        <AddIcon className="icon"/>
                        Add New Vendor
                      </StyledButtonSubmit>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 mt-4">
              <BNPRawTable
                columns={columns}
                tableWidth="100%"
                tableData={vendorData}
                tableName="Vendor List"
                handleChangeSortColumn={handleChangeSortColumn}
              />
              <div className="row mt-4">
                <div className="col-12 text-center">
                  <label>
                    <Pagination
                      pagerPagination={pagingInfo}
                      getPage={handleChangePage}
                      onChangeRowsPerPage={handleChangePerPage}
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </StyledContentBox>
      </div>
      <AddVendorModal
        open={open}
        handleClose={handleCloseModal}
        isEdit={isEditPopUp}
        editData={editData}
        provinces={provinces}
        languages={languages}
        services={services}
        handleAddVendor={handleSubmitAddVendor}
        handleEditVendor={handleSubmitEditVendor}
      />
    </StyledMainContent>
  )
}

export default Marketplace;
import {AxiosResponse} from "axios";
import {getAsync, getFileAsync, postAsync, putAsync} from "../cores/utils/http-client";
import {CreateVendorType, EditVendorType} from "../components/pages/marketplace/marketplace-type";
import moment from "moment";
import { BE_DATE_FORMAT } from "../cores/utils/format/date-time-format";

const URL_Employer = "/manage-employer/api";

export const getAllLanguages = (): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/languages`;

  return getAsync(url);
};

export const getAllVendorList = (params: any): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/vendors`;
  let fromDate: string | null = params["fromDate"];
  let toDate: string | null = params["toDate"];
  fromDate = fromDate ? moment(fromDate).format(BE_DATE_FORMAT) : null;
  toDate = toDate ? moment(toDate).format(BE_DATE_FORMAT) : null;
  params["fromDate"] = fromDate;
  params["toDate"] = toDate;

  return getAsync(url, params);
};

export const changeVendorStatus = (vendorId: number, status: string): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/vendors/${vendorId}/status`;
  return putAsync(url, {"status": status});
};

export const getExportVendorList = (
  param: any
): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/vendors/export`;

  return getFileAsync(url, param);
};

export const getAllServices = (): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/vendors/services`;

  return getAsync(url);
};

export const getVendorDetail = (id: number): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/vendors/${id}`;

  return getAsync(url);
};

export const createVendor = (model: EditVendorType): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/vendors`;

  const request = convertRequestData(model);

  return postAsync(url, request);
};

export const updateVendor = (model: EditVendorType): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/vendors/${model.id}`;

  const request = convertRequestData(model);

  return putAsync(url, request);
};

const convertRequestData = (model: EditVendorType): any => {
  const {name, email, website, publishTo, costFrom, costTo, description, affiliateLink,
    couponCode, serviceIds, photos, coverImageUrl , discount} = model;
  const tzOffset = moment().utcOffset()/60;
  return {
    name,
    email,
    website,
    publishTo,
    costFrom,
    costTo,
    description,
    affiliateLink,
    couponCode,
    discount,
    location: {
      address: model.address,
      cityName: model.city,
      postcode: model.postcode,
      provinceId: model.provinceId,
      //Get from location, fallback hard code to Toronto center
      latitude: model.lat || 43.65107,
      longitude: model.lng || -79.347015,
    },
    languageSpoken: model.spokenLanguageIds,
    serviceIds,
    workingHours: Object.values(model.workingHours)
      .filter(item => item.checked)
      .map(item => ({
        ...item,
        from: moment(item.from).utcOffset(tzOffset).format('HH:mm'),
        to: moment(item.to).utcOffset(tzOffset).format('HH:mm'),
        //TODO: Get from location
        timezone: tzOffset
      }))
    ,
    photos,
    coverImageUrl,
  };
}
import React from 'react';
import {SummarizeType} from "../DashboardType";
import ReportSummarizedItem from "./ReportSummarizedItem";

type Props = {
  summarizedList: SummarizeType[];
}

const ReportSummarized = React.memo(function ReportSummarizedGroup(props: Props) {
  return (
    <div className="row">
      {props.summarizedList.map((item: SummarizeType) => (
        <ReportSummarizedItem key={`summarized_item_${item.title}`} title={item.title} content={item.value} />
      ))}
    </div>
  );
})

export default ReportSummarized;
import { TableSortType } from "../../../../shared/BNPTable/BNPTableType";

export const TransactionReportHeaderColumns: TableSortType[] = [
  {
    columnId: "txnTitle",
    columnName: "Title",
    sortColumn: false,
    sortType: null,
    width: 20,
  },
  {
    columnId: "txnDescription",
    columnName: "Description",
    sortColumn: false,
    sortType: null,
    width: 20,
  },
  {
    columnId: "clearedAmount",
    columnName: "Cleared Amount",
    sortColumn: false,
    sortType: null,
    width: 15,
  },
  {
    columnId: "pendingAmount",
    columnName: "Pending Amount",
    sortColumn: false,
    sortType: null,
    width: 15,
  },
  {
    columnId: "transactionType",
    columnName: "TransactionType",
    sortColumn: false,
    sortType: null,
    width: 20
  },
  {
    columnId: "createdDate",
    columnName: "Created Date",
    sortColumn: true,
    sortType: 'DESC',
    width: 20,
    type: 'DATE'
  }
];
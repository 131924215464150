import { TableSortType } from "../../shared/BNPTable/BNPTableType";
import {Option} from "../../../cores/helpers/select/select";
import {ICostRange, WeeklyWorkingHours, WorkingHour} from "./marketplace-type";
import { TableSortDataType } from "../../shared/BNPTable/BNPTableModel";

export const VendorListColumns: TableSortType[] = [
  {
    columnId: "id",
    columnName: "ID",
    sortType: null,
    width: 10,
    sortColumn: true,
  },
  {
    columnId: "name",
    columnName: "Vendor Name",
    sortType: null,
    width: 20,
    sortColumn: true,
  },
  {
    columnId: "email",
    columnName: "Email",
    sortType: null,
    width: 25,
    sortColumn: true,
  },
  {
    columnId: "createdDate",
    columnName: "Created Date",
    sortType: null,
    width: 18,
    sortColumn: true,
    type: TableSortDataType.DATE
  },
  {
    columnId: "status",
    columnName: "Status",
    sortType: null,
    width: 18,
    sortColumn: true,
  },
  {
    columnId: "action",
    columnName: "Action",
    sortType: null,
    width: 18,
    sortColumn: false,
  },
];

export const VendorPublishToOptions: Option[] = [
  {id: 'EMPLOYER', name: "Employer Portal"},
  {id: 'EMPLOYEE', name: "Employee Portal"},
  {id: 'ALL', name: "All"},
]

export const CostRangeOptions: ICostRange[] = [
  {
    id: 1,
    name: "Under $50",
    costFrom: 0,
    costTo: 5000
  },
  {
    id: 2,
    name: "$50 - $100",
    costFrom: 5000,
    costTo: 10000
  },
  {
    id: 3,
    name: "$100 - $150",
    costFrom: 10000,
    costTo: 15000
  },
  {
    id: 4,
    name: "Over $150",
    costFrom: 15000,
    costTo: null
  }
]

const daysOfWeek = [
  'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'
]

export const getDefaultWorkingHour = (dayOfWeek: string, checked: boolean): WorkingHour => ({
  dayOfWeek,
  checked,
  from: "",
  to: "",
  timezone: ""
})

export const getWorkingHourFromResponse = (res: WorkingHour[]): WeeklyWorkingHours => {

  const workingHours: WeeklyWorkingHours = {} as WeeklyWorkingHours;
  for (const dayOfWeek of daysOfWeek) {
    const workingHour = res.find(item => item.dayOfWeek === dayOfWeek);
    if (workingHour) {
      workingHours[dayOfWeek] = {...workingHour, checked: true, from: parseTime(workingHour.from), to: parseTime(workingHour.to)}
    } else {
      workingHours[dayOfWeek] = getDefaultWorkingHour(dayOfWeek, false)
    }
  }

  return workingHours;
}

const parseTime = (time: string): string => {
  const [hour, minute] = time.split(":");
  const date = new Date();
  date.setHours(Number(hour), Number(minute), 0, 0);

  return date.toString();
}

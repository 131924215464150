import {TableSortType} from "../../../../shared/BNPTable/BNPTableType";
import {TableSortDataType} from "../../../../shared/BNPTable/BNPTableModel";

export const commissionTableColumn: TableSortType[] = [
    {columnId: "companyName", columnName: "Employer", width: 20, sortType: null, sortColumn: false},
    {columnId: "enrollmentName", columnName: "Product", width: 20, sortType: null, sortColumn: false},
    {columnId: "typeOfCommission", columnName: "Type Of Commission", width: 20, sortType: null, sortColumn: false},
    {
        columnId: "claimTotal",
        columnName: "Amount",
        width: 10,
        sortType: null,
        sortColumn: false,
        type: TableSortDataType.CURRENCY
    },
    {
        columnId: "commissionEarn",
        columnName: "Commission Earned",
        width: 10,
        sortType: null,
        sortColumn: false,
        type: TableSortDataType.CURRENCY
    },
    {
        columnId: "clawBack",
        columnName: "Clawback",
        width: 10,
        sortType: null,
        sortColumn: false,
        type: TableSortDataType.CURRENCY
    },
    {
        columnId: "netCommissionEarn",
        columnName: "Net Commission Earned",
        width: 10,
        sortType: null,
        sortColumn: false,
        type: TableSortDataType.CURRENCY
    },
];

export const commissionMonthlyTableColumn: TableSortType[] = [
    {columnId: "companyName", columnName: "Employer", width: 20, sortType: null, sortColumn: false},
    {columnId: "enrollmentName", columnName: "Product", width: 20, sortType: null, sortColumn: false},
    {columnId: "month", columnName: "Month/Year", width: 10, sortType: null, sortColumn: false, type: TableSortDataType.MONTH},
    {columnId: "typeOfCommission", columnName: "Type Of Commission", width: 20, sortType: null, sortColumn: false},
    {
        columnId: "claimTotal",
        columnName: "Amount",
        width: 10,
        sortType: null,
        sortColumn: false,
        type: TableSortDataType.CURRENCY
    },
    {
        columnId: "commissionEarn",
        columnName: "Commission Earned",
        width: 10,
        sortType: null,
        sortColumn: false,
        type: TableSortDataType.CURRENCY
    },
    {
        columnId: "clawBack",
        columnName: "Clawback",
        width: 10,
        sortType: null,
        sortColumn: false,
        type: TableSortDataType.CURRENCY
    },
    {
        columnId: "netCommissionEarn",
        columnName: "Net Commission Earned",
        width: 10,
        sortType: null,
        sortColumn: false,
        type: TableSortDataType.CURRENCY
    },
];

export const commissionYearlyTableColumn: TableSortType[] = [
    {columnId: "companyName", columnName: "Employer", width: 20, sortType: null, sortColumn: false},
    {columnId: "enrollmentName", columnName: "Product", width: 20, sortType: null, sortColumn: false},
    {columnId: "year", columnName: "Year", width: 10, sortType: null, sortColumn: false, type: TableSortDataType.YEAR},
    {columnId: "typeOfCommission", columnName: "Type Of Commission", width: 20, sortType: null, sortColumn: false},
    {
        columnId: "claimTotal",
        columnName: "Amount",
        width: 10,
        sortType: null,
        sortColumn: false,
        type: TableSortDataType.CURRENCY
    },
    {
        columnId: "commissionEarn",
        columnName: "Commission Earned",
        width: 10,
        sortType: null,
        sortColumn: false,
        type: TableSortDataType.CURRENCY
    },
    {
        columnId: "clawBack",
        columnName: "Clawback",
        width: 10,
        sortType: null,
        sortColumn: false,
        type: TableSortDataType.CURRENCY
    },
    {
        columnId: "netCommissionEarn",
        columnName: "Net Commission Earned",
        width: 10,
        sortType: null,
        sortColumn: false,
        type: TableSortDataType.CURRENCY
    },
];

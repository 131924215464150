import { AxiosResponse } from "axios";
import { Dependent } from "../components/pages/company-management/dependent-modal/employee-type";
import {getAsync, putAsync} from "../cores/utils/http-client";

const employeesUrl = "/manage-employee/api";

export interface SearchResult {
  pageCount: number;
  records: any
}

export const putDependents = (employeeId: number,model: Dependent[]): Promise<AxiosResponse> => {
  let url = `${employeesUrl}/dependents/${employeeId}`;

  return putAsync(url, model);
};

export const getAllInfoEmployee = (employeeId: number): Promise<AxiosResponse> => {
  let url = `${employeesUrl}/employees/all-info/${employeeId}`;

  return getAsync(url);
};

export const getBenefits = (employeeId: number): Promise<AxiosResponse> => {
  let url = `${employeesUrl}/benefits-coverage/${employeeId}`;

  return getAsync(url);
};

export const getBenefitsType = (employeeId: number): Promise<AxiosResponse> => {
  let url = `${employeesUrl}/benefits-coverage/type/${employeeId}`;

  return getAsync(url);
};

export const getEmployeeEligibilityDate = (employeeId: number): Promise<AxiosResponse> => {
  let url = `${employeesUrl}/employees/eligibility-date/${employeeId}`;

  return getAsync(url);
};
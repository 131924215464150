import {TableSortType} from "../../../shared/BNPTable/BNPTableType";
import {TableSortDataType} from "../../../shared/BNPTable/BNPTableModel";

export const headerEFTHistory: TableSortType[] = [
  {
    columnId: "fileName",
    columnName: "EFT File Name",
    sortType: null,
    width: 20,
    sortColumn: true
  },
  {
    columnId: "createdDate",
    columnName: "Date",
    sortType: null,
    width: 15,
    sortColumn: true,
    type: TableSortDataType.DATE
  },
  {
    columnId: "totalAmount",
    columnName: "Total amount",
    sortType: null,
    width: 15,
    sortColumn: true,
    type: TableSortDataType.CURRENCY
  },
  {
    columnId: "numberOfRecords",
    columnName: "Number of Records",
    sortType: null,
    width: 17,
    sortColumn: true,
  },
  {
    columnId: "partnerUserName",
    columnName: "Who Triggered Download",
    sortType: null,
    width: 20,
    sortColumn: true
  },
  {
    columnId: "status",
    columnName: "Send To XP Status",
    sortType: null,
    width: 13,
    sortColumn: false
  },
  {
    columnId: "action",
    columnName: "Action",
    sortType: null,
    width: 13,
    sortColumn: false
  },
];

import React, {useState, useEffect,} from "react";
import styled, {css} from "styled-components";
import { RouteChildrenProps } from "react-router";
import { RequiredTopUpReportType, requiredTopUpReportColumns } from "./required-topup-report-model";
import { StyledBigTitle, StyledMainContent } from "../../shared/styled";
import { Prefunding } from "./prefunding/prefunding";
import { InsufficientFund } from "./insufficient-fund/insufficient-fund";
import { getRequiredTopUpReport, postEftDepositPartner } from "../../../services/wallet-service";
import { RequiredTopUpReportInfo, RequiredTopUpReportSearchParam } from "./required-topup-report-type";
import { PagingInfo, setPagingInfo } from "../../../cores/helpers/pagination/pagination";
import { SortTable, SortType } from "../../../cores/models/SortTable";
import { showCreditModal } from "../company-management/detail/credit/employer-credit-view";
import CreditModel, { closeCreditModal } from "./credit/credit-modal";
import { EftCredit } from "./credit/credit-modal-type";
import { ConfirmDialog, showConfirmModal } from "../../../cores/helpers/confirm-modal/confirm-modal";
import { addLoading, removeLoading } from "../../../cores/utils/loading";
import { formatterUSD } from "../../../cores/helpers/format-usd";
import { centToUsd, usdToCent } from "../../../cores/helpers/cent-to-usd";
import { getInfoByToken } from "../../../cores/utils/helpers";
import { generateUUID } from "../../../cores/helpers/uuid";
import { publishMessage } from "../../../cores/utils/message";
import { requiredTopUpReportURL } from "../../../cores/constants/url-config";


const StyledRequiredTopUpReportNavBar = styled.div`
  width: fit-content;
  min-width: 250px;
  background: none;
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
`;

const StyledRequiredTopUpReportNavBarItem = styled.div`
  overflow: hidden;
  line-height: 16px;
  display: flex;
  justify-content: center;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 32px;
  :hover {
    background-color: #F7F7F8;
  }
  ${(props: { active: boolean }) =>
    props.active &&
    css`
      background-color: ${props => props.theme.infoColor};
      color: #fff;
      font-weight: bold;
      :hover {
        background-color: ${props => props.theme.infoColor};
        opacity: 0.8;
      }
    `}
`;

const confirmTypes = [
    {
      id: 'EFT',
      name: 'EFT'
    },
    {
      id: 'ETRANSFER',
      name: 'E-Transfer or Bill Pay'
    }
]

type Props = RouteChildrenProps;

export default function RequiredTopUpReport(props: Props) {

    const [activeType, setActiveType] = useState<string>(RequiredTopUpReportType.PREFUNDING.type);
    const [requiredTopUpReportData, setRequiredTopUpReportData] = useState<RequiredTopUpReportInfo[]>([]);
    const [searchParam, setSearchParam] = useState<RequiredTopUpReportSearchParam>({
        activeType: RequiredTopUpReportType.PREFUNDING.type,
        page: 1,
        columnName: "employerId",
        sortType: "DESC",
        perPage: 10,
    });
    const [pagingInfo, setPagingInformation] = useState<PagingInfo>({
        currentPage: 0,
        totalPages: 0,
        startPage: 0,
        endPage: 0,
        pages: [],
        rowsPerPage: 10,
    });
    const [credit, setCredit] = useState<EftCredit>({
        partnerId: 0,
        employerId: 0,
        depositAmount: 0,
        referenceId: "",
    });
    const [showPopupTime, setShowPopupTime] = useState<string>('');
    const [miniumTopUpAmount, setMiniumTopUpAmount] = useState<number>(0);


    useEffect(() => {
        let param = getSearchByUrl();
        setSearchByParam(param.searchParams);
    }, []);

    useEffect(() => {
        return () => {
            if (window.location.pathname.includes(requiredTopUpReportURL) 
                && (props.history.action === "POP" || props.history.action === "PUSH")) {
                loadData();
            }
          };
    }, [props]);

    function loadData() {
        addLoading();
        let param = getSearchByUrl();
        getSearchClaimData(param.searchParams);
        setSearchParam(param.searchParams);
        setActiveType(param.searchParams.activeType);
        removeLoading();
    }

    function handleChangeType (type: string) {
        let searchParams: RequiredTopUpReportSearchParam = Object.assign({}, searchParam);
        searchParams.page = 1;
        searchParams.activeType = type;
        setSearchByParam(searchParams);
    }

    function getSearchClaimData(searchParam: RequiredTopUpReportSearchParam) {
        getRequiredTopUpReport(searchParam.activeType, searchParam).then((result) => {
            let pagingInfo = setPagingInfo(
                searchParam.page,
                result.data.pageCount,
                searchParam.perPage
            );
          let requiredTopUpReportData: RequiredTopUpReportInfo[] = result.data.records || [];
            setRequiredTopUpReportData(requiredTopUpReportData);
            setPagingInformation(pagingInfo);
        });
    }

    function getSearchByUrl() {
        const urlParams = new URLSearchParams(window.location.search),
        type = urlParams.get("type"),
        page = urlParams.get("page"),
        columnName = urlParams.get("columnName"),
        sortType: SortType = urlParams.get("sortType") as SortType,
        rowsPerPage = urlParams.get("rowsPerPage");
    
        let searchParams: RequiredTopUpReportSearchParam = Object.assign({}, searchParam);
    
        type 
        ? (searchParams.activeType = type === RequiredTopUpReportType.INSUFFICIENT_FUND.type ? type : RequiredTopUpReportType.PREFUNDING.type) 
        : (searchParams.activeType = RequiredTopUpReportType.PREFUNDING.type);

        page ? (searchParams.page = Number(page)) : (searchParams.page = 1);
        
        if (columnName && sortType) {
            searchParams.columnName = columnName;
            searchParams.sortType = sortType;
        } else {
            searchParams.columnName = 'employerId';
            searchParams.sortType = 'DESC';
        }
    
        rowsPerPage
          ? (searchParams.perPage = Number(rowsPerPage))
          : (searchParams.perPage = searchParam.perPage);
    
        // set sort table
        let columns: SortTable[] = requiredTopUpReportColumns;
        let index = columns.findIndex((column) => {
            return column.columnId === searchParams.columnName;
        });
        if (index > -1) {
          columns[index].sortType = sortType;
        }
    
        return {
            searchParams,
            columns,
        };
    };

    function sortTable(columnId: string) {
        let columnsHeaderTable: SortTable[] = Object.assign(requiredTopUpReportColumns);
        let searchParams = Object.assign({}, searchParam);
        searchParams.columnName = columnId;
        let index = columnsHeaderTable.findIndex((column) => {
          return column.columnId === columnId;
        });
    
        columnsHeaderTable.forEach((column, i) => {
          if (i === index) {
            column.sortType = column.sortType === "ASC" ? "DESC" : "ASC";
            searchParams.sortType = column.sortType;
            return;
          }
          column.sortType = null;
        });
    
        setSearchByParam(searchParams);
    };

    function setSearchByParam(requiredTopUpReportSearchParam: RequiredTopUpReportSearchParam) {
        let url = new URL(window.location.href);

        requiredTopUpReportSearchParam.activeType
          ? url.searchParams.set("type", requiredTopUpReportSearchParam.activeType)
          : url.searchParams.delete("type");
    
        requiredTopUpReportSearchParam.page
          ? url.searchParams.set("page", requiredTopUpReportSearchParam.page.toString())
          : url.searchParams.delete("page");
    
        if (requiredTopUpReportSearchParam.columnName && requiredTopUpReportSearchParam.sortType) {
          url.searchParams.set("sortType", requiredTopUpReportSearchParam.sortType);
          url.searchParams.set("columnName", requiredTopUpReportSearchParam.columnName);
        } else {
          url.searchParams.delete("sortType");
          url.searchParams.delete("columnName");
        }
    
        requiredTopUpReportSearchParam.perPage
          ? url.searchParams.set("rowsPerPage", requiredTopUpReportSearchParam.perPage.toString())
          : url.searchParams.delete("rowsPerPage");
        props.history.push(url.search);
    };

    function handleChangePage(page: number) {
        let searchParams: RequiredTopUpReportSearchParam = Object.assign(searchParam);
        searchParams.page = page;
        setSearchByParam(searchParams);
    };

    function setRowsPerPage(event: any) {
        const { value } = event.target;
    
        let searchParams = Object.assign({}, searchParam);
        searchParams.page = 1;
        searchParams.perPage = Number(value);
    
        setSearchByParam(searchParams);
    };

    function openModalCredit(event: any, topUpAmount: number, employerId: number) {
        event.stopPropagation();
        event.preventDefault();

        let creditToShow = Object.assign({}, credit);
        creditToShow.depositAmount = centToUsd(topUpAmount);
        creditToShow.employerId = employerId
        setCredit(creditToShow);
        setMiniumTopUpAmount(creditToShow.depositAmount);

        showCreditModal();
        setShowPopupTime((new Date()).toString());
    };

    function handleChangeInputDeposit(event: React.ChangeEvent<HTMLInputElement>) {
        let creditVar = Object.assign({}, credit);
        let { name, value } = event.target;
        creditVar.depositAmount = Number(value);
        setCredit(creditVar);
    };

    function handleSubmitDeposit(data: any) {
        setCredit({...credit, ...data})
        showConfirmModal();
    };
    
    function checkSubmit() {
        let creditSubmit = Object.assign({}, credit, {
          depositAmount: usdToCent(credit.depositAmount),
        });
        if (creditSubmit.depositAmount < miniumTopUpAmount) {
            return;
        }
        const info = getInfoByToken();
        creditSubmit.partnerId = info.mainPartnerId;
        creditSubmit.referenceId = generateUUID();
        postEftDepositPartner(creditSubmit).then(() => {
          loadData();
          publishMessage({
            message: "Request deposit has been created.",
            variant: "success",
          });
    
          let adjustment = {
            partnerId: 0,
            employerId: 0,
            depositAmount: 0,
            referenceId: "",
          };
    
          closeCreditModal();
          removeLoading();
          setCredit(adjustment);

        });
    };


    return (
        <>
        <StyledMainContent>
            <StyledBigTitle>Required Topup Report</StyledBigTitle>
            <div>
                <RequiredTopUpReportNavbar activeType={activeType} handleChangeType={handleChangeType} />
            </div>
            <div>
                {activeType === RequiredTopUpReportType.PREFUNDING.type && 
                <Prefunding 
                    {...props}
                    sortTable={sortTable}
                    requiredTopUpReportData={requiredTopUpReportData}
                    handleChangePage={handleChangePage}
                    setRowsPerPage={setRowsPerPage}
                    pagingInfo={pagingInfo}
                    columns={requiredTopUpReportColumns}
                    openModalCredit={openModalCredit}
                />
                }
                {activeType === RequiredTopUpReportType.INSUFFICIENT_FUND.type && 
                <InsufficientFund 
                    {...props}
                    sortTable={sortTable}
                    requiredTopUpReportData={requiredTopUpReportData}
                    handleChangePage={handleChangePage}
                    setRowsPerPage={setRowsPerPage}
                    pagingInfo={pagingInfo}
                    columns={requiredTopUpReportColumns}
                    openModalCredit={openModalCredit}
                />
                }
            </div>
            <CreditModel
                handleChangeInput={handleChangeInputDeposit}
                credit={credit}
                confirmTypes={confirmTypes}
                handleSubmit={handleSubmitDeposit}
                showPopupTime={showPopupTime}
                miniumDepositAmount={miniumTopUpAmount}
                miniumErrorMessage="Please do not enter a number equal or greater than the required top up amount"
            />
            <ConfirmDialog
                content={`Are you sure to make a deposit of ${formatterUSD(
                    "currency"
                ).format(credit.depositAmount)}?`}
                title={"Deposit"}
                handleOnOk={checkSubmit}
            />
        </StyledMainContent>
        </>
    );

}

function RequiredTopUpReportNavbar (props: {activeType: string, handleChangeType: (type: string) => void }) {
    return <StyledRequiredTopUpReportNavBar>
      {
      Object.keys(RequiredTopUpReportType).map((type: string) => (
        <div key={`report-nav-bar-item-${RequiredTopUpReportType[type].type}`}>
          <StyledRequiredTopUpReportNavBarItem 
            active={RequiredTopUpReportType[type].type === props.activeType}
            onClick={() => props.handleChangeType(RequiredTopUpReportType[type].type)}
            >
            {RequiredTopUpReportType[type].name}
          </StyledRequiredTopUpReportNavBarItem>
        </div>
      ))
      }
    </StyledRequiredTopUpReportNavBar>;
}

import { Dialog } from "@material-ui/core";
import React, { useEffect } from "react";
import { Subject, Subscription } from "rxjs";
import { StyledButtonCloseModal, StyledButtonSubmit, StyledHeaderModal, StyledHeaderTitleModal, StyledIconCloseModal } from "../../components/shared/styled";
import styled from "styled-components";

const confirmDialogSubject = new Subject();

export interface ConfirmDialogProps {
    id: string;
    open: boolean;
    title: string;
    content: string;
    onClose: (isOk: boolean) => void;
}

export const openConfirmDialog = (props: ConfirmDialogProps) => {
    confirmDialogSubject.next(props);
};

export const closeConfirmDialog = () => {
    confirmDialogSubject.next({
        id: '',
        open: false,
        title: '',
        content: '',
        onClose: () => { }
    })
};

const INITIAL_STATE: ConfirmDialogProps = {
    id: '',
    open: false,
    title: '',
    content: '',
    onClose: () => { }
}

export const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    width: 100%;
    align-self: flex-start;
  }
`;

const StyledWidthDiv = styled.div`
  width: 118px;
`;

const StyledOptionApproved = styled(StyledButtonSubmit)`
  background-color: ${props => props.theme.primaryColor};
`;
const StyledOptionRejected = styled(StyledButtonSubmit)`
  background-color: ${props => props.theme.secondaryColor};
`;

export const ConfirmDialog = () => {

    const [confirmState, seConfirmState] = React.useState<ConfirmDialogProps>(INITIAL_STATE);
    const [unsubscribe, setUnsubscribe] = React.useState<Subscription | null>(null);

    useEffect(() => {
        let result = confirmDialogSubject.subscribe(val => {
            seConfirmState(val as ConfirmDialogProps)
        })
        setUnsubscribe(result);
    }, [])

    function handleClose(isOk: boolean) {
        confirmState.onClose(isOk);
    }

    return (
        <>
            {
                confirmState.open && <StyledDialog
                    id={confirmState.id}
                    open={confirmState.open}
                    onClose={() => seConfirmState((prev) => ({ ...prev, open: false }))}
                >
                    <div role="document">
                        <div className="modal-content">
                            <StyledHeaderModal className="modal-header">
                                <StyledHeaderTitleModal className="text-left">
                                    {confirmState.title}
                                </StyledHeaderTitleModal>
                                <StyledButtonCloseModal
                                    name="btn-close"
                                    onClick={() => handleClose(false)}
                                >
                                    <StyledIconCloseModal>&times;</StyledIconCloseModal>
                                </StyledButtonCloseModal>

                            </StyledHeaderModal>
                            <div className="modal-body text-left">{confirmState.content}</div>
                            <div className="modal-footer">
                                <StyledWidthDiv>
                                    <StyledOptionRejected
                                        type="button"
                                        submit={true}
                                        onClick={() => handleClose(false)}
                                    >
                                        Cancel
                                    </StyledOptionRejected>
                                </StyledWidthDiv>
                                <StyledWidthDiv>
                                    <StyledOptionApproved
                                        type="button"
                                        submit={true}
                                        onClick={() => handleClose(true)}
                                        name="btn-ok"
                                    >
                                        Yes
                                    </StyledOptionApproved>
                                </StyledWidthDiv>
                            </div>
                        </div>
                    </div>
                </StyledDialog>
            }
        </>
    )
}
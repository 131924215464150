import { Status } from "../company-management/detail/employees/employee-model";
import {ReportTypeDetail, TReport} from "./claim-history-type";
import {TableSortType} from "../../shared/BNPTable/BNPTableType";

export const columnsClaimsHistoryCompleted: TableSortType[] =[
    {
      columnId: "code",
      columnName: "No.",
      sortType: null,
      width: 6,
      sortColumn: true
    },
    {
      columnId: "status",
      columnName: "Status",
      sortType: null,
      width: 6,
      sortColumn: true,
    },
    {
      columnId: "claimType",
      columnName: "Claim Type",
      sortType: null,
      width: 11,
      sortColumn: true,
    },
    {
      columnId: "employeeName",
      columnName: "Employee Name",
      sortType: null,
      width: 11,
      sortColumn: true,
    },
    {
      columnId: "provinceName",
      columnName: "Province",
      sortType: null,
      width: 11,
      sortColumn: true,
    },
    {
      columnId: "employerName",
      columnName: "Employer Name",
      sortType: null,
      width: 11,
      sortColumn: true,
    },
    {
      columnId: "referralPartnerName",
      columnName: "Partner",
      sortType: null,
      width: 10,
      sortColumn: true,
    },
    {
      columnId: "referralPartnerUserName",
      columnName: "Partner Team Member",
      sortType: null,
      width: 10,
      sortColumn: true,
    },
    {
      columnId: "claimTotal",
      columnName: "Total Claim",
      sortType: null,
      width: 8,
      sortColumn: true,
    },
    {
      columnId: "adminFee",
      columnName: "Admin Fee",
      sortType: null,
      width: 8,
      sortColumn: true,
    },
    {
      columnId: "gst",
      columnName: "GST",
      sortType: null,
      width: 6,
      sortColumn: true,
    },
    {
      columnId: "pst",
      columnName: "HST",
      sortType: null,
      width: 6,
      sortColumn: true,
    },
    {
      columnId: "rst",
      columnName: "rst",
      sortType: null,
      width: 6,
      sortColumn: true,
    },
    {
      columnId: "premiumTax",
      columnName: "premiumTax",
      sortType: null,
      width: 8,
      sortColumn: true,
    },
    {
      columnId: "total",
      columnName: "total",
      sortType: null,
      width: 7,
      sortColumn: true,
    },
    {
      columnId: "paidDate",
      columnName: "Paid Date",
      sortType: "DESC",
      width: 9,
      sortColumn: true,
    },
    {
      columnId: "partnerSplitAmount",
      columnName: "Partner Commission",
      sortType: null,
      width: 9,
      sortColumn: true,
    },
    {
      columnId: "beniplusSplitAmount",
      columnName: "Beniplus Commission",
      sortType: null,
      width: 9,
      sortColumn: true,
    },
    {
      columnId: "action",
      columnName: "Action",
      sortType: null,
      width: 9,
      sortColumn: false,
    },
];

export const columnsClaimsHistoryIssued: TableSortType[] =[
  {
    columnId: "code",
    columnName: "No.",
    sortType: null,
    width: 7,
    sortColumn: true,
  },
  {
    columnId: "status",
    columnName: "Status",
    sortType: null,
    width: 8,
    sortColumn: false,
  },
  {
    columnId: "claimType",
    columnName: "Claim Type",
    sortType: null,
    width: 20,
    sortColumn: true,
  },
  {
    columnId: "employeeName",
    columnName: "Employee Name",
    sortType: null,
    width: 15,
    sortColumn: true,
  },
  {
    columnId: "provinceName",
    columnName: "Province",
    sortType: null,
    width: 15,
    sortColumn: true,
  },
  {
    columnId: "employerName",
    columnName: "Employer Name",
    sortType: null,
    width: 15,
    sortColumn: true,
  },
  {
    columnId: "claimAmount",
    columnName: "Claim Amount",
    sortType: null,
    width: 10,
    sortColumn: true,
  },
  {
    columnId: "submittedDate",
    columnName: "Submitted Date",
    sortType: "DESC",
    width: 10,
    sortColumn: true,
  },
];

export const claimTypes: Status[] = [
  {
    id: "HSA",
    name: "Healthcare Spending Account",
  },
  {
    id: "WELLNESS",
    name: "Wellness Spending Account",
  },
  {
    id: "INSURANCE",
    name: "Insurance",
  },
  {
    id: "CHARITY",
    name: "Charity",
  },
  {
    id: "RRSP",
    name: "RRSP",
  },
  {
    id: "GROUP_INSURANCE",
    name: "Group Insurance",
  },
];

export const ClaimHistoryReportType: TReport<ReportTypeDetail> = {
  CMP: {
    type: "CMP",
    name: "Completed Claim",
    columns: columnsClaimsHistoryCompleted,
  },
  ISS: {
    type: "ISS",
    name: "Issued Claim (Not paid)",
    columns: columnsClaimsHistoryIssued,
  }
}

import {TableSortType} from "../../../../shared/BNPTable/BNPTableType";
import {TableSortDataType} from "../../../../shared/BNPTable/BNPTableModel";

export interface Status {
  id: string;
  name: string;
}

export interface Class {
  id: number;
  name: string;
}

export const employeeStatus: Status[] = [
  {
    id: "WORKING",
    name: "Working"
  },
  {
    id: "TERMINATING",
    name: "Deactivating"
  },
  {
    id: "TERMINATED",
    name: "Inactive"
  },
  {
    id: "SUSPEND",
    name: "Suspended",
  },
];

export const t4EmployeeStatus: Status[] = [
  {
    id: "TRUE",
    name: "True"
  },
  {
    id: "FALSE",
    name: "False",
  },
];

export const signUpStatus: Status[] = [
  {
    id: "INVITED",
    name: "Invited"
  },
  {
    id: "SIGNED_UP",
    name: "Signed Up"
  },
  {
    id: "CREATED",
    name: "Created",
  },
];

export type PlanPreferencesFill = {
  id: number;
  value: number;
};

export const planPreferences: PlanPreferencesFill[] = [
  {
    id: 3,
    value: 0
  },
  {
    id: 4,
    value: 30
  },
  {
    id: 5,
    value: 60
  },
  {
    id: 6,
    value: 90
  }
];

export const columnsHeaderTable: TableSortType[] = [
  {
    columnId: "code",
    columnName: "ID",
    sortType: null,
    sortColumn: true,
    width: 10
  },
  {
    columnId: "fullName",
    columnName: "Full name",
    sortType: null,
    sortColumn: true,
    width: 25
  },
  {
    columnId: "role",
    columnName: "Role",
    sortType: null,
    sortColumn: false,
    width: 15
  },
  {
    columnId: "className",
    columnName: "Class",
    sortType: null,
    sortColumn: true,
    width: 15
  },
  {
    columnId: "eligibilityDate",
    columnName: "Eligibility Date",
    sortType: null,
    sortColumn: true,
    width: 15,
    type: TableSortDataType.DATE
  },
  {
    columnId: "t4Employee",
    columnName: "T4 Employee",
    sortType: null,
    sortColumn: false,
    width: 10
  },
  {
    columnId: "status",
    columnName: "Status",
    sortType: null,
    sortColumn: true,
    width: 10
  },
  {
    columnId: "actions",
    columnName: "Actions",
    sortType: null,
    sortColumn: false,
    width: 25
  }
];

export const partnerViewColumnsHeaderTable: TableSortType[] = [
  {
    columnId: "code",
    columnName: "ID",
    sortType: null,
    sortColumn: true,
    width: 10
  },
  {
    columnId: "fullName",
    columnName: "Full name",
    sortType: null,
    sortColumn: true,
    width: 25
  },
  {
    columnId: "role",
    columnName: "Role",
    sortType: null,
    sortColumn: false,
    width: 15
  },
  {
    columnId: "className",
    columnName: "Class",
    sortType: null,
    sortColumn: true,
    width: 15
  },
  {
    columnId: "eligibilityDate",
    columnName: "Eligibility Date",
    sortType: null,
    sortColumn: true,
    width: 15,
    type: TableSortDataType.DATE
  },
  {
    columnId: "t4Employee",
    columnName: "T4 Employee",
    sortType: null,
    sortColumn: false,
    width: 10
  },
  {
    columnId: "status",
    columnName: "Status",
    sortType: null,
    sortColumn: true,
    width: 10
  },
];

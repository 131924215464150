import React from "react";
import {
  StyledBigTitle,
  StyledContentBox,
  StyledDefaultOption,
  StyledMainContent,
  StyledOption,
  StyledSelect,
  StyledSpanStatus,
} from "../../shared/styled";
import { MenuItem} from "@material-ui/core";
import styled from "styled-components";
import SearchIcon from "@material-ui/icons/Search";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import SystemUpdateAltOutlinedIcon from "@material-ui/icons/SystemUpdateAltOutlined";
import {BNPInput} from "../../../cores/helpers/input/input";
import Pagination from "../../../cores/helpers/pagination/pagination";
import {
  companySearchParamDetailEmployeeDefault
} from "../../../cores/constants/url-config";
import {Link} from "react-router-dom";
import {TerminatingRequest} from "../../../../../src/components/pages/employer/employees/employee-type";
import {Employee} from "../company-management/company-management-type";
import EmployeeAction, {employeeActionEnum} from "./employee-action";
import {closeConfirmModal, ConfirmEmployeeActionDialog, showConfirmModal} from "./confirm-employee-action-dialog";
import { findEmployeeStatusNameById } from "../../../cores/utils/helpers";
import {employeeStatus, t4EmployeeStatus} from "../company-management/detail/employees/employee-model";
import { BNPSelect } from "../../../cores/helpers/select/select";
import { StyledDivExportExcel } from "../claim-management/claim-management-view";
import BNPRawTable from "../../shared/BNPTable/BNPRawTable";
import {upperCase} from "lodash";
import { ManageEmployeeSearchParam } from "./employees-management";

const StyledIconSearch = styled(SearchIcon)`
  position: absolute;
  top: 13px;
  right: 14px;
  opacity: 0.7;
`;

const StyledBNPInput = styled(BNPInput)`
  padding-right: 35px;
`;

const StyledDivBoxTable = styled.div`
  min-height: 300px;
`;

const StyledSpanErrorColor = styled.span`
  font-weight: bold;
  color: ${props => props.theme.errorColor};
`;

const StyledSpanBold = styled.span`
  font-weight: bold;
`;

function EmployeeManagementView(props: { columns: any[], sortTable: (columnId: any) => void, employees: any[],
  pagingInfo: any,
  handleChangePage: any,
  setRowsPerPage: any,
  onChangeSearchTerm: (searchTerm: string) => void,
  onSelectFilter: (event: any) => void,
  exportListEmployeeToExcel: () => void,
  deleteEmployee: (employeeId: number) => void;
  handleSuspendEmployee: (id: number, fromDate: Date, toDate: Date) => void;
  handleUnSuspendEmployee: (id: number) => void;
  handleTerminatedEmployee: (req: TerminatingRequest) => void;
  handleReActivateEmployee: (employeeId: number) => void;
  searchEmployees: () => void;
  detailLink: string;
  searchParam: ManageEmployeeSearchParam;
}) {


  const [id, setId] = React.useState<number>(0);
  const [name, setName] = React.useState<string>("");
  const [confirmingEmployee, setConfirmingEmployee] = React.useState<Employee | null>(null);
  const [confirmNumber, setConfirmNumber] = React.useState<employeeActionEnum>(employeeActionEnum.cancel);
  const [searchKey, setSearchKey] = React.useState<string>("");

  const onSearchInputKeyUp = (e: any) => {
    if (e.key === "Enter") {
       props.onChangeSearchTerm(searchKey);
    }
  }

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(e.target.value);
  }

  const showModalConfirm = (employee: Employee, confirm: employeeActionEnum) => {
    setId(employee.id);
    setName(`${employee.firstName} ${employee.lastName}`);
    setConfirmingEmployee(employee);
    setConfirmNumber(confirm);
    showConfirmModal();
  };

  const handleDelete = () => {
    props.deleteEmployee(id);
    closeConfirmModal();
  };
  const handleSuspend = (fromDate: Date, toDate: Date) => {
    props.handleSuspendEmployee(id, fromDate, toDate);
    closeConfirmModal();
  };
  const handleUnSuspend = () => {
    props.handleUnSuspendEmployee(id);
    closeConfirmModal();
  };

  const handleCancelEmployee = (fromDate: Date) => {
    props.handleTerminatedEmployee({id, terminatedDate: fromDate, isTerminatedNow: false})
    closeConfirmModal();
  };

  const handleCancelEmployeeNow = () => {
    props.handleTerminatedEmployee({id,terminatedDate: new Date(), isTerminatedNow: true})
    closeConfirmModal();
  };

  const handleReActivate = () => {
    props.handleReActivateEmployee(id);
    closeConfirmModal();
  };

  const confirmModalData: {[key in employeeActionEnum]: {title: string; content: JSX.Element; secondButtonName?: string; action: any; secondAction?: any;}} = {
    [employeeActionEnum.cancel]: {
      title: "Benefit Cancellation Date",
      content: <span>You are about to <StyledSpanErrorColor>cancel the benefits </StyledSpanErrorColor>
        of {name}. Please fill in the <StyledSpanBold>Benefit Cancellation Date </StyledSpanBold>
        to specify the date you prefer.</span>,
      action: handleCancelEmployee,
      secondButtonName: "Deactivate Account Immediately",
      secondAction: () => {
        closeConfirmModal();
        confirmingEmployee && showModalConfirm(confirmingEmployee, employeeActionEnum.cancelNow)
      }
    },
    [employeeActionEnum.cancelNow]: {
      title: "Deactivate Confirmation",
      content: <span>The account of this employee will be deactivated immediately and the action cannot be reversed. Do you want to proceed?</span>,
      action: handleCancelEmployeeNow,
    },
    [employeeActionEnum.suspend]: {
      title: "Suspend",
      content: <span>You are about to <StyledSpanErrorColor>suspend</StyledSpanErrorColor> {name}. Please fill in the
                <StyledSpanBold> Start Date</StyledSpanBold> and <StyledSpanBold>End Date </StyledSpanBold>
                to specify the Suspension Period.</span>,
      action: handleSuspend,
    },
    [employeeActionEnum.unSuspend]: {
      title: "Un-suspend",
      content: <span>You are about to <StyledSpanErrorColor>un-suspend</StyledSpanErrorColor> {name}. Do you want to proceed?</span>,
      action: handleUnSuspend,
    },
    [employeeActionEnum.archive]: {
      title: "Archive",
      content: <span>You are about to <StyledSpanErrorColor>archive</StyledSpanErrorColor> {name}. Do you want to proceed?</span>,
      action: handleDelete,
    },
    [employeeActionEnum.reActivate]: {
      title: "Re-Activate",
      content: <span>You are about to <StyledSpanErrorColor>re-activate</StyledSpanErrorColor> {name}. Do you want to proceed?</span>,
      action: handleReActivate,
    },
  }

  const data = props.employees.map(employee => ({
    ...employee,
    firstName: `${employee.firstName} ${employee.lastName}`,
    url:
      <Link
          to={
              props.detailLink +
              companySearchParamDetailEmployeeDefault(
                  employee.id
              )
          }
      >
        Detail
      </Link>,
    t4Employee: upperCase(employee.t4Employee),
    status:
      <StyledSpanStatus title={!employee.t4Employee ? employeeStatus[0].id : employee.status}>
        {findEmployeeStatusNameById(employee.status, employee.t4Employee) || ""}
      </StyledSpanStatus>,
    actions:
        <EmployeeAction
            employee={employee}
            showModalConfirm={showModalConfirm}
        />

  }))


  return (
    <StyledMainContent>
      <StyledBigTitle>Employees Management</StyledBigTitle>
      <div className="container-fluid p-0 mt-3">
        <StyledContentBox>
          <div className="row">
            <div className="col-lg-12 mt-4">
              <div className="row">
                <div className="col-lg-2 mt-4">
                  <StyledBNPInput
                    placeholder="Search name or email"
                    onChange={handleChangeInput}
                    value={searchKey}
                    onKeyUp={onSearchInputKeyUp}
                  />
                  <StyledIconSearch onClick={() => props.onChangeSearchTerm(searchKey)} />
                </div>
                <div className="col-lg-2 mt-4">
                  <BNPSelect
                    options={employeeStatus}
                    value={props.searchParam.employeeStatus || -1}
                    name="employeeStatus"
                    placeholder="Filter by status"
                    onChange={props.onSelectFilter}
                  />
                </div>
                <div className="col-lg-2 mt-4">
                  <BNPSelect
                    options={t4EmployeeStatus}
                    value={props.searchParam.t4Employee || -1}
                    name="t4Status"
                    placeholder="Filter by T4"
                    onChange={props.onSelectFilter}
                  />
                </div>
                <div className="col-lg-2"></div>
                <div className="col-lg-2"></div>
                <div className="col-lg-2 mt-4">
                  <StyledSelect
                    value={-1}
                    //onChange={props.onChange}
                    //name={props.name}
                    //disabled={props.disabled ? true : false}
                  >
                    <MenuItem value={-1}>
                      <StyledDefaultOption>
                        <PrintOutlinedIcon className="icon" /> Export data
                      </StyledDefaultOption>
                    </MenuItem>
                    <MenuItem value="1">
                      <StyledOption
                        onClick={props.exportListEmployeeToExcel}
                      >
                        <StyledDivExportExcel>
                          <SystemUpdateAltOutlinedIcon className="icon" />{" "}
                          Export via Excel
                        </StyledDivExportExcel>
                      </StyledOption>
                    </MenuItem>
                  </StyledSelect>
                </div>
              </div>
            </div>

            <StyledDivBoxTable className="col-lg-12 mt-4">
              <BNPRawTable columns={props.columns}
                           tableWidth='100%'
                           tableData={data}
                           handleChangeSortColumn={props.sortTable}/>
              <div className="row mt-4">
                <div className="col-12 text-center">
                  <label>
                    <Pagination
                        pagerPagination={props.pagingInfo}
                        getPage={props.handleChangePage}
                        onChangeRowsPerPage={props.setRowsPerPage}
                    />
                  </label>
                </div>
              </div>
            </StyledDivBoxTable>
          </div>
        </StyledContentBox>
      </div>

      <ConfirmEmployeeActionDialog
          employee={confirmingEmployee}
          type={confirmNumber}
          title={confirmModalData[confirmNumber].title}
          content={confirmModalData[confirmNumber].content}
          handleOnOk={confirmModalData[confirmNumber].action}
          secondButtonName={confirmModalData[confirmNumber].secondButtonName}
          handleSecondAction={confirmModalData[confirmNumber].secondAction}
          searchEmployees={props.searchEmployees}
      />
    </StyledMainContent>
  );
}

export default EmployeeManagementView;

import React from 'react';
import {BNPModal} from "../../../../shared/BNPModal/BNPModal";
import {EmployerPlanWithSettings} from "./plan-type";
import {Enrollment, PlanPreferencesModel} from "../../company-management-type";
import PlanDetails from "./plan-details";
import {RouteChildrenProps} from "react-router";

type Props = {
  open: boolean;
  title: string;
  handleClose: () => void;
  employerPlans: EmployerPlanWithSettings[];
  employerPlanId: number;
  enrollments: Enrollment[];
  planPreferences: PlanPreferencesModel[];
} & RouteChildrenProps;

function PlanModal(props: Props) {


  const handleCloseModal = () => {
    props.handleClose();
  }


  const body = () => {
    const employerPlan = props.employerPlans &&
      props.employerPlans.find(plan => plan.employerPlanId === props.employerPlanId);
    return (
      <PlanDetails
        employerPlan={employerPlan}
        planPreferences={props.planPreferences}
        enrollments={props.enrollments}
        {...props}/>
    );
  }

  return (
    <BNPModal
      open={props.open}
      title={props.title}
      handleClose={handleCloseModal}
      body={body()}
      footer={<></>}
    />
  );
}

export default PlanModal;
import React from "react";
import styled from "styled-components";
import {
  StyledHeaderModal,
  StyledHeaderTitleModal,
  StyledButtonCloseModal,
  StyledIconCloseModal,
  StyledButtonSubmit,
} from "../../shared/styled";
import { BNPInput } from "../../../cores/helpers/input/input";
import { Admin } from "./manege-admin-type";
import { BNPSelect } from "../../../cores/helpers/select/select";

const StyledModalDialog = styled.div`
  max-width: 600px;
  margin: 1.75rem auto;
  position: relative;
  width: auto;
  pointer-events: none;
  text-align: left;
`;

const StyledOptionRejected = styled(StyledButtonSubmit)`
  background-color: ${(props) => props.theme.secondaryColor};
`;

type Props = {
  admin: Admin;
  handleChangInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleEditPartner: () => void;
};

export default function EditAdminModal(props: Props) {
  return (
    <div className="modal" id="editAdminModal">
      <StyledModalDialog role="document">
        <div className="modal-content">
          <StyledHeaderModal className="modal-header">
            <StyledHeaderTitleModal>Edit</StyledHeaderTitleModal>
            <StyledButtonCloseModal type="button" onClick={closeEditAdminModal}>
              <StyledIconCloseModal>&times;</StyledIconCloseModal>
            </StyledButtonCloseModal>
          </StyledHeaderModal>
          <div className="modal-body">
            <div className="row">
              <div className="col-6 mt-3">
                <div className="mb-2">First name</div>
                <BNPInput
                  value={props.admin.firstName || ""}
                  onChange={props.handleChangInput}
                  name="firstName"
                  tabIndex={1}
                />
              </div>
              <div className="col-6 mt-3">
                <div className="mb-2">Last name</div>
                <BNPInput
                  value={props.admin.lastName || ""}
                  onChange={props.handleChangInput}
                  name="lastName"
                  tabIndex={2}
                />
              </div>
              <div className="col-6 mt-3">
                <div className="mb-2">Email</div>
                <BNPInput
                  value={props.admin.email || ""}
                  name="email"
                  disabled={true}
                />
              </div>
              <div className="col-6 mt-3">
                <div className="mb-2">Role Name</div>
                <BNPSelect
                  options={[
                    { id: "super admin", name: "Super Admin" },
                    { id: "admin", name: "Admin" },
                  ]}
                  value={props.admin.roleName || -1}
                  onChange={props.handleChangInput}
                  name="roleName"
                  placeholder="Role"
                />
              </div>
              <div className="col-12 mt-3">
                <hr />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="col-lg-3 p-0 float-right mt-3 ml-2">
                  <StyledButtonSubmit
                    submit={true}
                    onClick={props.handleEditPartner}
                    tabIndex={4}
                  >
                    Save
                  </StyledButtonSubmit>
                </div>
                <div className="col-lg-3 p-0 float-right mt-3 ml-2">
                  <StyledOptionRejected
                    submit={true}
                    onClick={closeEditAdminModal}
                    tabIndex={3}
                  >
                    Close
                  </StyledOptionRejected>
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledModalDialog>
    </div>
  );
}

export function showEditAdminModal() {
  ($("#editAdminModal") as any).modal({
    show: true,
    keyboard: false,
    backdrop: "static",
  });
}
export function closeEditAdminModal() {
  ($("#editAdminModal") as any).modal("hide");
}

import { SortTable } from "../../../cores/models/SortTable";
import { PartnerCompanyDetailOptions, PartnerSearchParam, PartnerStatusOption } from "./partner-management-type";

export const partnerStatusOptions: PartnerStatusOption[] = [
  {
    id: "ACTIVE",
    name: "ACTIVE",
  },
  {
    id: "INACTIVE",
    name: "INACTIVE",
  },
];

export const PartnerTypes = {
  INDIVIDUAL: "INDIVIDUAL",
  COMPANY: "COMPANY",
}

export const partnerTypeOptions: PartnerStatusOption[] = [
  {
    id: PartnerTypes.INDIVIDUAL,
    name: PartnerTypes.INDIVIDUAL,
  },
  {
    id: PartnerTypes.COMPANY,
    name: PartnerTypes.COMPANY,
  },
];

export const partnerManagementColumns = (): SortTable[] => {
  return [
    {
      columnId: "id",
      columnName: "ID",
      sortType: null,
      width: 10,
      sortColumn: true,
    },
    {
      columnId: "partnerName",
      columnName: "Partner name",
      sortType: null,
      width: 20,
      sortColumn: true,
    },
    {
      columnId: "email",
      columnName: "Email",
      sortType: null,
      width: 25,
      sortColumn: true,
    },
    {
      columnId: "createdDate",
      columnName: "Created Date",
      sortType: null,
      width: 18,
      sortColumn: true,
    },
    {
      columnId: "status",
      columnName: "Status",
      sortType: null,
      width: 12,
      sortColumn: true,
    },
    {
      columnId: "actions",
      columnName: "Actions",
      sortType: null,
      width: 8
    },
  ];
};

export const defaultSearchParam: PartnerSearchParam = {
  page: 1,
  perPage: 10,
  status: null,
  createdDate: null,
  searchName: null,
  columnName: "id",
  sortType: "DESC",
};

export const defaultPartnerCompanyDetailOptions: PartnerCompanyDetailOptions = {
  allowAll: false,
  allowAdjustment: false,
  allowRefund: false,
  allowDisableEmployer: false,
  allowEditEmployer: false,
  allowEditWallet: false,
  allowEditPlans: false,
  allowViewEmployees: false,
  allowEditEmployee: false,
  allowViewTransactions: false,
  allowViewMonthlyGroupContributions: false,
  allowViewPremiumInsurance: false,
  allowViewEmployeeDetail: false,
  allowViewBankingInfo: false,
  allowEditBankingInfo: false,
  allowEditReferralPartner: false,
  allowEditReferralPartnerUser: false,
}
import React from 'react';
import {KeyboardTimePicker, MuiPickersUtilsProvider, TimePicker} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {StyledDivDatePicker} from "../../../components/shared/styled";
import {AccessTime} from "@material-ui/icons"

type Props = {
  name: string;
  placeholder?: string;
  value: any;
  onChange: (newValue: any, name: string) => void;
  disabled?: boolean;
  tabIndex?: number;
}

function BNPTimePicker(props: Props): JSX.Element {
  return (
    <StyledDivDatePicker>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardTimePicker
          value={props.value || null}
          onChange={(date) => props.onChange(date, props.name)}
          autoOk
          name={props.name}
          disabled={props.disabled}
          variant="inline"
          format={'HH:mm'}
          margin="normal"
          placeholder={props.placeholder ? props.placeholder : ""}
          KeyboardButtonProps={{
            tabIndex: -1,
            "aria-label": "change time",
          }}
          inputProps={{ tabIndex: props.tabIndex }}
          role="application"
          aria-hidden="true"
          keyboardIcon={<AccessTime/>}
        />
      </MuiPickersUtilsProvider>
    </StyledDivDatePicker>
  );
}

export default BNPTimePicker;
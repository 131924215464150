import React, { useEffect, useMemo, useState } from "react";
import { BNPModal } from "../../../../shared/BNPModal/BNPModal";
import { StyledButtonSubmit, StyledPTitle } from "../../../../shared/styled";
import {
  getPremiumPaymentProductProviders,
  getPremiumPaymentProducts,
  getPremiumPaymentProviders,
} from "../../../../../services/premium-payment-services";
import { BNPSelect, Option } from "../../../../../cores/helpers/select/select";
import { BNPInput } from "../../../../../cores/helpers/input/input";
import { ValidatorForm } from "react-material-ui-form-validator";
import { addLoading, removeLoading } from "../../../../../cores/utils/loading";
import {centToUsd, usdToCent} from "../../../../../cores/helpers/cent-to-usd";
import {PremiumInsuranceType} from "./premium-insurance-model";
import {BNPMultiSelect} from "../../../../../cores/helpers/input/BNPMultiSelect";
import {getEmployeeOptions} from "../../../../../services/partner-service";

type PremiumInsuranceModalProps = {
  currentSubscription: PremiumInsuranceType[]
  data: {
    productId: number;
    providerId: number;
  };
  open: boolean;
  setOpen: any;
  handleClose: () => void;
  handleSubmit: (request: any) => void;
  handleSubmitRequestToQuote: (request: any) => void;
};

export function PremiumInsuranceModal(props: PremiumInsuranceModalProps) {
  const [model, setModel] = useState<any>({
    providerId: -1,
    productId: -1,
    productProviderModels: [],
    employerId: new URLSearchParams(window.location.search).get("id")
  });
  const [products, setProducts] = useState<Option[]>([])
  const [providers, setProviders] = useState<Option[]>([])
  const [isEdit, setIsEdit] = useState<boolean>(props.data.productId !== -1 && props.data.providerId !== -1)
  const [employeeOptions, setEmployeeOptions] = useState<Option[]>([])

  useEffect(() => {
    getEmployeeOptions(model.employerId)
      .then((res) => {
        setEmployeeOptions(res.data)
      })
  }, []);

  useEffect(() => {
    if (props.data.productId === -1 || props.data.providerId === -1) {
      getProducts().then()
    } else {
      setIsEdit(true);
      setModel((prev: any) => ({...prev, productId: props.data.productId, providerId: props.data.providerId}))
      getProducts().then()
      getProviders(props.data.productId).then()
      getProductProviders(props.data.productId, props.data.providerId).then()
    }
  }, [props.data.providerId, props.data.productId, props.currentSubscription])

  const resetData = () => {
    setProviders([]);
    setModel({
      providerId: -1,
      productId: -1,
      productProviderModels: [],
      employerId: new URLSearchParams(window.location.search).get("id")
    })
    setIsEdit(false)
  }

  const getProviders = async (productId: number) => {
    addLoading();
    const result = await getPremiumPaymentProviders(productId);
    setProviders(result.data);
    removeLoading();
  }

  const getProducts = async () => {
    addLoading();
    const result = await getPremiumPaymentProducts()
    const filteredProduct = isEdit ? result.data :
        result.data.filter((item: Option) => !props.currentSubscription
            .some(curr => curr.productId === item.id))
    setProducts(filteredProduct)
    removeLoading();
  }

  const getProductProviders = async (productId: number, providerId: number) => {
    addLoading();
    const result = await getPremiumPaymentProductProviders(productId, providerId);
    const productProviderModels = result.data.map((item: any) => ({...item, monthlyAmount: centToUsd(item.monthlyAmount)}));
    setModel((prev: any) => ({...prev, productProviderModels}))
    removeLoading();
  }

  const handleChangeSelect = (event: any) => {
    const { name, value } = event.target;

    if (name === "product") {
      setModel({
        ...model,
        productId: value,
        providerId: -1,
        productProviderModels: [],
        maxInsured: 6500,
        excludeInsurances: [],
        excludeEmployeeIds: [],
      })
      getProviders(value).then()
    }

    if (name === "provider") {
      setModel({
        ...model,
        providerId: value
      });
      getProductProviders(model.productId, value).then()
    }
  }

  const handleChangeExcludeInsurance = (event: any) => {
    const { value } = event.target;
    setModel((prev: any) => ({...prev, excludeInsurances: value}))
  }

  const handleChangeExcludeEmployee = (event: any) => {
    const { value } = event.target;
    setModel((prev: any) => ({...prev, excludeEmployeeIds: value}))
  }

  const handleChangeMaxInsured = (event: any) => {
    const { value } = event.target;
    setModel((prev: any) => ({...prev, maxInsured: value}))
  }

  const handleChangeInput = (event: any, productProviderId: number) => {
    const { name, value } = event.target;
    const productProviderIndex = model.productProviderModels.findIndex((item: any) => item.id === productProviderId);
    if (productProviderIndex !== -1 && name === "monthlyAmount") {
      const productProviderModelTemp = [...model.productProviderModels];
      productProviderModelTemp[productProviderIndex].monthlyAmount = value
      setModel((prev: any) => ({...prev, productProviderModels: productProviderModelTemp}))
    }
  }

  const handleSubmit = () => {
    const employerId = model.employerId;
    const modelForSubmit = model.productProviderModels.map((item: any) => ({
      employerId,
      productProviderId: item.id,
      monthlyAmount: usdToCent(item.monthlyAmount),
      excludeInsurances: model.excludeInsurances,
      excludeEmployeeIds: model.excludeEmployeeIds,
      maxInsured: !!model.maxInsured ? usdToCent(model.maxInsured) : null
    }))

    if (isEdit) {
      props.handleSubmitRequestToQuote(modelForSubmit);
    } else {
      props.handleSubmit(modelForSubmit);
    }
    resetData();
  }

  const handleClose = () => {
    resetData();
    props.handleClose();
  }

  const isValid = useMemo(() => {
    const {providerId, productId, productProviderModels} = model;
    return providerId > 0 &&
      productId > 0 &&
      productProviderModels.every((item: any) => item.productProviderId > 0 && item.monthlyAmount > 0)
  }, [model.providerId, model.productId, model.monthlyAmount])

  const modalBody = <ValidatorForm onSubmit={props.handleSubmit} debounceTime={300}>
        <div className="row">
          <div className="col-md-6 mb-4">
            <StyledPTitle className="mb-2">Product</StyledPTitle>
            <BNPSelect
              name="product"
              placeholder={"Product"}
              options={products}
              value={model.productId}
              onChange={handleChangeSelect}
              disabled={isEdit}
            />
          </div>

          <div className="col-md-6 mb-4">
            <StyledPTitle className="mb-2">Provider</StyledPTitle>
            <BNPSelect
              name="provider"
              placeholder={"Provider"}
              options={providers}
              value={model.providerId}
              onChange={handleChangeSelect}
              disabled={isEdit}
            />
          </div>

          {model.productProviderModels.map((model: any) => (
              <>
                <div className="col-md-6 mb-4">
                  <StyledPTitle className="mb-2">Type</StyledPTitle>
                  <BNPInput
                      name="calculationType"
                      value={model.calculationType}
                      type="string"
                      disabled={true}
                  />
                </div>
                <div className="col-md-6 mb-4">
                  <StyledPTitle className="mb-2">Monthly Amount</StyledPTitle>
                  <BNPInput
                      name="monthlyAmount"
                      placeholder={"Monthly Amount"}
                      value={model.monthlyAmount || ""}
                      onChange={e => handleChangeInput(e, model.id)}
                      type="number"
                  />
                </div>
              </>
          ))}

          {model.productId === 3 && (
            <div className="col-12 mb-4">
              <StyledPTitle className="mb-2">Exclude Insurance</StyledPTitle>
              <BNPMultiSelect
                value={model.excludeInsurances || []}
                name="excludeInsurance"
                placeholder={"Exclude Insurance"}
                options={[
                  {id: 'LIFE', name: "Life"},
                  {id: 'AD_D', name: "AD&D"},
                  {id: 'DEPENDENT', name: "Dependent Life"},
                ]}
                onChange={handleChangeExcludeInsurance}
                allowAll={false}
              />
            </div>
          )}

          {model.productId === 4 && (
            <div className="col-12 mb-4">
              <StyledPTitle className="mb-2">Max Insured</StyledPTitle>
              <BNPInput
                name="maxInsured"
                placeholder={"Max Insured"}
                value={model.maxInsured || ""}
                type="number"
                onChange={handleChangeMaxInsured}
              />
            </div>
          )}

          {[3,4,5].includes(model.productId) && (
            <div className="col-12 mb-4">
              <StyledPTitle className="mb-2">Exclude Employee</StyledPTitle>
              <BNPMultiSelect
                value={model.excludeEmployeeIds || []}
                name="excludeEmployee"
                placeholder={"Exclude Employee"}
                options={employeeOptions}
                onChange={handleChangeExcludeEmployee}
                allowAll={false}
              />
            </div>
          )}

        </div>
      </ValidatorForm>

  return (
    <BNPModal
      open={props.open}
      title="Insurance Premium"
      handleClose={handleClose}
      body={modalBody}
      footer={
        <div className="col-md-4 p-0">
          <StyledButtonSubmit
            disabled={!isValid}
            onClick={handleSubmit}
            submit={isValid}
            type="submit"
          >
            Save
          </StyledButtonSubmit>
        </div>
      }
    />
  );
}

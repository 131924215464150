import * as React from 'react';
import {BNPModal} from "../../../../shared/BNPModal/BNPModal";
import {
  StyledButtonSubmit,
  StyledExpansionPanelDetails,
  StyledExpansionPanelSummary,
  StyledHideForDesktop,
  StyledHideForMobile,
  StyledTitleExpansion,
} from "../../../../shared/styled";
import {useEffect, useState} from "react";
import {BNPInput} from "../../../../../cores/helpers/input/input";
import {ValidatorForm} from "react-material-ui-form-validator";
import {CommissionRate, CommissionRateTableColumns} from "./CommissionRateManagementModel";
import {
  StyledBodyTable,
  StyledCellTable,
  StyledHeaderTable,
  StyledTable
} from "../../../../shared/table-styled";
import styled from "styled-components";
import BNPDatePicker from "../../../../../cores/helpers/date-picker/date-picker";
import moment from "moment";
import {ArrowDropUp} from "@material-ui/icons";
import {ExpansionPanel} from "@material-ui/core";
import {round} from "lodash";

type Props = {
  open: boolean;
  title: string;
  handleClose: () => void;
  handleSubmit: (data: any) => void;
  items: CommissionRate[];
};
const StyledOverflowTableWrapper = styled.div`
  width: 100%;
  display: block;
  overflow-x: auto;
  white-space: nowrap;
`;

const StyledOverflowTable = styled.div`
`;

const StyledCellTableCustom = styled(StyledCellTable)`
  :last-child {
      text-align: unset;
  }
`

const PercentageWrapper = styled.span`
    position: relative;
    display: inline-block;
    ::after {
        content: '%';
        position: absolute;
        right: 5px;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 20px;
        line-height: 20px;
    }
`

const PercentageInput = styled(BNPInput)`
    display: inline-block;
    width: 100%;
    padding-right: 20px;
    
`

export const CommissionRateManagementModal = (props: Props) => {
  const [isValid, setValid] = useState<boolean>(false)
  const [models, setModels] = useState<CommissionRate[]>([])

  useEffect(() => {
    setModels(props.items.map((item) => ({
      ...item,
      beniplusRate: round((10000 - item.partnerRate)/100,2),
      partnerRate: round(item.partnerRate/100, 2),
    })));
  }, [props.items, props.open]);

  useEffect(() => {
    // check data is valid
    const isValid = models.every((item) => {
      return item.partnerRate >= 0 && item.partnerRate <= 100 &&
        (item.fromDate == null || item.toDate == null || (
          moment(item.fromDate) <= moment(item.toDate)
        ))
    });
    setValid(isValid)
  }, [models]);

  const handleClose = () => {
    resetData();
    props.handleClose();
  }

  const resetData = () => {
    setValid(false);
    setModels([])
  }

  const handleSubmit = () => {
    const submissionModels: CommissionRate[] = models.map((item) => ({
      enrollmentId: item.enrollmentId,
      partnerRate: item.partnerRate * 100,
      fromDate: item.fromDate,
      toDate: item.toDate,
    }))
    props.handleSubmit(submissionModels);
    resetData();
    props.handleClose();
  }

  const handleChangeFromDate = (date: Date | null, enrollmentId: number) => {
    const newModels = models.map((item) => {
      if (item.enrollmentId === enrollmentId) {
        if (date === null) {
          item.fromDate = null;
        } else {
          item.fromDate = moment(date).toISOString(true).split('T')[0];
        }
      }
      return item;
    });
    setModels(newModels);
  }

  const handleChangeToDate = (date: Date | null, enrollmentId: number) => {
    const newModels = models.map((item) => {
      if (item.enrollmentId === enrollmentId) {
        if (date === null) {
          item.toDate = null;
        } else {
          item.toDate = moment(date).toISOString(true).split('T')[0];
        }
      }
      return item;
    });
    setModels(newModels);
  }

  const handleChangeInput = (e: any, enrollmentId: number) => {
    const { name, value } = e.target;
      const newModels = models.map((item) => {
        if (item.enrollmentId === enrollmentId) {
          if (name === 'partnerRate') {
            if (0 <= value && value <= 100) {
              item.partnerRate = value;
              item.beniplusRate = (100 - value);
            }
          } else if (name === 'beniplusRate') {
            if (0 <= value && value <= 100) {
              item.beniplusRate = value;
              item.partnerRate = 100 - value;
            }
          }
        }
        return item;
      });
    setModels(newModels);
  }

  const tableItems = models.sort((a, b) => a.enrollmentId - b.enrollmentId)
    .map((item) => ({
      ...item,
      beniplusRate: item.beniplusRate,
      partnerRate: item.partnerRate,
    }));
  const columnsById = CommissionRateTableColumns.reduce((acc:any, col) => {
    acc[col.columnId] = col;
    return acc;
  }, {"partnerSplit": {}, "beniplusRate": {}, "fromDate": {}, "toDate": {}});

  const modalBody = <ValidatorForm onSubmit={props.handleSubmit} debounceTime={300}>

    <div className="row">
      <div className="col-lg-12 mt-4">
        <StyledHideForMobile>
          <StyledTable>
            <StyledOverflowTableWrapper>
              <StyledOverflowTable style={{width: "100%"}}>
                <StyledHeaderTable>
                  {CommissionRateTableColumns.map((column, index) => (
                    <StyledCellTableCustom
                      width={column.width}
                      key={index}
                      className="cur"
                    >
                      {column.columnName}

                    </StyledCellTableCustom>
                  ))}
                </StyledHeaderTable>
                <div>
                  {tableItems.map((item, i) =>
                    (
                      <StyledBodyTable key={`bnp_table_row_${i}`}>
                        <StyledCellTable key={`bnp_table_row_${i}_enrollment_name`}
                                         width={columnsById["enrollmentName"].width}>
                          {item.enrollmentName}
                        </StyledCellTable>
                        <StyledCellTable key={`bnp_table_row_${i}_beniplus_split`}
                                         width={columnsById["beniplusRate"].width}>
                          <PercentageWrapper>
                            <PercentageInput
                              name={`beniplusRate`}
                              value={item.beniplusRate}
                              onChange={(e) => handleChangeInput(e, item.enrollmentId)}
                              type="string"
                            />
                          </PercentageWrapper>
                        </StyledCellTable>
                        <StyledCellTable key={`bnp_table_row_${i}_partner_split`}
                                         width={columnsById["partnerRate"].width}>
                          <PercentageWrapper>
                            <PercentageInput
                              name="partnerRate"
                              value={item.partnerRate}
                              onChange={(e) => handleChangeInput(e, item.enrollmentId)}
                              type="string"
                            />
                          </PercentageWrapper>
                        </StyledCellTable>
                        <StyledCellTable key={`bnp_table_row_${i}_from_date`} width={columnsById["fromDate"].width}>
                          <BNPDatePicker
                            name="fromDate"
                            value={item.fromDate}
                            onChange={(e) => handleChangeFromDate(e, item.enrollmentId)}
                          />
                        </StyledCellTable>
                        <StyledCellTable key={`bnp_table_row_${i}_to_date`} width={columnsById["toDate"].width}>
                          <BNPDatePicker
                            name="toDate"
                            value={item.toDate}
                            onChange={(e) => handleChangeToDate(e, item.enrollmentId)}
                          />
                        </StyledCellTable>
                      </StyledBodyTable>
                    ))}
                </div>
              </StyledOverflowTable>
            </StyledOverflowTableWrapper>
          </StyledTable>
        </StyledHideForMobile>

        <StyledHideForDesktop>
          {tableItems.map((item, i) => (
            <ExpansionPanel
              square
              defaultExpanded={false}
              className="mt-2"
              key={`bnp_mobile_table_row_${i}`}
            >
              <StyledExpansionPanelSummary
                className="m-0"
                aria-controls="panel1d-content"
                id="panel1d-header"
                expandIcon={<ArrowDropUp/>}
              >
                <div className="w-100">
                  <StyledTitleExpansion>{item.enrollmentName}</StyledTitleExpansion>
                  {/*<div className="mt-2">{item[props.columns[1].columnId]}</div>*/}

                </div>
              </StyledExpansionPanelSummary>
              <StyledExpansionPanelDetails>
                <div key={`bnp_mobile_table_row_${i}_beniplus_split`} className="w-50 mt-2">
                  <StyledTitleExpansion>Beniplus Split</StyledTitleExpansion>
                  <div className="mt-1">
                    <BNPInput
                      name={`beniplusRate`}
                      value={item.beniplusRate}
                      onChange={(e) => handleChangeInput(e, item.enrollmentId)}
                      type="string"
                    />
                  </div>
                </div>
                <div key={`bnp_mobile_table_row_${i}_partner_split`} className="w-50 mt-2">
                  <StyledTitleExpansion>Partner Split</StyledTitleExpansion>
                  <div className="mt-1">
                    <BNPInput
                      name="partnerRate"
                      value={item.partnerRate}
                      onChange={(e) => handleChangeInput(e, item.enrollmentId)}
                      type="string"
                    />
                  </div>
                </div>
                <div key={`bnp_mobile_table_row_${i}_from_date`} className="w-100 mt-2">
                  <StyledTitleExpansion>From Date</StyledTitleExpansion>
                  <div className="mt-1">
                    <BNPDatePicker
                      name="fromDate"
                      value={item.fromDate}
                      onChange={(e) => handleChangeFromDate(e, item.enrollmentId)}
                    />
                  </div>
                </div>
                <div key={`bnp_mobile_table_row_${i}_to_date`} className="w-100 mt-2">
                  <StyledTitleExpansion>To Date</StyledTitleExpansion>
                  <div className="mt-1">
                    <BNPDatePicker
                      name="toDate"
                      value={item.toDate}
                      onChange={(e) => handleChangeToDate(e, item.enrollmentId)}
                    />
                  </div>
                </div>

                <hr/>
              </StyledExpansionPanelDetails>
            </ExpansionPanel>
          ))}
        </StyledHideForDesktop>
      </div>
    </div>

  </ValidatorForm>

  return (
    <BNPModal
      open={props.open}
      title={props.title}
      handleClose={handleClose}
      body={modalBody}
      footer={<div className="col-md-4 p-0">
        <StyledButtonSubmit
          disabled={!isValid}
          onClick={handleSubmit}
          submit={isValid}
          type="submit"
        >
          Save
        </StyledButtonSubmit>
      </div>}
    />
  );
};
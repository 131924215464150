import React, {useEffect} from "react";
import Pagination, {
  PagingInfo, setPagingInfo,
} from "../../../../cores/helpers/pagination/pagination";
import {closeConfirmDialog, openConfirmDialog} from "../../../../cores/utils/confirmDialog";
import {addLoading, removeLoading} from "../../../../cores/utils/loading";
import {
  getEFTEmployeeBlacklist,
  getEFTEmployerBlacklist,
  postEFTEmployeeBlacklist,
  postEFTEmployerBlacklist,
  updateEFTEmployeeBlacklist,
  updateEFTEmployerBlacklist,
} from "../../../../services/eft-service";
import {TableSortType} from "../../../shared/BNPTable/BNPTableType";
import BNPRawTable from "../../../shared/BNPTable/BNPRawTable";
import {
  EFTBlacklistAddRequest,
  EFTBlacklistResponse,
  EFTBlacklistSearchParam
} from "./eft-blacklist-type";
import {BlacklistEmployeeTableColumns, BlacklistEmployerTableColumns} from "./eft-blacklist-model";
import {StyledButtonSubmit, StyledLargerTitle} from "../../../shared/styled";
import AddIcon from "@material-ui/icons/Add";
import {StyledSpanAction} from "../../employees-management/employee-action";
import {EftBlacklistAddNewModal} from "./eft-blacklist-add-new";
import styled from "styled-components";
import {EftBlacklistRemoveModal} from "./eft-backlist-remove-modal";

const StyledButtonDisable = styled(StyledSpanAction)`
  color: ${props => props.theme.errorColor};
`;

type Props = {};

type EFTBlacklistResponseTable = EFTBlacklistResponse & {
  action: JSX.Element;
}

export default function EftBlacklist({}: Props) {
  const [employeeSearchParams, setEmployeeSearchParams] = React.useState<EFTBlacklistSearchParam>({
    searchKey: "",
    page: 1,
    perPage: 10,
    columnName: "",
    sortType: null
  })
  const [employerSearchParams, setEmployerSearchParams] = React.useState<EFTBlacklistSearchParam>({
    searchKey: "",
    page: 1,
    perPage: 10,
    columnName: "",
    sortType: null
  })
  const [employeeBlacklist, setEmployeeBlackList] = React.useState<EFTBlacklistResponseTable[]>([]);
  const [employeeBlackListColumns, setEmployeeBlackListColumns] = React.useState<TableSortType[]>([...BlacklistEmployeeTableColumns]);
  const [employeeBlackListPaging, setEmployeeBlackListPaging] = React.useState<PagingInfo>(setPagingInfo(1, 0, 10));
  const [employerBlacklist, setEmployerBlackList] = React.useState<EFTBlacklistResponseTable[]>([]);
  const [employerBlackListColumns, setEmployerBlackListColumns] = React.useState<TableSortType[]>([...BlacklistEmployerTableColumns]);
  const [employerBlackListPaging, setEmployerBlackListPaging] = React.useState<PagingInfo>(setPagingInfo(1, 0, 10));
  const [openAddNewModal, setOpenAddNewModal] = React.useState<"employeeBlacklist" | "employerBlacklist" | "">("");
  const [openRemoveModal, setOpenRemoveModal] = React.useState<"employeeBlacklist" | "employerBlacklist" | "">("");
  const [removeItem, setRemoveItem] = React.useState<EFTBlacklistResponse | null>(null);

  useEffect(() => {
    addLoading();
    fetchEmployeeBlacklist(employeeSearchParams).then(() => removeLoading());
  }, [employeeSearchParams]);

  useEffect(() => {
    addLoading();
    fetchEmployerBlacklist(employerSearchParams).then(() => removeLoading());
  }, [employerSearchParams]);

  async function fetchEmployeeBlacklist(searchParam: EFTBlacklistSearchParam) {
    const result = await getEFTEmployeeBlacklist(searchParam);
    setEmployeeBlackList(convertTableData(result.data.records, "employeeBlacklist"));
    setEmployeeBlackListPaging(setPagingInfo(searchParam.page, result.data.pageCount, searchParam.perPage));
  }

  async function fetchEmployerBlacklist(searchParam: EFTBlacklistSearchParam) {
    const result = await getEFTEmployerBlacklist(searchParam);
    setEmployerBlackList(convertTableData(result.data.records, "employerBlacklist"));
    setEmployerBlackListPaging(setPagingInfo(searchParam.page, result.data.pageCount, searchParam.perPage));
  }

  const convertTableData = (data: EFTBlacklistResponse[], type: "employeeBlacklist" | "employerBlacklist"): EFTBlacklistResponseTable[] => {
    return data.map((item) => {
      return {
        ...item,
        action: <>
          <StyledButtonDisable
            title="Remove"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setRemoveItem(item);
              setOpenRemoveModal(type);
            }}
          >
            Remove
          </StyledButtonDisable>
        </>
      };
    });
  }

  const setRowsPerPage = (event: any, tableName: string) => {
    const { value } = event.target;
    if (tableName === 'employeeBlacklist') {
      setEmployeeSearchParams((prevState: EFTBlacklistSearchParam) => (
        {...prevState, page: 1, perPage: Number(value)}
      ));
    }
    if (tableName === 'employerBlacklist') {
      setEmployerSearchParams((prevState: EFTBlacklistSearchParam) => (
        {...prevState, page: 1, perPage: Number(value)}
      ));
    }
  };

  const handleChangePage = (page: number, tableName: string) => {
    if (tableName === 'employeeBlacklist') {
      setEmployeeSearchParams((prevState: EFTBlacklistSearchParam) => ({...prevState, page}));
    }
    if (tableName === 'employerBlacklist') {
      setEmployerSearchParams((prevState: EFTBlacklistSearchParam) => ({...prevState, page}));
    }
  };

  const sortTable = (columnId: string, tableName: string) => {
    if (tableName === 'employeeBlacklist') {
      const columnsHeaderTable: TableSortType[] = Object.assign([], employeeBlackListColumns);
      const index = columnsHeaderTable.findIndex((column) => {
        return column.columnId === columnId;
      });

      columnsHeaderTable.forEach((column, i) => {
        if (i === index) {
          column.sortType = column.sortType === "ASC" ? "DESC" : "ASC";
          return;
        }
        column.sortType = null;
      });

      setEmployeeSearchParams((prevState: EFTBlacklistSearchParam) => (
        {...prevState, columnName: columnId, sortType: columnsHeaderTable[index].sortType}));
      setEmployeeBlackListColumns(columnsHeaderTable);
    }
    if (tableName === 'employerBlacklist') {
      const columnsHeaderTable: TableSortType[] = Object.assign([], employerBlackListColumns);
      const index = columnsHeaderTable.findIndex((column) => {
        return column.columnId === columnId;
      });

      columnsHeaderTable.forEach((column, i) => {
        if (i === index) {
          column.sortType = column.sortType === "ASC" ? "DESC" : "ASC";
          return;
        }
        column.sortType = null;
      });

      setEmployerSearchParams((prevState: EFTBlacklistSearchParam) => (
        {...prevState, columnName: columnId, sortType: columnsHeaderTable[index].sortType}));
      setEmployerBlackListColumns(columnsHeaderTable);
    }
  };

  const handleRemoveBlacklist = (item: EFTBlacklistResponse,
                                       type: "employeeBlacklist" | "employerBlacklist") => {
    openConfirmDialog({
      id: `remove-eft-blacklist-${item.userId}`,
      open: true,
      title: `Remove ${item.userName} from EFT Blacklist`,
      content: `Are you sure you want to remove ${item.userName} from EFT Blacklist?`,
      onClose: (isOk) => handleCloseRemoveBlacklist(isOk, item, type)
    })
  }

  const handleCloseRemoveBlacklist = (isOk: boolean, item: EFTBlacklistResponse, type: "employeeBlacklist" | "employerBlacklist") => {
    if (isOk) {
      if (type === "employeeBlacklist") {
        addLoading();
        updateEFTEmployeeBlacklist({...item, enabled: false}).then(() => {
          return fetchEmployeeBlacklist(employeeSearchParams);
        }).finally(() => {
          closeConfirmDialog();
          setOpenRemoveModal("");
          setRemoveItem(null);
          removeLoading();
        })
      }
      if (type === "employerBlacklist") {
        addLoading();
        updateEFTEmployerBlacklist({...item, enabled: false}).then(() => {
          return fetchEmployerBlacklist(employeeSearchParams);
        }).finally(() => {
          closeConfirmDialog();
          setOpenRemoveModal("");
          setRemoveItem(null);
          removeLoading();
        });
      }
    } else {
      closeConfirmDialog();
      setOpenRemoveModal("");
      setRemoveItem(null);
    }
  }

  const handleAddNewToBlacklist = async (request: EFTBlacklistAddRequest, type: "employeeBlacklist" | "employerBlacklist") => {
    openConfirmDialog({
      id: `add-new-to-${type}`,
      open: true,
      title: `Add New ${type === "employeeBlacklist" ? "Employee" : "Employer"} to EFT Blacklist`,
      content: `Are you sure you want to add new ${type === "employeeBlacklist" ? "Employee" : "Employer"} to EFT Blacklist?`,
      onClose: (isOk) => {
        if (!isOk) {
          closeConfirmDialog();
          return;
        }
        if (type === "employeeBlacklist") {
          handleAddNewEmployeeToBlacklist(request);
        }
        if (type === "employerBlacklist") {
          handleAddNewEmployerToBlacklist(request);
        }
      }
    })
  }

  const handleAddNewEmployeeToBlacklist = (request: EFTBlacklistAddRequest) => {
    addLoading();
    postEFTEmployeeBlacklist(request).then(() => {
      return fetchEmployeeBlacklist(employeeSearchParams);
    }).then(() => {
      closeConfirmDialog();
      setOpenAddNewModal("");
      removeLoading();
    });
  }

  const handleAddNewEmployerToBlacklist = (request: EFTBlacklistAddRequest) => {
    addLoading();
    postEFTEmployerBlacklist(request).then(() => {
      return fetchEmployerBlacklist(employeeSearchParams);
    }).then(() => {
      closeConfirmDialog();
      setOpenAddNewModal("");
      removeLoading();
    });
  }

  return (
    <>
    <div className="row justify-content-center">

      <div className="col-12">
        <div className="row justify-content-between align-content-center">
          <div className="col-lg-4 col-md-6">
            <StyledLargerTitle>Employer Blacklist</StyledLargerTitle>
          </div>
          <div className="col-xl-2 col-lg-3 col-md-4">
            <StyledButtonSubmit
              submit={true}
              onClick={() => setOpenAddNewModal("employerBlacklist")}
            >
              <AddIcon className="icon"/>
              Add New Employer
            </StyledButtonSubmit>
          </div>
        </div>
        <BNPRawTable
          columns={employerBlackListColumns}
          tableWidth='100%'
          tableData={employerBlacklist}
          tableName={"employerBlacklist"}
          handleChangeSortColumn={(columnId) => sortTable(columnId, 'employerBlacklist')}
        />
        <div className="row mt-4">
          <div className="col-12 text-center">
            <label>
              <Pagination
                pagerPagination={employerBlackListPaging}
                getPage={(page: number) => handleChangePage(page, 'employerBlacklist')}
                onChangeRowsPerPage={(event: any) => setRowsPerPage(event, 'employerBlacklist')}
              />
            </label>
          </div>
        </div>
      </div>

      <div className="col-12 mt-5">
        <div className="row justify-content-between align-content-center">
          <div className="col-lg-4 col-md-6">
            <StyledLargerTitle>Employee Blacklist</StyledLargerTitle>
          </div>
          <div className="col-xl-2 col-lg-3 col-md-4">
            <StyledButtonSubmit
              submit={true}
              onClick={() => setOpenAddNewModal("employeeBlacklist")}
            >
              <AddIcon className="icon"/>
              Add New Employee
            </StyledButtonSubmit>
          </div>
        </div>
        <BNPRawTable
          columns={employeeBlackListColumns}
          tableWidth='100%'
          tableData={employeeBlacklist}
          tableName={"employeeBlacklist"}
          handleChangeSortColumn={(columnId) => sortTable(columnId, 'employeeBlacklist')}
        />
        <div className="row mt-4">
          <div className="col-12 text-center">
            <label>
              <Pagination
                pagerPagination={employeeBlackListPaging}
                getPage={(page: number) => handleChangePage(page, 'employeeBlacklist')}
                onChangeRowsPerPage={(event: any) => setRowsPerPage(event, 'employeeBlacklist')}
              />
            </label>
          </div>
        </div>
      </div>
    </div>
    <EftBlacklistAddNewModal
      type={openAddNewModal}
      open={!!openAddNewModal}
      handleClose={() => {
        setOpenAddNewModal("");
      }}
      handleAdd={handleAddNewToBlacklist}
    />
    <EftBlacklistRemoveModal
      removeItem={removeItem}
      type={openRemoveModal}
      open={!!openRemoveModal}
      handleClose={() => {
        setOpenRemoveModal("");
      }}
      handleRemove={handleRemoveBlacklist}
    />
    </>
  );
}

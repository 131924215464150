import React from "react";
import styled from "styled-components";
import {
  StyledInputMask,
  StyledButtonSubmit,
  StyledPTitle,
  StyledFormControlLabel,
} from "../../../shared/styled";
import { BNPInput } from "../../../../cores/helpers/input/input";
import { BNPSelect } from "../../../../cores/helpers/select/select";
import GoogleLocationInput, {
  ResponseLocation,
} from "../../../../cores/helpers/google-map-services/google-location";
import { Radio, RadioGroup } from "@material-ui/core";
import BNPDatePicker from "../../../../cores/helpers/date-picker/date-picker";
import { convertTimeZone } from "../../../../cores/utils/helpers";
import { FE_DETAIL_DATE_FORMAT } from "../../../../cores/utils/format/date-time-format";
import { Provinces } from "../../company-management/company-management-type";
import { PartnerDetail } from "../partner-management-type";
import { PartnerTypes, partnerTypeOptions } from "../partner-management-model";
import { CheckBox } from "../../../../cores/helpers/checkbox/checkbox";
import {BNPModal} from "../../../shared/BNPModal/BNPModal";

const StyledSpanErrorMessage = styled.span`
  color: ${(props) => props.theme.errorColor};
  font-size: 14px;
`

const StyledDescription = styled(RadioGroup)`
  flex-direction: row !important;
  width: 100%;
  .MuiFormControlLabel-label {
    font-size: 14px;
  }
  .MuiFormControlLabel-root {
    margin-right: 20px;
    margin-bottom: 0;
  }
`

const StyledBreakDiv = styled.div`
  height: 1px;
  width: 100%;
  background-color: #C2C7D0;
`

const StyledAgenda = styled.div`
  font-size: 18px;
  line-height: 21px;
  color: #0F3047;
`

const StyledCancelButton = styled(StyledButtonSubmit)`
  background-color: transparent;
  border: 1px solid ${(props) => props.theme.primaryColor};
  color: ${(props) => props.theme.primaryColor};
`

const StyledCustomSubmitButton = styled(StyledButtonSubmit)`
`

type Props = {
  partnerData: PartnerDetail;
  isCreate: boolean;
  handleSubmit: (data: any) => void;
  open: boolean;
  closePartnerModal: () => void;
  isSubmitValid: boolean;
  errorMessage: string;
  provinces: Provinces[];
  validateAndSetValue: (data: PartnerDetail) => void;
};


export default function PartnerModal(props: Props) {


  const { partnerData } = props;
  const isIndividualPartner = partnerData.type === PartnerTypes.INDIVIDUAL;


  const handleChangePartnerType = (event: any) => {

    const { value } = event.target;
    let temp: PartnerDetail = { ...partnerData };

    temp.type = value;

    props.validateAndSetValue(temp);

  }

  const handleChangeSameAsBillingCheckbox = (event: any) => {
    const { checked } = event.target;
    let temp: PartnerDetail = { ...partnerData };

    temp.partnerLocation.isMailingSameAsBillingAddress = checked;

    props.validateAndSetValue(temp);
  }

  const handleChangePartnerLicensed = (e: any) => {
    const { value } = e.target;
    const temp: PartnerDetail = { ...partnerData };
    temp.isLicensed = value === "true" ? true : false;

    props.validateAndSetValue(temp);
  }

  const handleChangeInput = (event: any) => {
    let { name, value } = event.target;
    let temp: any = { ...partnerData };

    temp[name] = value;

    props.validateAndSetValue(temp);

  };


  const handleChangeDate = (date: Date | null, name: string) => {
    let temp: any = { ...partnerData };

    if (null == temp.partnerLicenseAgentInformation) {
      temp.partnerLicenseAgentInformation = {};
    }

    temp.partnerLicenseAgentInformation[name] = date ? new Date(date) : null;

    props.validateAndSetValue(temp);
  };

  const handleChangeAddressInput = (event: any) => {
    let { name, value } = event.target;
    let temp: any = { ...partnerData };

    if (value === -1) value = null;
    temp.partnerLocation[name] = value;

    props.validateAndSetValue(temp);

  };

  const handleChangeLicenseInput = (event: any) => {
    let { name, value } = event.target;
    let temp: any = { ...partnerData };

    if (null == temp.partnerLicenseAgentInformation) {
      temp.partnerLicenseAgentInformation = {};
    }
    temp.partnerLicenseAgentInformation[name] = value;

    props.validateAndSetValue(temp);

  };

  const findProvinceIdByName = (provinceName: string, provinces: Provinces[]) => {
    let province = provinces.find((f) => f.name === provinceName);
    return province ? province.id : -1;
  };

  const setBillingAddress = (bilingAddress: string) => {
    let temp: PartnerDetail = { ...partnerData };
    temp.partnerLocation.billingStreetAddress = bilingAddress;

    props.validateAndSetValue(temp);
  };

  const setBillingLocation = (location: ResponseLocation) => {
    let temp: PartnerDetail = { ...partnerData };

    temp.partnerLocation.billingCityName = location.city;
    temp.partnerLocation.billingPostcode = location.postcode ? location.postcode : "";
    temp.partnerLocation.billingProvinceId = findProvinceIdByName(location.province, props.provinces);

    props.validateAndSetValue(temp);

  };

  const setMailingAddress = (mailingAddress: string) => {
    let temp: PartnerDetail = { ...partnerData };
    temp.partnerLocation.mailingStreetAddress = mailingAddress;

    props.validateAndSetValue(temp);
  };

  const setMailingLocation = (location: ResponseLocation) => {
    let temp: PartnerDetail = { ...partnerData };

    temp.partnerLocation.mailingCityName = location.city;
    temp.partnerLocation.mailingPostcode = location.postcode ? location.postcode : "";
    temp.partnerLocation.mailingProvinceId = findProvinceIdByName(location.province, props.provinces);

    props.validateAndSetValue(temp);

  };

  const modalBody = (
    <div className="row">
      <div className="col-md-12">
        <StyledAgenda className="mt-3 mb-3 fw-bold">
          Partner Info*
        </StyledAgenda>
      </div>

      <div className="col-md-6 mb-4">
        <StyledPTitle className="mb-2">Partner Is?</StyledPTitle>
        <BNPSelect
          options={partnerTypeOptions}
          value={partnerData.type}
          name="type"
          ignore={true}
          onChange={handleChangePartnerType}
          tabIndex={1}
          disabled={!props.isCreate}
        />
      </div>
      {isIndividualPartner &&
          <div className="col-md-6 mb-4">
              <StyledPTitle className="mb-2">Social Insurance Number</StyledPTitle>
              <BNPInput
                  value={partnerData.socialInsuranceNumber ? partnerData.socialInsuranceNumber : ''}
                  name="socialInsuranceNumber"
                  onChange={handleChangeInput}
                  placeholder="Social Insurance Number"
                  tabIndex={2}
              />
          </div>
      }

      {!isIndividualPartner &&
          <>
              <div className="col-md-6 mb-4">
                  <StyledPTitle className="mb-2">Company Email</StyledPTitle>
                  <BNPInput
                      value={partnerData.companyEmail ? partnerData.companyEmail : ''}
                      name="companyEmail"
                      onChange={handleChangeInput}
                      placeholder="Company Email"
                      tabIndex={2}
                      disabled={!props.isCreate}
                  />
              </div>
              <div className="col-md-4 mb-4">
                  <StyledPTitle className="mb-2">Company Name</StyledPTitle>
                  <BNPInput
                      value={partnerData.companyName ? partnerData.companyName : ''}
                      name="companyName"
                      onChange={handleChangeInput}
                      placeholder="Company Name"
                  />
              </div>
              <div className="col-md-4 mb-4">
                  <StyledPTitle className="mb-2">Business Number</StyledPTitle>
                  <BNPInput
                      value={partnerData.businessNumber ? partnerData.businessNumber : ''}
                      name="businessNumber"
                      onChange={handleChangeInput}
                      placeholder="Business Number"
                  />
              </div>
              <div className="col-md-4 mb-4">
                  <StyledPTitle className="mb-2">Tax Number</StyledPTitle>
                  <BNPInput
                      value={partnerData.taxNumber ? partnerData.taxNumber : ''}
                      name="taxNumber"
                      onChange={handleChangeInput}
                      placeholder="Taxt Number"
                  />
              </div>
          </>
      }

      <div className="col-md-4 mb-4">
        <StyledPTitle className="mb-2">Contact First Name</StyledPTitle>
        <BNPInput
          value={partnerData.contactFirstName}
          name="contactFirstName"
          onChange={handleChangeInput}
          placeholder="Contact First Name"
        />
      </div>
      <div className="col-md-4 mb-4">
        <StyledPTitle className="mb-2">Contact Last Name</StyledPTitle>
        <BNPInput
          value={partnerData.contactLastName}
          name="contactLastName"
          onChange={handleChangeInput}
          placeholder="Contact Last Name"
        />
      </div>
      <div className="col-md-4 mb-4">
        <StyledPTitle className="mb-2">Contact Email</StyledPTitle>
        <BNPInput
          value={partnerData.contactEmail}
          name="contactEmail"
          onChange={handleChangeInput}
          placeholder="Email"
          tabIndex={3}
          disabled={!props.isCreate}
        />
      </div>

      <StyledBreakDiv />
      <div className="col-md-12">
        <StyledAgenda className="mt-3 mb-3 fw-bold">
          Billing Address*
        </StyledAgenda>
      </div>

      <div className="col-md-12 mb-4">
        <StyledPTitle className="mb-2">Street address</StyledPTitle>
        <GoogleLocationInput
          id="billingStressAddress"
          address={partnerData.partnerLocation.billingStreetAddress}
          setAddress={setBillingAddress}
          setLocation={setBillingLocation}
          placeholder="Street address"
          tabIndex={1}
        />
      </div>
      <div className="col-md-3 mb-4">
        <StyledPTitle className="mb-2">Province</StyledPTitle>
        <BNPSelect
          options={props.provinces.map((province) => ({
            id: province.id,
            name: province.name,
          }))}
          placeholder="Province"
          name="billingProvinceId"
          value={partnerData.partnerLocation.billingProvinceId || -1}
          onChange={handleChangeAddressInput}
          tabIndex={1}
        />
      </div>
      <div className="col-md-3 mb-4">
        <StyledPTitle className="mb-2">City</StyledPTitle>
        <BNPInput
          value={partnerData.partnerLocation.billingCityName}
          name="billingCityName"
          onChange={handleChangeAddressInput}
          placeholder="City"
          tabIndex={2}
        />
      </div>
      <div className="col-md-3 mb-4">
        <StyledPTitle className="mb-2">Postcode</StyledPTitle>
        <StyledInputMask
          mask="*** ***"
          value={partnerData.partnerLocation.billingPostcode ? partnerData.partnerLocation.billingPostcode.toUpperCase() : ""}
          onChange={handleChangeAddressInput}
          name="billingPostcode"
          placeholder="Postcode"
          tabIndex={3}
        />
      </div>
      <div className="col-md-3 mb-4">
        <StyledPTitle className="mb-2">Phone number</StyledPTitle>
        <StyledInputMask
          mask="999-999-9999"
          value={partnerData.partnerLocation.billingPhoneNumber}
          onChange={handleChangeAddressInput}
          name="billingPhoneNumber"
          placeholder="Phone number"
          tabIndex={4}
        />
      </div>

      <StyledBreakDiv />
      <div className="col-md-12">
        <StyledAgenda className="mt-3 mb-3 fw-bold">
          Mailing Address*
        </StyledAgenda>
      </div>

      <div className="col-md-12 mb-3">
        <CheckBox
          title="This Is The Same As The Billing Address"
          checked={partnerData.partnerLocation.isMailingSameAsBillingAddress}
          onChange={handleChangeSameAsBillingCheckbox}
          tabIndex={13}
        />
      </div>

      {!partnerData.partnerLocation.isMailingSameAsBillingAddress &&
          <>
              <div className="col-md-12 mb-4">
                  <StyledPTitle className="mb-2">Street address</StyledPTitle>
                  <GoogleLocationInput
                      id="mailingStressAddress"
                      address={partnerData.partnerLocation.mailingStreetAddress ? partnerData.partnerLocation.mailingStreetAddress : ''}
                      setAddress={setMailingAddress}
                      setLocation={setMailingLocation}
                      placeholder="Street address"
                      tabIndex={1}
                  />
              </div>
              <div className="col-md-3 mb-4">
                  <StyledPTitle className="mb-2">Province</StyledPTitle>
                  <BNPSelect
                      options={props.provinces.map((province) => ({
                        id: province.id,
                        name: province.name,
                      }))}
                      placeholder="Province"
                      name="mailingProvinceId"
                      value={partnerData.partnerLocation.mailingProvinceId || -1}
                      onChange={handleChangeAddressInput}
                      tabIndex={9}
                  />
              </div>
              <div className="col-md-3 mb-4">
                  <StyledPTitle className="mb-2">City</StyledPTitle>
                  <BNPInput
                      value={partnerData.partnerLocation.mailingCityName || ""}
                      name="mailingCityName"
                      onChange={handleChangeAddressInput}
                      placeholder="City"
                      tabIndex={10}
                  />
              </div>
              <div className="col-md-3 mb-4">
                  <StyledPTitle className="mb-2">Postcode</StyledPTitle>
                  <StyledInputMask
                      mask="*** ***"
                      value={partnerData.partnerLocation.mailingPostcode ? partnerData.partnerLocation.mailingPostcode.toUpperCase() : ""}
                      onChange={handleChangeAddressInput}
                      name="mailingPostcode"
                      placeholder="Postcode"
                      tabIndex={11}
                  />
              </div>
              <div className="col-md-3 mb-4">
                  <StyledPTitle className="mb-2">Phone number</StyledPTitle>
                  <StyledInputMask
                      mask="999-999-9999"
                      value={partnerData.partnerLocation.mailingPhoneNumber || ""}
                      onChange={handleChangeAddressInput}
                      name="mailingPhoneNumber"
                      placeholder="Phone number"
                      tabIndex={7}
                  />
              </div>
          </>
      }

      <StyledBreakDiv />
      <div className="col-md-12">
        <StyledAgenda className="mt-3 mb-3 fw-bold">
          Partner Is A Licensed Agent?*
        </StyledAgenda>
      </div>

      <div className="col-12">
        <StyledDescription
          name="Partner Is A Licensed Agent?"
          value={partnerData.isLicensed}
          onChange={handleChangePartnerLicensed}
        >
                <span key="isLicensed_yes">
                  <StyledFormControlLabel
                    value={true}
                    control={<Radio color="primary" />}
                    label="Yes"
                  />
                </span>
          <span key="isLicensed_no">
                  <StyledFormControlLabel
                    value={false}
                    control={<Radio color="primary" />}
                    label="No"
                  />
                </span>
        </StyledDescription>
      </div>

      {partnerData.isLicensed &&
          <>
              <div className="col-md-4 mb-4">
                  <StyledPTitle className="mb-2">{isIndividualPartner ? "Agent License Number" : "Corp. License Number"}</StyledPTitle>
                  <BNPInput
                      value={partnerData.partnerLicenseAgentInformation ? partnerData.partnerLicenseAgentInformation.licenseNumber : ''}
                      name="licenseNumber"
                      onChange={handleChangeLicenseInput}
                      placeholder="License Number"
                      tabIndex={2}
                  />
              </div>
              <div className="col-md-4 mb-4">
                  <StyledPTitle className="mb-2">
                    {isIndividualPartner ? "Agent License Expiry Date" : "License Expiry Date"}
                  </StyledPTitle>
                  <BNPDatePicker
                      value={
                        partnerData.partnerLicenseAgentInformation
                        && partnerData.partnerLicenseAgentInformation.licenseExpiryDate
                          ? convertTimeZone(
                            "Canada/Eastern",
                            partnerData.partnerLicenseAgentInformation.licenseExpiryDate
                          ).format(FE_DETAIL_DATE_FORMAT)
                          : ""
                      }
                      name="licenseExpiryDate"
                      onChange={handleChangeDate}
                      placeholder="License Expiry Date"
                      minDate={new Date()}
                      tabIndex={20}
                  />
              </div>
              <div className="col-md-4 mb-4" />
              <div className="col-md-4 mb-4">
                  <StyledPTitle className="mb-2">{isIndividualPartner ? "Agent E&O Number" : "Corp. E&O Number"}</StyledPTitle>
                  <BNPInput
                      value={partnerData.partnerLicenseAgentInformation ? partnerData.partnerLicenseAgentInformation.enoNumber : ''}
                      name="enoNumber"
                      onChange={handleChangeLicenseInput}
                      placeholder="E&O Number"
                      tabIndex={2}
                  />
              </div>
              <div className="col-md-4 mb-4">
                  <StyledPTitle className="mb-2">E&O Provider</StyledPTitle>
                  <BNPInput
                      value={partnerData.partnerLicenseAgentInformation ? partnerData.partnerLicenseAgentInformation.enoProvider : ''}
                      name="enoProvider"
                      onChange={handleChangeLicenseInput}
                      placeholder="E&O Provider"
                      tabIndex={3}
                  />
              </div>
              <div className="col-md-4 mb-4">
                  <StyledPTitle className="mb-2">
                      E&O Expiry Date
                  </StyledPTitle>
                  <BNPDatePicker
                      value={
                        partnerData.partnerLicenseAgentInformation
                        && partnerData.partnerLicenseAgentInformation.enoExpiryDate
                          ? convertTimeZone(
                            "Canada/Eastern",
                            partnerData.partnerLicenseAgentInformation.enoExpiryDate
                          ).format(FE_DETAIL_DATE_FORMAT)
                          : ""
                      }
                      name="enoExpiryDate"
                      onChange={handleChangeDate}
                      placeholder="E&O Expiry Date"
                      minDate={new Date()}
                      tabIndex={20}
                  />
              </div>
          </>
      }
    </div>
  )




  return (
    <BNPModal
      open={props.open}
      title={props.isCreate ? "Create New Partner" : "Update Partner"}
      handleClose={props.closePartnerModal}
      body={modalBody}
      footer={<div className="row w-100 d-flex flex-column-reverse flex-sm-row-reverse ">
        <div className="col-sm-2 p-0 mt-2 ml-sm-2 mt-sm-0">
          <StyledCustomSubmitButton
            disabled={!props.isSubmitValid}
            onClick={props.handleSubmit}
            submit={props.isSubmitValid}
            type="submit"
            tabIndex={16}
          >
            Save
          </StyledCustomSubmitButton>
        </div>
        <div className="col-sm-2 p-0">
          <StyledCancelButton
            onClick={props.closePartnerModal}
            submit={true}
          >
            Cancel
          </StyledCancelButton>
        </div>
        <div className="col-sm-7 p-0 d-flex align-items-center">
          <StyledSpanErrorMessage>{props.errorMessage}</StyledSpanErrorMessage>
        </div>
      </div>}
    />
  );
}
import React, {useMemo} from "react";
import moment from "moment";
import styled from "styled-components";
import {formatterUSD} from "../../../cores/helpers/format-usd";
import {centToUsd} from "../../../cores/helpers/cent-to-usd";
import BNPProgressBar from "../progress-bar/BNPProgressBar";
import {TWalletBenefitDetail} from "./WalletBenefitModel";

const StyledBox = styled.div`
    background-color: #f2f2f5;
    padding: 15px 11px;
    border-radius: 5px;
    height: fit-content;
    @media (max-width: 599px) {
        padding: 10px;
    }
`;

export const StyledTitle = styled.span`
    color: ${(props) => props.theme.primaryColor};
    font-family: ${(props) => props.theme.subheadFont};
    font-size: 16px;
    font-weight: bold;
    line-height: 26px;
`;

const isBeforeResetBenefitDate = moment().month() === 1 && moment().date() <= 15;

export type WalletBenefitType = {
  proRated: boolean;
  isAvailable: boolean;
  walletBenefit: TWalletBenefitDetail;
};


export const WalletBenefitNew = (props: WalletBenefitType) => {
  const {
    enrollmentName,
    allocatedAmount,
    claimedAmount,
    pendingPaymentThisYear,
    pendingPaymentFromPreviousYear,
    pendingApproveClaimAmount,
    availableAmount,
    previousYearRollOverAmount
  } = props.walletBenefit;


  const {progressBarValues, progressBarColors} = useMemo(() => {
    if (isBeforeResetBenefitDate) {
      return {
        progressBarValues: [claimedAmount, pendingPaymentFromPreviousYear, pendingPaymentThisYear, pendingApproveClaimAmount],
        progressBarColors: ["primary", "#04A77A", "#04A77A", "#FFA400"]
      }
    }
    return {
      progressBarValues: [claimedAmount, pendingPaymentThisYear, pendingApproveClaimAmount],
      progressBarColors: ["primary", "#04A77A", "#FFA400"]
    }
  }, [props.walletBenefit]);

  return (
    <StyledBox className="mt-3">
      <StyledTitle>{enrollmentName}</StyledTitle>
      <div>
        <div className="w-100 mt-2 d-flex justify-content-between">
          Claimed
          <div style={{color: "#003049"}}>
            {formatterUSD("currency", "USD").format(
              centToUsd(claimedAmount)
            )}
          </div>
        </div>
        <div className="w-100 d-flex justify-content-between">
          Pending Payment
          <div style={{color: "#04A77A"}}>
            {formatterUSD("currency", "USD").format(
              centToUsd(pendingPaymentThisYear)
            )}
          </div>
        </div>
        {pendingPaymentFromPreviousYear > 0 && <div className="w-100 d-flex justify-content-between">
          Pending Payment From Previous Years
          <div style={{color: "#04A77A"}}>
            {formatterUSD("currency", "USD").format(
              centToUsd(pendingPaymentFromPreviousYear)
            )}
          </div>
        </div>}
        <div className="w-100 d-flex justify-content-between">
          Requested Claim
          <div style={{color: "#FFA400"}}>
            {formatterUSD("currency", "USD").format(
              centToUsd(pendingApproveClaimAmount)
            )}
          </div>
        </div>
      </div>
      <BNPProgressBar values={progressBarValues}
                      total={allocatedAmount + previousYearRollOverAmount}
                      colors={progressBarColors}
      />
      <div>
        <div className="w-100 mt-2 d-flex justify-content-between">
          Allocated this year
          <div style={{color: "#003049"}}>
            {formatterUSD("currency", "USD").format(
              centToUsd(allocatedAmount)
            )}
          </div>
        </div>
        {previousYearRollOverAmount > 0 &&
          <div className="w-100 d-flex justify-content-between">
            Previous year rollover
            <div style={{color: "#003049"}}>
              {formatterUSD("currency", "USD").format(
                centToUsd(previousYearRollOverAmount)
              )}
            </div>
          </div>
        }
        <div className="w-100 d-flex justify-content-between" style={{fontWeight: "bold"}}>
          Available this year
          <div style={{color: "#003049"}}>
            {formatterUSD("currency", "USD").format(
              centToUsd(availableAmount)
            )}
          </div>
        </div>
      </div>
    </StyledBox>
  );
};
import React, { useState, useEffect } from "react";
import {
  getProvinces,
} from "../../../../services/company-service";
import { addLoading, removeLoading } from "../../../../cores/utils/loading";
import moment from "moment";
import { validFullNameLength } from "../../../../cores/utils/validation/validate-employee";
import { Provinces } from "../../company-management/company-management-type";
import { CreatePartnerRequest, PartnerDetail, PartnerLicenseAgentInformation, PartnerLocation } from "../partner-management-type";
import PartnerModal from "./partner-modal";
import { PartnerTypes } from "../partner-management-model";
import { validateEmail } from "../../../../cores/config/config";
import { postNewPartner } from "../../../../services/partner-service";
import { publishMessage } from "../../../../cores/utils/message";

type Props = {
  successCallBack: () => void;
  open: boolean;
  handleClose: () => void;
};


export const defaultCreatePartner: PartnerDetail = {
  type: 'INDIVIDUAL',
  contactFirstName: '',
  contactLastName: '',
  contactEmail: '',
  socialInsuranceNumber: null,
  companyEmail: null,
  companyName: null,
  businessNumber: null,
  taxNumber: null,
  isLicensed: false,
  partnerLocation: {
    billingStreetAddress: '',
    billingProvinceId: null,
    billingPostcode: '',
    billingCityName: '',
    billingPhoneNumber: '',
    mailingStreetAddress: null,
    mailingProvinceId: null,
    mailingPostcode: null,
    mailingCityName: null,
    mailingPhoneNumber: null,
    isMailingSameAsBillingAddress: true
  },
  partnerLicenseAgentInformation: null,
};

export default function CreatePartnerModal(props: Props) {
  const [createPartnerData, setCreatePartnerData] = useState<PartnerDetail>(defaultCreatePartner);
  const [provinces, setProvinces] = useState<Provinces[]>([]);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    const { contactFirstName, contactLastName, companyName } = createPartnerData;
    if (!validFullNameLength(contactFirstName, contactLastName)) {
      setErrorMessage("Full name length could not be larger than 30 letters.");
    } else if (!!companyName && !validFullNameLength(companyName, '')) {
      setErrorMessage("Company name length could not be larger than 30 letters.");
    } else {
      setErrorMessage("");
    }
  }, [createPartnerData.contactFirstName, createPartnerData.contactLastName, createPartnerData.companyName]);

  const init = async () => {
    const [
      provinceData,
    ] = await Promise.all([
      getProvinces()
    ]);

    setProvinces(provinceData.data);
  };

  const validateAndSetValue = (validateData: PartnerDetail) => {
    let isValid = validate(validateData);
    setCreatePartnerData(validateData);
    setIsValid(isValid);
  }

  const validate = (data: PartnerDetail) => {

    const isBaseInfoValid: boolean = !!(
      data.type
      && data.contactFirstName && data.contactFirstName.trim()
      && data.contactLastName && data.contactLastName.trim()
      && data.contactEmail && data.contactEmail.trim()
      && !!(data.contactEmail.match(validateEmail))
      && null != data.isLicensed
      && data.partnerLocation);

    const partnerLocation: PartnerLocation = data.partnerLocation;

    const isBaseLocationValid: boolean = !!(
      partnerLocation.billingStreetAddress && partnerLocation.billingStreetAddress.trim()
      && partnerLocation.billingCityName && partnerLocation.billingCityName.trim()
      && partnerLocation.billingPhoneNumber && partnerLocation.billingPhoneNumber.trim()
      && partnerLocation.billingPostcode && partnerLocation.billingPostcode.trim()
      && partnerLocation.billingProvinceId && partnerLocation.billingProvinceId > 0
      && null != partnerLocation.isMailingSameAsBillingAddress);

    const isMailingAddressValid = partnerLocation.isMailingSameAsBillingAddress
      ? isBaseLocationValid
      : !!(
        partnerLocation.mailingStreetAddress && partnerLocation.mailingStreetAddress.trim()
        && partnerLocation.mailingCityName && partnerLocation.mailingCityName.trim()
        && partnerLocation.mailingPhoneNumber && partnerLocation.mailingPhoneNumber.trim()
        && partnerLocation.mailingPostcode && partnerLocation.mailingPostcode.trim()
        && partnerLocation.mailingProvinceId && partnerLocation.mailingProvinceId > 0
      );

    const licensedData: PartnerLicenseAgentInformation | null = data.partnerLicenseAgentInformation;

    const isLicensedValid = !data.isLicensed ||
      !!(
        licensedData
        && licensedData.licenseNumber && licensedData.licenseNumber.trim()
        && licensedData.licenseExpiryDate
        && licensedData.enoNumber && licensedData.enoNumber.trim()
        && licensedData.enoProvider && licensedData.enoProvider.trim()
        && licensedData.enoExpiryDate
      )

    if (data.type === PartnerTypes.INDIVIDUAL) {
      return !!(
        isBaseInfoValid
        && isBaseLocationValid
        && isMailingAddressValid
        && isLicensedValid
        && data.socialInsuranceNumber && data.socialInsuranceNumber.trim()
      )
    }

    return !!(
      isBaseInfoValid
      && isBaseLocationValid
      && isMailingAddressValid
      && isLicensedValid
      && data.companyName && data.companyName.trim()
      && data.companyEmail && data.companyEmail.trim()
      && !!(data.companyEmail.match(validateEmail))
      && data.businessNumber && data.businessNumber.trim()
      && data.taxNumber && data.taxNumber.trim()
    );
  };


  const convertCreatePartnerData = (): CreatePartnerRequest => {

    let createdData: any = {
      ...createPartnerData,
      partnerLocationRequest: { ...createPartnerData.partnerLocation },
      partnerLicenseAgentInformationRequest: createPartnerData.isLicensed
        ? { ...createPartnerData.partnerLicenseAgentInformation }
        : null,
    }
    // delete createdData.partnerLocation;
    // delete createdData.partnerLicenseAgentInformation;

    createdData.partnerLocationRequest.billingPostcode = createdData.partnerLocationRequest.billingPostcode.toUpperCase();
    if (createdData.partnerLocationRequest && createdData.partnerLocationRequest.mailingPostcode) {
      createdData.partnerLocationRequest.mailingPostcode = createdData.partnerLocationRequest.mailingPostcode.toUpperCase();
    }
    createdData.partnerLocationRequest.billingPhoneNumber = createdData.partnerLocationRequest.billingPhoneNumber
      .split("-")
      .join("")
      .split("_")
      .join("");
    if (createdData.partnerLocationRequest && createdData.partnerLocationRequest.mailingPhoneNumber) {
      createdData.partnerLocationRequest.mailingPhoneNumber = createdData.partnerLocationRequest.mailingPhoneNumber
        .split("-")
        .join("")
        .split("_")
        .join("");
    }

    if (createdData.partnerLicenseAgentInformationRequest) {
      if (createdData.partnerLicenseAgentInformationRequest.licenseExpiryDate) {
        createdData.partnerLicenseAgentInformationRequest.licenseExpiryDate =
          moment(new Date(createdData.partnerLicenseAgentInformationRequest.licenseExpiryDate)).format("YYYY-MM-DD");
      }
      if (createdData.partnerLicenseAgentInformationRequest.enoExpiryDate) {
        createdData.partnerLicenseAgentInformationRequest.enoExpiryDate =
          moment(new Date(createdData.partnerLicenseAgentInformationRequest.enoExpiryDate)).format("YYYY-MM-DD");
      }
    }

    return createdData;
  }

  const handleSubmit = async () => {

    if (!validate(createPartnerData)) return;

    addLoading();

    const temp = convertCreatePartnerData();
    await postNewPartner(temp)
      .then(res => {
        if (res && res.status === 201) {
          publishMessage({
            message: "A new partner has been created successfully.",
            variant: "success",
          });
          setCreatePartnerData(defaultCreatePartner);
          props.successCallBack();
          props.handleClose();
        }
      })
    removeLoading();

  };


  return (
    <PartnerModal
      partnerData={createPartnerData}
      isCreate={true}
      handleSubmit={handleSubmit}
      closePartnerModal={props.handleClose}
      isSubmitValid={isValid}
      errorMessage={errorMessage}
      validateAndSetValue={validateAndSetValue}
      provinces={provinces}
      open={props.open}
    />
  );
}

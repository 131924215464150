import React, {useEffect, useMemo, useState} from 'react';
import {StyledContentBox, StyledSpanLargerTitle, StyledSpanEditInfo} from "../../../../shared/styled";
import BNPRawTable from "../../../../shared/BNPTable/BNPRawTable";
import {TableSortType} from "../../../../shared/BNPTable/BNPTableType";
import Pagination, {PagingInfo} from "../../../../../cores/helpers/pagination/pagination";
import {CompanyInfo, SearchParamCompany} from "../../../company-management/company-management-type";
import {closeConfirmDialog, openConfirmDialog} from "../../../../../cores/utils/confirmDialog";
import {changeEmployerStatus} from "../../../../../services/company-service";
import {EnableStatusEnum} from "../../../../HOC/withStatusButtonActions";



type Props = {
  data: CompanyInfo[];
  fetchData: (searchParam: SearchParamCompany) => void;
  pagingInfo: PagingInfo;
};

export const CompanyManagement = React.memo((props: Props) => {
  const [searchParam, setSearchParam] = useState<SearchParamCompany>({
    page: 1,
    status: null,
    searchKey: null,
    columnName: null,
    fromDate: null,
    toDate: null,
    sortType: null,
    perPage: 10,
  });

  useEffect(() => {
    props.fetchData(searchParam);
  }, [searchParam.page, searchParam.perPage, searchParam.columnName, searchParam.sortType]);

  const handleChangePage = (page: number) => {
    setSearchParam({...searchParam, page});
  }

  const handleChangePerPage = (perPage: any) => {
    const { value } = perPage.target;
    setSearchParam({...searchParam, perPage: value});
  }

  const handleConfirmDisableEmployer = (item: CompanyInfo) => {
    openConfirmDialog({
      id: `employer-change-status-to-disable`,
      open: true,
      title: `Disable Employer`,
      content: `Are you sure you want to disable company ${item.companyName}?`,
      onClose: (isOk) => handleSubmitDisableEmployer(isOk, item.id)
    })
  }

  const handleSubmitDisableEmployer = (isOk: boolean, id: number) => {
    if(isOk) {
      changeEmployerStatus(id, EnableStatusEnum.Disabled).then(() => {
        props.fetchData(searchParam);
      })
    }
    closeConfirmDialog();
  }

  const tableData = useMemo(() => props.data.map(item => ({
    ...item,
    actions: <StyledSpanEditInfo onClick={() => handleConfirmDisableEmployer(item)}>
      Disable
    </StyledSpanEditInfo>
  })), [props.data]);
  return (
    <StyledContentBox>
      <StyledSpanLargerTitle>
        Employer management
      </StyledSpanLargerTitle>
      <BNPRawTable columns={columnsHeaderTable} tableWidth={"100%"} tableData={tableData}/>
      <div className="row mt-4">
        <div className="col-12 text-center">
          <label>
            <Pagination
              pagerPagination={props.pagingInfo}
              getPage={handleChangePage}
              onChangeRowsPerPage={handleChangePerPage}
            />
          </label>
        </div>
      </div>
    </StyledContentBox>

  );
});

const columnsHeaderTable: TableSortType[] = [
  {
    columnId: "id",
    columnName: "ID",
    sortColumn: true,
    sortType: null,
    width: 10,
  },
  {
    columnId: "companyName",
    columnName: "Company name",
    sortColumn: true,
    sortType: null,
    width: 20,
  },
  {
    columnId: "noOfEmployees",
    columnName: "No of employees",
    sortColumn: true,
    sortType: null,
    width: 15,
  },
  {
    columnId: "joinedDate",
    columnName: "Joined Date",
    sortColumn: true,
    sortType: null,
    width: 15,
    type: 'DATE'
  },
  {
    columnId: "status",
    columnName: "Status",
    sortColumn: true,
    sortType: null,
    width: 20,
  },
  {
    columnId: "actions",
    columnName: "Actions",
    sortColumn: false,
    sortType: null,
    width: 20
  }
];
import React, {useMemo, useState} from "react";
import { BNPModal } from "../../../../shared/BNPModal/BNPModal";
import {StyledButtonSubmit, StyledPTitle} from "../../../../shared/styled";
import {BNPSelect, Option} from "../../../../../cores/helpers/select/select";
import {BNPInput} from "../../../../../cores/helpers/input/input";
import BNPDatePicker from "../../../../../cores/helpers/date-picker/date-picker";

type CustomAdminFeeModalProps = {
  enrollmentOptions: Option[]
  open: boolean;
  setOpen: any;
  handleClose: () => void;
  handleSubmit: (request: any) => void;
};

const defaultCustomAdminFeeModel = {
  enrollmentId: -1,
  adminFeePercentage: undefined,
  fromDate: new Date(),
  toDate: null
}

export function CustomAdminFeeModal(props: CustomAdminFeeModalProps) {
  const todayDate = new Date();
  todayDate.setHours(0, 0, 0);
  const [model, setModel] = useState<any>(defaultCustomAdminFeeModel);

  const handleChangeSelect = (event: any) => {
    const { name, value } = event.target;

    if (name === "enrollmentId")
      setModel({...model, enrollmentId: value})
  }

  const handleChangeInput = (event: any) => {
    const { name, value } = event.target;

    if (name === "adminFeePercentage")
      setModel({...model, adminFeePercentage: value})
  }

  function handleChangeDate(date: Date | null, name: string) {
    if (name === "fromDate" && date) {
      setModel({...model, fromDate: date});
    }
    if (name === "toDate" && date) {
      setModel({...model, toDate: date});
    }
  }

  const isValid = useMemo(() => {
    const {enrollmentId, adminFeePercentage, fromDate} = model;
    return enrollmentId > 0 &&
      adminFeePercentage >= 0 &&
      fromDate
  }, [model.enrollmentId, model.adminFeePercentage, model.fromDate, model.toDate])

  const handleSubmit = () => {
    props.handleSubmit(model)
    setModel(defaultCustomAdminFeeModel)
  }

  const modalBody =
    <div className="row">
      <div className="col-md-6 mb-4">
        <StyledPTitle className="mb-2">
          Enrollment
        </StyledPTitle>
        <BNPSelect
          name="enrollmentId"
          value={model.enrollmentId || -1}
          options={props.enrollmentOptions}
          onChange={handleChangeSelect}
          placeholder={"Select Enrollment"}
        />
      </div>
      <div className="col-md-6 mb-4">
        <StyledPTitle className="mb-2">
          Admin Fee
        </StyledPTitle>
        <BNPInput
          name="adminFeePercentage"
          value={model.adminFeePercentage}
          onChange={handleChangeInput}
          type="number"
        />
      </div>
      <div className="col-md-6 mb-4">
        <StyledPTitle className="mb-2">
          From Date
        </StyledPTitle>
        <BNPDatePicker onChange={handleChangeDate} name="fromDate" value={model.fromDate} minDate={todayDate} />
      </div>
      <div className="col-md-6 mb-4">
        <StyledPTitle className="mb-2">
          To Date
        </StyledPTitle>
        <BNPDatePicker onChange={handleChangeDate} name="toDate" value={model.toDate} minDate={model.fromDate}/>
      </div>
    </div>


  return (
    <BNPModal
      open={props.open}
      title="Custom Admin Fee"
      handleClose={props.handleClose}
      body={modalBody}
      footer={
        <div className="col-md-4 p-0">
          <StyledButtonSubmit
            disabled={!isValid}
            onClick={handleSubmit}
            submit={isValid}
            type="submit"
          >
            Save
          </StyledButtonSubmit>
        </div>
      }
    />
  );
}

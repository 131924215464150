import {TableSortType} from "../../../../shared/BNPTable/BNPTableType";
import {TableSortDataType} from "../../../../shared/BNPTable/BNPTableModel";

export const defaultPartnerAdmin = {
  firstName: "",
  lastName: "",
  email: "",
  role: "",
  roleId: -1,
  status: "",
  id: -1,
};

export type CommissionRate = {
  enrollmentId: number;
  enrollmentName?: string;
  partnerRate: number;
  beniplusRate?: number;
  fromDate: string | null;
  toDate: string | null;
}


export const CommissionRateTableColumns: TableSortType[] = [
  {
    columnId: "enrollmentName",
    columnName: "Wallet",
    sortType: null,
    width: 30,
    sortColumn: false
  },
  {
    columnId: "beniplusRate",
    columnName: "Beniplus Split",
    sortType: null,
    width: 10,
    sortColumn: false,
    type: TableSortDataType.PERCENTAGE,
  },
  {
    columnId: "partnerRate",
    columnName: "Partner Split",
    sortType: null,
    width: 10,
    sortColumn: false,
    type: TableSortDataType.PERCENTAGE,
  },
  {
    columnId: "fromDate",
    columnName: "From Date",
    sortType: null,
    width: 25,
    sortColumn: false,
    type: TableSortDataType.DATE,
  },
  {
    columnId: "toDate",
    columnName: "To Date",
    sortType: null,
    width: 25,
    sortColumn: false,
    type: TableSortDataType.DATE,
  },
]
import { AxiosResponse } from "axios";
import { getAsync, postAsync, getFileAsync } from "../cores/utils/http-client";
import { Adjustment } from "../components/pages/company-management/company-management-type";
import { TaxSearchParam } from "../components/pages/report/tax-type";
import { SearchParamClaimHistory } from "../components/pages/claim-history/claim-history-type";
import { RequiredTopUpReportSearchParam } from "../components/pages/required-topup-report/required-topup-report-type";
import { EftCredit } from "../components/pages/required-topup-report/credit/credit-modal-type";
import { TableSearchParamType } from "../components/shared/BNPTable/BNPTableType";
import { PayAutoTopUpPendingType } from "../components/pages/company-management/detail/pay-auto-topup/PayAutoTopUpPendingType";

const URL_Employer = "/manage-wallet-partner/employer/wallet";
const URL_Employee = "/manage-wallet-partner/employee/wallet";
let URL_WALLET_EMPLOYER =
  "/manage-wallet-partner/partner/wallet/manual/employer";

const URL_Wallet_Partner = "manage-wallet-partner/partner/wallet";

const URL_EMPLOYEE_WALLET = "/manage-employee/api/wallet-info";

/**
 * available amount
 * get physical balance
 * @param employeeId
 */
export const getEmployeeWalletInfo = (
  employeeId: number
): Promise<AxiosResponse> => {
  let url = `${URL_EMPLOYEE_WALLET}/employee/${employeeId}`;

  return getAsync(url);
};

/**
 * pending amount
 * logical balance
 * @param employerId
 */
export const getAllBalancesEmployer = (
  employerId: number
): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/balances/${employerId}`;

  return getAsync(url);
};


/**
 * list all transactions
 * api get all transactions
 * @param employerId
 */
export const getAllTransactions = (
  employerId: number,
  criteria: TableSearchParamType
): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/transactions/all/${employerId}`;

  return getAsync(url, criteria);
};

/**
 * list all transactions of employee
 * api get all transactions of employee
 * @param employeeId
 */
export const getAllTransactionsForEmployee = (
  employeeId: number
): Promise<AxiosResponse> => {
  let url = `${URL_Employee}/transactions/all/${employeeId}`;

  return getAsync(url);
};

/**
 * Cash credit
 * @param model
 */
export const postCashCreditPartner = (
  model: Adjustment
): Promise<AxiosResponse> => {
  let url = `${URL_WALLET_EMPLOYER}/cash/credit`;

  return postAsync(url, model);
};

/**
 * get total amount claims has status == Requested
 * @param employerId
 */
export const getPendingAmountClaims = (
  employerId: number
): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/balances/pending-claim/${employerId}`;

  return getAsync(url);
};

/**
 * get total amount claims has status == Requested
 * @param employerId
 */
export const getAllSeperatePendingAmount = (
  employerId: number
): Promise<AxiosResponse> => {
  let url = `manage-wallet-partner/employer/wallet/balances/pending/${employerId}`;

  return getAsync(url);
};

/**
 * get total amount claims has status == Requested
 * @param employeeId
 */

export const getPendingClaimAmountByCategory = (
    employeeId: number
): Promise<AxiosResponse> => {
  let url = `${URL_Employee}/balances/pending-claim-by-category/${employeeId}`;

  return getAsync(url);
};

export const getWalletEmployee = (
  employeeId: number
): Promise<AxiosResponse> => {
  let url = `${URL_Employee}/balances/${employeeId}`;

  return getAsync(url, {}, false);
};

export const getWalletOnboardingDate = (
  employeeId: number
): Promise<AxiosResponse> => {
  let url = `${URL_Employee}/wallet/${employeeId}/onboardingDate`;

  return getAsync(url, {}, false);
};

export const getAllReportReceipt = (
  param: SearchParamClaimHistory
): Promise<AxiosResponse> => {
  let url = `${URL_Wallet_Partner}/report`;

  return getAsync(url, param);
};

export const getAllISSReportReceipt = (
  param: SearchParamClaimHistory
): Promise<AxiosResponse> => {
  let url = `${URL_Wallet_Partner}/iss-report`;

  return getAsync(url, param);
};

export const getExportISSClaimHistory = (
  param: SearchParamClaimHistory
): Promise<AxiosResponse> => {
  let url = `${URL_Wallet_Partner}/iss-report/export`;

  return getFileAsync(url, param);
};

export const getReceiptImage = (claimId: number): Promise<AxiosResponse> => {
  let url = `${URL_Wallet_Partner}/report/${claimId}`;

  return getAsync(url);
};

export const getExportClaimHistory = (
  param: SearchParamClaimHistory
): Promise<AxiosResponse> => {
  let url = `${URL_Wallet_Partner}/report/export`;

  return getFileAsync(url, param);
};

export const getReportTax = (param: TaxSearchParam): Promise<AxiosResponse> => {
  let url = `${URL_Wallet_Partner}/tax/report`;

  return getAsync(url, param);
};

export const getExportTax = (param: TaxSearchParam): Promise<AxiosResponse> => {
  let url = `${URL_Wallet_Partner}/tax/report/export`;

  return getFileAsync(url, param);
};

/**
 * list all transactions
 * api get all transactions
 * @param partnerId
 */
export const getAllTransactionsPartner = (
  partnerId: number
): Promise<AxiosResponse> => {
  let url = `/manage-wallet-partner/partner/wallet/eft/current/response`;

  return postAsync(url, { partnerId: partnerId });
};

export const getAllTransactionsPartnerPagination = (
  request: any
): Promise<AxiosResponse> => {
  let url = `/manage-wallet-partner/partner/wallet/eft/current/response/pagination`;

  return postAsync(url, request);
}

/**
 * list all transactions
 * api get all transactions
 * @param partnerId
 */
export const getAllRequestTransactionsPartner = (
  partnerId: number
): Promise<AxiosResponse> => {
  let url = `/manage-wallet-partner/partner/wallet/eft/current/pending`;

  return postAsync(url, { partnerId: partnerId });
};

export const getAllRequestTransactionsPartnerCount = (): Promise<AxiosResponse> => {
  let url = `/manage-wallet-partner/partner/wallet/eft/current/pending/count`;

  return postAsync(url, {});
};

export const getAllRequestTransactionsPartnerWithPagination = (
  request: any
): Promise<AxiosResponse> => {
  let url = `/manage-wallet-partner/partner/wallet/eft/current/pending/pagination`;

  return postAsync(url, request);
};

export const getEmployerRequestTransactionsPartnerWithPagination = (
  request: any
): Promise<AxiosResponse> => {
  let url = `/manage-wallet-partner/partner/wallet/employers/eft/current/pending/pagination`;

  if (!request.columnName) {
    delete request.columnName;
    delete request.sortType
  }

  return postAsync(url, request);
};

export const getEmployeeRequestTransactionsPartnerWithPagination = (
  request: any
): Promise<AxiosResponse> => {
  let url = `/manage-wallet-partner/partner/wallet/employees/eft/current/pending/pagination`;

  if (!request.columnName) {
    delete request.columnName;
    delete request.sortType
  }

  return postAsync(url, request);
};

/**
 * available amount
 * get physical balance
 * @param partnerId
 */
export const getBalancePartner = (
  partnerId: number
): Promise<AxiosResponse> => {
  let url = `/manage-wallet-partner/partner/wallet/balances/${partnerId}`;

  return getAsync(url);
};

export type Credit = {
  amount: number;
  targetId: number; //employer_id
  desc: string; // any
};

/**
 * Cash credit employer
 * @param model
 */
export const postCashCreditEmployer = (
  model: Credit
): Promise<AxiosResponse> => {
  let url = `/manage-wallet-partner/partner/wallet/manual/employer/cash/credit`;

  return postAsync(url, model);
};

/**
 * Cash debit employer
 * @param model
 */
export const postCashDebitEmployer = (
  model: Credit
): Promise<AxiosResponse> => {
  let url = `/manage-wallet-partner/partner/wallet/manual/employer/cash/debit`;

  return postAsync(url, model);
};

/**
 * Cash credit employee
 * @param model
 */
export const postCashCreditEmployee = (
  model: Credit
): Promise<AxiosResponse> => {
  let url = `/manage-wallet-partner/partner/wallet/manual/employee/cash/credit`;

  return postAsync(url, model);
};

/**
 * Cash debit employee
 * @param model
 */
export const postCashDebitEmployee = (
  model: Credit
): Promise<AxiosResponse> => {
  let url = `/manage-wallet-partner/partner/wallet/manual/employee/cash/debit`;

  return postAsync(url, model);
};

export const postEFTManualApprove = (model: {
  partnerId: number;
  sundryNumber: string;
}, handleErrorAutomatic: boolean = true): Promise<AxiosResponse> => {
  let url = `/manage-wallet-partner/partner/wallet/eft/manualapproval/`;

  return postAsync(url, model, handleErrorAutomatic);
};

export const postEFTPendingReverse = (model: {
  partnerId: number;
  sundryNumber: string;
}): Promise<AxiosResponse> => {
  let url = `/manage-wallet-partner/partner/wallet/eft/pendingreverse/`;

  return postAsync(url, model);
};

export const declineWithdraw = (journalId: number, handleErrorAutomatic: boolean = true): Promise<AxiosResponse> => {
  let url = `/manage-wallet-partner/partner/wallet/decline/withdraw/${journalId}`;

  return postAsync(url, {}, handleErrorAutomatic);
};

export const declineDeposit = (journalId: number, handleErrorAutomatic: boolean = true): Promise<AxiosResponse> => {
  let url = `/manage-wallet-partner/partner/wallet/decline/deposit/${journalId}`;

  return postAsync(url, {}, handleErrorAutomatic);
};

export const checkEmployeeHasReceivedPaidAmount = (claimId: number): Promise<AxiosResponse> => {
  let url = `/manage-wallet-partner/partner/wallet/decline/claim/${claimId}/check`;

  return getAsync(url);
};

export const requestRefundForEmployer = (model: any): Promise<AxiosResponse> => {
  let url = `/manage-wallet-partner/employer/bank/eft/withdraw/`;

  return postAsync(url, model);
};

export const getRequiredTopUpReport = (type: string, param: RequiredTopUpReportSearchParam): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/required-topup-report/${type}`
  
  return getAsync(url, param);
}

export const postEftDepositPartner = (model: EftCredit): Promise<AxiosResponse> => {
  let url = `/manage-wallet-partner/employer/bank/eft/deposit/`;

  return postAsync(url, model);
};

/**
 * Get Auto Top Up Pending
 * @param model
 */
export const getAutoTopUpPending = (
  employerId: number
): Promise<AxiosResponse> => {
  let url = `/manage-wallet-partner/employer/bank/auto-deposit/pending/`;

  return getAsync(url, { employerId });
};

/**
 * Pay Auto Top Up Pending
 * @param model
 */
export const postPayAutoTopUpPending = (
  model: PayAutoTopUpPendingType
): Promise<AxiosResponse> => {
  let url = `/manage-wallet-partner/employer/bank/auto-deposit/pending/paid/`;

  return postAsync(url, model);
};

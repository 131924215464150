import React from "react";
import styled, {css} from "styled-components";
import {
  ClaimHistoryResponse, ISSClaimHistoryResponse,
  SearchParamClaimHistory,
} from "./claim-history-type";
import {
  PagingInfo,
  setPagingInfo,
} from "../../../cores/helpers/pagination/pagination";
import {addLoading, removeLoading} from "../../../cores/utils/loading";
import {RouteChildrenProps} from "react-router";
import {
  getAllISSReportReceipt,
  getAllReportReceipt,
  getExportClaimHistory, getExportISSClaimHistory,
  getReceiptImage
} from "../../../services/wallet-service";
import {
  claimTypes,
  ClaimHistoryReportType,
} from "./claim-history-model";
import {Status} from "../company-management/detail/employees/employee-model";
import {
  StyledBigTitle,
  StyledContentBox,
  StyledLargerTitle,
  StyledMainContent,
} from "../../shared/styled";
import InfoIcon from "@material-ui/icons/Info";
import { SaveAlt } from "@material-ui/icons";
import {convertTimeZone, download, findNameById} from "../../../cores/utils/helpers";
import {formatterUSD} from "../../../cores/helpers/format-usd";
import {centToUsd} from "../../../cores/helpers/cent-to-usd";
import BNPTable from "../../shared/BNPTable/BNPTable";
import {TableSearchParamType, TableSortType} from "../../shared/BNPTable/BNPTableType";
import {AxiosResponse} from "axios";
import {exportExcel} from "../../../cores/helpers/export-file/export-file";
import {defaultTablePagingInfo, defaultTableSearchParam} from "../../shared/BNPTable/BNPTableModel";
import {FE_DATE_FORMAT} from "../../../cores/utils/format/date-time-format";

const StyledAction = styled.div`
  color: ${(props) => props.theme.infoColor};
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
  padding-left: 16px;
  cursor: pointer;

  :hover {
    text-decoration: none;
  }

  .MuiSvgIcon-root {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
`;

const StyledReportNavBar = styled.div`
  width: fit-content;
  background: none;
  display: flex;
  justify-content: space-between;
`;

const StyledReportNavBarItem = styled.div`
  overflow: hidden;
  line-height: 16px;
  display: flex;
  justify-content: center;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 32px;

  :hover {
    background-color: #F7F7F8;
  }

  ${(props: { active: boolean }) =>
          props.active &&
          css`
            background-color: ${props => props.theme.infoColor};
            color: #fff;
            font-weight: bold;

            :hover {
              background-color: ${props => props.theme.infoColor};
              opacity: 0.8;
            }
          `}
`;

type Props = RouteChildrenProps;
type State = {
  claimHistoryCompleted: ClaimHistoryResponse[];
  claimHistoryIssued: ISSClaimHistoryResponse[];
  searchParam: TableSearchParamType;
  pagingInfo: PagingInfo;
  columns: TableSortType[];
  claimTypes: Status[];
  activeReportType: string;
};

export default class ClaimHistory extends React.Component<Props, State> {
  state: State = {
    claimHistoryCompleted: [],
    claimHistoryIssued: [],
    pagingInfo: defaultTablePagingInfo,
    searchParam: {...defaultTableSearchParam, columnName: "paidDate"},
    columns: ClaimHistoryReportType.CMP.columns,
    claimTypes: claimTypes,
    activeReportType: "CMP"
  };

  async componentDidMount() {
    addLoading();

    await this.getSearchClaimData({...this.state.searchParam, claimType: this.state.searchParam.filter});

    removeLoading();
  }

  async componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState.activeReportType !== this.state.activeReportType ||
      JSON.stringify(prevState.searchParam) !== JSON.stringify(this.state.searchParam)) {
      addLoading();
      if (this.state.activeReportType === "CMP") {
        await this.getSearchClaimData({...this.state.searchParam, claimType: this.state.searchParam.filter})
      } else if (this.state.activeReportType === "ISS") {
        await this.getSearchISSPaidClaimData({...this.state.searchParam, claimType: this.state.searchParam.filter})
      }
      removeLoading()
    }
  }

  async getSearchClaimData(searchParam: SearchParamClaimHistory) {
    await getAllReportReceipt(searchParam).then((result) => {
      let pagingInfo = setPagingInfo(
        searchParam.page,
        result.data.pageCount,
        searchParam.perPage
      );
      this.setState({
        claimHistoryCompleted: this.convertCompletedClaimData(result),
        pagingInfo: pagingInfo,
      });
    });
  }

  async getSearchISSPaidClaimData(searchParam: SearchParamClaimHistory) {
    await getAllISSReportReceipt(searchParam).then((result) => {
      let pagingInfo = setPagingInfo(
        searchParam.page,
        result.data.pageCount,
        searchParam.perPage
      );
      this.setState({
        claimHistoryIssued: this.convertIssuedClaimData(result),
        pagingInfo: pagingInfo,
      });
    });
  }

  convertCompletedClaimData = (result: AxiosResponse) => {
    return result.data.records.map((item: any) => ({
      code: item.code,
      status: item.status,
      claimType: findNameById(item.claimType, claimTypes),
      employeeName: item.employeeName,
      employerName: item.employerName,
      referralPartnerName: item.referralPartnerName,
      referralPartnerUserName: item.referralPartnerUserName,
      provinceName: item.provinceName ? item.provinceName : "-",
      claimTotal: formatterUSD("currency").format(centToUsd(item.claimTotal)),
      adminFee: formatterUSD("currency").format(centToUsd(item.adminFee)),
      gst: formatterUSD("currency").format(centToUsd(item.gst)),
      pst: formatterUSD("currency").format(centToUsd(item.pst)),
      rst: formatterUSD("currency").format(centToUsd(item.rst)),
      premiumTax: formatterUSD("currency").format(centToUsd(item.premiumTax)),
      total: formatterUSD("currency").format(centToUsd(item.total)),
      paidDate: item.paidDate ? convertTimeZone("Canada/Eastern", item.paidDate).format(FE_DATE_FORMAT) : "",
      partnerSplitAmount: item.partnerSplitAmount ?
        formatterUSD("currency").format(centToUsd(item.partnerSplitAmount))
        : "-",
      beniplusSplitAmount: formatterUSD("currency").format(centToUsd(item.beniplusSplitAmount)),
      action: <StyledAction
        onClick={() => {
          getReceiptImage(item.claimId).then(
            (result) => {
              download(result.data);
            }
          );
        }}
      >
        <SaveAlt/>
        Download
      </StyledAction>
    }))
  }

  convertIssuedClaimData = (result: AxiosResponse) => {
    return result.data.records.map((item: any) => ({
      code: item.code,
      status: item.status,
      claimType: findNameById(item.claimType, claimTypes),
      employeeName: item.employeeName,
      employerName: item.employerName,
      provinceName: item.provinceName ? item.provinceName : "-",
      claimAmount: formatterUSD("currency").format(centToUsd(item.claimAmount)),
      submittedDate: item.submittedDate ?
        convertTimeZone("Canada/Eastern", item.submittedDate).format(FE_DATE_FORMAT) : "-",
    }))
  }

  handleChangeClaimStatus = (status: string) => {
    if (Object.keys(ClaimHistoryReportType).includes(status)) {
      this.setState({
        activeReportType: status,
        columns: ClaimHistoryReportType[status].columns,
        searchParam: {...defaultTableSearchParam, columnName: status === "CMP" ? "paidDate" : "submittedDate"},
        claimHistoryIssued: [],
        claimHistoryCompleted: [],
        pagingInfo: defaultTablePagingInfo
      });
    }
  }

  setSearchParam = (searchParam: TableSearchParamType) => {
    this.setState({searchParam: searchParam})
  }

  handleDownloadCMPReport = () => {
    getExportClaimHistory({...this.state.searchParam, claimType: this.state.searchParam.filter}).then(
      (result) => {
        exportExcel(result.data);
      }
    );
  }

  handleDownloadISSReport = () => {
    getExportISSClaimHistory({...this.state.searchParam, claimType: this.state.searchParam.filter}).then(
      (result) => {
        exportExcel(result.data);
      }
    );
  }

  render() {
    return (
      <StyledMainContent>
        <StyledBigTitle>Claim History</StyledBigTitle>
        <div className="container-fluid p-0 mt-3">
          <StyledContentBox>
            <div className="row">
              <div className="col-lg-12 mt-3 mb-5">
                <StyledLargerTitle>Legend for status</StyledLargerTitle>
                <div className="mt-2">
                  <InfoIcon
                    fontSize="small"
                    color="action"
                    className="top--2px mr-1"
                  />
                  <strong>ISS:</strong> Claim request has been received and
                  required invoices have been issued; processing payment.
                </div>
                <div className="mt-2">
                  <InfoIcon
                    fontSize="small"
                    color="action"
                    className="top--2px mr-1"
                  />
                  <strong>CMP:</strong> Processing a claim is complete; required
                  payments have issued.
                </div>
              </div>
            </div>

            <ReportNavbar
              activeReportType={this.state.activeReportType}
              handleChangeReportType={this.handleChangeClaimStatus}/>

            {this.state.activeReportType === "CMP" &&
                <BNPTable tableName={this.state.activeReportType}
                          searchParam={this.state.searchParam}
                          setSearchParam={this.setSearchParam}
                          columns={this.state.columns}
                          tableWidth="1900px"
                          tableData={this.state.claimHistoryCompleted}
                          pagingInfo={this.state.pagingInfo}
                          haveFilterByDate={true}
                          filterType={claimTypes}
                          filterPlaceHolder="Claim Type"
                          haveSearch={true}
                          searchPlaceholder="Search by Name or Province"
                          handleDownload={this.handleDownloadCMPReport}/>}

            {this.state.activeReportType === "ISS" &&
                <BNPTable tableName={this.state.activeReportType}
                          searchParam={this.state.searchParam}
                          setSearchParam={this.setSearchParam}
                          columns={this.state.columns}
                          tableWidth="100%"
                          tableData={this.state.claimHistoryIssued}
                          pagingInfo={this.state.pagingInfo}
                          haveFilterByDate={true}
                          filterType={claimTypes}
                          filterPlaceHolder="Claim Type"
                          haveSearch={true}
                          searchPlaceholder="Search by Name or Province"
                          handleDownload={this.handleDownloadISSReport}/>}
          </StyledContentBox>
        </div>
      </StyledMainContent>
    );
  }
}

function ReportNavbar(props: { activeReportType: string, handleChangeReportType: (type: string) => void }) {
  return <StyledReportNavBar>
    {Object.keys(ClaimHistoryReportType).map((type: string) => (
      <div key={`report-nav-bar-item-${type}`}>
        <StyledReportNavBarItem active={type === props.activeReportType}
                                onClick={() => props.handleChangeReportType(type)}>
          {ClaimHistoryReportType[type].name}
        </StyledReportNavBarItem>
      </div>
    ))}
  </StyledReportNavBar>;
}

import React, {useEffect, useState} from "react";
import { RouteChildrenProps } from "react-router";
import styled, {css} from "styled-components";
import {StyledBigTitle, StyledButtonSubmit, StyledMainContent} from "../../../shared/styled";
import theme from "../../../../cores/helpers/theme";
import {formatterUSD} from "../../../../cores/helpers/format-usd";
import {centToUsd} from "../../../../cores/helpers/cent-to-usd";
import {DetailInformation} from "../../../shared/detail-information/DetailInformation";
import {BankingInformation} from "../../../shared/banking-information/BankingInformation";
import {BankingInfo} from "../../../shared/banking-information/BankingInformationType";
import {DetailInformationDataModel} from "./partner-detail-model";
import UpdateBankingInformationModal from "../../../shared/banking-information/BankingInformationModal";
import {PartnerAdminManagement} from "./admin-management/PartnerAdminManagement";
import {PartnerAdminManagementModal} from "./admin-management/PartnerAdminManagementModal";
import {defaultPartnerAdmin} from "./admin-management/PartnerAdminManagementModel";
import {CommissionRateManagement} from "./commission-rate/CommissionRateManagement";
import {CommissionRateManagementModal} from "./commission-rate/CommissionRateManagementModal";
import {
  getPartnerCommissionRates, updatePartnerCommissionRates,
} from "../../../../services/partner-service";
import { getPartnerBankingInformation, getPartnerDetailByAdmin, getPartnerPortalRoles, getPartnerUsers } from "../../../../services/partner-service";
import { addLoading, removeLoading } from "../../../../cores/utils/loading";
import { PartnerDetail, PartnerInformation, PartnerLocation, PartnerRole, PartnerUserData, SearchPartnerUserParam } from "../partner-management-type";
import EditPartnerModal from "../partner-modal/edit-partner-modal";
import { Provinces } from "../../company-management/company-management-type";
import { getProvinces } from "../../../../services/company-service";
import {CompanyManagement} from "./company-management/CompanyManagement";
import {CompanyInfo, SearchParamCompany} from "../../company-management/company-management-type";
import {PagingInfo, setPagingInfo} from "../../../../cores/helpers/pagination/pagination";
import {getEmployersByPartnerId} from "../../../../services/partner-service";
import {CommissionRate} from "./commission-rate/CommissionRateManagementModel";
import { PartnerAdjustmentModal } from "./partner-wallet/PartnerAdjustmentModal";
import { PartnerCommissionBalanceType } from "./partner-detail-type";
import {getExportPartnerTransactions, getPartnerCommissionBalances, getPartnerTransactions} from "../../../../services/partner-wallet-service";
import { PartnerTransactionExportParam, SearchPartnerTransactionParam, TransactionInfo } from "./transaction/TransactionType";
import { Transaction } from "./transaction/Transaction";
import moment from "moment";
import { BE_DATE_FORMAT } from "../../../../cores/utils/format/date-time-format";
import { exportExcel } from "../../../../cores/helpers/export-file/export-file";

const StyledTitleBalance = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 29px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledBalance = styled.div`
  font-size: 26px;
  font-weight: bold;
  line-height: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
    ${(props: { color: string }) =>
  props.color &&
  css`
        color: ${props.color};
      `};
`;

const StyledOptionRequest = styled(StyledButtonSubmit)`
  background-color: transparent;
  border: 1px solid ${(props) => props.theme.primaryColor};
  color: ${(props) => props.theme.primaryColor};
  overflow-x: hidden;
`;

type Props = RouteChildrenProps;

const PartnerManagementDetail = (props: Props) => {
  const [partnerId, setPartnerId] = useState<number>(
    Number(new URLSearchParams(window.location.search).get("id")) || 0);
    const [partnerDetail, setPartnerDetail] = useState<PartnerDetail>({
      type: 'INDIVIDUAL',
      contactFirstName: '',
      contactLastName: '',
      contactEmail: '',
      socialInsuranceNumber: null,
      companyEmail: null,
      companyName: null,
      businessNumber: null,
      taxNumber: null,
      isLicensed: false,
      partnerLocation: {
        billingStreetAddress: '',
        billingProvinceId: null,
        billingPostcode: '',
        billingCityName: '',
        billingPhoneNumber: '',
        mailingStreetAddress: null,
        mailingProvinceId: null,
        mailingPostcode: null,
        mailingCityName: null,
        mailingPhoneNumber: null,
        isMailingSameAsBillingAddress: true
      },
      partnerLicenseAgentInformation: null,
    });
  const [bankingInfo, setBankingInfo] = useState<BankingInfo>({
    voidChequeUrl: "",
    eSignature: "",
    transitNumber: "",
    bankNumber: "",
    accountNumber: "",
    eSignatureDate: ""
  });
  const [partnerAdminList, setPartnerAdminList] = useState<PartnerUserData[]>([]);
  const [partnerAdminEdit, setPartnerAdminEdit] = useState<any>({});
  const [openEditPartnerAdminModal, setOpenEditPartnerAdminModal] = useState<boolean>(false);
  const [commissionRateList, setCommissionRateList] = useState<any[]>([
    {wallet: "HSA", beniplusSplit: 0.6, partnerSplit: 0.4},
    {wallet: "WSA", beniplusSplit: 0.6, partnerSplit: 0.4},
    {wallet: "Personal Insurance", beniplusSplit: 0.6, partnerSplit: 0.4},
    {wallet: "RRSP", beniplusSplit: 0.6, partnerSplit: 0.4},
  ]);
  const [openAddCommissionRateModal, setOpenAddCommissionRateModal] = useState<boolean>(false);
  const [provinces, setProvinces] = useState<Provinces[]>([]);
  const [roles, setRoles] = useState<PartnerRole[]>([]);
  const [employerList, setEmployerList] = useState<CompanyInfo[]>([])
  const [employerListPagination, setEmployerListPagination] = useState<PagingInfo>(setPagingInfo(1, 0, 10));
  const [partnerUserPagination, setPartnerUserPagination] = useState<PagingInfo>(setPagingInfo(1, 0, 10));
  const [searchPartnerUserParam, setSearchPartnerUserParam] = useState<SearchPartnerUserParam>({
    page: 1,
    perPage: 10
  });
  const [openAdjustmentModal, setOpenAdjustmentModal] = useState<boolean>(false);
  const [commissionBalances, setCommissionBalances] = useState<PartnerCommissionBalanceType>({
    commissionPending: 0,
    clawbackPending: 0
  });
  const [transactions, setTransactions] = useState<TransactionInfo[]>([])
  const [transactionPagination, setTransactionPagination] = useState<PagingInfo>(setPagingInfo(1, 0, 10));
  const [openEditPartnerModal, setOpenEditPartnerModal] = useState<boolean>(false);
  const [openEditBankingModal, setOpenEditBankingModal] = useState<boolean>(false);

  
  useEffect(() => {
    addLoading();
    init();
    removeLoading();
  }, []);

  const init = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlPartnerId = urlParams.get("id");
    if (urlPartnerId) {
      const partnerId = Number(urlPartnerId);
      setPartnerId(partnerId);
      loadData(partnerId);
    }
  }

  const loadData = async (id: number) => {

    const [provinceData] = await Promise.all([getProvinces()]);
    const provinces: Provinces[] = provinceData.data;
    setProvinces(provinces);

    loadRoles();
    loadPartnerDataDetail(id, provinces);
    loadBankingInformation(id);
    loadPartnerCommissionRates(id);
    loadPartnerCommissionBalances(partnerId);
  }

  const loadRoles = () => {
    return getPartnerPortalRoles()
      .then(res => {
        setRoles(res.data);
    })
  }

  const loadBankingInformation = (id: number) => {
    // because this API can be return 404
    // and we accept that as the partner banking information has been not created
    // and dont want to show any error message when handleErrorAutomatic = true
    return getPartnerBankingInformation(id, false)
      .then(res => {
        if (res.status == 200) {
          setBankingInfo(res.data);
        }
    })
  }

  const loadPartnerAdminUser = (id: number, searchParam: SearchPartnerUserParam) => {
    return getPartnerUsers(id, searchParam)
      .then(res => {
        setPartnerAdminList(res.data.records);
        setPartnerUserPagination(setPagingInfo(
          searchParam.page,
          res.data.pageCount,
          searchParam.perPage
        ))
    })
  }

  const loadPartnerCommissionRates = (id: number) => {
    return getPartnerCommissionRates(id)
      .then(res => {
        setCommissionRateList(res.data.records);
      })
  }

  const loadPartnerDataDetail = async (id: number, provinces: Provinces[]) : Promise<PartnerDetail> => {
    return getPartnerDetailByAdmin(id)
      .then(res => {
        let data: PartnerDetail = convertResponseDataToPartnerDetail(res.data);
        assignProvinceName(data, provinces);
        setPartnerDetail(data);
        return data;
      })
  }

  const loadPartnerCommissionBalances = (id: number) => {
    getPartnerCommissionBalances(id).then(res => {
      setCommissionBalances(res.data);
    });
  }

  const assignProvinceName = (partnerData: PartnerDetail, provinces: Provinces[])=> {
   
    let billingProvince = provinces.find((f) => f.id == partnerData.partnerLocation.billingProvinceId);
    partnerData.partnerLocation.billingProvinceName = billingProvince ? billingProvince.name : "---";

    let mailingProvince = provinces.find((f) => f.id == partnerData.partnerLocation.mailingProvinceId);
    partnerData.partnerLocation.mailingProvinceName = mailingProvince ? mailingProvince.name : "---";
      
  }

  const convertResponseDataToPartnerDetail = (partnerDetailData: any): PartnerDetail => {
    
    const partnerInformation: PartnerInformation = partnerDetailData.partnerInformation;
    const partnerLocation: PartnerLocation = partnerInformation.partnerLocation;

    let partnerDetail: PartnerDetail = {
      id: partnerId,
      createdDate: partnerDetailData.createdDate,
      type: partnerInformation.type,
      contactFirstName: partnerInformation.contactFirstName,
      contactLastName: partnerInformation.contactLastName,
      contactEmail: partnerInformation.contactEmail,

      socialInsuranceNumber: partnerInformation.socialInsuranceNumber,

      companyEmail: partnerInformation.companyEmail,
      companyName: partnerInformation.companyName,
      businessNumber: partnerInformation.businessNumber,
      taxNumber: partnerInformation.taxNumber,

      isLicensed: partnerDetailData.isLicensed,
      partnerLocation: partnerLocation,
      partnerLicenseAgentInformation: partnerDetailData.licenseAgentInformation
    }

    return partnerDetail;
  }

  const getEmployerList = async (searchParam: SearchParamCompany) => {
    const res = await getEmployersByPartnerId(partnerId, searchParam);
    setEmployerList(res.data.records);
    setEmployerListPagination(setPagingInfo(
      searchParam.page,
      res.data.pageCount,
      searchParam.perPage
    ))
  }

  const getTransactions = async (searchParam: SearchPartnerTransactionParam) => {

    searchParam.fromDate = null != searchParam.fromDate ? moment(searchParam.fromDate).format(BE_DATE_FORMAT) : null;
    searchParam.toDate = null != searchParam.toDate ? moment(searchParam.toDate).format(BE_DATE_FORMAT) : null;

    const res = await getPartnerTransactions(partnerId, searchParam);
    setTransactions(res.data.records);
    setTransactionPagination(setPagingInfo(
      searchParam.page,
      res.data.pageCount,
      searchParam.perPage
    ))
  }

  const exportTransactions = async (searchParam: SearchPartnerTransactionParam) => {

    let exportCriteria: PartnerTransactionExportParam = { ...searchParam };
    delete exportCriteria.page;
    delete exportCriteria.perPage;

    exportCriteria.fromDate = null != exportCriteria.fromDate ? moment(exportCriteria.fromDate).format(BE_DATE_FORMAT) : null;
    exportCriteria.toDate = null != exportCriteria.toDate ? moment(exportCriteria.toDate).format(BE_DATE_FORMAT) : null;

    getExportPartnerTransactions(partnerId, exportCriteria)
      .then(
        (result) => {
          exportExcel(result.data);
        }
      );
  }

  const handleCloseAdjustmentModal = () => {
    setOpenAdjustmentModal(false);
  }

  const handleAdjustmentSuccess = () => {
    addLoading();
    loadPartnerCommissionBalances(partnerId);
    removeLoading();
  }

  const handleOpenPartnerAdminModal = (partnerUserId: number) => {

    let partnerAdmin = partnerAdminList.find((item) => item.id === partnerUserId);
    if (partnerAdmin) {
      partnerAdmin.roleId = partnerAdmin.partnerRole.id;
    }
   
    setPartnerAdminEdit(partnerAdmin || {...defaultPartnerAdmin});
    setOpenEditPartnerAdminModal(true);
  }

  const handleClosePartnerAdminEditModal = () => {
    setOpenEditPartnerAdminModal(false);
  }

  const handleModifyPartnerUserSuccess = () => {
    addLoading();
    loadPartnerAdminUser(partnerId, searchPartnerUserParam);
    removeLoading();
  }

  const handleOpenAddCommissionRateModal = () => {
    setOpenAddCommissionRateModal(true);
  }

  const showEditPartnerModel = () => {
    setOpenEditPartnerModal(true);
  }

  const hideEditPartnerModal = () => {
    setOpenEditPartnerModal(false);
  }

  const showUpdateBankingInformationModal = () => {
    setOpenEditBankingModal(true);
  }

  const hideUpdateBankingInformationModal = () => {
    setOpenEditBankingModal(false);
  }

  const handleEditPartnerSuccess = () => {
    addLoading();
    loadPartnerDataDetail(partnerId, provinces);
    removeLoading();
  }

  const handleEditPartnerBankingSuccess = () => {
    addLoading();
    loadBankingInformation(partnerId);
    removeLoading();
  }

  const handleChangePartnerUserPage = (page: number) => {
    setSearchPartnerUserParam({...searchPartnerUserParam, page});
  }

  const handleChangePartnerUserPerPage = (perPage: any) => {
    const { value } = perPage.target;
    setSearchPartnerUserParam({...searchPartnerUserParam, page: 1, perPage: value});
  }

  const handleSubmitCommissionRates = async (commissionRates: CommissionRate[]) => {
    addLoading();
    await updatePartnerCommissionRates(partnerId, commissionRates);
    removeLoading();
    loadPartnerCommissionRates(partnerId);
  }

  return (
    <>
      <StyledMainContent>
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-lg-3 mt-3">
              <StyledBigTitle>{partnerDetail.companyName || ""}</StyledBigTitle>
            </div>

            <div className="col-lg-2 mt-3">
              <StyledTitleBalance title="Balance in Account">
                Commission Pending
              </StyledTitleBalance>
              <StyledBalance
                color={theme.primaryColor}
                title={formatterUSD("currency", "USD").format(
                  centToUsd(commissionBalances.commissionPending)
                )}
              >
                {formatterUSD("currency", "USD").format(
                  centToUsd(commissionBalances.commissionPending)
                )}
              </StyledBalance>
            </div>
            <div className="col-lg-2 mt-3">
              <StyledTitleBalance title="Top Up Pending">
                Clawback Pending
              </StyledTitleBalance>
              <StyledBalance
                color={theme.primaryColor}
                title={formatterUSD("currency", "USD").format(
                  centToUsd(commissionBalances.clawbackPending)
                )}
              >
                {formatterUSD("currency", "USD").format(
                  centToUsd(commissionBalances.clawbackPending)
                )}
              </StyledBalance>
            </div>
            <div className="col-lg-2 mt-3">
              <StyledTitleBalance title="Claim Pending">
                Net Commission Earned
              </StyledTitleBalance>
              <StyledBalance
                color={theme.secondaryColor}
                title={formatterUSD("currency", "USD").format(
                  centToUsd(
                    commissionBalances.commissionPending - commissionBalances.clawbackPending
                  )
                )}
              >
                {formatterUSD("currency", "USD").format(
                  centToUsd(
                    commissionBalances.commissionPending - commissionBalances.clawbackPending
                  )
                )}
              </StyledBalance>
            </div>
            <div className="col-lg-3">
              <div className="d-flex justify-content-end align-items-end">
                <div className="w-65 mt-4">
                  <StyledOptionRequest submit={true} onClick={() => setOpenAdjustmentModal(true)}>
                    Adjustment
                  </StyledOptionRequest>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-6">
              <DetailInformation
                data={partnerDetail}
                model={DetailInformationDataModel}
                title="Partner Information"
                showEditModal={showEditPartnerModel}
              />
            </div>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-12">
                  <BankingInformation
                    bankingInfo={bankingInfo}
                    showUpdateBankingInformationModal={showUpdateBankingInformationModal}
                  />
                </div>
                <div className="col-12 mt-3">
                  <PartnerAdminManagement
                    data={partnerAdminList}
                    partnerId={partnerId}
                    handleOpenPartnerAdminModal={handleOpenPartnerAdminModal}
                    fetchData={loadPartnerAdminUser}
                    pagingInfo={partnerUserPagination}
                    searchParam={searchPartnerUserParam}
                    handleChangePage={handleChangePartnerUserPage}
                    handleChangePerPage={handleChangePartnerUserPerPage}
                  />
                </div>
                <div className="col-12 mt-3">
                  <CommissionRateManagement
                    data={commissionRateList}
                    handleOpenAddCommissionRateModal={handleOpenAddCommissionRateModal}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={"row mt-3"}>
            <div className={"col-12"}>
              <CompanyManagement
                data={employerList}
                fetchData={getEmployerList}
                pagingInfo={employerListPagination}
              />
            </div>
            <div className={"col-12"}>
              <Transaction
                data={transactions}
                fetchData={getTransactions}
                pagingInfo={transactionPagination}
                handleDownload={exportTransactions}
              />
            </div>
          </div>
        </div>
      </StyledMainContent>
      <PartnerAdjustmentModal
        open={openAdjustmentModal}
        title={"Adjustment"}
        handleClose={handleCloseAdjustmentModal}
        successCallBack={handleAdjustmentSuccess}
        partnerId={partnerId}
      />
      <EditPartnerModal
        open={openEditPartnerModal}
        successCallBack={handleEditPartnerSuccess}
        editPartner={partnerDetail}
        provinces={provinces}
        partnerId={partnerId}
        handleClose={hideEditPartnerModal}
      />
      <UpdateBankingInformationModal
        partnerId={partnerId}
        bankingInfoEdit={bankingInfo}
        successCallBack={handleEditPartnerBankingSuccess}
        open={openEditBankingModal}
        handleClose={hideUpdateBankingInformationModal}
      />
      <PartnerAdminManagementModal
        open={openEditPartnerAdminModal}
        title={"Add/Update Partner Admin"}
        handleClose={handleClosePartnerAdminEditModal}
        successCallBack={handleModifyPartnerUserSuccess}
        model={partnerAdminEdit}
        roles={roles}
        partnerId={partnerId}
      />
      <CommissionRateManagementModal
        open={openAddCommissionRateModal}
        title={"Edit Commission Rate"}
        handleClose={() => setOpenAddCommissionRateModal(false)}
        handleSubmit={handleSubmitCommissionRates}
        items={commissionRateList}
      />
    </>
  );
}

export default PartnerManagementDetail;
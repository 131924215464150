import React, { useState } from "react";
import { FinancialReportType } from "../DashboardModel";
import { BNPSelect } from "../../../../cores/helpers/select/select";
import { FinancialSummaryReport } from "./FinancialSummaryReport";
import { FinancialDetailReport } from "./FinancialDetailReport";
import { StyledBigTitle, StyledLargerTitle } from "../../../shared/styled";
import {FinancialComparisonReport} from "./FinancialComparisonReport";
import { AxiosResponse } from "axios";
import { TReportFinancial, TReportWallet } from "../DashboardType";

type Props = {
  getFinancialSummaryReport: (date: Date | string) => Promise<TReportFinancial<number>>;
  getFinancialComparisonReport: (fromDate: (Date | string), toDate: (Date | string)) => Promise<AxiosResponse>;
  getFinancialDetailReport: (fromDate: (Date | string), toDate: (Date | string)) => Promise<AxiosResponse>;
  getWalletSummaryReport: (date: Date | string) => Promise<TReportWallet<number>>;
  getWalletComparisonReport: (fromDate: (Date | string), toDate: (Date | string)) => Promise<AxiosResponse>;
  getWalletDetailReport: (fromDate: (Date | string), toDate: (Date | string)) => Promise<AxiosResponse>;
  exportFinancialSummaryReport: (date: Date | string) => void;
  exportFinancialComparisonReport: (fromDate: (Date | string), toDate: (Date | string)) => void;
  exportFinancialDetailReport: (fromDate: (Date | string), toDate: (Date | string)) => void;
};

export default function FinancialReport(props: Props) {
  const [reportType, setReportType] = useState<string>(
    FinancialReportType.summary.id
  );
  const options = Object.values(FinancialReportType).map((item) => ({
    id: item.id,
    name: item.title,
  }));

  function onChangeReportType(event: any) {
    const { value } = event.target;
    if (FinancialReportType[value])
      setReportType(FinancialReportType[value].id);
  }

  return (
    <>
      <div className="row pt-3 d-flex">
        <div className="col-lg-9 col-md-8 col-12">
          <StyledBigTitle>Financial Report</StyledBigTitle>
        </div>
        <div className="col-lg-3 col-md-4 col-12">
          <BNPSelect
            placeholder="Type of Report"
            options={options}
            value={reportType}
            onChange={onChangeReportType}
          />
        </div>
      </div>
      <div className="row pt-3">
        <div className="col-12 mb-3">
          <StyledLargerTitle>
            {FinancialReportType[reportType].title.toUpperCase()}
          </StyledLargerTitle>
        </div>
        {reportType === FinancialReportType.summary.id && (
          <FinancialSummaryReport
            getWalletSummaryReport={props.getWalletSummaryReport}
            getFinancialSummaryReport={props.getFinancialSummaryReport}
            exportFinancialSummaryReport={props.exportFinancialSummaryReport}
          />
        )}
        {reportType === FinancialReportType.comparison.id && (
          <FinancialComparisonReport
            getWalletComparisonReport={props.getWalletComparisonReport}
            getFinancialComparisonReport={props.getFinancialComparisonReport}
            exportFinancialComparisonReport={props.exportFinancialComparisonReport}
          />
        )}
        {reportType === FinancialReportType.detailed.id && (
          <FinancialDetailReport
            getWalletDetailReport={props.getWalletDetailReport}
            getFinancialDetailReport={props.getFinancialDetailReport}
            exportFinancialDetailReport={props.exportFinancialDetailReport}
          />
        )}
      </div>
    </>
  );
}

import React from "react";
import Profile from "../../../cores/config/component/images/profile.jpg";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { capFirst } from "../../../cores/helpers/cap-first";
import { showChangePasswordModal } from "./change-password";
import { getInfoByToken, signOut } from "../../../cores/utils/helpers";
import { signInURL } from "../../../cores/constants/url-config";

const StyledHeaderRight = styled.span`
  position: absolute;
  color: #2d2d2d;
  right: 30px;
  top: 14px;
  font-family: ${(props) => props.theme.textFont} !important;
`;

const StyledText = styled.span`
  margin-right: 0.5em;
  font-size: 14px;
`;

const StyledImgProfile = styled.img`
  height: 28px;
  width: 28px;
  border-radius: 50%;
  position: relative;
  top: -2px;
`;

const StyledLink = styled(Link)`
  font-weight: 500;
  font-size: 16px;
  height: 30px;
  color: ${(props) => props.theme.primaryColor};
  cursor: pointer;
  padding: 5px 0;
  :hover {
    color: ${(props) => props.theme.primaryColor};
    opacity: 0.7;
    text-decoration: none;
  }
  position: relative;
  top: 5px;
`;
const StyledDivChangePassword = styled.div`
  font-weight: 500;
  font-size: 16px;
  height: 30px;
  color: ${(props) => props.theme.primaryColor};
  cursor: pointer;
  padding: 5px 0;
  :hover {
    color: ${(props) => props.theme.primaryColor};
    opacity: 0.7;
  }
`;

const StyledUl = styled.ul`
  padding: 8px 20px !important;
`;
const StyledLi = styled.li`
  height: 30px;
`;

export default function Header() {
  const decoded = getInfoByToken();
  const name = decoded.permission ? decoded.permission : "";
  const username = decoded.firstName ? `${decoded.firstName} ${decoded.lastName}` : "";

  return (
    <div>
      <StyledHeaderRight>
        <div className="dropdown mt-1 cur">
          <span className="dropdown-toggle" data-toggle="dropdown">
            <StyledText>{`${username} (${capFirst(name)})`}</StyledText>
            <StyledImgProfile src={Profile} alt="profile" />
          </span>
          <StyledUl className="dropdown-menu">
            <StyledLi className="dropdown">
              <StyledDivChangePassword onClick={showChangePasswordModal}>
                Change password
              </StyledDivChangePassword>
            </StyledLi>
            <StyledLi className="dropdown">
              <StyledLink
                onClick={() => {
                  signOut();
                }}
                to={signInURL}
              >
                Sign out
              </StyledLink>
            </StyledLi>
          </StyledUl>
        </div>
      </StyledHeaderRight>
    </div>
  );
}

import React, {useMemo} from 'react';
import styled from "styled-components";
import CreateIcon from "@material-ui/icons/Create";
import {StyledContentBox, StyledLargerTitle} from "../../../../shared/styled";
import BNPRawTable from "../../../../shared/BNPTable/BNPRawTable";
import {CommissionRateTableColumns} from "./CommissionRateManagementModel";
import {round} from "lodash";
import {StyledCellTable} from "../../../../shared/table-styled";

export const StyledSpanEditInfo = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  float: right;
  cursor: pointer;
  font-family: ${(props) => props.theme.textFont};
  :hover {
    opacity: 0.7;
  }
  position: relative;
`;

type Props = {
  data: any[];
  handleOpenAddCommissionRateModal?: () => void;
};

const StyledCellTableCustomer = styled(StyledCellTable)`
:last-child {
    text-align: unset;
}
`

export const CommissionRateManagement = (props: Props) => {
  const tableData = useMemo(() => props.data.map(item => ({
    ...item,
    partnerRate: round(item.partnerRate/10000,2),
    beniplusRate: round(1 - item.partnerRate/10000, 2),
  })), [props.data]);

  return (
    <>
      <StyledContentBox>
        <StyledLargerTitle>
          Commission Structure
          {!!props.handleOpenAddCommissionRateModal &&
            <StyledSpanEditInfo
              onClick={() => !!props.handleOpenAddCommissionRateModal &&
                props.handleOpenAddCommissionRateModal()
            }>
              <CreateIcon fontSize="small"/> Edit
            </StyledSpanEditInfo>
          }
        </StyledLargerTitle>
        <BNPRawTable
          columns={CommissionRateTableColumns}
          tableWidth="100%"
          tableData={tableData}
          cellComponent={StyledCellTableCustomer}
        />
      </StyledContentBox>
    </>
  );
};


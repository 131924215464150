import {
  TReportCommission,
  TReportFinancial,
  TReportWallet
} from "./DashboardType";
import {TableSortType} from "../../shared/BNPTable/BNPTableType";

export type TStringKey<TValue> = {
  [key: string]: TValue;
};

export const WalletReportType: TStringKey<any> = {
  summary: {
    id: "summary",
    title: "Summary",
  },
  comparison: {
    id: "comparison",
    title: "Comparison",
  },
  detailed: {
    id: "detailed",
    title: "Detailed",
  },
};

export const FinancialReportType: TStringKey<any> = {
  summary: {
    id: "summary",
    title: "Summary",
  },
  comparison: {
    id: "comparison",
    title: "Comparison",
  },
  detailed: {
    id: "detailed",
    title: "Detailed",
  },
};

export const defaultWalletReport: TReportWallet<number> = {
  noOfEmployer: 0,
  noOfEmployee: 0,
  totalSize: 0,
  averageSize: 0,
};

export const CommissionReportTitleMap: TReportCommission<string> = {
  totalClaimSize: "Total Claim Size",
  totalResellerMarkup: "Total Reseller Markup",
  averageMarkupPercentage: "Average Markup Percentage",
  commissionEarned: "Commission Earned",
  platformFee: "Platform Fee",
  adjudicationFee: "Adjudication Fee",
  totalReimbursedFee: "Total Reimbursed Fee"
};

export const WalletReportTitleMap: TReportWallet<string> = {
  noOfEmployer: "Number of Employer Wallets",
  noOfEmployee: "Number of Employee Wallets",
  totalSize: "Total Wallet Size",
  averageSize: "Average Wallet Size (active wallets)",
};

export const FinancialReportTitleMap: TReportFinancial<string> = {
  totalWalletSize: "Total Wallet Size",
  averageWalletSize: "Avg Wallet Size",
  utilization: "Utilization",
  claims: "Claims",
  adminFees: "Admin Fees",
  taxes: "Taxes",
};

export const CommissionReportColumn: TableSortType[] = [
  {
    columnId: "totalClaimSize",
    columnName: "Total Claim Size",
    width: 17,
    sortType: null,
    sortColumn: false,
  },
  {
    columnId: "totalResellerMarkup",
    columnName: "Total Reseller Markup",
    width: 17,
    sortType: null,
    sortColumn: false,
  },
  {
    columnId: "averageMarkupPercentage",
    columnName: "Avg. Markup Percentage",
    width: 17,
    sortType: null,
    sortColumn: false,
  },
  {
    columnId: "commissionEarned",
    columnName: "Commission Earned",
    width: 17,
    sortType: null,
    sortColumn: false,
  },
  {
    columnId: "platformFee",
    columnName: "Platform Fee",
    width: 17,
    sortType: null,
    sortColumn: false,
  },
  {
    columnId: "adjudicationFee",
    columnName: "Adjudication Fee",
    width: 17,
    sortType: null,
    sortColumn: false,
  },
  {
    columnId: "totalReimbursedFee",
    columnName: "Total Reimbursed Fee",
    width: 17,
    sortType: null,
    sortColumn: false,
  },
];

export const CommissionDetailReportColumn: TableSortType[] = [
  {
    columnId: "month",
    columnName: "",
    width: 17,
    sortType: null,
    sortColumn: false,
  },
  ...CommissionReportColumn
]

export const WalletReportColumn: TableSortType[] = [
  {
    columnId: "noOfEmployer",
    columnName: "Employer Wallets",
    width: 25,
    sortType: null,
    sortColumn: false,
  },
  {
    columnId: "noOfEmployee",
    columnName: "Employee Wallets",
    width: 25,
    sortType: null,
    sortColumn: false,
  },
  {
    columnId: "totalSize",
    columnName: "Total Wallet Size",
    width: 25,
    sortType: null,
    sortColumn: false,
  },
  {
    columnId: "averageSize",
    columnName: "Avg Wallet Size",
    width: 25,
    sortType: null,
    sortColumn: false,
  },
];

export const WalletDetailReportColumn: TableSortType[] = [
  {
    columnId: "month",
    columnName: "",
    width: 25,
    sortType: null,
    sortColumn: false,
  },
  ...WalletReportColumn
]

export const FinancialReportColumn: TableSortType[] = [
  {
    columnId: "totalWalletSize",
    columnName: "Total Wallet Size",
    width: 20,
    sortType: null,
    sortColumn: false,
  },
  {
    columnId: "averageWalletSize",
    columnName: "Avg Wallet Size",
    width: 20,
    sortType: null,
    sortColumn: false,
  },
  {
    columnId: "claims",
    columnName: "Claims",
    width: 20,
    sortType: null,
    sortColumn: false,
  },
  {
    columnId: "adminFees",
    columnName: "Admin Fee",
    width: 20,
    sortType: null,
    sortColumn: false,
  },
  {
    columnId: "taxes",
    columnName: "Taxes",
    width: 20,
    sortType: null,
    sortColumn: false,
  },
];

export const FinancialDetailReportColumn: TableSortType[] = [
  {
    columnId: "month",
    columnName: "",
    width: 17,
    sortType: null,
    sortColumn: false,
  },
  ...FinancialReportColumn
]

export const WalletComparisonReportColumn: TableSortType[] = [
  {
    columnId: "metric",
    columnName: "",
    width: 25,
    sortType: null,
    sortColumn: false,
  },
  {
    columnId: "earlier",
    columnName: "Date A (earlier)",
    width: 25,
    sortType: null,
    sortColumn: false,
  },
  {
    columnId: "later",
    columnName: "Date B (later)",
    width: 25,
    sortType: null,
    sortColumn: false,
  },
  {
    columnId: "comparison",
    columnName: "Comparison",
    width: 25,
    sortType: null,
    sortColumn: false,
  },
];
import React, { useEffect, useState } from "react";
import "./App.css";
import { Message } from "./cores/utils/message";
import RouteLayout from "./components/shared/layout/route-layout";
import { Redirect, Switch, BrowserRouter } from "react-router-dom";
import ClaimManagement from "./components/pages/claim-management/claim-management";
import { ThemeProvider } from "styled-components";
import Theme from "../src/cores/helpers/theme";
import ClaimManagementDetail from "./components/pages/claim-management/detail/claim-management-detail";
import SignIn from "./components/pages/sign-in/sign-in";
import RouteLayoutSignIn from "./components/shared/sign-in-layout/sign-in-route-layout";
import AdminCompanyManagement from "./components/pages/company-management/admin-company-management";
import PartnerCompanyManagement from "./components/pages/company-management/partner-company-management";
import CompanyManagementDetail from "./components/pages/company-management/detail/company-management-detail";
import { StyledApp } from "./components/shared/styled";
import ManageAdmin from "./components/pages/manage-admin/manage-admin";
import { Loading } from "./cores/utils/loading";
import CompanyManagementEmployeeDetail from "./components/pages/company-management/detail-employee/detail-employee";
import { googleAPIKey } from "./cores/config/config";
import ClaimHistory from "./components/pages/claim-history/claim-history";
import EFTExport from "./components/pages/eft-export/eft-export";
import {
  claimManagementURL,
  claimManagementDetailURL,
  companyManagementURL,
  companyManagementDetailURL,
  companyManagementDetailEmployeeURL,
  adminManagementURL,
  claimHistoryURL,
  exportEftFileURL,
  homePage,
  signInURL,
  claimManagementSearchParamDefault,
  taxURL,
  depositWithdrawTransactionURL,
  contactURL,
  depositWithdrawTransactionHistoryURL,
  requestDepositWithdrawTransactionURL,
  emailHistoryURL, employeesManagementUrl,
  rrspExportURL,
  adminDashboardURL,
  requiredTopUpReportURL,
  forgotPasswordURL,
  partnerDashboardURL,
  partnerCompanyManagementURL,
  partnerEmployeeManagementURL,
  partnerProfileURL,
  updatePasswordURL,
  partnerManagementURL,
  partnerDetailURL,
  partnerCompanyManagementDetailURL,
  partnerCompanyManagementDetailEmployeeURL,
  marketplaceURL,
} from "./cores/constants/url-config";
import ReportTax from "./components/pages/report/tax";
import RequestDepositWithdrawTransaction from "./components/pages/request-deposit-withdraw-transaction/request-deposit-withdraw-transaction";
import DepositWithdrawTransaction from "./components/pages/deposit-withdraw-transaction/deposit-withdraw-transaction";
import Contact from "./components/pages/contact/contact";
import DepositWithdrawTransactionHistory from "./components/pages/deposit-withdraw-transaction-history/deposit-withdraw-transaction-history";
import EmailHistory from "./components/pages/email-history/email-history";
import AdminEmployeeManagement from "./components/pages/employees-management/admin-employee-management";
import PartnerEmployeeManagement from "./components/pages/employees-management/partner-employee-management";
import { ConfirmDialog } from "./cores/utils/confirmDialog";
import RRSPExport from "./components/pages/rrsp-export/RRSPExport";
import Dashboard from "./components/pages/dashboard/Dashboard";
import RequiredTopUpReport from "./components/pages/required-topup-report/required-topup-report";
import { getEmployerConfigurationInfo } from "./cores/utils/helpers"
import { EmployerConfiguration } from "./models/employer-configuration/employer-configuration-model"
import PartnerRouteLayout from "./components/shared/layout/partner-route-layout";
import PartnerDashboard from "./components/pages/partner-portal/dashboard/Dashboard";
import PartnerProfile from "./components/pages/partner-portal/profile/profile";
import ForgotPassword from "./components/pages/forgot-password/forgot-password";
import UpdatePassword from "./components/pages/forgot-password/update-password";
import PartnerManagement from "./components/pages/partner-management/partner-management";
import PartnerManagementDetail from "./components/pages/partner-management/partner-detail/partner-detail";
import Marketplace from "./components/pages/marketplace/marketplace";

const App: React.FC = () => {

  const [employerConfiguration, setEmployerConfiguration] = useState<EmployerConfiguration>({
    enablePresetBankingInformation: false,
  });


  useEffect(() => {
    // Start of  google api script
    const googleApi = document.createElement("script");

    googleApi.type = "text/javascript";
    googleApi.src = `https://maps.googleapis.com/maps/api/js?key=${googleAPIKey}&libraries=places`;
    googleApi.async = true;
    document.body.appendChild(googleApi);
    // End of  google api script

    // Start of  Zendesk Widget script
    // const zenDesk = document.createElement("script");

    // zenDesk.type = "text/javascript";
    // zenDesk.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskAPIKey}`;
    // zenDesk.async = true;
    // zenDesk.id = "ze-snippet";
    // document.body.appendChild(zenDesk);
    // End of  Zendesk Widget script

    getEmployerConfigurationInfo()
    .then((result => {
      setEmployerConfiguration({
        enablePresetBankingInformation: result.enablePresetBankingInformation
      })
    }))

    return () => {
      document.body.removeChild(googleApi);
      // document.body.removeChild(zenDesk);
    };
  }, []);

  return (
    <ThemeProvider theme={Theme}>
      <div className="App">
        <StyledApp>
          <Message />
          <Loading />
          <ConfirmDialog />
          <BrowserRouter>
            <Switch>
              <RouteLayoutSignIn exact path={signInURL} component={SignIn} />
              <RouteLayoutSignIn exact path={forgotPasswordURL} component={ForgotPassword} />
              <RouteLayoutSignIn exact path={updatePasswordURL} component={UpdatePassword} />

              {/* Route for partner portal */}
              <PartnerRouteLayout
                exact
                path={partnerDashboardURL}
                component={PartnerDashboard}
              />
              <PartnerRouteLayout
                exact
                path={partnerCompanyManagementURL}
                component={PartnerCompanyManagement}
              />
              <PartnerRouteLayout
                exact
                path={partnerCompanyManagementDetailURL}
                component={CompanyManagementDetail}
                {...employerConfiguration}
              />
              <PartnerRouteLayout
                exact
                path={partnerEmployeeManagementURL}
                component={PartnerEmployeeManagement}
              />
              <PartnerRouteLayout
                exact
                path={partnerCompanyManagementDetailEmployeeURL}
                component={CompanyManagementEmployeeDetail}
              />
              <PartnerRouteLayout
                exact
                path={partnerProfileURL}
                component={PartnerProfile}
              />

              <RouteLayout
                exact
                path={claimManagementURL}
                component={ClaimManagement}
              />
              <RouteLayout
                exact
                path={claimManagementDetailURL}
                component={ClaimManagementDetail}
              />
              <RouteLayout
                exact
                path={companyManagementURL}
                component={AdminCompanyManagement}
              />
              <RouteLayout
                exact
                path={companyManagementDetailURL}
                component={CompanyManagementDetail}
                {...employerConfiguration}
              />

              <RouteLayout
                exact
                path={companyManagementDetailEmployeeURL}
                component={CompanyManagementEmployeeDetail}
              />

              <RouteLayout
                exact
                path={employeesManagementUrl}
                component={AdminEmployeeManagement}
              />

              <RouteLayout
                exact
                path={adminDashboardURL}
                component={Dashboard}
              />

              <RouteLayout
                exact
                path={adminManagementURL}
                component={ManageAdmin}
              />
              <RouteLayout
                exact
                path={claimHistoryURL}
                component={ClaimHistory}
              />
              <RouteLayout
                exact
                path={rrspExportURL}
                component={RRSPExport}
              />
              <RouteLayout
                exact
                path={emailHistoryURL}
                component={EmailHistory}
              />

              <RouteLayout
                exact
                path={exportEftFileURL}
                component={EFTExport}
              />
              <RouteLayout exact path={taxURL} component={ReportTax} />
              
              <RouteLayout
                exact
                path={requestDepositWithdrawTransactionURL}
                component={RequestDepositWithdrawTransaction}
              />
              <RouteLayout
                exact
                path={depositWithdrawTransactionHistoryURL}
                component={DepositWithdrawTransactionHistory}
              />

              <RouteLayout
                exact
                path={depositWithdrawTransactionURL}
                component={DepositWithdrawTransaction}
              />

              <RouteLayout path={contactURL} component={Contact} />

              <RouteLayout
                exact
                path={requiredTopUpReportURL}
                component={RequiredTopUpReport}
              />

              <RouteLayout
                exact
                path={partnerManagementURL}
                component={PartnerManagement}
              />

              <RouteLayout
                exact
                path={partnerDetailURL}
                component={PartnerManagementDetail}
              />

              <RouteLayout
                exact
                path={marketplaceURL}
                component={Marketplace}
              />

              <Redirect to={homePage + claimManagementSearchParamDefault} />
            </Switch>
          </BrowserRouter>
        </StyledApp>
      </div>
    </ThemeProvider>
  );
};

export default App;

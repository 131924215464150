import React, {useCallback, useEffect, useMemo, useState} from 'react';
import EmployeeManagementView from "./employees-management-view";
import {addLoading, removeLoading} from "../../../cores/utils/loading";
import {PagingInfo, setPagingInfo} from "../../../cores/helpers/pagination/pagination";
import {TerminatingRequest} from "../../../../../src/components/pages/employer/employees/employee-type";
import {
  banEmployeeByAdmin,
  putSuspendEmployee,
  putUnSuspendEmployee,
  reActivateEmployee,
  terminatingEmployees
} from "../../../services/company-service";
import { publishMessage } from "../../../cores/utils/message"
import {Employee} from "../company-management/company-management-type";
import {RouteChildrenProps} from "react-router";
import {TableSortType} from "../../shared/BNPTable/BNPTableType";
import { SearchResult } from "../../../services/employee-service";
import {
  companyManagementDetailEmployeeURL,
  partnerCompanyManagementDetailEmployeeURL
} from "../../../cores/constants/url-config";
import { exportExcel } from '../../../cores/helpers/export-file/export-file';
import { AxiosResponse } from 'axios';

type Props = RouteChildrenProps & {
  columns: TableSortType[];
  fetchData: (searchTerm: string, employeeStatus: string, t4EmployeeStatus: string, currentPage: number,
              rowsPerPage: number, sorts: Record<string, any>) => Promise<SearchResult>;
  exportExcel: (searchTerm: string, employeeStatus: string, t4EmployeeStatus: string, currentPage: number,
    rowsPerPage: number, sorts: Record<string, any>) => Promise<AxiosResponse>;
  isPartnerView: boolean;
}

export type ManageEmployeeSearchParam = {
  searchTerm: string;
  employeeStatus: string;
  t4Employee: string;
  currentPage: number;
  rowsPerPage: number;
}

function EmployeeManagementComponent(props: Props) {
  const [searchParam, setSearchParam] = useState<ManageEmployeeSearchParam>({
    searchTerm: "",
    employeeStatus: "",
    t4Employee: "",
    currentPage: 1,
    rowsPerPage: 10
  });
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [sorts, setSorts] = useState<Record<string, "ASC" | "DESC">>({});
  const [paging, setPaging] = useState<PagingInfo>(setPagingInfo(1, 0, 10))

  const onChangeSearchTerm = useCallback((value: string) => {
    setSearchParam(prev => ({...prev, searchTerm: value, currentPage: 1}));
  }, []);

  const onSelectFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target;
    const searchParamCopy = {...searchParam};
    if (name === "employeeStatus") {
      if (typeof value === "number") {
        searchParamCopy.employeeStatus = "";
      } else {
        searchParamCopy.employeeStatus = value;
      }
    } else if (name === "t4Status") {
      if (typeof value === "number") {
        searchParamCopy.t4Employee = "";
      } else {
        searchParamCopy.t4Employee = value;
      }
    }

    searchParamCopy.currentPage = 1;

    setSearchParam(searchParamCopy);
  };

  const exportListEmployeeToExcel = () => {
    props.exportExcel(
      searchParam.searchTerm,
      searchParam.employeeStatus,
      searchParam.t4Employee,
      searchParam.currentPage,
      searchParam.rowsPerPage,
      sorts
    ).then(
      (result) => {
        exportExcel(result.data);
      }
    );
  }

  const sortTable = useCallback((columnId: string) => {
    const sortableColumns = ["firstName", "email", "companyName", "referralPartnerName", "referralPartnerUserName"];
    if (!sortableColumns.includes(columnId)) return;
    const currentColumnSort = sorts[columnId];
    const newSort: Record<string, "ASC" | "DESC"> = {}
    if (currentColumnSort === "ASC") {
      newSort[columnId] = "DESC";
    } else {
      newSort[columnId] = "ASC"
    }
    setSorts(newSort);
  }, [sorts]);

  const columnsWithSorts = useMemo(() => props.columns.map(column => ({
    ...column,
    sortType: sorts[column.columnId] || null
  })), [props.columns, sorts])

  const searchEmployees = () => {
    addLoading();
    if (searchParam.currentPage > 0) props.fetchData(
        searchParam.searchTerm,
        searchParam.employeeStatus,
        searchParam.t4Employee,
        searchParam.currentPage,
        searchParam.rowsPerPage,
        sorts
      ).then((result) => {
        setEmployees(result.records);
        let newCurrentPage = result.pageCount < searchParam.currentPage ? result.pageCount : searchParam.currentPage;
        // if (newCurrentPage !== currentPage) {
        //   setCurrentPage(newCurrentPage);
        // }
        setPaging(setPagingInfo(newCurrentPage, result.pageCount, searchParam.rowsPerPage));
        removeLoading();
      })
      .catch(() => {
        removeLoading();
      })
    ;
  };

  const handleChangePage = useCallback((e: any) => {
    setSearchParam(prev => ({...prev, currentPage: e}));
  }, []);

  const handleSetRowsPerPage = useCallback((e: any) => {
    setSearchParam(prev => ({...prev, rowsPerPage: e.target.value, currentPage: 1}));
  },[]);

  const handleSortTable = useCallback((e: any) => {
    sortTable(e);
  }, [sortTable])

  const handleSubmitTerminatedEmployee = async (model: TerminatingRequest) => {

    terminatingEmployees(model).then(() => {
      searchEmployees();

      publishMessage({
        variant: "success",
        message: "Employee deactivation successfully.",
      });
    });
  };

  const handleSuspendEmployee = (id: number, fromDate: Date | string, toDate: Date | string) => {
    putSuspendEmployee(id, fromDate, toDate).then(() => {
      searchEmployees();

      publishMessage({
        variant: "success",
        message: "Suspend employee successfully.",
      });
    });
  };
  const handleUnSuspendEmployee = (id: number) => {
    putUnSuspendEmployee(id).then(() => {
      searchEmployees();

      publishMessage({
        variant: "success",
        message: "Un-suspend employee successfully.",
      });
    });
  };

  // delete employee
  const deleteEmployee = (employeeId: number) => {
    banEmployeeByAdmin(employeeId).then(() => {
      searchEmployees();
    });
  };

  const handleReActivateEmployee = (id: number) => {
    // handle re-activate employee
    addLoading();
    reActivateEmployee(id)
      .then(() => {
        publishMessage({
          variant: "success",
          message: "Re-activate employee successfully.",
        });
      })
      .finally(() => {
      removeLoading();
      searchEmployees();
    });
  }

  useEffect(() => {
    searchEmployees();
  }, [sorts, searchParam])

  return <EmployeeManagementView
    onChangeSearchTerm={onChangeSearchTerm}
    onSelectFilter={onSelectFilter}
    exportListEmployeeToExcel={exportListEmployeeToExcel}
    columns={columnsWithSorts}
    employees={employees}
    handleChangePage={handleChangePage}
    pagingInfo={paging}
    setRowsPerPage={handleSetRowsPerPage}
    sortTable={handleSortTable}
    handleTerminatedEmployee={handleSubmitTerminatedEmployee}
    handleSuspendEmployee={handleSuspendEmployee}
    handleUnSuspendEmployee={handleUnSuspendEmployee}
    handleReActivateEmployee={handleReActivateEmployee}
    deleteEmployee={deleteEmployee}
    searchEmployees={searchEmployees}
    detailLink={props.isPartnerView ? partnerCompanyManagementDetailEmployeeURL : companyManagementDetailEmployeeURL}
    searchParam={searchParam}
  />
}

export default EmployeeManagementComponent;
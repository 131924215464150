import React from "react";
import { StyledSpanAction } from "../employees-management/employee-action";

type Props = {
  vendor: any;
  updateVendorStatus: (id: number, status: string, name: string) => void;
  handleEditVendor: (id: number) => void;
};

function VendorAction(props: Props) {
  const { vendor } = props;

  return (
    <>
      <StyledSpanAction
        title="Edit"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          props.handleEditVendor(vendor.id);
        }}
      >Edit</StyledSpanAction>
      {vendor.status === "ACTIVE" && (
        <StyledSpanAction
          title="Disable"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            props.updateVendorStatus(vendor.id, "INACTIVE", vendor.name)
          }}
        >
          Disable
        </StyledSpanAction>
      )}
      {vendor.status === "INACTIVE" && (
        <StyledSpanAction
          title="Enable"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            props.updateVendorStatus(vendor.id, "ACTIVE", vendor.name)
          }}
        >
          Enable
        </StyledSpanAction>
      )}
    </>
  );
}

export default VendorAction;

import {TableSortType} from "../../shared/BNPTable/BNPTableType";

export const rrspHeaders: TableSortType[] = [
  {
    columnId: "createdDate",
    columnName: "Created Date",
    sortType: 'DESC',
    width: 20,
    sortColumn: true,
  },
  {
    columnId: "fileName",
    columnName: "File Name",
    sortType: null,
    width: 35,
    sortColumn: true,
  },
  {
    columnId: "month",
    columnName: "Report Month",
    sortType: null,
    width: 20,
    sortColumn: true,
  },
  {
    columnId: "action",
    columnName: "Download",
    sortType: null,
    width: 25,
    sortColumn: false,
  },
];
import React, {useEffect, useState} from "react";
import {
  StyledMainContent,
  StyledBigTitle,
  StyledContentBox,
  StyledButtonSubmit,
} from "../../shared/styled";
import styled, {css} from "styled-components";
import { EFTRequest } from "./eft-information/eft-information-type";
import {TStringKey} from "../dashboard/DashboardModel";
import EFTInformation from "./eft-information/eft-information";
import {EftExportHistory} from "./eft-history/eft-export-history";
import EftBlacklist from "./eft-blacklist/eft-blacklist";
import {RouteChildrenProps} from "react-router";

const StyledReportNavBar = styled.div`
    width: fit-content;
    min-width: 210px;
    background: none;
    display: flex;
    justify-content: space-between;
`;

const StyledReportNavBarItem = styled.div`
    overflow: hidden;
    line-height: 16px;
    display: flex;
    justify-content: center;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 32px;

    :hover {
        background-color: #f7f7f8;
    }

    ${(props: { active: boolean }) =>
  props.active &&
  css`
      background-color: ${(props) => props.theme.infoColor};
      color: #fff;
      font-weight: bold;

      :hover {
        background-color: ${(props) => props.theme.infoColor};
        opacity: 0.8;
      }
    `}
`;

const StyledErrorMessage = styled.div`
  padding: 9px 5px;
  width: 100%;
  color: ${(props) => props.theme.secondaryColor};
`;

type Props = {
  eftInfo: EFTRequest;
} & RouteChildrenProps;

type TExportEFTTab = {
  id: string;
  title: string;
};

const ExportEFTTab: TStringKey<TExportEFTTab> = {
  eftHistory: {
    id: "eftHistory",
    title: "EFT History",
  },
  eftInfo: {
    id: "eftInfo",
    title: "EFT Information",
  },
  eftBlacklist: {
    id: "eftBlacklist",
    title: "EFT Blacklist",
  },
};

export default function EFTExport(props: Props) {
  const [activeTab, setActiveTab] = useState<string>(
    Object.keys(ExportEFTTab)[0]
  );

  useEffect(() => {
    const url = new URLSearchParams(window.location.search);
    const tab = url.get("tab");
    if (tab) {
      setActiveTab(tab);
    }
  }, [window.location.search]);

  function handleChangeTab(type: string) {
    let url = new URL(window.location.href);
    url.searchParams.set("tab", type);
    props.history.push(url.search)
  }

  return (
    <StyledMainContent>
      <StyledBigTitle>Export EFT File</StyledBigTitle>
      <div className="container-fluid p-0 mt-3">
        <StyledContentBox>
          <div className="row">
            <div className="col-12 pt-2 mb-5">
              <EFTExportNav
                activeTab={activeTab}
                handleChangeTab={handleChangeTab}
              />
            </div>
            <div className="col-12">
              {activeTab === ExportEFTTab.eftInfo.id && (<EFTInformation/>)}
              {activeTab === ExportEFTTab.eftHistory.id && (<EftExportHistory/>)}
              {activeTab === ExportEFTTab.eftBlacklist.id && (<EftBlacklist/>)}
            </div>
          </div>
        </StyledContentBox>
      </div>
    </StyledMainContent>
  );
}

function EFTExportNav(props: {
  activeTab: string;
  handleChangeTab: (type: string) => void;
}) {
  return (
    <StyledReportNavBar>
      {Object.keys(ExportEFTTab).map((type: string) => (
        <div key={`report-nav-bar-item-${type}`}>
          <StyledReportNavBarItem
            active={type === props.activeTab}
            onClick={() => props.handleChangeTab(type)}
          >
            {ExportEFTTab[type].title}
          </StyledReportNavBarItem>
        </div>
      ))}
    </StyledReportNavBar>
  );
}

import React, {useMemo} from 'react';
import {BNPModal} from "../../../../../shared/BNPModal/BNPModal";
import {addLoading, removeLoading} from "../../../../../../cores/utils/loading";
import {BNPSelect} from "../../../../../../cores/helpers/select/select";
import styled from "styled-components";
import {EmployerBenefitClass, EmployerPlanWithSettings} from "../plan-type";
import {StyledButtonSubmit, StyledPTitle, StyledSpanTitle} from "../../../../../shared/styled";
import {
  getEmployeesByEmployerClassBenefitId,
  getEmployerPlans,
  putChangeEmployeeClassBenefit
} from "../../../../../../services/company-service";
import {toUTCTime} from "../../../../../../cores/helpers/to-utc-time";
import BNPDatePicker from "../../../../../../cores/helpers/date-picker/date-picker";
import {formatterUSD} from "../../../../../../cores/helpers/format-usd";
import {centToUsd} from "../../../../../../cores/helpers/cent-to-usd";
import moment from 'moment';

const StyledModalWrapper = styled.div`
    background-color: ${props => props.theme.backgroundColor.lightBlue};
    align-items: center;
    justify-content: center;
    padding: 1.5rem 1rem;
`;

const StyledModalText = styled.div`
    font-size: 1.125rem;
    font-weight: 400;
    font-family: ${props => props.theme.textFont};
    line-height: 1.75rem;
`;

const StyledModalTitleText = styled.div`
    font-size: 0.875rem;
    font-weight: 400;
    font-family: ${props => props.theme.textFont};
    line-height: 1.25rem;
    color: ${props => props.theme.grey[500]};
`;

type ExistingEmployeeType = {
  id: number;
  firstName: string;
  lastName: string;
}

type ExistingEmployeeWithEmployerClassBenefitIdType = ExistingEmployeeType & {
  employerPlanId: number;
  employerClassBenefitId: number;
}

type Props = {
  open: boolean;
  handleClose: () => void;
  employerPlanId: number;
  employerClassBenefitId: number | null;
  handleDeleteClass: (employerClassBenefitId: number) => void;
};

function DeleteClassModal(props: Props) {
  const urlParams = new URLSearchParams(window.location.search);
  const employerId = Number(urlParams.get('id')) || 0;

  const [className, setClassName] = React.useState("");
  const [existingEmployees, setExistingEmployees] = React.useState<ExistingEmployeeType[]>([]);
  const [selectedEmployees, setSelectedEmployees] = React.useState<ExistingEmployeeWithEmployerClassBenefitIdType[]>([]);
  const [employerPlans, setEmployerPlans] = React.useState<EmployerPlanWithSettings[]>([] as EmployerPlanWithSettings[]);
  const [classBenefitFromDate, setClassBenefitFromDate] = React.useState<Date>(new Date());

  React.useEffect(() => {
    addLoading();
    Promise.all([
      getExistingEmployees(),
      getEmployerPlansData()
    ]).then(() => removeLoading());
  }, [props.open]);

  const getExistingEmployees = async () => {
    if (!props.employerClassBenefitId) return;
    // API Call
    const response = await getEmployeesByEmployerClassBenefitId(employerId, props.employerClassBenefitId);
    if (response.data) {
      setExistingEmployees(response.data);
      setSelectedEmployees(response.data.map((employee: any) => ({
        ...employee,
        employerPlanId: props.employerPlanId,
        employerClassBenefitId: -1
      })));
    }
  }

  const getEmployerPlansData = async () => {
    const response = await getEmployerPlans(employerId);
    if (response.data) {
      setEmployerPlans(response.data);

      response.data.flatMap((plan: EmployerPlanWithSettings) => plan.classBenefits)
        .forEach((classItem: EmployerBenefitClass) => {
          if (classItem.id === props.employerClassBenefitId) {
            setClassName(classItem.className);
          }
        })}
  }

  const currentEmployerClassBenefit = useMemo(() => {
    return employerPlans
      .flatMap(item => item.classBenefits)
      .find(item => item.id === props.employerClassBenefitId)
  }, [employerPlans, props.employerClassBenefitId])

  const handleChangePlan = (employeeId: number, planId: number) => {
    setSelectedEmployees((prev) => {
      return prev.map((employee) => {
        if (employee.id === employeeId) {
          return {
            ...employee,
            employerPlanId: planId,
          };
        }
        return employee;
      });
    });
  }

  const handleChangeClass = (employeeId: number, classId: number) => {
    setSelectedEmployees((prev) => {
      return prev.map((employee) => {
        if (employee.id === employeeId) {
          return {
            ...employee,
            employerClassBenefitId: classId,
          };
        }
        return employee;
      });
    });
  }

  const validUpdateEmployeeClasses = useMemo(() => {
    return selectedEmployees.every((employee) => {
      return employee.employerClassBenefitId > 0 && employee.employerClassBenefitId !== props.employerClassBenefitId;
    });
  }, [selectedEmployees, props.employerClassBenefitId]);

  const handleSubmit = async () => {
    if (!props.employerClassBenefitId) return;

    const employeeClasses = selectedEmployees.reduce((acc, employee) => {
      if (!acc[employee.employerClassBenefitId]) {
        acc[employee.employerClassBenefitId] = [employee.id];
      } else {
        acc[employee.employerClassBenefitId].push(employee.id);
      }
      return acc;
    }, {} as {[key: number]: number[]});

    for (const [employerClassBenefitId, employeeIds] of Object.entries(employeeClasses)) {
      await putChangeEmployeeClassBenefit(
        employerId,
        {
        employerClassBenefitId: Number(employerClassBenefitId),
        employeeIds,
        classBenefitFromDate: toUTCTime(classBenefitFromDate)
      });
    }

    props.handleDeleteClass(props.employerClassBenefitId);
  }

  const planOptions = useMemo(() => {
    return employerPlans.map((plan) => {
      return {
        id: plan.employerPlanId,
        name: plan.planName,
      };
    });
  }, [employerPlans]);

  const handleChangeClassBenefitFromDate = (date: Date | null) => {
    if (!date) return;
    setClassBenefitFromDate(date);
  }

  const body = () => {
    return (
          <div>
            {existingEmployees.length > 0 ? (
              <>
                <StyledModalText>There are employees currently enrolled in this class. Please move them to another class before deleting this class.</StyledModalText>
                <StyledModalWrapper>
                  <div className="row d-none d-md-flex">
                    <div className="col-4">
                      <StyledModalTitleText>Employee Name</StyledModalTitleText>
                    </div>
                    <div className="col-4">
                      <StyledModalTitleText>Move to Plan</StyledModalTitleText>
                    </div>
                    <div className="col-4">
                      <StyledModalTitleText>Move to Class</StyledModalTitleText>
                    </div>
                  </div>
                  {selectedEmployees.map((employee: any) => (
                    <EmployeePlanAndClassSettingsItem
                      key={`move_${employee.id}_to_other_class`}
                      employee={employee}
                      planOptions={planOptions}
                      employerPlans={employerPlans}
                      employerClassBenefitId={props.employerClassBenefitId || 0}
                      employerClassBenefitCoverageAmount={currentEmployerClassBenefit ? currentEmployerClassBenefit.coverageAmount : 0}
                      handleChangePlan={handleChangePlan}
                      handleChangeClass={handleChangeClass}
                    />
                  ))}
                  <div className="col-4 mt-2">
                    <StyledPTitle className="mb-0">Effective Date</StyledPTitle>
                    <BNPDatePicker
                      value={classBenefitFromDate}
                      onChange={handleChangeClassBenefitFromDate}
                      name="classBenefitFromDate"
                      placeholder="From Date"
                    />
                  </div>
                </StyledModalWrapper>
              </>
              ) :
              <StyledModalText>Are you sure you want to delete this class?</StyledModalText>
            }

          </div>
    )
  }

  return (
    <BNPModal
      open={props.open}
      title={`Delete Class ${className ? `"${className}"` : ""}`}
      handleClose={props.handleClose}
      body={body()}
      footer={
        <div className="col-md-4 p-0">
          <StyledButtonSubmit
            disabled={!validUpdateEmployeeClasses}
            onClick={handleSubmit}
        submit={validUpdateEmployeeClasses}
        type="submit"
      >
        {existingEmployees.length > 0 ? "Move to New Class & Delete Class" : "Delete Class"}
      </StyledButtonSubmit>
    </div>
      }
    />
  );
}

export default DeleteClassModal;

type EmployeePlanAndClassSettingsItemProps = {
  employee: ExistingEmployeeWithEmployerClassBenefitIdType;
  planOptions: any[];
  employerPlans: EmployerPlanWithSettings[];
  employerClassBenefitId: number;
  employerClassBenefitCoverageAmount: number;
  handleChangePlan: (employeeId: number, planId: number) => void;
  handleChangeClass: (employeeId: number, classId: number) => void;
}

const EmployeePlanAndClassSettingsItem = ({employee,
                                            planOptions,
                                            employerPlans,
                                            employerClassBenefitId,
                                            employerClassBenefitCoverageAmount,
                                            handleChangePlan,
                                            handleChangeClass}: EmployeePlanAndClassSettingsItemProps) => {

  const classesOptions = useMemo(() => {
    const plan = employerPlans.find(
      (plan) => plan.employerPlanId === employee.employerPlanId
    );
    if (!plan) return [];
    return plan.classBenefits
      .filter((classItem) => classItem.id !== employerClassBenefitId)
      .map((classItem) => ({
        id: classItem.id,
        name: `${classItem.className} - ${formatterUSD("currency", "USD").format(
          centToUsd(classItem.coverageAmount)
        )}`,
        disabled: Number(moment().format("MMDD")) <= 115 ?
          false :
          classItem.coverageAmount < employerClassBenefitCoverageAmount
      }))
  }, [employee.employerPlanId, employerPlans, employerClassBenefitId, employerClassBenefitCoverageAmount]);

  return (
    <div className={"row align-items-center"}>
      <div className={"col-md-4 mt-1"}>
        <StyledSpanTitle>{employee.firstName} {employee.lastName}</StyledSpanTitle>
      </div>
      <div className={"col-md-4 mt-1"}>
        <BNPSelect
          options={planOptions}
          value={employee.employerPlanId}
          onChange={(e) => handleChangePlan(employee.id, e.target.value)}
          placeholder={"Select Plan"}
        />
      </div>
      <div className={"col-md-4 mt-1"}>
        <BNPSelect
          options={classesOptions}
          value={employee.employerClassBenefitId}
          onChange={(e) => handleChangeClass(employee.id, e.target.value)}
          placeholder={"Select Class"}
        />
      </div>
    </div>
  )
}
import {AxiosResponse} from "axios";
import {getAsync, getFileAsync} from "../cores/utils/http-client";

const employeesUrl = "/manage-employee/api";

export const getRrspClaimReport = (model: any): Promise<AxiosResponse> => {
  let url = `${employeesUrl}/report/rrsp`;

  return getAsync(url, model);
};

export const getRrspClaimReportAsZip = (model: any): Promise<AxiosResponse> => {
  let url = `${employeesUrl}/report/rrsp/download`;

  return getFileAsync(url, model);
};
import React from "react";
import { Link, withRouter } from "react-router-dom";
import styled, { css } from "styled-components";
import theme from "../../../cores/helpers/theme";

const StyledSpanTitle = styled.span`
  padding-left: 0.5em;
  padding-top: 1em;
`;

const StyledDiv = styled.div`
  width: 3%;
  height: 50px;
  ${(props: { bgColor: string }) =>
    props.bgColor &&
    css`
      background-color: ${props.bgColor};
    `}
`;

const StyledNavItem = styled("li")`
  .nav-link {
    color: ${theme => theme.theme.sideBarTextColor};
    opacity: 0.5;
    font-size: 14px;
    line-height: 22px;
    height: 50px;
    padding: 1em 0 1em 1em;
  }
  
  .nav-link:hover {
    color: ${theme => theme.theme.sideBarTextColor};
    opacity: 1;
  }
  
  &.active .nav-link {
    color: ${theme => theme.theme.sideBarTextColor};
    opacity: 1;
  }
`

const StyledLink = styled(Link)`
  width: 97%;
  padding-right: unset;
`;

const StyledBadge = styled.div`
  background-color: #d71f27;
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  font-size: 12px;
  color: #fff;
  margin-right: 14px;
`;

const StyledDivLink = styled.div`
  width: max-content;

  :hover:not(.active) {
    transform: translateX(0.5rem);
  }
`;

const NavItem = ({
  isActive,
  isChangeLink,
  to,
  label,
  iconClass,
  handleDrawerToggle,
  noti
}: any) => {
  let classes = ["nav-item", "d-flex", "align-items-center"];
  if (isActive) classes.push("active");

  return (
    <StyledNavItem className={classes.join(" ")} onClick={handleDrawerToggle}>
      <StyledDiv bgColor={isActive ? theme.secondaryColor : "transparent"}>
      </StyledDiv>
      <StyledLink
        onClick={(e) => {
          isChangeLink && e.preventDefault();
        }}
        className="nav-link d-flex flex-row"
        to={to}>
        <StyledDivLink>
          {iconClass}
          <StyledSpanTitle>{label}</StyledSpanTitle>
        </StyledDivLink>
      </StyledLink>
      {
        !noti ?
          <></>
          :
          <StyledBadge className="d-flex align-items-center justify-content-center">
            {noti}
          </StyledBadge>
      }
    </StyledNavItem>
  );
};

export default withRouter(({ location, ...props }: any) => {
  const isChangeLink = location.pathname === props.to.split("?")[0];

  const pathSplit: string[] = location.pathname.split("/");

  const isActive = props.isPartnerPortalItem
    ? "/" + pathSplit[1] + "/" + (pathSplit.length > 2 ? pathSplit[2] + "" : "") + "/" === props.to.split("?")[0]
    : "/" + pathSplit[1] + "/" === props.to.split("?")[0];

  return <NavItem {...props} isActive={isActive} isChangeLink={isChangeLink} />;
});

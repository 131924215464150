import { SortTable } from "../../../cores/models/SortTable";

export const columnsHeaderAdmin = (): SortTable[] => {
  return [
    {
      columnId: "fullName",
      columnName: "Name",
      sortType: null,
      width: 25
    },
    {
      columnId: "email",
      columnName: "Email",
      sortType: null,
      width: 25
    },

    {
      columnId: "roleName",
      columnName: "Role name",
      sortType: null,
      width: 25
    },
    {
      columnId: "status",
      columnName: "Status",
      sortType: null,
      width: 25
    }
  ];
};

export const statuses = [
  {
    id: "ACTIVE",
    name: "Active"
  },
  {
    id: "INACTIVE",
    name: "Inactive"
  }
];

export const adminRoles = [
  {
    id: "SUPER ADMIN",
    name: "Super Admin"
  },
  {
    id: "ADMIN",
    name: "Admin"
  }
];

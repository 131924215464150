import React from "react";
import {
  PagingInfo,
  setPagingInfo,
} from "../../../cores/helpers/pagination/pagination";
import { RouteChildrenProps } from "react-router";
import {
  SearchParamCompany,
  StatusCompany,
  CompanyInfo,
} from "./company-management-type";
import { companyStatus } from "./company-management-model";
import CompanyManagementView from "./company-management-view";
import theme from "../../../cores/helpers/theme";
import { SortTable, SortType } from "../../../cores/models/SortTable";
import { changeEmployerStatus } from "../../../services/company-service";
import { addLoading, removeLoading } from "../../../cores/utils/loading";
import {
  postNewClient
} from "../../../services/company-service";
import NewClientModal from "./new-client-modal/new-client-modal";
import { message } from "../../../cores/helpers/message/message";
import { EnableStatusEnum } from "../../HOC/withStatusButtonActions";
import moment from "moment";
import {TableSortType} from "../../shared/BNPTable/BNPTableType";
import {AxiosResponse} from "axios";
import {isAdminUser} from "../../../cores/utils/helpers";
import {getPermissions} from "../../../services/partner-service";
import { exportExcel } from "../../../cores/helpers/export-file/export-file";

type Props = RouteChildrenProps & {
  defaultColumns: TableSortType[],
  fetchData: (searchParam: SearchParamCompany) => Promise<AxiosResponse>
  exportExcel: (searchParam: SearchParamCompany) => Promise<AxiosResponse>
  options: {
    allowSpecifyReferralPartner: boolean;
  }
};
type State = {
  searchParam: SearchParamCompany;
  pagingInfo: PagingInfo;
  companyStatus: StatusCompany[];
  companyData: CompanyInfo[];
  columns: TableSortType[];
  userPermissions: string[];
};

export default class CompanyManagementComponent extends React.Component<Props, State> {
  state: State = {
    pagingInfo: {
      currentPage: 0,
      totalPages: 0,
      startPage: 0,
      endPage: 0,
      pages: [],
      rowsPerPage: 10,
    },
    searchParam: {
      page: 1,
      status: null,
      fromDate: null,
      toDate: null,
      searchKey: null,
      columnName: null,
      sortType: null,
      perPage: 10,
    },
    companyStatus: companyStatus,
    companyData: [],
    columns: this.props.defaultColumns,
    userPermissions: [],
  };

  async componentDidMount() {
    addLoading();
    const userPermissions = (await getPermissions()).data.records;
    let param = this.getSearchByUrl();

    await this.getSearchClaimData(param.searchParam.page, param.searchParam);
    this.setState({
      columns: param.columns,
      searchParam: param.searchParam,
      userPermissions,
    });

    removeLoading();
  }

  async UNSAFE_componentWillReceiveProps() {
    let param = this.getSearchByUrl();
    addLoading();
    await this.getSearchClaimData(param.searchParam.page, param.searchParam);
    this.setState({
      columns: param.columns,
      searchParam: param.searchParam,
    });
    removeLoading();
  }

  async getSearchClaimData(page: number, searchParam: SearchParamCompany) {
    await this.props.fetchData(searchParam).then((result) => {
      const pagingInfo = setPagingInfo(
        page,
        result.data.pageCount,
        searchParam.perPage
      );
      const companyData: CompanyInfo[] = result.data.records || [];

      this.setState({ companyData: companyData, pagingInfo: pagingInfo });
    });
  }

  handleChangSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    let searchParam: SearchParamCompany = Object.assign(this.state.searchParam);

    if (Number(value) !== -1) searchParam.status = value;
    else searchParam.status = null;

    searchParam.page = 1;

    this.setSearchByParam(searchParam);
  };

  handleChangeDate = (date: Date | null, name: string) => {
    let searchParam: SearchParamCompany = Object.assign(this.state.searchParam);

    if (name === "fromDate") {
      if (searchParam.toDate && date) {
        let currentFrom = new Date(date),
          from = new Date(currentFrom.setHours(0, 0, 0, 0)),
          to = new Date(searchParam.toDate);
        if (from > to) {
          message("The from date cannot be after the to date.", "error");
          return;
        }
      }
      searchParam.fromDate = date;
    } else {
      if (searchParam.fromDate && date) {
        let from = new Date(searchParam.fromDate),
          to = new Date(new Date(date).setHours(23, 59, 59, 999));
        if (from > to) {
          message("The from date cannot be after the to date.", "error");
          return;
        }
      }
      searchParam.toDate = date;
    }
    searchParam.page = 1;

    this.setSearchByParam(searchParam);
  };

  handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    let searchParam: SearchParamCompany = Object.assign(this.state.searchParam);

    searchParam.searchKey = event.target.value;
    searchParam.page = 1;
    this.setState({ searchParam: searchParam });
  };

  searchNameOrEmail = () => {
    this.setSearchByParam(this.state.searchParam);
  };

  onKeyUp = (event: any) => {
    if (event.keyCode === 13) {
      this.searchNameOrEmail();
    }
  };

  handleChangePage = (page: number) => {
    let searchParam: SearchParamCompany = Object.assign(this.state.searchParam);
    searchParam.page = page;

    this.setSearchByParam(searchParam);
  };

  findStatusById = (statusId: string) => {
    let result = {
      status: "",
      color: "",
    };
    let status = this.state.companyStatus.find((statusClaim) => {
      return statusClaim.id === statusId;
    });
    if (status) {
      result.status = status.name;
    }
    result.color = statusId === "Enabled" ? "#0078ED" : theme.secondaryColor;

    return result;
  };

  sortTable = (columnId: string) => {
    let columnsHeaderTable: SortTable[] = [...this.state.columns];
    let searchParam = Object.assign({}, this.state.searchParam);
    searchParam.columnName = columnId;
    let index = columnsHeaderTable.findIndex((column) => {
      return column.columnId === columnId;
    });

    columnsHeaderTable.forEach((column, i) => {
      if (i === index) {
        column.sortType = column.sortType === "ASC" ? "DESC" : "ASC";
        searchParam.sortType = column.sortType;
        return;
      }
      column.sortType = null;
    });

    this.setSearchByParam(searchParam);
  };

  getSearchByUrl = () => {
    const urlParams = new URLSearchParams(window.location.search),
      page = urlParams.get("page"),
      status = urlParams.get("status"),
      fromDate = urlParams.get("fromDate"),
      toDate = urlParams.get("toDate"),
      searchKey = urlParams.get("searchKey"),
      columnName = urlParams.get("columnName"),
      sortType: SortType = urlParams.get("sortType") as SortType,
      rowsPerPage = urlParams.get("rowsPerPage");

    let searchParam = Object.assign({}, this.state.searchParam);

    page ? (searchParam.page = Number(page)) : (searchParam.page = 1);

    status ? (searchParam.status = status) : (searchParam.status = null);

    fromDate ? (searchParam.fromDate = fromDate) : (searchParam.fromDate = null);
    toDate ? (searchParam.toDate = toDate) : (searchParam.toDate = null);

    searchKey
      ? (searchParam.searchKey = searchKey)
      : (searchParam.searchKey = null);

    if (columnName && sortType) {
      searchParam.columnName = columnName;
      searchParam.sortType = sortType;
    } else {
      searchParam.columnName = null;
      searchParam.sortType = null;
    }

    rowsPerPage
      ? (searchParam.perPage = Number(rowsPerPage))
      : (searchParam.perPage = this.state.searchParam.perPage);

    // set sort table
    let columns: TableSortType[] = [...this.props.defaultColumns];
    let index = columns.findIndex((column) => {
      return column.columnId === searchParam.columnName;
    });
    if (index > -1) {
      columns[index].sortType = sortType;
    }

    return {
      searchParam,
      columns,
    };
  };

  setSearchByParam = (searchParam: SearchParamCompany) => {
    let url = new URL(window.location.href);

    searchParam.page
      ? url.searchParams.set("page", searchParam.page.toString())
      : url.searchParams.delete("page");

    searchParam.fromDate
      ?  url.searchParams.set("fromDate", moment(searchParam.fromDate).format("YYYY-MM-DD"))
      : url.searchParams.delete("fromDate")

    searchParam.toDate
      ?  url.searchParams.set("toDate", moment(searchParam.toDate).format("YYYY-MM-DD"))
      : url.searchParams.delete("toDate")

    searchParam.status
      ? url.searchParams.set("status", searchParam.status)
      : url.searchParams.delete("status");
    searchParam.searchKey
      ? url.searchParams.set("searchKey", searchParam.searchKey)
      : url.searchParams.delete("searchKey");
    if (searchParam.columnName && searchParam.sortType) {
      url.searchParams.set("sortType", searchParam.sortType);
      url.searchParams.set("columnName", searchParam.columnName);
    } else {
      url.searchParams.delete("sortType");
      url.searchParams.delete("columnName");
    }

    searchParam.perPage
      ? url.searchParams.set("rowsPerPage", searchParam.perPage.toString())
      : url.searchParams.delete("rowsPerPage");

    this.props.history.push(url.search);
  };

  setRowsPerPage = (event: any) => {
    const { value } = event.target;

    let searchParam = Object.assign({}, this.state.searchParam);
    searchParam.page = 1;
    searchParam.perPage = Number(value);

    // set url search
    this.setSearchByParam(searchParam);
  };

  handleAddNewClient = async (data: any) => {
    const res = await postNewClient(data)
    if (res.status === 204) {
      let param = this.getSearchByUrl();

      await this.getSearchClaimData(param.searchParam.page, param.searchParam);
      this.setState({
        columns: param.columns,
        searchParam: param.searchParam,
      });

      return true
    }
    return false
  }

  handleChangeStatusCompany = async (toStatus: EnableStatusEnum, company: CompanyInfo) => {
    const resp = await changeEmployerStatus(company.id, toStatus);
    if (resp.status === 202) {
      removeLoading();
      message(`The employer has been ${EnableStatusEnum[toStatus]}!`, "success");
      this.componentDidMount();
    }
  }

  buildPartnerOptions = () => {
    return {
      allowAddEmployer: this.state.userPermissions.includes("partner.employer:add"),
      allowEditEmployer: this.state.userPermissions.includes("partner.employer:edit"),
    }
  }

  handleExportExcel = async () => {
    addLoading();
    try {
      const result = await this.props.exportExcel(this.state.searchParam);
      exportExcel(result.data);
    } catch (e) {
      message("Export excel failed", "error");
    }
    removeLoading();
  }

  render() {
    return (
      <>
        <CompanyManagementView
          searchParam={this.state.searchParam}
          pagingInfo={this.state.pagingInfo}
          companyStatus={this.state.companyStatus}
          companyData={this.state.companyData}
          columns={this.state.columns}
          handleChangSelect={this.handleChangSelect}
          handleChangeDate={this.handleChangeDate}
          handleChangeInput={this.handleChangeInput}
          onKeyUp={this.onKeyUp}
          searchNameOrEmail={this.searchNameOrEmail}
          findStatusById={this.findStatusById}
          handleChangePage={this.handleChangePage}
          sortTable={this.sortTable}
          setRowsPerPage={this.setRowsPerPage}
          handleChangeStatusCompany={this.handleChangeStatusCompany}
          linkToBaseUrl={isAdminUser() ? "" : "/partner"}
          partnerOptions={this.buildPartnerOptions()}
          handleExportExcel={this.handleExportExcel}
        />
        <NewClientModal
          handleSubmit={this.handleAddNewClient}
          allowSpecifyReferralPartner={this.props.options.allowSpecifyReferralPartner}
        />
      </>
    );
  }
}

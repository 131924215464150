import React from "react";
import SidebarComponent from "../../../cores/config/component/side-bar"

type EmployerSidebarProps = {
  handleDrawerToggle?: () => void;
  transactionCount?: number;
};

export default function SideBar(props: EmployerSidebarProps) {

  return <SidebarComponent {...props} />;

}

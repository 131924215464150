import React, { useEffect, useState } from "react";
import { StyledButtonSubmit, StyledDivTitle } from "../../../shared/styled";
import { formatterUSD } from "../../../../cores/helpers/format-usd";
import { TReportWallet } from "../DashboardType";
import { centToUsd } from "../../../../cores/helpers/cent-to-usd";
import BNPDatePicker from "../../../../cores/helpers/date-picker/date-picker";
import BNPRawTable from "../../../shared/BNPTable/BNPRawTable";
import {defaultWalletReport, WalletReportColumn} from "../DashboardModel";
import {addLoading, removeLoading} from "../../../../cores/utils/loading";
import DownloadIcon from "@material-ui/icons/GetApp";

type Props = {

  getWalletSummaryReport: (date: Date | string) => Promise<TReportWallet<number>>;
  exportWalletSummaryReport: (date: Date | string) => void;

};

export function WalletSummaryReport(props: Props) {
  const [date, setDate] = useState<Date | string>(new Date());
  const [data, setData] = useState<TReportWallet<number | string>[]>(
    processSummarizedData(defaultWalletReport)
  );

  useEffect(() => {
    fetchData().then();
  }, [date]);

  async function fetchData() {
    addLoading()
    const result = await props.getWalletSummaryReport(date);
    setData(processSummarizedData(result))
    removeLoading()
  }

  function processSummarizedData(
    data: TReportWallet<number>
  ): TReportWallet<number | string>[] {
    return [
      {
        noOfEmployer: data.noOfEmployer,
        noOfEmployee: data.noOfEmployee,
        totalSize: formatterUSD("currency", "USD").format(
          centToUsd(Number(data.totalSize))
        ),
        averageSize: formatterUSD("currency", "USD").format(
          centToUsd(Number(data.averageSize))
        ),
      },
    ];
  }

  function handleChangeDate(date: Date | null, name: string) {
    if (date) setDate(date);
  }

  async function handleExportExcel() {
    addLoading();
    props.exportWalletSummaryReport(date);
    removeLoading();
  }

  return (
    <>
      <div className="col-lg-3 col-md-4 col-12">
        <StyledDivTitle>Date of Report</StyledDivTitle>
        <BNPDatePicker
          value={date}
          onChange={(date) => handleChangeDate(date, "date")}
          name="date"
          maxDate={new Date()}
        />
      </div>
      <div className="col-lg-6 col-12" />
      <div className="col-lg-2 col-12">
        <StyledDivTitle style={{height: "26px"}}></StyledDivTitle>
        <StyledButtonSubmit
          submit={true}
          onClick={handleExportExcel}
        >
          <DownloadIcon className="icon" style={{marginRight: "5px"}}/>
          Export To Excel
        </StyledButtonSubmit>
      </div>
      <div className="col-lg-12 pt-3">
        <BNPRawTable columns={WalletReportColumn} tableWidth="100%" tableData={data} />
      </div>
    </>
  );
}

import React from "react";
import {
  PagingInfo,
  setPagingInfo,
} from "../../../cores/helpers/pagination/pagination";
import { addLoading, removeLoading } from "../../../cores/utils/loading";
import { RouteChildrenProps } from "react-router";
import { SortTable, SortType } from "../../../cores/models/SortTable";
import RequestDepositWithdrawTransactionView from "./request-deposit-withdraw-transaction-view";
import {
  DepositWithdrawTransactions,
  DepositWithdrawTransactionSearchParams,
} from "../deposit-withdraw-transaction/deposit-withdraw-transaction-type";
import {
  declineWithdraw,
  declineDeposit,
  getAllRequestTransactionsPartnerWithPagination
} from "../../../services/wallet-service";
import { getInfoByToken } from "../../../cores/utils/helpers";
import { closeConfirmModal } from "../../../cores/helpers/confirm-modal/confirm-modal";
import {
  columnsDepositWithdrawTransactionsPending
} from "../deposit-withdraw-transaction/deposit-withdraw-transaction-model";

type Props = RouteChildrenProps;
type State = {
  searchParam: DepositWithdrawTransactionSearchParams;
  pagingInfo: PagingInfo;
  columns: SortTable[];
  transactions: DepositWithdrawTransactions[];
  transactionsFilter: DepositWithdrawTransactions[];
};

export default class RequestDepositWithdrawTransaction extends React.Component<
  Props,
  State
  > {
  state: State = {
    pagingInfo: {
      currentPage: 0,
      totalPages: 0,
      startPage: 0,
      endPage: 0,
      pages: [],
      rowsPerPage: 0,
    },
    searchParam: {
      page: 1,
      perPage: 10,
      type: null,
      columnName: null,
      searchKey: null,
      sortType: null,
    },
    columns: [],
    transactions: [],
    transactionsFilter: [],
  };

  async componentDidMount() {
    addLoading();
    await this.getData();
    removeLoading();
  }

  async componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.location.search !== this.props.location.search
    ) {
      addLoading();
      await this.getData();
      removeLoading();
    }
  }

  async getData() {
    const paramsSearch = this.getSearchByUrl();

    const resultTransactions = await getAllRequestTransactionsPartnerWithPagination({
      partnerId: getInfoByToken().mainPartnerId,
      ...paramsSearch.searchParam,
      searchName: paramsSearch.searchParam.searchKey,
      filter: paramsSearch.searchParam.type
    });

    const pagingInfo = setPagingInfo(
      paramsSearch.searchParam.page,
      resultTransactions.data.pageCount,
      paramsSearch.searchParam.perPage
    );

    this.setState({
      pagingInfo,
      transactionsFilter: resultTransactions.data.records,
      searchParam: paramsSearch.searchParam,
      columns: paramsSearch.columns,
    });
  }

  setRowsPerPage = (event: any) => {
    const { value } = event.target;

    let searchParams = Object.assign({}, this.state.searchParam);

    searchParams.page = 1;
    searchParams.perPage = Number(value);

    // set url search
    this.setSearchByParam(searchParams);
  };

  changePage = (page: number) => {
    let searchParams = Object.assign({}, this.state.searchParam);

    searchParams.page = page;

    this.setSearchByParam(searchParams);
  };

  setSearchByParam = (searchParam: DepositWithdrawTransactionSearchParams) => {
    let url = new URL(window.location.href);

    searchParam.page
      ? url.searchParams.set("page", searchParam.page.toString())
      : url.searchParams.delete("page");

    searchParam.perPage
      ? url.searchParams.set("rowsPerPage", searchParam.perPage.toString())
      : url.searchParams.delete("rowsPerPage");

    if (searchParam.columnName && searchParam.sortType) {
      url.searchParams.set("sortType", searchParam.sortType);
      url.searchParams.set("columnName", searchParam.columnName);
    } else {
      url.searchParams.delete("sortType");
      url.searchParams.delete("columnName");
    }

    if (searchParam.searchKey) {
      url.searchParams.set("searchKey", searchParam.searchKey);
    } else {
      url.searchParams.delete("searchKey");
    }

    searchParam.type
      ? url.searchParams.set("type", searchParam.type.toString())
      : url.searchParams.delete("type");

    this.props.history.push(url.search);
  };

  getSearchByUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    let searchParam = Object.assign({}, this.state.searchParam);

    let page = urlParams.get("page"),
      rowsPerPage = urlParams.get("rowsPerPage"),
      columnName = urlParams.get("columnName"),
      sortType: SortType = urlParams.get("sortType") as SortType,
      searchKey = urlParams.get("searchKey"),
      type = urlParams.get("type");

    page ? (searchParam.page = Number(page)) : (searchParam.page = 1);

    rowsPerPage
      ? (searchParam.perPage = Number(rowsPerPage))
      : (searchParam.perPage = 10);

    if (columnName && sortType) {
      searchParam.columnName = columnName;
      searchParam.sortType = sortType;
    } else {
      searchParam.columnName = null;
      searchParam.sortType = null;
    }

    type ? (searchParam.type = type) : (searchParam.type = null);
    searchKey
      ? (searchParam.searchKey = searchKey)
      : (searchParam.searchKey = null);

    // set sort table
    let columns: SortTable[] = [...columnsDepositWithdrawTransactionsPending]
    let index = columns.findIndex((column) => {
      return column.columnId === searchParam.columnName || 'date';
    });
    if (index > -1) {
      columns[index].sortType = sortType;
    }

    return {
      searchParam,
      columns,
    };
  };

  changeFilter = (event: any) => {
    let { name, value } = event.target,
      searchParam = Object.assign(this.state.searchParam);
    if (Number(value) === -1) value = null;
    searchParam[name] = value;
    searchParam.page = 1;
    // set url search
    this.setSearchByParam(searchParam);
  };

  handleDecline = async (journalId: number, entry: number, isWithdraw: boolean) => {
    let ok = false;
    if (isWithdraw) {
      const res = await declineWithdraw(journalId);
      ok = res.status === 204;
    } else {
      const res = await declineDeposit(journalId);
      ok = res.status === 204;
    }
    if (ok) await this.getData();
    
    closeConfirmModal();
    removeLoading();
  };

  sortTable = (columnId: string) => {
    let columnsHeaderTable: SortTable[] = Object.assign(this.state.columns);
    let searchParam = Object.assign({}, this.state.searchParam);
    searchParam.columnName = columnId;
    let index = columnsHeaderTable.findIndex((column) => {
      return column.columnId === columnId;
    });

    columnsHeaderTable.forEach((column, i) => {
      if (i === index) {
        column.sortType = column.sortType === "ASC" ? "DESC" : "ASC";
        searchParam.sortType = column.sortType;
        return;
      }
      column.sortType = null;
    });

    this.setSearchByParam(searchParam);
  };

  handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    let searchParam: DepositWithdrawTransactionSearchParams = Object.assign({}, this.state.searchParam);

    searchParam.searchKey = event.target.value;
    searchParam.page = 1;
    this.setState({ searchParam: searchParam });
  };

  searchBySundryNumber = () => {
    this.setSearchByParam(this.state.searchParam);
  };

  onKeyUp = (event: any) => {
    if (event.keyCode === 13) {
      this.searchBySundryNumber();
    }
  };

  render() {
    return (
      <>
        <RequestDepositWithdrawTransactionView
          transactionsFilter={this.state.transactionsFilter}
          pagingInfo={this.state.pagingInfo}
          setRowsPerPage={this.setRowsPerPage}
          changePage={this.changePage}
          changeFilter={this.changeFilter}
          searchParam={this.state.searchParam}
          columns={this.state.columns}
          sortTable={this.sortTable}
          handleChangeInput={this.handleChangeInput}
          onKeyUp={this.onKeyUp}
          searchBySundryNumber={this.searchBySundryNumber}
          handleDecline={this.handleDecline}
        />
      </>
    );
  }
}

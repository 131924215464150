import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import {
  StyledHeaderModal,
  StyledHeaderTitleModal,
  StyledButtonCloseModal,
  StyledIconCloseModal,
  StyledInputMask,
  StyledButtonSubmit,
  StyledPTitle,
  StyledDivTitle,
} from "../../../shared/styled";
import { BNPInput } from "../../../../cores/helpers/input/input";
import { BNPSelect, Option } from "../../../../cores/helpers/select/select";
import { CheckBox } from "../../../../cores/helpers/checkbox/checkbox";
import { Provinces, NewCompany } from "../company-management-type";
import GoogleLocationInput, {
  ResponseLocation,
} from "../../../../cores/helpers/google-map-services/google-location";
import {
  getProvinces
} from "../../../../services/company-service";
import { addLoading, removeLoading } from "../../../../cores/utils/loading";
import { validFullNameLength } from "../../../../cores/utils/validation/validate-employee";
import { BNPAutoComplete } from "../../../../cores/helpers/input/BNPAutoComplete";
import { getPartnerOptions, getReferralPartnerUserOptions } from "../../../../services/partner-service";
import { BENIPLUS_DEFAULT_OPTION, NA_OPTION_VALUE, getDefaultSelectedPartnerUserId, getInfoByToken, getRemakedReferralPartnerUsers, isAdminUser } from "../../../../cores/utils/helpers";

const StyledSpanErrorMessage = styled.span`
  color: ${props => props.theme.errorColor};
  font-size: 14px;
`;

const StyledModalDialog = styled.div`
  max-width: 800px;
  margin: 1.75rem auto;
  position: relative;
  width: auto;
  pointer-events: none;
`;

const defaultData: NewCompany = {
  companyName: "",
  firstName: "",
  lastName: "",
  companyEmail: "",
  noOfEmployees: 0,
  // default as Beniplus
  referralPartnerId: 1,
  referralPartnerUserId: 1,
  soleProprietor: false,

  streetAddress: "",
  provinceId: 0,
  cityName: "",
  postcode: "",
  companyPhoneNumber: "",

  streetAddress2: "",
  provinceId2: 0,
  cityName2: "",
  postcode2: "",
  companyPhoneNumber2: ""
}


type Props = {
  allowSpecifyReferralPartner: boolean;
  handleSubmit: (data: any) => Promise<boolean>;
};

export default function NewClientModal(props: Props) {

  const [data, setData] = useState<NewCompany>(defaultData)
  const [provinces, setProvinces] = useState<Provinces[]>([])
  const [isAdditionalStreet, setIsAdditionalStreet] = useState<boolean>(false)
  const [isValid, setIsValid] = useState<boolean>(false)
  const [add, setAdd] = useState<string>("")
  const [add2, setAdd2] = useState<string>("")
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [referralPartners, setReferralPartners] = useState([
    { id: 1, name: "Beniplus" }
  ])
  const [referralPartnerUsers, setReferralPartnerUsers] = useState([{
    id: 1, name: "Beniplus"
  }]);
  const [selectedReferralPartner, setSelectedReferralPartner] = useState<any>({ id: 1, name: "Beniplus" });
  const currentUserInfo = getInfoByToken();
  const isPartnerMember = currentUserInfo.permission === "partner member"

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    const { firstName, lastName } = data;
    if (!validFullNameLength(firstName, lastName)) {
      setErrorMessage("Full name length could not be larger than 30 letters.")
    } else {
      setErrorMessage("")
    }
  }, [data.firstName, data.lastName])

  const init = async () => {
    let res = await getProvinces()
    setProvinces(res.data)
    const allPartnerRes = await getPartnerOptions()
    const referalPartners: any = [BENIPLUS_DEFAULT_OPTION, ...allPartnerRes.data];

    const defaultPartner = getDefaultPartnet(referalPartners);
    let temp = { ...data };
    temp.referralPartnerId = defaultPartner.id

    setSelectedReferralPartner(defaultPartner);
    setData(temp);
    setReferralPartners(referalPartners);
  }

  const getDefaultPartnet = (referalPartners: any) => {
    let defaultPartner = referalPartners.findLast((i: any) => i.isDefaultReferral);

    if (!isAdminUser()) {
      defaultPartner = referalPartners.findLast((i: any) => i.id == currentUserInfo.mainPartnerId);
    }
    return defaultPartner ? defaultPartner : BENIPLUS_DEFAULT_OPTION;
  }


  const handleChangeInput = (event: any) => {
    const { value, name } = event.target

    let temp: any = { ...data }
    temp[name] = value

    let isValid = validate(temp, isAdditionalStreet)
    setData(temp)
    setIsValid(isValid)
  }

  const handleChangeSelect = (event: any, autoComplete?: any) => {
    const { name, value } = event.target;

    let temp: NewCompany = { ...data };

    temp[name] = value;

    let isValid = validate(temp, isAdditionalStreet)
    setData(temp)
    setIsValid(isValid)
  };

  useEffect(() => {
    if (data.referralPartnerId == null) return;
    getReferralPartnerUserOptions(data.referralPartnerId).then((response) => {

      const options = getRemakedReferralPartnerUsers(selectedReferralPartner, response.data);
      const defaultSelectedPartnerUserId = getDefaultSelectedPartnerUserId(selectedReferralPartner, options);
      setReferralPartnerUsers(options);

      let temp: NewCompany = { ...data };
      if (isPartnerMember) {
        const defaultPartnerUser = options.findLast((o: any) => o.id == currentUserInfo.partnerId);
        temp.referralPartnerUserId = defaultPartnerUser ? defaultPartnerUser.id : defaultSelectedPartnerUserId;
      } else {
        temp.referralPartnerUserId = defaultSelectedPartnerUserId;
      }
      setData(temp);

    });
  }, [data.referralPartnerId]);

  const handleChangeReferralPartner = (event: any, option: any) => {
    let temp: NewCompany = { ...data };

    temp.referralPartnerId = option.id;
    setSelectedReferralPartner(option);
    let isValid = validate(temp, isAdditionalStreet);
    setIsValid(isValid);
    setData(temp);
  }

  const handleChangeReferralPartnerUser = (event: any, option: any) => {
    let temp: NewCompany = { ...data };
    temp.referralPartnerUserId = option.id;

    let isValid = validate(temp, isAdditionalStreet);
    setIsValid(isValid);
    setData(temp);
  };

  const validate = (
    data: NewCompany,
    isAdditionalStreet: boolean
  ) => {
    const { firstName, lastName } = data;
    if (
      data.companyName &&
      data.companyEmail &&
      data.firstName &&
      data.lastName &&
      data.noOfEmployees &&

      data.streetAddress &&
      data.provinceId &&
      data.cityName &&
      data.postcode &&
      data.companyPhoneNumber &&
      validFullNameLength(firstName, lastName)
    ) {
      if (isAdditionalStreet) return true
      else if (
        data.streetAddress2 &&
        data.provinceId2 &&
        data.cityName2 &&
        data.postcode2 &&
        data.companyPhoneNumber2
      ) return true

    }

    return false
  }

  const findProvinceIdByName = (
    provinceName: string,
    provinces: Provinces[]
  ) => {
    let province = provinces.find((f) => f.name === provinceName)
    return province ? province.id : -1
  }

  const setAddress = (address: string) => {
    let temp: NewCompany = { ...data }
    temp.streetAddress = address

    let isValid = validate(temp, isAdditionalStreet)
    setData(temp)
    setAdd(address)
    setIsValid(isValid)
  };

  const setLocation = (location: ResponseLocation) => {
    let temp: NewCompany = { ...data }

    temp.cityName = location.city
    temp.postcode = location.postcode ? location.postcode : ""
    temp.provinceId = findProvinceIdByName(location.province, provinces)

    let isValid = validate(temp, isAdditionalStreet)
    setData(temp)
    setIsValid(isValid)
  };

  const setAddress2 = (address: string) => {
    let temp: NewCompany = { ...data }
    temp.streetAddress2 = address

    let isValid = validate(temp, isAdditionalStreet)
    setData(temp)
    setAdd2(address)
    setIsValid(isValid)
  };

  const setLocation2 = (location: ResponseLocation) => {
    let temp: NewCompany = { ...data }

    temp.cityName2 = location.city
    temp.postcode2 = location.postcode ? location.postcode : ""
    temp.provinceId2 = findProvinceIdByName(location.province, provinces)

    let isValid = validate(temp, isAdditionalStreet)
    setData(temp)
    setIsValid(isValid)
  };

  const handleSubmit = async () => {
    let temp: NewCompany = { ...data }
    temp.postcode = temp.postcode.toUpperCase()
    if (add)
      temp.streetAddress = add
    if (isAdditionalStreet) {
      temp.streetAddress2 = temp.streetAddress
      temp.postcode2 = temp.postcode
      temp.companyPhoneNumber2 = temp.companyPhoneNumber
      temp.cityName2 = temp.cityName
      temp.provinceId2 = temp.provinceId
    } else {
      temp.streetAddress2 = add2
    }
    temp.companyPhoneNumber = temp.companyPhoneNumber.split('-').join('');
    temp.companyPhoneNumber2 = temp.companyPhoneNumber2.split('-').join('');

    temp.referralPartnerUserId =
      temp.referralPartnerUserId == NA_OPTION_VALUE ? null : temp.referralPartnerUserId;

    addLoading()

    let res = await props.handleSubmit(temp);
    if (res) {
      resetData();
      closeCompanyInformationModal();
    } else setData(temp)
    removeLoading()
  }

  const resetData = () => {
    let currentData = { ...data };
    let resetData = { ...defaultData };
    resetData.referralPartnerId = currentData.referralPartnerId;
    resetData.referralPartnerUserId = currentData.referralPartnerUserId;

    const defaultPartner = getDefaultPartnet(referralPartners);

    if (currentData.referralPartnerId != defaultPartner.id) {
      resetData.referralPartnerId = defaultPartner.id;
    } else {
      const defaultSelectedPartnerUserId = getDefaultSelectedPartnerUserId(defaultPartner, referralPartnerUsers);
      if (currentData.referralPartnerUserId != defaultSelectedPartnerUserId) {
        resetData.referralPartnerUserId = defaultSelectedPartnerUserId;
      }
    }

    setSelectedReferralPartner(defaultPartner);
    setData(resetData);
  
  }

  return (
    <div className="modal" id="newClient">
      <StyledModalDialog role="document">
        <div className="modal-content">
          <StyledHeaderModal className="modal-header">
            <StyledHeaderTitleModal>Create New Company</StyledHeaderTitleModal>
            <StyledButtonCloseModal
              type="button"
              onClick={closeCompanyInformationModal} >
              <StyledIconCloseModal>&times;</StyledIconCloseModal>
            </StyledButtonCloseModal>
          </StyledHeaderModal>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12 mb-4">
                <StyledPTitle className="mb-2">Company Email</StyledPTitle>
                <BNPInput
                  value={data.companyEmail}
                  name="companyEmail"
                  onChange={handleChangeInput}
                  placeholder="Company email"
                  tabIndex={1} />
              </div>
              <div className="col-md-6 mb-4">
                <StyledPTitle className="mb-2">First name</StyledPTitle>
                <BNPInput
                  value={data.firstName}
                  name="firstName"
                  onChange={handleChangeInput}
                  placeholder="First name"
                  tabIndex={1} />
              </div>
              <div className="col-md-6 mb-4">
                <StyledPTitle className="mb-2">Last name</StyledPTitle>
                <BNPInput
                  value={data.lastName}
                  name="lastName"
                  onChange={handleChangeInput}
                  placeholder="Last name"
                  tabIndex={1} />
              </div>

              <div className="col-md-4 mb-4">
                <StyledPTitle className="mb-2">Company name</StyledPTitle>
                <BNPInput
                  value={data.companyName}
                  name="companyName"
                  onChange={handleChangeInput}
                  placeholder="Company name"
                  tabIndex={1} />
              </div>

              <div className="col-md-4 mb-4">
                <StyledPTitle className="mb-2">No of employees</StyledPTitle>
                <BNPInput
                  value={data.noOfEmployees || ""}
                  name="noOfEmployees"
                  type="number"
                  onChange={handleChangeInput}
                  maxLength={9}
                  placeholder="No of employees"
                  tabIndex={2} />
              </div>
              <div className="col-md-4 mb-4">
                <StyledPTitle className="mb-2">Sole Proprietor</StyledPTitle>
                <BNPSelect
                  options={[
                    { id: 1, name: "Yes" },
                    { id: 0, name: "No" },
                  ]}
                  value={data.soleProprietor ? 1 : 0}
                  name="soleProprietor"
                  onChange={handleChangeSelect}
                  tabIndex={3} />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="mt-3 mb-3 fw-bold">Billing address</div>
              </div>
              <div className="col-md-12 mb-4">
                <StyledPTitle className="mb-2">Street address</StyledPTitle>
                <GoogleLocationInput
                  id="streetAddress"
                  address={data.streetAddress === add ? data.streetAddress : add}
                  setAddress={setAddress}
                  setLocation={setLocation}
                  placeholder="Street address"
                  tabIndex={4}
                />
              </div>
              <div className="col-md-3 mb-4">
                <StyledPTitle className="mb-2">Province</StyledPTitle>
                <BNPSelect
                  options={provinces.map(province => ({
                    id: province.id,
                    name: province.name,
                  }))}
                  placeholder="Province"
                  name="provinceId"
                  value={data.provinceId || "-1"}
                  onChange={handleChangeSelect}
                  tabIndex={5}
                />
              </div>
              <div className="col-md-3 mb-4">
                <StyledPTitle className="mb-2">City</StyledPTitle>
                <BNPInput
                  value={data.cityName || ""}
                  name="cityName"
                  onChange={handleChangeInput}
                  placeholder="City"
                  tabIndex={6}
                />
              </div>
              <div className="col-md-3 mb-4">
                <StyledPTitle className="mb-2">Postcode</StyledPTitle>
                <StyledInputMask
                  mask="*** ***"
                  value={data.postcode.toUpperCase()}
                  onChange={handleChangeInput}
                  name="postcode"
                  placeholder="Postcode"
                  tabIndex={7}
                />
              </div>
              <div className="col-md-3 mb-4">
                <StyledPTitle className="mb-2">Phone number</StyledPTitle>
                <StyledInputMask
                  mask="999-999-9999"
                  value={data.companyPhoneNumber || ""}
                  onChange={handleChangeInput}
                  name="companyPhoneNumber"
                  placeholder="Phone number"
                  tabIndex={8}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="mt-3 mb-4 fw-bold">Mailing address</div>
              </div>
              <div className="col-md-12 mb-3">
                <CheckBox
                  title="Is the same as the billing address"
                  checked={isAdditionalStreet}
                  onChange={() => {
                    setIsAdditionalStreet(!isAdditionalStreet)
                    let isValid = validate(data, !isAdditionalStreet)
                    setIsValid(isValid)
                  }}
                //tabIndex={9}
                />
              </div>
            </div>

            {!isAdditionalStreet && (
              <div className="row">
                <div className="col-md-12 mb-4">
                  <StyledPTitle className="mb-2">Street address</StyledPTitle>

                  <GoogleLocationInput
                    id="streetAddress2"
                    address={data.streetAddress2 === add2 ? data.streetAddress2 : add2}
                    setAddress={setAddress2}
                    setLocation={setLocation2}
                    placeholder="Street address"
                    tabIndex={10}
                  />
                </div>
                <div className="col-md-3 mb-4">
                  <StyledPTitle className="mb-2">Province</StyledPTitle>
                  <BNPSelect
                    options={provinces.map((province: Provinces) => ({
                      id: province.id,
                      name: province.name,
                    }))}
                    placeholder="Province"
                    name="provinceId2"
                    value={data.provinceId2 || "-1"}
                    onChange={handleChangeSelect}
                    tabIndex={11}
                  />
                </div>
                <div className="col-md-3 mb-4">
                  <StyledPTitle className="mb-2">City</StyledPTitle>
                  <BNPInput
                    value={data.cityName2 || ""}
                    name="cityName2"
                    onChange={handleChangeInput}
                    placeholder="City"
                    tabIndex={12}
                  />
                </div>
                <div className="col-md-3 mb-4">
                  <StyledPTitle className="mb-2">Postcode</StyledPTitle>
                  <StyledInputMask
                    mask="*** ***"
                    value={data.postcode2.toUpperCase()}
                    onChange={handleChangeInput}
                    name="postcode2"
                    placeholder="Postcode"
                    tabIndex={13}
                  />
                </div>
                <div className="col-md-3 mb-4">
                  <StyledPTitle className="mb-2">Phone number</StyledPTitle>
                  <StyledInputMask
                    mask="999-999-9999"
                    value={data.companyPhoneNumber2 || ""}
                    onChange={handleChangeInput}
                    name="companyPhoneNumber2"
                    placeholder="Phone number"
                    tabIndex={14}
                  />
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md-12">
                <StyledDivTitle className="mt-3 mb-3">
                  Other information
                </StyledDivTitle>
              </div>
              <div className="col-md-6 mb-4">
                <StyledPTitle className="mb-2">Referral Partner</StyledPTitle>
                <BNPAutoComplete
                  name="referralPartnerId"
                  options={referralPartners}
                  optionSelectedId={data.referralPartnerId || -1}
                  disableClearable={true}
                  disabled={!props.allowSpecifyReferralPartner}
                  handleChange={handleChangeReferralPartner} />
              </div>
              <div className="col-md-6 mb-4">
                <StyledPTitle className="mb-2">Referral Partner Team Member</StyledPTitle>
                <BNPAutoComplete
                  name="refferalPartnerUserId"
                  options={referralPartnerUsers}
                  optionSelectedId={data.referralPartnerUserId || -1}
                  disableClearable={true}
                  disabled={isPartnerMember}
                  handleChange={handleChangeReferralPartnerUser} />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="row w-100">
              <div className="col-md-8 p-0 d-flex align-items-center">
                <StyledSpanErrorMessage>
                  {errorMessage}
                </StyledSpanErrorMessage>
              </div>
              <div className="col-md-4 p-0">
                <StyledButtonSubmit
                  disabled={!isValid}
                  onClick={handleSubmit}
                  submit={isValid}
                  type="submit"
                  tabIndex={16}>
                  Save
                </StyledButtonSubmit>
              </div>
            </div>
          </div>
        </div>
      </StyledModalDialog>
    </div>
  );
}

export function showCompanyInformationModal() {
  ($("#newClient") as any).modal({
    show: true,
    keyboard: false,
    backdrop: "static",
  });
}

function closeCompanyInformationModal() {
  ($("#newClient") as any).modal("hide");
}

import React from "react";
import logo from "../../../cores/config/component/images/logo.png";
import BackgroundImage from "../../assets/images/left-background/image.png";
import { Link } from "react-router-dom";
import {
  StyledWrapper,
  StyledLeftPage,
  StyledBackgroundImage,
  StyledLogo,
  StyledInfoText,
  StyledHeaderRightPage,
  StyledRightPage,
} from "./sign-in-layout-styled";
import { StyledContainer } from "../styled";
import { homePage } from "../../../cores/constants/url-config";
import { isBeniplusUI } from "../../../cores/config/component/ui-config";
import HomeIcon from "@material-ui/icons/Home";

const LayoutSignIn = ({ children }: any) => {
  React.useEffect(() => {
        window.scrollTo(0, 0);
  }, [children]);

  return (
    <StyledWrapper>
      <StyledContainer>
        <div className="row">
          <StyledLeftPage className="col-lg-6">
            <StyledBackgroundImage src={BackgroundImage} alt="girl" />
            <Link to={homePage}>
              <StyledLogo src={logo} alt="logo" />
            </Link>
            {isBeniplusUI 
              ?
              <StyledInfoText>
                <h1>
                  THE <br /> BENEFIT <br /> WALLET
                </h1>
                <h2>FOR SMALL BUSINESS</h2>
              </StyledInfoText>
              :
              <StyledInfoText>
                <h1>
                  YOUR <br /> DIGITAL <br /> BENEFIT <br /> WALLET
                </h1>
                <h2>GoToHealthWallet</h2>
              </StyledInfoText>
            }
          </StyledLeftPage>
          <StyledRightPage className="col-lg-6">
            <StyledHeaderRightPage className="d-flex justify-content-between">
              <Link to="/">
                <HomeIcon className="icon"></HomeIcon>
                <span>Homepage</span>
              </Link>
            </StyledHeaderRightPage>
            <div>{children}</div>
          </StyledRightPage>
        </div>
      </StyledContainer>
    </StyledWrapper>
  );
};

export default LayoutSignIn;

import {
  postAsync,
  getAsync,
  deleteAsync,
  putAsync,
} from "../cores/utils/http-client";
import { AxiosResponse } from "axios";
import { SearchParamAdmin } from "../components/pages/manage-admin/manege-admin-type";
import { getInfoByToken } from "../cores/utils/helpers";
const URL_USER = "auth/api/user";

export interface LoginReqModel {
  username: string;
  password: string;
}

export interface SignUpModel {
  firstName: string;
  lastName: string;
  email: string;
  roleName: string;
}

export type ChangePasswordType = {
  currentPassword: string;
  newPassword: string;
};

export type ForgotPasswordModel = {
  email: string;
};

export type UpdateForgotPasswordModel = {
  newPassword: string;
};

export const login = (model: LoginReqModel): Promise<AxiosResponse> => {
  let url = `${URL_USER}/login`;
  return postAsync(url, model);
};

export const loginAdmin = (model: LoginReqModel): Promise<AxiosResponse> => {
  let url = `partner-auth/api/partner-auth/login`;
  return postAsync(url, model);
};

export const postForgotPassword = (model: ForgotPasswordModel): Promise<AxiosResponse> => {
  let url = `partner-auth/api/partner-auth/forgot-password`;
  return postAsync(url, model);
};

export const putForgotPassword = (token: string, model: UpdateForgotPasswordModel): Promise<AxiosResponse> => {
  let url = `partner-auth/api/partner-auth/forgot-password?token=${token}`;
  return putAsync(url, model);
};

export const getSearchPartnerUser = (
  param: SearchParamAdmin
): Promise<AxiosResponse> => {
  let url = "partner-auth/api/partner-auth";

  return getAsync(url, param);
};

export const signUpAdmin = (
  partnerName: string,
  model: SignUpModel
): Promise<AxiosResponse> => {
  let url = `partner-auth/api/partner-auth/sign-up?partnerName=${partnerName}`;
  return postAsync(url, model);
};

export const deletePartnerUser = (id: number): Promise<AxiosResponse> => {
  let url = `partner-auth/api/partner-auth/${id}`;

  return deleteAsync(url);
};

export const activePartnerUser = (id: number): Promise<AxiosResponse> => {
  let url = `partner-auth/api/partner-auth/${id}`;

  return postAsync(url, {});
};

export const putModifyPartnerUser = (
  id: number,
  param: { firstName: string; lastName: string; roleName: string }
): Promise<AxiosResponse> => {
  let url = `partner-auth/api/partner-auth/${id}`;

  return putAsync(url, param);
};

export const getRole = (): Promise<AxiosResponse> => {
  let url = `auth/api/role`;

  return getAsync(url);
};

export const getAllRole = (): Promise<AxiosResponse> => {
  let url = `auth/api/role/all-role`;

  return getAsync(url);
};

export const putResetPassword = (id: number): Promise<AxiosResponse> => {
  let url = `partner-auth/api/partner-auth/reset-password/${id}`;

  return putAsync(url, {});
};

export const putChangePassword = (
  param: ChangePasswordType
): Promise<AxiosResponse> => {
  let url = `partner-auth/api/partner-auth/change-password`;

  return putAsync(url, param);
};

export const getInitWallet = (): Promise<AxiosResponse> => {
  let url = `manage-wallet-partner/partner/wallet/init/${
    getInfoByToken().mainPartnerId
  }`;

  return getAsync(url);
};

import React, {useEffect, useState} from "react";
import {TableSearchParamType} from "./BNPTableType";
import styled from "styled-components";
import {BNPSelect} from "../../../cores/helpers/select/select";
import BNPDatePicker from "../../../cores/helpers/date-picker/date-picker";
import {BNPInput} from "../../../cores/helpers/input/input";
import SearchIcon from "@material-ui/icons/Search";
import moment from "moment";

const StyledIconSearch = styled(SearchIcon)`
  position: absolute;
  top: 21px;
  right: 16px;
  opacity: 0.7;
`;

const StyledBNPSelect = styled(BNPSelect)`
`;

type TableFilterAndSearchProps = {
  tableType?: string;
  searchParam?: TableSearchParamType;
  filterPlaceHolder?: string;
  searchPlacholder?: string;
  filterTypes?: any[];
  handleChangeFilter?: (e:any) => void;
  handleSearch?: (searchKey: string) => void;
  handleChangeDate?: (date: Date | null, name: string) => void;
}

export function BNPTableActions(props: TableFilterAndSearchProps ) {
  const [inputSearch, setInputSearch] = useState<string>("");

  useEffect(() => {
    setInputSearch("");
  }, [props.tableType])

  function handleChangeSearchInput(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    setInputSearch(value);
  }

  function onKeyUp(event: any) {
    if (event.keyCode === 13 && props.handleSearch) {
      props.handleSearch(inputSearch);
    }
  }

  return (props.searchParam ?
    <div className="row">
      {props.handleChangeDate ? <>
          <div className="col-lg-3 pt-2">
            <BNPDatePicker
              onChange={props.handleChangeDate}
              name="from"
              placeholder="From Date"
              value={props.searchParam.from && moment(props.searchParam.from).zone(new Date().getTimezoneOffset()).toDate()} />
          </div>
          <div className="col-lg-3 pt-2">
            <BNPDatePicker
              onChange={props.handleChangeDate}
              name="to"
              placeholder="To Date"
              value={props.searchParam.to && moment(props.searchParam.to).zone(new Date().getTimezoneOffset()).toDate()}
              minDate={props.searchParam.from && props.searchParam.from} />
          </div>
        </> :
        <div className="col-lg-6" />}
      {props.filterTypes && props.handleChangeFilter && <div className="col-lg-3 pt-2">
          <StyledBNPSelect
              options={props.filterTypes}
              value={props.searchParam.filter || -1}
              name="filter"
              placeholder={props.filterPlaceHolder}
              onChange={props.handleChangeFilter}
              tabIndex={-1}
          />
      </div>}
      {props.handleSearch && <div className="col-lg-3 pt-2">
          <BNPInput
              placeholder={props.searchPlacholder || "Search"}
              onChange={handleChangeSearchInput}
              value={inputSearch || ""}
              onKeyUp={onKeyUp}
              tabIndex={-1}
          />
          <StyledIconSearch onClick={() => props.handleSearch && props.handleSearch(inputSearch)}/>
      </div>}
    </div> : <></>)
}
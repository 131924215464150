import React from "react";
import styled from "styled-components";
import { closeConfirmDialog, openConfirmDialog } from "../../cores/utils/confirmDialog";
import { StyledSpanAction } from "../pages/employees-management/employee-action";
import { StyledButtonSubmit } from "../shared/styled";
import {MenuItem} from "@material-ui/core";

const StyledOptionRejected = styled(StyledButtonSubmit)`
  background-color: ${(props) => props.theme.secondaryColor};
`;

export enum EnableStatusEnum {
    Enabled = 0,
    Disabled = 1,
    Rejected = 2,
    Unclaimed = 3,
    Archived = 4,
}

const MAP_STATUS_TO_ACTION = [
    { status: EnableStatusEnum.Unclaimed, actions: [{ labelName: 'Reject', requestTo: EnableStatusEnum.Rejected }] },
    { status: EnableStatusEnum.Enabled, actions: [{ labelName: 'Disable', requestTo: EnableStatusEnum.Disabled }] },
    {
        status: EnableStatusEnum.Disabled, actions: [
            { labelName: 'Reactive', requestTo: EnableStatusEnum.Enabled },
            { labelName: 'Archive', requestTo: EnableStatusEnum.Archived }
        ]
    },
    { status: EnableStatusEnum.Rejected, actions: [{ labelName: 'Archive', requestTo: EnableStatusEnum.Archived }] }
]

export function withStatusButtonActions(Component: any,
    status: string, onAcceptChangeStatus: (type: EnableStatusEnum) => void) {
    const FComponent = () => {

        const [mapButtons, setMapButtons] = React.useState<{ labelName: string, requestTo: EnableStatusEnum }[]>([]);
        React.useEffect(() => {
            if (status) {
                let temp: EnableStatusEnum = EnableStatusEnum[status as keyof typeof EnableStatusEnum];
                let mapFilter: any = MAP_STATUS_TO_ACTION.find(p => p.status === temp);
                if (mapFilter && mapFilter.actions) {
                    setMapButtons(mapFilter.actions);
                }
            }
        }, [])

        function handleActionEvent(event: any, action: EnableStatusEnum) {
            event.stopPropagation();
            event.preventDefault();
            openConfirmDialog({
                id: `employer-change-status-to-${status}`,
                open: true,
                title: `${EnableStatusEnum[action]}`,
                content: `Are you sure you want to ${EnableStatusEnum[action]} this user?`,
                onClose: (isOk) => handleCloseConfirm(isOk, action)
            })
        }

        function handleCloseConfirm(isOk: boolean, type: EnableStatusEnum) {
            if (isOk) {
                onAcceptChangeStatus(type);
            }
            closeConfirmDialog();
        }

        return (
            <>
                {
                    mapButtons && mapButtons.map((btn, key) => {
                        return (
                            <Component key={key}
                                label={btn.labelName}
                                onClick={(event: any) => handleActionEvent(event, btn.requestTo)} />
                        )
                    })
                }
            </>
        )
    }
    return <FComponent />
}

interface StatusActionButtonWrapperProps {
    status: string;
    onAcceptChangeStatus: (type: EnableStatusEnum) => void
}

interface StatusActionButtonProps {
    label: string;
    onClick: (event: any) => void
}



export const StatusTableActionWrapperButton = (props: StatusActionButtonWrapperProps) => {
    const StatusTableActionButton = (props: StatusActionButtonProps) => {
        const { label, onClick } = props;
        function transformTitle(label: string) {
            if (label === EnableStatusEnum[EnableStatusEnum.Archived]) {
                return 'Archive'
            }
            return label;
        }
        return (
            <StyledSpanAction title={transformTitle(label)}
                onClick={onClick}>
                {label}
            </StyledSpanAction>
        )
    }

    return withStatusButtonActions(StatusTableActionButton, props.status, props.onAcceptChangeStatus);
}



export const EmployerStatusButtonWrapperAction = (props: StatusActionButtonWrapperProps) => {
    const StatusActionButton = (props: StatusActionButtonProps) => {
        return (
            <StyledOptionRejected submit={true} onClick={props.onClick} >
                {props.label}
            </StyledOptionRejected>
        )
    }
    return withStatusButtonActions(StatusActionButton, props.status, props.onAcceptChangeStatus);
}

export const EmployerStatusMenuWrapperAction = (props: StatusActionButtonWrapperProps) => {
    const StatusActionButton = (props: StatusActionButtonProps) => {
        return (
            <MenuItem
                onClick={props.onClick}
                style={{ fontFamily: 'Graphik Light',
                    color: `${props.label === 'Archive' ? `#d71f27` : `black`}` }}>
                {props.label}
            </MenuItem>
        )
    }
    return withStatusButtonActions(StatusActionButton, props.status, props.onAcceptChangeStatus);
}
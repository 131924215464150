import React, {useEffect} from "react";
import styled from "styled-components";
import {EFTHistoryResponse, EFTHistorySearchParam} from "./eft-export-type";
import Pagination, {
  PagingInfo, setPagingInfo,
} from "../../../../cores/helpers/pagination/pagination";
import GetAppIcon from "@material-ui/icons/GetApp";
import ResendIcon from "@material-ui/icons/Send"
import {closeConfirmDialog, openConfirmDialog} from "../../../../cores/utils/confirmDialog";
import {addLoading, removeLoading} from "../../../../cores/utils/loading";
import {getEFTHistory, resendEftToXp} from "../../../../services/parner-auth-services";
import {headerEFTHistory} from "./eft-export-model";
import {TableSortType} from "../../../shared/BNPTable/BNPTableType";
import BNPRawTable from "../../../shared/BNPTable/BNPRawTable";

type Props = {};

const StyledDownload = styled.a`
  color: ${(props) => props.theme.primaryColor};
  font-weight: bold;
  text-decoration: none;
  :hover {
    text-decoration: none;
  }
`;

const StyledResend = styled(ResendIcon)`
  color: ${(props) => props.theme.secondaryColor};
  cursor: pointer;
  :hover {
      opacity: 0.8;
  }
  margin-left: 8px;
`;

type EFTHistoryResponseTable = EFTHistoryResponse & {
  action: JSX.Element;
}

export function EftExportHistory(props: Props) {
  const [searchParams, setSearchParams] = React.useState<EFTHistorySearchParam>({
    page: 1,
    perPage: 10,
    columnName: null,
    sortType: null,
  })
  const [eftHistories, setEftHistories] = React.useState<EFTHistoryResponseTable[]>([]);
  const [paging, setPaging] = React.useState<PagingInfo>(setPagingInfo(1, 0, 10));
  const [columns, setColumns] = React.useState<TableSortType[]>(headerEFTHistory);

  useEffect(() => {
    fetchEFTHistory(searchParams).then();
  }, [searchParams]);

  async function fetchEFTHistory(searchParam: EFTHistorySearchParam) {
    addLoading();
    const result = await getEFTHistory(searchParam);
    setEftHistories(convertTableData(result.data.records));
    setPaging(setPagingInfo(searchParam.page, result.data.pageCount, searchParam.perPage));
    removeLoading();
  }

  const convertTableData = (data: EFTHistoryResponse[]): EFTHistoryResponseTable[] => {
    return data.map((item) => {
      return {
        ...item,
        action: <>
          <StyledDownload href={item.url} download>
            <GetAppIcon className="cur" />
          </StyledDownload>
          {item.status === 'FAILED' &&
              <StyledResend onClick={(e) => handleResendEftToXp(e, item)} />
          }
        </>
      };
    });
  }

  const resendEft = (fileName: string) => {
    addLoading();
    resendEftToXp(fileName).then(() => {
      removeLoading();
    })
  }

  const setRowsPerPage = (event: any) => {
    const { value } = event.target;
    setSearchParams(prevState => (
      {...prevState, page: 1, perPage: Number(value)}
    ));
  };

  const handleChangePage = (page: number) => {
    setSearchParams(prevState => ({...prevState, page}));
  };

  const sortTable = (columnId: string) => {
    const columnsHeaderTable: TableSortType[] = Object.assign([], columns);
    const index = columnsHeaderTable.findIndex((column) => {
      return column.columnId === columnId;
    });

    columnsHeaderTable.forEach((column, i) => {
      if (i === index) {
        column.sortType = column.sortType === "ASC" ? "DESC" : "ASC";
        return;
      }
      column.sortType = null;
    });

    setSearchParams(prevState => (
      {...prevState, columnName: columnId, sortType: columnsHeaderTable[index].sortType}));
    setColumns(columnsHeaderTable);
  };
  const handleResendEftToXp = (e: any, item: EFTHistoryResponse) => {
    e.preventDefault();
    e.stopPropagation();

    openConfirmDialog({
      id: `resend-eft-file-${item.fileName}`,
      open: true,
      title: `Resend EFT to XP`,
      content: `Are you sure you want to resend EFT file ${item.fileName}, exported on ${item.createdDate} to XP?`,
      onClose: (isOk) => handleCloseConfirm(isOk, item.fileName)
    })
  }

  const handleCloseConfirm = (isOk: boolean, fileName: string) => {
    if (isOk) {
      resendEft(fileName);
    }
    closeConfirmDialog();
  }

  return (
    <div className="row justify-content-center">
      <div className="col-12">
        <BNPRawTable
          columns={columns}
          tableWidth='100%'
          tableData={eftHistories}
          tableName={"EFT Export History"}
          handleChangeSortColumn={sortTable}
        />
        <div className="row mt-4">
          <div className="col-12 text-center">
            <label>
              <Pagination
                pagerPagination={paging}
                getPage={handleChangePage}
                onChangeRowsPerPage={setRowsPerPage}
              />
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

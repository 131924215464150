import React, {useEffect, useState} from "react";
import moment from "moment";
import DownloadIcon from "@material-ui/icons/GetApp";
import {BNPSelect, Option} from "../../../../cores/helpers/select/select";
import {StyledBigTitle, StyledButtonSubmit, StyledDivTitle} from "../../../shared/styled";
import {BNPMultiSelect, MultiSelectGeneralOptions} from "../../../../cores/helpers/input/BNPMultiSelect";
import BNPDatePicker from "../../../../cores/helpers/date-picker/date-picker";
import {HorizontalMenu} from "../../../shared/horizontal-menu/HorizontalMenu";
import BNPRawTableWithSubTotal from "../../../shared/BNPTable/BNPRawTableWithSubTotal";
import {TableSortType} from "../../../shared/BNPTable/BNPTableType";
import Pagination, {setPagingInfo} from "../../../../cores/helpers/pagination/pagination";
import {
  getPartnerOptions,
} from "../../../../services/partner-service";
import {addLoading, removeLoading} from "../../../../cores/utils/loading";
import {
  commissionMonthlyTableColumn,
  commissionTableColumn,
  commissionYearlyTableColumn
} from "./CommissionReportModel";
import {
  getAdminCommissionReport,
  getAdminCommissionReportTotal,
  exportAdminCommissionReport,
  AdminCommissionReportRequestType,
  AdminCommissionReportTotalRequestType,
  ExportAdminCommissionReportType
} from "../../../../services/partner-wallet-service";
import {exportExcel} from "../../../../cores/helpers/export-file/export-file";
import { getAllCommissionEnrollmentOptions } from "../../../../services/dashboard-service";

type Props = {};

type CommissionReportSearchParamType = {
  partner: (number | string)[];
  period: number;
  reportingType: "SUMMARY" | "MONTH" | "YEAR";
  enrollmentId: number | null;
  fromDate: string;
  toDate: string;
  page: number;
  perPage: number;
};

const periodOptions: Option[] = [
  { id: 1, name: "This Month" },
  { id: 2, name: "Last Month" },
  { id: 3, name: "Quarter To Date" },
  { id: 4, name: "Year To Date" },
  { id: 5, name: "Custom Period" },
];

const reportTypeOptions: Option[] = [
  { id: "SUMMARY", name: "Summary" },
  { id: "MONTH", name: "Month" },
  { id: "YEAR", name: "Year" },
];

export default function CommissionReport(props: Props) {
  const {fromDate: defaultFromDate, toDate: defaultToDate} = calculatePeriod(1);
  const [searchParam, setSearchParam] = useState<CommissionReportSearchParamType>({
    partner: [],
    period: 1,
    reportingType: "SUMMARY",
    enrollmentId: -1,
    fromDate: defaultFromDate,
    toDate: defaultToDate,
    page: 1,
    perPage: 10,
  });
  const [partnerOptions, setPartnerOptions] = useState<Option[]>([]);
  const [activeTabOption, setActiveTabOption] = useState<Option>(MultiSelectGeneralOptions.ALL);
  const [productTypeOptions, setProductTypeOptions] = useState<Option[]>([]);
  const [commissionSubTotal, setCommissionSubTotal] = useState<any>({
    companyName: "Total",
    commissionEarn: 0,
    clawBack: 0,
    netCommissionEarn: 0,
  });
  const [columns, setColumns] = useState<TableSortType[]>(commissionTableColumn);
  const [paging, setPaging] = useState<any>(setPagingInfo(1, 1, 10))
  const [data, setData] = useState<any[]>([]);
  const [horizontalMenuItems, setHorizontalMenuItems] = useState<Option[]>([]);

  useEffect(() => {
    addLoading();
    Promise.all([
      getPartnerOptionsData(),
      getProductTypeOptions(),
    ]).then(() => removeLoading())
   }, []);

  useEffect(() => {
    addLoading()
    getSubTotalData().then(
        () => removeLoading()
    );
  }, [activeTabOption, searchParam.partner, searchParam.enrollmentId, searchParam.reportingType,
    searchParam.fromDate, searchParam.toDate]);

  useEffect(() => {
    if (activeTabOption.id !== null) {
      addLoading()
      getTableData().then(
          () => removeLoading()
      );
    }
  }, [activeTabOption, searchParam.partner, searchParam.enrollmentId, searchParam.reportingType,
    searchParam.fromDate, searchParam.toDate, searchParam.page, searchParam.perPage]);

  async function getPartnerOptionsData() {
    const res = await getPartnerOptions();
    setPartnerOptions([MultiSelectGeneralOptions.ALL, ...res.data]);
    setHorizontalMenuItems([MultiSelectGeneralOptions.ALL, ...res.data]);
    setSearchParam({...searchParam, partner: [MultiSelectGeneralOptions.ALL.id, ...res.data.map((item: any) => item.id)]});
  }

  async function getProductTypeOptions() {
    const res = await getAllCommissionEnrollmentOptions();
    setProductTypeOptions(res.data);
  }

  async function getSubTotalData() {

    if (searchParam.partner.length === 0) {
      return;
    }

    const model: AdminCommissionReportTotalRequestType = {
      partnerIds: getSubmitPartnerIds(),
      fromDate: searchParam.fromDate,
      toDate: searchParam.toDate,
      enrollmentId: !!searchParam.enrollmentId && searchParam.enrollmentId <= 0 ? null : searchParam.enrollmentId,
    }
    const res = await getAdminCommissionReportTotal(model);

    setCommissionSubTotal({
      ...res.data,
      companyName: "TOTAL",
      commissionEarn: res.data.commissionEarn || 0,
      clawBack: res.data.clawBack || 0,
      netCommissionEarn: res.data.netCommissionEarn || 0,
    });
  }

  async function getTableData() {

    if (searchParam.partner.length === 0) {
      setData([]);
      return;
    }

    const sortCondition = getSortCondition(searchParam.reportingType);

    const model: AdminCommissionReportRequestType = {
      partnerIds: getSubmitPartnerIds(),
      fromDate: searchParam.fromDate,
      toDate: searchParam.toDate,
      enrollmentId: !!searchParam.enrollmentId && searchParam.enrollmentId <= 0 ? null : searchParam.enrollmentId,
      reportingType: searchParam.reportingType,
      page: searchParam.page,
      perPage: searchParam.perPage,
      sortType: sortCondition.sortType,
      columnName: sortCondition.columnName,
    }
    const res = await getAdminCommissionReport(model);
    setData(res.data.records);
    setPaging(setPagingInfo(searchParam.page, res.data.pageCount, searchParam.perPage));
  }
  
  function getSubmitPartnerIds(): number[] | null {
    let partnerIds;

    if (activeTabOption.id === MultiSelectGeneralOptions.ALL.id) {
      partnerIds = null;
    } else if (activeTabOption.id === MultiSelectGeneralOptions.ALL_SELECTED.id) {
      partnerIds = searchParam.partner.filter(
        item => item !== MultiSelectGeneralOptions.ALL_SELECTED.id && Number(item) > 0).map((item) => Number(item));
    } else {
      partnerIds = [Number(activeTabOption.id)];
    }

    return partnerIds;
  }

  function getSortCondition(reportingType: "SUMMARY" | "MONTH" | "YEAR"):  {
    columnName?: string;
    sortType?: 'ASC' | 'DESC';
  } {
    if (reportingType === "MONTH") {
      return {
        columnName: "ledgerCreatedDateMonthYear",
        sortType: "ASC",
      }
    } else if (reportingType === "YEAR") {
      return {
        columnName: "ledgerCreatedDateYear",
        sortType: "ASC",
      }
    } else {
      return {

      }
    }
  }

  function handleChangePartner(event: any) {
    let { value } = event.target;
    value = value.filter((item: any) => item !== MultiSelectGeneralOptions.ALL_SELECTED.id);
    if (value.includes(MultiSelectGeneralOptions.ALL.id)) {
      value = partnerOptions.map((item) => item.id);
    } else if (value.length > 1) {
      value = [MultiSelectGeneralOptions.ALL_SELECTED.id, ...value];
    }

    setSearchParam(prevState => {
      if (prevState.partner.includes(MultiSelectGeneralOptions.ALL.id) && !value.includes(MultiSelectGeneralOptions.ALL.id)) {
        value = []
      }
      return {...prevState, partner: value};
    });
    setHorizontalMenuItems(getHorizontalMenuItems(value));
    setActiveTabOption({id: value.length > 0 ? value[0] : null, name: ""})
    if(value.length === 0) {
      setData([]);
    }
  }

  function getHorizontalMenuItems(searchParamPartnerIds: (number | string)[]) {
    if (searchParamPartnerIds.includes(MultiSelectGeneralOptions.ALL.id)) { // All selected
      return [...partnerOptions];
    } else if (!searchParamPartnerIds.includes(MultiSelectGeneralOptions.ALL.id) && searchParamPartnerIds.length > 1) { // All selected
      return [MultiSelectGeneralOptions.ALL_SELECTED, ...partnerOptions.filter((item) => searchParamPartnerIds.includes(Number(item.id)))]
    }
    return partnerOptions.filter((item) => searchParamPartnerIds.includes(Number(item.id)));
  }

  function handleChangeReportingPeriod(event: any) {
    let { value } = event.target;

    const {fromDate, toDate} = calculatePeriod(value);

    setSearchParam({
      ...searchParam,
      period: value,
      fromDate: fromDate,
      toDate: toDate,
    });
  }

  function handleChangeReportingType(event: any) {
    let {name, value} = event.target;

    if (value <=0 ) value = "SUMMARY";

    if (value === "MONTH") {
      setColumns(commissionMonthlyTableColumn);
    } else if (value === "YEAR") {
      setColumns(commissionYearlyTableColumn);
    } else {
      setColumns(commissionTableColumn);
    }

    setSearchParam({
      ...searchParam,
      [name]: value,
    });
  }

  function handleChangeProductType(event: any) {
    let { value } = event.target;

    setSearchParam({...searchParam, enrollmentId: value});
  }

  function handleChangeDate(date: Date | null, name: string) {
    let temp: any = { ...searchParam };

    temp[name] = date ? moment(date).toISOString(true) : null;

    validateAndSetValue(temp);
  }

  function validateAndSetValue(temp: any) {
    if (temp.from && temp.to && temp.from > temp.to) {
      temp.to = temp.from;
    }

    setSearchParam(temp);
  }

  async function handleExportExcel() {
    const model: ExportAdminCommissionReportType = {
      partnerIds: getSubmitPartnerIds(),
      fromDate: searchParam.fromDate,
      toDate: searchParam.toDate,
      enrollmentId: !!searchParam.enrollmentId && searchParam.enrollmentId <= 0 ? null : searchParam.enrollmentId,
      reportingType: searchParam.reportingType,
    }
    addLoading();
    const res = await exportAdminCommissionReport(model);
    if (res.status === 200) {
      exportExcel(res.data)
    }
    removeLoading();
  }

  function handleChangeActiveTab(item: Option) {
    setActiveTabOption(item);
  }

  function handleChangePage(page: number) {
    setSearchParam({...searchParam, page});
  }

  function handleChangePerPage(event: any) {
    const { value } = event.target;
    setSearchParam({...searchParam, perPage: value, page: 1});
  }

  function calculatePeriod(periodId: number) {
    let fromLocal, toLocal: Date;

    switch (periodId) {
      case 2: //Last month
        fromLocal = moment().month(moment().month()-1).startOf('month').toDate();
        toLocal = moment().month(moment().month()-1).endOf('month').toDate();
        break;
      case 3: //Quarter
        fromLocal = moment().quarter(moment().quarter()).startOf('quarter').toDate();
        toLocal = moment().quarter(moment().quarter()).endOf('quarter').toDate();
        break;
      case 4: //Year
        fromLocal = moment().year(moment().year()).startOf('year').toDate();
        toLocal = moment().year(moment().year()).endOf('year').toDate();
        break;
      default: //Default this month
        fromLocal = moment().month(moment().month()).startOf('month').toDate();
        toLocal = moment().month(moment().month()).endOf('month').toDate();
        break;
    }

    return {
      fromDate: fromLocal && moment(fromLocal).toISOString(true),
      toDate: toLocal && moment(toLocal).toISOString(true),
    };
  }

  return (
    <>
      <div className="row pt-3 d-flex">
        <div className="col-lg-9 col-md-8 col-12">
          <StyledBigTitle>Commission Report</StyledBigTitle>
        </div>
      </div>
      <div className="row pt-5">
        <div className="col-lg-2 col-12">
          <StyledDivTitle>Partner</StyledDivTitle>
          <BNPMultiSelect
            name={"partner"}
            value={searchParam.partner}
            options={partnerOptions}
            placeholder={"Please select partner"}
            onChange={handleChangePartner}
            allowAll={true}
          />
        </div>
        <div className="col-lg-2 col-12">
          <StyledDivTitle>Reporting Period</StyledDivTitle>
          <BNPSelect
            name={"period"}
            value={searchParam.period}
            options={periodOptions}
            placeholder={"All"}
            onChange={handleChangeReportingPeriod}
          />
        </div>
        <div className="col-lg-2 col-12">
          <StyledDivTitle>Reporting Type</StyledDivTitle>
          <BNPSelect
            name={"reportingType"}
            value={searchParam.reportingType}
            options={reportTypeOptions}
            placeholder={"Reporting Type"}
            onChange={handleChangeReportingType}
          />
        </div>
        <div className="col-lg-2 col-12">
          <StyledDivTitle>Product Type</StyledDivTitle>
          <BNPSelect
            name={"enrollmentId"}
            value={searchParam.enrollmentId}
            options={productTypeOptions}
            placeholder={"All Product"}
            onChange={handleChangeProductType}
          />
        </div>
        <div className="col-lg-2 col-12" />
        <div className="col-lg-2 col-12">
          <StyledDivTitle style={{height: "26px"}}></StyledDivTitle>
          <StyledButtonSubmit
            submit={true}
            onClick={handleExportExcel}
          >
            <DownloadIcon className="icon" style={{marginRight: "5px"}}/>
            Export To Excel
          </StyledButtonSubmit>
        </div>
      </div>
      {searchParam.period === 5 && (<div className="row pt-3">
        <div className="col-lg-2 col-12">
          <StyledDivTitle>From</StyledDivTitle>
          <BNPDatePicker
            value={searchParam.fromDate}
            onChange={(date) => handleChangeDate(date, "fromDate")}
            name="fromDate"
            maxDate={new Date()}
          />
        </div>
        <div className="col-lg-2 col-12">
          <StyledDivTitle>To</StyledDivTitle>
          <BNPDatePicker
            value={searchParam.toDate}
            onChange={(date) => handleChangeDate(date, "toDate")}
            name="toDate"
          />
        </div>
      </div>)}
      <div className="row pt-5">
        <HorizontalMenu
          items={horizontalMenuItems}
          onClick={handleChangeActiveTab}
          activeItem={activeTabOption}
        />
      </div>
      {activeTabOption.id !== null && (
        <>
          <BNPRawTableWithSubTotal
          columns={columns}
          tableData={data}
          tableSubTotal={commissionSubTotal}
          tableWidth='100%'
          />
          <div className="row mt-4">
            <div className="col-12 text-center">
              <label>
                <Pagination
                  pagerPagination={paging}
                  getPage={handleChangePage}
                  onChangeRowsPerPage={handleChangePerPage}
                />
              </label>
            </div>
          </div>
        </>
      )}
    </>
  );
}

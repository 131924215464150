import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {
  StyledButtonSubmit,
  StyledHeaderModal,
  StyledHeaderTitleModal,
  StyledButtonCloseModal,
  StyledIconCloseModal,
  StyledCell
} from "../../../shared/styled";
import { addLoading, removeLoading } from "../../../../cores/utils/loading";
import { ClaimInfo, ClaimSeparation } from "../claim-management-type";
import { formatterUSD } from "../../../../cores/helpers/format-usd";
import {centToUsd, usdToCent} from "../../../../cores/helpers/cent-to-usd";
import ManualApproveAmount from "./manual-approve-amount";
import { calculateClaimSeparation } from "../../../../services/claim-service";
import { convertTimeZone } from "../../../../cores/utils/helpers";
import { FE_DETAIL_DATE_FORMAT } from "../../../../cores/utils/format/date-time-format";

type Props = {
  claimInfo: ClaimInfo;
  disabledButton: boolean;
  findLimitAmount: (typeOfClaim: string, approvedStep: boolean) => number;
  handleOnOk: (manualAmount: number | null) => void;
  reloadBalance: (callback: () => void) => void; 
  triggerReloadBalance: boolean; 
};

const StyledWidthDiv = styled.div`
  width: 118px;
`;

const StyledOptionApproved = styled(StyledButtonSubmit)`
  background-color: ${props => props.theme.primaryColor};
`;
const StyledOptionRejected = styled(StyledButtonSubmit)`
  background-color: ${props => props.theme.secondaryColor};
`;
const StyledModal = styled.div`
  z-index: 1100 !important;
`;

export function ConfirmApproveClaimDialog(props: Props) {
  const refTyping = useRef<number | null>(null);
  const [isHaveManualAmount, setHaveManualAmount] = useState<boolean>(false);
  const [manualAmount, setManualAmount] = useState<number | null>(null);
  const [claimSeparations, setClaimSeparations] =  useState<ClaimSeparation[]|undefined>(undefined);
  const [limitAmount, setLimitAmount] =  useState<number>(0);
  const [isValidManualAmount, setIsValidManualAmount] =  useState<boolean>(true);

  const typeOfClaim = props.claimInfo.receipts.length
    ? props.claimInfo.receipts[0].typeOfClaim
    : "";
  
  useEffect(() => {
    if (props.claimInfo && props.claimInfo.employeeId) {
      props.reloadBalance(() => {
        findLimitAmount()
      });
    }
  }, [props.triggerReloadBalance])

  const findLimitAmount = () => {
    const limitAmount = props.findLimitAmount(typeOfClaim, true);
    setLimitAmount(limitAmount);
    setIsValidManualAmount(usdToCent(manualAmount) <= limitAmount);
  }


  const checkAmount = () => {
    let amount = props.claimInfo.receipts.length
      ? props.claimInfo.receipts[0].amount
      : 0;

    return limitAmount < amount;
  };

  const handleChangeCheckbox = (e: any, checked: boolean) => {
    setHaveManualAmount(checked);
  }


  useEffect(() => {
    if (refTyping.current) {
      clearTimeout(refTyping.current);
    }
    refTyping.current = setTimeout(async () => {

      const isValidAmount = usdToCent(manualAmount) <= limitAmount; 
      setIsValidManualAmount(isValidAmount);
      if (!isValidAmount) {
        setClaimSeparations(undefined);
        return;
      }
      if (props.claimInfo.enableClaimSeparation && manualAmount) {
        addLoading();
        const params = { approvedAmount: usdToCent(manualAmount) };
        await calculateClaimSeparation(props.claimInfo.id, params).then(resp => {
          setClaimSeparations([...resp.data])
        });
        removeLoading();
      }
    }, 500);
  }, [manualAmount]);

  const ClaimSeparationDetail = (props: {data: ClaimSeparation}) =>{
    const {data} = props;
    const getStr = () => {
      let strArray = [`${formatterUSD("currency", "USD").format(centToUsd(data.amount))}`];
      if (data.paidStatus) {
        if (data.paidStatus === 'CMP') {
          strArray.push('Paid');
        } else if (data.paidStatus === 'ISS') {
          strArray.push('Waiting payment from Employer');
        }
      } else if (data.status && data.status === 'PENDING_APPROVE') {
        strArray.push(`Pending payment until`);
        if (data.forDate) { strArray.push(convertTimeZone("Canada/Eastern", data.forDate).format(FE_DETAIL_DATE_FORMAT)) };
      } else if (data.status && data.status === 'APPROVED') {
        strArray.push(`Will be paid on approval`);
      }
      return strArray.join(" ");
    }
    return (
      <StyledCell width="100%" style={{paddingLeft:"10px"}}>
        &bull; {getStr()}
      </StyledCell>
    )
  }

  return (
    <StyledModal className="modal" id="confirmApproveClaimModal">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          {/* header */}
          <StyledHeaderModal className="modal-header">
            <StyledHeaderTitleModal className="text-left">
              Approve
            </StyledHeaderTitleModal>
            <StyledButtonCloseModal
              name="btn-close"
              onClick={closeConfirmApproveClaimModal}
            >
              <StyledIconCloseModal>&times;</StyledIconCloseModal>
            </StyledButtonCloseModal>
          </StyledHeaderModal>
          {/* end header */}
          {/* body */}
          <div className="modal-body text-left">
            {/* body */}
            {limitAmount !== 0 && <ManualApproveAmount isChecked={isHaveManualAmount}
                                  onSwitchChange={handleChangeCheckbox}
                                  manualAmount={manualAmount}
                                  setManualAmount={setManualAmount}
                                  isValid={isValidManualAmount}/>}
            {checkAmount() ? (
              limitAmount === 0 ? (
                <>
                  <div>{`${typeOfClaim} wallet of ${props.claimInfo.employeeName} was out of limit.`}</div>
                  <div className="mt-3">Do you want to approve this claim?</div>
                </>
              ) : (
                  <>
                    <div>{`${typeOfClaim} wallet of ${
                      props.claimInfo.employeeName
                      } only has ${formatterUSD("currency").format(
                        centToUsd(limitAmount)
                      )} left.`}</div>
                    <div className="mt-3">{`Do you want to approve this claim${isHaveManualAmount && manualAmount ? " with amount $" + manualAmount : ""}?`}</div>
                  </>
                )
            ) : (
                `Are you sure you want to approve this claim${isHaveManualAmount && manualAmount ? " with amount $" + manualAmount : ""}?`
              )}
          </div>
          {isHaveManualAmount && props.claimInfo.enableClaimSeparation && claimSeparations && (
            <StyledCell width="100%" className="d-flex flex-column" style={{ padding: 0 }}>
              {claimSeparations.map(p => <ClaimSeparationDetail data={p} />)}
            </StyledCell>
          )}
          {/* end body */}
          {/* footer */}
          <div className="modal-footer">
            <StyledWidthDiv>
              <StyledOptionRejected
                type="button"
                submit={true}
                onClick={closeConfirmApproveClaimModal}
              >
                Cancel
              </StyledOptionRejected>
            </StyledWidthDiv>
            <StyledWidthDiv>
              <StyledOptionApproved
                type="button"
                submit={!props.disabledButton && isValidManualAmount}
                onClick={() => {
                  props.handleOnOk(isHaveManualAmount && manualAmount ? manualAmount : null);
                  closeConfirmApproveClaimModal();
                  addLoading();
                }}
                name="btn-ok"
                id="confirmButton"
                disabled={props.disabledButton || !isValidManualAmount}
              >
                {checkAmount()
                  ? limitAmount === 0
                    ? "Yes"
                    : `Yes, proceed to pay ${isHaveManualAmount && manualAmount && isValidManualAmount ?
                      "$" + manualAmount : 
                      formatterUSD("currency").format(
                      centToUsd(limitAmount)
                    )}`
                  : "Yes"}
              </StyledOptionApproved>
            </StyledWidthDiv>
          </div>
          {/* end footer */}
        </div>
      </div>
    </StyledModal>
  );
}

export function showConfirmApproveClaimModal() {
  ($("#confirmApproveClaimModal") as any).modal({
    show: true,
    keyboard: false,
    backdrop: "static"
  });
}
export function closeConfirmApproveClaimModal() {
  ($("#confirmApproveClaimModal") as any).modal("hide");
}

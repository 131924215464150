import React, {useEffect, useMemo, useState} from "react";
import { ChequeInfo, ConfirmTypes, EftCredit } from "./credit-modal-type";
import styled from "styled-components";
import { StyledButtonCloseModal, StyledButtonSubmit, StyledHeaderModal, StyledHeaderTitleModal, StyledIconCloseModal, StyledPError, StyledSpanTitle } from "../../../shared/styled";
import { BNPInput } from "../../../../cores/helpers/input/input";
import { BNPSelect } from "../../../../cores/helpers/select/select";
import ImageUpload from "../../../../cores/helpers/upload-image/image-upload";
import { getFileUrl } from "../../../../services/file-service";



type Props = {
    credit: EftCredit;
    handleChangeInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleSubmit: (e: any) => void;
    confirmTypes: ConfirmTypes[];
    showPopupTime: string;
    miniumDepositAmount: number | null;
    miniumErrorMessage: string;
};
export const StyledDivBoxCheque = styled.div`
  background-color: #f4f5f9;
  padding: 21px 17px;
  border-radius: 5px;
  margin-top: 1.5rem;
`;

export default function CreditModel(props: Props) {
    const [chequeInfo, setChequeInfo] = useState( {} );
    const [fileUrl, setFileUrl] = useState();
    const [confirmImage, setConfirmImage] = useState();
    const [typeConfirm, setTypeConfirm] = useState( 'EFT' );

    const cleanDataAfterSubmit = () => {
        setFileUrl('');
        setConfirmImage('');
        setTypeConfirm('EFT')
    }

    useEffect(() => {
        cleanDataAfterSubmit();
    }, [props.showPopupTime])

    const checkNegative = () => {
        return props.credit.depositAmount < 0;
    };

    const checkMiniumAmount = () => {
        return props.miniumDepositAmount ? props.credit.depositAmount < props.miniumDepositAmount : false;
    }

    const handleChangSelect = (e: any) => {
        const {value} = e;
        setTypeConfirm( value.toString() );
    };

    const getFileURL = (url: string) => {
        getFileUrl( url ).then( (response) => {
            setFileUrl( response.data );
            setConfirmImage( url );
        } );
    };

    const handleChangeVoidCheque = (urlImage: string) => {
        let cheque: ChequeInfo = Object.assign( chequeInfo );
        if (urlImage) {
            cheque.voidCheque = urlImage;
        }
        getFileURL( urlImage );
        setChequeInfo( cheque )
    };

    const handleSubmit = () => {
        const dataUpdate = {confirmImage: typeConfirm === 'ETRANSFER' ? confirmImage : '', transferType: typeConfirm}
        props.handleSubmit(dataUpdate);
    }

    const isDisableSubmit = useMemo( () => {
        let isSubmit = true;
        switch (typeConfirm) {
            case 'ETRANSFER':
                if(!fileUrl || props.credit.depositAmount <= 0 || checkMiniumAmount()) isSubmit = false;
                break;
            case 'EFT':
                if(!typeConfirm || props.credit.depositAmount <= 0 || checkMiniumAmount()) isSubmit = false;
                break;
            default:
                isSubmit = false;
                break;
        }
        return isSubmit;

    }, [typeConfirm, fileUrl, props.credit.depositAmount] );

    return (
        <div className="modal" id="creditModal" role="dialog">
            <div className="modal-dialog modal-dialog-centered" style={{minWidth: "700px"}} role="document">
                <div className="modal-content">
                    <div className="modal-body">
                        <StyledButtonCloseModal name="btn-close" onClick={closeCreditModal}>
                            <StyledIconCloseModal>&times;</StyledIconCloseModal>
                        </StyledButtonCloseModal>
                        <StyledHeaderModal>
                            <StyledHeaderTitleModal>Deposit</StyledHeaderTitleModal>
                        </StyledHeaderModal>
                        <div className="row">
                            <div className="col-8">
                                <StyledSpanTitle>Enter amount to deposit</StyledSpanTitle>
                                <BNPInput
                                    type="number"
                                    className="col-12 mt-2"
                                    onChange={props.handleChangeInput}
                                    name="depositAmount"
                                    placeholder="Amount"
                                    value={props.credit.depositAmount || ""}
                                />
                                {checkNegative() ? (
                                    <StyledPError>
                                        Please do not enter a negative number
                                    </StyledPError>
                                ) : (
                                    ""
                                )}
                                {(props.miniumDepositAmount && checkMiniumAmount()) && (
                                    <StyledPError>
                                        {props.miniumErrorMessage}
                                    </StyledPError>
                                )}
                            </div>
                            <div className="col-4">
                                <StyledSpanTitle>Choose Transfer Type</StyledSpanTitle>
                                <BNPSelect
                                    className="mt-2"
                                    options={props.confirmTypes}
                                    placeholder="Transfer Type"
                                    name="transferType"
                                    value={typeConfirm}
                                    onChange={(e) => handleChangSelect( e.target )}
                                />
                            </div>
                            {
                                typeConfirm === 'ETRANSFER' && (
                                    <div className="col-md-12 mt-4">
                                        <ImageUpload
                                            addFile={handleChangeVoidCheque}
                                            addBlob={() => {}}
                                            urlImage={fileUrl || ''}
                                            folderName="Employer/Cheque"
                                        />
                                    </div>
                                )
                            }
                        </div>
                        <div className="row mt-3">
                            <StyledButtonSubmit
                                className="col-2 ml-auto"
                                onClick={handleSubmit}
                                submit={isDisableSubmit}
                                disabled={!isDisableSubmit}
                                type="submit"
                            >
                                Save
                            </StyledButtonSubmit>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function showCreditModal() {
    ($( "#creditModal" ) as any).modal( {
        show: true,
        keyboard: false,
        backdrop: "static",
    } );
}

export function closeCreditModal() {
    ($( "#creditModal" ) as any).modal( "hide" );
}

import jwt from "jsonwebtoken";
import { UserInfo } from "../../models/login/login-req-model";
import moment from "moment";
import {employeeStatus, Status} from "../../components/pages/company-management/detail/employees/employee-model";
import { getCookie, removeCookie, setCookie } from "./cookie";
import { getEmployerConfiguration } from "../../services/company-service"
import { publishMessage } from "../utils/message"
import { ADMIN_PORTAL_ROLES, PARTNER_PORTAL_ROLES } from "../constants/string-constraint";
import {AxiosResponse} from "axios";
import {getFileUrl} from "../../services/file-service";

export const authorizationHeader = (): { [index: string]: string } => {
  const token = getToken();
  if (token) {
    return {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
  }
  return {};
};
export const COOKIE_NAME = {
  TOKEN: 'token',
}
export const getToken = () => getCookie(COOKIE_NAME.TOKEN);
export const setToken = (token: string, expriedAt?: Date): void => {
  if (token) {
    setCookie(COOKIE_NAME.TOKEN, token, expriedAt);
  } else {
    removeCookie(COOKIE_NAME.TOKEN);
  }
};

export const getInfoByToken = (): UserInfo => {
  let token = getToken() as string;

  let decoded = jwt.decode(token) as UserInfo;

  return decoded;
};

export const isSuperAdmin = (): boolean => {
  return getInfoByToken().permission === "super admin";
}

export const isAdminUser = (): boolean => {
  return ADMIN_PORTAL_ROLES.filter(r => {
    const idx = getInfoByToken().authorities.findIndex(a => a === r);
    return idx > -1
  }).length > 0;
}

export const isPartnerUser = (): boolean => {
  return PARTNER_PORTAL_ROLES.filter(r => {
    const idx = getInfoByToken().authorities.findIndex(a => a === r);
    return idx > -1
  }).length > 0;
}

export const signOut = () => {
  setToken("");
};

export const download = (url: string) => {
  window.open(url, "_blank");
};

export const getInfoByCookies = () => {
  let cookies = document.cookie.split(";");

  let token = cookies.find((cookie) => {
    return cookie.match("token");
  });

  let isSetupDone = cookies.find((cookie) => {
    return cookie.match("isSetupDone");
  });

  return {
    token: token ? token.split("=")[1] : "",
    isSetupDone: isSetupDone ? isSetupDone.split("=")[1] : "",
  };
};

export const convertTimeZone = (timeZone: string, date: Date | string) => {
  const tz = date.toLocaleString("en", { timeZone }).split(" ").slice(-1)[0];
  const dateString = date.toString();
  const offset = Date.parse(`${dateString} UTC`) - Date.parse(`${dateString} ${tz}`);
  
  // return UTC offset in millis
  return moment(date).add(-offset, "ms");
}

export const convertDateToTimeZone = (timeZone: string, date: Date | string) : string => {
  const dateInRequestTimeZone = (typeof date === 'string')
    ? new Date(date).toLocaleDateString("en", { timeZone })
    : date.toLocaleDateString("en", { timeZone });
  // result will be ex:  12/30/2024
  return dateInRequestTimeZone;
}

export const getDateWithoutTime = (dateTime: string | Date) => {
  const time = new Date(dateTime).getTime();
  let date = new Date(time);
  date.setHours(0, 0, 0, 0);
  return date;
}

export const getMonthsFromNowDate = (months: number) => {
  return moment().add(months, 'months').toDate()
}

export const getStartDateOfMonth = (date: Date) => {
  return moment(date).startOf('month').toDate()
}

export const getEndDateOfMonth = (date: Date) => {
  return moment(date).endOf('month').toDate()
}

export const findEmployeeStatusNameById = (statusId: string, T4Employee = true): string => {
  if (!T4Employee) return employeeStatus[0].name

  const status: Status | undefined = employeeStatus.find((employeeStatus) => {
    return employeeStatus.id === statusId;
  });
  return status ? status.name : "";
};

export const findNameById = (statusId: string, statusList: Status[]) => {
  const status: Status | undefined = statusList.find((employeeStatus) => {
    return employeeStatus.id === statusId;
  });
  return status ? status.name : "";
}


export const getEmployerConfigurationInfo = async (): Promise<any> => {

  const res = await getEmployerConfiguration();
  if (res.status === 200 && res.data) {
    return res.data;
  } else {
    publishMessage({
      message: "Can not get employer configuration. Please try again later.",
      variant: "error",
    });
  }
};

export const getAuthFileUrl = async (url: string) => {
  const response: AxiosResponse<string> = await getFileUrl(url);
  return response.data;
}


export const NA_OPTION_VALUE: number = -1;

export const BENIPLUS_DEFAULT_OPTION = { id: 1, name: "Beniplus", type: "COMPANY", companyEmail: "admin@beniplus.ca" };

export const getRemakedReferralPartnerUsers = (partner: any, originalPartnerUsers: any) => {
  if (!partner) return originalPartnerUsers;

  if (partner.type == 'COMPANY' && partner.companyEmail == partner.contactEmail) {
    return [{ id: NA_OPTION_VALUE, name: partner.companyName }, ...originalPartnerUsers];
  }
  return originalPartnerUsers;
}

export const getDefaultSelectedPartnerUserId = (partner: any, partnerUserOptions: any) => {

  let defaultSelectedPartnerUserId = NA_OPTION_VALUE;

  if (!partner) return defaultSelectedPartnerUserId;

  if (partner.type != 'COMPANY' || partner.companyEmail != partner.contactEmail) {
    let defaultPartnerUserEmail = partner.type == 'COMPANY' ? partner.companyEmail : partner.contactEmail;
    // get default for referralPartnerUser
    if (partnerUserOptions && partnerUserOptions.length > 0) {
      let existDefaultPartnerUser = partnerUserOptions.filter((pu: any) => pu.email == defaultPartnerUserEmail);
      if (existDefaultPartnerUser && existDefaultPartnerUser.length > 0) {
        defaultSelectedPartnerUserId = existDefaultPartnerUser[0].id;
      } else {
        defaultSelectedPartnerUserId = partnerUserOptions[0].id;
      }
    }
  }

  return defaultSelectedPartnerUserId;

}

export function compareStrings(str1: string, str2: string) {
  // Normalize the strings and convert to lowercase
  const normalizedStr1 = str1.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
  const normalizedStr2 = str2.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

  // Compare the normalized strings
  return normalizedStr1 === normalizedStr2;
}
import React from "react";
import {RouteChildrenProps} from "react-router";
import {TableSortType} from "../../shared/BNPTable/BNPTableType";
import EmployeeManagementComponent from "./employees-management";
import {getEmployeesByPartner, exportEmployeesByPartner} from "../../../services/partner-service";
import {getInfoByToken} from "../../../cores/utils/helpers";

type Props = RouteChildrenProps;

export default function PartnerEmployeeManagement(props: Props) {

    const partnerId = getInfoByToken().mainPartnerId;

    return (
        <EmployeeManagementComponent
            columns={columns}
            fetchData={(searchTerm, employeeStatus, t4Employee, currentPage, rowsPerPage, sorts) =>
                getEmployeesByPartner(partnerId, searchTerm, employeeStatus, t4Employee, currentPage, rowsPerPage, sorts)}
            exportExcel={(searchTerm, employeeStatus, t4Employee, currentPage, rowsPerPage, sorts) =>
                exportEmployeesByPartner(partnerId, searchTerm, employeeStatus, t4Employee, currentPage, rowsPerPage, sorts)}
            {...props}
            isPartnerView
        />
    );
}

const columns: TableSortType[] = [
    {columnName: "Employee Name", columnId: "firstName", sortType: null, width: 15, sortColumn: true},
    {columnName: "Email", columnId: "email", sortType: null, width: 20, sortColumn: true},
    {columnName: "Company", columnId: "companyName", sortType: null, width: 15, sortColumn: true},
    {columnName: "Employee URL", columnId: "url", sortType: null, width: 15, sortColumn: false},
    {columnName: "T4 Employee", columnId: "t4Employee", sortType: null, width: 10, sortColumn: false},
    {columnName: "Partner Team Member", columnId: "referralPartnerUserName", sortType: null, width: 10, sortColumn: true},
    {columnName: "Status", columnId: "status", sortType: null, width: 10, sortColumn: false},
    {columnName: "Actions", columnId: "actions", sortType: null, width: 20, sortColumn: false},
]
import React, { useMemo } from "react";
import Logo from "./images/white-logo.png";
import NavItem from "../../../components/shared/layout/nav-item";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Benefit from "./images/benefit.svg";
import BusinessIcon from "@material-ui/icons/Business";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import GroupIcon from "@material-ui/icons/Group";
import HistoryIcon from "@material-ui/icons/History";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import Dashboard from "@material-ui/icons/Dashboard";
import { getInfoByToken, isAdminUser } from "../../utils/helpers";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import LocalHospitalOutlinedIcon from "@material-ui/icons/LocalHospitalOutlined";
import {
  claimManagementURL,
  companyManagementURL,
  adminManagementURL,
  claimHistoryURL,
  exportEftFileURL,
  homePage,
  claimManagementSearchParamDefault,
  companySearchParamDefault,
  adminManagementSearchParamDefault,
  depositWithdrawTransactionURL,
  depositWithdrawTransactionSearchParamDefault,
  emailHistoryURL,
  employeesManagementUrl,
  rrspExportURL,
  adminDashboardURL,
  partnerManagementURL,
  marketplaceURL,
} from "../../constants/url-config";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";

type EmployerSidebarProps = {
  handleDrawerToggle?: () => void;
  transactionCount?: number;
};

const StyledDivLogo = styled.div`
  height: 60px;
  padding: 15px 21px;
`;

const StyledImgLogo = styled.img`
  height: 50px;
  position: absolute;
  left: 28px;
  top: 8px;
  @media (max-width: 600px) {
    height: 40px;
  }
`;

const StyledImgClaim = styled.img`
  margin-top: -5px;
`;

const navItemList = [
  {
    to: claimManagementURL + claimManagementSearchParamDefault,
    label: "Claim Management",
    icon: <StyledImgClaim src={Benefit} alt="claim" />,
    permissionRoles: ["super admin", "admin"]
  },
  {
    to: companyManagementURL + companySearchParamDefault,
    label: "Company Management",
    icon: <BusinessIcon />,
    permissionRoles: ["super admin", "admin"]
  },
  {
    to: employeesManagementUrl,
    label: "Employees Management",
    icon: <BusinessIcon />,
    permissionRoles: ["super admin", "admin"]
  },
  {
    to: marketplaceURL,
    label: "Vendor",
    icon: <LocalHospitalOutlinedIcon />,
    permissionRoles: ["super admin"]
  },
  {
    to: partnerManagementURL,
    label: "Partner Management",
    icon: <BusinessIcon />,
    permissionRoles: ["super admin"]
  },
  {
    to: adminDashboardURL,
    label: "Dashboard",
    icon: <Dashboard />,
    permissionRoles: ["super admin"]
  },
  {
    to: adminManagementURL + adminManagementSearchParamDefault,
    label: "Admin Management",
    icon: <GroupIcon />,
    permissionRoles: ["super admin"]
  },
  {
    to: depositWithdrawTransactionURL + depositWithdrawTransactionSearchParamDefault,
    label: "Deposit/Withdraw",
    icon: <AccountBalanceWalletIcon />,
    permissionRoles: ["super admin", "admin"]
  },
  {
    to: exportEftFileURL,
    label: "Export EFT File",
    icon: <CloudDownloadIcon />,
    permissionRoles: ["super admin"],
    notification: true
  },
  {
    to: claimHistoryURL,
    label: "Claim History",
    icon: <HistoryIcon />,
    permissionRoles: ["super admin", "admin"]
  },
  {
    to: rrspExportURL,
    label: "Monthly Contribution Export",
    icon: <MonetizationOnIcon />,
    permissionRoles: ["super admin"]
  },
  {
    to: emailHistoryURL,
    label: "Email History",
    icon: <MailOutlineIcon />,
    permissionRoles: ["super admin", "admin"]
  }
]

export default function SideBar(props: EmployerSidebarProps) {
  const navItemListMemo = useMemo(() => {

    // check if not Admin user do not show
    if (!isAdminUser()) {
      return [];
    }

    const permission = getInfoByToken().permission;
    return  navItemList.filter(item => item.permissionRoles.includes(permission));
  }, []);

  return (
    <div>
      {/* logo */}
      <StyledDivLogo>
        <Link to={homePage + claimManagementSearchParamDefault}>
          <StyledImgLogo src={Logo} alt="logo" />
        </Link>
      </StyledDivLogo>

      {/* top nav */}
      <ul className="nav flex-column top-nav">
        {navItemListMemo.map(item => (
          <NavItem
          key={`nav-item-${item.label}`}
          to={item.to}
          handleDrawerToggle={props.handleDrawerToggle}
          label={item.label}
          iconClass={item.icon}
          noti={item.notification && props.transactionCount}
        />
        ))}
      </ul>
    </div>
  );

}

import React, {useEffect, useState} from 'react';
import {SuspendHistoryType} from "./SuspendHistoryType";
import moment from "moment";
import styled from "styled-components";
import EditIcon from "@material-ui/icons/Edit";
import DoneIcon from "@material-ui/icons/Done";
import DeleteIcon from "@material-ui/icons/Delete"
import ClearIcon from "@material-ui/icons/Clear"
import {
  deleteSuspendHistory,
  getSuspendHistory,
  updateSuspendHistory
} from "../../../../services/company-service";
import BNPDatePicker from "../../../../cores/helpers/date-picker/date-picker";
import {addLoading, removeLoading} from "../../../../cores/utils/loading";
import {toUTCTime} from "../../../../cores/helpers/to-utc-time";
import {Employee} from "../../company-management/company-management-type";

const StyledSuspendHistoryWrapper = styled.div`
  margin-bottom: 2rem;
`;

const StyledTitle = styled.div`
  font-weight: bold;
  color: ${props => props.theme.grey[500]};
`;

const StyledActionButton = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 100%;
  padding-left: 8px;
`;

const StyledIconEdit = styled(EditIcon)`
  width: 20px !important;
  height: 20px !important;
  color: ${(props) => props.theme.primaryColor};
`;

const StyledIconClear = styled(ClearIcon)`
  width: 20px !important;
  height: 20px !important;
  color: ${(props) => props.theme.primaryColor};
`;

const StyledIconUpdate = styled(DoneIcon)`
  width: 20px !important;
  height: 20px !important;
  color: ${(props) => props.theme.successColor};
`;

const StyledIconDelete = styled(DeleteIcon)`
  width: 20px !important;
  height: 20px !important;
  color: ${(props) => props.theme.errorColor};
`;

type Props = {
  employee: Employee | null;
  searchEmployees: () => void;
}

const SuspendHistory = React.memo(function SuspendHistory(props: Props) {
  const [suspendHistories, setSuspendHistories] = useState<SuspendHistoryType[]>([]);
  const [suspendHistoryEdit, setSuspendHistoryEdit] = useState<SuspendHistoryType | null>(null);

  useEffect(() => {
    addLoading();
    getSuspendData().then(() => removeLoading())
  }, [props.employee])

  async function getSuspendData() {
    if (props.employee) {
      const result = await getSuspendHistory(props.employee.id);
      const suspendHistoriesResult: SuspendHistoryType[] = result.data.map((item: any) => ({
        id: item.id,
        from: item.fromDate,
        to: item.toDate,
      }))
      setSuspendHistories(suspendHistoriesResult)
    }
  }

  function handleChangeDate(date: Date | null, name: string) {
    const suspendHistoryEditClone = Object.assign({}, suspendHistoryEdit)

    if (name === "from" && date) {
      suspendHistoryEditClone.from = toUTCTime(new Date(date))
    } else if (name === "to" && date) {
      suspendHistoryEditClone.to = toUTCTime(new Date(date))
    }

    setSuspendHistoryEdit(suspendHistoryEditClone)
  }

  function handleEdit(suspendHistory: SuspendHistoryType) {
    setSuspendHistoryEdit(suspendHistory);
  }

  async function handleUpdate() {
    if (props.employee && suspendHistoryEdit) {
      addLoading();
      await updateSuspendHistory(props.employee.id, suspendHistoryEdit.id, suspendHistoryEdit.from, suspendHistoryEdit.to);
      await getSuspendData();
      setSuspendHistoryEdit(null);
      props.searchEmployees();
      removeLoading();
    }
  }

  function handleClearEdit() {
    setSuspendHistoryEdit(null);
  }

  async function handleRemove(suspendHistoryId: number) {
    if (props.employee) {
      addLoading();
      await deleteSuspendHistory(props.employee.id, suspendHistoryId);
      await getSuspendData();
      removeLoading();
    }
  }

  return <>
    {suspendHistories.length > 0 &&
      <StyledSuspendHistoryWrapper>
        <StyledTitle>Suspend History</StyledTitle>
        {suspendHistories.map((item, idx) => (
          <div key={`suspend_history_${idx}`} className="row pt-3">
            <div className="col-5">
              <BNPDatePicker
                disabled={!suspendHistoryEdit || suspendHistoryEdit.id !== item.id}
                onChange={handleChangeDate}
                value={!suspendHistoryEdit || suspendHistoryEdit.id !== item.id ? item.from : suspendHistoryEdit.from}
                placeholder="Start Date"
                name="from"
                tabIndex={1}
                maxDate={props.employee && props.employee.benefitCancellationDate}/>
            </div>
            <div className="col-5">
              <BNPDatePicker
                disabled={!suspendHistoryEdit || suspendHistoryEdit.id !== item.id}
                onChange={handleChangeDate}
                value={!suspendHistoryEdit || suspendHistoryEdit.id !== item.id ? item.to : suspendHistoryEdit.to}
                placeholder="End Date"
                name="to"
                tabIndex={1}
                minDate={item.from}
                maxDate={props.employee && props.employee.benefitCancellationDate}
              />
            </div>
            <div className="col-2">
              {!moment(item.to).isBefore(new Date()) && <>
                {
                  !suspendHistoryEdit || suspendHistoryEdit.id !== item.id ?
                    <StyledActionButton title="Edit" onClick={() => handleEdit(item)}>
                      <StyledIconEdit/>
                    </StyledActionButton> :
                    <>
                      <StyledActionButton title="Clear" onClick={handleClearEdit}>
                        <StyledIconClear/>
                      </StyledActionButton>
                      <StyledActionButton title="Update" onClick={handleUpdate}>
                        <StyledIconUpdate/>
                      </StyledActionButton>
                    </>
                }</>}
              {moment(item.from).isAfter(new Date()) && <>
                  <StyledActionButton title="Delete" onClick={() => handleRemove(item.id)}>
                      <StyledIconDelete/>
                  </StyledActionButton>
              </>}
            </div>
          </div>
        ))}
      </StyledSuspendHistoryWrapper>
  }</>
})

export default SuspendHistory;
import React, {useMemo} from "react";
import {
  StyledMainContent,
  StyledBigTitle,
  StyledCell,
  StyledButtonSubmit,
  StyledContentBox,
  StyledSpanLargerTitle,
  StyledDivTitle,
} from "../../../shared/styled";
import styled, { css } from "styled-components";
import {
  DataDetailCompany,
  PaymentModel,
  Employee,
  Transactions,
  WalletSearchParams,
} from "../company-management-type";
import { formatPhoneNumber } from "../../../../cores/helpers/format-phone-number";
import EmployeeTable from "./employees/employee-table";
import Pagination, {
  PagingInfo,
} from "../../../../cores/helpers/pagination/pagination";
import theme from "../../../../cores/helpers/theme";
import CreateIcon from "@material-ui/icons/Create";
import { showCompanyInformationModal } from "./company-information-modal";
import { centToUsd } from "../../../../cores/helpers/cent-to-usd";
import { formatterUSD } from "../../../../cores/helpers/format-usd";
import { MedicalAndTravel } from "../../../../cores/helpers/medical-and-travel-coverage/medical-and-travel-coverage-type";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { BNPSelect } from "../../../../cores/helpers/select/select";
import { statusTransaction } from "../company-management-model";
import { TermsAndConditionsModal } from "../../../../cores/helpers/terms-and-conditions/terms-and-conditions";
import { Class } from "./employees/employee-model";
import {
  companyManagementDetailEmployeeURL,
  partnerCompanyManagementDetailEmployeeURL,
} from "../../../../cores/constants/url-config";
import {
  convertTimeZone,
  getInfoByToken,
  isAdminUser
} from "../../../../cores/utils/helpers";
import ModalImage from "react-modal-image";
import {
  Menu,
  IconButton,
  MenuItem,
  Fade
} from "@material-ui/core";
import EmployerRefundView from "./refund/employer-refund-view";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { showEmployeeModal } from "../employee-modal/employee-modal";
import { PdfViewer } from "../../../shared/pdf-viewer";
import {
  EmployerStatusMenuWrapperAction,
  EnableStatusEnum
} from "../../../HOC/withStatusButtonActions";
import {FE_DETAIL_DATE_FORMAT, getStaticDateFromServerDate} from "../../../../cores/utils/format/date-time-format";
import {PremiumInsurance} from "./premium-insurances/premium-insurances";
import {CustomAdminFee} from "./custom-admin-fee/custom-admin-fee";
import { isBeniplusUI } from "../../../../cores/config/component/ui-config";
import {PartnerCompanyDetailOptions} from "../../partner-management/partner-management-type";
import { RRSPProvider } from "./rrsp-provider/rrsp-provider";
import BNPRawTable from "../../../shared/BNPTable/BNPRawTable";
import {PlanAndClassTableHeader, TransactionTableHeader} from "./company-management-detail-model";
import {TableSortType} from "../../../shared/BNPTable/BNPTableType";
import {StyledSpanAction} from "../../employees-management/employee-action";
import {EmployerEnrollment} from "./plan/plan-type";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      borderRadius: "0.25rem",
      border: '1px solid #003049'
    },
  }),
);


const StyledOptionRequest = styled(StyledButtonSubmit)`
  background-color: transparent;
  border: 1px solid ${(props) => props.theme.primaryColor};
  color: ${(props) => props.theme.primaryColor};
  overflow-x: hidden;
`;

const StyledLine = styled.div`
  padding: 5px;
  display: flex;
`;

const ContentBoxCustomStyled = styled.div`
  padding: 20px 15px;
  border-radius: 5px;
  background-color: #f4f5f9;
`;
const CellStyled = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledTitleBalance = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 29px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const StyledBalance = styled.div`
  font-size: 26px;
  font-weight: bold;
  line-height: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
    ${(props: { color: string }) =>
    props.color &&
    css`
        color: ${props.color};
      `};
`;

export const StyledSpanEditInfo = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  float: right;
  cursor: pointer;
  font-family: ${(props) => props.theme.textFont};
  :hover {
    opacity: 0.7;
  }
  position: relative;
  top: 6px;
`;

const StyledModalImageContainer = styled.div`
  .modal-image {
    max-width: 100% !important;
    max-height: 300px !important;
  }
`;

type Props = {
  data: DataDetailCompany;
  findPaymentMethodById: (id: number) => PaymentModel;
  columns: TableSortType[];
  employees: Employee[];
  getEmployees: () => void;
  pagingInfo: PagingInfo;
  isLoadingTableEmployees: boolean;
  changePage: (page: number) => void;
  sortTable: (columnId: string) => void;
  setRowsPerPage: (event: any) => void;
  findProvinceById: (id: number) => string;
  findEnrollmentById: (id: number) => string;
  showUpdateBankingInformationModal: () => void;
  showDeposit: () => void;
  showModalRejectEmployer: () => void;
  showPlansAndClassesModal: (employerPlanId: number) => void;
  fileUrl: string;
  //balances
  availableAmount: number;
  pendingAmount: {
    processing: number;
    pendingAmount: number;
    claimRequested: number;
    processingMonthlyContribution: number;
  };
  transactions: Transactions[];

  pagingInfoWallet: PagingInfo;
  changePageWallet: (page: number) => void;
  setRowsPerPageWallet: (event: any) => void;

  searchParamWallet: WalletSearchParams;
  changeFilterWallet: (event: any) => void;
  classes: Class[];
  handleChangeInputRefund: (event: any) => void;
  amount: number;
  showRefund: () => void;
  showAutoTopUpPending: () => void;
  onSubmitChangeStatus: (type: EnableStatusEnum) => void;
  enablePresetBankingInformation: boolean;
  partnerOptions: PartnerCompanyDetailOptions;
};

export default function CompanyManagementDetailView(props: Props) {
  const classes = useStyles();
  const widthTitle = "30%";
  const widthParam = "70%";

  const [isPdfFile, setIsPdfFile] = React.useState<boolean>(false);
  const role = getInfoByToken().permission;

  React.useEffect(() => {
    if (props.fileUrl.match("/*.pdf")) {
      setIsPdfFile(true);
    } else {
      setIsPdfFile(false);
    }
  }, [props.fileUrl]);

  const payment = props.findPaymentMethodById(
    props.data.bankingInfo ? props.data.bankingInfo.paymentMethod : 0
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const employerInformationData = useMemo(() => {
    if (props.data.companyInformation)
      return [
        {title: 'Company name', value: props.data.companyInformation.companyName},
        {title: 'No of employees', value: props.data.companyInformation.noOfEmployees},
        {title: 'Referral Name', value: props.data.companyInformation.referralPartnerName},
        {title: 'Referral Team Member Name', value: props.data.companyInformation.referralPartnerUserName},
        {title: 'Joined Date', value: convertTimeZone("Canada/Eastern", props.data.companyInformation.joinDate).format(FE_DETAIL_DATE_FORMAT)},
        {title: 'Sole Proprietor', value: props.data.companyInformation.soleProprietor ? 'Yes' : 'No'},
        {title: <StyledDivTitle>Billing address</StyledDivTitle>, value: ' '},
        {title: 'Street address', value: props.data.companyInformation.streetAddress},
        {title: 'Province', value: props.findProvinceById(props.data.companyInformation.provinceId)},
        {title: 'City', value: props.data.companyInformation.cityName},
        {title: 'PostCode', value: props.data.companyInformation.postcode},
        {title: 'Phone number', value: formatPhoneNumber(props.data.companyInformation.companyPhoneNumber)},
        {title: <StyledDivTitle>Mailing address</StyledDivTitle>, value: ' '},
        {title: 'Street address', value: props.data.companyInformation.streetAddress2},
        {title: 'Province', value: props.findProvinceById(props.data.companyInformation.provinceId2)},
        {title: 'City', value: props.data.companyInformation.cityName2},
        {title: 'PostCode', value: props.data.companyInformation.postcode2},
        {title: 'Phone number', value: formatPhoneNumber(props.data.companyInformation.companyPhoneNumber2)},
      ]
    return []
  },[props.data.companyInformation])

  const employerPlansData = useMemo(() => {
    if (!props.data.employerPlans) return [];
    return props.data.employerPlans.map((item) => ({
      ...item,
      planStartDate: item.planStartDate ? getStaticDateFromServerDate(item.planStartDate) : "",
      action: <StyledSpanAction onClick={() => props.showPlansAndClassesModal(item.employerPlanId)}>Detail</StyledSpanAction>
    }));
  }, [props.data.employerPlans])

  const enrollmentsForCustomAdminFee = useMemo(() => {
    if (!props.data.employerPlans) return [];

    const map = new Map<number, EmployerEnrollment>();
    props.data.employerPlans.forEach((item) => {
      item.employerEnrollments.forEach((enrollment) => {
        if (!map.has(enrollment.enrollmentId)) {
          map.set(enrollment.enrollmentId, enrollment);
        }
      });
    });
    return Array.from(map.values());
  }, [props.data.employerPlans]);

  return (
    <>
      <StyledMainContent>
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-lg-2 mt-3">
              <StyledBigTitle>{props.data.name}</StyledBigTitle>
            </div>

            <div className="col-lg-2 mt-3">
              <StyledTitleBalance title="Balance in Account">
                Balance in Account
              </StyledTitleBalance>
              <StyledBalance
                color={theme.primaryColor}
                title={formatterUSD("currency", "USD").format(
                  centToUsd(props.availableAmount)
                )}
              >
                {formatterUSD("currency", "USD").format(
                  centToUsd(props.availableAmount)
                )}
              </StyledBalance>
            </div>
            <div className="col-lg-2 mt-3">
              <StyledTitleBalance title="Top Up Pending">
                Top Up Pending
              </StyledTitleBalance>
              <StyledBalance
                color={theme.secondaryColor}
                title={formatterUSD("currency", "USD").format(
                  centToUsd(props.pendingAmount.pendingAmount)
                )}
              >
                {formatterUSD("currency", "USD").format(
                  centToUsd(props.pendingAmount.pendingAmount)
                )}
              </StyledBalance>
            </div>
            <div className="col-lg-2 mt-3">
              <StyledTitleBalance title="Claim Pending">
                Claim Pending
              </StyledTitleBalance>
              <StyledBalance
                color={theme.secondaryColor}
                title={formatterUSD("currency", "USD").format(
                  centToUsd(
                    props.pendingAmount.claimRequested +
                    props.pendingAmount.processing
                  )
                )}
              >
                {formatterUSD("currency", "USD").format(
                  centToUsd(
                    props.pendingAmount.claimRequested +
                    props.pendingAmount.processing
                  )
                )}
              </StyledBalance>
            </div>
            <div className="col-lg-2 mt-3">
              <StyledTitleBalance title="Claim Pending">
                Auto Topup Pending
              </StyledTitleBalance>
              <StyledBalance
                  color={theme.secondaryColor}
                  title={formatterUSD("currency", "USD").format(
                      centToUsd(
                          props.pendingAmount.processingMonthlyContribution
                      )
                  )}
              >
                {formatterUSD("currency", "USD").format(
                    centToUsd(
                        props.pendingAmount.processingMonthlyContribution
                    )
                )}
              </StyledBalance>
            </div>

            <div className="col-lg-2">
              <div className="d-flex justify-content-end align-items-end">
                <div className="w-65 mt-4">
                  {props.partnerOptions.allowAdjustment && (
                    <StyledOptionRequest submit={true} onClick={props.showDeposit}>
                      Adjustment
                    </StyledOptionRequest>
                  )}
                </div>
                {props.partnerOptions.allowDisableEmployer &&
                  <div className="w-35 mt-4 d-flex justify-content-end">
                  <IconButton
                    style={{ width: "100%", maxWidth: "50px" }}
                    aria-controls="fade-menu"
                    aria-haspopup="true"
                    className={classes.button}
                    onClick={handleClick}>
                    <MoreVertIcon style={{ color: '#003049' }} />
                  </IconButton>
                </div>
                }
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 mt-3">
              <StyledContentBox>
                <StyledSpanLargerTitle>
                  Company information
                  {props.partnerOptions.allowEditEmployer && (

                    <StyledSpanEditInfo onClick={showCompanyInformationModal}>
                      <CreateIcon fontSize="small"/> Edit
                    </StyledSpanEditInfo>
                  )}
                </StyledSpanLargerTitle>

                <div className="mt-3">
                  {employerInformationData.map((item, idx) => (
                    <StyledLine key={`employer-information-${item.title}-${idx}`} className={idx % 2 === 0 ? 'striped' : ''}>
                      <StyledCell width={widthTitle} className="fw-bold">
                        {item.title}
                      </StyledCell>
                      <StyledCell width={widthParam}>
                        {item.value || '---'}
                      </StyledCell>
                    </StyledLine>
                  ))}
                </div>
              </StyledContentBox>
              {!props.enablePresetBankingInformation && props.partnerOptions.allowViewBankingInfo &&
                  <div className="mt-3">
                      <StyledContentBox>
                          <StyledSpanLargerTitle>
                              Banking info
                            {props.partnerOptions.allowEditBankingInfo &&
                                <StyledSpanEditInfo
                                    onClick={props.showUpdateBankingInformationModal}
                                >
                                    <CreateIcon fontSize="small"/> Edit
                                </StyledSpanEditInfo>
                            }
                          </StyledSpanLargerTitle>

                          <div className="mt-2">{payment.name}</div>

                        {payment.id === 2 && (
                          <ContentBoxCustomStyled className="mt-3">
                            <div className="d-flex">
                              <CellStyled className="w-40 p-2">Account Number</CellStyled>
                              <CellStyled className="w-60 text-right p-2 fw-bold">
                                {props.data.bankingInfo
                                  ? props.data.bankingInfo.accountNumber
                                  : ""}
                              </CellStyled>
                            </div>
                            <div className="d-flex">
                              <CellStyled className="w-40 p-2">Transit Number</CellStyled>
                              <CellStyled className="w-60 text-right p-2 fw-bold">
                                {props.data.bankingInfo
                                  ? props.data.bankingInfo.transitNumber
                                  : ""}
                              </CellStyled>
                            </div>
                            <div className="d-flex">
                              <CellStyled className="w-40 p-2">
                                Institution Number
                              </CellStyled>
                              <CellStyled className="w-60 text-right p-2 fw-bold">
                                {props.data.bankingInfo
                                  ? props.data.bankingInfo.bankNumber
                                  : ""}
                              </CellStyled>
                            </div>

                            <div className="d-flex">
                              <CellStyled className="w-40 p-2">Cheque</CellStyled>
                              <CellStyled className="w-60 text-right p-2 fw-bold">
                                {props.data.bankingInfo &&
                                  (props.fileUrl ? (
                                    isPdfFile ?
                                      <PdfViewer fileUrl={props.fileUrl} height="300px"/>
                                      :

                                      <StyledModalImageContainer>
                                        <ModalImage
                                          className="modal-image"
                                          alt="cheque"
                                          small={props.fileUrl}
                                          large={props.fileUrl}
                                        />
                                      </StyledModalImageContainer>
                                  ) : (
                                    ""
                                  ))}
                              </CellStyled>
                            </div>
                          </ContentBoxCustomStyled>
                        )}
                      </StyledContentBox>
                  </div>
              }
            </div>
            <div className="col-lg-6 mt-3">
              <div className="row">
                <div className="col-12">
                  <StyledContentBox>
                    <StyledSpanLargerTitle>
                      Plans and Classes
                    </StyledSpanLargerTitle>
                    <BNPRawTable
                      columns={PlanAndClassTableHeader}
                      tableWidth="100%"
                      tableData={employerPlansData}
                      tableName={"Employer Plans"}
                    />
                  </StyledContentBox>
                </div>
                {role === "super admin"
                  &&
                    <div className="col-12 mt-3">
                        <CustomAdminFee enrollmentCoverages={enrollmentsForCustomAdminFee}
                                        findEnrollmentById={props.findEnrollmentById}
                                        employerId={props.data.id}
                        />
                    </div>
                }
                {isBeniplusUI
                  && isAdminUser()
                  &&
                    <div className="col-12 mt-3">
                        <PremiumInsurance employerId={props.data.id}/>
                    </div>
                }
                {role === "super admin"
                  &&
                    <div className="col-12 mt-3">
                        <RRSPProvider employerId={props.data.id}/>
                    </div>
                }
              </div>
            </div>


            <div className="col-lg-12 mt-3">
              <StyledContentBox>
                <StyledSpanLargerTitle>
                  Employees management
                  {props.partnerOptions.allowAll && (
                    <StyledSpanEditInfo onClick={showEmployeeModal}>
                      <CreateIcon fontSize="small"/>Add
                    </StyledSpanEditInfo>
                  )}
                </StyledSpanLargerTitle>
                <EmployeeTable
                  employees={props.employees}
                  pagingInfo={props.pagingInfo}
                  changePage={props.changePage}
                  columns={props.columns}
                  sortTable={props.sortTable}
                  setRowsPerPage={props.setRowsPerPage}
                  classes={props.classes}
                  getEmployees={props.getEmployees}
                  actionsHidden={!props.partnerOptions.allowEditEmployee}
                  detailLink={props.partnerOptions.allowAll ? companyManagementDetailEmployeeURL : (
                    props.partnerOptions.allowViewEmployeeDetail ? partnerCompanyManagementDetailEmployeeURL : "")}
                  disableItemClick={!props.partnerOptions.allowViewEmployeeDetail}
                />
              </StyledContentBox>
            </div>

            {props.partnerOptions.allowViewTransactions && (
              <div className="col-lg-12 mt-3">
                <StyledContentBox>
                  <StyledSpanLargerTitle>Transaction</StyledSpanLargerTitle>

                  <div className="row">
                    <div className="col-lg-10 mt-2"></div>
                    <div className="col-lg-2 mt-2">
                      <BNPSelect
                        options={statusTransaction}
                        placeholder="Filter by transaction type"
                        name="transactionType"
                        onChange={props.changeFilterWallet}
                        value={props.searchParamWallet.transactionType || -1}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 mt-2">
                      <BNPRawTable
                        columns={TransactionTableHeader}
                        tableWidth="100%"
                        tableData={props.transactions}
                      />
                      <div className="row mt-4">
                        <div className="col-12 text-center">
                          <label>
                            <Pagination
                              pagerPagination={props.pagingInfoWallet}
                              getPage={props.changePageWallet}
                              onChangeRowsPerPage={props.setRowsPerPageWallet}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </StyledContentBox>
              </div>
            )}
          </div>
        </div>
        <TermsAndConditionsModal/>
      </StyledMainContent>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        style={{marginTop: "50px"}}
        TransitionComponent={Fade}>
        {props.partnerOptions.allowRefund && (
          <div>
            <MenuItem
              onClick={() => {
                handleClose();
                props.showRefund();
              }}
              style={{ fontFamily: 'Graphik Light' }}>
              Refund
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                props.showAutoTopUpPending();
              }}
              style={{ fontFamily: 'Graphik Light' }}>
              Pay auto top-up pending
            </MenuItem>
          </div>
        )}
        <EmployerStatusMenuWrapperAction status={props.data.status}
                                          onAcceptChangeStatus={props.onSubmitChangeStatus}/>
      </Menu>
      <EmployerRefundView
        id={props.data.id}
        amount={props.amount}
        availableAmount={props.availableAmount}
        handleChangeInputRefund={props.handleChangeInputRefund}
      />
    </>
  );
}
const urls = {
  development: "http://127.0.0.1:5555",
  production: "https://api-prod.beniplus.ca",
} as any;
const API_URL = urls[process.env.NODE_ENV];
export default API_URL;

export const maxAge = "21600";

export const homePage = "/claim-management/";

export const claimManagementURL = "/claim-management/";
export const claimManagementDetailURL = "/claim-management/detail/";
export const companyManagementURL = "/company-management/";
export const companyManagementDetailURL = "/company-management/detail/";
export const companyManagementDetailEmployeeURL =
  "/company-management/detail/employee/";
export const employeesManagementUrl = "/employees-management/";
export const adminManagementURL = "/admin-management/";
export const claimHistoryURL = "/claim-history/";
export const rrspExportURL= "/monthly-contribution-export/";
export const eftInformationURL = "/eft-information/";
export const exportEftFileURL = "/export-eft-file/";
export const signInURL = "/sign-in/";
export const taxURL = "/report-tax/";
export const requestDepositWithdrawTransactionURL = "/deposit-withdraw-transaction/request";
export const depositWithdrawTransactionURL = "/deposit-withdraw-transaction/";
export const depositWithdrawTransactionHistoryURL = "/deposit-withdraw-transaction/history";
export const contactURL = "/contact/";
export const emailHistoryURL = "/email-history/";
export const adminDashboardURL = "/dashboard/";
export const requiredTopUpReportURL = "/required-topup-report/"
export const partnerManagementURL = "/partner-management/";
export const forgotPasswordURL = "/forgot-password/";
export const updatePasswordURL = "/update-password/";
export const partnerDetailURL = partnerManagementURL + "detail/";
export const marketplaceURL = "/marketplace/";



// PARTNER PORTAL

export const partnerPrefix = "/partner";


export const partnerDashboardURL = partnerPrefix + "/dashboard/";

export const partnerCompanyManagementURL = partnerPrefix + "/company-management/";
export const partnerCompanyManagementDetailURL = partnerPrefix + "/company-management/detail/";
export const partnerCompanyManagementDetailEmployeeURL = partnerPrefix + "/company-management/detail/employee/";

export const partnerEmployeeManagementURL = partnerPrefix + "/employees-management/";

export const partnerProfileURL = partnerPrefix + "/profile/";


// search param
export const claimManagementSearchParamDefault =
  "?page=1&status=REQUESTED&rowsPerPage=10&sortType=DESC&columnName=submittedDate";
export const companySearchParamDefault =
  "?page=1&rowsPerPage=10&sortType=DESC&columnName=joinedDate&status=Enabled";
export const companySearchParamDetailDefault = (id: number) =>
  `?id=${id}&page=1&rowsPerPage=10&pageWallet=1&rowsPerPageWallet=10&sortType=DESC&columnName=eligibilityDate`;
export const companySearchParamDetailEmployeeDefault = (id: number) =>
  `?id=${id}&page=1&rowsPerPage=10&sortType=DESC&columnName=submittedDate`;
export const adminManagementSearchParamDefault = "?page=1&rowsPerPage=10";
export const claimHistoryManagementSearchParamDefault =
  "?page=1&pageSize=10&sortType=DESC&columnName=paidDate";
export const depositWithdrawTransactionSearchParamDefault =
  "?page=1&rowsPerPage=10&sortType=DESC&columnName=date";

export const taxSearchParamDefault = "?page=1&pageSize=10&dateRange=THIS_MONTH";

type SearchParam = {
  name: string;
  value: string | number;
};

export const setURLFromParamSearch = (
  url: string,
  paramSearch: SearchParam[]
) => {
  let returnUrl = new URL(window.location.href);
  returnUrl.pathname = url;

  paramSearch.forEach((param) => {
    returnUrl.searchParams.set(param.name, param.value.toString());
  });

  return returnUrl.pathname + returnUrl.search;
};

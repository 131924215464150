import React, {useEffect, useState} from "react";
import {EFTBlacklistSearchParam} from "./eft-blacklist-type";
import useDebounce from "../../../../cores/hook/useDebounce";
import {StyledPTitle} from "../../../shared/styled";
import {BNPInput} from "../../../../cores/helpers/input/input";
import {BNPModal} from "../../../shared/BNPModal/BNPModal";
import BNPRawTable from "../../../shared/BNPTable/BNPRawTable";
import Pagination, {setPagingInfo} from "../../../../cores/helpers/pagination/pagination";
import {
  blacklistSearchEmployeeOptionColumns,
  blacklistSearchEmployerOptionColumns
} from "./eft-blacklist-model";
import {
  getEFTEmployeeBlacklistSearchOption,
  getEFTEmployerBlacklistSearchOption
} from "../../../../services/eft-service";
import {StyledSpanAction} from "../../employees-management/employee-action";

type EftBlacklistAddNewModalProps = {
  type: "employeeBlacklist" | "employerBlacklist" | "";
  open: boolean;
  handleClose: () => void;
  handleAdd: (request: any, type: "employeeBlacklist" | "employerBlacklist") => void;
};

export function EftBlacklistAddNewModal(props: EftBlacklistAddNewModalProps) {
  const [input, setInput] = useState<string>("");
  const [searchParams, setSearchParams] = React.useState<EFTBlacklistSearchParam>({
    searchKey: "",
    page: 1,
    perPage: 10,
    columnName: "",
    sortType: null,
  })
  const [options, setOptions] = React.useState<any[]>([]);
  const [paging, setPaging] = React.useState<any>(setPagingInfo(1, 0, 10));
  const debounceInput = useDebounce(input, 500);

  useEffect(() => {
    setSearchParams(prevState => (
      {...prevState, searchKey: debounceInput}
    ));
  }, [debounceInput]);

  useEffect(() => {
    if (props.open && props.type === "employeeBlacklist" && !!searchParams.searchKey) {
      getEFTEmployeeBlacklistSearchOption(searchParams).then((response) => {
        setOptions(convertSearchOptions(response.data.records));
        setPaging(setPagingInfo(searchParams.page, response.data.pageCount, searchParams.perPage));
      });
    }

    if (props.open && props.type === "employerBlacklist" && !!searchParams.searchKey) {
      getEFTEmployerBlacklistSearchOption(searchParams).then((response) => {
        setOptions(convertSearchOptions(response.data.records));
        setPaging(setPagingInfo(searchParams.page, response.data.pageCount, searchParams.perPage));
      });
    }
  }, [props.open, props.type, searchParams.searchKey, searchParams.page, searchParams.perPage]);

  const convertSearchOptions = (options: any[]) => {
    return options.map((option) => ({
      ...option,
      action: <StyledSpanAction
        onClick={() =>
          !!props.type && props.handleAdd(option, props.type)}
      >
        Add
      </StyledSpanAction>
    }))
  }

  useEffect(() => {
    return () => {
      setSearchParams({
        searchKey: "",
        page: 1,
        perPage: 10,
        columnName: "",
        sortType: null,
      });
      setOptions([]);
      setInput("");
      setPaging(setPagingInfo(1, 0, 10));
    }
  }, [props.open, props.type]);

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value);
  };

  const setRowsPerPage = (event: any) => {
    const { value } = event.target;
    setSearchParams(prevState => (
      {...prevState, page: 1, perPage: Number(value)}
    ));
  };

  const handleChangePage = (page: number) => {
    setSearchParams(prevState => ({...prevState, page}));
  };

  const modalBody =
    <div className="row">
      <div className="col-12 mb-4">
        <StyledPTitle className="mb-2">
          Search {props.type === "employeeBlacklist" ? "Employee" : "Employer"}
        </StyledPTitle>
        <BNPInput name="searchKey" value={input} onChange={handleChangeInput} />
      </div>
      <div className="col-12">
        <BNPRawTable
          columns={props.type === "employeeBlacklist" ?
            blacklistSearchEmployeeOptionColumns :
            blacklistSearchEmployerOptionColumns
          }
          tableWidth='100%'
          tableData={options}
          tableName={"blacklistSearchOption"}
        />
        <div className="row mt-4">
          <div className="col-12 text-center">
            <label>
              <Pagination
                pagerPagination={paging}
                getPage={handleChangePage}
                onChangeRowsPerPage={setRowsPerPage}
              />
            </label>
          </div>
        </div>
      </div>

    </div>


  return (
    <BNPModal
      open={props.open}
      title="Add To Blacklist"
      handleClose={props.handleClose}
      body={modalBody}
      footer={<></>}
    />
  );
}

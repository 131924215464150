import React from "react";
import {
  StyledMainContent,
  StyledBigTitle,
  StyledContentBox,
  StyledColor,
  StyledHideForMobile,
  StyledHideForDesktop,
  StyledExpansionPanelSummary,
  StyledTitleExpansion,
  StyledExpansionPanelDetails,
  StyledSpanExpansionEdit,
  StyledSpanExpansionDelete,
} from "../../shared/styled";
import { BNPSelect } from "../../../cores/helpers/select/select";
import { BNPInput } from "../../../cores/helpers/input/input";
import styled from "styled-components";
import SearchIcon from "@material-ui/icons/Search";
import {
  StyledTable,
  StyledHeaderTable,
  StyledCellTable,
  StyledArrowDropDownIcon,
  StyledArrowDropUpIcon,
  StyledImportExportIcon,
  StyledBodyTable,
} from "../../shared/table-styled";
import Pagination, {
  PagingInfo,
} from "../../../cores/helpers/pagination/pagination";
import { Admin, SearchParamAdmin } from "./manege-admin-type";
import theme from "../../../cores/helpers/theme";
import { SortTable } from "../../../cores/models/SortTable";
import { StatusCompany } from "../company-management/company-management-type";
import { capFirst } from "../../../cores/helpers/cap-first";
import EditIcon from "@material-ui/icons/Edit";
import EditAdminModal from "./manage-admin-modal-edit";
import { ConfirmDialog } from "../../../cores/helpers/confirm-modal/confirm-modal";
import AddNewAdminModal, {
  showAddNewAdminModal,
} from "./manage-admin-modal-add-new";
import { SignUpModel } from "../../../services/auth-service";
import ClearIcon from "@material-ui/icons/Clear";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import CheckIcon from "@material-ui/icons/Check";
import { getInfoByToken } from "../../../cores/utils/helpers";
import { ExpansionPanel } from "@material-ui/core";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import { AdminRole } from "./admin-management-type";

const StyledIconSearch = styled(SearchIcon)`
  position: absolute;
  top: 13px;
  right: 14px;
  opacity: 0.7;
`;

const StyledBNPInput = styled(BNPInput)`
  padding-right: 35px;
`;
const StyledDivBoxTable = styled.div`
  min-height: 300px;
  width: 94%;
`;

const StyledDivAddNew = styled.div`
  position: absolute;
  right: 5px;
  color: ${(props) => props.theme.primaryColor};
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`;

const StyledSpanIconEdit = styled.span`
  position: relative;
  display: flex;
  top: 12px;
  left: 1%;
  cursor: pointer;
  width: 0;
`;
const StyledIconEdit = styled(EditIcon)`
  width: 18px !important;
  height: 18px !important;
  color: ${(props) => props.theme.primaryColor};
`;
const StyledIconReject = styled(ClearIcon)`
  width: 20px !important;
  height: 20px !important;
  color: ${(props) => props.theme.secondaryColor};
  margin-top: -2px;
  margin-left: 5px;
`;
const StyledIconActive = styled(CheckIcon)`
  width: 20px !important;
  height: 20px !important;
  color: ${(props) => props.theme.primaryColor};
  margin-top: -2px;
  margin-left: 5px;
`;
const StyledIconResetPassword = styled(AutorenewIcon)`
  width: 20px !important;
  height: 20px !important;
  color: ${(props) => props.theme.primaryColor};
  margin-top: -2px;
  margin-left: 5px;
`;

type Props = {
  admins: Admin[];
  admin: Admin;
  columns: SortTable[];
  statuses: StatusCompany[];
  roles: AdminRole[];
  searchParam: SearchParamAdmin;
  pagingInfo: PagingInfo;
  newAdmin: SignUpModel;
  isValid: boolean;
  isChange: boolean;
  isActive: boolean;

  handleChangSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyUp: (event: any) => void;
  searchNameOrEmail: () => void;
  handleChangePage: (page: number) => void;
  sortTable: (columnId: string) => void;
  handleShowModalConfirmPartner: (admin: Admin, isChange: boolean) => void;
  handleShowModalEditPartner: (admin: Admin) => void;
  handleRejectPartner: () => void;
  handleChangInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleEditPartner: () => void;
  handleChangInputAddNewPartner: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleAddNewPartner: () => void;
  handleResetPassword: () => void;
  setRowsPerPage: (event: any) => void;
  handleActivePartner: () => void;
  handleShowModalActivePartner: (admin: Admin) => void;
  handleChangeSelectAddNewPartner: (event: any) => void;
};

export default function ManageAdminView(props: Props) {
  const findStatusNameById = (id: string) => {
    let status = props.statuses.find((status) => {
      return status.id === id;
    });

    return status ? status.name : "";
  };

  let isSuperAdmin = getInfoByToken().permission === "super admin";

  return (
    <StyledMainContent>
      <StyledBigTitle>Admin Management</StyledBigTitle>
      <div className="container-fluid p-0 mt-3">
        <StyledContentBox>
          <div className="row">
            <div className="col-lg-12 mt-4">
              <div className="row">
                <div className="col-lg-2 mt-4">
                  <BNPSelect
                    options={props.statuses}
                    value={props.searchParam.status}
                    name="status"
                    placeholder="Filter by status"
                    onChange={props.handleChangSelect}
                  />
                </div>
                <div className="col-lg-2 mt-4">
                  <BNPSelect
                    options={props.roles}
                    value={props.searchParam.role}
                    name="role"
                    placeholder="Filter by role"
                    onChange={props.handleChangSelect}
                  />
                </div>

                <div className="col-lg-2 mt-4">
                  <StyledBNPInput
                    placeholder="Search name or email"
                    value={props.searchParam.searchKey || ""}
                    onKeyUp={props.onKeyUp}
                    onChange={props.handleChangeInput}
                  />
                  <StyledIconSearch onClick={props.searchNameOrEmail} />
                </div>
                <StyledDivAddNew onClick={showAddNewAdminModal}>
                  Add new admin
                </StyledDivAddNew>
              </div>
            </div>
            <StyledDivBoxTable className="mt-4">
              <StyledHideForMobile>
                <StyledTable>
                  <StyledHeaderTable>
                    {props.columns.map((column, index) => (
                      <StyledCellTable
                        width={column.width}
                        key={index}
                        className="cur"
                        onClick={() => props.sortTable(column.columnId)}
                      >
                        {column.columnName}
                        <span>
                          {column.sortType ? (
                            column.sortType === "ASC" ? (
                              <StyledArrowDropDownIcon />
                            ) : (
                              <StyledArrowDropUpIcon />
                            )
                          ) : (
                            <StyledImportExportIcon />
                          )}
                        </span>
                      </StyledCellTable>
                    ))}
                  </StyledHeaderTable>
                  {props.admins.map((admin, i) => (
                    <div className="d-flex">
                      <StyledBodyTable
                        key={i}
                        onClick={() =>
                          isSuperAdmin
                            ? props.handleShowModalEditPartner({ ...admin })
                            : null
                        }
                      >
                        <StyledCellTable width={25}>
                          {admin.firstName + " " + admin.lastName}
                        </StyledCellTable>
                        <StyledCellTable width={25}>
                          {admin.email}
                        </StyledCellTable>
                        <StyledCellTable width={25}>
                          {capFirst(admin.roleName)}
                        </StyledCellTable>
                        <StyledCellTable width={25} className="text-right">
                          <StyledColor
                            color={
                              admin.status === "ACTIVE"
                                ? theme.primaryColor
                                : theme.secondaryColor
                            }
                          >
                            {findStatusNameById(admin.status)}
                          </StyledColor>
                        </StyledCellTable>
                      </StyledBodyTable>
                      <StyledSpanIconEdit>
                        {isSuperAdmin && (
                          <span title="Edit admin">
                            <StyledIconEdit
                              onClick={() =>
                                isSuperAdmin
                                  ? props.handleShowModalEditPartner({
                                      ...admin,
                                    })
                                  : null
                              }
                            />
                          </span>
                        )}

                        {admin.status === "ACTIVE" ? (
                          <span title="Reject admin">
                            <StyledIconReject
                              onClick={() =>
                                props.handleShowModalConfirmPartner(
                                  admin,
                                  false
                                )
                              }
                            />
                          </span>
                        ) : (
                          <span title="Active admin">
                            <StyledIconActive
                              onClick={() =>
                                props.handleShowModalActivePartner(admin)
                              }
                            />
                          </span>
                        )}
                        <span title="Reset password">
                          <StyledIconResetPassword
                            onClick={() =>
                              props.handleShowModalConfirmPartner(admin, true)
                            }
                          />
                        </span>
                      </StyledSpanIconEdit>
                    </div>
                  ))}
                  <div className="row mt-4">
                    <div className="col-12 text-center">
                      <label>
                        <Pagination
                          pagerPagination={props.pagingInfo}
                          getPage={props.handleChangePage}
                          onChangeRowsPerPage={props.setRowsPerPage}
                        />
                      </label>
                    </div>
                  </div>
                </StyledTable>
              </StyledHideForMobile>
              <StyledHideForDesktop>
                {props.admins.map((admin, index) => (
                  <ExpansionPanel
                    square
                    defaultExpanded={false}
                    className="mt-2"
                    key={index}
                  >
                    <StyledExpansionPanelSummary
                      className="m-0"
                      expandIcon={<ArrowDropUp />}
                    >
                      <div className="w-100 ">
                        <StyledTitleExpansion>Name</StyledTitleExpansion>
                        <div className="mt-2">
                          {admin.firstName + " " + admin.lastName}
                        </div>
                      </div>
                    </StyledExpansionPanelSummary>
                    <StyledExpansionPanelDetails>
                      <div className="w-100">
                        <StyledTitleExpansion>Email</StyledTitleExpansion>
                        <div className="mt-1">{admin.email}</div>
                      </div>
                      <div className="w-100 mt-3">
                        <StyledTitleExpansion>Role name</StyledTitleExpansion>
                        <div className="mt-1">{capFirst(admin.roleName)}</div>
                      </div>
                      <div className="w-100 mt-3">
                        <StyledTitleExpansion>Status</StyledTitleExpansion>
                        <div className="mt-1">
                          <StyledColor
                            color={
                              admin.status === "ACTIVE"
                                ? theme.primaryColor
                                : theme.secondaryColor
                            }
                          >
                            {findStatusNameById(admin.status)}
                          </StyledColor>
                        </div>
                      </div>

                      <hr />
                      <div className="text-center">
                        {isSuperAdmin && (
                          <StyledSpanExpansionEdit
                            onClick={() =>
                              isSuperAdmin
                                ? props.handleShowModalEditPartner({
                                    ...admin,
                                  })
                                : null
                            }
                          >
                            <StyledIconEdit />
                            Edit admin
                          </StyledSpanExpansionEdit>
                        )}

                        {admin.status === "ACTIVE" ? (
                          <StyledSpanExpansionDelete
                            onClick={() =>
                              props.handleShowModalConfirmPartner(admin, false)
                            }
                          >
                            <StyledIconReject />
                            Reject admin
                          </StyledSpanExpansionDelete>
                        ) : (
                          <StyledSpanExpansionEdit
                            onClick={() =>
                              props.handleShowModalActivePartner(admin)
                            }
                          >
                            <StyledIconActive />
                            Active admin
                          </StyledSpanExpansionEdit>
                        )}
                        <StyledSpanExpansionEdit
                          onClick={() =>
                            props.handleShowModalConfirmPartner(admin, true)
                          }
                        >
                          <StyledIconResetPassword />
                          Reset password
                        </StyledSpanExpansionEdit>
                      </div>
                    </StyledExpansionPanelDetails>
                  </ExpansionPanel>
                ))}
                <div className="row mt-4">
                  <div className="col-12 text-center">
                    <label>
                      <Pagination
                        pagerPagination={props.pagingInfo}
                        getPage={props.handleChangePage}
                        onChangeRowsPerPage={props.setRowsPerPage}
                      />
                    </label>
                  </div>
                </div>
              </StyledHideForDesktop>
            </StyledDivBoxTable>
          </div>
        </StyledContentBox>
      </div>

      <EditAdminModal
        admin={props.admin}
        handleChangInput={props.handleChangInput}
        handleEditPartner={props.handleEditPartner}
      />
      <ConfirmDialog
        title={
          props.isChange
            ? "Reset password"
            : props.isActive
            ? "Disable"
            : "Active"
        }
        content={
          props.isChange
            ? "Are you sure you want to reset password this user?"
            : props.isActive
            ? "Are you sure you want to disable this user?"
            : "Are you sure you want to activate this user?"
        }
        handleOnOk={
          props.isChange
            ? props.handleResetPassword
            : props.isActive
            ? props.handleRejectPartner
            : props.handleActivePartner
        }
      />
      <AddNewAdminModal
        newAdmin={props.newAdmin}
        isValid={props.isValid}
        handleAddNewPartner={props.handleAddNewPartner}
        handleChangInputAddNewPartner={props.handleChangInputAddNewPartner}
        handleChangeSelect={props.handleChangeSelectAddNewPartner}
      />
    </StyledMainContent>
  );
}

import {TableSortType} from "../../../../../../../src/components/shared/BNPTable/BNPTableType";
import { PartnerUserData } from "../../partner-management-type";

export const defaultPartnerAdmin: PartnerUserData = {
  firstName: "",
  lastName: "",
  email: "",
  status: "ACTIVE",
  roleId: -1,
  id: -1,
  isLicensed: false,
  partnerRole: {
    id: -1,
    name: ""
  },
  licenseAgentInformation: null,
};



export const PartnerAdminManagementColumns: TableSortType[] = [
  {
    columnId: "id",
    columnName: "ID",
    sortType: null,
    width: 8,
    sortColumn: false
  },
  {
    columnId: "name",
    columnName: "Full name",
    sortType: null,
    width: 25,
    sortColumn: false,
  },
  {
    columnId: "role",
    columnName: "Role",
    sortType: null,
    width: 25,
    sortColumn: false,
  },
  {
    columnId: "email",
    columnName: "Email",
    sortType: null,
    width: 25,
    sortColumn: false,
  },
  {
    columnId: "status",
    columnName: "Status",
    sortType: null,
    width: 15,
    sortColumn: false,
  },
  {
    columnId: "actions",
    columnName: "Actions",
    sortType: null,
    width: 15,
    sortColumn: false,
  },
]
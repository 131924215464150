import {
  EFTBlacklistAddRequest,
  EFTBlacklistSearchParam, EFTBlacklistUpdateRequest
} from "../components/pages/eft-export/eft-blacklist/eft-blacklist-type";
import {AxiosResponse} from "axios";
import {getAsync, postAsync, putAsync} from "../cores/utils/http-client";

const URL_Partner = "/partner-auth/api";

export const getEFTEmployeeBlacklist = (
  param: EFTBlacklistSearchParam
): Promise<AxiosResponse> => {
  let url = `${URL_Partner}/blacklist/employees`;

  if (!param.columnName) {
    delete param.columnName;
    delete param.sortType;
  }

  return getAsync(url, param);
};

export const getEFTEmployerBlacklist = (
  param: EFTBlacklistSearchParam
): Promise<AxiosResponse> => {
  let url = `${URL_Partner}/blacklist/employers`;

  if (!param.columnName) {
    delete param.columnName;
    delete param.sortType;
  }

  return getAsync(url, param);
};

export const getEFTEmployeeBlacklistSearchOption = (
  param: EFTBlacklistSearchParam
): Promise<AxiosResponse> => {
  let url = `${URL_Partner}/admin/employees/search-by-name`;

  if (!param.columnName) {
    delete param.columnName;
    delete param.sortType;
  }

  const requestParam = {
    name: param.searchKey,
    ...param
  }

  return getAsync(url, requestParam);
};

export const getEFTEmployerBlacklistSearchOption = (
  param: EFTBlacklistSearchParam
): Promise<AxiosResponse> => {
  let url = `${URL_Partner}/admin/employers/search-by-name`;

  if (!param.columnName) {
    delete param.columnName;
    delete param.sortType;
  }

  const requestParam = {
    name: param.searchKey,
    ...param
  }

  return getAsync(url, requestParam);
};

export const postEFTEmployeeBlacklist = (
  request: EFTBlacklistAddRequest
): Promise<AxiosResponse> => {
  let url = `${URL_Partner}/blacklist/employees`;

  return postAsync(url, request);
};

export const postEFTEmployerBlacklist = (
  request: EFTBlacklistAddRequest
): Promise<AxiosResponse> => {
  let url = `${URL_Partner}/blacklist/employers`;

  return postAsync(url, request);
};

export const updateEFTEmployeeBlacklist = (
  request: EFTBlacklistUpdateRequest
): Promise<AxiosResponse> => {
  let url = `${URL_Partner}/blacklist/employees`;

  return putAsync(url, request);
};

export const updateEFTEmployerBlacklist = (
  request: EFTBlacklistUpdateRequest
): Promise<AxiosResponse> => {
  let url = `${URL_Partner}/blacklist/employers`;

  return putAsync(url, request);
};
import {StyledDivTitle} from "../../../shared/styled";
import React from "react";
import { PartnerDetail, PartnerInformation, PartnerLicenseAgentInformation, PartnerLocation } from "../partner-management-type";
import { PartnerTypes } from "../partner-management-model";
import moment from "moment";
import { FE_DATE_FORMAT } from "../../../../cores/utils/format/date-time-format";

export const DetailInformationDataModel = [
  {
    title: <StyledDivTitle className="mt-2 mb-2">Partner Information</StyledDivTitle>,
    param: "",
    getValue: (v: any): any => "",
  },
  {
    title: "Partner Name",
    param: "",
    getValue: (v: PartnerDetail) => {
      if (!v) return "---";

      let name = v.contactFirstName + " " + v.contactLastName;
      if (v.type === PartnerTypes.COMPANY) {
        name = v.companyName ? v.companyName : "---";
      }
      return name;
    },
  },
  {
    title: "Joined Date",
    param: "createdDate",
    getValue: (v: any) => (v && moment(new Date(v)).format(FE_DATE_FORMAT)) || "---",
  },
  {
    title: "Type of Partner",
    param: "type",
    getValue: (v: any) => v || "---",
  },
  {
    title: <StyledDivTitle className="mt-2 mb-2">Billing Address</StyledDivTitle>,
    param: "",
    getValue: (v: any): any => "",
  },
  {
    title: "Street Address",
    param: "partnerLocation",
    getValue: (v: PartnerLocation) => (v && v.billingStreetAddress) || "---",
  },
  {
    title: "Province",
    param: "partnerLocation",
    getValue: (v: PartnerLocation) => (v && v.billingProvinceName) || "---",
  },
  {
    title: "City",
    param: "partnerLocation",
    getValue: (v: PartnerLocation) => (v && v.billingCityName) || "---",
  },
  {
    title: "Postal Code",
    param: "partnerLocation",
    getValue: (v: PartnerLocation) => (v && v.billingPostcode) || "---",
  },
  {
    title: "Phone Number",
    param: "partnerLocation",
    getValue: (v: PartnerLocation) => (v && v.billingPhoneNumber) || "---",
  },
  {
    title: "Email",
    param: "",
    getValue: (v: PartnerDetail) => {
      if (!v) return "---";

      let email = v.contactEmail;
      if (v.type === PartnerTypes.COMPANY) {
        email = v.companyEmail ? v.companyEmail : "---";
      }
      return email;
    },
  },
  {
    title: <StyledDivTitle className="mt-2 mb-2">Licensed Agent</StyledDivTitle>,
    param: "",
    getValue: (v: any): any => "",
  },
  {
    title: "Agent License Number",
    param: "partnerLicenseAgentInformation",
    getValue: (v: PartnerLicenseAgentInformation) => (v && v.licenseNumber) || "---",
  },
  {
    title: "Expiry Date",
    param: "partnerLicenseAgentInformation",
    getValue: (v: PartnerLicenseAgentInformation) => (v && v.enoExpiryDate && moment(new Date(v.licenseExpiryDate)).format(FE_DATE_FORMAT)) || "---",
  },
  {
    title: "Agent E&O Number",
    param: "partnerLicenseAgentInformation",
    getValue: (v: PartnerLicenseAgentInformation) => (v && v.enoNumber) || "---",
  },
  {
    title: "E&O Provider",
    param: "partnerLicenseAgentInformation",
    getValue: (v: PartnerLicenseAgentInformation) => (v && v.enoProvider) || "---",
  },
  {
    title: "E&O Expiry Date",
    param: "partnerLicenseAgentInformation",
    getValue: (v: PartnerLicenseAgentInformation) => (v && v.enoExpiryDate && moment(new Date(v.enoExpiryDate)).format(FE_DATE_FORMAT)) || "---",
  },
];
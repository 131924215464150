import React, {useEffect, useState} from "react";
import { BNPModal } from "../../../../shared/BNPModal/BNPModal";
import {StyledButtonSubmit, StyledPTitle} from "../../../../shared/styled";
import {BNPSelect} from "../../../../../cores/helpers/select/select";
import {BNPInput} from "../../../../../cores/helpers/input/input";
import { RRSPProviderType } from "./rrsp-provider-model";

type RRSPProviderModalProps = {
  rrspProviderOption: RRSPProviderType[];
  currentRRSPProvider: RRSPProviderType | undefined;
  open: boolean;
  setOpen: any;
  handleClose: () => void;
  handleSubmit: (request: any) => void;
};

export function RRSPProviderModal(props: RRSPProviderModalProps) {
  const [model, setModel] = useState<RRSPProviderType | undefined>(props.currentRRSPProvider);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    setModel(props.currentRRSPProvider);
  }, [props.open]);

  const handleChangeSelect = (event: any) => {
    const { name, value } = event.target;

    if (name === 'id') {
      const rrspProvider = props.rrspProviderOption.find((x) => x.id === parseInt(value));

      if (rrspProvider) {
        setModel(rrspProvider);
        setIsValid(!!props.currentRRSPProvider && rrspProvider.id !== props.currentRRSPProvider.id);
      } else {
        setModel(undefined);
        setIsValid(false);
      }
    }
  }

  const handleSubmit = () => {
    props.handleSubmit(model)
  }

  const modalBody =
    <div className="row">
      <div className="col-md-6 mb-4">
        <StyledPTitle className="mb-2">
          RRSP Provider
        </StyledPTitle>
        <BNPSelect
          name="id"
          value={!!model ? model.id : -1}
          options={props.rrspProviderOption}
          onChange={handleChangeSelect}
          placeholder="Select RRSP Provider"
        />
      </div>
      <div className="col-md-6 mb-4">
        <StyledPTitle className="mb-2">
          Trigger Date
        </StyledPTitle>
        <BNPInput name="triggerDateDescription" value={!!model ? model.triggerDateDescription : ""} disabled />
      </div>
    </div>


  return (
    <BNPModal
      open={props.open}
      title="RRSP Provider"
      handleClose={props.handleClose}
      body={modalBody}
      footer={
        <div className="col-md-4 p-0">
          <StyledButtonSubmit
            disabled={!isValid}
            onClick={handleSubmit}
            submit={isValid}
            type="submit"
          >
            Save
          </StyledButtonSubmit>
        </div>
      }
    />
  );
}

import React from "react";
import styled from "styled-components";
import {
  StyledButtonSubmit,
  StyledPTitle,
  StyledDivDatePicker,
} from "../styled";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { convertTimeZone } from "../../../cores/utils/helpers";
import { BankingInfo } from "./BankingInformationType";
import OCRCheque from "../../../cores/helpers/ocr-cheque/ocr-cheque";
import ImageUpload from "../../../cores/helpers/upload-image/image-upload";
import { BNPInput } from "../../../cores/helpers/input/input";
import Cheque from "../../../cores/config/component/images/cheque.jpg";
import { putPartnerBankingInformation } from "../../../services/partner-service";
import { publishMessage } from "../../../cores/utils/message";
import { getFileUrl } from "../../../services/file-service";
import moment from "moment";
import {BNPModal} from "../BNPModal/BNPModal";

const StyledDivBoxCheque = styled.div`
  background-color: #f4f5f9;
  padding: 21px 17px;
  border-radius: 5px;
  margin-top: 1.5rem;
`;

const StyledImgCheque = styled.img`
  max-height: 100%;
  max-width: 100%;
`;

const StyledSpan = styled.span`
  font-size: 12px !important;
`;

type Props = {
  bankingInfoEdit: BankingInfo;
  partnerId: number;
  successCallBack: () => void;
  open: boolean;
  handleClose: () => void;
};

function UpdateBankingInformationModal(props: Props) {
  const [bankingInfoEdit, setBankingInfoEdit] = React.useState<BankingInfo>(props.bankingInfoEdit);
  const [fileUrlEdit, setFileUrlEdit] = React.useState<string>("");

  React.useEffect(() => {
    setBankingInfoEdit(props.bankingInfoEdit);
  }, [props.bankingInfoEdit]);


  React.useEffect(() => {
    if (props.bankingInfoEdit.voidChequeUrl) {
      getFileUrl(props.bankingInfoEdit.voidChequeUrl).then((response) => {
        setFileUrlEdit(response.data);
      });
    }
  }, [props.bankingInfoEdit.voidChequeUrl]);

  const handleChangeInputChequeInfo = (event: any) => {
    const { name, value } = event.target;

    let bankingInfo: any = Object.assign({}, bankingInfoEdit);

    if (name !== "eSignature") {
      if (Number(value) >= 0) {
        bankingInfo[name] = value;
      }
    } else {
      bankingInfo.eSignature = value;
    }

    setBankingInfoEdit(bankingInfo);
  };

  const handleChangeVoidCheque = (urlImage: string) => {
    let temp: BankingInfo = bankingInfoEdit;
    if (urlImage) {
      temp.voidChequeUrl = urlImage;
      getFileUrl(urlImage).then((response) => {
        setFileUrlEdit(response.data);
      });
      setBankingInfoEdit(temp);
    }
  };

  const handleOCRCheque = (urlBlob: string) => {
    //addLoading();
    OCRCheque(urlBlob, "../../tessdata", (res: any) => {
      if (!res.error) {
        const newCheque: BankingInfo = { ...bankingInfoEdit };
        newCheque.transitNumber = res.numbers.transit;
        newCheque.bankNumber = res.numbers.institution;
        newCheque.accountNumber = res.numbers.account;

        setBankingInfoEdit(newCheque);
      }
      //removeLoading();
    });
  };

  const handleChangeESignatureDate = (date: Date | null, name: string) => {
    const bankingInfoEditClone: BankingInfo = { ...bankingInfoEdit };
    bankingInfoEditClone.eSignatureDate = date ? date : new Date();
    setBankingInfoEdit(bankingInfoEditClone)
  }

  const validateBankingInfo = () => {
    return !!((
      bankingInfoEdit.accountNumber &&
      bankingInfoEdit.bankNumber &&
      bankingInfoEdit.eSignature &&
      bankingInfoEdit.transitNumber &&
      bankingInfoEdit.eSignatureDate &&
      bankingInfoEdit.voidChequeUrl));
  };

  const isValid = validateBankingInfo();

  const handleSubmitBankingInfo = () => {
    if (isValid) {

      let editRequest: BankingInfo = { ...bankingInfoEdit };
      editRequest.eSignatureDate = moment(new Date(bankingInfoEdit.eSignatureDate)).format("YYYY-MM-DD");

      putPartnerBankingInformation(editRequest, props.partnerId).
        then(res => {
          if (res && res.status === 200) {
            publishMessage({
              message: "Partner banking information has been modified successfully.",
              variant: "success",
            });
            props.successCallBack();
            props.handleClose();
          }
        })
    }
  }

  const modalBody = (
    <div className="p-2">
      <StyledDivBoxCheque>
        <div className="row">
          <div className="col-lg-5">
            <div className="col-lg-12 mt-4">
              <StyledPTitle className="mb-2">
                Void Cheque
              </StyledPTitle>
              <div className="mt-2">
                <ImageUpload
                  addFile={handleChangeVoidCheque}
                  addBlob={handleOCRCheque}
                  urlImage={fileUrlEdit}
                  folderName="Partner/Cheque"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="row">
              <div className="col-lg-6 mt-4">
                <StyledPTitle className="mb-2">
                  Account Number
                </StyledPTitle>
                <BNPInput
                  maxLength={12}
                  name="accountNumber"
                  className="mt-2"
                  value={bankingInfoEdit.accountNumber}
                  onChange={handleChangeInputChequeInfo}
                  tabIndex={1}
                />
              </div>
              <div className="col-lg-6 mt-4">
                <StyledPTitle className="mb-2">
                  Transit Number
                </StyledPTitle>

                <BNPInput
                  maxLength={5}
                  className="mt-2"
                  value={bankingInfoEdit.transitNumber}
                  onChange={handleChangeInputChequeInfo}
                  name="transitNumber"
                  tabIndex={2}
                />
              </div>
              <div className="col-lg-6 mt-4">
                <StyledPTitle className="mb-2">
                  Institution Number
                </StyledPTitle>
                <BNPInput
                  maxLength={3}
                  name="bankNumber"
                  className="mt-2"
                  value={bankingInfoEdit.bankNumber}
                  onChange={handleChangeInputChequeInfo}
                  tabIndex={3}
                />
              </div>
              <div className="col-lg-6 mt-4">
                <StyledPTitle className="mb-2">
                  E-signature{" "}
                  <StyledSpan>
                    (Please type name to accept)
                  </StyledSpan>
                </StyledPTitle>
                <BNPInput
                  name="eSignature"
                  className="mt-2"
                  value={bankingInfoEdit.eSignature}
                  onChange={handleChangeInputChequeInfo}
                  tabIndex={4}
                />
              </div>

              <div className="col-lg-6 col-6 mt-4">
                <StyledPTitle className="mb-2">Date</StyledPTitle>
                <StyledDivDatePicker>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      disableFuture
                      InputAdornmentProps={{position: "start"}}
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline1"
                      placeholder="Date"
                      name="date"
                      value={bankingInfoEdit.eSignatureDate ?
                        convertTimeZone("Canada/Eastern", bankingInfoEdit.eSignatureDate)
                        : ''
                      }
                      onChange={(date) =>
                        handleChangeESignatureDate(date, "eSignatureDate")
                      }
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      tabIndex={24}
                      role="application"
                      aria-hidden="true"
                    />
                  </MuiPickersUtilsProvider>
                </StyledDivDatePicker>
              </div>

              <div className="col-12 mt-3 text-center">
                <label>
                  <StyledImgCheque src={Cheque} alt="cheque"/>
                </label>
              </div>
            </div>
          </div>
        </div>
      </StyledDivBoxCheque>

    </div>
  )

  return (
    <BNPModal
      open={props.open}
      title={"Banking Information"}
      handleClose={props.handleClose}
      body={modalBody}
      footer={<div className="col-md-4 p-0">
        <StyledButtonSubmit
          disabled={!isValid}
          onClick={handleSubmitBankingInfo}
          submit={isValid}
          type="submit"
        >
          Save & Update
        </StyledButtonSubmit>
      </div>}
    />
  );
}

export default UpdateBankingInformationModal;

import React from "react";
import { Route } from "react-router-dom";
import {} from "react";
import PartnerLayout from "./partner-layout";

const PartnerRouteLayout = ({ component: Component, history, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <PartnerLayout>
          <Component {...matchProps} {...rest}></Component>
        </PartnerLayout>
      )}
    />
  );
};

export default PartnerRouteLayout;

import moment from "moment-timezone";

export const FE_DATE_FORMAT = "DD/MM/YYYY";
export const FE_DATETIME_FORMAT = "DD/MM/YYYY HH:mm:ss Z";
export const FE_DETAIL_DATE_FORMAT = "DD MMM YYYY";
export const FE_MONTH_FORMAT = "MM/YYYY";
export const FE_YEAR_FORMAT = "YYYY";
export const BE_DATE_FORMAT = "YYYY-MM-DD";

// Overloads
export function getStaticDateFromServerDate(date: null): null;
export function getStaticDateFromServerDate(date: Date | string): moment.Moment;
export function getStaticDateFromServerDate(date: Date | string | null): moment.Moment | null {
  if (!date) {
    return null;
  }
  if (typeof date === "string" && date.length === 10) {
    return moment(date);
  }
  return moment(date).tz("America/Toronto");
}

export const offsetToFrontEndTimezone = (date: Date | string | null): Date | null => {
  if (!date)
    return null;
  return moment(date).zone(new Date().getTimezoneOffset()).toDate()
}

const getLocalDateWithoutTimeZone = (date: Date | string | null): string | null => {
  if (!date)
    return null;
  return moment.parseZone(date).format(BE_DATE_FORMAT);
}

export const getLocalDateTime = (date: Date | string | null): Date | null => {
  if (!date)
    return null;
  return offsetToFrontEndTimezone(getLocalDateWithoutTimeZone(date));
}
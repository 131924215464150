import { getAsync, putAsync, getFileAsync, postAsync } from "../cores/utils/http-client";
import { AxiosResponse } from "axios";
import {
  SearchParamClaim,
  RejectValue,
} from "../components/pages/claim-management/claim-management-type";
import { ReceiptUpdate } from "../components/pages/claim-management/detail/claim-type";

export const getAllClaim = (
  param: SearchParamClaim
): Promise<AxiosResponse> => {
  let url = "/manage-claim/api/claims/search";
  return getAsync(url, param);
};

export const getNextClaim = (
    claimId: number
): Promise<AxiosResponse> => {
  let url = `/manage-claim/api/claims/next-claim-id/${claimId}`;

  return getAsync(url);
};

export const getClaimInfo = (id: number): Promise<AxiosResponse> => {
  let url = `/manage-claim/api/claims/${id}`;

  return getAsync(url);
};

export const putRejectClaim = (
  id: number,
  param: RejectValue
): Promise<AxiosResponse> => {
  let url = `/manage-claim/api/claims/${id}/reject`;

  return putAsync(url, param);
};

export const putReverseClaim = (
  id: number,
  param: RejectValue
): Promise<AxiosResponse> => {
  let url = `/manage-claim/api/claims/${id}/reverse`;

  return putAsync(url, param);
};

export const putApproveClaim = (id: number, model: any): Promise<AxiosResponse> => {
  let url = `/manage-claim/api/claims/${id}/approve`;

  return putAsync(url, model);
};

export const getBenefits = (): Promise<AxiosResponse> => {
  let url = `manage-employer/api/enrollments`;

  return getAsync(url);
};

export const getExportListSearchClaim = (
  param: SearchParamClaim
): Promise<AxiosResponse> => {
  let url = `manage-claim/api/claims/export`;

  return getFileAsync(url, param);
};

export const getClaimInfoById = (claimId: number, employeeId: number): Promise<AxiosResponse> => {
  let url = `manage-claim/api/employees/claim-info`;

  let param = { claimId: claimId, employeeId: employeeId };

  return getAsync(url, param);
};

export const getExpenseTypes = (
  enrollmentId: number,
  employeeId: number
): Promise<AxiosResponse> => {
  let url = `manage-claim/api/claims/expense-types/${enrollmentId}`;
  const param = {employeeId}

  return getAsync(url, param);
};

export const getAllReceiptById = (param: {
  claimId: number;
  employeeId: number
}): Promise<AxiosResponse> => {
  let url = `manage-claim/api/receipts`;

  return getAsync(url, param);
};

export const putUpdateReceipt = (
  employeeId:number,
  model: ReceiptUpdate
): Promise<AxiosResponse> => {
  let url = `manage-claim/api/receipts/${employeeId}`;

  return putAsync(url, model);
};

export const checkDuplicatedClaim = (
    claimId:number,
): Promise<AxiosResponse> => {
  let url = `manage-claim/api/claims/check-duplicated/${claimId}`;

  return getAsync(url);
};

export const calculateClaimSeparation = (
  claimId: number,
  param: any
): Promise<AxiosResponse> => {
  let url = `manage-claim/api/claims/${claimId}/calculate-claim-separation`;

  return postAsync(url, param);
};
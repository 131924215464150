import React, { useState, useEffect } from "react";
import { RouteChildrenProps } from "react-router";
import { DepositWithdrawRes, SearchCriteria, DepositWithdrawFilterType, DepositWithdraw } from "./deposit-withdraw-transaction-history-type";
import ManageWalletPartnerService from "./manage-wallet-partner-service";
import { exportExcel as readOuputStream } from "../../../cores/helpers/export-file/export-file";
import {
  StyledMainContent,
  StyledBigTitle,
  StyledContentBox,
  StyledHideForMobile,
  StyledHideForDesktop,
  StyledExpansionPanelSummary,
  StyledTitleExpansion,
  StyledExpansionPanelDetails,
  StyledSelect,
  StyledOption,
  StyledDefaultOption,
} from "../../shared/styled";
import {
  StyledTable,
  StyledHeaderTable,
  StyledCellTable,
  StyledBodyTable,
  StyledArrowDropDownIcon,
  StyledArrowDropUpIcon,
  StyledImportExportIcon,
} from "../../shared/table-styled";
import moment from "moment";
import { formatterUSD } from "../../../cores/helpers/format-usd";
import BNPDatePicker from "../../../cores/helpers/date-picker/date-picker";
import { BNPSelect } from "../../../cores/helpers/select/select";
import {
  StyledBNPInput,
  StyledDivExportExcel,
} from "../claim-management/claim-management-view";
import { StyledIconSearch } from "../company-management/detail-employee/detail-employee-view";
import { message } from "../../../cores/helpers/message/message";
import { ExpansionPanel, MenuItem } from "@material-ui/core";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import SystemUpdateAltOutlinedIcon from "@material-ui/icons/SystemUpdateAltOutlined";
import { SortTable } from "../../../cores/models/SortTable";
import { centToUsd } from "../../../cores/helpers/cent-to-usd";
import Pagination, { PagingInfo } from "../../../cores/helpers/pagination/pagination";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import { convertTimeZone } from "../../../cores/utils/helpers";
import {FE_DETAIL_DATE_FORMAT} from "../../../cores/utils/format/date-time-format";

interface Props extends RouteChildrenProps {
  match: any;
}

interface SearchResult {
  pageCount: number;
  records: DepositWithdrawRes[];
}

export default function DepositWithdrawTransactionHistory(props: Props) {

  const [data, setData] = useState<SearchResult>({
    pageCount: 0,
    records: []
  });

  const [columns, setColumns] = useState<SortTable[]>([
    {
      columnId: 'name',
      columnName: 'Name',
      width: 40,
      sortType: null
    },
    {
      columnId: 'type',
      columnName: 'Type',
      width: 20,
      sortType: null
    },
    {
      columnId: 'amount',
      columnName: 'Amount',
      width: 20,
      sortType: null
    },
    {
      columnId: 'date',
      columnName: 'Approved Date',
      width: 20,
      sortType: "DESC"
    }
  ]);

  const [paging, setPaging] = useState<PagingInfo>({
    currentPage: 1,
    endPage: 1,
    pages: [1],
    rowsPerPage: 10,
    startPage: 1,
    totalPages: 1
  });

  const [criteria, setCriteria] = useState<SearchCriteria>({
    columnNames: [ "date" ],
    sortTypes: [ "DESC" ],
    searchKey: '',
    from: null,
    to: null,
    type: DepositWithdrawFilterType.ALL,
    page: 1,
    perPage: 10
  });

  useEffect(() => {
    search();
  }, [criteria]);

  const search = async () => {
    let formatCriteria: any = { ...criteria };
    if (formatCriteria.from)
      formatCriteria.from = moment(formatCriteria.from).format("YYYY-MM-DD")
    if (formatCriteria.to)
      formatCriteria.to = moment(formatCriteria.to).format("YYYY-MM-DD")
    const res = await ManageWalletPartnerService.search(formatCriteria);

    if (res.status === 200) {
      setData(res.data);
      let pagingInfo = { ...paging };

      const pageCount = res.data.pageCount;
      pagingInfo.currentPage = criteria.page;
      pagingInfo.totalPages = pageCount;
      pagingInfo.startPage = criteria.page - 2 <= 1 ? 1 : criteria.page - 2;
      pagingInfo.endPage = criteria.page + 2 >= pageCount ? pageCount : criteria.page + 2;
      pagingInfo.rowsPerPage = criteria.perPage;
      let arr = [];
      for (let i = pagingInfo.startPage; i <= pagingInfo.endPage; i++) {
        arr.push(i);
      }
      pagingInfo.pages = arr;

      setPaging(pagingInfo);
    }
  };

  const exportExcel = async () => {
    let formatCriteria: any = { ...criteria };
    if (formatCriteria.from)
      formatCriteria.from = moment(formatCriteria.from).format("YYYY-MM-DD")
    if (formatCriteria.to)
      formatCriteria.to = moment(formatCriteria.to).format("YYYY-MM-DD")
    const res = await ManageWalletPartnerService.exportExcel(formatCriteria);
    if (res.status === 200) readOuputStream(res.data);
  };

  const handleChangeDate = (date: Date | null, name: string) => {
    let searchCriteria: SearchCriteria = { ...criteria };

    if (name === "from") {
      if (searchCriteria.to && date) {
        let currentFrom = new Date(date),
          from = new Date(currentFrom),
          to = new Date(searchCriteria.to);
        if (from > to) {
          message("The from date cannot be after the to date.", "error");
          return;
        }
      }
      searchCriteria.from = date;
    } else {
      if (searchCriteria.from && date) {
        let from = new Date(searchCriteria.from),
          to = new Date(date);
        if (from > to) {
          message("The from date cannot be after the to date.", "error");
          return;
        }
      }
      searchCriteria.to = date;
    }
    searchCriteria.page = 1;

    setCriteria(searchCriteria);
  };

  const handleChangSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    let searchCriteria: SearchCriteria = { ...criteria };

    switch (value) {
      case "DEPOSIT":
        searchCriteria.type = DepositWithdrawFilterType.DEPOSIT;
        break;
      case "WITHDRAW":
        searchCriteria.type = DepositWithdrawFilterType.WITHDRAW;
        break;
      default:
        searchCriteria.type = DepositWithdrawFilterType.ALL;
    }
    searchCriteria.page = 1;

    setCriteria(searchCriteria);
  };

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    let searchCriteria: SearchCriteria = { ...criteria };

    searchCriteria.searchKey = event.target.value;
    searchCriteria.page = 1;

    setCriteria(searchCriteria);
  };

  const onKeyUp = (event: any) => {
    if (event.keyCode === 13) {
      let searchCriteria: SearchCriteria = { ...criteria };
      searchCriteria.page = 1;

      setCriteria(searchCriteria);
    }
  };

  const sortTable = (columnId: string) => {
    let columnsHeaderTable: SortTable[] = [...columns];
    let searchCriteria: SearchCriteria = { ...criteria };
    searchCriteria.columnNames = [columnId];
    let index = columnsHeaderTable.findIndex((column) => {
      return column.columnId === columnId;
    });

    columnsHeaderTable.forEach((column, i) => {
      if (i === index) {
        column.sortType = column.sortType === "ASC" ? "DESC" : "ASC";
        searchCriteria.sortTypes = [column.sortType];
        return;
      }
      column.sortType = null;
    });

    setColumns(columnsHeaderTable);
    setCriteria(searchCriteria);
  };

  const handleChangePage = (page: number) => {
    let searchCriteria: SearchCriteria = { ...criteria };
    searchCriteria.page = page;

    setCriteria(searchCriteria);
  };

  const setRowsPerPage = (event: any) => {
    const { value } = event.target;

    let searchCriteria: SearchCriteria = { ...criteria };
    searchCriteria.page = 1;
    searchCriteria.perPage = Number(value);

    // set url search
    setCriteria(searchCriteria);
  };

  return (
    <StyledMainContent>
      <StyledBigTitle>Deposit/Withdraw History</StyledBigTitle>
      <div className="container-fluid p-0 mt-3">
        <StyledContentBox>
          <div className="row">
            <div className="col-lg-2 mt-4">
              <BNPDatePicker
                onChange={handleChangeDate}
                value={criteria.from ?
                  convertTimeZone("Canada/Eastern", criteria.from).format(FE_DETAIL_DATE_FORMAT)
                  :
                  ""
                }
                placeholder="From date"
                name="from" />
            </div>
            <div className="col-lg-2 mt-4">
              <BNPDatePicker
                onChange={handleChangeDate}
                value={criteria.to ?
                  convertTimeZone("Canada/Eastern", criteria.to).format(FE_DETAIL_DATE_FORMAT)
                  :
                  ""
                }
                placeholder="To date"
                name="to" />
            </div>
            <div className="col-lg-2 mt-4">
              <BNPSelect
                options={DepositWithdraw}
                value={criteria.type}
                name="type"
                placeholder="Filter by type"
                ignore={true}
                onChange={handleChangSelect} />
            </div>
            <div className="col-lg-2 mt-4">
              <StyledBNPInput
                placeholder="Search name"
                onChange={handleChangeInput}
                value={criteria.searchKey}
                onKeyUp={onKeyUp} />
              <StyledIconSearch onClick={search} />
            </div>
            <div className="col-lg-2"></div>
            <div className="col-lg-2 mt-4">
              <StyledSelect value={-1}>
                <MenuItem value={-1}>
                  <StyledDefaultOption>
                    <PrintOutlinedIcon className="icon" /> Export data
                  </StyledDefaultOption>
                </MenuItem>
                <MenuItem value="1">
                  <StyledOption onClick={exportExcel}>
                    <StyledDivExportExcel>
                      <SystemUpdateAltOutlinedIcon className="icon" /> Export via Excel
                    </StyledDivExportExcel>
                  </StyledOption>
                </MenuItem>
              </StyledSelect>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 mt-4">
              <StyledHideForMobile>
                <StyledTable>
                  <StyledHeaderTable>
                    {columns.map((column, index) => (
                      <StyledCellTable
                        width={column.width}
                        key={index}
                        className="cur"
                        onClick={() => sortTable(column.columnId)} >
                        {column.columnName}
                        <span>
                          {
                            column.sortType ?
                              (column.sortType === "ASC" ? <StyledArrowDropDownIcon /> : <StyledArrowDropUpIcon />) :
                              <StyledImportExportIcon />
                          }
                        </span>
                      </StyledCellTable>
                    ))}
                  </StyledHeaderTable>
                  <div>
                    {data.records.map((history, i) => (
                      <StyledBodyTable key={i}>
                        <StyledCellTable width={40}>
                          {history.name}
                        </StyledCellTable>
                        <StyledCellTable width={20}>
                          {history.type}
                        </StyledCellTable>
                        <StyledCellTable width={20}>
                          {formatterUSD("currency").format(
                            centToUsd(history.amount)
                          )}
                        </StyledCellTable>
                        <StyledCellTable width={20}>
                          {convertTimeZone("Canada/Eastern", history.date).format("YYYY, MMM DD")}
                        </StyledCellTable>
                      </StyledBodyTable>
                    ))}
                  </div>
                  <div className="row mt-4">
                    <div className="col-12 text-center">
                      <label>
                        <Pagination
                          pagerPagination={paging}
                          getPage={handleChangePage}
                          onChangeRowsPerPage={setRowsPerPage} />
                      </label>
                    </div>
                  </div>
                </StyledTable>
              </StyledHideForMobile>

              <StyledHideForDesktop>
                {data.records.map((history, index) => (
                  <ExpansionPanel
                    square
                    defaultExpanded={false}
                    className="mt-2"
                    key={index} >
                    <StyledExpansionPanelSummary
                      className="m-0"
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                      expandIcon={<ArrowDropUp />} >
                      <div className="w-100">
                        <StyledTitleExpansion>Name</StyledTitleExpansion>
                        <div className="mt-2">{history.name}</div>
                      </div>
                    </StyledExpansionPanelSummary>
                    <StyledExpansionPanelDetails>
                      <div className="w-100 mt-2">
                        <StyledTitleExpansion>Type</StyledTitleExpansion>
                        <div className="mt-1">{history.type}</div>
                      </div>
                      <div className="w-100 mt-2">
                        <StyledTitleExpansion>
                          Amount
                        </StyledTitleExpansion>
                        <div className="mt-1">
                          {formatterUSD("currency").format(
                            centToUsd(history.amount)
                          )}
                        </div>
                      </div>
                      <div className="w-100 mt-2">
                        <div className="mt-1">{convertTimeZone("Canada/Eastern", history.date).format(FE_DETAIL_DATE_FORMAT)}</div>
                      </div>
                    </StyledExpansionPanelDetails>
                  </ExpansionPanel>
                ))}
                <div className="row mt-4">
                  <div className="col-12 text-center">
                    <label>
                      <Pagination
                        pagerPagination={paging}
                        getPage={handleChangePage}
                        onChangeRowsPerPage={setRowsPerPage} />
                    </label>
                  </div>
                </div>
              </StyledHideForDesktop>
            </div>
          </div>
        </StyledContentBox>
      </div>
    </StyledMainContent>
  );
}
import {AxiosResponse} from "axios";
import {getAsync, getFileAsync} from "../cores/utils/http-client";

const URL_Employer = "/manage-employer/api";
const URL_Wallet = "/manage-wallet-partner/partner/report"
const URL_Cooperation_Wallet = "/manage-wallet-partner/partners/";
const URL_Claim = "/manage-claim/api";

export const getWalletSummaryReport = (date: string): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/report/wallet?date=${date}`;

  return getAsync(url);
};

export const exportWalletSummaryReport = (date: string): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/report/wallet/export?date=${date}`;

  return getFileAsync(url);
};


export const getWalletComparisonReport = (earlierDate: string, laterDate: string): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/report/wallet/comparison?earlierDate=${earlierDate}&laterDate=${laterDate}`;

  return getAsync(url);
};


export const exportWalletComparisonReport = (earlierDate: string, laterDate: string): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/report/wallet/comparison/export?earlierDate=${earlierDate}&laterDate=${laterDate}`;

  return getFileAsync(url);
};

export const getWalletDetailReport = (fromDate: string, toDate: string): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/report/wallet/detail?fromDate=${fromDate}&toDate=${toDate}`;

  return getAsync(url);
};

export const exportWalletDetailReport = (fromDate: string, toDate: string): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/report/wallet/detail/export?fromDate=${fromDate}&toDate=${toDate}`;

  return getFileAsync(url);
};

export const getCooperationPartnerWalletSummaryReport = (partnerId: number, date: string): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/report/partners/${partnerId}/wallet/summary?date=${date}`;

  return getAsync(url);
};

export const getCooperationPartnerWalletComparisonReport = (partnerId: number, earlierDate: string, laterDate: string): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/report/partners/${partnerId}/wallet/comparison?earlierDate=${earlierDate}&laterDate=${laterDate}`;

  return getAsync(url);
};

export const getCooperationPartnerWalletDetailReport = (partnerId: number, fromDate: string, toDate: string): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/report/partners/${partnerId}/wallet/detail?fromDate=${fromDate}&toDate=${toDate}`;

  return getAsync(url);
};

export const getFinancialSummaryReport = (date: string): Promise<AxiosResponse> => {
  let url = `${URL_Wallet}/financial/summary?date=${date}`;

  return getAsync(url);
};

export const exportFinancialSummaryReport = (date: string): Promise<AxiosResponse> => {
  let url = `${URL_Wallet}/financial/summary/export?date=${date}`;

  return getFileAsync(url);
};

export const getFinancialComparisonReport = (earlierDate: string, laterDate: string): Promise<AxiosResponse> => {
  let url = `${URL_Wallet}/financial/comparison?earlierDate=${earlierDate}&laterDate=${laterDate}`;

  return getAsync(url);
};

export const exportFinancialComparisonReport = (earlierDate: string, laterDate: string): Promise<AxiosResponse> => {
  let url = `${URL_Wallet}/financial/comparison/export?earlierDate=${earlierDate}&laterDate=${laterDate}`;

  return getFileAsync(url);
};

export const getFinancialDetailReport = (fromDate: string, toDate: string): Promise<AxiosResponse> => {
  let url = `${URL_Wallet}/financial/detail?fromDate=${fromDate}&toDate=${toDate}`;

  return getAsync(url);
};

export const exportFinancialDetailReport = (fromDate: string, toDate: string): Promise<AxiosResponse> => {
  let url = `${URL_Wallet}/financial/detail/export?fromDate=${fromDate}&toDate=${toDate}`;

  return getFileAsync(url);
};

export const getCooperationPartnerFinancialSummaryReport = (partnerId: number, date: string): Promise<AxiosResponse> => {
  let url = `${URL_Cooperation_Wallet}/${partnerId}/report/financial/summary?date=${date}`;

  return getAsync(url);
};

export const getCooperationPartnerFinancialComparisonReport = (partnerId: number, earlierDate: string, laterDate: string): Promise<AxiosResponse> => {
  let url = `${URL_Cooperation_Wallet}/${partnerId}/report/financial/comparison?earlierDate=${earlierDate}&laterDate=${laterDate}`;

  return getAsync(url);
};

export const getCooperationPartnerFinancialDetailReport = (partnerId: number, fromDate: string, toDate: string): Promise<AxiosResponse> => {
  let url = `${URL_Cooperation_Wallet}/${partnerId}/report/financial/detail?fromDate=${fromDate}&toDate=${toDate}`;

  return getAsync(url);
};

export const getCommissionSummaryReport = (date: string): Promise<AxiosResponse> => {
  let url = `${URL_Wallet}/commission/summary?date=${date}`;

  return getAsync(url);
};

export const getCommissionComparisonReport = (earlierDate: string, laterDate: string): Promise<AxiosResponse> => {
  let url = `${URL_Wallet}/commission/comparison?earlierDate=${earlierDate}&laterDate=${laterDate}`;

  return getAsync(url);
};

export const getCommissionDetailReport = (fromDate: string, toDate: string): Promise<AxiosResponse> => {
  let url = `${URL_Wallet}/commission/detail?fromDate=${fromDate}&toDate=${toDate}`;

  return getAsync(url);
};

export const getAllCommissionEnrollmentOptions = (): Promise<AxiosResponse> => {
  let url = `${URL_Claim}/enrollments/commissions/enrollment-options`;

  return getAsync(url);
};
import React, { useEffect, useState } from "react";
import {
  StyledMainContent,
  StyledBigTitle,
  StyledContentBox,
  StyledCell,
  StyledSpanLargerTitle,
  StyledHideForMobile,
  StyledHideForDesktop,
  StyledExpansionPanelSummary,
  StyledTitleExpansion,
  StyledExpansionPanelDetails,
  StyledSpanExpansionEdit,
  StyledExpansionLabel,
  StyledBreadcrumbs,
  StyledContent,
  StyledButtonSubmit,
  StyledErrorMessage,
} from "../../../shared/styled";
import { ExpansionPanel } from "@material-ui/core";
import styled, { css } from "styled-components";
import {
  ClaimData,
  StatusClaim,
  SearchParamClaim,
} from "../../claim-management/claim-management-type";
import theme from "../../../../cores/helpers/theme";
import { Link } from "react-router-dom";
import BNPDatePicker from "../../../../cores/helpers/date-picker/date-picker";
import {
  StyledTable,
  StyledHeaderTable,
  StyledCellTable,
  StyledArrowDropDownIcon,
  StyledArrowDropUpIcon,
  StyledImportExportIcon,
  StyledLinkBodyTable,
  StyledBodyTable
} from "../../../shared/table-styled";
import { formatterUSD } from "../../../../cores/helpers/format-usd";
import moment from "moment";
import Pagination, {
  PagingInfo,
} from "../../../../cores/helpers/pagination/pagination";
import { BNPSelect } from "../../../../cores/helpers/select/select";
import SearchIcon from "@material-ui/icons/Search";
import { SortTable } from "../../../../cores/models/SortTable";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import {
  EmployeeInfo,
  Balance,
  Transactions,
  WalletSearchParams,
  PendingAmountByCategory,
  PendingAmount
} from "../company-management-type";
import {Class, employeeStatus, Status} from "../detail/employees/employee-model";
import { capFirst } from "../../../../cores/helpers/cap-first";
import { formatPhoneNumber } from "../../../../cores/helpers/format-phone-number";
import { relationships } from "../../claim-management/claim-management-model";
import ModalImage from "react-modal-image";
import { centToUsd } from "../../../../cores/helpers/cent-to-usd";
import { getLimitBalance } from "./detail-employee";
import {claimManagementDetailURL,
  companyManagementDetailURL,
  companySearchParamDetailDefault} from "../../../../cores/constants/url-config";
import { showCreditModal } from "../detail/credit/employer-credit-view";
import {convertTimeZone, findEmployeeStatusNameById, isAdminUser} from "../../../../cores/utils/helpers";
import { statusTransaction } from "../company-management-model";
import { showEmployeeModal } from "../employee-modal/employee-modal"
import CreateIcon from "@material-ui/icons/Create";
import { showDependentModal } from "../dependent-modal/dependents-modal";
import { PdfViewer } from "../../../shared/pdf-viewer";
import {
  FE_DATE_FORMAT,
  FE_DATETIME_FORMAT,
  FE_DETAIL_DATE_FORMAT
} from "../../../../cores/utils/format/date-time-format";
import {WalletBenefit} from "../../../shared/wallet-benefit/WalletBenefit";
import {PartnerEmployeeDetailOptions} from "../../partner-management/partner-management-type";
import {TWalletBenefit} from "../../../shared/wallet-benefit/WalletBenefitModel";
import {WalletBenefitNew} from "../../../shared/wallet-benefit/WalletBenefit-new";

const StyledSpanEditInfo = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  float: right;
  cursor: pointer;
  font-family: ${(props) => props.theme.textFont};
  :hover {
    opacity: 0.7;
  }
  position: relative;
  top: 6px;
`;

const StyledTitleBalance = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 32px;
`;
const StyledBalance = styled.div`
  font-size: 26px;
  font-weight: bold;
  line-height: 32px;
  ${(props: { color: string }) =>
    props.color &&
    css`
      color: ${props.color};
    `}
`;
const StyledLine = styled.div`
  padding: 5px;
  display: flex;
`;

export const StyledIconSearch = styled(SearchIcon)`
  position: absolute;
  top: 13px;
  right: 14px;
  opacity: 0.7;
`;
export const StyledDivBoxTable = styled.div`
  min-height: 300px;
`;

const StyledChangeDetail = styled(Link)`
  width: 100%;
  color: ${(props) => props.theme.primaryColor};

  :hover {
    background-color: #e8edf2;
    text-decoration: none;
    color: ${(props) => props.theme.primaryColor};
  }
`;

const StyledModalImageContainer = styled.div`
  .modal-image {
    max-height: 150px !important;
    max-width: 200px !important;
  }
`;
const StyledOptionRequest = styled(StyledButtonSubmit)`
  background-color: transparent;
  border: 1px solid ${(props) => props.theme.primaryColor};
  color: ${(props) => props.theme.primaryColor};
`;

const StyledAddDependent = styled(StyledButtonSubmit)`
  background-color: transparent;
  border: 1px solid ${(props) => props.theme.secondaryColor};
  color: ${(props) => props.theme.secondaryColor};
`;

type Props = {
  searchParam: SearchParamClaim;
  pagingInfo: PagingInfo;
  statusClaim: StatusClaim[];
  claimsData: ClaimData[];
  columns: SortTable[];

  handleChangeDate: (date: Date | null, name: string) => void;
  handleChangSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyUp: (event: any) => void;
  searchNameOrEmail: () => void;
  findStatusNameById: (statusId: string) => string;
  handleChangePage: (page: number) => void;
  sortTable: (columnId: string) => void;

  // employee info
  employee: EmployeeInfo;
  classes: Class[];
  statuses: Status[];
  roles: Status[];
  signUpStatuses: Status[];
  onboardingDate: Date | string;

  findClassById: (classId: number) => string;
  findSignUpStatusById: (signUpStatusId: string) => string;
  findStatusesById: (statusId: string) => string;
  findRoleById: (roleId: string) => string;
  findEnrollmentNameById: (id: number) => string;
  setRowsPerPage: (event: any) => void;

  pagingInfoWallet: PagingInfo;
  changePageWallet: (page: number) => void;
  setRowsPerPageWallet: (event: any) => void;

  transactions: Transactions[];
  searchParamWallet: WalletSearchParams;
  changeFilterWallet: (event: any) => void;
  isPdf: boolean;
  modelFactory: () => void;
  handleConfirmBanThisEmployee: () => void;
  partnerOptions: PartnerEmployeeDetailOptions;

  walletBenefit: TWalletBenefit;
};

export default function CompanyManagementEmployeeDetailView(props: Props) {
  const widthTitle = "40%";
  const widthParam = "60%";

  const findDependentNameById = (id: string): string => {
    let dependent = relationships.find((relationship) => {
      return relationship.id === id;
    });

    return dependent ? dependent.name : "";
  }

  const employeeInformation = [
    {
      title: "Employee ID",
      param: props.employee.code || "---"
    },
    {
      title: "First name",
      param: props.employee.firstName || "---"
    },
    {
      title: "Last name",
      param: props.employee.lastName || "---"
    },
    {
      title: "Status",
      param: findEmployeeStatusNameById(props.employee.status, props.employee.t4Employee) || "---"
    },
    {
      title: "Gender at Birth",
      param: capFirst(props.employee.gender) || "---"
    },
    {
      title: "Day of birth",
      param: props.employee.dateOfBirth
        ? convertTimeZone("Canada/Eastern", props.employee.dateOfBirth).format(FE_DETAIL_DATE_FORMAT)
        : "---"
    },
    {
      title: "Email",
      param: props.employee.email || "---"
    },
    {
      title: "Phone number",
      param: formatPhoneNumber(props.employee.phoneNumber) || "---"
    },
    {
      title: "Home address",
      param: (
        (props.employee.homeAddress ? props.employee.homeAddress : '' ) +
        (props.employee.cityName ? `, ${props.employee.cityName}` : '') +
        (props.employee.provinceName ? `, ${props.employee.provinceName}` : '') +
        (props.employee.postCode ? `, ${props.employee.postCode}` : '')
      )
    },
    {
      title: "Plan",
      param: props.employee.employerPlanName || "---"
    },
    {
      title: "Class",
      param: props.employee.employerClassBenefitName || "---"
    },
    {
      title: "Position",
      param: props.employee.position || "---"
    },
    {
      title: "Salary",
      param: formatterUSD("currency", "USD").format(
        props.employee.salary / 100
      ) || "---"
    },
    {
      title: "Social insurance number",
      param: props.employee.socialInsuranceNumber || "---"
    },
    {
      title: "Eligibility date",
      param: props.employee.eligibilityDate
        ? convertTimeZone("Canada/Eastern", props.employee.eligibilityDate).format(FE_DETAIL_DATE_FORMAT)
        : "---"
    },
    {
      title: "Hiring date",
      param: props.employee.hiringDate
        ? convertTimeZone("Canada/Eastern", props.employee.hiringDate).format(FE_DETAIL_DATE_FORMAT)
        : "---"
    },
    {
      title: "Role",
      param: props.employee.role
        ? capFirst(props.employee.role[0])
        : "---"
    },
    {
      title: "Benefit cancellation date",
      param: props.employee.benefitCancellationDate
        ? convertTimeZone("Canada/Eastern", props.employee.benefitCancellationDate).format(FE_DETAIL_DATE_FORMAT)
        : "---"
    },
    {
      title: "External base ID",
      param: props.employee.externalBaseId || "---"
    },
    {
      title: "Onboarding date",
      param: props.onboardingDate
        ? convertTimeZone("Canada/Eastern", props.onboardingDate).format(FE_DETAIL_DATE_FORMAT)
        : "---"
    }
  ]

  return (
    <StyledMainContent>
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-lg-3 mt-3">
            {props.employee.id && <>
              <StyledBigTitle>
                {props.employee.firstName + " " + props.employee.lastName || ""}
              </StyledBigTitle>
              <StyledBreadcrumbs aria-label="breadcrumb" className="mt-3">
                {props.employee.employerId &&
                <Link to={companyManagementDetailURL + companySearchParamDetailDefault(props.employee.employerId)}>
                  {props.employee.companyName || ""}
                </Link>
                }
              </StyledBreadcrumbs>
            </>}
          </div>
          <div className="col-lg-2 mt-3">
            <StyledTitleBalance>Wallet Balance</StyledTitleBalance>
            <StyledBalance color={theme.primaryColor}>
              {formatterUSD("currency", "USD").format(
                centToUsd(props.walletBenefit.cashBalance)
              )}
            </StyledBalance>
          </div>
          <div className="col-lg-2 mt-3">
            <StyledTitleBalance>Pending Payment</StyledTitleBalance>
            <StyledBalance color={theme.secondaryColor}>
              {formatterUSD("currency", "USD").format(
                centToUsd(props.walletBenefit.pendingPayment)
              )}
            </StyledBalance>
          </div>
          <div className="col-lg-2 mt-3">
            <StyledTitleBalance>Requested Claims</StyledTitleBalance>
            <StyledBalance color={theme.secondaryColor}>
              {formatterUSD("currency", "USD").format(
                centToUsd(props.walletBenefit.walletInfoDetails.map(wallet => wallet.pendingApproveClaimAmount).reduce((total, amount) => total + amount, 0))
              )}
            </StyledBalance>
          </div>
          <div className="col-lg-1 mt-3"></div>

          <div className="col-lg-2 mt-3">
            {isAdminUser() && (
            <StyledOptionRequest submit={true} onClick={showCreditModal}>
              Adjustment
            </StyledOptionRequest>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-lg-6 mt-4">
                <StyledContentBox>
                  <StyledSpanLargerTitle>
                    Profile Information
                    {props.partnerOptions.allowEditEmployee && (
                    <StyledSpanEditInfo onClick={() => {
                      props.modelFactory()
                      showEmployeeModal()
                    }}>
                      <CreateIcon fontSize="small" />Edit
                    </StyledSpanEditInfo>
                    )}
                  </StyledSpanLargerTitle>
                  <div className="mt-3">
                    {
                      employeeInformation.map((info, index) => (
                        <StyledLine key={index} className={index % 2 === 0 ? "striped" : ""}>
                          <StyledCell width={widthTitle} className="fw-bold">
                            {info.title}
                          </StyledCell>
                          <StyledCell width={widthParam}>
                            {info.param}
                          </StyledCell>
                        </StyledLine>
                      ))
                    }
                  </div>
                </StyledContentBox>
              </div>
              <div className="col-lg-6 mt-4">
                <div className="row">
                  <div className="col-12">
                    <StyledContentBox>
                      <StyledSpanLargerTitle>
                        Benefits Coverage
                      </StyledSpanLargerTitle>
                      {props.employee.reAllocationStatus === 'NONE' && <>
                          <div className="mt-2">
                            <StyledSpanLargerTitle>
                              Available amount{": "}
                              {formatterUSD("currency", "USD").format(
                                centToUsd(props.walletBenefit.availableAmount)
                              )}
                            </StyledSpanLargerTitle>
                          </div>
                          <div className="mt-2">
                            Pending amount:{" "}
                            {formatterUSD("currency", "USD").format(
                              centToUsd(props.walletBenefit.pendingPayment)
                            )}
                          </div>
                          <div className="mt-2">
                            Wallet utilization:{" "}
                            {props.walletBenefit.utilizationRate.toFixed(2)} %
                          </div>
                        {props.walletBenefit.walletInfoDetails.map((wallet, index) => (
                          <WalletBenefitNew
                            key={`wallet-benefits-${index}`}
                            proRated={props.employee.prorated}
                            isAvailable={props.employee.available}
                            walletBenefit={wallet}
                          />
                        ))}
                      </>}
                      {!!props.employee.reAllocationStatus && props.employee.reAllocationStatus !== 'NONE' ?
                        <StyledErrorMessage>
                          User is in allocation period. Benefit coverage will display after user finish their allocation
                        </StyledErrorMessage> : ""
                      }
                    </StyledContentBox>
                  </div>
                  {props.partnerOptions.allowViewBankingInformation && (

                    <div className="col-12 mt-3">
                      <StyledContentBox>
                        <StyledSpanLargerTitle>
                        Banking Information
                      </StyledSpanLargerTitle>

                      <div className="mt-3">
                        <StyledContent>Account Number</StyledContent>
                        <StyledContent className="fw-400 float-right">
                          {props.employee.bankingInformation
                            ? props.employee.bankingInformation.accountNumber
                            : ""}
                        </StyledContent>
                        <hr />
                      </div>
                      <div className="mt-3">
                        <StyledContent>Transit Number</StyledContent>
                        <StyledContent className="fw-400 float-right">
                          {props.employee.bankingInformation
                            ? props.employee.bankingInformation.transitNumber
                            : ""}
                        </StyledContent>
                        <hr />
                      </div>
                      <div className="mt-3">
                        <StyledContent>Institution Number</StyledContent>
                        <StyledContent className="fw-400 float-right">
                          {props.employee.bankingInformation
                            ? props.employee.bankingInformation.bankNumber
                            : ""}
                        </StyledContent>
                        <hr />
                      </div>
                      <div className="mt-3 row">
                        <div className="col-5">
                        <StyledContent>Cheque</StyledContent>
                        </div>
                        <div className="col-7">
                          <StyledContent className="fw-400">
                            {props.employee.bankingInformation &&
                              props.employee.bankingInformation.voidCheque && (
                                props.isPdf ?
                                  <PdfViewer fileUrl={props.employee.bankingInformation.voidCheque} height="150px" />
                                  :
                                  <StyledModalImageContainer>
                                    <ModalImage
                                      className="modal-image"
                                      small={
                                        props.employee.bankingInformation.voidCheque
                                      }
                                      large={
                                        props.employee.bankingInformation.voidCheque
                                      }
                                    />
                                  </StyledModalImageContainer>
                              )}
                          </StyledContent>
                        </div>
                      </div>
                    </StyledContentBox>
                  </div>
                  )}

                  <div className="col-12 mt-3">
                    <StyledContentBox>
                      <StyledSpanLargerTitle>Dependents</StyledSpanLargerTitle>

                      {props.employee.dependents
                        ? props.employee.dependents.map((dependent, index) => (
                          <div className="mt-3 row" key={index}>
                            <StyledContent className="col-6">
                              {dependent.firstName + " " + dependent.lastName}
                            </StyledContent>
                            <StyledContent className="col-3">
                              {dependent.dateOfBirth
                              ? `${moment().diff(dependent.dateOfBirth, 'years')} YO`
                              : ""}
                        </StyledContent>
                            <StyledContent  className="col-3 text-right">
                              {findDependentNameById(dependent.relationship)}
                            </StyledContent>
                            <hr />
                          </div>
                        ))
                        : ""}
                        <div className="row">
                          <div className="col-8"></div>
                          <div className="col-4">
                            {props.partnerOptions.allowEditDependents && (
                            <StyledAddDependent submit={true}
                            onClick={showDependentModal}>
                            Manage Dependent
                            </StyledAddDependent>
                            )}
                          </div>
                        </div>
                    </StyledContentBox>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {props.partnerOptions.allowAll &&
            <div className="col-lg-12 mt-3">
              <StyledContentBox>
                <StyledSpanLargerTitle>Claims</StyledSpanLargerTitle>
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <div className="row">
                      <div className="col-lg-2 mt-4">
                        <BNPDatePicker
                          onChange={props.handleChangeDate}
                          value={props.searchParam.from ?
                            convertTimeZone("Canada/Eastern", props.searchParam.from).format(FE_DETAIL_DATE_FORMAT)
                            :
                            ""
                          }
                          placeholder="From date"
                          name="from"
                        />
                      </div>
                      <div className="col-lg-2 mt-4">
                        <BNPDatePicker
                          value={props.searchParam.to ?
                            convertTimeZone("Canada/Eastern", props.searchParam.to).format(FE_DETAIL_DATE_FORMAT)
                            :
                            ""
                          }
                          onChange={props.handleChangeDate}
                          placeholder="To date"
                          name="to"
                        />
                      </div>
                      <div className="col-lg-2 mt-4">
                        <BNPSelect
                          options={props.statusClaim}
                          value={props.searchParam.status}
                          name="claimStatus"
                          placeholder="Filter by status"
                          onChange={props.handleChangSelect}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 mt-4">
                    <StyledHideForMobile>
                      <StyledTable>
                        <StyledHeaderTable>
                          {props.columns.map((column, index) => (
                            <StyledCellTable
                              width={column.width}
                              key={index}
                              className="cur"
                              onClick={() => props.sortTable(column.columnId)}
                            >
                              {column.columnName}
                              <span>
                                {column.sortType ? (
                                  column.sortType === "ASC" ? (
                                    <StyledArrowDropDownIcon />
                                  ) : (
                                    <StyledArrowDropUpIcon />
                                  )
                                ) : (
                                  <StyledImportExportIcon />
                                )}
                              </span>
                            </StyledCellTable>
                          ))}
                        </StyledHeaderTable>
                        <div>
                          {props.claimsData.map((claimData, i) => (
                            <StyledLinkBodyTable
                              to={`${claimManagementDetailURL}?id=${claimData.id}`}
                              key={i}
                            >
                              <StyledCellTable width={5}>
                                {claimData.id}
                              </StyledCellTable>
                              <StyledCellTable width={12}>
                                {claimData.employeeName}
                              </StyledCellTable>
                              <StyledCellTable width={12}>
                                {claimData.companyName}
                              </StyledCellTable>
                              <StyledCellTable width={10}>
                                {claimData.totalAmount
                                  ? formatterUSD("currency", "USD").format(
                                    claimData.totalAmount / 100
                                  )
                                  : 0}
                              </StyledCellTable>
                              <StyledCellTable
                                width={10}
                                title={claimData.submittedDate
                                  ? moment(claimData.submittedDate).format(FE_DATETIME_FORMAT)
                                  : ""}
                              >
                                {claimData.submittedDate
                                  ? moment(claimData.submittedDate).format(FE_DATE_FORMAT)
                                  : ""}
                              </StyledCellTable>
                              <StyledCellTable
                                width={10}
                                title={claimData.reviewDate
                                  ? moment(claimData.reviewDate).format(FE_DATETIME_FORMAT)
                                  : ""}
                              >
                                {claimData.reviewDate
                                  ? moment(claimData.reviewDate).format(FE_DATE_FORMAT)
                                  : ""}
                              </StyledCellTable>
                              <StyledCellTable width={25}>
                                {claimData.claimType || ""}
                              </StyledCellTable>
                              <StyledCellTable width={12}>
                                {claimData.partnerName || ""}
                              </StyledCellTable>
                              <StyledCellTable width={8}>
                                {props.findStatusNameById(claimData.claimStatus)}
                              </StyledCellTable>
                            </StyledLinkBodyTable>
                          ))}
                        </div>
                        <div className="row mt-4">
                          <div className="col-12 text-center">
                            <label>
                              <Pagination
                                pagerPagination={props.pagingInfo}
                                getPage={props.handleChangePage}
                                onChangeRowsPerPage={props.setRowsPerPage}
                              />
                            </label>
                          </div>
                        </div>
                      </StyledTable>
                    </StyledHideForMobile>
                  </div>

                  <div className="col-lg-12 mt-4">
                    <StyledHideForDesktop>
                      {props.claimsData.map((claimData, index) => (
                        <ExpansionPanel
                          square
                          defaultExpanded={false}
                          className="mt-2"
                          key={index}
                        >
                          <StyledExpansionPanelSummary
                            className="m-0"
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                            expandIcon={<ArrowDropUp />}
                          >
                            <div className="w-100 ">
                              <StyledTitleExpansion>Claimer</StyledTitleExpansion>
                              <div className="mt-2">{claimData.employeeName}</div>
                            </div>
                          </StyledExpansionPanelSummary>
                          <StyledExpansionPanelDetails>
                            <div className="w-100">
                              <StyledTitleExpansion>
                                Id
                              </StyledTitleExpansion>
                              <div className="mt-1">{claimData.id}</div>
                            </div>
                            <div className="w-100">
                              <StyledTitleExpansion>
                                Company name
                              </StyledTitleExpansion>
                              <div className="mt-1">{claimData.companyName}</div>
                            </div>
                            <div className="w-100 mt-3">
                              <StyledTitleExpansion>
                                Total amount
                              </StyledTitleExpansion>
                              <div className="mt-1">
                                {claimData.totalAmount
                                  ? formatterUSD("currency", "USD").format(
                                    claimData.totalAmount / 100
                                  )
                                  : 0}
                              </div>
                            </div>
                            <div className="w-100 mt-3">
                              <StyledTitleExpansion>
                                Submitted Date
                              </StyledTitleExpansion>
                              <div className="mt-1">
                                {claimData.submittedDate
                                  ? moment(claimData.submittedDate).format(FE_DATE_FORMAT)
                                  : ""}
                              </div>
                            </div>
                            <div className="w-100 mt-3">
                              <StyledTitleExpansion>
                                Review Date
                              </StyledTitleExpansion>
                              <div className="mt-1">
                                {claimData.reviewDate
                                  ? moment(claimData.reviewDate).format(FE_DATE_FORMAT)
                                  : ""}
                              </div>
                            </div>
                            <div className="w-100 mt-3">
                              <StyledTitleExpansion>
                                Partner
                              </StyledTitleExpansion>
                              <div className="mt-1">
                                {claimData.partnerName}
                              </div>
                            </div>
                            <div className="w-100 mt-3">
                              <StyledTitleExpansion>Status</StyledTitleExpansion>
                              <div className="mt-1">
                                {props.findStatusNameById(claimData.claimStatus)}
                              </div>
                            </div>
                            <hr />
                            <div className="text-center">
                              <StyledSpanExpansionEdit>
                                <StyledExpansionLabel>
                                  <StyledChangeDetail
                                    to={`${claimManagementDetailURL}?id=${claimData.id}`}
                                  >
                                    Detail
                                  </StyledChangeDetail>
                                </StyledExpansionLabel>
                              </StyledSpanExpansionEdit>
                            </div>
                          </StyledExpansionPanelDetails>
                        </ExpansionPanel>
                      ))}
                      <div className="row mt-4">
                        <div className="col-12 text-center">
                          <label>
                            <Pagination
                              pagerPagination={props.pagingInfo}
                              getPage={props.handleChangePage}
                              onChangeRowsPerPage={props.setRowsPerPage}
                            />
                          </label>
                        </div>
                      </div>
                    </StyledHideForDesktop>
                  </div>
                </div>
              </StyledContentBox>
            </div>
          }

          {props.partnerOptions.allowAll &&
            <div className="col-lg-12 mt-3">
              <StyledContentBox>
                <StyledSpanLargerTitle>Transaction</StyledSpanLargerTitle>

                <div className="row">
                  <div className="col-lg-10 mt-2"></div>
                  <div className="col-lg-2 mt-2">
                    <BNPSelect
                      options={statusTransaction}
                      placeholder="Filter by transaction type"
                      name="transactionType"
                      onChange={props.changeFilterWallet}
                      value={props.searchParamWallet.transactionType || -1}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 mt-2">
                    <StyledHideForMobile>
                      <StyledTable>
                        <StyledHeaderTable>
                          <StyledCellTable width={10} title="Sundry Number">
                            Sundry Number
                          </StyledCellTable>
                          <StyledCellTable width={25} title="Title">
                            Title
                          </StyledCellTable>
                          <StyledCellTable width={25} title="Description">
                            Description
                          </StyledCellTable>
                          <StyledCellTable width={12} title="Cleared Amount">
                            cleared Amount
                          </StyledCellTable>
                          <StyledCellTable width={12} title="Pending Amount">
                            pending Amount
                          </StyledCellTable>
                          <StyledCellTable width={13} title="Transaction Type">
                            transaction Type
                          </StyledCellTable>
                          <StyledCellTable
                            width={13}
                            className="text-right"
                            title="Created Date"
                          >
                            created Date
                          </StyledCellTable>
                        </StyledHeaderTable>
                        <div>
                          {props.transactions.map((transaction, index) => (
                            <StyledBodyTable
                              key={index}
                              title={transaction.txnTitle}
                            >
                              <StyledCellTable width={10}>
                                {transaction.sundryNumber}
                              </StyledCellTable>
                              <StyledCellTable width={25}>
                                {transaction.txnTitle}
                              </StyledCellTable>
                              <StyledCellTable
                                width={25}
                                title={transaction.txnDescription}
                              >
                                {transaction.txnDescription}
                              </StyledCellTable>
                              <StyledCellTable
                                width={12}
                                title={formatterUSD("currency", "USD").format(
                                  centToUsd(transaction.clearedAmount)
                                )}
                              >
                                {formatterUSD("currency", "USD").format(
                                  centToUsd(transaction.clearedAmount)
                                )}
                              </StyledCellTable>
                              <StyledCellTable
                                width={12}
                                title={formatterUSD("currency", "USD").format(
                                  centToUsd(transaction.pendingAmount)
                                )}
                              >
                                {formatterUSD("currency", "USD").format(
                                  centToUsd(transaction.pendingAmount)
                                )}
                              </StyledCellTable>
                              <StyledCellTable
                                width={13}
                                title={transaction.transactionType}
                              >
                                {transaction.transactionType}
                              </StyledCellTable>
                              <StyledCellTable
                                width={13}
                                className="text-right"
                                title={
                                  transaction.createdDate
                                    ? moment(transaction.createdDate).format(FE_DATETIME_FORMAT)
                                    : ""
                                }
                              >
                                {transaction.createdDate
                                  ? moment(transaction.createdDate).format(FE_DATE_FORMAT)
                                  : ""}
                              </StyledCellTable>
                            </StyledBodyTable>
                          ))}
                        </div>
                      </StyledTable>
                      <div className="text-center mt-4">
                        <label>
                          <Pagination
                            pagerPagination={props.pagingInfoWallet}
                            getPage={props.changePageWallet}
                            onChangeRowsPerPage={props.setRowsPerPageWallet}
                          />
                        </label>
                      </div>
                    </StyledHideForMobile>

                    <StyledHideForDesktop>
                      {props.transactions.map((transaction, index) => (
                        <ExpansionPanel
                          square
                          defaultExpanded={false}
                          className="mt-2"
                          key={index}
                        >
                          <StyledExpansionPanelSummary
                            className="m-0"
                            expandIcon={<ArrowDropUp />}
                          >
                            <div className="w-100 ">
                              <StyledTitleExpansion>Title</StyledTitleExpansion>
                              <div className="mt-2">{transaction.txnTitle}</div>
                            </div>
                          </StyledExpansionPanelSummary>
                          <StyledExpansionPanelDetails>
                            <div className="w-100">
                              <StyledTitleExpansion>
                                Description
                              </StyledTitleExpansion>
                              <div className="mt-1">
                                {transaction.txnDescription}
                              </div>
                            </div>
                            <div className="w-100 mt-3">
                              <StyledTitleExpansion>
                                Cleared Amount
                              </StyledTitleExpansion>
                              <div className="mt-1">
                                {formatterUSD("currency", "USD").format(
                                  centToUsd(transaction.clearedAmount)
                                )}
                              </div>
                            </div>
                            <div className="w-100 mt-3">
                              <StyledTitleExpansion>
                                Pending Amount
                              </StyledTitleExpansion>
                              <div className="mt-1">
                                {formatterUSD("currency", "USD").format(
                                  centToUsd(transaction.pendingAmount)
                                )}
                              </div>
                            </div>
                            <div className="w-100 mt-3">
                              <StyledTitleExpansion>
                                Transaction Type
                              </StyledTitleExpansion>
                              <div className="mt-1">
                                {transaction.transactionType}
                              </div>
                            </div>
                            <div className="w-100 mt-3">
                              <StyledTitleExpansion>
                                Created Date
                              </StyledTitleExpansion>
                              <div className="mt-1">
                                {transaction.createdDate
                                  ? moment(transaction.createdDate).format(FE_DATE_FORMAT)
                                  : ""}
                              </div>
                            </div>
                          </StyledExpansionPanelDetails>
                        </ExpansionPanel>
                      ))}
                      <div className="row mt-4">
                        <div className="col-12 text-center">
                          <label>
                            <Pagination
                              pagerPagination={props.pagingInfoWallet}
                              getPage={props.changePageWallet}
                              onChangeRowsPerPage={props.setRowsPerPageWallet}
                            />
                          </label>
                        </div>
                      </div>
                    </StyledHideForDesktop>
                  </div>
                </div>
              </StyledContentBox>
            </div>
          }

        </div>
      </div>
    </StyledMainContent>
  );
}

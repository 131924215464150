import React from "react";
import {
  StyledMainContent,
  StyledBigTitle,
  StyledContentBox,
  StyledHideForMobile,
  StyledHideForDesktop,
  StyledExpansionPanelSummary,
  StyledTitleExpansion,
  StyledExpansionPanelDetails,
  StyledSelect,
  StyledDefaultOption,
  StyledOption,
} from "../../shared/styled";
import { TaxResponse, TaxSearchParam } from "./tax-type";
import Pagination, {
  PagingInfo,
} from "../../../cores/helpers/pagination/pagination";
import {
  StyledTable,
  StyledHeaderTable,
  StyledCellTable,
  StyledBodyTable,
} from "../../shared/table-styled";
import { ExpansionPanel, MenuItem } from "@material-ui/core";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import { SortTable } from "../../../cores/models/SortTable";
import { BNPSelect } from "../../../cores/helpers/select/select";
import { dateRange } from "./tax-model";
import { formatterUSD } from "../../../cores/helpers/format-usd";
import { centToUsd } from "../../../cores/helpers/cent-to-usd";
import { Provinces } from "../company-management/company-management-type";
import {
  getExportClaimHistory,
  getExportTax,
} from "../../../services/wallet-service";
import { exportExcel } from "../../../cores/helpers/export-file/export-file";
import { StyledDivExportExcel } from "../claim-management/claim-management-view";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import SystemUpdateAltOutlinedIcon from "@material-ui/icons/SystemUpdateAltOutlined";

type Props = {
  taxes: TaxResponse[];
  pagingInfo: PagingInfo;
  searchParam: TaxSearchParam;
  handleChangePage: (page: number) => void;
  setRowsPerPage: (event: any) => void;
  columns: SortTable[];
  handleChangeSelect: (event: any) => void;
  handleChangeProvince: (event: any) => void;
  provinces: Provinces[];
};

export function TaxView(props: Props) {
  return (
    <StyledMainContent>
      <StyledBigTitle>Tax</StyledBigTitle>
      <div className="container-fluid p-0 mt-3">
        <StyledContentBox>
          <div className="row">
            {/* <div className="col-lg-2 mt-4">
              <BNPDatePicker
                onChange={props.handleChangeDate}
                value={props.searchParam.from}
                placeholder="From date"
                name="from"
              />
            </div>
            <div className="col-lg-2 mt-4">
              <BNPDatePicker
                onChange={props.handleChangeDate}
                value={props.searchParam.to}
                placeholder="To date"
                name="to"
              />
            </div> */}
            <div className="col-lg-6 mt-4"></div>
            <div className="col-lg-2 mt-4">
              <BNPSelect
                options={dateRange}
                value={props.searchParam.dateRange || -1}
                name="dateRange"
                onChange={props.handleChangeSelect}
              />
            </div>
            <div className="col-lg-2 mt-4">
              <BNPSelect
                options={props.provinces}
                value={props.searchParam.provinceId || -1}
                name="provinceId"
                placeholder="Filter by province"
                onChange={props.handleChangeProvince}
              />
            </div>
            <div className="col-lg-2 mt-4">
              <StyledSelect value={-1}>
                <MenuItem value={-1}>
                  <StyledDefaultOption>
                    <PrintOutlinedIcon className="icon" /> Export data
                  </StyledDefaultOption>
                </MenuItem>
                <MenuItem value="1">
                  <StyledOption
                    onClick={() => {
                      getExportTax(props.searchParam).then((result) => {
                        exportExcel(result.data);
                      });
                    }}
                  >
                    <StyledDivExportExcel>
                      <SystemUpdateAltOutlinedIcon className="icon" /> Export
                      via Excel
                    </StyledDivExportExcel>
                  </StyledOption>
                </MenuItem>
              </StyledSelect>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 mt-4">
              <StyledHideForMobile>
                <StyledTable>
                  <StyledHeaderTable>
                    {props.columns.map((column, index) => (
                      <StyledCellTable
                        width={column.width}
                        key={index}
                        className="cur"
                      >
                        {column.columnName}
                      </StyledCellTable>
                    ))}
                  </StyledHeaderTable>
                  <div>
                    {props.taxes.map((tax, i) => (
                      <StyledBodyTable key={i}>
                        <StyledCellTable width={20}>
                          {tax.province}
                        </StyledCellTable>
                        <StyledCellTable width={20}>
                          {formatterUSD("currency").format(centToUsd(tax.gst))}
                        </StyledCellTable>
                        <StyledCellTable width={20}>
                          {formatterUSD("currency").format(centToUsd(tax.pst))}
                        </StyledCellTable>
                        <StyledCellTable width={20}>
                          {formatterUSD("currency").format(centToUsd(tax.rst))}
                        </StyledCellTable>
                        <StyledCellTable width={20}>
                          {formatterUSD("currency").format(
                            centToUsd(tax.premiumTax)
                          )}
                        </StyledCellTable>
                      </StyledBodyTable>
                    ))}
                  </div>
                  <div className="row mt-4">
                    <div className="col-12 text-center">
                      <label>
                        <Pagination
                          pagerPagination={props.pagingInfo}
                          getPage={props.handleChangePage}
                          onChangeRowsPerPage={props.setRowsPerPage}
                        />
                      </label>
                    </div>
                  </div>
                </StyledTable>
              </StyledHideForMobile>

              <StyledHideForDesktop>
                {props.taxes.map((tax, index) => (
                  <ExpansionPanel
                    square
                    defaultExpanded={false}
                    className="mt-2"
                    key={index}
                  >
                    <StyledExpansionPanelSummary
                      className="m-0"
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                      expandIcon={<ArrowDropUp />}
                    >
                      <div className="w-100">
                        <StyledTitleExpansion>Province</StyledTitleExpansion>
                        <div className="mt-2">{tax.province}</div>
                      </div>
                    </StyledExpansionPanelSummary>
                    <StyledExpansionPanelDetails>
                      <div className="w-100 mt-2">
                        <StyledTitleExpansion>GST</StyledTitleExpansion>
                        <div className="mt-1">
                          {formatterUSD("currency").format(centToUsd(tax.gst))}
                        </div>
                      </div>
                      <div className="w-100 mt-2">
                        <StyledTitleExpansion>PST</StyledTitleExpansion>
                        <div className="mt-1">
                          {formatterUSD("currency").format(centToUsd(tax.pst))}
                        </div>
                      </div>
                    </StyledExpansionPanelDetails>
                  </ExpansionPanel>
                ))}
                <div className="row mt-4">
                  <div className="col-12 text-center">
                    <label>
                      <Pagination
                        pagerPagination={props.pagingInfo}
                        getPage={props.handleChangePage}
                        onChangeRowsPerPage={props.setRowsPerPage}
                      />
                    </label>
                  </div>
                </div>
              </StyledHideForDesktop>
            </div>
          </div>
        </StyledContentBox>
      </div>
    </StyledMainContent>
  );
}

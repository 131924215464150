import React from "react";
import { Route } from "react-router-dom";
import {} from "react";
import LayoutEmployer from "./sign-in-layout";

const RouteLayoutSignIn = ({ component: Component, history, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <LayoutEmployer>
          <Component {...matchProps}></Component>
        </LayoutEmployer>
      )}
    />
  );
};

export default RouteLayoutSignIn;

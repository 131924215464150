import React from "react";
import CompanyManagementEmployeeDetailView from "./detail-employee-view";
import {
  ClaimData,
  StatusClaim,
  SearchParamClaim,
} from "../../claim-management/claim-management-type";
import {
  PagingInfo,
  setPagingInfo,
} from "../../../../cores/helpers/pagination/pagination";
import {
  EmployeeInfo,
  Balance,
  Adjustment,
  Transactions,
  WalletSearchParams,
  EmployeeModal,
  PendingAmountByCategory
} from "../company-management-type";
import {
  Status,
  Class,
  employeeStatus,
  signUpStatus,
} from "../detail/employees/employee-model";
import {
  employeeDetailClaimColumns,
  statusClaim,
} from "../../claim-management/claim-management-model";
import {
  getEmployeeClaimsById,
  getEmployeeInfoById,
  getEnrollments,
  updateEmployeeByAdmin,
  banEmployeeByAdmin,
} from "../../../../services/company-service";
import { RouteChildrenProps } from "react-router";
import { SortTable, SortType } from "../../../../cores/models/SortTable";
import { addLoading, removeLoading } from "../../../../cores/utils/loading";
import { message } from "../../../../cores/helpers/message/message";
import { getRole } from "../../../../services/auth-service";
import { getFileUrl } from "../../../../services/file-service";
import {
  getWalletEmployee,
  Credit,
  postCashCreditEmployee,
  postCashDebitEmployee,
  getAllTransactionsForEmployee, getPendingClaimAmountByCategory,
  getWalletOnboardingDate,
  getEmployeeWalletInfo
} from "../../../../services/wallet-service";
import EmployerCreditView, {
  closeCreditModal,
} from "../detail/credit/employer-credit-view";
import {
  ConfirmDialog,
  showConfirmModal,
} from "../../../../cores/helpers/confirm-modal/confirm-modal";
import { usdToCent } from "../../../../cores/helpers/cent-to-usd";
import { publishMessage } from "../../../../cores/utils/message";
import { formatterUSD } from "../../../../cores/helpers/format-usd";
import { filterData } from "../../../../cores/helpers/filter/filter";
import EmployeePopup from "../employee-modal/employee-modal";
import moment from "moment";
import DependentModal, { closeDependentModal } from "../dependent-modal/dependents-modal";
import {PartnerEmployeeDetailOptions} from "../../partner-management/partner-management-type";
import {getPermissions} from "../../../../services/partner-service";
import {isAdminUser} from "../../../../cores/utils/helpers";
import {TWalletBenefit} from "../../../shared/wallet-benefit/WalletBenefitModel";

type State = {
  // claims data
  searchParam: SearchParamClaim;
  pagingInfo: PagingInfo;
  statusClaim: StatusClaim[];
  claimsData: ClaimData[];
  columns: SortTable[];
  // employee info
  employee: EmployeeInfo;
  classes: Class[];
  statuses: Status[];
  roles: Status[];
  signUpStatuses: Status[];
  enrollments: Class[];
  onboardingDate: Date | string;
  // wallet
  adjustment: Adjustment;

  searchParamWallet: WalletSearchParams;
  transactionsFilter: Transactions[];
  transactions: Transactions[];
  pagingInfoWallet: PagingInfo;
  isPdf: boolean;

  editEmployee: any;
  isAdjustment: boolean;
  userPermissions: string[];
  partnerOptions: PartnerEmployeeDetailOptions;

  walletBenefit: TWalletBenefit;
};
type Props = RouteChildrenProps;

export default class CompanyManagementEmployeeDetail extends React.Component<
  Props,
  State
> {
  state: State = {
    claimsData: [],
    statusClaim: statusClaim,
    pagingInfo: {
      currentPage: 1,
      totalPages: 0,
      startPage: 0,
      endPage: 0,
      pages: [],
      rowsPerPage: 10,
    },
    searchParam: {
      page: 1,
      from: null,
      to: null,
      status: null,
      claimType: null,
      dateFilterType: null,
      searchName: null,
      columnName: null,
      sortType: null,
      perPage: 10,
    },
    columns: [],
    // employee info
    employee: {} as EmployeeInfo,
    classes: [],
    roles: [],
    statuses: employeeStatus,
    signUpStatuses: signUpStatus,
    enrollments: [],
    onboardingDate: "",
    // credit
    adjustment: {
      credit: 0,
      debit: 0,
      id: 0,
      note: "",
    },
    searchParamWallet: {
      page: 1,
      perPage: 10,
      transactionType: "",
    },
    transactionsFilter: [],
    transactions: [],
    pagingInfoWallet: {
      currentPage: 0,
      totalPages: 0,
      startPage: 0,
      endPage: 0,
      pages: [],
      rowsPerPage: 10,
    },
    isPdf: false,

    editEmployee: {},
    isAdjustment: false,
    userPermissions: [],
    partnerOptions: {
      allowAll: false,
      allowEditEmployee: false,
      allowViewBankingInformation: false,
      allowEditDependents: false,
    },

    walletBenefit: {
      cashBalance: 0,
      availableAmount: 0,
      pendingPayment: 0,
      utilizationRate: 0,
      monthlyCurrentLimit: 0,
      walletInfoDetails: [],
    }
  };
  employeeId: number = 0;

  async componentDidMount() {
    addLoading();
    let param = this.getSearchByUrl();

    const userPermissions = await this.getUserPermissions();
    const partnerOptions: PartnerEmployeeDetailOptions = this.buildPartnerOptions(userPermissions);
    this.setState({
      userPermissions,
      partnerOptions
    });

    await this.getData(userPermissions);

    if (partnerOptions.allowAll) {
      await this.getSearchClaimData(param.searchParam);
      await this.getAllTransactions(this.employeeId);
    }

    await this.getOnboardingDate(this.employeeId);

    this.setState({
      columns: param.columns,
      searchParam: param.searchParam,
      searchParamWallet: param.searchParamWallet
    });

    removeLoading();
  }

  componentDidUpdate(p: Props, prevState: State) {
    if (this.state.partnerOptions.allowAll && (
      this.state.searchParamWallet.page !== prevState.searchParamWallet.page ||
      this.state.searchParamWallet.perPage !== prevState.searchParamWallet.perPage ||
      this.state.searchParamWallet.transactionType !== prevState.searchParamWallet.transactionType ||
      this.state.transactions.length !== prevState.transactions.length)
    ) {
      const { transactions } = this.state;
      let paramSearch = this.getSearchByUrl();
      let response = filterData(
        Object.assign([], transactions),
        paramSearch.searchParamWallet.page,
        paramSearch.searchParamWallet.perPage,
        paramSearch.searchParamWallet.transactionType
          ? [{ 
              name: "transactionType",
              value: paramSearch.searchParamWallet.transactionType,
            }]
          : null
      );

      let pagingInfo = setPagingInfo(
        paramSearch.searchParamWallet.page,
        response.pageCount,
        paramSearch.searchParamWallet.perPage
      );

      this.setState({
        pagingInfoWallet: pagingInfo,
        transactionsFilter: response.records,
      });
    }
  }

    async getOnboardingDate(employeeId: number) {
        const onboardingDateResponse = await getWalletOnboardingDate(employeeId);
        this.setState({
            onboardingDate: onboardingDateResponse.data
        })
    }

  async getUserPermissions() {
    const res = await getPermissions();
    if (res.status === 200 && res.data) {
      return res.data.records;
    } else {
      publishMessage({
        message: "Can not get employer configuration. Please try again later.",
        variant: "error",
      });
    }
  }

  buildPartnerOptions(userPermissions: any): PartnerEmployeeDetailOptions {
    const allowAll = isAdminUser();

    return {
      allowAll: allowAll,
      allowViewBankingInformation: userPermissions.includes("employee.banking:view"),
      allowEditEmployee: userPermissions.includes("employee.profile:edit"),
      allowEditDependents: allowAll,
    }
  }

  async getData(userPermissions: string[]) {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");

    const [resultEmployee, resultRole, resultEnrollment] =
        await Promise.all([
          getEmployeeInfoById(Number(id)),
          getRole(),
          getEnrollments(),
          userPermissions.includes("employee.wallet:view") ? this.getAllBalances(Number(id)) : Promise.resolve(),
        ])
    const employee: EmployeeInfo = resultEmployee.data || ({} as EmployeeInfo);
    const roles: Status[] = resultRole.data || [];
    const enrollments: Class[] = resultEnrollment.data || [];

    let isPdf = false;
    if (employee.bankingInformation.voidCheque) {
      let link = employee.bankingInformation.voidCheque
      isPdf = !!link.match("/*.pdf")
      getFileUrl(link).then((response) => {
        employee.bankingInformation.voidCheque = response.data;
      });
    }

    this.setState({
      employee: employee,
      roles: roles,
      enrollments: enrollments,
      isPdf
    });
  }

  async getAllBalances(employeeId: number) {
    const resultWalletBenefitInfo = await getEmployeeWalletInfo(employeeId);

    this.setState({
      walletBenefit: resultWalletBenefitInfo.data ? resultWalletBenefitInfo.data : this.state.walletBenefit
    });
  }

  async UNSAFE_componentWillReceiveProps() {
    if (!this.state.partnerOptions.allowAll) return;

    let param = this.getSearchByUrl();
    addLoading();
    await this.getSearchClaimData(param.searchParam);

    this.setState({
      columns: param.columns,
      searchParam: param.searchParam,
      // searchParamWallet: param.searchParamWallet
    });
    removeLoading();
  }

  async getSearchClaimData(searchParam: SearchParamClaim) {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");

    await getEmployeeClaimsById(Number(id), searchParam).then((result) => {
      let pagingInfo = setPagingInfo(
        searchParam.page,
        result.data.pageCount,
        searchParam.perPage
      );
      let claimData: ClaimData[] = result.data.records || [];

      this.setState({
        claimsData: claimData,
        pagingInfo: pagingInfo,
      });
    });
  }

  handleChangSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    let searchParam: SearchParamClaim = Object.assign(this.state.searchParam);

    if ((event.target.name = "claimStatus")) {
      if (Number(value) !== -1) searchParam.status = value;
      else searchParam.status = null;
    }
    searchParam.page = 1;

    this.setSearchByParam(searchParam);
  };
  handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    let searchParam: SearchParamClaim = Object.assign(this.state.searchParam);

    searchParam.searchName = event.target.value;
    searchParam.page = 1;
    this.setState({ searchParam: searchParam });
  };

  searchNameOrEmail = () => {
    this.setSearchByParam(this.state.searchParam);
  };

  onKeyUp = (event: any) => {
    if (event.keyCode === 13) {
      this.searchNameOrEmail();
    }
  };

  handleChangeDate = (date: Date | null, name: string) => {
    let searchParam: SearchParamClaim = Object.assign(this.state.searchParam);

    if (name === "from") {
      if (searchParam.to && date) {
        let currentFrom = new Date(date),
          from = new Date(currentFrom),
          to = new Date(searchParam.to);
        if (from > to) {
          message("The from date cannot be after the to date.", "error");
          return;
        }
      }
      searchParam.from = date;
    } else {
      if (searchParam.from && date) {
        let from = new Date(searchParam.from),
          to = new Date(date);
        if (from > to) {
          message("The from date cannot be after the to date.", "error");
          return;
        }
      }
      searchParam.to = date;
    }
    searchParam.page = 1;

    this.setSearchByParam(searchParam);
  };

  handleChangePage = (page: number) => {
    let searchParam: SearchParamClaim = Object.assign(this.state.searchParam);
    searchParam.page = page;

    this.setSearchByParam(searchParam);
  };

  findStatusNameById = (statusId: string) => {
    let status = this.state.statusClaim.find((statusClaim) => {
      return statusClaim.id === statusId;
    });
    return status ? status.name : "";
  };

  findEnrollmentNameById = (id: number) => {
    let enrollment = this.state.enrollments.find((enrollment) => {
      return enrollment.id === id;
    });
    return enrollment ? enrollment.name : "";
  };

  sortTable = (columnId: string) => {
    let columnsHeaderTable: SortTable[] = Object.assign(this.state.columns);
    let searchParam = Object.assign({}, this.state.searchParam);
    searchParam.columnName = columnId;
    let index = columnsHeaderTable.findIndex((column) => {
      return column.columnId === columnId;
    });

    columnsHeaderTable.forEach((column, i) => {
      if (i === index) {
        column.sortType = column.sortType === "ASC" ? "DESC" : "ASC";
        searchParam.sortType = column.sortType;
        return;
      }
      column.sortType = null;
    });

    this.setSearchByParam(searchParam);
  };

  getSearchByUrl = () => {
    const urlParams = new URLSearchParams(window.location.search),
      id = urlParams.get("id"),
      page = urlParams.get("page"),
      from = urlParams.get("from"),
      to = urlParams.get("to"),
      status = urlParams.get("status"),
      searchName = urlParams.get("searchName"),
      columnName = urlParams.get("columnName"),
      sortType: SortType = urlParams.get("sortType") as SortType,
      rowsPerPage = urlParams.get("rowsPerPage"),
      pageWallet = urlParams.get("pageWallet"),
      rowsPerPageWallet = urlParams.get("rowsPerPageWallet"),
      transactionType = urlParams.get("transactionType");
    this.employeeId = Number(id);
    let searchParam = Object.assign({}, this.state.searchParam);

    if (page) {
      searchParam.page = Number(page);
    } else {
      searchParam.page = 1;
    }
    if (from) {
      searchParam.from = from;
    } else {
      searchParam.from = null;
    }
    if (to) {
      searchParam.to = to;
    } else {
      searchParam.to = null;
    }
    if (status) {
      searchParam.status = status;
    } else {
      searchParam.status = null;
    }
    if (searchName) {
      searchParam.searchName = searchName;
    } else {
      searchParam.searchName = null;
    }
    if (columnName && sortType) {
      searchParam.columnName = columnName;
      searchParam.sortType = sortType;
    } else {
      searchParam.columnName = null;
      searchParam.sortType = null;
    }

    rowsPerPage
      ? (searchParam.perPage = Number(rowsPerPage))
      : (searchParam.perPage = this.state.searchParam.perPage);

    // set sort table
    let columns: SortTable[] = employeeDetailClaimColumns();
    let index = columns.findIndex((column) => {
      return column.columnId === searchParam.columnName;
    });
    if (index > -1) {
      columns[index].sortType = sortType;
    }

    let searchParamWallet = Object.assign({}, this.state.searchParamWallet);
    if(pageWallet) {
      searchParamWallet.page = Number(pageWallet);
    }
    if(rowsPerPageWallet) {
      searchParamWallet.perPage = Number(rowsPerPageWallet);
    }
    if(transactionType) {
      searchParamWallet.transactionType = transactionType;
    }

    return {
      searchParam,
      columns,
      searchParamWallet
    };
  };

  setSearchByParam = (searchParam: SearchParamClaim) => {
    let url = new URL(window.location.href);

    if (searchParam.page) {
      url.searchParams.set("page", searchParam.page.toString());
    } else {
      url.searchParams.delete("page");
    }
    if (searchParam.from) {
      url.searchParams.set("from", moment(searchParam.from).format("YYYY-MM-DD"));
    } else {
      url.searchParams.delete("from");
    }
    if (searchParam.to) {
      url.searchParams.set("to", moment(searchParam.to).format("YYYY-MM-DD"));
    } else {
      url.searchParams.delete("to");
    }
    if (searchParam.status) {
      url.searchParams.set("status", searchParam.status);
    } else {
      url.searchParams.delete("status");
    }
    if (searchParam.searchName) {
      url.searchParams.set("searchName", searchParam.searchName);
    } else {
      url.searchParams.delete("searchName");
    }
    if (searchParam.columnName && searchParam.sortType) {
      url.searchParams.set("sortType", searchParam.sortType);
      url.searchParams.set("columnName", searchParam.columnName);
    } else {
      url.searchParams.delete("sortType");
      url.searchParams.delete("columnName");
    }

    searchParam.perPage
      ? url.searchParams.set("rowsPerPage", searchParam.perPage.toString())
      : url.searchParams.delete("rowsPerPage");

    this.props.history.push(url.search);
  };

  setSearchByParamWallet = (searchParam: WalletSearchParams) => {
    let url = new URL(window.location.href);

    if (searchParam.page) {
      url.searchParams.set("pageWallet", searchParam.page.toString());
    } else {
      url.searchParams.delete("pageWallet");
    }
    if (searchParam.perPage) {
      url.searchParams.set("rowsPerPageWallet", searchParam.perPage.toString());
    } else {
      url.searchParams.delete("rowsPerPageWallet");
    }
    if (searchParam.transactionType) {
      url.searchParams.set("transactionType", searchParam.transactionType.toString());
    } else {
      url.searchParams.delete("transactionType");
    }

    this.props.history.push(url.search);
  };

  setRowsPerPage = (event: any) => {
    const { value } = event.target;

    let searchParam = Object.assign({}, this.state.searchParam);
    searchParam.page = 1;
    searchParam.perPage = Number(value);

    // set url search
    this.setSearchByParam(searchParam);
  };

  findClassById = (classId: number): string => {
    let classItem = this.state.classes.find((classBenefit: Class) => {
      return classBenefit.id === classId;
    });
    return classItem ? classItem.name : "";
  };

  findSignUpStatusById = (signUpStatusId: string): string => {
    let signUpStatusItem = this.state.signUpStatuses.find(
      (signUpStatus: Status) => {
        return signUpStatus.id === signUpStatusId;
      }
    );
    return signUpStatusItem ? signUpStatusItem.name : "";
  };

  findStatusesById = (statusId: string): string => {
    let statusItem = this.state.statuses.find((status: Status) => {
      return status.id === statusId;
    });

    return statusItem ? statusItem.name : "";
  };

  findRoleById = (roleId: string): string => {
    let role = this.state.roles.find((role: Status) => {
      return role.id === roleId;
    });

    return role ? role.name : "";
  };

  //#endRegion Classes and Medical & Travel
  handleChangeInputDeposit = (event: React.ChangeEvent<HTMLInputElement>) => {
    let credit = Object.assign(this.state.adjustment);
    let { name, value } = event.target;
    credit[name] = value;

    
    this.setState({
      adjustment: credit,
    });
  };

  handleSubmitDeposit = () => {
    showConfirmModal();
    this.setState({ isAdjustment: true })
  };

  checkSubmitAdjustment = () => {
    let adjustment = Object.assign({}, this.state.adjustment);

    let valid = this.validateAdjustment(adjustment);

    // save credit
    if (valid.status === 1) {
      this.cashCredit(adjustment);
    }

    // save debit
    if (valid.status === 2) {
      this.cashDebit(adjustment);
    }

    // save both
    if (valid.status === 3) {
      this.cashCredit(adjustment);
      this.cashDebit(adjustment);
    }
  };

  cashCredit = (adjustmentData: Adjustment) => {
    let credit: Credit = {
      amount: usdToCent(adjustmentData.credit),
      targetId: this.state.employee.id,
      desc: `${this.state.employee.firstName} credit ${formatterUSD(
        "currency"
      ).format(adjustmentData.credit)}${this.state.adjustment.note ? ` - ${this.state.adjustment.note}` : ""}`,
    };

    postCashCreditEmployee(credit).then(() => {
      this.getAllBalances(this.state.employee.id);

      publishMessage({
        message: "Save credit success",
        variant: "success",
      });

      let adjustment: Adjustment = {
        credit: 0,
        debit: 0,
        id: 0,
        note: "",
      };

      closeCreditModal();
      this.getAllTransactions(this.employeeId);
      removeLoading();
      this.setState({ adjustment: adjustment });
    });
  };

  cashDebit = (adjustmentData: Adjustment) => {
    let debit: Credit = {
      amount: usdToCent(adjustmentData.debit),
      targetId: this.state.employee.id,
      desc: `${this.state.employee.firstName} debit ${formatterUSD(
        "currency"
      ).format(adjustmentData.debit)}${this.state.adjustment.note ? ` - ${this.state.adjustment.note}` : ""}`,
    };

    postCashDebitEmployee(debit).then(() => {
      this.getAllBalances(this.state.employee.id);

      publishMessage({
        message: "Save debit success",
        variant: "success",
      });

      let adjustment: Adjustment = {
        credit: 0,
        debit: 0,
        id: 0,
        note: "",
      };

      closeCreditModal();
      this.getAllTransactions(this.employeeId);
      removeLoading();
      this.setState({ adjustment: adjustment });
    });
  };

  validateAdjustment = (adjustment: Adjustment) => {
    // status === 1 credit, 2 debit, 3 both
    let response = {
      message: "",
      status: 0,
    };

    if (adjustment.credit && adjustment.debit) {
      response.status = 3;
      response.message = `${
        this.state.employee.firstName
      }'s wallet will be credit ${formatterUSD("currency").format(
        adjustment.credit
      )}
      and we will deduct ${formatterUSD("currency").format(
        adjustment.debit
      )} from their wallet.
      <br />
      Do you want to continue?`;
    }
    if (adjustment.credit && !adjustment.debit) {
      response.status = 1;
      response.message = `${
        this.state.employee.firstName
      }'s wallet will be credit ${formatterUSD("currency").format(
        adjustment.credit
      )}.
      <br />
      Do you want to continue?`;
    }
    if (!adjustment.credit && adjustment.debit) {
      response.status = 2;
      response.message = `System will deduct ${formatterUSD("currency").format(
        adjustment.debit
      )} from ${this.state.employee.firstName}'s wallet.
      <br />
      Do you want to continue?`;
    }

    return response;
  };

  changeFilterWallet = (event: any) => {
    let { name, value } = event.target,
      searchParam: any = Object.assign({}, this.state.searchParamWallet);

    if (Number(value) === -1) value = null;

    searchParam[name] = value;

    searchParam.page = 1;

    // set url search
    this.setSearchByParamWallet(searchParam);

    this.setState({ searchParamWallet: searchParam });
  };

  changePageWallet = (page: number) => {
    let searchParamWallet = Object.assign({},
      this.state.searchParamWallet
    );
    searchParamWallet.page = page;

    this.setSearchByParamWallet(searchParamWallet);
    this.setState({searchParamWallet})
  };

  async getAllTransactions(employerId: number) {
    let resultTransactions = await getAllTransactionsForEmployee(employerId);

    let transactions: Transactions[] = resultTransactions.data;

    this.setState({transactions})
  }

  setRowsPerPageWallet = (event: any) => {
    const { value } = event.target;

    let searchParamWallet = Object.assign({}, this.state.searchParamWallet);
    searchParamWallet.page = 1;
    searchParamWallet.perPage = Number(value);

    // set url search
    this.setSearchByParamWallet(searchParamWallet);
    this.setState({searchParamWallet});
  };

  handleUpdateEmployee = async (data: EmployeeModal) => {
    const res = await updateEmployeeByAdmin(data);
    if(res.status === 200) {
      publishMessage({
        message: "Update employee success",
        variant: "success"
      });
      await this.getData(this.state.userPermissions);
      return true;
    }
    return false;
  }

  modelFactory = () => {
    let temp: EmployeeModal = { ...this.state.employee } as any
    this.setState({ editEmployee: temp})
  }

  handleConfirmBanThisEmployee = () => {
    showConfirmModal();
    this.setState({ isAdjustment: false })
  };

  handleBanThisEmployee = async () => {
    const res = await banEmployeeByAdmin(this.state.employee.id);
    if (res.status === 200) {
      this.props.history.goBack();
    }
  }

  handleCloseDependentModal = async (): Promise<void> => {
    closeDependentModal();
    await this.getData(this.state.userPermissions);
  };

  render() {
    return (
      <>
        <CompanyManagementEmployeeDetailView
          searchParam={this.state.searchParam}
          pagingInfo={this.state.pagingInfo}
          statusClaim={this.state.statusClaim}
          claimsData={this.state.claimsData}
          columns={this.state.columns}
          handleChangeDate={this.handleChangeDate}
          handleChangSelect={this.handleChangSelect}
          handleChangeInput={this.handleChangeInput}
          onKeyUp={this.onKeyUp}
          searchNameOrEmail={this.searchNameOrEmail}
          findStatusNameById={this.findStatusNameById}
          handleChangePage={this.handleChangePage}
          sortTable={this.sortTable}
          // employee info
          employee={this.state.employee}
          classes={this.state.classes}
          statuses={this.state.statuses}
          roles={this.state.roles}
          signUpStatuses={this.state.signUpStatuses}
          findClassById={this.findClassById}
          findSignUpStatusById={this.findSignUpStatusById}
          findStatusesById={this.findStatusesById}
          findRoleById={this.findRoleById}
          findEnrollmentNameById={this.findEnrollmentNameById}
          setRowsPerPage={this.setRowsPerPage}
          onboardingDate={this.state.onboardingDate}

          pagingInfoWallet={this.state.pagingInfoWallet}
          changePageWallet={this.changePageWallet}
          setRowsPerPageWallet={this.setRowsPerPageWallet}

          transactions={this.state.transactionsFilter}
          searchParamWallet={this.state.searchParamWallet}
          changeFilterWallet={this.changeFilterWallet}
          isPdf={this.state.isPdf}
          modelFactory={this.modelFactory}
          handleConfirmBanThisEmployee={this.handleConfirmBanThisEmployee}
          partnerOptions={this.state.partnerOptions}

          walletBenefit={this.state.walletBenefit}
        />

        <EmployerCreditView
          handleChangeInput={this.handleChangeInputDeposit}
          adjustment={this.state.adjustment}
          handleSubmit={this.handleSubmitDeposit}
        />
        <ConfirmDialog
          content={
            this.state.isAdjustment ?
              <div
                dangerouslySetInnerHTML={{
                  __html: this.validateAdjustment(this.state.adjustment).message,
                }}
              ></div>
              :
              "Are you sure you want to disable this user?"
          }
          title={this.state.isAdjustment ? "Confirm employee adjustment" : "Disable"}
          handleOnOk={() => {
            if (this.state.isAdjustment)
              this.checkSubmitAdjustment()
            else
              this.handleBanThisEmployee()
          }}
        />
        <EmployeePopup
          data={this.state.editEmployee}
          isCreate={false}
          handleSubmit={this.handleUpdateEmployee}
        />
        <DependentModal handleCloseDependentModal={this.handleCloseDependentModal} />
      </>
    );
  }
}

export const getLimitBalance = (id: number, balances: Balance[]) => {
  switch (id) {
    case 1: {
      let hsaLimit = balances.find((m) => {
        return m.accountName === "hsa_limit";
      });

      return hsaLimit ? hsaLimit.balance : 0;
    }
    case 2: {
      let wellnessLimit = balances.find((m) => {
        return m.accountName === "wellness_limit";
      });
      return wellnessLimit ? wellnessLimit.balance : 0;
    }
    case 3: {
      let insuranceLimit = balances.find((m) => {
        return m.accountName === "insurance_limit";
      });
      return insuranceLimit ? insuranceLimit.balance : 0;
    }
    case 4: {
      let charityLimit = balances.find((m) => {
        return m.accountName === "charity_limit";
      });
      return charityLimit ? charityLimit.balance : 0;
    }
    case 5: {
      let rrspLimit = balances.find((m) => {
        return m.accountName === "rrsp_limit";
      });
      return rrspLimit ? rrspLimit.balance : 0;
    }
    case 6: {
      let craLimit = balances.find((m) => {
        return m.accountName === "cra_limit";
      });
      return craLimit ? craLimit.balance : 0;
    }
    default:
      return 0;
  }
};

import React, {useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import { Select, MenuItem } from "@material-ui/core";
import {
  BenefitCoverage,
  ReceiptData,
  ReceiptUpdate,
  TypeOfExpense,
} from "./claim-type";
import {
  StyledHeaderModal,
  StyledHeaderTitleModal,
  StyledButtonCloseModal,
  StyledIconCloseModal,
  StyledPError,
  StyledButtonSubmit,
  StyledTextarea,
} from "../../../shared/styled";
import { BNPSelect } from "../../../../cores/helpers/select/select";
import { BNPInput } from "../../../../cores/helpers/input/input";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { formatterUSD } from "../../../../cores/helpers/format-usd";
import { centToUsd, usdToCent } from "../../../../cores/helpers/cent-to-usd";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import ImageIcon from "@material-ui/icons/Image";
import { convertTimeZone } from "../../../../cores/utils/helpers";
import {
  getExpenseTypes,
  putUpdateReceipt,
} from "../../../../services/claim-service";
import { ClaimInfo } from "../claim-management-type";
import { toUTCTime } from "../../../../cores/helpers/to-utc-time";
import {FE_DATE_FORMAT} from "../../../../cores/utils/format/date-time-format";

const StyledDivNameClaimer = styled.div`
  font-size: 14px;
  line-height: 16px;
`;

const StyledDivTitleSelect = styled.div`
  color: #a0b2c6;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 5px;
  text-transform: uppercase;
`;
const StyledDivDatePicker = styled.div`
  border: 1px solid #ced4da;
  border-radius: 5px;
  .MuiFormControl-marginNormal {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0.5rem;
  }
  .MuiInputBase-input {
    font-size: 14px;
    height: 19px;
  }
`;

const StyledSelect = styled(Select)`
  font-family: ${(props) => props.theme.textFont} !important;
  .MuiSelect-select {
    border-radius: 5px;
    padding: 7px 0 7px;
    min-height: 34px;
  }
  svg {
    width: 1em !important;
    height: 1em !important;
  }
`;
const StyledDefaultOption = styled.div`
  font-size: 13px;
  color: #2d2d2d;
  opacity: 0.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const StyledOption = styled.div`
  font-size: 14px;
  display: flex;
  width: 100%;
  padding: 0 0.5rem;
`;

const StyledModalDialog = styled.div`
  text-align: left;
  max-width: 600px;
  margin: 1.75rem auto;
  width: 100%;
  pointer-events: none;
`;

const StyledDivRelationshipAmount = styled.div`
  color: #000;
  font-size: 14px;
  line-height: 14px;
  margin-top: 2px;
`;

type Props = {
  claimInfo: ClaimInfo;
  editReceiptId: number | null;
  employeeId: number;
  receiptIncludeId: ReceiptData[];
  reloadData: () => void;
  findLimitAmount: (typeOfClaim: string) => number;
  employeeEligibilityDate: Date | null;
};

export function ReceiptModal(props: Props) {
  const [receiptEdit, setReceiptEdit] = useState<any | null>(null);
  const [employeeBenefitCoverage, setEmployeeBenefitCoverage] = useState<
    BenefitCoverage[]
  >([]);
  const [isValidAmount, setIsValidAmount] = useState<boolean>(true);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isOtherExpense, setIsOtherExpense] = useState<boolean>(false);
  const [expanseTypes, setExpanseTypes] = useState<TypeOfExpense[]>([]);
  const [isOverAmount, setIsOverAmount] = useState<boolean>(false);
  const [isGroupInsuranceReceipt, setGroupInsuranceReceipt] = useState<boolean>(false);

  useEffect(() => {
    if (receiptEdit && receiptEdit.expenseType === "Other")
      setIsOtherExpense(true);
    if (receiptEdit) {
      setIsValid(validateEdit());
    }
    setIsOverAmount(false);
  }, [JSON.stringify(receiptEdit)]);

  useEffect(() => {
    if (receiptEdit) {
      const enrollmentId = getEnrollmentIdFromBenefitCoverageId(
        receiptEdit.employeeBenefitCoverageId
      );
      //get type of expense
      if (enrollmentId && enrollmentId > -1) {
        if (enrollmentId === 2)
          getTypeOfExpense(enrollmentId, receiptEdit, true);
        else getTypeOfExpense(enrollmentId, receiptEdit, false);
      }
      if(receiptEdit.typeOfClaim === 'Group Insurance') {
        setGroupInsuranceReceipt(true)
      }
    }
  }, [receiptEdit && receiptEdit.employeeBenefitCoverageId]);

  useEffect(() => {
    if (props.editReceiptId && props.receiptIncludeId) {
      const receiptEditInput = props.receiptIncludeId.find(
        (rc) => rc.id === props.editReceiptId
      );
      const receiptInfoData = props.claimInfo.receipts.find(
        (rc) => rc.id === props.editReceiptId
      );
      const {
        limitAmount,
        // limitAmountLastYear,
        proRatedAmount,
        // proRatedAmountLastYear,
        rolloverAmount,
        claimerName,
        expenseType,
        typeOfClaim,
      } = receiptInfoData || {};
      const receiptEditData = {
        ...receiptEditInput,
        amount: centToUsd(receiptEditInput && receiptEditInput.amount),
        limitAmount,
        // limitAmountLastYear,
        proRatedAmount,
        // proRatedAmountLastYear,
        rolloverAmount,
        claimerName,
        expenseType,
        typeOfClaim,
      };
      setReceiptEdit(receiptEditData);
    }
    if (props.claimInfo.employeeBenefitCoverageType) {
      setEmployeeBenefitCoverage(props.claimInfo.employeeBenefitCoverageType);
    }
  }, [props.editReceiptId, props.employeeId]);

  async function getTypeOfExpense(
    enrollmentId: number,
    receipt: ReceiptUpdate,
    isChangeSelect: boolean
  ) {
    let resultExpanseTypes = await getExpenseTypes(
      enrollmentId,
      props.employeeId
    );
    let expanseTypesResult: TypeOfExpense[] = resultExpanseTypes.data
      ? resultExpanseTypes.data
      : [];

    let isOtherExpanse = expanseTypesResult.some((expanseType) => {
      return (
        expanseType.id === receipt.expenseTypeId && expanseType.name === "Other"
      );
    });

    // if wellnest.
    if (enrollmentId === 2 && isChangeSelect) {
      let expanseType = expanseTypesResult.find((expanseType) => {
        return expanseType.name === "Other";
      });

      if (expanseType) {
        receipt.expenseTypeId = expanseType.id;
        receipt.expenseTypeOther = "";
        isOtherExpanse = true;
      }
    }
    setIsOtherExpense(isOtherExpanse);
    setExpanseTypes(expanseTypesResult);
    setReceiptEdit(receipt);
  }

  const handleChangeInput = (event: any) => {
    const { name, value } = event.target;

    let receipt: any = Object.assign({}, receiptEdit);
    setIsValidAmount(true);

    receipt[name] = value;

    // if amount then check value > 0
    if (name === "amount" && Number(value) < 0) {
      // show error message
      setIsValidAmount(false);
    }

    setReceiptEdit(receipt);
  };

  const handleChangeDate = (date: Date | null) => {
    let receipt: ReceiptUpdate = Object.assign({}, receiptEdit);

    receipt.expenseDate = date;

    setReceiptEdit(receipt);
  };

  const handleChangeEmployeeBenefitCoverage = (event: any) => {
    const value = Number(event.target.value);
    let receipt: ReceiptUpdate = Object.assign({}, receiptEdit);

    receipt.employeeBenefitCoverageId = value;
    const enrollmentId = getEnrollmentIdFromBenefitCoverageId(value);
    //get type of expense
    if (enrollmentId && enrollmentId > -1) {
      if (enrollmentId === 2) getTypeOfExpense(enrollmentId, receipt, true);
      else getTypeOfExpense(enrollmentId, receipt, false);
    }

    setReceiptEdit(receipt);
  };

  const getEnrollmentIdFromBenefitCoverageId = (
    benefitCoverageId: number
  ): number | undefined => {
    let benefitInfo = props.claimInfo.benefitsInfo.find((benefitInfo) => {
      return benefitInfo.id === benefitCoverageId;
    });
    if (benefitInfo && benefitInfo.id) return benefitInfo.enrollmentId;
    return -1;
  };

  const handleChangeExpanseType = (event: any) => {
    const value = Number(event.target.value);
    let receipt: ReceiptUpdate = Object.assign({}, receiptEdit);

    let isOtherExpanse = false;

    let expanseType = expanseTypes.find((f) => {
      return f.id === value;
    });

    // if value = (Other)
    if (expanseType) {
      if (expanseType.name === "Other") {
        isOtherExpanse = true;
      }
      receipt.expenseTypeOther = "";
    }

    // set expenseTypeId
    receipt.expenseTypeId = value;

    setReceiptEdit(receipt);
    setIsOtherExpense(isOtherExpanse);
  };

  const validateEdit = () => {
    if (isGroupInsuranceReceipt)
      return receiptEdit.amount > 0 &&
          receiptEdit.claimerId > -1 &&
          receiptEdit.expenseDate &&
          receiptEdit.expenseTypeId > 0;
    if (!isOtherExpense) {
      return receiptEdit.amount > 0 &&
          receiptEdit.claimerId > -1 &&
          receiptEdit.employeeBenefitCoverageId > 0 &&
          receiptEdit.expenseDate &&
          receiptEdit.expenseTypeId > 0;
    } else {
      return receiptEdit.amount > 0 &&
          receiptEdit.claimerId > -1 &&
          receiptEdit.employeeBenefitCoverageId > 0 &&
          receiptEdit.expenseDate &&
          receiptEdit.expenseTypeId > 0 &&
          receiptEdit.expenseTypeOther;
    }
  };

  const updateReceipt = () => {
    let currentBenefitCoverage = props.claimInfo.employeeBenefitCoverage.find(
      (m) =>
        m.employeeBenefitCoverageId === receiptEdit.employeeBenefitCoverageId
    ) as BenefitCoverage;

    let limitAmount = receiptEdit.limitAmount + receiptEdit.rolloverAmount;
    let proRatedAmount =
      receiptEdit.proRatedAmount + receiptEdit.rolloverAmount;

    let calculateProRatedAmountRemain =
      limitAmount -
      props.findLimitAmount(currentBenefitCoverage.name.trim()) -
      proRatedAmount;

    let limit = props.claimInfo.isProRated
      ? calculateProRatedAmountRemain >= 0
        ? 0
        : props.claimInfo.available
        ? Math.abs(calculateProRatedAmountRemain)
        : 0
      : props.findLimitAmount(currentBenefitCoverage.name.trim());

    if (Math.abs(limit / 100) < receiptEdit.amount) {
      setIsOverAmount(true);
    } else {
      handleSubmitReceipt();
    }
  };

  const updateGroupInsuranceReceipt = () => {
      handleSubmitReceipt();
  }

  const handleSubmitReceipt = () => {
    let receipt: any = Object.assign({}, receiptEdit);
    // set amount save
    receipt.expenseDate = receipt.expenseDate
      ? toUTCTime(new Date(receipt.expenseDate))
      : toUTCTime(new Date());
    receipt.amount = usdToCent(receiptEdit.amount);

    if (receipt.typeOfClaim !== "Group Insurance") {
      let benefitInfo = props.claimInfo.benefitsInfo.find((benefitInfo) => {
        return benefitInfo.enrollmentId === receipt.employeeBenefitCoverageId;
      });
      if (benefitInfo && benefitInfo.id) {
        receipt.employeeBenefitCoverageId = benefitInfo.id;
      }
    }

    const receiptUpdate: ReceiptUpdate = {
      receiptId: receipt.id,
      attachmentUrl: receipt.attachmentUrl,
      claimerType: receipt.claimerType,
      claimerId: receipt.claimerId,
      expenseTypeOther: receipt.expenseTypeOther,
      expenseTypeId: receipt.expenseTypeId,
      employeeBenefitCoverageId: receipt.employeeBenefitCoverageId,
      amount: receipt.amount,
      expenseDate: receipt.expenseDate,
      notes: receipt.notes,
      fileUrl: receipt.fileUrl,
    };
    putUpdateReceipt(props.employeeId, receiptUpdate).then(() => {
      closeReceiptModal();
      props.reloadData();
    });
  };

  const groupInsuranceModal = () => {
    const disabledComponent = (name: string, placeholder: string) => (
        <div className="col-lg-6 mt-3">
          <StyledDivTitleSelect>{name}</StyledDivTitleSelect>
          <BNPSelect
              options={[]}
              value={-1}
              placeholder={placeholder}
              disabled />
        </div>)
    return (<div className="row">
      {disabledComponent("Claimant", receiptEdit && receiptEdit.claimerName)}
      {disabledComponent("Type of claim", receiptEdit && receiptEdit.typeOfClaim)}
      {disabledComponent("Type of Expense", receiptEdit && receiptEdit.expenseType)}

      <div className="col-lg-6 mt-3">
        <StyledDivTitleSelect>Amount of Claim</StyledDivTitleSelect>
        <BNPInput
            placeholder="Amount of Claim"
            type="number"
            maxLength={9}
            value={receiptEdit && receiptEdit.amount}
            name="amount"
            onChange={handleChangeInput}
        />
        {!isValidAmount && (
            <StyledPError>
              Please do not enter a negative number
            </StyledPError>
        )}
        {isOverAmount && (
            <StyledPError>
              The amount is more than the amount that user set to.
            </StyledPError>
        )}
      </div>
      <div className="col-lg-6 mt-3">
        <StyledDivTitleSelect>Date of Expense</StyledDivTitleSelect>
        <StyledDivDatePicker>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                autoOk
                disabled
                InputAdornmentProps={{ position: "start" }}
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker-inline"
                placeholder={FE_DATE_FORMAT}
                value={
                  receiptEdit && receiptEdit.expenseDate
                      ? convertTimeZone(
                          "Canada/Eastern",
                          receiptEdit.expenseDate
                      )
                      : null
                }
                onChange={handleChangeDate}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                InputLabelProps={{
                  shrink: true,
                }}
            />
          </MuiPickersUtilsProvider>
        </StyledDivDatePicker>
      </div>
      <div className="col-lg-12 mt-3">
        <StyledDivTitleSelect>Note</StyledDivTitleSelect>
        <StyledTextarea
            value={(receiptEdit && receiptEdit.notes) || ""}
            cols={100}
            rows={4}
            name="notes"
            placeholder="Note"
            disabled
        />
      </div>

      <div className="col-12 mt-3">
        <StyledButtonSubmit
            onClick={updateGroupInsuranceReceipt}
            submit={isValid}
            disabled={!isValid}
            tabIndex={8}
        >
          Save
        </StyledButtonSubmit>
      </div>
    </div>)
  }

  return (
    <div className="modal" id="editReceiptModal">
      <StyledModalDialog role="document">
        <div className="modal-content">
          {/* header */}
          <StyledHeaderModal className="modal-header">
            <StyledHeaderTitleModal>
              Edit Receipt Infomation
            </StyledHeaderTitleModal>

            <StyledButtonCloseModal
              name="btn-close"
              onClick={closeReceiptModal}
            >
              <StyledIconCloseModal>&times;</StyledIconCloseModal>
            </StyledButtonCloseModal>
          </StyledHeaderModal>
          {/* end header */}
          {/* body */}
          <div className="modal-body">
            {isGroupInsuranceReceipt ? groupInsuranceModal() : (<div className="row">
              <div className="col-12 mt-3">
                {/* {receiptEdit.attachmentUrl &&
                  <UploadImage
                    urlImage={props.receiptEdit.fileUrl}
                  />
                } */}
              </div>

              <div className="col-lg-6 mt-3">
                <StyledDivTitleSelect>Claimant</StyledDivTitleSelect>
                <StyledSelect value={-1} name="claimer" disabled>
                  <MenuItem value={-1} key={-1}>
                    <StyledDefaultOption className="p-2">
                      {receiptEdit && receiptEdit.claimerName}
                    </StyledDefaultOption>
                  </MenuItem>
                </StyledSelect>
              </div>
              <div className="col-lg-6 mt-3">
                <StyledDivTitleSelect>Type of claim</StyledDivTitleSelect>
                <StyledSelect
                  value={
                    (receiptEdit && receiptEdit.employeeBenefitCoverageId) || -1
                  }
                  onChange={handleChangeEmployeeBenefitCoverage}
                  name="benefitCoverage"
                >
                  <MenuItem value={-1} key={-1}>
                    <StyledDefaultOption className="p-2">
                      Benefit
                    </StyledDefaultOption>
                  </MenuItem>
                  {receiptEdit && employeeBenefitCoverage &&
                    employeeBenefitCoverage.map((dependent, index) => {
                      let limitAmount =
                        receiptEdit.limitAmount +
                        receiptEdit.rolloverAmount;
                      let proRatedAmount =
                        receiptEdit.proRatedAmount +
                        receiptEdit.rolloverAmount;

                      let calculateProRatedAmountRemain =
                        limitAmount -
                        props.findLimitAmount(dependent.name.trim()) -
                        proRatedAmount;
                      return (
                        <MenuItem
                          value={dependent.employeeBenefitCoverageId}
                          key={index}
                        >
                          <StyledOption>
                            <div>
                              <StyledDivNameClaimer>
                                {dependent.name}
                              </StyledDivNameClaimer>
                              <StyledDivRelationshipAmount>
                                {` - Available ` +
                                  formatterUSD("currency").format(
                                    centToUsd(
                                      props.claimInfo.isProRated
                                        ? calculateProRatedAmountRemain >= 0
                                          ? 0
                                          : props.claimInfo.available
                                          ? Math.abs(
                                              calculateProRatedAmountRemain
                                            )
                                          : 0
                                        : props.findLimitAmount(
                                            dependent.name.trim()
                                          )
                                    )
                                  )}
                              </StyledDivRelationshipAmount>
                            </div>
                          </StyledOption>
                        </MenuItem>
                      );
                    })}
                </StyledSelect>
              </div>
              <div className="col-lg-6 mt-3">
                <StyledDivTitleSelect>Type of Expense</StyledDivTitleSelect>
                <BNPSelect
                  options={expanseTypes}
                  value={receiptEdit && receiptEdit.expenseTypeId}
                  placeholder={receiptEdit && receiptEdit.expenseType}
                  name="expanseTypes"
                  onChange={handleChangeExpanseType}
                />
                <BNPInput
                  className="mt-3"
                  placeholder="Type of expanse"
                  value={(receiptEdit && receiptEdit.expenseTypeOther) || ""}
                  hidden={!isOtherExpense}
                  name="expenseTypeOther"
                  onChange={handleChangeInput}
                  maxLength={255}
                />
              </div>

              <div className="col-lg-6 mt-3">
                <StyledDivTitleSelect>Amount of Claim</StyledDivTitleSelect>
                <BNPInput
                  placeholder="Amount of Claim"
                  type="number"
                  maxLength={9}
                  value={receiptEdit && receiptEdit.amount}
                  name="amount"
                  onChange={handleChangeInput}
                />
                {!isValidAmount && (
                  <StyledPError>
                    Please do not enter a negative number
                  </StyledPError>
                )}
                {isOverAmount && (
                  <StyledPError>
                    The amount is more than the amount that user set to.
                  </StyledPError>
                )}
              </div>
              <div className="col-lg-6 mt-3">
                <StyledDivTitleSelect>Date of Expense</StyledDivTitleSelect>
                <StyledDivDatePicker>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      disableFuture
                      minDate={props.employeeEligibilityDate && convertTimeZone("Canada/Eastern", props.employeeEligibilityDate)}
                      InputAdornmentProps={{ position: "start" }}
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      placeholder={FE_DATE_FORMAT}
                      value={
                        receiptEdit && receiptEdit.expenseDate
                          ? convertTimeZone(
                              "Canada/Eastern",
                              receiptEdit.expenseDate
                            )
                          : null
                      }
                      onChange={handleChangeDate}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </StyledDivDatePicker>
              </div>
              <div className="col-lg-12 mt-3">
                <StyledDivTitleSelect>Note</StyledDivTitleSelect>
                <StyledTextarea
                  value={(receiptEdit && receiptEdit.notes) || ""}
                  cols={100}
                  rows={4}
                  name="notes"
                  placeholder="Note"
                  disabled
                />
              </div>

              <div className="col-12 mt-3">
                <StyledButtonSubmit
                  onClick={updateReceipt}
                  submit={isValid}
                  disabled={!isValid}
                  tabIndex={8}
                >
                  Save
                </StyledButtonSubmit>
              </div>
            </div>)}
            {/* end body */}
          </div>
        </div>
      </StyledModalDialog>
    </div>
  );
}

export function showReceiptModal() {
  ($("#editReceiptModal") as any).modal({
    show: true,
    keyboard: false,
    backdrop: "static",
  });
}
export function closeReceiptModal() {
  ($("#editReceiptModal") as any).modal("hide");
}

import { AxiosResponse } from "axios";
import { getAsync, getFileAsync, postAsync, putAsync } from "../cores/utils/http-client";
import {
  CreatePartnerRequest,
  CreatePartnerUserRequest,
  EditPartnerRequest,
  EditPartnerUserRequest,
  PartnerSearchParam,
  UpdatePartnerUserStatusRequest,
  PartnerExportParam,
  SearchPartnerUserParam,
  UpdatePartnerStatusRequest,
  DisablePartnerRequest
} from "../components/pages/partner-management/partner-management-type";
import {SearchParamCompany} from "../components/pages/company-management/company-management-type";
import { BankingInfo } from "../components/shared/banking-information/BankingInformationType";
import {
  CommissionRate
} from "../components/pages/partner-management/partner-detail/commission-rate/CommissionRateManagementModel";
import {SearchResult} from "./employee-service";

const PARTNER_AUTH_BASEURL = "/partner-auth/api";
const URL_Partner = "/partner-auth/api/partners";
const URL_Admin = "/partner-auth/api/admin";
const URL_Employer_Partner_Options = "/manage-employer/api/partner-options";
const URL_Employee = "/manage-employer/api/employees";

export const postNewPartner = (param: CreatePartnerRequest): Promise<AxiosResponse> => {
  let url = `${URL_Partner}`;

  return postAsync(url, param);
};

export const putPartner = (param: EditPartnerRequest, partnerId: number): Promise<AxiosResponse> => {
  let url = `${URL_Partner}/${partnerId}`;

  return putAsync(url, param);
};

export const getPartners = (param: PartnerSearchParam): Promise<AxiosResponse> => {
  let url = `${URL_Partner}`;

  return getAsync(url, param);
};

export const getExportPartners = (param: PartnerSearchParam | PartnerExportParam): Promise<AxiosResponse> => {
  let url = `${URL_Partner}/export`;

  return getFileAsync(url, param);
};

export const getPartnerDetailByAdmin = (partnerId: number): Promise<AxiosResponse> => {
  let url = `${URL_Partner}/${partnerId}`;

  return getAsync(url);
};


export const getPartnerUsers = (partnerId: number, searchParam: SearchPartnerUserParam): Promise<AxiosResponse> => {
  let url = `${URL_Partner}/${partnerId}/users`;

  return getAsync(url, searchParam);
};

export const getPartnerPortalRoles = (): Promise<AxiosResponse> => {
  let url = `/partner-auth/api/partner-roles/partner-portal-roles`;

  return getAsync(url);
};

export const postNewPartnerUser = (param: CreatePartnerUserRequest, partnerId: number): Promise<AxiosResponse> => {
  let url = `${URL_Partner}/${partnerId}/partner-users`;

  return postAsync(url, param);
};

export const putPartnerUser = (param: EditPartnerUserRequest, partnerId: number, partnerUserId: number): Promise<AxiosResponse> => {
  let url = `${URL_Partner}/${partnerId}/partner-users/${partnerUserId}`;

  return putAsync(url, param);
};

export const putPartnerUserStatus = (param: UpdatePartnerUserStatusRequest, partnerId: number, partnerUserId: number): Promise<AxiosResponse> => {
  let url = `${URL_Partner}/${partnerId}/partner-users/${partnerUserId}/status`;

  return putAsync(url, param);
};


export const getEmployersByAdmin = (
  param: SearchParamCompany
): Promise<AxiosResponse> => {
  let url = `${URL_Admin}/employers`;

  return getAsync(url, param);
};

export const exportEmployersByAdmin = (
  param: SearchParamCompany
): Promise<AxiosResponse> => {

  let url = `${URL_Admin}/employers/export`;

  return getFileAsync(url, {...param, page: null});
};

export const getEmployersByPartnerId = (
  partnerId: number,
  param: SearchParamCompany
): Promise<AxiosResponse> => {
  let url = `${URL_Partner}/${partnerId}/employers`;

  return getAsync(url, param);
};

export const exportEmployersByPartnerId = (
  partnerId: number,
  param: SearchParamCompany
): Promise<AxiosResponse> => {
  let url = `${URL_Partner}/${partnerId}/employers/export`;

  return getFileAsync(url, {...param, page: null});
};

export const getPartnerCommissionRates = (partnerId: number): Promise<AxiosResponse> => {
  const url = `${URL_Partner}/${partnerId}/commission-rates`;
  return getAsync(url);
}

export const getPartnerBankingInformation = (partnerId: number, handleErrorAutomatic: boolean): Promise<AxiosResponse> => {
  let url = `${URL_Partner}/${partnerId}/banking`;
  return getAsync(url, undefined, handleErrorAutomatic);
};

export const getPartnerOptions = (): Promise<AxiosResponse> => {
  let url = `${URL_Employer_Partner_Options}`;
  return getAsync(url);
};

export const getReferralPartnerUserOptions = (partnerId: number): Promise<AxiosResponse> => {
  let url = `${URL_Employer_Partner_Options}/users?partnerId=${partnerId}`;

  return getAsync(url);
};

export const getEmployeeOptions = (employerId: number): Promise<AxiosResponse> => {
  const url = `${URL_Employee}/options/employer/${employerId}`;
  return getAsync(url);
}

export const getEmployeesByAdmin = async (searchTerm: string, employeeStatus: string, t4Employee: string, currentPage: number, rowsPerPage: number, sorts: Record<string, any>): Promise<SearchResult> => {
  const param = {
    searchTerm,
    employeeStatus: employeeStatus,
    t4EmployeeStatus: t4Employee,
    page: currentPage,
    perPage: rowsPerPage,
    columnName: Object.keys(sorts)[0],
    sortType: Object.values(sorts)[0]
  }
  const response = await getAsync(`${URL_Admin}/employees`, param);
  return response.data;
}

export const exportEmployeeByAdmin = async (searchTerm: string, employeeStatus: string, t4Employee: string, currentPage: number, rowsPerPage: number, sorts: Record<string, any>): Promise<AxiosResponse> => {
  let url = `${URL_Admin}/employees/export`;
  const param = {
    searchTerm,
    employeeStatus: employeeStatus,
    t4EmployeeStatus: t4Employee,
    page: null,
    perPage: rowsPerPage,
    columnName: Object.keys(sorts)[0],
    sortType: Object.values(sorts)[0]
  }

  return getFileAsync(url, param);
};

export const getEmployeesByPartner = async (partnerId: number, searchTerm: string, employeeStatus: string, t4Employee: string, currentPage: number, rowsPerPage: number, sorts: Record<string, any>): Promise<SearchResult> => {
  const param = {
    searchTerm,
    employeeStatus: employeeStatus,
    t4EmployeeStatus: t4Employee,
    page: currentPage,
    perPage: rowsPerPage,
    columnName: Object.keys(sorts)[0],
    sortType: Object.values(sorts)[0]
  }
  const response = await getAsync(`${URL_Partner}/${partnerId}/employees`, param);
  return response.data;
}

export const exportEmployeesByPartner = async (partnerId: number, searchTerm: string, employeeStatus: string, t4Employee: string, currentPage: number, rowsPerPage: number, sorts: Record<string, any>): Promise<AxiosResponse> => {
  const param = {
    searchTerm,
    employeeStatus: employeeStatus,
    t4EmployeeStatus: t4Employee,
    page: null,
    perPage: rowsPerPage,
    columnName: Object.keys(sorts)[0],
    sortType: Object.values(sorts)[0]
  }
  return getFileAsync(`${URL_Partner}/${partnerId}/employees/export`, param);
}

export const putPartnerBankingInformation = (bankingInfo: BankingInfo, partnerId: number): Promise<AxiosResponse> => {
  let url = `${URL_Partner}/${partnerId}/banking`;

  return putAsync(url, bankingInfo);
};

export const updatePartnerCommissionRates = (partnerId: number, commissionRates: CommissionRate[]): Promise<AxiosResponse> => {
  const url = `${URL_Partner}/${partnerId}/commission-rates`;
  return putAsync(url, {
    items: commissionRates
  });
}

export const getPermissions = (): Promise<AxiosResponse> => {
  let url = `${PARTNER_AUTH_BASEURL}/permissions`;
  return getAsync(url);
}

export const disablePartner = async (partnerId: number, params: DisablePartnerRequest) => {

  const res = await putAsync(`${URL_Partner}/${partnerId}/status`, {
    active: false,
    ...params,
  });

  if (res.status !== 200) {
    throw new Error("Can not disable partner");
  }
}

export const enablePartner = async (partnerId: number) => {

  const res = await putAsync(`${URL_Partner}/${partnerId}/status`, {
    active: true,
  });

  if (res.status !== 200) {
    throw new Error("Can not enable partner");
  }
}

export const getPartnerEmployerOptions = (partnerId: number): Promise<AxiosResponse> => {
  let url = `${URL_Partner}/${partnerId}/employer-options`;

  return getAsync(url);
};

export const getPartnerCommissionEnrollmentOptions = (partnerId: number): Promise<AxiosResponse> => {
  let url = `${URL_Partner}/${partnerId}/enrollment-options`;

  return getAsync(url);
};

import React, { useState } from "react";
import { BNPSelect } from "../../../../cores/helpers/select/select";
import { WalletReportType } from "../DashboardModel";
import { WalletSummaryReport } from "./WalletSummaryReport";
import { WalletComparisonReport } from "./WalletComparisonReport";
import { WalletDetailReport } from "./WalletDetailReport";
import { StyledBigTitle, StyledLargerTitle } from "../../../shared/styled";
import { AxiosResponse } from "axios";
import { TReportWallet } from "../DashboardType";

type Props = {

  getWalletSummaryReport: (date: Date | string) => Promise<TReportWallet<number>>;
  getWalletComparisonReport: (fromDate: (Date | string), toDate: (Date | string)) => Promise<AxiosResponse>;
  getWalletDetailReport: (fromDate: (Date | string), toDate: (Date | string)) => Promise<AxiosResponse>;
  exportWalletSummaryReport: (date: Date | string) => void;
  exportWalletComparisonReport: (fromDate: (Date | string), toDate: (Date | string)) => void;
  exportWalletDetailReport: (fromDate: (Date | string), toDate: (Date | string)) => void;

};

export default function WalletReport(props: Props) {
  const [reportType, setReportType] = useState<string>(
    WalletReportType.summary.id
  );
  const options = Object.values(WalletReportType).map((item) => ({
    id: item.id,
    name: item.title,
  }));

  function onChangeReportType(event: any) {
    const { value } = event.target;
    if (WalletReportType[value]) setReportType(WalletReportType[value].id);
  }

  return (
    <>
      <div className="row pt-3 d-flex">
        <div className="col-lg-9 col-md-8 col-12">
          <StyledBigTitle>Wallet Report</StyledBigTitle>
        </div>
        <div className="col-lg-3 col-md-4 col-12">
          <BNPSelect
            placeholder="Type of Report"
            options={options}
            value={reportType}
            onChange={onChangeReportType}
          />
        </div>
      </div>
      <div className="row pt-3">
        <div className="col-12 mb-3">
          <StyledLargerTitle>
            {WalletReportType[reportType].title.toUpperCase()}
          </StyledLargerTitle>
        </div>
        {reportType === WalletReportType.summary.id &&
          <WalletSummaryReport
            getWalletSummaryReport={props.getWalletSummaryReport}
            exportWalletSummaryReport={props.exportWalletSummaryReport}
          />
        }
        {reportType === WalletReportType.comparison.id && (
          <WalletComparisonReport
            getWalletComparisonReport={props.getWalletComparisonReport}
            exportWalletComparisonReport={props.exportWalletComparisonReport}
          />
        )}
        {reportType === WalletReportType.detailed.id &&
          <WalletDetailReport
            getWalletDetailReport={props.getWalletDetailReport}
            exportWalletDetailReport={props.exportWalletDetailReport}
          />
        }
      </div>
    </>
  );
}

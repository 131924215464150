import React, { useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import { StyledMainContent } from "../../../shared/styled";
import { TStringKey } from "../../dashboard/DashboardModel";
import { TReportFinancial, TReportWallet } from "../../dashboard/DashboardType";
import { RouteComponentProps } from "react-router-dom";
import WalletReport from "../../dashboard/wallet/WalletReport";
import FinancialReport from "../../dashboard/financial/FinancialReport";
import { AxiosResponse } from "axios";
import { getInfoByToken } from "../../../../cores/utils/helpers";
import { getCooperationPartnerFinancialComparisonReport, getCooperationPartnerFinancialDetailReport, getCooperationPartnerFinancialSummaryReport, getCooperationPartnerWalletComparisonReport, getCooperationPartnerWalletDetailReport, getCooperationPartnerWalletSummaryReport, getFinancialDetailReport } from "../../../../services/dashboard-service";
import moment from "moment";
import { BE_DATE_FORMAT } from "../../../../cores/utils/format/date-time-format";
import { PartnerDashboardTypeDetail } from "./DashboardType";
import TransactionReport from "./transaction-report/TransactionReport";
import CommissionReport from "./partner-commission/CommissionReport";


const StyledReportNavBar = styled.div`
  width: fit-content;
  min-width: 210px;
  background: none;
  display: flex;
  justify-content: space-between;
`;

const StyledReportNavBarItem = styled.div`
  overflow: hidden;
  line-height: 16px;
  display: flex;
  justify-content: center;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 32px;

  :hover {
    background-color: #f7f7f8;
  }

  ${(props: { active: boolean }) =>
    props.active &&
    css`
      background-color: ${(props) => props.theme.infoColor};
      color: #fff;
      font-weight: bold;

      :hover {
        background-color: ${(props) => props.theme.infoColor};
        opacity: 0.8;
      }
    `}
`;

export const DashboardType: TStringKey<PartnerDashboardTypeDetail> = {
  commission: {
    id: "commission",
    title: "Commission Report",
    permissions: ["partner admin", "partner member"],
    isLicensed: false,
  },
  transaction: {
    id: "transaction",
    title: "Transaction Report",
    permissions: ["partner admin"],
    isLicensed: true,
  },
};

function PartnerDashboard(props: RouteComponentProps) {

  const partnerId = getInfoByToken().mainPartnerId;
  const [activeReportType, setActiveReportType] = useState<string>(
    Object.keys(DashboardType)[0]
  );

  function handleChangeReportType(type: string) {
    setActiveReportType(type);
  }

  useEffect(() => {
  }, []);
  

  async function getWalletSummaryReport(date: Date | string): Promise<TReportWallet<number>> {
    return await getCooperationPartnerWalletSummaryReport(partnerId, moment(date).format(BE_DATE_FORMAT))
      .then(res => res.data);
  }

  async function getWalletComparisonReport(fromDate: Date | string, toDate: Date | string): Promise<AxiosResponse> {
    return await getCooperationPartnerWalletComparisonReport(
      partnerId,
      moment(fromDate).format(BE_DATE_FORMAT),
      moment(toDate).format(BE_DATE_FORMAT)
    );
  }

  async function getWalletDetailReport(fromDate: Date | string, toDate: Date | string): Promise<AxiosResponse> {
    return await getCooperationPartnerWalletDetailReport(
      partnerId,
      moment(fromDate).format(BE_DATE_FORMAT),
      moment(toDate).format(BE_DATE_FORMAT)
    );
  }

  async function getFinancialSummaryReport(date: Date | string): Promise<TReportFinancial<number>> {
    return await getCooperationPartnerFinancialSummaryReport(
      partnerId,
      moment(date).format(BE_DATE_FORMAT)
    ).then(res => res.data);
  }

  async function getFinancialComparisonReport(fromDate: Date | string, toDate: Date | string): Promise<AxiosResponse> {
    return await getCooperationPartnerFinancialComparisonReport(
      partnerId,
      moment(fromDate).format(BE_DATE_FORMAT),
      moment(toDate).format(BE_DATE_FORMAT)
    );
  }

  async function getFinancialDetailReport(fromDate: Date | string, toDate: Date | string): Promise<AxiosResponse> {
    return await getCooperationPartnerFinancialDetailReport(
      partnerId,
      moment(fromDate).format(BE_DATE_FORMAT),
      moment(toDate).format(BE_DATE_FORMAT)
    );
  }

  return (
    <StyledMainContent>
      <div className="pt-5 mb-5">
        <DashboardNav
          activeReportType={activeReportType}
          handleChangeReportType={handleChangeReportType}
        />
        {activeReportType === DashboardType.commission.id &&
          <CommissionReport/>}
        {/*{activeReportType === DashboardType.wallet.id &&*/}
        {/*  <WalletReport*/}
        {/*    getWalletSummaryReport={getWalletSummaryReport}*/}
        {/*    getWalletComparisonReport={getWalletComparisonReport}*/}
        {/*    getWalletDetailReport={getWalletDetailReport}*/}
        {/*  />}*/}
        {/*{activeReportType === DashboardType.financial.id &&*/}
        {/*  <FinancialReport*/}
        {/*    getFinancialSummaryReport={getFinancialSummaryReport}*/}
        {/*    getWalletSummaryReport={getWalletSummaryReport}*/}
        {/*    getFinancialComparisonReport={getFinancialComparisonReport}*/}
        {/*    getWalletComparisonReport={getWalletComparisonReport}*/}
        {/*    getFinancialDetailReport={getFinancialDetailReport}*/}
        {/*    getWalletDetailReport={getWalletDetailReport}*/}
        {/*  />*/}
        {/*}*/}
        {activeReportType === DashboardType.transaction.id &&
          <TransactionReport
            partnerId={partnerId}
          />
        }
      </div>
    </StyledMainContent>
  );
}

function DashboardNav(props: {
  activeReportType: string;
  handleChangeReportType: (type: string) => void;
}) {

  const permission = getInfoByToken().permission;
  const isLicensed = getInfoByToken().isLicensed;

  const validDashboardType = useMemo(() => {
    return Object.keys(DashboardType).filter(type =>
      DashboardType[type].permissions.includes(permission) && (!DashboardType[type].isLicensed || isLicensed));
  }, []);

  return (
    <StyledReportNavBar>
      {validDashboardType.map((type: string) => (
        <div key={`report-nav-bar-item-${type}`}>
          <StyledReportNavBarItem
            active={type === props.activeReportType}
            onClick={() => props.handleChangeReportType(type)}
          >
            {DashboardType[type].title}
          </StyledReportNavBarItem>
        </div>
      ))}
    </StyledReportNavBar>
  );
}

export default PartnerDashboard;

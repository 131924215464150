import {TableSortType} from "../../../shared/BNPTable/BNPTableType";
import {TableSortDataType} from "../../../shared/BNPTable/BNPTableModel";

export const payoutCommissionSummaryTableColumn: TableSortType[] = [
    {columnId: "partnerId", columnName: "Partner ID", width: 10, sortType: null, sortColumn: false},
    {columnId: "partnerName", columnName: "Partner Name", width: 30, sortType: null, sortColumn: false},
    {
        columnId: "commissionEarned",
        columnName: "Commission Earned",
        width: 25,
        sortType: null,
        sortColumn: false,
        type: TableSortDataType.CURRENCY,
    },
    {
        columnId: "clawback",
        columnName: "Clawback",
        width: 20,
        sortType: null,
        sortColumn: false,
        type: TableSortDataType.CURRENCY
    },
    {
        columnId: "netCommissionEarned",
        columnName: "Net Commission Earned",
        width: 25,
        sortType: null,
        sortColumn: false,
        type: TableSortDataType.CURRENCY
    },
];

export const payoutCommissionMonthlyTableColumn: TableSortType[] = [
    {columnId: "createdDate", columnName: "Month/Year", width: 10, sortType: null, sortColumn: false, type: TableSortDataType.MONTH},
    {columnId: "partnerId", columnName: "Partner ID", width: 10, sortType: null, sortColumn: false},
    {columnId: "partnerName", columnName: "Partner Name", width: 20, sortType: null, sortColumn: false},
    {columnId: "transactionId", columnName: "Transaction ID", width: 10, sortType: null, sortColumn: false},
    {
        columnId: "commissionEarned",
        columnName: "Commission Earned",
        width: 15,
        sortType: null,
        sortColumn: false,
        type: TableSortDataType.CURRENCY,
    },
    {
        columnId: "clawback",
        columnName: "Clawback",
        width: 10,
        sortType: null,
        sortColumn: false,
        type: TableSortDataType.CURRENCY
    },
    {
        columnId: "netCommissionEarned",
        columnName: "Net Commission Earned",
        width: 15,
        sortType: null,
        sortColumn: false,
        type: TableSortDataType.CURRENCY
    },
    {
        columnId: "createdDate",
        columnName: "Created Date",
        width: 10,
        sortType: null,
        sortColumn: false,
        type: TableSortDataType.DATE
    },
    {
        columnId: "payoutDate",
        columnName: "Payout Date",
        width: 10,
        sortType: null,
        sortColumn: false,
        type: TableSortDataType.DATE
    },
];

export const payoutCommissionYearlyTableColumn: TableSortType[] = [
    {columnId: "groupYear", columnName: "Year", width: 10, sortType: null, sortColumn: false, type: TableSortDataType.YEAR},
    {columnId: "partnerId", columnName: "Partner ID", width: 10, sortType: null, sortColumn: false},
    {columnId: "partnerName", columnName: "Partner Name", width: 30, sortType: null, sortColumn: false},
    {
        columnId: "commissionEarned",
        columnName: "Commission Earned",
        width: 25,
        sortType: null,
        sortColumn: false,
        type: TableSortDataType.CURRENCY,
    },
    {
        columnId: "clawback",
        columnName: "Clawback",
        width: 20,
        sortType: null,
        sortColumn: false,
        type: TableSortDataType.CURRENCY
    },
    {
        columnId: "netCommissionEarned",
        columnName: "Net Commission Earned",
        width: 25,
        sortType: null,
        sortColumn: false,
        type: TableSortDataType.CURRENCY
    },
];

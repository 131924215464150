import React from "react";
import {
  StyledMainContent,
  StyledBigTitle,
  StyledContentBox,
  StyledSelect,
  StyledDefaultOption,
  StyledOption,
} from "../../shared/styled";
import BNPDatePicker from "../../../cores/helpers/date-picker/date-picker";
import { BNPSelect } from "../../../cores/helpers/select/select";
import { BNPInput } from "../../../cores/helpers/input/input";
import {
  SearchParamClaim,
  StatusClaim,
  ClaimData,
  ClaimType,
  DateFilterType,
} from "./claim-management-type";
import SearchIcon from "@material-ui/icons/Search";
import styled from "styled-components";
import { MenuItem } from "@material-ui/core";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import SystemUpdateAltOutlinedIcon from "@material-ui/icons/SystemUpdateAltOutlined";
import Pagination, {
  PagingInfo,
} from "../../../cores/helpers/pagination/pagination";
import { getExportListSearchClaim } from "../../../services/claim-service";
import { exportExcel } from "../../../cores/helpers/export-file/export-file";
import { claimManagementDetailURL } from "../../../cores/constants/url-config";
import { convertTimeZone } from "../../../cores/utils/helpers";
import BNPRawTable from "../../shared/BNPTable/BNPRawTable";
import {TableSortType} from "../../shared/BNPTable/BNPTableType";

export const StyledIconSearch = styled(SearchIcon)`
  position: absolute;
  top: 13px;
  right: 14px;
  opacity: 0.7;
`;
export const StyledDivExportExcel = styled.div`
  color: #007540;
`;
export const StyledBNPInput = styled(BNPInput)`
  padding-right: 35px;
`;
export const StyledDivBoxTable = styled.div`
  min-height: 300px;
`;

type Props = {
  searchParam: SearchParamClaim;
  pagingInfo: PagingInfo;
  statusClaim: StatusClaim[];
  claimType: ClaimType[];
  dateFilterType: DateFilterType[];
  claimsData: ClaimData[];
  columns: TableSortType[];

  handleChangeDate: (date: Date | null, name: string) => void;
  handleChangSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyUp: (event: any) => void;
  searchNameOrEmail: () => void;
  findStatusNameById: (statusId: string) => string;
  handleChangePage: (page: number) => void;
  sortTable: (columnId: string) => void;
  setRowsPerPage: (event: any) => void;
};

export default function ClaimManagementView(props: Props) {

  const tableData = props.claimsData.map(it => ({
    ...it,
    claimStatus: props.findStatusNameById(it.claimStatus),
    linkTo: `${claimManagementDetailURL}?id=${it.id}`
  }))

  return (
    <StyledMainContent>
      <StyledBigTitle>Claim Management</StyledBigTitle>
      <div className="container-fluid p-0 mt-3">
        <StyledContentBox>
          <div className="row">
            <div className="col-lg-12 mt-4">
              <div className="row">
                <div className="col-lg-2 mt-4">
                  <BNPDatePicker
                    onChange={props.handleChangeDate}
                    value={props.searchParam.from ?
                      convertTimeZone("Canada/Eastern", props.searchParam.from).toDate()
                      :
                      ""
                    }
                    placeholder="From date"
                    name="from"
                    tabIndex={1}
                  />
                </div>
                <div className="col-lg-2 mt-4">
                  <BNPDatePicker
                    value={props.searchParam.to ?
                      convertTimeZone("Canada/Eastern", props.searchParam.to).toDate()
                      :
                      ""
                    }
                    onChange={props.handleChangeDate}
                    placeholder="To date"
                    name="to"
                    tabIndex={2}
                  />
                </div>
                <div className="col-lg-1 mt-4">
                  <BNPSelect
                    options={props.dateFilterType}
                    value={props.searchParam.dateFilterType}
                    name="dateFilterType"
                    placeholder="Choose date type to filter"
                    onChange={props.handleChangSelect}
                    tabIndex={3}
                  />
                </div>
                <div className="col-lg-1 mt-4">
                  <BNPSelect
                    options={props.statusClaim}
                    value={props.searchParam.status}
                    name="claimStatus"
                    placeholder="Filter by status"
                    onChange={props.handleChangSelect}
                    tabIndex={4}
                  />
                </div>
                <div className="col-lg-1 mt-4">
                  <BNPSelect
                    options={props.claimType}
                    value={props.searchParam.claimType}
                    name="claimType"
                    placeholder="Filter by Claim Type"
                    onChange={props.handleChangSelect}
                    tabIndex={5}
                  />
                </div>
                <div className="col-lg-2 mt-4">
                  <StyledBNPInput
                    placeholder="Search name or email or claim ID"
                    onChange={props.handleChangeInput}
                    value={props.searchParam.searchName || ""}
                    onKeyUp={props.onKeyUp}
                    tabIndex={6}
                  />
                  <StyledIconSearch onClick={props.searchNameOrEmail} />
                </div>
                <div className="col-lg-2"></div>
                <div className="col-lg-1 mt-4">
                  <StyledSelect
                    value={-1}
                    tabIndex={7}
                    //onChange={props.onChange}
                    //name={props.name}
                    //disabled={props.disabled ? true : false}
                  >
                    <MenuItem value={-1}>
                      <StyledDefaultOption>
                        <PrintOutlinedIcon className="icon" /> Export data
                      </StyledDefaultOption>
                    </MenuItem>
                    <MenuItem value="1">
                      <StyledOption
                        onClick={() => {
                          getExportListSearchClaim(props.searchParam).then(
                            (result) => {
                              exportExcel(result.data);
                            }
                          );
                        }}
                      >
                        <StyledDivExportExcel>
                          <SystemUpdateAltOutlinedIcon className="icon" />{" "}
                          Export via Excel
                        </StyledDivExportExcel>
                      </StyledOption>
                    </MenuItem>
                  </StyledSelect>
                </div>
              </div>
            </div>

            <div className="col-lg-12 mt-4">
              <BNPRawTable
                  tableName='claim_management_table'
                  columns={props.columns}
                  tableWidth='100%'
                  tableData={tableData}
                  handleChangeSortColumn={props.sortTable}
              />
              <div className="row mt-4">
                <div className="col-12 text-center">
                  <label>
                    <Pagination
                        pagerPagination={props.pagingInfo}
                        getPage={props.handleChangePage}
                        onChangeRowsPerPage={props.setRowsPerPage}
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </StyledContentBox>
      </div>
    </StyledMainContent>
  );
}

import React from "react";
import styled from "styled-components";
import {
  StyledHeaderModal,
  StyledHeaderTitleModal,
  StyledButtonCloseModal,
  StyledIconCloseModal,
  StyledButtonSubmit,
} from "../../../shared/styled";
import { BNPSelect } from "../../../../cores/helpers/select/select";
import { RejectValue } from "../claim-management-type";
import { rejectOption } from "../claim-management-model";

const StyledModalDialog = styled.div`
  max-width: 800px;
  margin: 1.75rem auto;
  position: relative;
  width: auto;
  pointer-events: none;
  text-align: left;
`;
const StyledDescriptionBox = styled.div`
  padding: 0px 5px;
  width: 100%;
`;
const StyledContentBox = styled.div`
  padding: 9px 5px;
  width: 100%;
`;
const StyledTextArea = styled.textarea`
  border: 1px solid #ced4da;
  width: 100%;
  border-radius: 3px;
  padding: 0.5rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
`;

type Props = {
  rejectValue: RejectValue;
  isValid: boolean;
  handleChangeValueRejectModal: (event: any) => void;
  handleSubmitRejectClaim: () => void;
  isReversed?: boolean;
  title?: any;
  isValidForReversed?: boolean;
};

export default function RejectModal(props: Props) {
  return (
    <div className="modal" id="rejectModal">
      <StyledModalDialog role="document">
        <div className="modal-content">
          <StyledHeaderModal className="modal-header">
            <StyledHeaderTitleModal>
              Declined
            </StyledHeaderTitleModal>
            <StyledButtonCloseModal type="button" onClick={closeRejectModal}>
              <StyledIconCloseModal>&times;</StyledIconCloseModal>
            </StyledButtonCloseModal>
          </StyledHeaderModal>
          <div className="modal-body">
            {
              props.isReversed &&
              (<div className="row">
                <StyledDescriptionBox>
                  {props.title}
                </StyledDescriptionBox>
              </div>)
            }
            {
              (!props.isReversed || props.isValidForReversed) && (
                <div className="row">
                  <StyledContentBox>
                    <BNPSelect
                      options={rejectOption}
                      placeholder="Choose declined option."
                      name="reasonType"
                      className="mt-3"
                      value={props.rejectValue.reasonType}
                      onChange={props.handleChangeValueRejectModal}
                    />
                    <StyledTextArea
                      placeholder="Declined description"
                      name="reason"
                      className="mt-3 w-100"
                      rows={4}
                      value={props.rejectValue.reason}
                      onChange={props.handleChangeValueRejectModal}
                    />
                  </StyledContentBox>
                </div>
              )}
            <div className="row">
              <div className="col-12">
                <div className="col-md-4 p-0 float-right mt-3">
                  {
                    (!props.isReversed || props.isValidForReversed) ?
                      (<StyledButtonSubmit
                        submit={props.isValid}
                        disabled={!props.isValid}
                        onClick={props.handleSubmitRejectClaim}>
                        Declined
                      </StyledButtonSubmit>)
                      :
                      (<StyledButtonSubmit
                        submit={true}
                        onClick={closeRejectModal}>
                        Ok
                      </StyledButtonSubmit>)
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledModalDialog>
    </div>
  );
}

export function showRejectModal() {
  ($("#rejectModal") as any).modal({
    show: true,
    keyboard: false,
    backdrop: "static",
  });
}
export function closeRejectModal() {
  ($("#rejectModal") as any).modal("hide");
}

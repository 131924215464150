import React, { Fragment, useEffect, useState } from "react";
import CreateIcon from "@material-ui/icons/Create";
import {
  StyledContentBox,
  StyledSpanLargerTitle,
} from "../../../../shared/styled";
import BNPRawTable from "../../../../shared/BNPTable/BNPRawTable";
import { TableSortType } from "../../../../shared/BNPTable/BNPTableType";
import { addLoading, removeLoading } from "../../../../../cores/utils/loading";
import {
  createRRSPProvider,
  getRRSPProviderSettings,
  getAllRRSPProviders,
} from "../../../../../services/company-service";
import { RRSPProviderType } from "./rrsp-provider-model";
import { RRSPProviderModal } from "./rrsp-provider-modal";
import { StyledSpanEditInfo } from "../company-management-detail-view";

type Props = {
  employerId: number;
};

const RRSPProviderTableColumns: TableSortType[] = [
  {
    columnId: "name",
    columnName: "Provider Name",
    sortType: null,
    width: 40,
    sortColumn: false,
  },
  {
    columnId: "triggerDateDescription",
    columnName: "Trigger Date",
    sortType: null,
    width: 60,
    sortColumn: false,
  },
];

export function RRSPProvider(props: Props) {
  const [rrspProviders, setRRSPProviders] = useState<RRSPProviderType[]>([]);
  const [data, setData] = useState<RRSPProviderType>();
  const [displayModal, setDisplayModal] = useState(false);

  useEffect(() => {
    getAllRRSPProviders().then((result) => {
      setRRSPProviders(result.data);
    });
  }, []);

  useEffect(() => {
    fetchData().then();
  }, [props.employerId]);

  const fetchData = async () => {
  
    if (props.employerId) {
      const result = await getRRSPProviderSettings(props.employerId);
      setData(result.data);
    }
  };

  const handleSubmitRRSPProvider = async (model: any) => {
    const modelToSubmit = {
      employerId: props.employerId,
      rrspProviderId: model.id,
    };
    addLoading();
    await createRRSPProvider(modelToSubmit);
    await fetchData();
    setDisplayModal(false);
    removeLoading();
  };

  return (
    <Fragment>
      <StyledContentBox>
        <StyledSpanLargerTitle>
          RRSP Provider
          <StyledSpanEditInfo onClick={() => setDisplayModal(true)}>
            <CreateIcon fontSize="small" /> Edit
          </StyledSpanEditInfo>
        </StyledSpanLargerTitle>
        <BNPRawTable
          tableName="rrsp_provider_table"
          columns={RRSPProviderTableColumns}
          tableWidth="100%"
          tableData={data ? [data] : []}
        />
      </StyledContentBox>
      <RRSPProviderModal 
        rrspProviderOption={rrspProviders}
        currentRRSPProvider={data}
        open={displayModal}
        setOpen={setDisplayModal}
        handleClose={() => setDisplayModal(false)}
        handleSubmit={handleSubmitRRSPProvider}
      />
    </Fragment>
  );
}

import React from "react";
import CompanyManagementDetailView from "./company-management-detail-view";
import {
  getEmployer,
  getEmployeesByEmployer,
  getProvinces,
  putCompanyInfo,
  getEnrollments,
  putBankingInfo,
  getPlanPreferences,
  getAgreementBankingInfo,
  createEmployeeByAdmin,
  changeEmployerStatus,
  createNotT4EmployeeByAdmin,
  getEmployerPlans
} from "../../../../services/company-service";
import {
  DataDetailCompany,
  SearchParamEmployee,
  Employee,
  Provinces,
  CompanyDataEdit,
  CompanyInfoDetail,
  Enrollment,
  Agreement,
  BankingInformation,
  EmployerEnrollment as EmployerEnrollmentBenefit,
  BenefitClass,
  Adjustment,
  Balances,
  PlanPreferencesModel,
  RequestPlanPreferences,
  PlanPreferences,
  Transactions,
  WalletSearchParams,
  EmployeeModal,
} from "../company-management-type";
import { payments } from "../company-management-model";
import {
  PagingInfo,
  setPagingInfo,
} from "../../../../cores/helpers/pagination/pagination";
import { columnsHeaderTable, Class, partnerViewColumnsHeaderTable } from "./employees/employee-model";
import {
  ConfirmDialog,
  showConfirmModal,
  closeConfirmModal,
} from "../../../../cores/helpers/confirm-modal/confirm-modal";
import { message } from "../../../../cores/helpers/message/message";
import { SortTable, SortType } from "../../../../cores/models/SortTable";
import { removeLoading, addLoading } from "../../../../cores/utils/loading";
import CompanyInformationModal, {
  closeCompanyInformationModal,
} from "./company-information-modal";
import { formatValueInputMask } from "../../../../cores/helpers/format-phone-number";
import { publishMessage } from "../../../../cores/utils/message";
import UpdateBankingInformationModal, {
  showUpdateBankingInformationModal,
  closeUpdateBankingInformationModal,
} from "./banking-information-modal";
import OCRCheque from "../../../../cores/helpers/ocr-cheque/ocr-cheque";
import { ClassBenefit } from "../../../../cores/helpers/add-class-benefits/add-class-benefits";
import { usdToCent } from "../../../../cores/helpers/cent-to-usd";
import EmployerCreditView, {
  showCreditModal,
  closeCreditModal,
} from "./credit/employer-credit-view";
import {
  getAllBalancesEmployer,
  getAllTransactions,
  postCashCreditEmployer,
  Credit,
  postCashDebitEmployer,
  getAllSeperatePendingAmount,
  requestRefundForEmployer,
  postPayAutoTopUpPending,
  getAutoTopUpPending,
} from "../../../../services/wallet-service";
import { getFileUrl } from "../../../../services/file-service";
import { ResponseLocation } from "../../../../cores/helpers/google-map-services/google-location";
import { Filter } from "../../../../cores/helpers/filter/filter";
import { formatterUSD } from "../../../../cores/helpers/format-usd";
import {
  BENIPLUS_DEFAULT_OPTION,
  NA_OPTION_VALUE,
  getDefaultSelectedPartnerUserId,
  getInfoByToken,
  getRemakedReferralPartnerUsers,
  compareStrings
} from "../../../../cores/utils/helpers";
import { generateUUID } from "../../../../cores/helpers/uuid";
import { closeRefundModal, showRefundModal } from "./refund/employer-refund-view";
import EmployeePopup, { defaultEmployee } from "../employee-modal/employee-modal";
import { EnableStatusEnum } from "../../../HOC/withStatusButtonActions";
import {UserInfo} from "../../../../models/login/login-req-model";
import {getPartnerOptions, getPermissions, getReferralPartnerUserOptions} from "../../../../services/partner-service";
import {PartnerCompanyDetailOptions} from "../../partner-management/partner-management-type";
import {ADMIN_PORTAL_ROLES, ROLE_PARTNER_ADMIN} from "../../../../cores/constants/string-constraint";
import { Option } from "../../../../cores/helpers/select/select";
import { defaultPartnerCompanyDetailOptions } from "../../partner-management/partner-management-model";
import {TableSearchParamType, TableSortType} from "../../../shared/BNPTable/BNPTableType";
import PayAutoTopUpPendingModal from "./pay-auto-topup/PayAutoTopUpPendingModal";
import { AutoDepositPendingType, PayAutoTopUpPendingType } from "./pay-auto-topup/PayAutoTopUpPendingType";
import _ from "lodash";
import PlanModal from "./plan/plan-modal";
import {toUTCTime} from "../../../../cores/helpers/to-utc-time";
import {validateEmail} from "../../../../cores/config/config";
import {RouteChildrenProps} from "react-router";

type State = {
  companyInfos: DataDetailCompany;
  employees: Employee[];
  searchParam: SearchParamEmployee;
  pagingInfo: PagingInfo;
  isLoadingTableEmployees: boolean;
  columns: TableSortType[];
  provinces: Provinces[];
  enrollments: Enrollment[];
  //balances
  availableAmount: number;
  pendingAmount: {
    processing: number;
    pendingAmount: number;
    claimRequested: number;
    processingMonthlyContribution: number;
  };
  transactions: Transactions[];
  balances: Balances[];

  //data edit company
  companyInfoEdit: CompanyDataEdit;
  isValidCompanyInfo: boolean;
  isAdditionalStreet: boolean;
  //data edit banking info
  paymentMethod: number;
  bankingInfoEdit: BankingInformation;
  agreementData: Agreement[];
  fileUrl: string;
  fileUrlEdit: string;
  //data edit employer enrollments benefit
  employerEnrollmentBenefit: EmployerEnrollmentBenefit[];
  // data classes and  medical & travel
  classes: Class[];
  classBenefit: ClassBenefit;
  employerClassBenefits: BenefitClass[];
  isValid: boolean;
  isOtherClass: boolean;
  // credit
  adjustment: Adjustment;
  isAdjustment: boolean;
  //plan preferences
  planPreferencesByEmployer: RequestPlanPreferences[];
  planPreferences: PlanPreferencesModel[];

  searchParamWallet: WalletSearchParams;
  pagingInfoWallet: PagingInfo;
  isRefund: boolean;
  amount: number;
  isT4Employee: boolean;
  userPermissions: string[];
  referralPartnerUsers: Option[];
  referralPartners: Option[];
  partnerOptions: PartnerCompanyDetailOptions;
  // pay auto top up pending
  openPayAutoTopUpPending: boolean;
  autoDepositPending: AutoDepositPendingType[];
  openEmployerPlanIdModal: number;
};

const EMPLOYEE: string = 'EMPLOYEE';
const TRANSACTION: string = 'TRANSACTION';

export default class CompanyManagementDetail extends React.Component<
  any & RouteChildrenProps,
  State
> {
  state: State = {
    companyInfos: {} as DataDetailCompany,
    employees: [],
    searchParam: {
      page: 1,
      columnName: null,
      sortType: null,
      perPage: 10,
    },
    searchParamWallet: {
      page: 1,
      perPage: 10,
      transactionType: "",
    },
    pagingInfo: {
      currentPage: 0,
      totalPages: 0,
      startPage: 0,
      endPage: 0,
      pages: [],
      rowsPerPage: 10,
    },
    pagingInfoWallet: {
      currentPage: 0,
      totalPages: 0,
      startPage: 0,
      endPage: 0,
      pages: [],
      rowsPerPage: 10,
    },
    columns: _.cloneDeep(columnsHeaderTable),
    //balances
    availableAmount: 0,
    pendingAmount: {
      claimRequested: 0,
      pendingAmount: 0,
      processing: 0,
      processingMonthlyContribution: 0,
    },
    transactions: [],
    balances: [],
    isLoadingTableEmployees: false,
    companyInfoEdit: {} as CompanyDataEdit,
    provinces: [],
    isAdditionalStreet: false,
    isValidCompanyInfo: false,
    enrollments: [],
    paymentMethod: -1,
    //data edit banking info
    agreementData: [],
    bankingInfoEdit: {
      accountNumber: "",
      bankNumber: "",
      eSignature: "",
      transitNumber: "",
      voidCheque: "",
      agreements: [],
      date: new Date()
    },
    fileUrl: "",
    fileUrlEdit: "",
    //data edit employer enrollments benefit
    employerEnrollmentBenefit: [],
    //classes
    classes: [],
    classBenefit: {
      classId: 0,
      coverageAmount: 0,
      noOfEmployees: 0,
      className: "",
    },
    employerClassBenefits: [],

    isValid: false,
    isOtherClass: false,
    // credit
    adjustment: {
      credit: 0,
      debit: 0,
      id: 0,
      note: "",
    },
    isAdjustment: false,
    //plan preferences
    planPreferences: [],
    planPreferencesByEmployer: [],
    isRefund: false,
    amount: 0,
    isT4Employee: true,
    userPermissions: [],
    referralPartnerUsers: [],
    referralPartners: [],
    partnerOptions: defaultPartnerCompanyDetailOptions,
    openPayAutoTopUpPending: false,
    autoDepositPending: [],
    openEmployerPlanIdModal: 0,
  };
  employerId: number = 0;

  userInfo: UserInfo = getInfoByToken();

  async componentDidMount() {
    let param = this.getSearchByUrl();

    addLoading();
    this.employerId = Number(param.id);

    const userPermissions = await this.getUserPermissions();
    const partnerOptions: PartnerCompanyDetailOptions = this.buildPartnerOptions(userPermissions);
    this.setState({
      userPermissions,
      partnerOptions,
      columns: partnerOptions.allowEditEmployee ?
        _.cloneDeep(columnsHeaderTable) :
        _.cloneDeep(partnerViewColumnsHeaderTable),
    });

    const [result] = await Promise.all([
        this.getData(Number(this.employerId)),
        this.getAllBalances(Number(this.employerId)),
        this.getEmployeeByEmployer(Number(this.employerId), param.searchParam),
        this.getAllTransactions(Number(this.employerId), param.searchParamWallet),
    ]);

    let isAdditionalStreet = false;
    if (
      result.companyInfo.companyInformation.postcode &&
      result.companyInfo.companyInformation.postcode2 &&
      result.companyInfo.companyInformation.postcode ===
      result.companyInfo.companyInformation.postcode2
    ) {
      isAdditionalStreet = true;
    }

    let companyInfoEdit = this.setCompanyDataEdit(
      result.companyInfo.companyInformation
    );

    let referralPartnerData: any = [BENIPLUS_DEFAULT_OPTION, ...result.partnerOptions];
    let referralPartnerUsers = [];

    if (!companyInfoEdit.referralPartnerId) {
      let defaultPartner = referralPartnerData.findLast((i: any) => i.isDefaultReferral);
      defaultPartner = defaultPartner ? defaultPartner : BENIPLUS_DEFAULT_OPTION;
      let defaultPartnerId: number = defaultPartner.id;
      companyInfoEdit.referralPartnerId = defaultPartnerId;
    }

    const referralPartnerUsersRes = await getReferralPartnerUserOptions(companyInfoEdit.referralPartnerId);
    referralPartnerUsers = referralPartnerUsersRes.data || [];

    let selectedPartner = this.getSelectedPartner(referralPartnerData, companyInfoEdit.referralPartnerId);
    if (selectedPartner) {
      referralPartnerUsers = getRemakedReferralPartnerUsers(selectedPartner, referralPartnerUsers);
    }

    if (companyInfoEdit.referralPartnerUserId == null) {
      companyInfoEdit.referralPartnerUserId = getDefaultSelectedPartnerUserId(selectedPartner, referralPartnerUsers);
    }

    if (result.companyInfo.bankingInfo.voidChequeUrl && !this.props.enablePresetBankingInformation) {
      this.getFileUrl(result.companyInfo.bankingInfo.voidChequeUrl).then();
    }

    this.initBankingInfoEdit({...result.companyInfo.bankingInfo, voidCheque:this.state.fileUrl, agreements: []})

    this.setState({
      companyInfoEdit: companyInfoEdit,
      companyInfos: result.companyInfo,
      provinces: result.provinces,
      isAdditionalStreet: isAdditionalStreet,
      enrollments: result.enrollments,
      planPreferences: result.planPreferences,
      agreementData: result.agreementData,
      searchParam: param.searchParam,
      searchParamWallet: param.searchParamWallet,
      referralPartners: referralPartnerData,
      referralPartnerUsers: referralPartnerUsers,
    });

    removeLoading();
  }

  async getData(employerId: number) {
    const [resultProvinces, resultCompanyInfo, resultEnrollments,
      resultAllPlanReferences, result,
      partnersResult, employerPlansResult
    ] =
    await Promise.all([
      getProvinces(),
      getEmployer(employerId),
      getEnrollments(),
      getPlanPreferences(),
      getAgreementBankingInfo(),
      getPartnerOptions(),
      getEmployerPlans(employerId),
    ])

    const companyInfo: DataDetailCompany = resultCompanyInfo.data || {};
    companyInfo.employerPlans = employerPlansResult.data || [];

    return {
      provinces: resultProvinces.data || [] as Provinces[],
      companyInfo,
      enrollments: resultEnrollments.data || [] as Enrollment[],
      planPreferences: resultAllPlanReferences.data || [] as PlanPreferencesModel[],
      agreementData: result.data || [],
      partnerOptions: partnersResult.data || [],
    };
  }

  getSelectedPartner = (referralPartnerData: any, selectedReferralPartnerId: number) => {
    let selectedPartner = null;

    let selectedPartnerArr = referralPartnerData && referralPartnerData.length > 0
      ? referralPartnerData.filter((p: any) => p.id == selectedReferralPartnerId) : [];
    if (selectedPartnerArr.length > 0) {
      selectedPartner = selectedPartnerArr[0];
    }

    return selectedPartner;
  }

  initBankingInfoEdit(bankingInfo: BankingInformation) {
    this.setState({
      bankingInfoEdit: {
        ...bankingInfo,
        voidCheque: bankingInfo.voidChequeUrl,
        agreements: []
      },
      fileUrlEdit: this.state.fileUrl }
    )
  }

  async getAllBalances(employerId: number) {
    let resultBalances = await getAllBalancesEmployer(employerId);
    let resultPendingAmount = await getAllSeperatePendingAmount(employerId);

    //get balance
    let balances: Balances[] = resultBalances.data.balances,
      pendingAmount = resultPendingAmount.data,
      availableAmount = 0;
    if (balances.length) {
      let physical = balances.find(
        (balance) => balance.walletType === "physical"
      );

      if (physical) {
        availableAmount = physical.balance;
      }
    }

    this.setState({
      pendingAmount,
      availableAmount,
      balances,
    });
  }

  async getUserPermissions() {
    const res = await getPermissions();
    if (res.status === 200 && res.data) {
      return res.data.records;
    } else {
      publishMessage({
        message: "Can not get employer configuration. Please try again later.",
        variant: "error",
      });
    }
  }

  buildPartnerOptions(userPermissions: any): PartnerCompanyDetailOptions {
    const isAdminUser = ADMIN_PORTAL_ROLES.some(role => userPermissions.includes(role));
    return {
      allowAll: isAdminUser,
      allowAdjustment: isAdminUser,
      allowRefund: isAdminUser,
      allowDisableEmployer: userPermissions.includes("employer.detail:disable"),
      allowEditEmployer: userPermissions.includes("employer.detail:edit"),
      allowEditWallet: userPermissions.includes("employer.wallet:edit"),
      allowEditPlans: userPermissions.includes("employer.plan:edit"),
      allowViewEmployees: userPermissions.includes("employer.employee:view"),
      allowEditEmployee: userPermissions.includes("employee.detail:edit"),
      allowViewEmployeeDetail: userPermissions.includes("employee.profile:view"),
      allowViewTransactions: userPermissions.includes("employer.transaction:view"),
      allowViewMonthlyGroupContributions: userPermissions.includes("partner.wallet:view"),
      allowViewPremiumInsurance: userPermissions.includes("employer.group-insurance:view"),
      allowViewBankingInfo: userPermissions.includes("employer.banking:view"),
      allowEditBankingInfo: userPermissions.includes("employer.banking:edit"),
      allowEditReferralPartner: isAdminUser,
      allowEditReferralPartnerUser: isAdminUser || userPermissions.includes(ROLE_PARTNER_ADMIN)
    }
  }

  async getAllTransactions(employerId: number, transactionSearchParam: WalletSearchParams) {
    if (!this.state.userPermissions.includes("employer.transaction:view")) {
      return Promise.resolve();
    }

    let criteria: TableSearchParamType = {
      page: transactionSearchParam.page,
      perPage: transactionSearchParam.perPage,
      from: null,
      to: null,
      filter: transactionSearchParam.transactionType,
      searchKey: null,
      columnName: "createdDate",
      sortType: null,
    }

    let response = await getAllTransactions(employerId, criteria);
    let data = response.data;

    let transactions: Transactions[] = data.records;

    let pagingInfo = setPagingInfo(
      transactionSearchParam.page,
      data.pageCount,
      transactionSearchParam.perPage
    );

    this.setState({
      transactions,
      pagingInfoWallet: pagingInfo,
    });
  }

  async UNSAFE_componentWillReceiveProps() {
    let param = this.getSearchByUrl();

    let paramSearch = this.getSearchByUrl();

    if (paramSearch.isTransaction) {
      await this.getAllTransactions(Number(param.id), param.searchParamWallet);
    } else {
      await this.getEmployeeByEmployer(Number(param.id), param.searchParam);
    }

    this.setState({
      searchParam: param.searchParam,
      searchParamWallet: param.searchParamWallet,
    });

  }

  getParamFilterWallet = (paramSearch: WalletSearchParams) => {
    let params: Filter[] = [];

    if (paramSearch.transactionType) {
      params.push({
        name: "transactionType",
        value: paramSearch.transactionType,
      });
    }

    return params;
  };

  async getEmployeeByEmployer(id: number, searchParam: SearchParamEmployee) {
    await getEmployeesByEmployer(id, searchParam).then((result) => {
      let pagingInfo = setPagingInfo(
        searchParam.page,
        result.data.pageCount,
        searchParam.perPage
      );

      this.setState({ employees: result.data.records, pagingInfo: pagingInfo });
    });
  }

  reloadEmployeeByEmployer = async () => {
    let param = this.getSearchByUrl();

    await this.getEmployeeByEmployer(this.employerId, param.searchParam);
  }

  changePage = (page: number) => {
    let searchParam: SearchParamEmployee = Object.assign(
      this.state.searchParam
    );
    searchParam.page = page;

    this.setSearchByParam(searchParam, this.state.searchParamWallet, EMPLOYEE);
  };

  changePageWallet = (page: number) => {
    let searchParamWallet: WalletSearchParams = Object.assign(
      this.state.searchParamWallet
    );
    searchParamWallet.page = page;

    this.setSearchByParam(this.state.searchParam, searchParamWallet, TRANSACTION);
  };

  findPaymentMethodById = (id: number) => {
    let payment = payments.find((paymentMethod) => {
      return paymentMethod.id === id;
    });

    return payment
      ? payment
      : {
        id: -1,
        name: "",
      };
  };

  findEnrollmentById = (id: number) => {
    let enrollmentItem = this.state.enrollments.find((enrollment) => {
      return enrollment.id === id;
    });

    return enrollmentItem ? enrollmentItem.name : "";
  };

  getSearchByUrl = () => {
    const urlParams = new URLSearchParams(window.location.search),
      id = urlParams.get("id"),
      page = urlParams.get("page"),
      columnName = urlParams.get("columnName"),
      sortType: SortType = urlParams.get("sortType") as SortType,
      rowsPerPage = urlParams.get("rowsPerPage"),
      pageWallet = urlParams.get("pageWallet"),
      rowsPerPageWallet = urlParams.get("rowsPerPageWallet"),
      transactionType = urlParams.get("transactionType"),
      component = urlParams.get("component") ? urlParams.get("component") : EMPLOYEE
      ;

    let searchParam = Object.assign({}, this.state.searchParam);
    let searchParamWallet = Object.assign({}, this.state.searchParamWallet);

    if (page) {
      searchParam.page = Number(page);
    } else {
      searchParam.page = 1;
    }
    if (columnName && sortType) {
      searchParam.columnName = columnName;
      searchParam.sortType = sortType;
    } else {
      searchParam.columnName = null;
      searchParam.sortType = null;
    }

    rowsPerPage
      ? (searchParam.perPage = Number(rowsPerPage))
      : (searchParam.perPage = this.state.searchParam.perPage);

    pageWallet
      ? (searchParamWallet.page = Number(pageWallet))
      : (searchParamWallet.page = 1);
    rowsPerPageWallet
      ? (searchParamWallet.perPage = Number(rowsPerPageWallet))
      : (searchParamWallet.perPage = 10);

    transactionType
      ? (searchParamWallet.transactionType = transactionType)
      : (searchParamWallet.transactionType = "");

    // set sort table
    let columns: SortTable[] = this.state.columns;
    let index = columns.findIndex((column) => {
      return column.columnId === searchParam.columnName;
    });
    if (index > -1) {
      columns[index].sortType = sortType;
    }

    return {
      searchParam,
      searchParamWallet,
      columns,
      id,
      isTransaction: component === 'TRANSACTION' ? true : false,
    };
  };

  setSearchByParam = (
    searchParam: SearchParamEmployee,
    searchParamWallet: WalletSearchParams,
    component: string,
  ) => {
    let url = new URL(window.location.href);

    if (searchParam.page) {
      url.searchParams.set("page", searchParam.page.toString());
    } else {
      url.searchParams.delete("page");
    }
    if (searchParam.columnName && searchParam.sortType) {
      url.searchParams.set("sortType", searchParam.sortType);
      url.searchParams.set("columnName", searchParam.columnName);
    } else {
      url.searchParams.delete("sortType");
      url.searchParams.delete("columnName");
    }

    searchParam.perPage
      ? url.searchParams.set("rowsPerPage", searchParam.perPage.toString())
      : url.searchParams.delete("rowsPerPage");

    searchParamWallet.page
      ? url.searchParams.set("pageWallet", searchParamWallet.page.toString())
      : url.searchParams.delete("pageWallet");

    searchParamWallet.transactionType
      ? url.searchParams.set(
        "transactionType",
        searchParamWallet.transactionType
      )
      : url.searchParams.delete("transactionType");

    searchParamWallet.perPage
      ? url.searchParams.set(
        "rowsPerPageWallet",
        searchParamWallet.perPage.toString()
      )
      : url.searchParams.delete("rowsPerPageWallet");

    url.searchParams.set("component", component);

    this.props.history.push(url.search);
  };

  sortTable = (columnId: string) => {
    let columnsHeaderTable: SortTable[] = Object.assign(this.state.columns);
    let searchParam = Object.assign({}, this.state.searchParam);
    searchParam.columnName = columnId;
    let index = columnsHeaderTable.findIndex((column) => {
      return column.columnId === columnId;
    });

    columnsHeaderTable.forEach((column, i) => {
      if (i === index) {
        column.sortType = column.sortType === "ASC" ? "DESC" : "ASC";
        searchParam.sortType = column.sortType;
        return;
      }
      column.sortType = null;
    });

    this.setSearchByParam(searchParam, this.state.searchParamWallet, EMPLOYEE);
  };

  setRowsPerPage = (event: any) => {
    const { value } = event.target;

    let searchParam = Object.assign({}, this.state.searchParam);
    searchParam.page = 1;
    searchParam.perPage = Number(value);

    // set url search
    this.setSearchByParam(searchParam, this.state.searchParamWallet, EMPLOYEE);
  };

  setRowsPerPageWallet = (event: any) => {
    const { value } = event.target;

    let searchParamWallet = Object.assign({}, this.state.searchParamWallet);
    searchParamWallet.page = 1;
    searchParamWallet.perPage = Number(value);

    // set url search
    this.setSearchByParam(this.state.searchParam, searchParamWallet, TRANSACTION);
  };

  findProvinceById = (id: number) => {
    let result = this.state.provinces.find((province) => {
      return province.id === id;
    });

    return result ? result.name : "";
  };

  //#region edit company info
  handleAdditionalStreet = (event: any): void => {
    let isValid = this.validate(
      this.state.companyInfoEdit,
      event.target.checked
    );
    this.setState({
      isAdditionalStreet: event.target.checked,
      isValidCompanyInfo: isValid,
    });
  };

  handleChangInput = (event: any) => {
    const companyInformation: CompanyDataEdit = Object.assign({}, this.state.companyInfoEdit);
    if (
      event.target.name === "companyPhoneNumber" ||
      event.target.name === "companyPhoneNumber2"
    ) {
      companyInformation[event.target.name] = formatValueInputMask(event.target.value);
    } else if (event.target.name === "noOfEmployees") {
      companyInformation.noOfEmployees = Number(event.target.value);
    } else {
      companyInformation[event.target.name] = event.target.value;
    }

    const isValid = this.validate(
      companyInformation,
      this.state.isAdditionalStreet
    );

    this.setState({
      companyInfoEdit: companyInformation,
      isValidCompanyInfo: isValid,
    });
  };

  handleChangSelect = (event: any) => {
    const { name, value } = event.target;

    let companyInformation = Object.assign(this.state.companyInfoEdit);

    companyInformation[name] = value;
    let isValid = this.validate(
      companyInformation,
      this.state.isAdditionalStreet
    );
    this.setState({
      companyInfoEdit: companyInformation,
      isValidCompanyInfo: isValid,
    });
  };

  validate = (
    companyInfoEdit: CompanyDataEdit,
    isAdditionalStreet: boolean
  ) => {
    if (
      isAdditionalStreet &&
      companyInfoEdit.companyName &&
      this.validEmailString(companyInfoEdit.fundRelatedEmails) &&
      this.validEmailString(companyInfoEdit.employeeClaimRelatedEmails) &&
      companyInfoEdit.companyPhoneNumber &&
      companyInfoEdit.noOfEmployees &&
      companyInfoEdit.provinceId &&
      companyInfoEdit.cityName &&
      companyInfoEdit.postcode
    ) {
      return true;
    } else if (
      !isAdditionalStreet &&
      companyInfoEdit.companyName &&
      this.validEmailString(companyInfoEdit.fundRelatedEmails) &&
      this.validEmailString(companyInfoEdit.employeeClaimRelatedEmails) &&
      companyInfoEdit.companyPhoneNumber &&
      companyInfoEdit.noOfEmployees &&
      companyInfoEdit.companyPhoneNumber2 &&
      companyInfoEdit.provinceId &&
      companyInfoEdit.cityName &&
      companyInfoEdit.postcode &&
      companyInfoEdit.provinceId2 &&
      companyInfoEdit.cityName2 &&
      companyInfoEdit.postcode2
    ) {
      return true;
    }

    return false;
  };

  validEmailString = (emails: string | undefined) => {
    if (!emails) return false;
    const emailArray = emails.split(';');
    return emailArray.length > 0 && emailArray.every(email => email.trim().match(validateEmail));
  }

  handleSubmit = () => {
    let companyInfo = !this.state.isAdditionalStreet
      ? this.state.companyInfoEdit
      : Object.assign({}, this.state.companyInfoEdit, {
        streetAddress2: this.state.companyInfoEdit.streetAddress,
        cityName2: this.state.companyInfoEdit.cityName,
        provinceId2: this.state.companyInfoEdit.provinceId,
        postcode2: this.state.companyInfoEdit.postcode,
        companyPhoneNumber2: this.state.companyInfoEdit.companyPhoneNumber,
      });

    companyInfo.referralPartnerUserId =
      companyInfo.referralPartnerUserId == NA_OPTION_VALUE ? null : companyInfo.referralPartnerUserId;

    putCompanyInfo(companyInfo).then(() => {
      closeCompanyInformationModal();
      publishMessage({
        message: "Your company information has been update successfully.",
        variant: "success",
      });

      let data = this.setCompanyInfo(companyInfo);

      this.setState({ companyInfos: data, companyInfoEdit: companyInfo });
    });
  };

  setCompanyInfo = (companyInfoEdit: CompanyDataEdit) => {
    let companyInfo: DataDetailCompany = { ...this.state.companyInfos };

    companyInfo.companyInformation.companyName = companyInfoEdit.companyName;
    companyInfo.companyInformation.companyPhoneNumber =
      companyInfoEdit.companyPhoneNumber;
    companyInfo.companyInformation.companyPhoneNumber2 =
      companyInfoEdit.companyPhoneNumber2;
    companyInfo.companyInformation.noOfEmployees =
      companyInfoEdit.noOfEmployees;
    companyInfo.companyInformation.referralPartnerId = companyInfoEdit.referralPartnerId;
    companyInfo.companyInformation.referralPartnerUserId = companyInfoEdit.refferalPartnerUserId;
    companyInfo.companyInformation.soleProprietor =
      companyInfoEdit.soleProprietor;
    companyInfo.companyInformation.cityName = companyInfoEdit.cityName;
    companyInfo.companyInformation.cityName2 = companyInfoEdit.cityName2;
    companyInfo.companyInformation.employerId = companyInfoEdit.id;
    companyInfo.companyInformation.postcode = companyInfoEdit.postcode;
    companyInfo.companyInformation.postcode2 = companyInfoEdit.postcode2;
    companyInfo.companyInformation.provinceId = companyInfoEdit.provinceId;
    companyInfo.companyInformation.provinceId2 = companyInfoEdit.provinceId2;
    companyInfo.companyInformation.streetAddress =
      companyInfoEdit.streetAddress;
    companyInfo.companyInformation.streetAddress2 =
      companyInfoEdit.streetAddress2;

    return companyInfo;
  };

  setCompanyDataEdit = (companyInfoDetail: CompanyInfoDetail) => {
    return {
      id: companyInfoDetail.employerId,
      companyName: companyInfoDetail.companyName,
      companyEmail: companyInfoDetail.companyEmail || "",
      fundRelatedEmails: companyInfoDetail.fundRelatedEmails || "",
      employeeClaimRelatedEmails: companyInfoDetail.employeeClaimRelatedEmails || "",
      firstName: companyInfoDetail.firstName || "",
      lastName: companyInfoDetail.lastName || "",
      companyPhoneNumber: companyInfoDetail.companyPhoneNumber,
      companyPhoneNumber2: companyInfoDetail.companyPhoneNumber2,
      noOfEmployees: companyInfoDetail.noOfEmployees,
      referralPartnerId: companyInfoDetail.referralPartnerId,
      referralPartnerName: companyInfoDetail.referralPartnerName,
      referralPartnerUserId: companyInfoDetail.referralPartnerUserId,
      referralPartnerUserName: companyInfoDetail.referralPartnerUserName,
      soleProprietor: companyInfoDetail.soleProprietor,
      cityName: companyInfoDetail.cityName,
      cityName2: companyInfoDetail.cityName2,
      postcode: companyInfoDetail.postcode,
      postcode2: companyInfoDetail.postcode2,
      provinceId: companyInfoDetail.provinceId,
      provinceId2: companyInfoDetail.provinceId2,
      streetAddress: companyInfoDetail.streetAddress,
      streetAddress2: companyInfoDetail.streetAddress2,
    };
  };

  setAddress = (address: string) => {
    let companyInformation: CompanyDataEdit = Object.assign(
      this.state.companyInfoEdit
    );
    companyInformation.streetAddress = address;

    let isValid = this.validate(
      companyInformation,
      this.state.isAdditionalStreet
    );

    this.setState({ companyInfoEdit: companyInformation, isValid: isValid });
  };

  setLocation = (location: ResponseLocation) => {
    let companyInformation: CompanyDataEdit = Object.assign(
      this.state.companyInfoEdit
    );

    companyInformation.cityName = location.city;
    companyInformation.postcode = location.postcode ? location.postcode : "";
    companyInformation.provinceId = findProvinceIdByName(
      location.province,
      this.state.provinces
    );

    let isValid = this.validate(
      companyInformation,
      this.state.isAdditionalStreet
    );

    this.setState({ companyInfoEdit: companyInformation, isValid: isValid });
  };

  setAddress2 = (address: string) => {
    let companyInformation: CompanyDataEdit = Object.assign(
      this.state.companyInfoEdit
    );
    companyInformation.streetAddress2 = address;

    let isValid = this.validate(
      companyInformation,
      this.state.isAdditionalStreet
    );

    this.setState({ companyInfoEdit: companyInformation, isValid: isValid });
  };

  setLocation2 = (location: ResponseLocation) => {
    let companyInformation: CompanyDataEdit = Object.assign(
      this.state.companyInfoEdit
    );

    companyInformation.cityName2 = location.city;
    companyInformation.postcode2 = location.postcode ? location.postcode : "";
    companyInformation.provinceId2 = findProvinceIdByName(
      location.province,
      this.state.provinces
    );

    let isValid = this.validate(
      companyInformation,
      this.state.isAdditionalStreet
    );

    this.setState({ companyInfoEdit: companyInformation, isValid: isValid });
  };

  //#endRegion edit company info

  //#region edit banking info

  async getFileUrl(url: string) {
    await getFileUrl(url).then((response) => {
      this.setState({ fileUrl: response.data, fileUrlEdit: response.data });
    });
  }
  // change payment method
  handleChangePaymentMethod = (id: number) => {
    this.setState({ paymentMethod: id });
  };

  handleChangeInputChequeInfo = (event: any) => {
    const { name, value } = event.target;

    let bankingInfo: BankingInformation = Object.assign({}, this.state.bankingInfoEdit);

    if (name !== "eSignature") {
      if (Number(value) >= 0) {
        bankingInfo[name] = value;
      }
    } else {
      bankingInfo[name] = value;
    }

    this.setState({ bankingInfoEdit: bankingInfo });
  };

  handleChangeCheckboxChequeInfo = (event: any) => {
    const { name, checked } = event.target;
    let bankingInfoEdit = Object.assign({}, this.state.bankingInfoEdit);

    if (checked) {
      bankingInfoEdit.agreements.push(Number(name));
    } else {
      let index = bankingInfoEdit.agreements.findIndex((agreement: any) => {
        return agreement === Number(name);
      });

      bankingInfoEdit.agreements.splice(index, 1);
    }

    this.setState({ bankingInfoEdit });
  };

  handleChangeESignatureDate = (date: Date | null, name: string) => {
    const bankingInfoEditClone = {...this.state.bankingInfoEdit};
    bankingInfoEditClone.date = date ? date : new Date();
    this.setState({bankingInfoEdit: bankingInfoEditClone})
  }

  handleChangeVoidCheque = (urlImage: string) => {
    let bankingInfoEdit: BankingInformation = Object.assign({}, this.state.bankingInfoEdit);
    if (urlImage) {
      bankingInfoEdit.voidCheque = urlImage;

      getFileUrl(urlImage).then((response) => {
        this.setState({ fileUrlEdit: response.data });
      });

      this.setState({ bankingInfoEdit });
    }
  };

  handleOCRCheque = (urlBlob: string) => {
    //addLoading();
    OCRCheque(urlBlob, "../../tessdata", (res: any) => {
      if (!res.error) {
        const newCheque: BankingInformation = { ...this.state.bankingInfoEdit };
        newCheque.transitNumber = res.numbers.transit;
        newCheque.bankNumber = res.numbers.institution;
        newCheque.accountNumber = res.numbers.account;

        this.setState({ bankingInfoEdit: newCheque });
      }
      //removeLoading();
    });
  };

  handleSubmitBankingInfo = async () => {
    let model = {
      paymentMethod: this.state.paymentMethod,
      pdfServiceAgreement: "",
      chequeInfo: { ...this.state.bankingInfoEdit, authorizedEmail: null }
    };

    await putBankingInfo(this.state.companyInfos.id, model)
    publishMessage({
      variant: "success",
      message: "Your payment information has been update successfully.",
    });

    // set banking info edit to companyInfos
    this.setBankingInfoToCompanyInfos();

    closeUpdateBankingInformationModal();
  };

  setBankingInfoToCompanyInfos = () => {
    let companyInfos = Object.assign({}, this.state.companyInfos);

    companyInfos.bankingInfo.paymentMethod = this.state.paymentMethod;

    if (this.state.paymentMethod === 2) {
      companyInfos.bankingInfo.accountNumber = this.state.bankingInfoEdit.accountNumber;
      companyInfos.bankingInfo.bankNumber = this.state.bankingInfoEdit.bankNumber;
      companyInfos.bankingInfo.eSignature = this.state.bankingInfoEdit.eSignature;
      companyInfos.bankingInfo.transitNumber = this.state.bankingInfoEdit.transitNumber;
      companyInfos.bankingInfo.voidChequeUrl = this.state.bankingInfoEdit.voidCheque;
    }

    this.setState({ companyInfos, fileUrl: this.state.fileUrlEdit });
  };

  validateBankingInfo = () => {
    return !!((this.state.paymentMethod === 2 &&
        this.state.bankingInfoEdit.accountNumber &&
        this.state.bankingInfoEdit.bankNumber &&
        this.state.bankingInfoEdit.eSignature &&
        this.state.bankingInfoEdit.transitNumber &&
        this.state.bankingInfoEdit.agreements.length === this.state.agreementData.length &&
        this.state.bankingInfoEdit.voidCheque) ||
      (this.state.paymentMethod !== 2 && this.state.paymentMethod !== -1));
  };

  showUpdateBankingInformationModal = () => {

    if (this.props.enablePresetBankingInformation) return;

    let paymentMethod = -1;

    if (this.state.companyInfos.bankingInfo) {
      paymentMethod = this.state.companyInfos.bankingInfo.paymentMethod;
    }

    this.setState({ paymentMethod });
    showUpdateBankingInformationModal();
  };
  //#endRegion edit banking info

  //#region edit employer plans and classes
  showPlansAndClassesModal = (employerPlanId: number) => {
    this.setState({ openEmployerPlanIdModal: employerPlanId });
  }

  handleClosePlansAndClassesModal = () => {
    this.setState({ openEmployerPlanIdModal: 0 });
  }
  //#endRegion edit employer plans and classes

  showModalRejectEmployer = () => {
    this.setState({ isAdjustment: false });
    showConfirmModal();
  };

  //#endRegion Classes and Medical & Travel
  handleChangeInputDeposit = (event: React.ChangeEvent<HTMLInputElement>) => {
    let credit = Object.assign(this.state.adjustment);
    let { name, value } = event.target;
    credit[name] = value;

    this.setState({
      adjustment: credit,
    });
  };

  showDeposit = () => {
    this.setState({ isAdjustment: true, isRefund: false });
    showCreditModal();
  };

  showRefund = () => {
    this.setState({ isAdjustment: false, isRefund: true });
    showRefundModal();
  };

  showPayAutoTopUpPending = async () => {
    addLoading();
    const result = await getAutoTopUpPending(this.state.companyInfos.id);
    this.setState({
      autoDepositPending: result.data,
      openPayAutoTopUpPending: true
    });
    removeLoading();
  }

  handleClosePayAutoTopUpPending = () => {
    this.setState({ openPayAutoTopUpPending: false });
  }

  handleSubmitPayAutoTopUpPending = async (productId: string) => {
    const [depositGroupCode, productName] = productId.split('-');
    const modal: PayAutoTopUpPendingType = {
      employerId: this.state.companyInfos.id,
      depositGroupCode,
      productName
    };

    addLoading();
    await postPayAutoTopUpPending(modal);
    await this.getAllBalances(this.state.companyInfos.id);
    this.handleClosePayAutoTopUpPending();
    removeLoading();

  }

  handleSubmitDeposit = () => {
    showConfirmModal();
  };

  checkSubmitAdjustment = () => {
    let adjustment = Object.assign({}, this.state.adjustment);

    let valid = this.validateAdjustment(adjustment);

    // save credit
    if (valid.status === 1) {
      this.cashCredit(adjustment);
    }

    // save debit
    if (valid.status === 2) {
      this.cashDebit(adjustment);
    }

    // save both
    if (valid.status === 3) {
      this.cashCredit(adjustment);
      this.cashDebit(adjustment);
    }
  };

  cashCredit = (adjustmentData: Adjustment) => {
    let credit: Credit = {
      amount: usdToCent(adjustmentData.credit),
      desc: `${this.state.companyInfos.name} credit ${formatterUSD(
        "currency"
      ).format(adjustmentData.credit)}${this.state.adjustment.note ? ` - ${this.state.adjustment.note}` : ""}`,
      targetId: this.state.companyInfos.id,
    };

    postCashCreditEmployer(credit).then(() => {
      this.getAllBalances(this.state.companyInfos.id);

      publishMessage({
        message: "Save credit success",
        variant: "success",
      });

      let adjustment: Adjustment = {
        credit: 0,
        debit: 0,
        id: 0,
        note: "",
      };

      closeCreditModal();

      let param = this.getSearchByUrl();
      this.getAllTransactions(this.employerId, param.searchParamWallet);

      removeLoading();
      this.setState({ isAdjustment: true, adjustment: adjustment });
    });
  };

  cashDebit = (adjustmentData: Adjustment) => {
    let debit: Credit = {
      amount: usdToCent(adjustmentData.debit),
      desc: `${this.state.companyInfos.name} debit ${formatterUSD(
        "currency"
      ).format(adjustmentData.debit)}${this.state.adjustment.note ? ` - ${this.state.adjustment.note}` : ""}`,
      targetId: this.state.companyInfos.id,
    };

    postCashDebitEmployer(debit).then(() => {
      this.getAllBalances(this.state.companyInfos.id);

      publishMessage({
        message: "Save debit success",
        variant: "success",
      });

      let adjustment: Adjustment = {
        credit: 0,
        debit: 0,
        id: 0,
        note: "",
      };

      closeCreditModal();

      let param = this.getSearchByUrl();
      this.getAllTransactions(this.employerId, param.searchParamWallet);

      removeLoading();
      this.setState({ isAdjustment: true, adjustment: adjustment });
    });
  };

  validateAdjustment = (adjustment: Adjustment) => {
    // status === 1 credit, 2 debit, 3 both
    let response = {
      message: "",
      status: 0,
    };

    if (adjustment.credit && adjustment.debit) {
      response.status = 3;
      response.message = `${this.state.companyInfos.name
        }'s wallet will be credit ${formatterUSD("currency").format(
          adjustment.credit
        )}
      and we will deduct ${formatterUSD("currency").format(
          adjustment.debit
        )} from their wallet.
      <br />
      Do you want to continue?`;
    }
    if (adjustment.credit && !adjustment.debit) {
      response.status = 1;
      response.message = `${this.state.companyInfos.name
        }'s wallet will be credit ${formatterUSD("currency").format(
          adjustment.credit
        )}.
      <br />
      Do you want to continue?`;
    }
    if (!adjustment.credit && adjustment.debit) {
      response.status = 2;
      response.message = `System will deduct ${formatterUSD("currency").format(
        adjustment.debit
      )} from ${this.state.companyInfos.name}'s wallet.
      <br />
      Do you want to continue?`;
    }

    return response;
  };

  changeFilterWallet = (event: any) => {
    let { name, value } = event.target,
      searchParam = Object.assign(this.state.searchParamWallet);

    if (Number(value) === -1) value = null;

    searchParam[name] = value;

    searchParam.page = 1;

    // set url search
    this.setSearchByParam(this.state.searchParam, searchParam, 'TRANSACTION');

    this.setState({ searchParamWallet: searchParam });
  };

  handleRefundSubmit = async () => {
    let param = this.getSearchByUrl();
    let employerId = Number(param.id);
    addLoading()
    this.setState({
      isRefund: false
    })

    const model = {
      partnerId: getInfoByToken().mainPartnerId,
      referenceId: generateUUID(),
      employerId: employerId,
      withdrawAmount: usdToCent(this.state.amount)
    }

    const res = await requestRefundForEmployer(model);
    if (res.status === 200) {
      await this.getAllBalances(employerId);

      let param = this.getSearchByUrl();
      await this.getAllTransactions(employerId, param.searchParamWallet);
    }

    removeLoading()
  }

  handleChangeInputRefund = (event: any) => {
    const { value } = event.target;

    this.setState({
      amount: Number(value)
    })
  }

  setIsT4Employee = (val: boolean) => {
    this.setState({
      isT4Employee: val
    })
  }

  handleCreateEmployee = async (data: EmployeeModal) => {
    let res;
    if (!this.state.isT4Employee) {
      res = await createNotT4EmployeeByAdmin(data);
    } else {
      res = await createEmployeeByAdmin(data);
    }
    if (res.status === 200) {
      publishMessage({
        message: "Create employee success",
        variant: "success"
      });
      let param = this.getSearchByUrl();
      await this.getEmployeeByEmployer(Number(this.employerId), param.searchParam);
      this.setState({
        isT4Employee: true
      })
      return true;
    }
    return false;
  }

  handleSubmitChangeStatus = async (toStatus: EnableStatusEnum) => {
    const resp = await changeEmployerStatus(this.state.companyInfos.id, toStatus);
    if (resp.status === 202) {
      let data = Object.assign(this.state.companyInfos);
      data.status = EnableStatusEnum[toStatus];
      await this.reloadEmployeeByEmployer();
      removeLoading();
      this.setState({ companyInfos: data });
      message(`The employer has been ${EnableStatusEnum[toStatus]}!`, "success");
    }
  }

  handleChangeReferralPartner = async (event: any, option: any) => {
    if (!!option && !!option.id && option.id !== -1 && option.id !== this.state.companyInfoEdit.referralPartnerId) {
      const referralPartnerUsersRes = await getReferralPartnerUserOptions(option.id);

      let referralPartnerUsers = getRemakedReferralPartnerUsers(option, referralPartnerUsersRes.data);
      let referralPartnerUserId = getDefaultSelectedPartnerUserId(option, referralPartnerUsers);

      const companyInfoEdit = { ...this.state.companyInfoEdit, referralPartnerId: option.id, referralPartnerUserId }
      let isValidCompanyInfo = this.validate(companyInfoEdit, this.state.isAdditionalStreet);
      this.setState({ companyInfoEdit, referralPartnerUsers: referralPartnerUsers || [], isValidCompanyInfo })
    } else {
      const companyInfoEdit = { ...this.state.companyInfoEdit, referralPartnerId: null, referralPartnerUserId: null }
      let isValidCompanyInfo = this.validate(companyInfoEdit, this.state.isAdditionalStreet);
      this.setState({ companyInfoEdit, referralPartnerUsers: [], isValidCompanyInfo })
    }

  }

  handleChangeReferralPartnerUser = (event: any, option: any) => {
    if (!!option && !!option.id && option.id !== -1 && option.id !== this.state.companyInfoEdit.referralPartnerUserId) {
      const companyInfoEdit = { ...this.state.companyInfoEdit, referralPartnerUserId: option.id }
      let isValidCompanyInfo = this.validate(companyInfoEdit, this.state.isAdditionalStreet);
      this.setState({ companyInfoEdit, isValidCompanyInfo })
    } else {
      const companyInfoEdit = { ...this.state.companyInfoEdit, referralPartnerUserId: null }
      let isValidCompanyInfo = this.validate(companyInfoEdit, this.state.isAdditionalStreet);
      this.setState({ companyInfoEdit, isValidCompanyInfo })
    }
  }


  render() {
    return (
      <div>
        <CompanyManagementDetailView
          data={this.state.companyInfos}
          employees={this.state.employees}
          getEmployees={this.reloadEmployeeByEmployer}
          pagingInfo={this.state.pagingInfo}
          findPaymentMethodById={this.findPaymentMethodById}
          isLoadingTableEmployees={this.state.isLoadingTableEmployees}
          changePage={this.changePage}
          columns={this.state.columns}
          sortTable={this.sortTable}
          setRowsPerPage={this.setRowsPerPage}
          findProvinceById={this.findProvinceById}
          findEnrollmentById={this.findEnrollmentById}
          showUpdateBankingInformationModal={
            this.showUpdateBankingInformationModal
          }
          showPlansAndClassesModal={this.showPlansAndClassesModal}
          //Deposit
          showDeposit={this.showDeposit}
          availableAmount={this.state.availableAmount}
          pendingAmount={this.state.pendingAmount}
          transactions={this.state.transactions}
          showModalRejectEmployer={this.showModalRejectEmployer}
          //banking info
          fileUrl={this.state.fileUrl}
          changePageWallet={this.changePageWallet}
          pagingInfoWallet={this.state.pagingInfoWallet}
          setRowsPerPageWallet={this.setRowsPerPageWallet}
          searchParamWallet={this.state.searchParamWallet}
          changeFilterWallet={this.changeFilterWallet}
          classes={this.state.classes}
          handleChangeInputRefund={this.handleChangeInputRefund}
          amount={this.state.amount}
          showRefund={this.showRefund}
          showAutoTopUpPending={this.showPayAutoTopUpPending}
          onSubmitChangeStatus={this.handleSubmitChangeStatus}
          enablePresetBankingInformation={this.props.enablePresetBankingInformation}
          partnerOptions={this.state.partnerOptions}
        />
        <EmployerCreditView
          handleChangeInput={this.handleChangeInputDeposit}
          adjustment={this.state.adjustment}
          handleSubmit={this.handleSubmitDeposit}
        />
        <ConfirmDialog
          content={
            this.state.isRefund ? ("Are you sure you want to refund for this user?") :
              (
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.validateAdjustment(this.state.adjustment)
                      .message,
                  }}
                ></div>
              )
          }
          title={
            this.state.isRefund ? ("Confirm employer refund") : "Confirm employer adjustment"
          }
          handleOnOk={async () => {
            if (this.state.isRefund) {
              closeConfirmModal()
              closeRefundModal()
              await this.handleRefundSubmit()
            } else {
              if (this.state.isAdjustment) this.checkSubmitAdjustment()
            }
          }
          }
        />

        {this.state.partnerOptions.allowEditEmployer &&
          <CompanyInformationModal
            companyInformation={this.state.companyInfoEdit}
            handleAdditionalStreet={this.handleAdditionalStreet}
            handleChangInput={this.handleChangInput}
            handleChangSelect={this.handleChangSelect}
            handleSubmit={this.handleSubmit}
            isAdditionalStreet={this.state.isAdditionalStreet}
            isValid={this.state.isValidCompanyInfo}
            provinces={this.state.provinces}
            //
            setAddress={this.setAddress}
            setLocation={this.setLocation}
            setAddress2={this.setAddress2}
            setLocation2={this.setLocation2}
            //
            referralPartners={this.state.referralPartners}
            referralPartnerUsers={this.state.referralPartnerUsers}
            handleChangeReferralPartner={this.handleChangeReferralPartner}
            handleChangeReferralPartnerUser={this.handleChangeReferralPartnerUser}
            allowEditReferralPartner={this.state.partnerOptions.allowEditReferralPartner}
            allowEditReferralPartnerUser={this.state.partnerOptions.allowEditReferralPartnerUser}
        />
        }
        {this.state.partnerOptions.allowEditBankingInfo &&
          <UpdateBankingInformationModal
            bankingInfoEdit={this.state.bankingInfoEdit}
            handleChangeInputChequeInfo={this.handleChangeInputChequeInfo}
            handleChangePaymentMethod={this.handleChangePaymentMethod}
            handleChangeVoidCheque={this.handleChangeVoidCheque}
            handleOCRCheque={this.handleOCRCheque}
            paymentMethod={this.state.paymentMethod}
            validateBankingInfo={this.validateBankingInfo}
            handleSubmitBankingInfo={this.handleSubmitBankingInfo}
            agreementData={this.state.agreementData}
            fileUrlEdit={this.state.fileUrlEdit}
            handleChangeCheckbox={this.handleChangeCheckboxChequeInfo}
            handleChangeDate={this.handleChangeESignatureDate}
            enablePresetBankingInformation={this.props.enablePresetBankingInformation}
          />
        }
        <EmployeePopup
          data={defaultEmployee}
          isCreate={true}
          handleSubmit={this.handleCreateEmployee}
          isT4Employee={this.state.isT4Employee}
          setIsT4Employee={this.setIsT4Employee}
        />
        {this.state.partnerOptions.allowRefund &&
          <PayAutoTopUpPendingModal
            open={this.state.openPayAutoTopUpPending}
            onClose={this.handleClosePayAutoTopUpPending}
            pendingTopUp={this.state.autoDepositPending}
            handleSubmit={this.handleSubmitPayAutoTopUpPending}
            availableAmount={this.state.availableAmount}
          />
        }
        <PlanModal
          open={this.state.openEmployerPlanIdModal > 0}
          title={"Plan and Classes"}
          handleClose={this.handleClosePlansAndClassesModal}
          employerPlans={this.state.companyInfos.employerPlans}
          employerPlanId={this.state.openEmployerPlanIdModal}
          enrollments={this.state.enrollments}
          planPreferences={this.state.planPreferences}
          history={this.props.history}
          location={this.props.location}
          match={this.props.match}
        />
      </div>
    );
  }
}

export function findProvinceIdByName(
  provinceName: string,
  provinces: Provinces[]
) {
  let province = provinces.find((f) => {
    return compareStrings(f.name, provinceName);
  });

  return province ? province.id : -1;
}
import { SortTable } from "../../../cores/models/SortTable";

export const statusDepositWithdraw = [
  {
    id: "PENDING",
    name: "Pending",
  },
  {
    id: "APPROVED",
    name: "Approved",
  },
];

export const columnsDepositWithdrawTransactions = (): SortTable[] => {
  return [
    {
      columnId: "firstName",
      columnName: "Name",
      sortType: null,
      width: 15,
    },
    {
      columnId: "type",
      columnName: "Type",
      sortType: null,
      width: 10,
    },
    {
      columnId: "amount",
      columnName: "amount",
      sortType: null,
      width: 10,
    },
    {
      columnId: "sundryNumber",
      columnName: "sundry Number",
      sortType: null,
      width: 12,
    },
    {
      columnId: "transferType",
      columnName: "Transfer Type",
      sortType: null,
      width: 12,
    },
    {
      columnId: "confirmImage",
      columnName: "Confirm Image",
      sortType: null,
      width: 12,
    },
    {
      columnId: "date",
      columnName: "Date",
      sortType: null,
      width: 8,
    },
  ];
};

export const columnsDepositWithdrawTransactionsPending: SortTable[] = [
  {
    columnId: "firstName",
    columnName: "Name",
    sortType: null,
    width: 20,
  },
  {
    columnId: "type",
    columnName: "Type",
    sortType: null,
    width: 20,
  },
  {
    columnId: "amount",
    columnName: "amount",
    sortType: null,
    width: 15,
  },
  {
    columnId: "sundryNumber",
    columnName: "sundry Number",
    sortType: null,
    width: 15,
  },
  {
    columnId: "date",
    columnName: "Date",
    sortType: null,
    width: 8,
  }
]

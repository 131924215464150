import React from "react";
import styled from "styled-components";
import {
  StyledHeaderModal,
  StyledHeaderTitleModal,
  StyledButtonCloseModal,
  StyledIconCloseModal,
  StyledButtonSubmit,
  LandingStyledTextValidator,
} from "../../shared/styled";
import { SignUpModel } from "../../../services/auth-service";
import { ValidatorForm } from "react-material-ui-form-validator";
import { validateEmail } from "../../../cores/config/config";
import { BNPSelect } from "../../../cores/helpers/select/select";
import { getInfoByToken } from "../../../cores/utils/helpers";

const StyledModalDialog = styled.div`
  max-width: 600px;
  margin: 1.75rem auto;
  position: relative;
  width: auto;
  pointer-events: none;
  text-align: left;
`;

type Props = {
  newAdmin: SignUpModel;
  isValid: boolean;
  handleChangInputAddNewPartner: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleAddNewPartner: () => void;
  handleChangeSelect: (event: any) => void;
};

export default function AddNewAdminModal(props: Props) {
  return (
    <div className="modal" id="addNewAdminModal">
      <StyledModalDialog role="document">
        <div className="modal-content">
          <StyledHeaderModal className="modal-header">
            <StyledHeaderTitleModal>Add new admin</StyledHeaderTitleModal>
            <StyledButtonCloseModal
              type="button"
              onClick={closeAddNewAdminModal}
            >
              <StyledIconCloseModal>&times;</StyledIconCloseModal>
            </StyledButtonCloseModal>
          </StyledHeaderModal>
          <div className="modal-body">
            <ValidatorForm
              className="w-100"
              onSubmit={props.handleAddNewPartner}
            >
              <div className="row">
                <div className="col-6 mt-3">
                  <div className="mb-2">First name</div>

                  <LandingStyledTextValidator
                    value={props.newAdmin.firstName}
                    onChange={props.handleChangInputAddNewPartner}
                    name="firstName"
                    type="text"
                    placeholder="First name"
                    validators={["required"]}
                    errorMessages={["Please enter first name"]}
                  />
                </div>
                <div className="col-6 mt-3">
                  <div className="mb-2">Last name</div>
                  <LandingStyledTextValidator
                    value={props.newAdmin.lastName}
                    onChange={props.handleChangInputAddNewPartner}
                    type="text"
                    name="lastName"
                    placeholder="Last name"
                    validators={["required"]}
                    errorMessages={["Please enter last name"]}
                  />
                </div>
                <div className="col-6 mt-3">
                  <div className="mb-2">Email</div>
                  <LandingStyledTextValidator
                    value={props.newAdmin.email}
                    onChange={props.handleChangInputAddNewPartner}
                    type="text"
                    name="email"
                    placeholder="Email"
                    validators={["required", `matchRegexp:${validateEmail}`]}
                    errorMessages={["Please enter email", "Email is not valid"]}
                    //validatorListener={props.validatorListener}
                  />
                  {/* <StyledPError className="mb-0" hidden={props.isEmailRegistered}>
                  Email already registered.
                </StyledPError> */}
                </div>
                <div className="col-6 mt-3">
                  <div className="mb-2">Role</div>
                  <BNPSelect
                    options={[
                      { id: "super admin", name: "Super Admin" },
                      { id: "admin", name: "Admin" },
                    ]}
                    disabled={getInfoByToken().permission !== "super admin"}
                    value={props.newAdmin.roleName || -1}
                    onChange={props.handleChangeSelect}
                    name="roleName"
                    placeholder="Role"
                  />
                </div>
                <div className="col-12 mt-3">
                  <hr />
                </div>
              </div>
            </ValidatorForm>

            <div className="row">
              <div className="col-12">
                <div className="col-lg-3 p-0 float-right mt-3 ml-2">
                  <StyledButtonSubmit
                    type="submit"
                    submit={props.isValid}
                    disabled={!props.isValid}
                    onClick={props.handleAddNewPartner}
                  >
                    Save
                  </StyledButtonSubmit>
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledModalDialog>
    </div>
  );
}

export function showAddNewAdminModal() {
  ($("#addNewAdminModal") as any).modal({
    show: true,
    keyboard: false,
    backdrop: "static",
  });
}
export function closeAddNewAdminModal() {
  ($("#addNewAdminModal") as any).modal("hide");
}

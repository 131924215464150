import React, {useState, useEffect} from "react";
import styled, {css} from "styled-components";
import { RouteChildrenProps } from "react-router";
import { StyledButtonSubmit, StyledChangeDetail, StyledContentBox, StyledExpansionLabel, StyledExpansionPanelDetails, StyledExpansionPanelSummary, StyledHideForDesktop, StyledHideForMobile, StyledMainContent, StyledSpanExpansionEdit, StyledTitleExpansion } from "../../../shared/styled";
import { StyledDivBoxTable } from "../../company-management/detail-employee/detail-employee-view";
import { SortTable } from "../../../../cores/models/SortTable";
import { StyledArrowDropDownIcon, StyledArrowDropUpIcon, StyledCellTable, StyledHeaderTable, StyledImportExportIcon, StyledLinkBodyTable, StyledTable } from "../../../shared/table-styled";
import { RequiredTopUpReportInfo } from "../required-topup-report-type";
import { companyManagementDetailURL, companySearchParamDetailDefault } from "../../../../cores/constants/url-config";
import { FE_DATE_FORMAT } from "../../../../cores/utils/format/date-time-format";
import { convertTimeZone } from "../../../../cores/utils/helpers";
import Pagination, { PagingInfo } from "../../../../cores/helpers/pagination/pagination";
import { ExpansionPanel } from "@material-ui/core";
import { ArrowDropUp } from "@material-ui/icons";
import { formatterUSD } from "../../../../cores/helpers/format-usd";
import { centToUsd } from "../../../../cores/helpers/cent-to-usd";



type Props = RouteChildrenProps & {
    columns: SortTable[],
    requiredTopUpReportData: RequiredTopUpReportInfo[],
    pagingInfo: PagingInfo,
    handleChangePage: (page: number) => void,
    setRowsPerPage: (event: any) => void;
    sortTable: (columnId: string) => void;
    openModalCredit: (event: any, topUpAmount: number, employerId: number) => void;

};
type State = {
  
};

export function InsufficientFund(props: Props) {


    return (
        <>
            <StyledMainContent>
                <div className="container-fluid p-0 mt-3">
                <StyledContentBox>
                    <div className="row">
                    <StyledDivBoxTable className="col-lg-12 mt-4">
                        <StyledHideForMobile>
                        <StyledTable>
                            <StyledHeaderTable>
                            {props.columns.map((column, index) => (
                                <StyledCellTable
                                width={column.width}
                                key={index}
                                className="cur"
                                onClick={() => props.sortTable(column.columnId)}
                                >
                                {column.columnName}
                                <span>
                                    {column.sortColumn && (column.sortType 
                                    ? (column.sortType === "ASC" ? <StyledArrowDropDownIcon /> : <StyledArrowDropUpIcon /> ) 
                                    : <StyledImportExportIcon />
                                    )}
                                </span>
                                </StyledCellTable>
                            ))}
                            </StyledHeaderTable>
                            <div>
                            {props.requiredTopUpReportData.map((data, i) => (
                                <StyledLinkBodyTable
                                to={
                                    companyManagementDetailURL +
                                    companySearchParamDetailDefault(data.employerId)
                                }
                                key={i}
                                >
                                <StyledCellTable width={10}>
                                    {data.employerId}
                                </StyledCellTable>
                                <StyledCellTable width={20}>
                                    {data.companyName}
                                </StyledCellTable>
                                <StyledCellTable width={20}>
                                    {data.companyEmail}
                                </StyledCellTable>
                                <StyledCellTable width={15}>
                                    {data.joinedDate
                                    ? convertTimeZone("Canada/Eastern", data.joinedDate).format(FE_DATE_FORMAT)
                                    : ""}
                                </StyledCellTable>
                                <StyledCellTable width={22}>
                                    {formatterUSD("currency", "USD").format(centToUsd(data.topUpAmount))}
                                </StyledCellTable>
                                <StyledCellTable width={10}>
                                    <StyledButtonSubmit 
                                        submit={true} 
                                        onClick={(event) => {props.openModalCredit(event, data.topUpAmount, data.employerId)}}>
                                        Top Up
                                    </StyledButtonSubmit>
                                </StyledCellTable>
                                
                                </StyledLinkBodyTable>
                            ))}
                            </div>
                            <div className="row mt-4">
                            <div className="col-12 text-center">
                                <label>
                                <Pagination
                                    pagerPagination={props.pagingInfo}
                                    getPage={props.handleChangePage}
                                    onChangeRowsPerPage={props.setRowsPerPage}
                                />
                                </label>
                            </div>
                            </div>
                        </StyledTable>
                        </StyledHideForMobile>

                        <StyledHideForDesktop>
                        {props.requiredTopUpReportData.map((data, index) => (
                            <ExpansionPanel
                            square
                            defaultExpanded={false}
                            className="mt-2"
                            key={index}
                            >
                            <StyledExpansionPanelSummary
                                className="m-0"
                                expandIcon={<ArrowDropUp />}
                            >
                                <div className="w-100 ">
                                <StyledTitleExpansion>ID</StyledTitleExpansion>
                                <div className="mt-2">{data.employerId}</div>
                                </div>
                            </StyledExpansionPanelSummary>
                            <StyledExpansionPanelDetails>
                                <div className="w-100">
                                <StyledTitleExpansion>
                                    Company name
                                </StyledTitleExpansion>
                                <div className="mt-1">{data.companyName}</div>
                                </div>
                                <div className="w-100 mt-3">
                                <StyledTitleExpansion>Email</StyledTitleExpansion>
                                <div className="mt-1">{data.companyEmail}</div>
                                </div>
                                <div className="w-100 mt-3">
                                <StyledTitleExpansion>Joined Date</StyledTitleExpansion>
                                <div className="mt-1">
                                    {data.joinedDate
                                    ? convertTimeZone("Canada/Eastern", data.joinedDate).format(FE_DATE_FORMAT)
                                    : ""}
                                </div>
                                </div>
                                <div className="w-100 mt-3">
                                    <StyledTitleExpansion>Required Top Up Amount</StyledTitleExpansion>
                                    <div className="mt-1">
                                        {formatterUSD("currency", "USD").format(centToUsd(data.topUpAmount))}
                                    </div>
                                </div>
                                <div>
                                    <StyledButtonSubmit 
                                        submit={true} 
                                        onClick={(event) => {props.openModalCredit(event, data.topUpAmount, data.employerId)}}>
                                        Top Up
                                    </StyledButtonSubmit>
                                </div>
                                <hr />
                                <div className="text-center">
                                <StyledSpanExpansionEdit>
                                    <StyledExpansionLabel>
                                    <StyledChangeDetail
                                        to={
                                        companyManagementDetailURL +
                                        companySearchParamDetailDefault(data.employerId)
                                        }
                                    >
                                        Detail
                                    </StyledChangeDetail>
                                    </StyledExpansionLabel>
                                </StyledSpanExpansionEdit>
                                </div>
                            </StyledExpansionPanelDetails>
                            </ExpansionPanel>
                        ))}
                        <div className="row mt-4">
                            <div className="col-12 text-center">
                            <label>
                                <Pagination
                                pagerPagination={props.pagingInfo}
                                getPage={props.handleChangePage}
                                onChangeRowsPerPage={props.setRowsPerPage}
                                />
                            </label>
                            </div>
                        </div>
                        </StyledHideForDesktop>
                    </StyledDivBoxTable>
                    </div>
                </StyledContentBox>
                </div>
            </StyledMainContent>
        </>
    );

}
export const defaultTableSearchParam = {
  page: 1,
  perPage: 10,
  from: null,
  to: null,
  filter: null,
  columnName: null,
  searchKey: null,
  sortType: null,
}

export const defaultTablePagingInfo = {
  currentPage: 0,
  totalPages: 0,
  startPage: 0,
  endPage: 0,
  pages: [],
  rowsPerPage: 0,
}

export const TableSortDataType = {
  DATE: "DATE",
  MONTH: "MONTH",
  YEAR: "YEAR",
  CURRENCY: "CURRENCY",
  PERCENTAGE: "PERCENTAGE",
};
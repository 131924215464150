import React, { useEffect } from 'react';
import styled from "styled-components";
import CreateIcon from "@material-ui/icons/Create";
import ModalImage from "react-modal-image";
import {StyledContentBox, StyledSpanLargerTitle} from "../styled";
import {PdfViewer} from "../pdf-viewer";
import {StyledSpanEditInfo} from "../../pages/company-management/detail/company-management-detail-view";
import {payments} from "../../pages/company-management/company-management-model";
import {BankingInfo} from "./BankingInformationType";
import {getAuthFileUrl} from "../../../cores/utils/helpers";

const CellStyled = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ContentBoxCustomStyled = styled.div`
  padding: 20px 15px;
  border-radius: 5px;
  background-color: #f4f5f9;
`;

const StyledModalImageContainer = styled.div`
  .modal-image {
    max-width: 100% !important;
    max-height: 300px !important;
  }
`;

type Props = {
  bankingInfo: BankingInfo;
  showUpdateBankingInformationModal: () => void;
};

export const BankingInformation = React.memo((props: Props) => {
  const [fileUrl, setFileUrl] = React.useState<string>("");
  const [isPdfFile, setIsPdfFile] = React.useState<boolean>(false);

  useEffect(() => {
    if (props.bankingInfo.voidChequeUrl) {
      getAuthFileUrl(props.bankingInfo.voidChequeUrl).then((fileUrl: any) => {
        setFileUrl(fileUrl);
        setIsPdfFile(fileUrl.endsWith(".pdf"));
      });
    }
  }, [props.bankingInfo.voidChequeUrl]);

 const findPaymentMethodById = (id: number) => {
   let payment = payments.find((paymentMethod) => {
     return paymentMethod.id === id;
   });

   return payment
     ? payment
     : {
       id: -1,
       name: "",
     };
 };

  const payment = findPaymentMethodById(props.bankingInfo ? 2 : 0);

  return (
    <StyledContentBox>
      <StyledSpanLargerTitle>
        Banking Info
        <StyledSpanEditInfo
          onClick={props.showUpdateBankingInformationModal}
        >
          <CreateIcon fontSize="small"/> Edit
        </StyledSpanEditInfo>
      </StyledSpanLargerTitle>

      <div className="mt-2">{payment.name}</div>

      {payment.id === 2 && (
        <ContentBoxCustomStyled className="mt-3">
          <div className="d-flex">
            <CellStyled className="w-40 p-2">Account Number</CellStyled>
            <CellStyled className="w-60 text-right p-2 fw-bold">
              {props.bankingInfo
                ? props.bankingInfo.accountNumber
                : ""}
            </CellStyled>
          </div>
          <div className="d-flex">
            <CellStyled className="w-40 p-2">Transit Number</CellStyled>
            <CellStyled className="w-60 text-right p-2 fw-bold">
              {props.bankingInfo
                ? props.bankingInfo.transitNumber
                : ""}
            </CellStyled>
          </div>
          <div className="d-flex">
            <CellStyled className="w-40 p-2">
              Institution Number
            </CellStyled>
            <CellStyled className="w-60 text-right p-2 fw-bold">
              {props.bankingInfo
                ? props.bankingInfo.bankNumber
                : ""}
            </CellStyled>
          </div>

          <div className="d-flex">
            <CellStyled className="w-40 p-2">Cheque</CellStyled>
            <CellStyled className="w-60 text-right p-2 fw-bold">
              {props.bankingInfo &&
                (fileUrl ? (
                  isPdfFile ?
                    <PdfViewer fileUrl={fileUrl} height="300px"/>
                    :

                    <StyledModalImageContainer>
                      <ModalImage
                        className="modal-image"
                        alt="cheque"
                        small={fileUrl}
                        large={fileUrl}
                      />
                    </StyledModalImageContainer>
                ) : (
                  ""
                ))}
            </CellStyled>
          </div>
        </ContentBoxCustomStyled>
      )}
    </StyledContentBox>
  );
});
import React from "react";
import { CheckBox } from "../checkbox/checkbox";
import styled from "styled-components";
import { BNPInput } from "../input/input";
import { IOSSwitch } from "../switch/ios-switch";
import { formatterUSD } from "../format-usd";
import {
  MedicalAndTravel,
  MedicalTravelData,
} from "./medical-and-travel-coverage-type";
import { centToUsd } from "../cent-to-usd";

const StyledSpanTitle = styled.span`
  font-size: 14px;
  font-weight: bolder;
  line-height: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledSpanSwitchTitle = styled.span`
  position: relative;
  top: 14px;
  max-width: 70%;
`;
const StyledSpanSwitch = styled.span`
  position: absolute;
  right: -8px;
`;

const StyledDivBox = styled.div`
  position: relative;
`;
const StyledDivCheckbox = styled.div`
  width: max-content;
`;

type Props = {
  isChecked: boolean;
  medicalTravels: MedicalAndTravel[];
  medicalTravelData: MedicalTravelData[];
  onSwitchChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  onCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onInputChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    option: string
  ) => void;
};

export default function MedicalAndTravelCoverage(props: Props) {
  const findMedicalTravelById = (id: string) => {
    let medicalTravel = props.medicalTravelData.find((medicalTravel) => {
      return medicalTravel.category === id;
    });

    if (medicalTravel) {
      return medicalTravel;
    }
    return { category: "", noOfEmployees: 0, amount: 0 };
  };

  return (
    <StyledDivBox>
      <div className="d-flex mb-5">
        <StyledSpanSwitchTitle>
          Request a quote for Major Medical & Travel Insurance
        </StyledSpanSwitchTitle>
        <StyledSpanSwitch>
          <IOSSwitch
            checked={props.isChecked}
            onChange={props.onSwitchChange}
          />
        </StyledSpanSwitch>
      </div>

      {props.isChecked &&
        props.medicalTravels.map((medicalTravel, index) => {
          let medicalTravelData = findMedicalTravelById(medicalTravel.id);

          return (
            <div className="mt-4" key={index}>
              <StyledDivCheckbox>
                <CheckBox
                  title={medicalTravel.name}
                  classSpan="top--2px"
                  checked={medicalTravelData.category ? true : false}
                  name={medicalTravel.id}
                  onChange={props.onCheckboxChange}
                />
              </StyledDivCheckbox>

              {medicalTravelData.category && (
                <div className="row">
                  <div className="col-lg-4 mt-2">
                    <StyledSpanTitle title="Monthly Premium Amount">
                      Monthly Premium Amount
                    </StyledSpanTitle>
                    <BNPInput
                      onChange={(e) => props.onInputChange(e, medicalTravel.id)}
                      name="amount"
                      disabled={true}
                      value={"$" + centToUsd(medicalTravel.amount) || ""}
                    />
                  </div>
                  <div className="col-lg-4 mt-2">
                    <StyledSpanTitle title="Number of employee">
                      Number of employee
                    </StyledSpanTitle>
                    <BNPInput
                      onChange={(e) => props.onInputChange(e, medicalTravel.id)}
                      name="noOfEmployees"
                      value={medicalTravelData.noOfEmployees || ""}
                    />
                  </div>
                  <div className="col-lg-4 mt-2">
                    <StyledSpanTitle title="Total Amount">
                      Total Amount
                    </StyledSpanTitle>
                    <BNPInput
                      disabled={true}
                      value={
                        medicalTravelData.noOfEmployees
                          ? formatterUSD("currency", "USD").format(
                              medicalTravelData.noOfEmployees *
                                centToUsd(medicalTravel.amount)
                            ) + "/month"
                          : "$0/month"
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          );
        })}
    </StyledDivBox>
  );
}

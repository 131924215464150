import React from "react";
import {
  StyledContent,
  StyledButtonSubmit,
} from "../../../shared/styled";
import { BNPInput } from "../../../../cores/helpers/input/input";
import { EFTRequest } from "./eft-information-type";

type Props = {
  eftInfo: EFTRequest;
  handleChangeInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: () => void;
  validate: (eftInfo: EFTRequest) => boolean;
};

export function EFTInformationView(props: Props) {
  let isValid = props.validate(props.eftInfo);

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-lg-6 col-md-9">
          <div className="row">
            <div className="col-lg-12 mt-2">
              <StyledContent>Originator ID *</StyledContent>
              <BNPInput
                value={props.eftInfo.originatorID}
                onChange={props.handleChangeInput}
                name="originatorID"
                type="number"
                tabIndex={1}
              />
            </div>

            <div className="col-lg-12 mt-2">
              <StyledContent>Destination Data Center Code *</StyledContent>
              <BNPInput
                value={props.eftInfo.destinationDataCentreCode}
                onChange={props.handleChangeInput}
                name="destinationDataCentreCode"
                tabIndex={2}
              />
            </div>
            <div className="col-lg-12 mt-2">
              <StyledContent>Originator Short Name *</StyledContent>
              <BNPInput
                value={props.eftInfo.originatorShortName}
                onChange={props.handleChangeInput}
                name="originatorShortName"
                tabIndex={3}
              />
            </div>
            <div className="col-lg-12 mt-2">
              <StyledContent>Originator Long Name *</StyledContent>
              <BNPInput
                value={props.eftInfo.originatorLongName}
                onChange={props.handleChangeInput}
                name="originatorLongName"
                tabIndex={4}
              />
            </div>
            <div className="col-lg-12 mt-2">
              <StyledContent>Return Institution Number *</StyledContent>
              <BNPInput
                maxLength={3}
                value={props.eftInfo.returnInstitutionNumber}
                onChange={props.handleChangeInput}
                name="returnInstitutionNumber"
                tabIndex={5}
              />
            </div>
            <div className="col-lg-12 mt-2">
              <StyledContent>Return Branch Number *</StyledContent>
              <BNPInput
                maxLength={5}
                value={props.eftInfo.returnBranchNumber}
                onChange={props.handleChangeInput}
                name="returnBranchNumber"
                tabIndex={6}
              />
            </div>
            <div className="col-lg-12 mt-2">
              <StyledContent>Return Account Number *</StyledContent>
              <BNPInput
                maxLength={12}
                value={props.eftInfo.returnAccountNumber}
                onChange={props.handleChangeInput}
                name="returnAccountNumber"
                tabIndex={7}
              />
            </div>
          </div>
          <div className="row justify-content-end">
            <div className="col-lg-4 col-sm-6 mt-5">
              <StyledButtonSubmit
                submit={isValid}
                disabled={!isValid}
                onClick={props.handleSubmit}
                tabIndex={8}
              >
                Save & Update
              </StyledButtonSubmit>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
import React from "react";
import {
  StatusCompany as CompanyStatus,
  PaymentModel,
} from "./company-management-type";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import EmailIcon from "@material-ui/icons/Email";
import PublicIcon from "@material-ui/icons/Public";

export const companyStatus: CompanyStatus[] = [
  {
    id: "Enabled",
    name: "Working",
  },
  {
    id: "Rejected",
    name: "Declined",
  },
  {
    id: "Disabled",
    name: "Disabled",
  },
  {
    id: "Unclaimed",
    name: "Unclaimed",
  },
];

export const defaultSearchParam = () => {
  return {
    page: 1,
    status: null,
    searchKey: null,
    columnName: null,
    sortType: null,
  };
};

export const payments: PaymentModel[] = [
  {
    id: 0,
    name: (
      <div>
        <EmailIcon className="mr-2" /> Email Transfer
      </div>
    ),
  },
  {
    id: 1,
    name: (
      <div>
        <PublicIcon className="mr-2" /> Online Bill Pay
      </div>
    ),
  },
  {
    id: 2,
    name: (
      <div>
        <AccountBalanceIcon className="mr-2" />
        Cheque (PAD) agreement
      </div>
    ),
  },
];

export const statusTransaction = [
  {
    id: "DEBIT",
    name: "Debit",
  },
  {
    id: "CREDIT",
    name: "Credit",
  },
];

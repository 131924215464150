import React from "react";
import ClaimManagementDetailView from "./claim-management-detail-view";
import {ClaimInfo, DuplicatedClaimInfo, RejectValue} from "../claim-management-type";
import {
  getNextClaim,
  getAllReceiptById,
  getClaimInfo,
  getClaimInfoById,
  checkDuplicatedClaim,
  putApproveClaim,
  putRejectClaim,
  putReverseClaim
} from "../../../../services/claim-service";
import { RouteChildrenProps } from "react-router";
import {
  statusClaim,
  relationships,
  rejectOption,
} from "../claim-management-model";
import { message } from "../../../../cores/helpers/message/message";
import { closeRejectModal } from "./reject-claim-modal";
import { removeLoading, addLoading } from "../../../../cores/utils/loading";
import { getFileUrl } from "../../../../services/file-service";
import {
  getWalletEmployee,
  getPendingClaimAmountByCategory
} from "../../../../services/wallet-service";
import {Balance, PendingAmountByCategory} from "../../company-management/company-management-type";
import {
  claimManagementURL,
  claimManagementSearchParamDefault,
  claimManagementDetailURL
} from "../../../../cores/constants/url-config";
import { BenefitCoverage, BenefitInfo } from "./claim-type";
import { getAllInfoEmployee, getBenefits, getBenefitsType, getEmployeeEligibilityDate } from "../../../../services/employee-service";
import { SortType } from "../../../../cores/models/SortTable";
import {usdToCent} from "../../../../cores/helpers/cent-to-usd";
import { handleError } from "../../../../cores/utils/http-client";

type Props = RouteChildrenProps;
type State = {
  claimInfo: ClaimInfo;
  duplicatedClaims: DuplicatedClaimInfo[];
  rejectValue: RejectValue;
  isValid: boolean;
  balances: Balance[];
  disabledButton: boolean;
  employeeEligibilityDate: Date | null;
  employeeBenefitCancellationDate: Date | null;
  pendingAmountByCategory: PendingAmountByCategory;
};

const NO_NEED_CHECK_LIMIT_AMOUNT_CLAIM_TYPE = ["Group Insurance"]
const DO_NOT_ALLOW_EDIT_CLAIM_STATUS = ['APPROVED', 'REJECTED'];

export default class ClaimManagementDetail extends React.Component<
  Props,
  State
  > {
  state: State = {
    claimInfo: {
      companyName: "",
      employeeEmail: "",
      employeeId: 0,
      employeeName: "",
      employeeStatus: "",
      id: 0,
      maxPaid: 0,
      reason: "",
      receipts: [],
      receiptIncludeId:[],
      employeeBenefitCoverage:[],
      employeeBenefitCoverageType:[],
      benefitsInfo:[],
      enrollments:[],
      reasonType: "",
      status: "",
      submittedDate: "",
      totalAmount: 0,
      companyId: 0,
      reviewDate: null,
      isProRated: false,
      available: false
    },
    duplicatedClaims: [],
    rejectValue: {
      reasonType: "-1",
      reason: "",
    },
    balances: [],
    isValid: false,
    disabledButton: false,
    employeeEligibilityDate: null,
    employeeBenefitCancellationDate: null,
    pendingAmountByCategory: {},
  };

  nextClaimSearchParam = {
    page: 1,
    from: null,
    to: null,
    searchName: null,
    status: "REQUESTED",
    columnName: "submittedDate",
    sortType: "DESC" as SortType,
    perPage: 1,
  }

  async componentDidMount() {
    await this.loadData();
  }

  async componentDidUpdate(prevProps: any) {
    if(prevProps.location.search.split("=")[1] !== this.props.location.search.split("=")[1]) {
      this.setState({
        rejectValue: {
          reasonType: "-1",
          reason: "",
        }
      });
      await this.loadData();
      this.setState({ disabledButton: false });
    }
  }

  loadData = async (props: any = this.props) => {
    let id = props.location.search.split("=")[1];

    if (id) {
      addLoading();
      await this.getData(Number(id))
      removeLoading();
    }
  }

  async getData(id: number) {
    let responseClaimInfo = await getClaimInfo(Number(id));

      let claimInfo: ClaimInfo = responseClaimInfo.data
        ? responseClaimInfo.data
        : {};
      if(claimInfo.receipts[0].attachmentUrl){
        await getFileUrl(claimInfo.receipts[0].attachmentUrl).then((response) => {
          if(response && response.status == 200 && response.data){
            claimInfo.receipts[0].attachmentUrl = response.data;

            this.setState({
              claimInfo: claimInfo,
            });
          }
        });
      }

      const [responseClaimData, responseEmployeeEligibilityDate, responseBalances, responseDuplicatedClaim ] =
          await Promise.all([this.getDataClaim(claimInfo.employeeId),
            getEmployeeEligibilityDate(claimInfo.employeeId),
            getWalletEmployee(claimInfo.employeeId),
            checkDuplicatedClaim(claimInfo.id)
          ])
      claimInfo.employeeBenefitCoverage = responseClaimData.employeeBenefitCoverage;
      claimInfo.employeeBenefitCoverageType = responseClaimData.employeeBenefitCoverageType;
      claimInfo.benefitsInfo = responseClaimData.benefitsInfo;
      claimInfo.enrollments = responseClaimData.enrollments;
      this.setState({
        claimInfo: claimInfo,
        pendingAmountByCategory: responseClaimData.pendingClaimAmountByCategory
      })

      if (!DO_NOT_ALLOW_EDIT_CLAIM_STATUS.includes(claimInfo.status)) {
        await getAllReceiptById({claimId: Number(id), employeeId: claimInfo.employeeId}).then(response => {
          claimInfo.receiptIncludeId = response.data;
  
          this.setState({
            claimInfo: claimInfo,
          });
        })
      }

      if (responseDuplicatedClaim.data.duplicatedClaims) {
        this.setState({duplicatedClaims: responseDuplicatedClaim.data.duplicatedClaims})
      }

      const employeeEligibilityDate = responseEmployeeEligibilityDate.data.eligibilityDate;
      const employeeBenefitCancellationDate = responseEmployeeEligibilityDate.data.benefitCancellationDate;
      this.setState({ employeeEligibilityDate, employeeBenefitCancellationDate })

      let balances = responseBalances ? responseBalances.data.balances : [];

      this.setState({
        balances,
      });
  }

  async getDataClaim(employeeId: number) {
    // get all info
    const [infoOfEmployee, resultBenefits, resultBenefitsType, resultPendingClaimAmountByCategory] = await Promise.all([
      getAllInfoEmployee(employeeId),
      getBenefits(employeeId),
      getBenefitsType(employeeId),
      getPendingClaimAmountByCategory(employeeId)
    ])
    const benefitsInfo: BenefitInfo[] = infoOfEmployee.data.benefitCoverage.benefitCoverages || [];
    const dependents = infoOfEmployee.data.dependents.length ? infoOfEmployee.data.dependents : [];
    const enrollments: BenefitCoverage[] = resultBenefits.data ? resultBenefits.data.enrollments : [];
    const enrollmentsType: BenefitCoverage[] = resultBenefitsType.data ? resultBenefitsType.data.enrollments : [];
    const pendingClaimAmountByCategory: PendingAmountByCategory = resultPendingClaimAmountByCategory.data;
  
    // set employee benefit coverage
    let employeeBenefitCoverage = this.setTypeOfPlan(benefitsInfo, enrollments, [], resultPendingClaimAmountByCategory.data);
    let employeeBenefitCoverageType = this.setTypeOfPlan(benefitsInfo, enrollmentsType, [], resultPendingClaimAmountByCategory.data);

    const {proRated, available, reAllocationStatus, benefitCancellationDate} = infoOfEmployee.data;

    return {
      dependents,
      employeeBenefitCoverage,
      employeeBenefitCoverageType,
      benefitsInfo,
      enrollments,
      enrollmentsType,
      proRated,
      isAvailable: available,
      reAllocationStatus,
      benefitCancellationDate,
      pendingClaimAmountByCategory
    };
  }

  setTypeOfPlan = (
    benefitInfos: BenefitInfo[],
    enrollments: BenefitCoverage[],
    balances: Balance[],
    pendingClaimAmountByCategory: PendingAmountByCategory,
  ) => {
    let employeeBenefitCoverage: BenefitCoverage[] = [];
  
    enrollments.forEach((enrollment) => {
      benefitInfos.forEach((benefitInfo) => {
        if (enrollment.id === benefitInfo.enrollmentId) {
          let value: BenefitCoverage = {
            id: enrollment.id,
            employeeBenefitCoverageId: benefitInfo.id,
            name: enrollment.name,
            limit: this.getLimitBalance(enrollment.id, balances) - pendingClaimAmountByCategory[enrollment.id].pendingISSClaimAmount,
            total: benefitInfo.amount + benefitInfo.rolloverAmount,
            proRatedAmount: benefitInfo.proRatedAmount + benefitInfo.rolloverAmount
          };
          employeeBenefitCoverage.push(value);
        }
      });
    });
  
    return employeeBenefitCoverage;
  };

  getLimitBalance = (id: number, balances: Balance[]) => {
    switch (id) {
      case 1: {
        let hsaLimit = balances.find((m) => {
          return m.accountName === "hsa_limit";
        });
  
        return hsaLimit ? hsaLimit.balance : 0;
      }
      case 2: {
        let wellnessLimit = balances.find((m) => {
          return m.accountName === "wellness_limit";
        });
        return wellnessLimit ? wellnessLimit.balance : 0;
      }
      case 3: {
        let insuranceLimit = balances.find((m) => {
          return m.accountName === "insurance_limit";
        });
        return insuranceLimit ? insuranceLimit.balance : 0;
      }
      case 4: {
        let charityLimit = balances.find((m) => {
          return m.accountName === "charity_limit";
        });
        return charityLimit ? charityLimit.balance : 0;
      }
      case 5: {
        let rrspLimit = balances.find((m) => {
          return m.accountName === "rrsp_limit";
        });
        return rrspLimit ? rrspLimit.balance : 0;
      }
      case 6: {
        let craLimit = balances.find((m) => {
          return m.accountName === "cra_limit";
        });
        return craLimit ? craLimit.balance : 0;
      }
      default:
        return 0;
    }
  };

  handleChangeValueRejectModal = (event: any) => {
    const { name, value } = event.target;
    let rejectValue = Object.assign(this.state.rejectValue);

    rejectValue[name] = value;

    let isValid = this.validateValueReject();
    this.setState({ rejectValue: rejectValue, isValid: isValid });
  };

  validateValueReject = () => {
    let isValid = false;
    if (
      (this.state.rejectValue.reasonType.toString() !== "-1" &&
        this.state.rejectValue.reasonType &&
        this.state.rejectValue.reasonType !== "OTHER") ||
      (this.state.rejectValue.reasonType === "OTHER" &&
        this.state.rejectValue.reason)
    ) {
      isValid = true;
    } else {
      isValid = false;
    }
    return isValid;
  };

  findNameStatusById = (statusId: string) => {
    let status = statusClaim.find((statusClaim) => {
      return statusClaim.id === statusId;
    });

    return status ? status.name : "";
  };
  findRelationshipById = (relationshipId: string) => {
    let relationship = relationships.find((relationship) => {
      return relationship.id === relationshipId;
    });

    return relationship ? relationship.name : "Other";
  };

  findRejectById = (rejectId: string) => {
    let reject = rejectOption.find((option) => {
      return option.id === rejectId;
    });

    return reject ? reject.name : "";
  };

  findEnrollmentIdFromTypeOfClaim = (typeOfClaim: string) => {
    if (this.state.claimInfo.enrollments) {
      const enrollment = this.state.claimInfo.enrollments.find((enrollment: any) => enrollment.name === typeOfClaim);
      return enrollment ? enrollment.id : -1;
    }
  }

  reloadBalance = async (callback: () => void) => {
    const responseBalances = await getWalletEmployee(this.state.claimInfo.employeeId);
    const resultPendingClaimAmountByCategory = await getPendingClaimAmountByCategory(this.state.claimInfo.employeeId)
    const pendingClaimAmountByCategory: PendingAmountByCategory = resultPendingClaimAmountByCategory.data;
    let balances = responseBalances ? responseBalances.data.balances : [];
    this.setState({ balances, pendingAmountByCategory: pendingClaimAmountByCategory }, callback);
  }

  findLimitAmount = (typeOfClaim: string, approvedStep: boolean = false) => {
    if (NO_NEED_CHECK_LIMIT_AMOUNT_CLAIM_TYPE.includes(typeOfClaim.trim()))
      return this.state.claimInfo.receipts[0].amount;
    if (this.state.claimInfo.enrollments &&
        this.state.pendingAmountByCategory &&
        this.state.pendingAmountByCategory[this.findEnrollmentIdFromTypeOfClaim(typeOfClaim.trim())]) {
      return this.findLimitAmountLegacy(typeOfClaim, approvedStep)
          - this.state.pendingAmountByCategory[this.findEnrollmentIdFromTypeOfClaim(typeOfClaim.trim())].pendingISSClaimAmount
    }
    return this.findLimitAmountLegacy(typeOfClaim, approvedStep)
  }

  findLimitAmountLegacy = (typeOfClaim: string, approvedStep: boolean = false) => {
    if (this.state.claimInfo.isProRated && approvedStep) {
      let receipt = this.state.claimInfo.receipts[0];
      let calculateProRatedAmountRemain: number = 
        (receipt.limitAmount + receipt.rolloverAmount)
        - this.findLimitAmountLegacy(receipt.typeOfClaim.trim())
        - (receipt.proRatedAmount + receipt.rolloverAmount);
      return calculateProRatedAmountRemain >= 0 ? 0 : this.state.claimInfo.available ? Math.abs(calculateProRatedAmountRemain) : 0;
    }
    switch (typeOfClaim) {
      case "Healthcare Spending Account": {
        let hsaLimit = this.state.balances.find((m) => {
          return m.accountName === "hsa_limit";
        });

        return hsaLimit ? hsaLimit.balance : 0;
      }
      case "Wellness Spending Account": {
        let wellnessLimit = this.state.balances.find((m) => {
          return m.accountName === "wellness_limit";
        });
        return wellnessLimit ? wellnessLimit.balance : 0;
      }
      case "Personal Insurance (e.g. life, disability)": {
        let insuranceLimit = this.state.balances.find((m) => {
          return m.accountName === "insurance_limit";
        });
        return insuranceLimit ? insuranceLimit.balance : 0;
      }
      case "Charitable Giving": {
        let charityLimit = this.state.balances.find((m) => {
          return m.accountName === "charity_limit";
        });
        return charityLimit ? charityLimit.balance : 0;
      }
      case "RRSP": {
        let rrspLimit = this.state.balances.find((m) => {
          return m.accountName === "rrsp_limit";
        });
        return rrspLimit ? rrspLimit.balance : 0;
      }
      case "Canada Revenue Agency": {
        let craLimit = this.state.balances.find((m) => {
          return m.accountName === "cra_limit";
        });
        return craLimit ? craLimit.balance : 0;
      }
      default:
        return 0;
    }
  };

  proceedToNextClaim = async () => {
    const nextClaim = await getNextClaim(this.state.claimInfo.id)
    if (nextClaim.data.claimId === 0) {
      this.props.history.push(
        claimManagementURL + claimManagementSearchParamDefault
      );
    } else {
      this.props.history.push(`${claimManagementDetailURL}?id=${nextClaim.data.claimId}`)
    }
  }

  handleSubmitApproveClaim = async (manualApproveAmount: number | null) => {
    this.setState({ disabledButton: true });
    if (this.state.claimInfo.id) {
      addLoading();
      const { isProRated } = this.state.claimInfo;
      let receipt = this.state.claimInfo.receipts[0];
      let limitAmount: number = isProRated ?
        Math.abs((receipt.limitAmount + receipt.rolloverAmount)
          - this.findLimitAmount(receipt.typeOfClaim.trim())
          - (receipt.proRatedAmount + receipt.rolloverAmount)) :
        this.findLimitAmount(receipt.typeOfClaim.trim());

      let req = {}
      if (manualApproveAmount) {
        req = { hasChangeAmount: true, approvedAmount: usdToCent(manualApproveAmount) };
      } else {
        let approvedAmount = receipt.amount > limitAmount ? limitAmount : receipt.amount;
        req = { hasChangeAmount: false, approvedAmount: approvedAmount };
      }

      await putApproveClaim(this.state.claimInfo.id, req)
        .catch(error => {
          this.setState({ disabledButton: false });
          return handleError(error);
        })
      message("The claim has been approved successfully!", "success");
      await this.proceedToNextClaim();
    } else {
      this.setState({ disabledButton: false });
    }
  };

  handleReversedApprovedClaim = async () => {
    if (this.state.claimInfo.id) {
      await putReverseClaim(this.state.claimInfo.id, this.state.rejectValue).then(() => {
        closeRejectModal();
        this.props.history.push(
            claimManagementURL + claimManagementSearchParamDefault
        );
        message("The approved claim has been declined successfully!", "success");
      })
    }
  }

  handleSubmitRejectClaim = async () => {
    if (this.state.claimInfo.id) {
      await putRejectClaim(this.state.claimInfo.id, this.state.rejectValue).then(
        () => {
          closeRejectModal();
          message("The claim has been declined", "success");
        }
      );
      await this.proceedToNextClaim();
    }
  };

  render() {
    return (
      <ClaimManagementDetailView
        claimInfo={this.state.claimInfo}
        duplicatedClaims={this.state.duplicatedClaims}
        handleChangeValueRejectModal={this.handleChangeValueRejectModal}
        isValid={this.state.isValid}
        rejectValue={this.state.rejectValue}
        findNameStatusById={this.findNameStatusById}
        findRelationshipById={this.findRelationshipById}
        handleSubmitApproveClaim={this.handleSubmitApproveClaim}
        handleSubmitRejectClaim={this.handleSubmitRejectClaim}
        findRejectById={this.findRejectById}
        findLimitAmount={this.findLimitAmount}
        disabledButton={this.state.disabledButton}
        handleReversedApprovedClaim={this.handleReversedApprovedClaim}
        balances={this.state.balances}
        reloadData={this.loadData}
        employeeEligibilityDate={this.state.employeeEligibilityDate}
        employeeBenefitCancellationDate={this.state.employeeBenefitCancellationDate}
        reloadBalance={this.reloadBalance}
      />
    );
  }
}

import React, {useMemo} from "react";
import {
  StyledMainContent,
  StyledBigTitle,
  StyledCell,
  StyledButtonSubmit,
  StyledContentBox,
  StyledBreadcrumbs,
  StyledSpanStatus,
  StyledExpansionPanelSummary,
} from "../../../shared/styled";
import styled from "styled-components";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
} from "@material-ui/core";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import CircleRounded from "@material-ui/icons/FiberManualRecord";
import { ClaimInfo, Receipt, DuplicatedClaimInfo, RejectValue, ClaimSeparation } from "../claim-management-type";
import RejectModal, { showRejectModal } from "./reject-claim-modal";
import { formatterUSD } from "../../../../cores/helpers/format-usd";
import { Link } from "react-router-dom";
import ModalImage from "react-modal-image";
import { centToUsd } from "../../../../cores/helpers/cent-to-usd";
import {
  ConfirmApproveClaimDialog,
  showConfirmApproveClaimModal,
} from "./confirm-approve-claim-modal";
import {ReceiptModal, showReceiptModal} from "./receipt-modal";
import {
  convertTimeZone,
  getDateWithoutTime,
  findEmployeeStatusNameById
} from "../../../../cores/utils/helpers";
import {
  claimManagementURL,
  companyManagementDetailURL,
  companySearchParamDetailDefault,
  companyManagementDetailEmployeeURL,
  companySearchParamDetailEmployeeDefault
} from "../../../../cores/constants/url-config";
import { Balance } from "../../company-management/company-management-type";
import { checkEmployeeHasReceivedPaidAmount } from "../../../../services/wallet-service";
import { PdfViewer } from "../../../shared/pdf-viewer";
import DuplicatedClaimView from "./duplicated-claim-view";
import {
  FE_DATETIME_FORMAT,
  FE_DETAIL_DATE_FORMAT,
  getStaticDateFromServerDate
} from "../../../../cores/utils/format/date-time-format";
import moment from "moment";

const StyledDivBoxExpand = styled.div`
  width: 100%;
  padding: 17px 21px;
  text-align: left;
`;

const StyledDivImgDependent = styled.div`
  width: max-content;
`;
const StyledImgDependent = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: #4e69be;
  color: #fff;
  text-align: center;
  padding: 0.8rem 0;
`;

const StyledDivDependent = styled.div`
  width: max-content;
  text-align: left;
  padding-left: 15px;
`;
const StyledDivName = styled.div`
  color: ${(props) => props.theme.primaryColor};
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
`;

const StyledErrorSpan = styled.span`
  color: ${(props) => props.theme.secondaryColor};
  font-size: 16px;
  line-height: 24px;
`;

const StyledOptionApproved = styled(StyledButtonSubmit)`
  background-color: ${(props) => props.theme.primaryColor};
`;
const StyledOptionRejected = styled(StyledButtonSubmit)`
  background-color: ${(props) => props.theme.secondaryColor};
`;
const StyledOptionEditted = styled(StyledButtonSubmit)`
  background-color: ${(props) => props.theme.primaryColor};
  width: 150px;
`;

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.primaryColor};
`;

const StyledColorBoldText = styled.span`
  font-size: 14px;
  line-height: 21px;
  color: #58AD2F;
  font-weight: bold;
`;

const StyledStatusBoldText = styled.span`
  font-size: 14px;
  line-height: 21px;
  color: ${props => {
    if (props.color === "APPROVED") return '#58AD2F';
    if (props.color === "REJECTED") return props.theme.secondaryColor;
    return "#969696";
  }};
  font-weight: bold;
`;

const StyledModalImageContainer = styled.div`
  .modal-image {
    height: auto;
  }
  width: 100%;
  height: 232px;
  margin-bottom: 8px;
  overflow-y: scroll;
  overflow-x: hidden;
  text-align: center;
`;

export const StyledLine = styled.div`
  border-bottom: 1px solid #e9eaed;
  margin: auto 24px;
`;

type Props = {
  claimInfo: ClaimInfo;
  duplicatedClaims: DuplicatedClaimInfo[];
  rejectValue: RejectValue;
  isValid: boolean;
  disabledButton: boolean;
  handleChangeValueRejectModal: (event: any) => void;
  findNameStatusById: (statusId: string) => string;
  findRelationshipById: (relationshipId: string) => string;
  handleSubmitApproveClaim: (manualAmount: number | null) => void;
  handleSubmitRejectClaim: () => void;
  findRejectById: (rejectId: string) => string;
  findLimitAmount: (typeOfClaim: string) => number;
  handleReversedApprovedClaim: () => void;
  balances: Balance[];
  reloadData: () => void;
  employeeEligibilityDate: Date | null;
  employeeBenefitCancellationDate: Date | null;
  reloadBalance: (callback: () => void) => void; 
};

const DO_NOT_ALLOW_EDIT_CLAIM_STATUS = ['APPROVED', 'REJECTED'];

export default function ClaimManagementDetailView(props: Props) {
  const [expanded, setExpanded] = React.useState<string | false>();

  const [isReversed, setIsReversed] = React.useState<boolean>(false);

  const [isEmployeeReceived, setBool] = React.useState<boolean>(false);

  const [editReceiptId, setEditReceiptId] = React.useState<number | null>(null);

  const [isValidExpenseDate, setIsValidExpenseDate] = React.useState<boolean>(true);
  const [triggerReloadBalance, setTriggerReloadBalance] = React.useState<boolean>(false);

  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    newExpanded: boolean
  ) => {
    setExpanded(expanded ? panel : false);
  };

  const setAvatar = (name: string) => {
    let abbreviations = "";

    let splitName = name.split(" ");

    splitName.forEach((splitName) => {
      abbreviations += splitName.slice(0, 1);
    });

    return abbreviations;
  };

  React.useEffect(() => {
    if (isReversed) check();
  }, [isReversed]);

  React.useEffect(() => {
     if(props.claimInfo.receipts.some(rec => !checkValidExpenseDate(rec))) {
       setIsValidExpenseDate(false);
     } else {
       setIsValidExpenseDate(true);
     }
  }, [props.employeeEligibilityDate, props.claimInfo]);

  const check = async () => {
    const res = await checkEmployeeHasReceivedPaidAmount(props.claimInfo.id);
    if (res.status === 200) setBool(res.data);
  }

  const getCashAmount = (): number => {
    const arr = props.balances.filter(
      (i) => i.accountName.toLowerCase() === "cash"
    );

    if (arr.length > 0) return arr[0].balance;

    return 0;
  }

  const formatUSD = (amount: number) => {
    return formatterUSD("currency", "USD").format(centToUsd(amount));
  }

  const getTitle = () => {
    const balance = getCashAmount();
    const maxPaid = props.claimInfo.maxPaid;
    let defaultMsg = ` has ${formatUSD(balance)} in their wallet.`;

    if (maxPaid && balance >= maxPaid) {
      return (<>
        <span style={{ fontFamily: 'Graphik Semibold' }}>{props.claimInfo.employeeName}</span>{defaultMsg}
        <br />
        {`${formatUSD(balance - maxPaid)} will be deducted from `}<span style={{ fontFamily: 'Graphik Semibold' }}>{props.claimInfo.employeeName}</span>'s wallet.
        <br />
        Please enter reason to decline this claim:
      </>);
    }

    return (<>
      <span style={{ fontFamily: 'Graphik Semibold' }}>{props.claimInfo.employeeName}</span>{defaultMsg}
      <br />
      Please make sure that this employee has enough money to deduct their wallet.
    </>);
  }

  const isValidForReversed = useMemo(() => {
    const typeOfClaim = props.claimInfo.receipts[0] ? props.claimInfo.receipts[0].typeOfClaim : "";
    const availableForRevertTypeOfClaim = ["Healthcare Spending Account", "Wellness Spending Account"];

    return (props.claimInfo.status === "APPROVED" &&
        props.claimInfo.employeeStatus !== "TERMINATED" &&
        availableForRevertTypeOfClaim.includes(typeOfClaim));
  }, [props.claimInfo.status, props.claimInfo.employeeStatus,  props.claimInfo.maxPaid, props.balances, props.claimInfo.receipts])

  const checkValidExpenseDate = (receipt: Receipt): boolean => {
    const expenseDate = receipt.expenseDate;
    if (props.employeeEligibilityDate && expenseDate) {
      return getDateWithoutTime(expenseDate) >= getDateWithoutTime(props.employeeEligibilityDate);
    }
    return true;
  }
  const widthTitle = "30%";
  const widthParam = "70%";
  const isRequestedClaim = props.claimInfo.status === "REQUESTED";
  const isApprovedClaim = props.claimInfo.status === "APPROVED";

  const openConfirmApproveClaimModal = () => {
    setTriggerReloadBalance(!triggerReloadBalance);
    showConfirmApproveClaimModal();
  }

  const ClaimSeparationDetail = (props: {data: ClaimSeparation}) =>{
    const {data} = props;
    const getStr = () => {
      let strArray = [`${!!data.id ? `${data.id} - ` : ""}${formatterUSD("currency", "USD").format(centToUsd(data.amount))}`];
      if (data.paidStatus) {
        if (data.paidStatus === 'CMP') {
          strArray.push('Paid');
        } else if (data.paidStatus === 'ISS') {
          strArray.push('Waiting payment from Employer');
        }
      } else if (data.status && data.status === 'PENDING_APPROVE') {
        strArray.push(`Pending payment until`);
        if (data.forDate) { strArray.push(convertTimeZone("Canada/Eastern", data.forDate).format(FE_DETAIL_DATE_FORMAT)) };
      } else if (data.status && data.status === 'APPROVED') {
        strArray.push(`Will be paid on approval`);
      }
      return strArray.join(" ");
    }
    return (
      <StyledCell width="100%" style={{paddingLeft:"10px"}}>
        &bull; {getStr()}
      </StyledCell>
    )
  }

  return (
    <StyledMainContent>
      <StyledBigTitle>Claim Information</StyledBigTitle>
      <StyledBreadcrumbs aria-label="breadcrumb" className="mt-3">
        <StyledLink to={claimManagementURL}>Claim Management</StyledLink>

        <Typography color="textPrimary">Claim Information</Typography>
      </StyledBreadcrumbs>
      <div className="container-fluid p-0 mt-3">
        <StyledContentBox>
          <div className="row">
            <div className="col-12">
              <div className="d-flex">
                <StyledCell width={widthTitle} className="fw-bold">
                  Claim Id
                </StyledCell>
                <StyledCell width={widthParam}>{props.claimInfo.id}</StyledCell>
              </div>
              <div className="d-flex">
                <StyledCell width={widthTitle} className="fw-bold">
                  Claim Status
                </StyledCell>
                <StyledCell width={widthParam}>
                  <StyledStatusBoldText color={props.claimInfo.status}>
                    <CircleRounded color="inherit" fontSize="inherit" className="mr-1" />{props.findNameStatusById(props.claimInfo.status)}
                  </StyledStatusBoldText>
                </StyledCell>
              </div>
              <div className="d-flex">
                <StyledCell width={widthTitle} className="fw-bold">
                  Employee Name
                </StyledCell>
                <StyledCell width={widthParam}>
                  <Link
                    to={
                      companyManagementDetailEmployeeURL +
                      companySearchParamDetailEmployeeDefault(props.claimInfo.employeeId)
                    }>
                    {props.claimInfo.employeeName}
                  </Link>
                </StyledCell>
              </div>
              <div className="d-flex">
                <StyledCell width={widthTitle} className="fw-bold">
                  Employee Email
                </StyledCell>
                <StyledCell width={widthParam}>
                  {props.claimInfo.employeeEmail}
                </StyledCell>
              </div>
              <div className="d-flex">
                <StyledCell width={widthTitle} className="fw-bold">
                  Company Name
                </StyledCell>
                <StyledCell width={widthParam}>
                  <Link
                    to={
                      companyManagementDetailURL +
                      companySearchParamDetailDefault(props.claimInfo.companyId)
                    }
                  >
                    {props.claimInfo.companyName}
                  </Link>
                </StyledCell>
              </div>
              <div className="d-flex">
                <StyledCell width={widthTitle} className="fw-bold">
                  Employee Status
                </StyledCell>
                <StyledCell width={widthParam}>
                  <StyledSpanStatus title={props.claimInfo.employeeStatus}>
                    <CircleRounded color="inherit" fontSize="inherit" className="mr-1" />
                    {findEmployeeStatusNameById(props.claimInfo.employeeStatus) || "---"}
                  </StyledSpanStatus>
                </StyledCell>
              </div>
              {props.claimInfo.status === "APPROVED" && (
                <div className="d-flex">
                  <StyledCell width={widthTitle} className="fw-bold">
                    Total Paid
                  </StyledCell>
                  {
                    props.claimInfo.enableClaimSeparation && props.claimInfo.claimSeparations && props.claimInfo.claimSeparations.length > 0 ? (
                      <StyledCell width={widthParam} className="d-flex flex-column" style={{ padding: 0 }}>
                        <StyledCell width="100%">
                          {formatterUSD("currency", "USD").format(centToUsd(props.claimInfo.claimSeparations.reduce((total, currentVal) => total + currentVal.amount, 0)))}
                        </StyledCell>
                        {props.claimInfo.claimSeparations.map(p => <ClaimSeparationDetail data={p} />)}
                      </StyledCell>
                    ) :
                      <StyledCell width={widthParam}>{formatterUSD("currency", "USD").format(centToUsd(props.claimInfo.maxPaid))}</StyledCell>
                  }
                </div>
              )}
               {props.claimInfo.status === "REQUESTED" && props.claimInfo.enableClaimSeparation && (
                <div className="d-flex">
                  <StyledCell width={widthTitle} className="fw-bold">
                    Estimate Paid
                  </StyledCell>
                  {
                    props.claimInfo.claimSeparations ? (
                      <StyledCell width={widthParam} className="d-flex flex-column" style={{ padding: 0 }}>
                        <StyledCell width="100%">
                          {formatterUSD("currency", "USD").format(centToUsd(props.claimInfo.claimSeparations.reduce((total, currentVal) => total + currentVal.amount, 0)))}
                        </StyledCell>
                        {props.claimInfo.claimSeparations.map(p => <ClaimSeparationDetail data={p}/>)}
                      </StyledCell>
                    ) :
                      <StyledCell width={widthParam}>{formatterUSD("currency", "USD").format(centToUsd(props.claimInfo.maxPaid))}</StyledCell>
                  }
                </div>
              )}
              <div className="d-flex">
                <StyledCell width={widthTitle} className="fw-bold">
                  Eligibility Date
                </StyledCell>
                <StyledCell width={widthParam}>
                  {props.employeeEligibilityDate
                      ? convertTimeZone("Canada/Eastern", props.employeeEligibilityDate).format(FE_DETAIL_DATE_FORMAT)
                      : ""}
                </StyledCell>
              </div>
              <div className="d-flex">
                <StyledCell width={widthTitle} className="fw-bold">
                  Benefit Cancellation Date
                </StyledCell>
                <StyledCell width={widthParam}>
                  {props.employeeBenefitCancellationDate
                      ? convertTimeZone("Canada/Eastern", props.employeeBenefitCancellationDate).format(FE_DETAIL_DATE_FORMAT)
                      : ""}
                </StyledCell>
              </div>
              {props.claimInfo.reasonType ? (
                <div className="d-flex">
                  <StyledCell width={widthTitle} className="fw-bold">
                    Rejection Reason
                  </StyledCell>
                  <StyledCell width={widthParam}>
                    {props.findRejectById(props.claimInfo.reasonType)}
                  </StyledCell>
                </div>
              ) : (
                  ""
                )}
              {props.claimInfo.reason ? (
                <div className="d-flex">
                  <StyledCell width={widthTitle} className="fw-bold">
                    Rejection Description
                  </StyledCell>
                  <StyledCell width={widthParam}>
                    {props.claimInfo.reason}
                  </StyledCell>
                </div>
              ) : (
                  ""
                )}

              {props.claimInfo.status !== "REQUESTED" ? (
                <div className="d-flex">
                  <StyledCell width={widthTitle} className="fw-bold">
                    Review Date
                  </StyledCell>
                  <StyledCell width={widthParam}>
                    {props.claimInfo.reviewDate
                      ? convertTimeZone("Canada/Eastern", props.claimInfo.reviewDate).format(FE_DETAIL_DATE_FORMAT)
                      : ""}
                  </StyledCell>
                </div>
              ) : (
                  ""
                )}
            </div>
            <div className="col-12 mt-3">
              {props.claimInfo.receipts
                ? props.claimInfo.receipts.map((receipt, index) => {
                  let limitAmount = receipt.limitAmount + receipt.rolloverAmount;
                  let proRatedAmount = receipt.proRatedAmount + receipt.rolloverAmount;

                  let calculateProRatedAmountRemain = limitAmount - props.findLimitAmount(receipt.typeOfClaim.trim()) - proRatedAmount;
                  return (
                    <ExpansionPanel
                      square
                      defaultExpanded={true}
                      //expanded={expanded === "panel" + index}
                      onChange={handleChange("panel" + index)}
                      key={index}
                    >
                      <StyledExpansionPanelSummary expandIcon={<ArrowDropUp />}>
                          <div className="d-flex justify-content-between w-100">
                            <div className="d-flex w-100">
                              <StyledDivImgDependent>
                                <StyledImgDependent>
                                  {setAvatar(receipt.claimerName)}
                                </StyledImgDependent>
                              </StyledDivImgDependent>
                              <StyledDivDependent>
                                <StyledDivName>
                                  {receipt.claimerName}
                                </StyledDivName>
                                <div>
                                  {props.findRelationshipById(
                                    receipt.relationship
                                  )}
                                </div>
                              </StyledDivDependent>
                            </div>
                            {!DO_NOT_ALLOW_EDIT_CLAIM_STATUS.includes(props.claimInfo.status) && 
                            <StyledOptionEditted 
                              submit
                              onClick={(e) => {
                                e.stopPropagation();
                                setEditReceiptId(receipt.id)
                                showReceiptModal();
                              }}
                            >
                              Edit
                            </StyledOptionEditted>}
                          </div>
                      </StyledExpansionPanelSummary>
                      <StyledLine />
                      <ExpansionPanelDetails>
                        <StyledDivBoxExpand>
                          <div className="row">
                            <div className="col-12">
                              <div className="d-flex mt-3">
                                <StyledCell
                                  width={widthTitle}
                                  className="fw-bold"
                                >
                                  Receipt Id
                                </StyledCell>
                                <StyledCell width={widthParam}>
                                  {receipt.id}
                                </StyledCell>
                              </div>
                              <div className="d-flex">
                                <StyledCell
                                  width={widthTitle}
                                  className="fw-bold"
                                >
                                  Type of Claim
                                </StyledCell>
                                <StyledCell width={widthParam}>
                                  {receipt.typeOfClaim}
                                  {props.claimInfo.status === "REQUESTED"
                                    ? ` - Available ` +
                                    formatterUSD("currency").format(
                                      centToUsd(
                                        props.claimInfo.isProRated ?
                                          calculateProRatedAmountRemain >= 0 ? 0 : props.claimInfo.available ? Math.abs(calculateProRatedAmountRemain) : 0
                                          :
                                          props.findLimitAmount(
                                            receipt.typeOfClaim.trim()
                                          )
                                      )
                                    )
                                    : ""}
                                </StyledCell>
                              </div>
                              <div className="d-flex">
                                <StyledCell
                                  width={widthTitle}
                                  className="fw-bold"
                                >
                                  Type of Expense
                                </StyledCell>
                                <StyledCell width={widthParam}>
                                  {receipt.expenseType}
                                </StyledCell>
                              </div>

                              <div className="d-flex">
                                <StyledCell
                                  width={widthTitle}
                                  className="fw-bold"
                                >
                                  Amount
                                </StyledCell>
                                <StyledCell width={widthParam}>
                                  <StyledColorBoldText>
                                    {formatterUSD("currency", "USD").format(
                                        receipt.amount / 100
                                    )}
                                  </StyledColorBoldText>
                                </StyledCell>
                              </div>
                              <div className="d-flex">
                                <StyledCell
                                  width={widthTitle}
                                  className="fw-bold"
                                >
                                  Date of Expense
                                </StyledCell>
                                <StyledCell width={widthParam}>
                                  {getStaticDateFromServerDate(receipt.expenseDate).format(FE_DETAIL_DATE_FORMAT)}
                                  {!checkValidExpenseDate(receipt) && <StyledErrorSpan> - Invalid Expense Date</StyledErrorSpan>}
                                </StyledCell>
                              </div>
                              <div className="d-flex">
                                <StyledCell
                                    width={widthTitle}
                                    className="fw-bold"
                                >
                                  Submitted Date
                                </StyledCell>
                                <StyledCell
                                  width={widthParam}
                                  title={props.claimInfo.submittedDate
                                    ? moment(props.claimInfo.submittedDate).format(FE_DATETIME_FORMAT)
                                    : ""}
                                >
                                  {props.claimInfo.submittedDate
                                        ? moment(props.claimInfo.submittedDate).format(FE_DETAIL_DATE_FORMAT)
                                        : ""}
                                </StyledCell>
                              </div>
                              <div className="d-flex">
                                <StyledCell
                                  width={widthTitle}
                                  className="fw-bold"
                                >
                                  Note
                                </StyledCell>
                                <StyledCell width={widthParam}>
                                  {receipt.notes}
                                </StyledCell>
                              </div>
                            </div>
                            <div className="col-12">
                              {String(receipt.attachmentUrl).match("/*.pdf") ? (
                                <PdfViewer fileUrl={receipt.attachmentUrl} height="232px" />
                              ) : (
                                  <StyledModalImageContainer>
                                    <ModalImage
                                      className="modal-image"
                                      alt="receipt"
                                      small={String(receipt.attachmentUrl)}
                                      large={String(receipt.attachmentUrl)}
                                    />
                                  </StyledModalImageContainer>
                                )}
                            </div>
                          </div>
                          {props.duplicatedClaims.length > 0 &&
                              <>
                                <StyledLine className="mt-4" />
                                <DuplicatedClaimView duplicatedClaims={props.duplicatedClaims} />
                              </>}
                        </StyledDivBoxExpand>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  )
                })
                : ""}
            </div>
          </div>
        </StyledContentBox>
        {isRequestedClaim && (
          <div className="row">
            <div className="col-lg-2 mt-3">
              <StyledOptionRejected
                submit={isRequestedClaim}
                disabled={!isRequestedClaim}
                onClick={() => {
                  setIsReversed(false);
                  showRejectModal();
                }}
                className="mt-3" >
                Declined
              </StyledOptionRejected>
            </div>
            <div className="col-lg-2 mt-3">
              <StyledOptionApproved
                submit={isRequestedClaim && isValidExpenseDate}
                disabled={!isRequestedClaim || !isValidExpenseDate}
                className="mt-3"
                onClick={openConfirmApproveClaimModal}
              >
                Approve
              </StyledOptionApproved>
            </div>
          </div>)
        }
        {isApprovedClaim && !props.claimInfo.disableDecline && (
          <div className="row">
            <div className="col-lg-2 mt-3">
              <StyledOptionRejected
                  submit={isValidForReversed}
                  disabled={!isValidForReversed}
                  onClick={() => {
                    setIsReversed(true);
                    showRejectModal();
                  }}
                  className="mt-3">
                Decline this approved claim
              </StyledOptionRejected>
            </div>
          </div>)
        }
      </div>
      <RejectModal
        isValid={props.isValid}
        rejectValue={props.rejectValue}
        handleChangeValueRejectModal={props.handleChangeValueRejectModal}
        handleSubmitRejectClaim={!isReversed ? props.handleSubmitRejectClaim : props.handleReversedApprovedClaim}
        isReversed={isReversed}
        title={!isEmployeeReceived ? (<></>) : getTitle()}
        isValidForReversed={!isEmployeeReceived ? true : isValidForReversed}
      />
      <ConfirmApproveClaimDialog
        claimInfo={props.claimInfo}
        findLimitAmount={props.findLimitAmount}
        handleOnOk={props.handleSubmitApproveClaim}
        disabledButton={props.disabledButton}
        triggerReloadBalance={triggerReloadBalance}
        reloadBalance={props.reloadBalance}
      />
      {!DO_NOT_ALLOW_EDIT_CLAIM_STATUS.includes(props.claimInfo.status) && 
      <ReceiptModal
        claimInfo={props.claimInfo}
        editReceiptId={editReceiptId}
        receiptIncludeId={props.claimInfo.receiptIncludeId}
        employeeId={props.claimInfo.employeeId}
        reloadData={props.reloadData}
        findLimitAmount={props.findLimitAmount}
        employeeEligibilityDate={props.employeeEligibilityDate}
      />}
    </StyledMainContent>
  );
}

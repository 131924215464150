import React from "react";
import styled from "styled-components";
import {
  StyledButtonSubmit,
  StyledHeaderModal,
  StyledHeaderTitleModal,
  StyledButtonCloseModal,
  StyledIconCloseModal,
} from "../../../components/shared/styled";

const StyledModalDialog = styled.div`
  max-width: 800px;
  margin: 1.75rem auto;
  position: relative;
  width: auto;
  pointer-events: none;
`;

const StyledWidthDiv = styled.div`
  width: 118px;
`;
const StyledModal = styled.div`
  z-index: 1100 !important;
`;

const StyledOptionApproved = styled(StyledButtonSubmit)`
  background-color: ${(props) => props.theme.primaryColor};
`;

export function TermsAndConditionsModal() {
  return (
    <StyledModal className="modal" id="termsAndConditionsModal">
      <StyledModalDialog role="document">
        <div className="modal-content">
          {/* header */}
          <StyledHeaderModal className="modal-header">
            <StyledHeaderTitleModal className="text-left">
              Terms & Conditions
            </StyledHeaderTitleModal>
            <StyledButtonCloseModal
              name="btn-close"
              onClick={closeTermsAndConditionsModal}
            >
              <StyledIconCloseModal>&times;</StyledIconCloseModal>
            </StyledButtonCloseModal>
          </StyledHeaderModal>
          {/* end header */}
          {/* body */}
          <div className="modal-body text-left">
            <div className="mt-2">
              - Terms of service (also known as terms of use and terms and
              conditions, commonly abbreviated as TOS or ToS, ToU or T&C) are
              the legal agreements between a service provider and a person who
              wants to use that service. The person must agree to abide by the
              terms of service in order to use the offered service. Terms of
              service (also known as terms of use and terms and conditions,
              commonly abbreviated as TOS or ToS, ToU or T&C) are the legal
              agreements between a service provider and a person who wants to
              use that service. The person must agree to abide by the terms of
              service in order to use the offered service.Terms of service (also
              known as terms of use and terms and conditions, commonly
              abbreviated as TOS or ToS, ToU or T&C) are the legal agreements.
            </div>
            <div className="mt-2">
              - Terms of service (also known as terms of use and terms and
              conditions, commonly abbreviated as TOS or ToS, ToU or T&C) are
              the legal agreements between a service provider and a person who
              wants to use that service. The person must agree to abide by the
              terms of service in order to use the offered service. Terms of
              service (also known as terms of use and terms and conditions,
              commonly abbreviated as TOS or ToS, ToU or T&C) are the legal
              agreements between a service provider and a person who wants to
              use that service. The person must agree to abide by the terms of
              service in order to use the offered service.Terms of service (also
              known as terms of use and terms and conditions, commonly
              abbreviated as TOS or ToS, ToU or T&C) are the legal agreements.
            </div>
          </div>
          {/* end body */}
          {/* footer */}
          <div className="modal-footer">
            <StyledWidthDiv>
              <StyledOptionApproved
                type="button"
                submit={true}
                onClick={() => {
                  closeTermsAndConditionsModal();
                }}
                name="btn-ok"
              >
                Close
              </StyledOptionApproved>
            </StyledWidthDiv>
          </div>
          {/* end footer */}
        </div>
      </StyledModalDialog>
    </StyledModal>
  );
}

export function showTermsAndConditionsModal() {
  $("#termsAndConditionsModal").attr(
    "style",
    "display: block; overflow: auto;"
  );
  $("body").append(
    '<div id="modalBackdropTermsAndConditionsModal" style="z-index: 1090" class="modal-backdrop show"></div>'
  );
}
export function closeTermsAndConditionsModal() {
  $("#termsAndConditionsModal").removeAttr("style");
  $("#modalBackdropTermsAndConditionsModal").remove();
}

import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {FE_DATE_FORMAT, getStaticDateFromServerDate} from "../../../../../cores/utils/format/date-time-format";
import EmployerClass from "./employer-classes/EmployerClass";
import {Enrollment, PlanPreferences, PlanPreferencesModel} from "../../company-management-type";
import {StyledBigTitle, StyledButtonSubmit, StyledContentBox, StyledMainContent} from '../../../../shared/styled';
import {
  EmployerBenefitClass,
  EmployerEnrollment,
  EmployerPlanWithSettings,
  RequestPlanPreferences,
} from "./plan-type";
import {Class} from "../employees/employee-model";
import {RouteChildrenProps} from "react-router";
import {addLoading, removeLoading} from "../../../../../cores/utils/loading";
import {
  deleteClassBenefit,
  getClassesBenefitByEmployerId,
  getClassesByEmployerId,
  postClassBenefitByEmployerId,
  putClassBenefitByEmployerId
} from "../../../../../services/company-service";
import DeleteClassModal from "./employer-classes/DeleteClassModal";
import {toUTCTime} from "../../../../../cores/helpers/to-utc-time";
import {closeConfirmDialog, openConfirmDialog} from "../../../../../cores/utils/confirmDialog";
import moment from "moment";

const StyledComponentWrapper = styled.div`
  background-color: ${(props: any) => props.theme.backgroundColor.lightBlue};
  padding: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 4px;
  font-family: ${(props) => props.theme.textFont};

  .MuiPaper-root {
    background-color: transparent;
  }

  .MuiPaper-elevation1 {
    box-shadow: none;
  }
`;

const StyledContentTitle = styled.div`
    font-family: "Roboto",sans-serif;
    font-weight: 500;
    font-size: 1.25rem;
    margin-bottom: 1rem;
`;

export const StyledContentValue = styled.div`
  color: ${(props) => props.theme.primaryColor};
  font-family: ${(props) => props.theme.headlineFont};
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
`;

type Props = {
  employerPlan: EmployerPlanWithSettings | undefined;
  planPreferences: PlanPreferencesModel[];
  enrollments: Enrollment[];
} & RouteChildrenProps;

function PlanDetails(props: Props) {
  const urlParams = new URLSearchParams(window.location.search);
  const employerId = Number(urlParams.get('id')) || 0;

  const [planDetails, setPlanDetails] = useState<EmployerPlanWithSettings | undefined>(props.employerPlan);
  const [updateClassBenefits, setUpdateClassBenefits] = useState<EmployerBenefitClass[]>(planDetails ? planDetails.classBenefits : []);
  const [classes, setClasses] = React.useState<Class[]>([]);
  const [editClassId, setEditClassId] = useState<number | null>(null);
  const [deleteClassId, setDeleteClassId] = useState<number | null>(null);

  useEffect(() => {
    if (!!props.employerPlan) {
      setPlanDetails(props.employerPlan);
      setUpdateClassBenefits(props.employerPlan.classBenefits);
      refetchClassBenefits().then();
    }
  }, [props.employerPlan]);

  const refetchClassBenefits = async () => {
    if (!planDetails) {
      return;
    }
    const planId = planDetails.employerPlanId;
    const [resultClassBenefits, resultClasses] = await Promise.all([
      getClassesBenefitByEmployerId(employerId, planId),
      getClassesByEmployerId(employerId, planId)
    ])

    setClasses(resultClasses.data || []);
    setUpdateClassBenefits(resultClassBenefits.data || []);
  }

  const findEnrollmentNameByEnrollmentId = (enrollmentId: number) => {
    const enrollment = props.enrollments.find((enrollment: Enrollment) => enrollment.id === enrollmentId);
    return enrollment ? enrollment.name : "";
  }

  const findPlanPreferenceNameByPreferenceId = (planPreferencesModel: PlanPreferencesModel) => {
    const planPreference = planPreferencesModel.planPreferences.find(
      (planPreference: PlanPreferences) => {
        return planDetails && planDetails.planPreferences.find(
          (employerPlanPreference: RequestPlanPreferences) => employerPlanPreference.planPreferenceId === planPreference.id
        )
      });
    return planPreference ? planPreference.name : "";
  }

  const handleEditClass = (classId: number) => {
    if(!!editClassId || editClassId === 0) return;
    setEditClassId(classId);
  }

  const handleCancelEditClass = () => {
    if (!planDetails) {
      return;
    }
    setUpdateClassBenefits([...planDetails.classBenefits]);
    setEditClassId(null);
  }

  const handleSubmitEditClass = async (editClass: EmployerBenefitClass) => {
    openConfirmDialog({
      id: `submit-edit-class-${editClass}`,
      open: true,
      title: "Submit Edit Class",
      content: "Are you sure you want to edit this class? This will affect all employees in this class.",
      onClose: async (isOk: boolean) => {
        if (isOk) {
          if (!planDetails) {
            return;
          }
          const planId = planDetails.employerPlanId;

          addLoading();
          if (editClass.id === 0) {
            await postClassBenefitByEmployerId(employerId, planId, {planId, ...editClass});
          } else {
            await putClassBenefitByEmployerId(employerId, {
              employerClassBenefitId: editClass.id,
              ...editClass,
              classBenefitFromDate: toUTCTime(editClass.classBenefitFromDate)
            });
          }
          setEditClassId(null);
          await refetchClassBenefits();
          removeLoading();
        }
        closeConfirmDialog();
      }
    });
  }

  const handleAddMoreClass = () => {
    const newClass = {
      id: 0,
      classId: 0,
      className: "",
      coverageAmount: 0,
    } as EmployerBenefitClass;

    const newUpdatingClassBenefits = [newClass, ...updateClassBenefits];

    setUpdateClassBenefits(newUpdatingClassBenefits);
    setEditClassId(0);
  }

  const handleCloseDeleteClassConfirmModal = async (employerClassBenefitId: number) => {
    addLoading();
    if (!planDetails) {
      return;
    }
    const planId = planDetails.employerPlanId;
    await deleteClassBenefit(employerId, planId, employerClassBenefitId);
    await refetchClassBenefits();
    setDeleteClassId(null);
    removeLoading();
  }

  const handleDeleteClass = (employerClassBenefit: EmployerBenefitClass) => {
    setDeleteClassId(employerClassBenefit.id);
  }

  return (
    <>
    <StyledMainContent>
      <div className={"d-flex flex-column justify-content-around align-items-start flex-lg-row justify-content-lg-between align-items-lg-center"}>
        <StyledBigTitle>{planDetails && planDetails.planName ? planDetails.planName : "Plan Details"}</StyledBigTitle>
      </div>
      {planDetails && <div className="container-fluid p-0">
        <StyledContentBox>
          <div className={"d-flex justify-content-between"}>
            <StyledBigTitle>Class</StyledBigTitle>
            {Number(moment().format("MMDD")) > 115 && <StyledButtonSubmit
              submit
              style={{maxWidth: "150px"}}
              onClick={handleAddMoreClass}
            >
              Add Class
            </StyledButtonSubmit>}
          </div>

          {updateClassBenefits.map((employerClass: EmployerBenefitClass) => (
            <StyledComponentWrapper key={`employer_class_${employerClass.id}`}>
              <EmployerClass
                classBenefit={employerClass}
                isEdit={editClassId !== null && editClassId === employerClass.id}
                classOptions={classes}
                handleEditClass={handleEditClass}
                handleCancelEditClass={handleCancelEditClass}
                handleSubmitEditClass={handleSubmitEditClass}
                handleDeleteClass={handleDeleteClass}
              />
            </StyledComponentWrapper>
          ))}
          <StyledComponentWrapper>
            <div className="row">
              <div className="col-md-6">
                <StyledContentTitle>Plan Information</StyledContentTitle>
                <div className="mb-4">
                  <div>Plan Start Date</div>
                  <StyledContentValue>
                    {planDetails.planStartDate ? getStaticDateFromServerDate(planDetails.planStartDate).format(FE_DATE_FORMAT) : ""}
                  </StyledContentValue>
                </div>
                <StyledContentTitle>Wallet Coverage</StyledContentTitle>
                <div className="mb-3">
                  <div>Categories of benefits</div>
                  {planDetails.employerEnrollments.map((enrollment: EmployerEnrollment) => (
                    <StyledContentValue key={`employer_enrollment_${enrollment.enrollmentId}`}>
                      {findEnrollmentNameByEnrollmentId(enrollment.enrollmentId)}
                    </StyledContentValue>
                  ))}
                </div>
              </div>
              <div className="col-md-6">
                <StyledContentTitle>Plan Preferences</StyledContentTitle>
                {props.planPreferences.map((planPreferencesModel: PlanPreferencesModel) => (
                  <div className="mb-3" key={`employer_plan_preferences_${planPreferencesModel.id}`}>
                    <div>{planPreferencesModel.name}</div>
                    <StyledContentValue>{findPlanPreferenceNameByPreferenceId(planPreferencesModel)}</StyledContentValue>
                  </div>
                ))}
              </div>
            </div>
          </StyledComponentWrapper>
        </StyledContentBox>
      </div>}
    </StyledMainContent>
      <DeleteClassModal
        open={!!deleteClassId}
        handleClose={() => setDeleteClassId(null)}
        employerClassBenefitId={deleteClassId || 0}
        employerPlanId={!!planDetails ? planDetails.employerPlanId : 0}
        handleDeleteClass={handleCloseDeleteClassConfirmModal}
      />
    </>
  )
}

export default PlanDetails;
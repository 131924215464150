import React from 'react';
import {StyledHideForMobile} from "../../../shared/styled";
import {
    StyledCellTable,
    StyledHeaderTable, StyledLinkBodyTable,
    StyledTable
} from "../../../shared/table-styled";
import {claimManagementDetailURL} from "../../../../cores/constants/url-config";
import {formatterUSD} from "../../../../cores/helpers/format-usd";
import {convertTimeZone} from "../../../../cores/utils/helpers";
import {LaunchOutlined, WarningOutlined} from "@material-ui/icons";
import styled from "styled-components";
import {getFileUrl} from "../../../../services/file-service"
import {DuplicatedClaimInfo} from "../claim-management-type";
import {FE_DETAIL_DATE_FORMAT} from "../../../../cores/utils/format/date-time-format";

const StyledDivUrl = styled.div`
  color: #007BFF;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  justify-content: center;
`;

const StyledWarningSpan = styled.span`
  color: ${props => props.theme.secondaryColor};
  font-weight: bold;
`;

const StyledDivImgDependent = styled.div`
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledDivDependent = styled.div`
  width: max-content;
  text-align: left;
  padding-left: 15px;
`;
const StyledDivName = styled.div`
  color: ${(props) => props.theme.primaryColor};
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
`;

type Props = {
    duplicatedClaims: DuplicatedClaimInfo[];
}

const columns = [
    {
        columnName: "RECEIPT ID",
        width: 200,
    },
    {
        columnName: "AMOUNT",
        width: 200,
    },
    {
        columnName: "DATE OF EXPENSE",
        width: 200,
    },
    {
        columnName: "QUICK VIEW",
        width: 200,
    },
    {
        columnName: "",
        width: 200,
    }
]

function DuplicatedClaimView (props: Props) {

    async function handleOpenUrl (e: any, url: string) {
        e.preventDefault();
        e.stopPropagation();
        const res = await getFileUrl(url);
        if (res) {
            window.open(res.data, "Receipt Attachment", "left=100,top=100,width=520,height=520")
        }
    }

    return (
        <>
        <div className="d-flex justify-content-between w-100 pt-4">
            <div className="d-flex w-100">
                <StyledDivImgDependent>
                    <WarningOutlined color="error" fontSize={"large"} />
                </StyledDivImgDependent>
                <StyledDivDependent>
                    <StyledDivName>
                        Duplicate identified!
                    </StyledDivName>
                    <div>
                        We have found {" "}
                        <StyledWarningSpan>{props.duplicatedClaims.length} more receipt{props.duplicatedClaims.length > 1 ? "s" : ""}</StyledWarningSpan>{" "}
                        that likely are duplicates to this one.
                    </div>
                </StyledDivDependent>
            </div>
        </div>
        <div className="col-lg-12 mt-4">
            <StyledTable id="tableClaim">
                <StyledHeaderTable>
                    {columns.map((column, index) => (
                        <StyledCellTable
                            width={column.width}
                            key={index}
                            className="cur"
                        >
                            {column.columnName}
                            <span>
                    </span>
                        </StyledCellTable>
                    ))}
                </StyledHeaderTable>
                <div>
                    {props.duplicatedClaims.map((claimData, i) => (
                        <StyledLinkBodyTable
                            to={`${claimManagementDetailURL}?id=${claimData.id}`}
                            key={i}
                        >
                            <StyledCellTable width={20}>
                                {claimData.receiptId}
                            </StyledCellTable>
                            <StyledCellTable width={20}>
                                {claimData.amount
                                    ? formatterUSD("currency", "USD").format(
                                        claimData.amount / 100
                                    )
                                    : 0}
                                <WarningOutlined color="error" fontSize="small" style={{ marginLeft: "3px"}} />
                            </StyledCellTable>
                            <StyledCellTable width={20}>
                                {claimData.expenseDate
                                    ? convertTimeZone("Canada/Eastern", claimData.expenseDate).format(FE_DETAIL_DATE_FORMAT)
                                    : ""}
                                <WarningOutlined color="error" fontSize="small" style={{ marginLeft: "3px"}} />
                            </StyledCellTable>
                            <StyledCellTable width={20}>
                                {claimData.attachmentUrl
                                    ? <StyledDivUrl onClick={(e) => handleOpenUrl(e, claimData.attachmentUrl)}>
                                        <LaunchOutlined color="inherit" fontSize={"small"} />
                                        Quick view
                                    </StyledDivUrl>
                                    : ""}
                            </StyledCellTable>
                            <StyledCellTable width={20}>
                                <StyledDivUrl style={{textAlign: "center"}}>
                                    <LaunchOutlined color="inherit" fontSize={"small"} />
                                     - ClaimID - {claimData.id}
                                </StyledDivUrl>
                            </StyledCellTable>
                        </StyledLinkBodyTable>
                    ))}
                </div>
            </StyledTable>
        </div>
        </>
    );
};

export default DuplicatedClaimView;
import React from "react";
import Dropzone from "react-dropzone";
import ImgStack from "../../config/component/images/img-stack.svg";
import styled, { css } from "styled-components";
import { publishMessage } from "../../utils/message";
import { removeLoading, addLoading } from "../../utils/loading";
import { postFileToS3 } from "../../../services/company-service";
import DeleteIcon from "@material-ui/icons/Delete"
import {FormControl} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";

type StyledButtonProps = {
  border: string;
};

const StyledDropZone = styled(Dropzone)<StyledButtonProps>`
    width: 100%;
    height: auto;
    border-radius: 3px;
`;

const StyledDivDefaultUpload = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border: 1px dashed ${(props) => props.theme.grey[200]};
    border-radius: 8px;
    padding: 1rem;
`;

const StyledIcon = styled.img`
  position: relative;
  left: 48%;
`;

const StyledDivDragAndDrop = styled.div`
    margin-top: 15px;
    opacity: 0.5;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
`;

const StyledDivYourFileHereOrBr = styled.div`
    opacity: 0.5;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    margin-top: 8px;
    margin-bottom: 15px;
`;

const StyledImgReviewContainer = styled.div`
    overflow: hidden;
    position: relative;
    height: 150px;
    width: 250px;
    border-radius: 8px;
`;

const StyledImgReview = styled.img`
    position: absolute;
    width: 100%;
    height: auto;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
`;

const StyledAside = styled.aside`
    text-align: center;
    padding-bottom: 16px;
`;

const StyledImgPreviewWrapper = styled.div`
    display: inline-flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    width: 280px;
    height: 150px;
    border: 1px solid ${(props) => props.theme.grey[200]};
    border-radius: 8px;
    margin-right: 10px;
`;

const StyledImageActionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
`

const StyledThumbnailRadioButton = styled(Radio)`
  
`;

const StyledDeleteIcon = styled(DeleteIcon)`
  color: ${(props) => props.theme.errorColor};
`;

type Props = {
  addFile: (urlImage: string) => void;
  addBlob: (urlBlob: string) => void;
  urlImages: string[];
  thumbnail: string;
  folderName: string;
  removeFile: (urlImage: string) => void;
  setThumbnail: (urlImage: string) => void;
};

const MultipleImageUpload: React.FC<Props> = React.memo((props) => {

  const onDrop = async (accepted: File[], rejected: any) => {
    if (accepted.length > 0) {
      for (const file of accepted) {
        if (file.size < 10485760) {
          await handleChangeImage([file]);
        } else {
          publishMessage({
            variant: "error",
            message:
              "This image cannot be uploaded, because it exceeds the maximum file size for uploading.",
          });
        }
      }
    } else {
      publishMessage({
        variant: "error",
        message: "Please submit a valid file type!",
      });
    }
  };

  const handleChangeImage = async (files: File[]) => {
    if (files.length) {
      const formData = new FormData();
      formData.append("file", files[0]);
      formData.append("folderName", props.folderName ? props.folderName : "Images");
      addLoading();

      props.addBlob(URL.createObjectURL(files[0]));

      await postFileToS3(formData)
        .then((result) => {
          props.addFile(result.data);
        })
        .finally(() => {
          removeLoading();
        });
    }
  };

  const handleRemovePreviewImage = (e: any, url: string) => {
    e.preventDefault();
    e.stopPropagation();
    props.removeFile(url);
  };

  const handleThumbnailChange = (e: any, url: string) => {
    e.preventDefault();
    e.stopPropagation();
    props.setThumbnail(url);
  };

  const { urlImages } = props;

  const render =
    <StyledDivDefaultUpload>
      {urlImages.length > 0 && (
        <FormControl component="fieldset">
          <StyledAside>
            {urlImages.map((urlImage) => (
              <StyledImgPreviewWrapper key={`image-preview-${urlImage}`} onClick={e => {e.stopPropagation()}}>
                <StyledImgReviewContainer>
                  <StyledImgReview src={urlImage || ImgStack} alt="profile" />
                </StyledImgReviewContainer>
                <StyledImageActionWrapper>
                  <StyledThumbnailRadioButton
                    color={"primary"}
                    name="thumbnail"
                    checked={props.thumbnail === urlImage}
                    onChange={(e) => handleThumbnailChange(e, urlImage)}
                  />
                  <StyledDeleteIcon
                    type="button"
                    onClick={(e) => handleRemovePreviewImage(e, urlImage)}
                  />
                </StyledImageActionWrapper>
              </StyledImgPreviewWrapper>
            ))}
          </StyledAside>
        </FormControl>)
      }
      <StyledIcon src={ImgStack} alt="img-stack" />
      <StyledDivDragAndDrop>Drag & drop</StyledDivDragAndDrop>
      <StyledDivYourFileHereOrBr>your file here or Browse to upload.</StyledDivYourFileHereOrBr>
      <StyledDivYourFileHereOrBr>Minimum upload 3 photos. Maximum upload 5 photos.</StyledDivYourFileHereOrBr>
    </StyledDivDefaultUpload>;

  return (
    <div>
      <StyledDropZone
        style={{
          objectFit: "cover",
          objectPosition: "center",
        }}
        border={urlImages.length > 0 ? "border" : ""}
        multiple={true}
        accept="image/*"
        onDrop={(accepted, rejected) => onDrop(accepted, rejected)}
      >
        {({ isDragAccept, isDragReject }) => {
          if (isDragReject) return "Please submit a valid file";
          return render;
        }}
      </StyledDropZone>
    </div>
  );
});

export default MultipleImageUpload;

import React, { useState, useEffect } from "react";
import moment from "moment";
import { addLoading, removeLoading } from "../../../../cores/utils/loading";
import { validFullNameLength } from "../../../../cores/utils/validation/validate-employee";
import { Provinces } from "../../company-management/company-management-type";
import { EditPartnerRequest, PartnerDetail, PartnerLicenseAgentInformation, PartnerLocation } from "../partner-management-type";
import PartnerModal from "./partner-modal";
import { PartnerTypes } from "../partner-management-model";
import { validateEmail } from "../../../../cores/config/config";
import { putPartner } from "../../../../services/partner-service";
import { publishMessage } from "../../../../cores/utils/message";

type Props = {
  successCallBack: () => void;
  partnerId: number;
  editPartner: PartnerDetail;
  provinces: Provinces[];
  open: boolean;
  handleClose: () => void;
};


export default function EditPartnerModal(props: Props) {
  const [editPartnerData, setEditPartnerData] = useState<PartnerDetail>(props.editPartner);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
  }, []);

  useEffect(() => {
    const { contactFirstName, contactLastName, companyName } = editPartnerData;
    if (!validFullNameLength(contactFirstName, contactLastName)) {
      setErrorMessage("Full name length could not be larger than 30 letters.");
    } else if (!!companyName && !validFullNameLength(companyName, '')) {
      setErrorMessage("Company name length could not be larger than 30 letters.");
    } else {
      setErrorMessage("");
    }
  }, [editPartnerData.contactFirstName, editPartnerData.contactLastName, editPartnerData.companyName]);


  useEffect(() => {
    setEditPartnerData(props.editPartner);
  }, [props.editPartner]);

  const validateAndSetValue = (validateData: PartnerDetail) => {
    let isValid = validate(validateData);
    setEditPartnerData(validateData);
    setIsValid(isValid);
  }

  const validate = (data: PartnerDetail) => {

    const isBaseInfoValid: boolean = !!(
      data.type
      && data.contactFirstName && data.contactFirstName.trim()
      && data.contactLastName && data.contactLastName.trim()
      && data.contactEmail && data.contactEmail.trim()
      && !!(data.contactEmail.match(validateEmail))
      && null != data.isLicensed
      && data.partnerLocation);

    const partnerLocation: PartnerLocation = data.partnerLocation;

    const isBaseLocationValid: boolean = !!(
      partnerLocation.billingStreetAddress && partnerLocation.billingStreetAddress.trim()
      && partnerLocation.billingCityName && partnerLocation.billingCityName.trim()
      && partnerLocation.billingPhoneNumber && partnerLocation.billingPhoneNumber.trim()
      && partnerLocation.billingPostcode && partnerLocation.billingPostcode.trim()
      && partnerLocation.billingProvinceId && partnerLocation.billingProvinceId > 0
      && null != partnerLocation.isMailingSameAsBillingAddress);

    const isMailingAddressValid = partnerLocation.isMailingSameAsBillingAddress
      ? isBaseLocationValid
      : !!(
        partnerLocation.mailingStreetAddress && partnerLocation.mailingStreetAddress.trim()
        && partnerLocation.mailingCityName && partnerLocation.mailingCityName.trim()
        && partnerLocation.mailingPhoneNumber && partnerLocation.mailingPhoneNumber.trim()
        && partnerLocation.mailingPostcode && partnerLocation.mailingPostcode.trim()
        && partnerLocation.mailingProvinceId && partnerLocation.mailingProvinceId > 0
      );

    const licensedData: PartnerLicenseAgentInformation | null = data.partnerLicenseAgentInformation;

    const isLicensedValid = !data.isLicensed ||
      !!(
        licensedData
        && licensedData.licenseNumber && licensedData.licenseNumber.trim()
        && licensedData.licenseExpiryDate
        && licensedData.enoNumber && licensedData.enoNumber.trim()
        && licensedData.enoProvider && licensedData.enoProvider.trim()
        && licensedData.enoExpiryDate
      )

    if (data.type === PartnerTypes.INDIVIDUAL) {
      return !!(
        isBaseInfoValid
        && isBaseLocationValid
        && isMailingAddressValid
        && isLicensedValid
        && data.socialInsuranceNumber && data.socialInsuranceNumber.trim()
      )
    }

    return !!(
      isBaseInfoValid
      && isBaseLocationValid
      && isMailingAddressValid
      && isLicensedValid
      && data.companyName && data.companyName.trim()
      && data.companyEmail && data.companyEmail.trim()
      && !!(data.companyEmail.match(validateEmail))
      && data.businessNumber && data.businessNumber.trim()
      && data.taxNumber && data.taxNumber.trim()
    );
  };


  const convertEditPartnerData = (): EditPartnerRequest => {

    const partnerLocation: PartnerLocation = { ...editPartnerData.partnerLocation };

    let editData: any = {

      contactFirstName: editPartnerData.contactFirstName,
      contactLastName: editPartnerData.contactLastName,
    
      socialInsuranceNumber: editPartnerData.socialInsuranceNumber,
    
      companyName: editPartnerData.companyName,
      businessNumber: editPartnerData.businessNumber,
      taxNumber: editPartnerData.taxNumber,

      partnerLocationRequest: partnerLocation,
      
      isLicensed: editPartnerData.isLicensed,
      partnerLicenseAgentInformationRequest: editPartnerData.isLicensed
        ? { ...editPartnerData.partnerLicenseAgentInformation }
        : null,
    }

    editData.partnerLocationRequest.billingPostcode = editData.partnerLocationRequest.billingPostcode.toUpperCase();
    if (editData.partnerLocationRequest && editData.partnerLocationRequest.mailingPostcode) {
      editData.partnerLocationRequest.mailingPostcode = editData.partnerLocationRequest.mailingPostcode.toUpperCase();
    }
    editData.partnerLocationRequest.billingPhoneNumber = editData.partnerLocationRequest.billingPhoneNumber
      .split("-")
      .join("")
      .split("_")
      .join("");
    if (editData.partnerLocationRequest && editData.partnerLocationRequest.mailingPhoneNumber) {
      editData.partnerLocationRequest.mailingPhoneNumber = editData.partnerLocationRequest.mailingPhoneNumber
        .split("-")
        .join("")
        .split("_")
        .join("");
    }

    if (editData.partnerLicenseAgentInformationRequest) {
      if (editData.partnerLicenseAgentInformationRequest.licenseExpiryDate) {
        editData.partnerLicenseAgentInformationRequest.licenseExpiryDate =
          moment(new Date(editData.partnerLicenseAgentInformationRequest.licenseExpiryDate)).format("YYYY-MM-DD");
      }
      if (editData.partnerLicenseAgentInformationRequest.enoExpiryDate) {
        editData.partnerLicenseAgentInformationRequest.enoExpiryDate =
          moment(new Date(editData.partnerLicenseAgentInformationRequest.enoExpiryDate)).format("YYYY-MM-DD");
      }
    }

    return editData;
  }

  const handleSubmit = async () => {

    if (!validate(editPartnerData)) return;

    addLoading();

    const temp = convertEditPartnerData();
    await putPartner(temp, props.partnerId)
      .then(res => {
        if (res && res.status === 200) {
          publishMessage({
            message: "Partner has been modified successfully.",
            variant: "success",
          });
          props.successCallBack();
          props.handleClose();
        }
      })
    removeLoading();

  };


  return (
    <PartnerModal
      partnerData={editPartnerData}
      isCreate={false}
      handleSubmit={handleSubmit}
      closePartnerModal={props.handleClose}
      isSubmitValid={isValid}
      errorMessage={errorMessage}
      validateAndSetValue={validateAndSetValue}
      provinces={props.provinces}
      open={props.open}
    />
  );
}

import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import moment from "moment";
import {TableSortType} from "../../../shared/BNPTable/BNPTableType";
import BNPRawTable from "../../../shared/BNPTable/BNPRawTable";
import {StyledBigTitle} from "../../../shared/styled";
import {StyledDivExport} from "../../../shared/BNPTable/BNPTable";
import CreateQuoteModal, {showCreateQuoteModal} from "./QuoteModal";
import {addLoading, removeLoading} from "../../../../cores/utils/loading";
import {getListQuote} from "../../../../services/quote-service";
import {FE_DATE_FORMAT} from "../../../../cores/utils/format/date-time-format";
import {download} from "../../../../cores/utils/helpers";
import {SaveAlt} from "@material-ui/icons";

const StyledAction = styled.div`
  color: ${(props) => props.theme.infoColor};
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
  padding-left: 16px;
  cursor: pointer;

  :hover {
    text-decoration: none;
  }

  .MuiSvgIcon-root {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
`;

type Props = {

};

const QuoteColumnTable: TableSortType[] = [
    {
        columnId: "id",
        columnName: "Quote ID",
        width: 25,
        sortType: null,
        sortColumn: false,
    },
    {
        columnId: "date",
        columnName: "Quote Date",
        width: 25,
        sortType: null,
        sortColumn: false,
    },
    {
        columnId: "companyName",
        columnName: "Company Name",
        width: 25,
        sortType: null,
        sortColumn: false,
    },
    {
        columnId: "url",
        columnName: "Download",
        width: 25,
        sortType: null,
        sortColumn: false,
    },
]

export function QuoteEngine(props: Props) {
    const [quote, setQuote] = useState<any[]>([]);

    useEffect(() => {
        fetchData().then();
    }, []);

    const fetchData = async () => {
        addLoading();

        const res = await getListQuote();

        const data = res.data.map((item: any) => ({
            ...item,
            date: moment(item.createdDate).format(FE_DATE_FORMAT),
            url: <StyledAction
              onClick={() => {
                  download(item.url)
              }}
            >
                <SaveAlt/>
                Download
            </StyledAction>
        }));
        setQuote(data);

        removeLoading();
    }

    const handleOpenQuoteModal = () => {
        showCreateQuoteModal();
    }

    return (
        <>
        <div>
            <div className="col-lg-9 col-md-8 col-12">
                <StyledBigTitle>Quote Engine</StyledBigTitle>
            </div>
            <div className='d-flex justify-content-end'>
                <div className='col-lg-2 col-12 pt-2'>
                    <StyledDivExport className='w-100' onClick={handleOpenQuoteModal}>
                        <StyledAction>
                            Add a quote
                        </StyledAction>
                    </StyledDivExport>
                </div>
            </div>
            <div>
                <BNPRawTable
                    columns={QuoteColumnTable}
                    tableWidth="100%"
                    tableData={quote}
                />
            </div>
        </div>
        <CreateQuoteModal reloadPage={fetchData} />
        </>
    );
}
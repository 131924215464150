import React from "react";
import ManageAdminView from "./manage-admin-view";
import { Admin, SearchParamAdmin } from "./manege-admin-type";
import { StatusCompany as Status } from "../company-management/company-management-type";
import { adminRoles, columnsHeaderAdmin, statuses } from "./manage-admin-model";
import { SortTable, SortType } from "../../../cores/models/SortTable";
import { RouteChildrenProps } from "react-router";
import {
  PagingInfo,
  setPagingInfo,
} from "../../../cores/helpers/pagination/pagination";
import {
  getSearchPartnerUser,
  deletePartnerUser,
  activePartnerUser,
  SignUpModel,
  signUpAdmin,
  putModifyPartnerUser,
  putResetPassword,
} from "../../../services/auth-service";
import { showConfirmModal } from "../../../cores/helpers/confirm-modal/confirm-modal";
import {
  showEditAdminModal,
  closeEditAdminModal,
} from "./manage-admin-modal-edit";
import { message } from "../../../cores/helpers/message/message";
import { closeAddNewAdminModal } from "./manage-admin-modal-add-new";
import { addLoading, removeLoading } from "../../../cores/utils/loading";
import { getInfoByToken } from "../../../cores/utils/helpers";
import { AdminRole } from "./admin-management-type";

type State = {
  admins: Admin[];
  admin: Admin;
  newAdmin: SignUpModel;
  columns: SortTable[];
  searchParam: SearchParamAdmin;
  pagingInfo: PagingInfo;
  statuses: Status[];
  roles: AdminRole[];
  isValid: boolean;
  isChange: boolean;
  isActive: boolean;
};
type Props = RouteChildrenProps;

export default class ManageAdmin extends React.Component<Props, State> {
  state: State = {
    admins: [],
    admin: {} as Admin,
    newAdmin: {
      firstName: "",
      lastName: "",
      email: "",
      roleName: getInfoByToken().permission === "super admin" ? "" : "admin",
    },
    columns: [],
    searchParam: {
      page: 1,
      searchKey: null,
      columnName: null,
      sortType: null,
      status: null,
      role: null,
      type: null,
      perPage: 10,
    },
    pagingInfo: {
      currentPage: 0,
      totalPages: 0,
      startPage: 0,
      endPage: 0,
      pages: [],
      rowsPerPage: 10,
    },
    statuses: statuses,
    roles: adminRoles,
    isValid: false,
    isChange: false,
    isActive: true,
  };

  async componentDidMount() {
    let param = this.getSearchByUrl();

    addLoading();
    await this.getSearchClaimData(param.searchParam);

    this.setState({
      columns: param.columns,
      searchParam: param.searchParam,
    });
    removeLoading();
  }

  async UNSAFE_componentWillReceiveProps() {
    let param = this.getSearchByUrl();
    addLoading();
    await this.getSearchClaimData(param.searchParam);

    this.setState({
      columns: param.columns,
      searchParam: param.searchParam,
    });
    removeLoading();
  }

  async getSearchClaimData(searchParam: SearchParamAdmin) {
    await getSearchPartnerUser(searchParam).then((result) => {
      let pagingInfo = setPagingInfo(
        searchParam.page,
        result.data.pageCount,
        searchParam.perPage
      );
      let users = result.data.records || [];

      this.setState({
        pagingInfo: pagingInfo,
        admins: users,
      });
    });
  }

  handleChangSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    let searchParam = Object.assign(this.state.searchParam);

    if (Number(value) !== -1) searchParam[name] = value;
    else searchParam.status = null;
    searchParam.page = 1;
    this.setSearchByParam(searchParam);

    this.setState({ searchParam: searchParam });
  };
  handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    let searchParam: SearchParamAdmin = Object.assign(this.state.searchParam);

    searchParam.searchKey = event.target.value;
    searchParam.page = 1;
    this.setState({ searchParam: searchParam });
  };

  searchNameOrEmail = () => {
    this.setSearchByParam(this.state.searchParam);
  };

  onKeyUp = (event: any) => {
    if (event.keyCode === 13) {
      this.searchNameOrEmail();
    }
  };

  handleChangePage = (page: number) => {
    let searchParam: SearchParamAdmin = Object.assign(this.state.searchParam);
    searchParam.page = page;

    this.setSearchByParam(searchParam);

    this.setState({ searchParam: searchParam });
  };

  sortTable = (columnId: string) => {
    let columnsHeaderTable: SortTable[] = Object.assign(this.state.columns);
    let searchParam = Object.assign({}, this.state.searchParam);
    searchParam.columnName = columnId;
    let index = columnsHeaderTable.findIndex((column) => {
      return column.columnId === columnId;
    });

    columnsHeaderTable.forEach((column, i) => {
      if (i === index) {
        column.sortType = column.sortType === "ASC" ? "DESC" : "ASC";
        searchParam.sortType = column.sortType;
        return;
      }
      column.sortType = null;
    });

    this.setSearchByParam(searchParam);
  };

  getSearchByUrl = () => {
    const urlParams = new URLSearchParams(window.location.search),
      page = urlParams.get("page"),
      status = urlParams.get("status"),
      role = urlParams.get("role"),
      searchKey = urlParams.get("searchKey"),
      type = urlParams.get("type"),
      columnName = urlParams.get("columnName"),
      sortType: SortType = urlParams.get("sortType") as SortType,
      rowsPerPage = urlParams.get("rowsPerPage");

    let searchParam = Object.assign({}, this.state.searchParam);

    page ? (searchParam.page = Number(page)) : (searchParam.page = 1);
    status ? (searchParam.status = status) : (searchParam.status = null);
    role ? (searchParam.role = role) : (searchParam.role = null);
    searchKey
      ? (searchParam.searchKey = searchKey)
      : (searchParam.searchKey = null);

    type ? (searchParam.type = type) : (searchParam.type = null);
    if (columnName && sortType) {
      searchParam.columnName = columnName;
      searchParam.sortType = sortType;
    } else {
      searchParam.columnName = null;
      searchParam.sortType = null;
    }
    rowsPerPage
      ? (searchParam.perPage = Number(rowsPerPage))
      : (searchParam.perPage = this.state.searchParam.perPage);

    // set sort table
    let columns: SortTable[] = columnsHeaderAdmin();
    let index = columns.findIndex((column) => {
      return column.columnId === searchParam.columnName;
    });
    if (index > -1) {
      columns[index].sortType = sortType;
    }

    return {
      searchParam,
      columns,
    };
  };

  setSearchByParam = (searchParam: SearchParamAdmin) => {
    let url = new URL(window.location.href);

    if (searchParam.page) {
      url.searchParams.set("page", searchParam.page.toString());
    } else {
      url.searchParams.delete("page");
    }
    if (searchParam.type) {
      url.searchParams.set("type", searchParam.type.toString());
    } else {
      url.searchParams.delete("type");
    }
    if (searchParam.status) {
      url.searchParams.set("status", searchParam.status);
    } else {
      url.searchParams.delete("status");
    }
    if (searchParam.role) {
      url.searchParams.set("role", searchParam.role);
    } else {
      url.searchParams.delete("role");
    }
    if (searchParam.searchKey) {
      url.searchParams.set("searchKey", searchParam.searchKey);
    } else {
      url.searchParams.delete("searchKey");
    }
    if (searchParam.columnName && searchParam.sortType) {
      url.searchParams.set("sortType", searchParam.sortType);
      url.searchParams.set("columnName", searchParam.columnName);
    } else {
      url.searchParams.delete("sortType");
      url.searchParams.delete("columnName");
    }

    searchParam.perPage
      ? url.searchParams.set("rowsPerPage", searchParam.perPage.toString())
      : url.searchParams.delete("rowsPerPage");

    this.props.history.push(url.search);
  };

  setRowsPerPage = (event: any) => {
    const { value } = event.target;

    let searchParam = Object.assign({}, this.state.searchParam);
    searchParam.page = 1;
    searchParam.perPage = Number(value);

    // set url search
    this.setSearchByParam(searchParam);
  };

  handleShowModalConfirmPartner = (admin: Admin, isChange: boolean) => {
    this.setState({ admin: admin, isChange: isChange, isActive: true });
    showConfirmModal();
  };

  handleShowModalActivePartner = (admin: Admin) => {
    this.setState({ admin: admin, isChange: false, isActive: false });
    showConfirmModal();
  };

  handleShowModalEditPartner = (admin: Admin) => {
    this.setState({ admin: admin });
    showEditAdminModal();
  };

  handleRejectPartner = () => {
    deletePartnerUser(this.state.admin.id).then(() => {
      message("This user has been disabled.", "success");

      let admins: Admin[] = Object.assign(this.state.admins);
      let index = admins.findIndex((admin) => {
        return admin.id === this.state.admin.id;
      });
      if (index > -1) {
        admins[index].status = "INACTIVE";
      }

      removeLoading();
      this.setState({ admins: admins });
    });
  };

  handleActivePartner = () => {
    activePartnerUser(this.state.admin.id).then(() => {
      message("This user has been activated.", "success");

      let admins: Admin[] = Object.assign(this.state.admins);
      let index = admins.findIndex((admin) => {
        return admin.id === this.state.admin.id;
      });
      if (index > -1) {
        admins[index].status = "ACTIVE";
      }

      removeLoading();
      this.setState({ admins: admins });
    });
  };

  handleChangInputEdit = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let admin = Object.assign(this.state.admin);

    admin[name] = value;

    this.setState({ admin: admin });
  };

  handleEditPartner = () => {
    let param = {
      firstName: this.state.admin.firstName,
      lastName: this.state.admin.lastName,
      roleName: this.state.admin.roleName,
    };

    putModifyPartnerUser(this.state.admin.id, param)
      .then(() => {
        message("The information has been updated successfully.", "success");
        closeEditAdminModal();
      })
      .finally(() => {
        this.getSearchClaimData(this.state.searchParam);
      });
  };

  handleChangInputAddNewPartner = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    let newAdmin = Object.assign(this.state.newAdmin);

    newAdmin[name] = value;
    let isValid = this.validate(newAdmin);
    this.setState({ newAdmin: newAdmin, isValid: isValid });
  };
  handleChangSelectAddNewPartner = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    let newAdmin = Object.assign(this.state.newAdmin);

    newAdmin[name] = value;
    let isValid = this.validate(newAdmin);
    this.setState({ newAdmin: newAdmin, isValid: isValid });
  };

  validate = (newAdmin: SignUpModel) => {
    if (
      newAdmin.firstName &&
      newAdmin.lastName &&
      newAdmin.email &&
      newAdmin.roleName
    ) {
      return true;
    } else {
      return false;
    }
  };

  handleAddNewPartner = () => {
    signUpAdmin("Beniplus", this.state.newAdmin).then(() => {
      message("The user has been created successfully.", "success");
      closeAddNewAdminModal();

      this.getSearchClaimData(this.state.searchParam);

      let newAdmin: SignUpModel = {
        firstName: "",
        lastName: "",
        email: "",
        roleName: getInfoByToken().permission === "super admin" ? "" : "admin",
      };

      this.setState({ newAdmin: newAdmin });
    });
  };

  handleResetPassword = () => {
    putResetPassword(this.state.admin.id).then(() => {
      message("The password has been reset successfully.", "success");

      removeLoading();
    });
  };

  render() {
    return (
      <ManageAdminView
        statuses={this.state.statuses}
        roles={this.state.roles}
        columns={this.state.columns}
        admins={this.state.admins}
        admin={this.state.admin}
        newAdmin={this.state.newAdmin}
        isValid={this.state.isValid}
        searchParam={this.state.searchParam}
        pagingInfo={this.state.pagingInfo}
        handleChangSelect={this.handleChangSelect}
        handleChangeInput={this.handleChangeInput}
        onKeyUp={this.onKeyUp}
        searchNameOrEmail={this.searchNameOrEmail}
        handleChangePage={this.handleChangePage}
        sortTable={this.sortTable}
        handleShowModalConfirmPartner={this.handleShowModalConfirmPartner}
        handleShowModalEditPartner={this.handleShowModalEditPartner}
        handleRejectPartner={this.handleRejectPartner}
        handleChangInput={this.handleChangInputEdit}
        handleEditPartner={this.handleEditPartner}
        handleChangInputAddNewPartner={this.handleChangInputAddNewPartner}
        handleAddNewPartner={this.handleAddNewPartner}
        isChange={this.state.isChange}
        handleResetPassword={this.handleResetPassword}
        setRowsPerPage={this.setRowsPerPage}
        handleActivePartner={this.handleActivePartner}
        isActive={this.state.isActive}
        handleShowModalActivePartner={this.handleShowModalActivePartner}
        handleChangeSelectAddNewPartner={this.handleChangSelectAddNewPartner}
      />
    );
  }
}

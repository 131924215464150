import * as React from 'react';
import {BNPModal} from "../../../../shared/BNPModal/BNPModal";
import {StyledButtonSubmit, StyledFormControlLabel, StyledPTitle} from "../../../../shared/styled";
import {useEffect, useState} from "react";
import {BNPInput} from "../../../../../cores/helpers/input/input";
import {ValidatorForm} from "react-material-ui-form-validator";
import {BNPSelect} from "../../../../../cores/helpers/select/select";
import { CreatePartnerUserRequest, EditPartnerUserRequest, PartnerLicenseAgentInformation, PartnerRole, PartnerUserData } from '../../partner-management-type';
import { Radio, RadioGroup } from '@material-ui/core';
import { convertTimeZone } from '../../../../../cores/utils/helpers';
import { FE_DETAIL_DATE_FORMAT } from '../../../../../cores/utils/format/date-time-format';
import BNPDatePicker from '../../../../../cores/helpers/date-picker/date-picker';
import styled from 'styled-components';
import { validateEmail } from '../../../../../cores/config/config';
import { postNewPartnerUser, putPartnerUser } from '../../../../../services/partner-service';
import moment from 'moment';
import { publishMessage } from '../../../../../cores/utils/message';
import { defaultPartnerAdmin } from './PartnerAdminManagementModel';



const StyledAgenda = styled.div`
  font-size: 18px;
  line-height: 21px;
  color: #0F3047;
`
const StyledDescription = styled(RadioGroup)`
  flex-direction: row !important;
  width: 100%;
  .MuiFormControlLabel-label {
    font-size: 14px;
  }
  .MuiFormControlLabel-root {
    margin-right: 20px;
    margin-bottom: 0;
  }
`

type Props = {
  open: boolean;
  title: string;
  handleClose: () => void;
  successCallBack: () => void;
  model: PartnerUserData;
  roles: PartnerRole[];
  partnerId: number;
};
export const PartnerAdminManagementModal = (props: Props) => {
  const [isValid, setValid] = useState<boolean>(false)
  const [isEdit, setEdit] = useState<boolean>(false)
  const [model, setModel] = useState<PartnerUserData>(defaultPartnerAdmin)

  useEffect(() => {
    setModel(props.model);
    setEdit(props.model.id !== -1)
  }, [props.model]);

  const handleClose = () => {
    props.handleClose();
  }

  const resetData = () => {
    setValid(false);
    setModel(defaultPartnerAdmin)
  }

  const handleSubmit = () => {

    if (isEdit) {
      putPartnerUser(convertToEditPartnerUserRequest(), props.partnerId, model.id)
        .then(res => {
          if (res && res.status === 200) {
            publishMessage({
              message: "A partner user has been modified successfully.",
              variant: "success",
            });
            props.successCallBack();
            props.handleClose();
          }
        });
    } else {
      postNewPartnerUser(convertToCreatePartnerUserRequest(), props.partnerId)
      .then(res => {
        if (res && res.status === 201) {
          publishMessage({
            message: "A new partner user has been created successfully.",
            variant: "success",
          });
          resetData();
          props.successCallBack();
          props.handleClose();
        }
    })
    }

  }

  const convertToCreatePartnerUserRequest = () => {
    let createPartnerUser: CreatePartnerUserRequest = {
      firstName: model.firstName,
      lastName: model.lastName,
      email: model.email,

      roleId: model.roleId,
      isLicensed: model.isLicensed,
      partnerLicenseAgentInformationRequest: model.isLicensed ? model.licenseAgentInformation : null
    }
    convertToDateRequest(createPartnerUser.partnerLicenseAgentInformationRequest);
    return createPartnerUser;
  }

  const convertToEditPartnerUserRequest = () => {
    let editPartnerUser: EditPartnerUserRequest = {
      firstName: model.firstName,
      lastName: model.lastName,
      roleId: model.roleId,
      isLicensed: model.isLicensed,
      partnerLicenseAgentInformationRequest: model.isLicensed ? model.licenseAgentInformation : null
    }
    convertToDateRequest(editPartnerUser.partnerLicenseAgentInformationRequest);
    return editPartnerUser;
  }

  const convertToDateRequest = (data: PartnerLicenseAgentInformation | null) => {
    if (data) {
      if (data.licenseExpiryDate) {
        data.licenseExpiryDate = moment(new Date(data.licenseExpiryDate)).format("YYYY-MM-DD");
      }
      if (data.enoExpiryDate) {
        data.enoExpiryDate = moment(new Date(data.enoExpiryDate)).format("YYYY-MM-DD");
      }
    }
  }

  const handleChangeInput = (e: any) => {
    const { name, value } = e.target;
    let temp: any = { ...model };
    temp[name] = value;

    validateAndSetValue(temp);
  }


  const handleChangePartnerRole = (event: any) => {

    const { value } = event.target;
    let temp: PartnerUserData = { ...model };

    const role = findRoleById(value, props.roles);
    temp.roleId = value;
    validateAndSetValue(temp);

  }

  const findRoleById = (id: number, roles: PartnerRole[]) => {
    return roles.find((f) => f.id === id);
  };

  const handleChangePartnerLicensed = (e: any) => {
    const { value } = e.target;
    const temp: PartnerUserData = { ...model };
    temp.isLicensed = value === "true" ? true : false;

    validateAndSetValue(temp);
  }

  const handleChangeLicenseInput = (event: any) => {
    let { name, value } = event.target;
    let temp: any = { ...model };

    if (null == temp.licenseAgentInformation) {
      temp.licenseAgentInformation = {};
    }
    temp.licenseAgentInformation[name] = value;

    validateAndSetValue(temp);

  };

  const handleChangeDate = (date: Date | null, name: string) => {
    let temp: any = { ...model };

    if (null == temp.licenseAgentInformation) {
      temp.licenseAgentInformation = {};
    }

    temp.licenseAgentInformation[name] = date ? new Date(date) : null;

    validateAndSetValue(temp);
  };

  const validateAndSetValue = (validateData: PartnerUserData) => {
    let isValid = validate(validateData);
    setModel(validateData);
    setValid(isValid);
  }

  const validate = (data: PartnerUserData) => {

    const isBaseInfoValid: boolean = !!(
      data.firstName && data.firstName.trim()
      && data.lastName && data.lastName.trim()
      && data.email && data.email.trim()
      && !!(data.email.match(validateEmail))
      && data.roleId && data.roleId != -1
      && null != data.isLicensed);

    const licensedData: PartnerLicenseAgentInformation | null = data.licenseAgentInformation;

    const isLicensedValid = !data.isLicensed ||
      !!(
        licensedData
        && licensedData.licenseNumber && licensedData.licenseNumber.trim()
        && licensedData.licenseExpiryDate
        && licensedData.enoNumber && licensedData.enoNumber.trim()
        && licensedData.enoProvider && licensedData.enoProvider.trim()
        && licensedData.enoExpiryDate
      )

    return !!(
      isBaseInfoValid
      && isLicensedValid
    );
  };



  const modalBody = <ValidatorForm onSubmit={handleSubmit} debounceTime={300}>
    <div className="row">
      <div className="col-md-6 mb-4">
        <StyledPTitle className="mb-2">First Name</StyledPTitle>
        <BNPInput
          name="firstName"
          value={model.firstName}
          onChange={handleChangeInput}
          type="string"
        />
      </div>
      <div className="col-md-6 mb-4">
        <StyledPTitle className="mb-2">Last Name</StyledPTitle>
        <BNPInput
          name="lastName"
          value={model.lastName}
          onChange={handleChangeInput}
          type="string"
        />
      </div>
      <div className="col-md-6 mb-4">
        <StyledPTitle className="mb-2">Email</StyledPTitle>
        <BNPInput
          name="email"
          value={model.email}
          onChange={handleChangeInput}
          type="string"
          disabled={isEdit}
        />
      </div>
      <div className="col-md-6 mb-4">
        <StyledPTitle className="mb-2">Role</StyledPTitle>
        <BNPSelect
          name="roleId"
          value={model.roleId}
          onChange={handleChangePartnerRole}
          options={props.roles}
          placeholder='Select'
        />
      </div>

      <div className="col-md-12">
              <StyledAgenda className="mt-3 mb-3 fw-bold">
                Partner Is A Licensed Agent?*
              </StyledAgenda>
            </div>

            <div className="col-12">
              <StyledDescription
              
                name="Partner Is A Licensed Agent?"
                value={model.isLicensed}
                onChange={handleChangePartnerLicensed}
              >
                <span key="isLicensed_yes">
                  <StyledFormControlLabel
                    value={true}
                    control={<Radio color="primary" />}
                    label="Yes"
                  />
                </span>
                <span key="isLicensed_no">
                  <StyledFormControlLabel
                    value={false}
                    control={<Radio color="primary" />}
                    label="No"
                  />
                </span>
              </StyledDescription>
            </div>

            {model.isLicensed &&
              <>
                <div className="col-md-4 mb-4">
                  <StyledPTitle className="mb-2">Agent License Number</StyledPTitle>
                  <BNPInput
                    value={model.licenseAgentInformation ? model.licenseAgentInformation.licenseNumber : ''}
                    name="licenseNumber"
                    onChange={handleChangeLicenseInput}
                    placeholder="License Number"
                    tabIndex={2}
                  />
                </div>
                <div className="col-md-4 mb-4">
                  <StyledPTitle className="mb-2">Agent License Expiry Date</StyledPTitle>
                  <BNPDatePicker
                    value={
                      model.licenseAgentInformation
                        && model.licenseAgentInformation.licenseExpiryDate
                        ? convertTimeZone(
                          "Canada/Eastern",
                          model.licenseAgentInformation.licenseExpiryDate
                        ).format(FE_DETAIL_DATE_FORMAT)
                        : ""
                    }
                    name="licenseExpiryDate"
                    onChange={handleChangeDate}
                    placeholder="License Expiry Date"
                    tabIndex={20}
                  />
                </div>
                <div className="col-md-4 mb-4" />
                <div className="col-md-4 mb-4">
                  <StyledPTitle className="mb-2">Agent E&O Number</StyledPTitle>
                  <BNPInput
                    value={model.licenseAgentInformation ? model.licenseAgentInformation.enoNumber : ''}
                    name="enoNumber"
                    onChange={handleChangeLicenseInput}
                    placeholder="E&O Number"
                  />
                </div>
                <div className="col-md-4 mb-4">
                  <StyledPTitle className="mb-2">E&O Provider</StyledPTitle>
                  <BNPInput
                    value={model.licenseAgentInformation ? model.licenseAgentInformation.enoProvider : ''}
                    name="enoProvider"
                    onChange={handleChangeLicenseInput}
                    placeholder="E&O Provider"
                  />
                </div>
                <div className="col-md-4 mb-4">
                  <StyledPTitle className="mb-2">
                    E&O Expiry Date
                  </StyledPTitle>
                  <BNPDatePicker
                    value={
                      model.licenseAgentInformation
                        && model.licenseAgentInformation.enoExpiryDate
                        ? convertTimeZone(
                          "Canada/Eastern",
                          model.licenseAgentInformation.enoExpiryDate
                        ).format(FE_DETAIL_DATE_FORMAT)
                        : ""
                    }
                    name="enoExpiryDate"
                    onChange={handleChangeDate}
                    placeholder="E&O Expiry Date"
                  />
                </div>
              </>
            }
    </div>
  </ValidatorForm>

  return (
    <BNPModal
      open={props.open}
      title={isEdit ? "Update Team Member" : "Add New Team Member"}
      handleClose={handleClose}
      body={modalBody}
      footer={<div className="col-md-4 p-0">
        <StyledButtonSubmit
          disabled={!isValid}
          onClick={handleSubmit}
          submit={isValid}
          type="submit"
        >
          Save
        </StyledButtonSubmit>
      </div>}
    />
  );
};
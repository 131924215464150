import * as React from 'react';
import {
  StyledMainContent,
  StyledBigTitle,
  StyledContentBox,
  StyledColor,
  StyledSelect,
  StyledDefaultOption,
  StyledOption,
} from "../../shared/styled";
import { BNPSelect } from "../../../cores/helpers/select/select";
import { BNPInput } from "../../../cores/helpers/input/input";
import SearchIcon from "@material-ui/icons/Search";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import SystemUpdateAltOutlinedIcon from "@material-ui/icons/SystemUpdateAltOutlined";
import styled from "styled-components";
import Pagination, {
  PagingInfo,
} from "../../../cores/helpers/pagination/pagination";
import {
  SearchParamCompany,
  CompanyInfo,
  StatusCompany,
} from "./company-management-type";
import { showCompanyInformationModal } from "./new-client-modal/new-client-modal";
import BNPRawTable from "../../shared/BNPTable/BNPRawTable";
import {TableSortType} from "../../shared/BNPTable/BNPTableType";
import {
  companyManagementDetailURL,
  companySearchParamDetailDefault
} from "../../../cores/constants/url-config";
import { EnableStatusEnum, StatusTableActionWrapperButton } from "../../HOC/withStatusButtonActions";
import { MenuItem } from "@material-ui/core";
import BNPDatePicker from '../../../cores/helpers/date-picker/date-picker';
import { StyledDivExportExcel } from '../claim-management/claim-management-view';
import {PartnerCompanyManagementOptions} from "../partner-management/partner-management-type";
import {getStaticDateFromServerDate} from "../../../cores/utils/format/date-time-format";

const StyledIconSearch = styled(SearchIcon)`
  position: absolute;
  top: 13px;
  right: 14px;
  opacity: 0.7;
`;

const StyledBNPInput = styled(BNPInput)`
  padding-right: 35px;
`;
const StyledDivBoxTable = styled.div`
  min-height: 300px;
`;

const StyledDivAddNew = styled.div`
  position: absolute;
  right: 5px;
  color: ${(props) => props.theme.primaryColor};
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`;

type Props = {
  searchParam: SearchParamCompany;
  pagingInfo: PagingInfo;
  companyStatus: StatusCompany[];
  companyData: CompanyInfo[];
  columns: TableSortType[];

  handleChangeDate: (date: Date | null, name: string) => void;
  handleChangSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyUp: (event: any) => void;
  searchNameOrEmail: () => void;
  findStatusById: (
    statusId: string
  ) => {
    status: string;
    color: string;
  };
  handleChangePage: (page: number) => void;
  sortTable: (columnId: string) => void;
  setRowsPerPage: (event: any) => void;
  handleChangeStatusCompany: (type: EnableStatusEnum, company: CompanyInfo) => void;
  handleExportExcel: () => void;
  linkToBaseUrl: string;
  partnerOptions: PartnerCompanyManagementOptions;
};

export default function CompanyManagementView(props: Props) {

  const tableActions = (companyInfo: CompanyInfo) => props.partnerOptions.allowEditEmployer ?
    <StatusTableActionWrapperButton status={companyInfo.status}
                                    onAcceptChangeStatus={(type) => props.handleChangeStatusCompany(type, companyInfo)} />
    : <div/>;

  const data: any[] = props.companyData.map(i => ({
    ...i,
    joinedDate: i.joinedDate ? getStaticDateFromServerDate(i.joinedDate) : "",
    status: <StyledColor
        color={props.findStatusById(i.status).color}
    >
      {props.findStatusById(i.status).status}
    </StyledColor>,
    actions: tableActions(i),
    linkTo: props.linkToBaseUrl + companyManagementDetailURL +
        companySearchParamDetailDefault(i.id)
  }))
  return (
    <>
      <StyledMainContent>
        <StyledBigTitle>Company Management</StyledBigTitle>
        <div className="container-fluid p-0 mt-3">
          <StyledContentBox>
            <div className="row">
              <div className="col-lg-12 mt-4">
                <div className="row">
                  <div className="col-lg-2 mt-4">
                    <BNPDatePicker
                      onChange={props.handleChangeDate}
                      value={props.searchParam.fromDate || ""}
                      placeholder="From date"
                      name="fromDate"
                      maxDate={props.searchParam.toDate}
                    />
                  </div>
                  <div className="col-lg-2 mt-4">
                    <BNPDatePicker
                      value={props.searchParam.toDate || ""}
                      onChange={props.handleChangeDate}
                      placeholder="To date"
                      name="toDate"
                      minDate={props.searchParam.fromDate}
                    />
                  </div>
                  <div className="col-lg-2 mt-4">
                    <BNPSelect
                      options={props.companyStatus}
                      value={props.searchParam.status}
                      name="claimStatus"
                      placeholder="Filter by status"
                      onChange={props.handleChangSelect}
                    />
                  </div>
                  <div className="col-lg-2 mt-4">
                    <StyledBNPInput
                      placeholder="Search name or email"
                      onChange={props.handleChangeInput}
                      value={
                        props.searchParam.searchKey
                          ? props.searchParam.searchKey
                          : ""
                      }
                      onKeyUp={props.onKeyUp}
                    />
                    <StyledIconSearch onClick={props.searchNameOrEmail} />
                  </div>
                  <div className="col-lg-2 mt-4">
                    <StyledSelect
                      value={-1}
                      tabIndex={5}
                      //onChange={props.onChange}
                      //name={props.name}
                      //disabled={props.disabled ? true : false}
                    >
                      <MenuItem value={-1}>
                        <StyledDefaultOption>
                          <PrintOutlinedIcon className="icon" /> Export data
                        </StyledDefaultOption>
                      </MenuItem>
                      <MenuItem value="1">
                        <StyledOption
                          onClick={props.handleExportExcel}
                        >
                          <StyledDivExportExcel>
                            <SystemUpdateAltOutlinedIcon className="icon" />{" "}
                            Export via Excel
                          </StyledDivExportExcel>
                        </StyledOption>
                      </MenuItem>
                    </StyledSelect>
                  </div>
                  {props.partnerOptions.allowAddEmployer && (
                  <StyledDivAddNew onClick={showCompanyInformationModal}>
                    Add new company
                  </StyledDivAddNew>
                  )}
                </div>
              </div>
              <StyledDivBoxTable className="col-lg-12 mt-4">
                <BNPRawTable columns={props.columns}
                             tableWidth='100%'
                             tableData={data}
                             tableName='company_management_table'
                             handleChangeSortColumn={props.sortTable}
                />

                <div className="row mt-4">
                  <div className="col-12 text-center">
                    <label>
                      <Pagination
                          pagerPagination={props.pagingInfo}
                          getPage={props.handleChangePage}
                          onChangeRowsPerPage={props.setRowsPerPage}
                      />
                    </label>
                  </div>
                </div>
              </StyledDivBoxTable>
            </div>
          </StyledContentBox>
        </div>
      </StyledMainContent>
    </>
  );
}

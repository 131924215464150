import {TableSortType} from "../../../shared/BNPTable/BNPTableType";
import {TableSortDataType} from "../../../shared/BNPTable/BNPTableModel";

export const BlacklistEmployeeTableColumns: TableSortType[] = [
  {
    columnId: "userId",
    columnName: "Employee ID",
    width: 25,
    sortType: null,
    sortColumn: true
  },
  {
    columnId: "userName",
    columnName: "Employee Name",
    width: 25,
    sortType: null,
    sortColumn: true
  },
  {
    columnId: "createdDate",
    columnName: "Created Date",
    width: 25,
    sortType: null,
    sortColumn: true,
    type: TableSortDataType.DATE
  },
  {
    columnId: "action",
    columnName: "Action",
    width: 25,
    sortType: null,
    sortColumn: false
  }
]

export const BlacklistEmployerTableColumns: TableSortType[] = [
  {
    columnId: "userId",
    columnName: "Employer ID",
    width: 25,
    sortType: null,
    sortColumn: true
  },
  {
    columnId: "userName",
    columnName: "Employer Name",
    width: 25,
    sortType: null,
    sortColumn: true
  },
  {
    columnId: "createdDate",
    columnName: "Created Date",
    width: 25,
    sortType: null,
    sortColumn: true,
    type: TableSortDataType.DATE
  },
  {
    columnId: "action",
    columnName: "Action",
    width: 25,
    sortType: null,
    sortColumn: false
  }
]

export const blacklistSearchEmployerOptionColumns: TableSortType[] = [
  {
    columnId: "userId",
    columnName: "Employer ID",
    width: 30,
    sortType: null,
    sortColumn: false
  },
  {
    columnId: "userName",
    columnName: "Employer Name",
    width: 50,
    sortType: null,
    sortColumn: false
  },
  {
    columnId: "action",
    columnName: "Action",
    width: 20,
    sortType: null,
    sortColumn: false
  }
]

export const blacklistSearchEmployeeOptionColumns: TableSortType[] = [
  {
    columnId: "userId",
    columnName: "Employee ID",
    width: 30,
    sortType: null,
    sortColumn: false
  },
  {
    columnId: "userName",
    columnName: "Employee Name",
    width: 50,
    sortType: null,
    sortColumn: false
  },
  {
    columnId: "action",
    columnName: "Action",
    width: 20,
    sortType: null,
    sortColumn: false
  }
]

export const depositWithdrawTransactionsPendingColumns: TableSortType[] = [
  {
    columnId: "name",
    columnName: "Name",
    sortType: null,
    width: 25,
    sortColumn: false
  },
  {
    columnId: "transactionType",
    columnName: "Type",
    sortType: null,
    width: 15,
    sortColumn: false
  },
  {
    columnId: "amount",
    columnName: "amount",
    sortType: null,
    width: 15,
    sortColumn: false,
    type: TableSortDataType.CURRENCY
  },
  {
    columnId: "sundryNumber",
    columnName: "sundry Number",
    sortType: null,
    width: 20,
    sortColumn: false
  },
  {
    columnId: "date",
    columnName: "Date",
    sortType: null,
    width: 15,
    sortColumn: false,
    type: TableSortDataType.DATE
  },
  {
    columnId: "action",
    columnName: "Action",
    sortType: null,
    width: 10,
    sortColumn: false
  }
]
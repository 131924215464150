import {TableSortType} from "../../../shared/BNPTable/BNPTableType";
import {TableSortDataType} from "../../../shared/BNPTable/BNPTableModel";

export const PlanAndClassTableHeader: TableSortType[] = [
  {
    columnId: "employerPlanId",
    columnName: "Id",
    sortColumn: false,
    sortType: null,
    width: 20,
  },
  {
    columnId: "planName",
    columnName: "Plan Name",
    sortColumn: false,
    sortType: null,
    width: 30,
  },
  {
    columnId: "planStartDate",
    columnName: "Start Date",
    sortColumn: false,
    sortType: null,
    width: 30,
    type: TableSortDataType.DATE,
  },
  {
    columnId: "action",
    columnName: "Action",
    sortColumn: false,
    sortType: null,
    width: 20,
  }
]

export const TransactionTableHeader: TableSortType[] = [
  {
    columnId: "txnTitle",
    columnName: "Title",
    sortColumn: false,
    sortType: null,
    width: 25,
  },
  {
    columnId: "txnDescription",
    columnName: "Description",
    sortColumn: false,
    sortType: null,
    width: 25,
  },
  {
    columnId: "clearedAmount",
    columnName: "Cleared Amount",
    sortColumn: false,
    sortType: null,
    width: 12,
    type: TableSortDataType.CURRENCY,
  },
  {
    columnId: "pendingAmount",
    columnName: "Pending Amount",
    sortColumn: false,
    sortType: null,
    width: 12,
    type: TableSortDataType.CURRENCY,
  },
  {
    columnId: "transactionType",
    columnName: "Transaction Type",
    sortColumn: false,
    sortType: null,
    width: 13,
  },
  {
    columnId: "createdDate",
    columnName: "Created Date",
    sortColumn: false,
    sortType: null,
    width: 13,
    type: TableSortDataType.DATE,
  },
]
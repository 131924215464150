import React from "react";
import {
  PagingInfo,
  setPagingInfo,
} from "../../../cores/helpers/pagination/pagination";
import { RouteChildrenProps } from "react-router";
import { SortTable, SortType } from "../../../cores/models/SortTable";
import { addLoading, removeLoading } from "../../../cores/utils/loading";
import moment from "moment";
import PartnerManagementView from "./partner-management-view";
import {
  DisablePartnerRequest,
  PartnerData,
  PartnerExportParam,
  PartnerSearchParam,
  PartnerStatusOption
} from "./partner-management-type";
import { defaultSearchParam, partnerManagementColumns, partnerStatusOptions } from "./partner-management-model";
import CreatePartnerModal from "./partner-modal/create-partner-modal";
import {disablePartner, enablePartner, getExportPartners, getPartners} from "../../../services/partner-service";
import { exportExcel } from "../../../cores/helpers/export-file/export-file";

type Props = RouteChildrenProps;
type State = {
  searchParam: PartnerSearchParam;
  pagingInfo: PagingInfo;
  partnerStatusOptions: PartnerStatusOption[];
  partnerData: PartnerData[];
  columns: SortTable[];
  openPartnerModal: boolean;
};

export default class PartnerManagement extends React.Component<Props, State> {
  state: State = {
    searchParam: defaultSearchParam,
    pagingInfo: {
      currentPage: 0,
      totalPages: 0,
      startPage: 0,
      endPage: 0,
      pages: [],
      rowsPerPage: 10,
    },
    partnerStatusOptions: partnerStatusOptions,
    partnerData: [],
    columns: [],
    openPartnerModal: false,
  };

  async componentDidMount() {
    this.loadData();
  }

  async UNSAFE_componentWillReceiveProps() {
    this.loadData();
  }

  loadData = async () => {    
    addLoading();
    
    let param = this.getSearchByUrl();
    await this.searchPartner(param.searchParam);

    this.setState({
      columns: param.columns,
      searchParam: param.searchParam,
    });

    removeLoading();
  }

  async searchPartner(searchParam: PartnerSearchParam) {
    await getPartners(searchParam).then((result) => {
      let pagingInfo = setPagingInfo(
        searchParam.page,
        result.data.pageCount,
        searchParam.perPage
      );
      let partnerData: PartnerData[] = result.data.records || [];

      this.setState({
        partnerData,
        pagingInfo,
      });
    });
  }

  handleChangeStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    let searchParam: PartnerSearchParam = Object.assign(this.state.searchParam);

    if (Number(value) !== -1) searchParam.status = value;
      else searchParam.status = null;
    
    searchParam.page = 1;
    this.setSearchByParam(searchParam);
    this.setState({ searchParam: searchParam });

  };

  handleChangeCreatedDate = (date: Date | null, name: string) => {
    let searchParam: PartnerSearchParam = Object.assign(this.state.searchParam);

    searchParam.createdDate = date ? new Date(date) : null;
    searchParam.page = 1;
    this.setState({ searchParam: searchParam });
    if (!searchParam.createdDate
      || (searchParam.createdDate instanceof Date && !isNaN(searchParam.createdDate.getTime()))) {
      this.setSearchByParam(searchParam);
    }
  };

  handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {

    event.preventDefault();
    let searchParam: PartnerSearchParam = Object.assign(this.state.searchParam);

    searchParam.searchName = event.target.value;
    searchParam.page = 1;
    this.setState({ searchParam: searchParam });
  };

  searchNameOrEmail = () => {
    this.setSearchByParam(this.state.searchParam);
  };

  onKeyUp = (event: any) => {
    if (event.keyCode === 13) {
      this.searchNameOrEmail(); // enter
    }
  };

  handleChangePage = (page: number) => {
    let searchParam: PartnerSearchParam = Object.assign(this.state.searchParam);
    searchParam.page = page;

    this.setState({ searchParam: searchParam });
    this.setSearchByParam(searchParam);
  };

  sortTable = (columnId: string) => {
    let columnsHeaderTable: SortTable[] = Object.assign(this.state.columns);
    let searchParam = Object.assign({}, this.state.searchParam);
    searchParam.columnName = columnId;
    let index = columnsHeaderTable.findIndex((column) => {
      return column.columnId === columnId;
    });

    columnsHeaderTable.forEach((column, i) => {
      if (i === index) {
        column.sortType = column.sortType === "ASC" ? "DESC" : "ASC";
        searchParam.sortType = column.sortType;
        return;
      }
      column.sortType = null;
    });

    this.setSearchByParam(searchParam);
  };

  getSearchByUrl = () => {
    const urlParams = new URLSearchParams(window.location.search),
      page = urlParams.get("page"),
      createdDate = urlParams.get("createdDate"),
      status = urlParams.get("status"),
      searchName = urlParams.get("searchName"),
      columnName = urlParams.get("columnName"),
      sortType: SortType = urlParams.get("sortType") as SortType,
      perPage = urlParams.get("perPage");

    let searchParam = Object.assign({}, this.state.searchParam);

    searchParam.page = page ? Number(page) : this.state.searchParam.page;
    searchParam.perPage = perPage ? Number(perPage) : this.state.searchParam.perPage;
    searchParam.createdDate = createdDate ? createdDate : null;
    searchParam.status = status ? status : null;
    searchParam.searchName = searchName ? searchName : null;
    
    if (columnName && sortType) {
      searchParam.columnName = columnName;
      searchParam.sortType = sortType;
    } else {
      searchParam.columnName = "id";
      searchParam.sortType = "DESC";
    }

    // set sort table
    let columns: SortTable[] = partnerManagementColumns();
    let index = columns.findIndex((column) => {
      return column.columnId === searchParam.columnName;
    });
    if (index > -1) {
      columns[index].sortType = sortType;
    }

    return {
      searchParam,
      columns,
    };
  };

  setSearchByParam = (searchParam: PartnerSearchParam) => {
    let url = new URL(window.location.href);

    if (searchParam.page) {
      url.searchParams.set("page", searchParam.page.toString());
    } else {
      url.searchParams.delete("page");
    }

    searchParam.perPage
    ? url.searchParams.set("perPage", searchParam.perPage.toString())
    : url.searchParams.delete("perPage");

    if (searchParam.status) {
      url.searchParams.set("status", searchParam.status);
    } else {
      url.searchParams.delete("status");
    }

    if (searchParam.searchName) {
      url.searchParams.set("searchName", searchParam.searchName);
    } else {
      url.searchParams.delete("searchName");
    }

    if (searchParam.createdDate) {
      url.searchParams.set("createdDate", moment(searchParam.createdDate).format("YYYY-MM-DD"));
    } else {
      url.searchParams.delete("createdDate");
    }

    if (searchParam.columnName && searchParam.sortType) {
      url.searchParams.set("sortType", searchParam.sortType);
      url.searchParams.set("columnName", searchParam.columnName);
    } else {
      url.searchParams.delete("sortType");
      url.searchParams.delete("columnName");
    }

    this.props.history.push(url.search);

  };

  setRowsPerPage = (event: any) => {
    const { value } = event.target;

    let searchParam = Object.assign({}, this.state.searchParam);
    searchParam.page = 1;
    searchParam.perPage = Number(value);

    // set url search
    this.setSearchByParam(searchParam);
  };

  handleExportExcel = () => {

    let exportCriteria: PartnerExportParam = { ...this.state.searchParam };
    delete exportCriteria.page;
    delete exportCriteria.perPage;

    getExportPartners(exportCriteria).then(
      (result) => {
        exportExcel(result.data);
      }
    );
  }

  handleOnClickAddNewPartner = () => {
    this.setState({ openPartnerModal: true });
  }

  handleAddNewPartnerSuccess = () => {
    this.loadData();
  }

  handleEnablePartner = async (partnerId: number) => {
    await enablePartner(partnerId);
    this.loadData();
  }

  handleDisablePartner = async (partnerId: number, request: DisablePartnerRequest) => {
    await disablePartner(partnerId, request);
    this.loadData();
  }

  handleClosePartnerModal = () => {
    this.setState({ openPartnerModal: false });
  }

  render() {
    return (
      <>
        <PartnerManagementView
          searchParam={this.state.searchParam}
          pagingInfo={this.state.pagingInfo}
          partnerStatusOptions={this.state.partnerStatusOptions}
          partnerData={this.state.partnerData}
          columns={this.state.columns}
          handleChangeCreatedDate={this.handleChangeCreatedDate}
          handleChangeStatus={this.handleChangeStatus}
          handleChangeInput={this.handleChangeInput}
          onKeyUp={this.onKeyUp}
          handleChangePage={this.handleChangePage}
          sortTable={this.sortTable}
          setRowsPerPage={this.setRowsPerPage}
          handleAddNewPartner={this.handleOnClickAddNewPartner}
          handleExportExcel={this.handleExportExcel}
          handleEnablePartner={this.handleEnablePartner}
          handleDisablePartner={this.handleDisablePartner}
        />
        <CreatePartnerModal
          successCallBack={this.handleAddNewPartnerSuccess}
          open={this.state.openPartnerModal}
          handleClose={this.handleClosePartnerModal}
        />

      </>

    );
  }
}

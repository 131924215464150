import React, { useEffect, useState } from "react";
import { BNPModal } from "../../../../shared/BNPModal/BNPModal";
import {
  StyledButtonSubmit,
  StyledDivTitle,
  StyledPError,
} from "../../../../shared/styled";
import { BNPInput } from "../../../../../cores/helpers/input/input";
import { ValidatorForm } from "react-material-ui-form-validator";
import { BNPSelect, Option } from "../../../../../cores/helpers/select/select";
import {
  closeConfirmDialog,
  openConfirmDialog,
} from "../../../../../cores/utils/confirmDialog";
import { formatterUSD } from "../../../../../cores/helpers/format-usd";
import { usdToCent } from "../../../../../cores/helpers/cent-to-usd";
import {
  PartnerAdjustmentType,
  postAdjustmentPartnerWallet,
} from "../../../../../services/partner-wallet-service";
import {
  getPartnerCommissionEnrollmentOptions,
  getPartnerEmployerOptions,
} from "../../../../../services/partner-service";

type Props = {
  open: boolean;
  title: string;
  handleClose: () => void;
  successCallBack: () => void;
  partnerId: number;
};

type CreditModalType = {
  amount: number;
  creditType: number;
  type: number;
  note: string;
  employerId: number;
  enrollmentId: number;
};

const AdjustmentTypeOptions: Option[] = [
  { id: 1, name: "Clawback" },
  { id: 2, name: "Other" },
];

const CreditDebitOptions: Option[] = [
  { id: 1, name: "Credit" },
  { id: 2, name: "Debit" },
];

export const PartnerAdjustmentModal = (props: Props) => {
  const [isValid, setValid] = useState<boolean>(false);
  const [model, setModel] = useState<CreditModalType>({
    amount: 0,
    type: -1,
    creditType: 1,
    employerId: -1,
    enrollmentId: -1,
    note: "",
  });
  const [employerOptions, setEmployerOptions] = useState<Option[]>([]);
  const [enrollmentOptions, setEnrollmentOptions] = useState<Option[]>([]);

  useEffect(() => {
    Promise.all([
      getPartnerEmployerOptions(props.partnerId),
      getPartnerCommissionEnrollmentOptions(props.partnerId),
    ]).then(([employerOptionsResult, enrollmentOptionsResult]) => {
      setEmployerOptions(employerOptionsResult.data);
      setEnrollmentOptions(enrollmentOptionsResult.data);
    });
  }, [props.partnerId]);

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const modelClone = { ...model, [name]: value };
    setModel(modelClone);
    setValid(checkValid(modelClone));
  };

  const handleChangeCreditDebitType = (
    e: React.ChangeEvent<{ value: unknown }>
  ) => {
    const value = e.target.value as number;
    const modelClone = { ...model, creditType: value };

    setModel(modelClone);
    setValid(checkValid(modelClone));
  };

  const handleChangeAdjustmentType = (
    e: React.ChangeEvent<{ value: unknown }>
  ) => {
    const value = e.target.value as number;
    const modelClone = { ...model, type: value };

    setModel(modelClone);
    setValid(checkValid(modelClone));
  };

  const handleChangeSelect = (e: any) => {
    const { name, value } = e.target;
    const modelClone = { ...model, [name]: value };

    setModel(modelClone);
    setValid(checkValid(modelClone));
  };

  const checkValid = (model: CreditModalType) => {
    return (
      model.amount > 0 &&
      model.type > 0 &&
      model.employerId > 0 &&
      model.enrollmentId > 0
    );
    return false;
  };

  const handleClose = () => {
    setModel({
      amount: 0,
      type: -1,
      creditType: 1,
      employerId: -1,
      enrollmentId: -1,
      note: "",
    });
    props.handleClose();
  };

  const handleSubmit = () => {
    const isDebit = model.type === 1 || (model.type === 2 && model.creditType === 2);
    const adjustmentTypeOption = AdjustmentTypeOptions.find(
      (x) => x.id === model.type
    );

    const submitModel: PartnerAdjustmentType = {
      amount: usdToCent(isDebit ? -model.amount : model.amount),
      targetId: props.partnerId,
      desc: model.note,
      adjustmentType: !!adjustmentTypeOption
        ? adjustmentTypeOption.name.toString()
        : "",
      employerId: model.employerId,
      enrollmentId: model.enrollmentId,
    };

    openConfirmDialog({
      id: `employer-change-status-to-disable`,
      open: true,
      title: `Adjustment Partner Wallet`,
      content: `Are you sure you want to ${
        model.type === 1 ? "clawback" : model.amount > 0 ? "credit" : "debit"
      } ${formatterUSD("currency").format(
        Math.abs(model.amount)
      )} from this partner account?`,
      onClose: (isOk: boolean) =>
        handleAdjustmentPartnerWallet(isOk, submitModel),
    });

    handleClose();
  };

  const handleAdjustmentPartnerWallet = (
    isOk: boolean,
    submitModel: PartnerAdjustmentType
  ) => {
    if (isOk) {
      postAdjustmentPartnerWallet(submitModel).then(() => {
        props.successCallBack();
      });
    }
    closeConfirmDialog();
  };

  const guideMessage = () => {
    if (model.type === 1 && model.amount >= 0) {
      return (
        <StyledPError>Please enter a negative number to clawback</StyledPError>
      );
    } else if (model.type === 2 && model.amount === 0) {
      return (
        <StyledPError>
          Please enter a positive number to credit or negative number to debit
        </StyledPError>
      );
    }
    return null;
  };

  const modalBody = (
    <ValidatorForm onSubmit={() => {}} debounceTime={300}>
      <div className="row">
        <div className="col-12">
          <StyledDivTitle>Amount*</StyledDivTitle>
          <BNPInput
            type="number"
            className="col-12 mt-2"
            onChange={handleChangeInput}
            name="amount"
            placeholder="Amount"
            value={model.amount || ""}
          />
        </div>
        {model.type === 2 && (
          <div className="col-12 mt-3">
            <StyledDivTitle>Credit/Debit?*</StyledDivTitle>
            <BNPSelect
              name="creditType"
              value={model.creditType}
              onChange={handleChangeCreditDebitType}
              options={CreditDebitOptions}
              placeholder="Credit/Debit"
            />
          </div>
        )}
        <div className="col-12 mt-3">
          <StyledDivTitle>Adjustment Type*</StyledDivTitle>
          <BNPSelect
            name="adjustmentType"
            value={model.type}
            onChange={handleChangeAdjustmentType}
            options={AdjustmentTypeOptions}
            placeholder="Select"
          />
        </div>
        <div className="col-12 mt-3">
          <StyledDivTitle>Employer*</StyledDivTitle>
          <BNPSelect
            name="employerId"
            value={model.employerId}
            onChange={handleChangeSelect}
            options={employerOptions}
            placeholder="Select"
          />
        </div>
        <div className="col-12 mt-3">
          <StyledDivTitle>Product Type*</StyledDivTitle>
          <BNPSelect
            name="enrollmentId"
            value={model.enrollmentId}
            onChange={handleChangeSelect}
            options={enrollmentOptions}
            placeholder="Select"
          />
        </div>
        <div className="col-12 mt-3">
          <StyledDivTitle>Note</StyledDivTitle>
          <BNPInput
            maxLength={255}
            className="col-12 mt-2"
            onChange={handleChangeInput}
            name="note"
            placeholder="Note"
            value={model.note || ""}
          />
        </div>
      </div>
    </ValidatorForm>
  );

  return (
    <BNPModal
      open={props.open}
      title={props.title}
      handleClose={handleClose}
      body={modalBody}
      footer={
        <div className="col-md-4 p-0">
          <StyledButtonSubmit
            disabled={!isValid}
            onClick={handleSubmit}
            submit={isValid}
            type="submit"
          >
            Save
          </StyledButtonSubmit>
        </div>
      }
      modalSize="md"
    />
  );
};

import React from "react";
import {
  SearchParamClaim,
  StatusClaim,
  ClaimData,
  ClaimType,
  DateFilterType,
} from "./claim-management-type";

import {
  PagingInfo,
  setPagingInfo,
} from "../../../cores/helpers/pagination/pagination";
import { getAllClaim as getSearchClaim } from "../../../services/claim-service";
import { RouteChildrenProps } from "react-router";
import { statusClaim, columnsClaims, claimType, dateFilterType } from "./claim-management-model";
import { message } from "../../../cores/helpers/message/message";
import ClaimManagementView from "./claim-management-view";
import { SortType } from "../../../cores/models/SortTable";
import { addLoading, removeLoading } from "../../../cores/utils/loading";
import moment from "moment";
import {TableSortType} from "../../shared/BNPTable/BNPTableType";

type Props = RouteChildrenProps;
type State = {
  searchParam: SearchParamClaim;
  pagingInfo: PagingInfo;
  statusClaim: StatusClaim[];
  claimType: ClaimType[];
  dateFilterType: DateFilterType[];
  claimsData: ClaimData[];
  columns: TableSortType[];
};

export default class ClaimManagement extends React.Component<Props, State> {
  state: State = {
    pagingInfo: {
      currentPage: 0,
      totalPages: 0,
      startPage: 0,
      endPage: 0,
      pages: [],
      rowsPerPage: 10,
    },
    searchParam: {
      page: 1,
      from: null,
      to: null,
      status: null,
      claimType: null,
      dateFilterType: null,
      searchName: null,
      columnName: null,
      sortType: null,
      perPage: 10,
    },
    statusClaim: statusClaim,
    claimType: claimType,
    dateFilterType: dateFilterType,
    claimsData: [],
    columns: columnsClaims(),
  };

  async componentDidMount() {
    addLoading();
    let param = this.getSearchByUrl();

    await this.getSearchClaimData(param.searchParam);

    this.setState({
      columns: param.columns,
      searchParam: param.searchParam,
    });
    removeLoading();
  }

  async UNSAFE_componentWillReceiveProps() {
    let param = this.getSearchByUrl();
    addLoading();
    await this.getSearchClaimData(param.searchParam);

    this.setState({
      columns: param.columns,
      searchParam: param.searchParam,
    });
    removeLoading();
  }

  async getSearchClaimData(searchParam: SearchParamClaim) {
    await getSearchClaim(searchParam).then((result) => {
      let pagingInfo = setPagingInfo(
        searchParam.page,
        result.data.pageCount,
        searchParam.perPage
      );
      let claimData: ClaimData[] = result.data.records || [];

      this.setState({
        claimsData: claimData,
        pagingInfo: pagingInfo,
      });
    });
  }

  handleChangSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    let searchParam: SearchParamClaim = Object.assign(this.state.searchParam);

    if ((event.target.name === "claimStatus")) {
      if (Number(value) !== -1) searchParam.status = value;
      else searchParam.status = null;
    } else if ((event.target.name === "claimType")) {
      if (Number(value) !== -1) searchParam.claimType = value;
      else searchParam.claimType = null;
    } else if ((event.target.name === "dateFilterType")) {
      if (Number(value) !== -1) searchParam.dateFilterType = value;
      else searchParam.dateFilterType = null;
    }
    searchParam.page = 1;

    this.setSearchByParam(searchParam);
  };
  handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    let searchParam: SearchParamClaim = Object.assign(this.state.searchParam);

    searchParam.searchName = event.target.value;
    searchParam.page = 1;
    this.setState({ searchParam: searchParam });
  };

  searchNameOrEmail = () => {
    this.setSearchByParam(this.state.searchParam);
  };

  onKeyUp = (event: any) => {
    if (event.keyCode === 13) {
      this.searchNameOrEmail();
    }
  };

  handleChangeDate = (date: Date | null, name: string) => {
    let searchParam: SearchParamClaim = Object.assign(this.state.searchParam);

    if (name === "from") {
      if (searchParam.to && date) {
        let currentFrom = new Date(date),
          from = new Date(currentFrom),
          to = new Date(searchParam.to);
        if (from > to) {
          message("The from date cannot be after the to date.", "error");
          return;
        }
      }
      searchParam.from = date;
    } else {
      if (searchParam.from && date) {
        let from = new Date(searchParam.from),
          to = new Date(date);
        if (from > to) {
          message("The from date cannot be after the to date.", "error");
          return;
        }
      }
      searchParam.to = date;
    }
    searchParam.page = 1;

    this.setSearchByParam(searchParam);
  };

  handleChangePage = (page: number) => {
    let searchParam: SearchParamClaim = Object.assign(this.state.searchParam);
    searchParam.page = page;

    this.setSearchByParam(searchParam);
  };

  findStatusNameById = (statusId: string) => {
    let status = this.state.statusClaim.find((statusClaim) => {
      return statusClaim.id === statusId;
    });
    return status ? status.name : "";
  };

  sortTable = (columnId: string) => {
    let columnsHeaderTable: TableSortType[] = [...this.state.columns];
    let searchParam = Object.assign({}, this.state.searchParam);
    searchParam.columnName = columnId;
    let index = columnsHeaderTable.findIndex((column) => {
      return column.columnId === columnId;
    });

    columnsHeaderTable.forEach((column, i) => {
      if (i === index) {
        column.sortType = column.sortType === "ASC" ? "DESC" : "ASC";
        searchParam.sortType = column.sortType;
        return;
      }
      column.sortType = null;
    });

    this.setSearchByParam(searchParam);
  };

  getSearchByUrl = () => {
    const urlParams = new URLSearchParams(window.location.search),
      page = urlParams.get("page"),
      from = urlParams.get("from"),
      to = urlParams.get("to"),
      status = urlParams.get("status"),
      claimType = urlParams.get("claimType"),
      dateFilterType = urlParams.get("dateFilterType"),
      searchName = urlParams.get("searchName"),
      columnName = urlParams.get("columnName"),
      sortType: SortType = urlParams.get("sortType") as SortType,
      rowsPerPage = urlParams.get("rowsPerPage");

    let searchParam = Object.assign({}, this.state.searchParam);

    if (page) {
      searchParam.page = Number(page);
    } else {
      searchParam.page = 1;
    }
    if (from) {
      searchParam.from = from;
    } else {
      searchParam.from = null;
    }
    if (to) {
      searchParam.to = to;
    } else {
      searchParam.to = null;
    }
    if (status) {
      searchParam.status = status;
    } else {
      searchParam.status = null;
    }
    if (claimType) {
      searchParam.claimType = claimType;
    } else {
      searchParam.claimType = null;
    }
    if (dateFilterType) {
      searchParam.dateFilterType = dateFilterType;
    } else {
      searchParam.dateFilterType = null;
    }
    if (searchName) {
      searchParam.searchName = searchName;
    } else {
      searchParam.searchName = null;
    }
    if (columnName && sortType) {
      searchParam.columnName = columnName;
      searchParam.sortType = sortType;
    } else {
      searchParam.columnName = null;
      searchParam.sortType = null;
    }
    rowsPerPage
      ? (searchParam.perPage = Number(rowsPerPage))
      : (searchParam.perPage = this.state.searchParam.perPage);

    // set sort table
    let columns: TableSortType[] = columnsClaims();
    let index = columns.findIndex((column) => {
      return column.columnId === searchParam.columnName;
    });
    if (index > -1) {
      columns[index].sortType = sortType;
    }

    return {
      searchParam,
      columns,
    };
  };

  setSearchByParam = (searchParam: SearchParamClaim) => {
    let url = new URL(window.location.href);

    if (searchParam.page) {
      url.searchParams.set("page", searchParam.page.toString());
    } else {
      url.searchParams.delete("page");
    }
    if (searchParam.from) {
      url.searchParams.set("from", moment(searchParam.from).format("YYYY-MM-DD"));
    } else {
      url.searchParams.delete("from");
    }
    if (searchParam.to) {
      url.searchParams.set("to", moment(searchParam.to).format("YYYY-MM-DD"));
    } else {
      url.searchParams.delete("to");
    }
    if (searchParam.status) {
      url.searchParams.set("status", searchParam.status);
    } else {
      url.searchParams.delete("status");
    }
    if (searchParam.claimType) {
      url.searchParams.set("claimType", searchParam.claimType);
    } else {
      url.searchParams.delete("claimType");
    }
    if (searchParam.dateFilterType) {
      url.searchParams.set("dateFilterType", searchParam.dateFilterType);
    } else {
      url.searchParams.delete("dateFilterType");
    }
    if (searchParam.searchName) {
      url.searchParams.set("searchName", searchParam.searchName);
    } else {
      url.searchParams.delete("searchName");
    }
    if (searchParam.columnName && searchParam.sortType) {
      url.searchParams.set("sortType", searchParam.sortType);
      url.searchParams.set("columnName", searchParam.columnName);
    } else {
      url.searchParams.delete("sortType");
      url.searchParams.delete("columnName");
    }

    searchParam.perPage
      ? url.searchParams.set("rowsPerPage", searchParam.perPage.toString())
      : url.searchParams.delete("rowsPerPage");

    this.props.history.push(url.search);
  };

  setRowsPerPage = (event: any) => {
    const { value } = event.target;

    let searchParam = Object.assign({}, this.state.searchParam);
    searchParam.page = 1;
    searchParam.perPage = Number(value);

    // set url search
    this.setSearchByParam(searchParam);
  };

  render() {
    return (
      <ClaimManagementView
        searchParam={this.state.searchParam}
        pagingInfo={this.state.pagingInfo}
        statusClaim={this.state.statusClaim}
        claimType={this.state.claimType}
        dateFilterType={this.state.dateFilterType}
        claimsData={this.state.claimsData}
        columns={this.state.columns}
        handleChangeDate={this.handleChangeDate}
        handleChangSelect={this.handleChangSelect}
        handleChangeInput={this.handleChangeInput}
        onKeyUp={this.onKeyUp}
        searchNameOrEmail={this.searchNameOrEmail}
        findStatusNameById={this.findStatusNameById}
        handleChangePage={this.handleChangePage}
        sortTable={this.sortTable}
        setRowsPerPage={this.setRowsPerPage}
      />
    );
  }
}

import React, {Fragment, useEffect, useMemo, useState} from "react";
import {StyledContentBox, StyledSpanLargerTitle, StyledSpanStatus,} from "../../../../shared/styled";
import CreateIcon from "@material-ui/icons/Create";
import {StyledSpanEditInfo} from "../company-management-detail-view";
import {PremiumInsuranceModal} from "./premium-insurance-modal";
import {
  PremiumInsuranceTableAdminColumns,
  PremiumInsuranceTableColumns,
  PremiumInsuranceTableType,
  PremiumInsuranceType,
  PremiumPaymentStatus,
} from "./premium-insurance-model";
import {centToUsd} from "../../../../../cores/helpers/cent-to-usd";
import {Fade, IconButton, Menu, MenuItem} from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import styled from "styled-components";
import {capFirst} from "../../../../../cores/helpers/cap-first";
import BNPRawTable from "../../../../shared/BNPTable/BNPRawTable";
import {
  archiveEmployerPremiumPayment,
  createPremiumPaymentSubscription,
  createPremiumPaymentSubscriptionRequestToQuote,
  getBriefPremiumPaymentSubscriptions,
  getPremiumPaymentSubscriptions,
  updateEmployerPremiumPaymentStatus,
} from "../../../../../services/premium-payment-services";
import {closeConfirmDialog, openConfirmDialog,} from "../../../../../cores/utils/confirmDialog";
import {addLoading, removeLoading} from "../../../../../cores/utils/loading";
import {formatterUSD} from "../../../../../cores/helpers/format-usd";
import moment from "moment";
import { getInfoByToken } from "../../../../../cores/utils/helpers";

const StyledIconButton = styled(IconButton)`
  padding: 0 !important;
`;

type PremiumInsuranceProps = {
  employerId: number;
};

export const PremiumInsurance = React.memo(function PremiumInsurance(
  props: PremiumInsuranceProps
) {
  const [displayModal, setDisplayModal] = useState<boolean>(false);
  const [premiumInsuranceData, setPremiumInsuranceData] = useState<
    PremiumInsuranceType[]
  >([]);
  const [actionProduct, setActionProduct] = useState<
    PremiumInsuranceType | undefined
  >(undefined);
  const nextMonth = new Date().setMonth(new Date().getMonth() + 1)
  const [editModelData, setEditModelData] = useState<any>({
    productId: -1,
    providerId: -1
  });
  const role = getInfoByToken().permission;

  const actionType: { [key: string]: any } = {
    edit: {
      buttonTitle: "Edit",
    },
    active: {
      buttonTitle: "Activate",
      dialogTitle: "Group Coverage",
      dialogContent:
      `By activating this coverage this company will be charged a total monthly amount of ${
        actionProduct &&
        formatterUSD("currency").format(
          centToUsd(actionProduct.monthlyAmount * actionProduct.noOfEmployee)
        )
      } starting from ${moment(nextMonth).format('01/MM/YYYY')} The first payment will be processed on ${
        moment(nextMonth).format('05/MM/YYYY')
      }.\n
      Are you sure you want to activate this Group Coverage?`,
      dialogHandleOnOk: (isOk: boolean) =>
        isOk &&
        actionProduct &&
        handleUpdateEmployerPremiumPaymentStatus(
          actionProduct.id,
          PremiumPaymentStatus.ACTIVE
        ),
    },
    cancel: {
      buttonTitle: "Cancel",
      dialogTitle: "Cancel Group Coverage",
      dialogContent:
        "Are you sure you want to cancel this company’s Group Coverage?",
      dialogHandleOnOk: (isOk: boolean) =>
        isOk &&
        actionProduct &&
        handleUpdateEmployerPremiumPaymentStatus(
          actionProduct.id,
          PremiumPaymentStatus.CANCELLED
        ),
    },
    archive: {
      buttonTitle: "Archive",
      dialogTitle: "Archive Group Coverage",
      dialogContent:
        "Are you sure you want to archive this company’s Group Coverage?",
      dialogHandleOnOk: (isOk: boolean) =>
        isOk &&
        actionProduct &&
        handleArchiveEmployerPremiumPayment(actionProduct.id),
    },
  };

  const handleUpdateEmployerPremiumPaymentStatus = async (
    id: number,
    status: PremiumPaymentStatus
  ) => {
    addLoading();
    await updateEmployerPremiumPaymentStatus(id, status);
    await loadPremiumPaymentData();
    removeLoading();
  };

  const handleArchiveEmployerPremiumPayment = async (id: number) => {
    addLoading();
    await archiveEmployerPremiumPayment(id);
    await loadPremiumPaymentData();
    removeLoading();
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    productId: number
  ) => {
    setActionProduct(
      premiumInsuranceData.find((item) => item.id === productId) || undefined
    );
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const showModalConfirm = (action: string) => {
    openConfirmDialog({
      id: "PremiumPaymentActionConfirm",
      open: true,
      title: actionType[action].dialogTitle,
      content: actionType[action].dialogContent,
      onClose: (isOk: boolean) => {
        actionType[action].dialogHandleOnOk(isOk);
        closeConfirmDialog();
      },
    });
  };

  useEffect(() => {
    loadPremiumPaymentData().then();
  }, [props.employerId]);

  const loadPremiumPaymentData = async () => {
    if (props.employerId) {
      const result = await getPremiumPaymentSubscriptionsByRole(props.employerId);
      setPremiumInsuranceData(result.data);
    }
  };

  const getPremiumPaymentSubscriptionsByRole = (employerId: number) => {
    
    if (role === "super admin") {
      return getPremiumPaymentSubscriptions(employerId);
    }
    return getBriefPremiumPaymentSubscriptions(employerId);

  }

  const processPremiumInsuranceData = (
    premiumInsuranceData: PremiumInsuranceType[]
  ): PremiumInsuranceTableType[] => {
    return premiumInsuranceData.map((item) => {
      return {
        ...item,
        noOfEmployees: item.noOfEmployee,
        monthlyAmount: `$${centToUsd(item.monthlyAmount)}`,
        totalAmount: `$${centToUsd(item.noOfEmployee * item.monthlyAmount)}`,
        status: (
          <StyledSpanStatus title={item.status}>
            {capFirst(item.status)}
          </StyledSpanStatus>
        ),
        actions: (
          <StyledIconButton
            aria-controls="fade-menu"
            aria-haspopup="true"
            onClick={(event) => handleClick(event, item.id)}
          >
            <MoreHorizIcon style={{ color: "#003049" }} />
          </StyledIconButton>
        ),
      };
    });
  };

  const premiumInsuranceTableData = useMemo(
    () => processPremiumInsuranceData(premiumInsuranceData),
    [premiumInsuranceData]
  );

  const handleSubmitSubscription = async (request: any) => {
    await createPremiumPaymentSubscription(request);
    await loadPremiumPaymentData();
    handleCloseModal();
  };

  const handleSubmitSubscriptionRequestToQuote = async (request: any) => {
    await createPremiumPaymentSubscriptionRequestToQuote(request);
    await loadPremiumPaymentData();
    handleCloseModal();
  };

  const handleCloseModal = () => {
    setEditModelData({
      productId: -1,
      providerId: -1
    })
    setDisplayModal(false);
  };

  const showModelEdit = (actionProduct: PremiumInsuranceType) => {
    setEditModelData({
      productId: actionProduct.productId,
      providerId: actionProduct.providerId
    })
    setDisplayModal(true);
  }

  if (role !== "super admin") {
    return (
      <Fragment>
        <StyledContentBox>
          <StyledSpanLargerTitle>
            Monthly Group Contributions
          </StyledSpanLargerTitle>
          <BNPRawTable
            columns={PremiumInsuranceTableAdminColumns}
            tableWidth="100%"
            tableData={premiumInsuranceTableData}
          />
        </StyledContentBox>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <StyledContentBox>
        <StyledSpanLargerTitle>
          Monthly Group Contributions
          <StyledSpanEditInfo onClick={() => setDisplayModal(true)}>
            <CreateIcon fontSize="small" /> Add
          </StyledSpanEditInfo>
        </StyledSpanLargerTitle>
        <BNPRawTable
          columns={PremiumInsuranceTableColumns}
          tableWidth="100%"
          tableData={premiumInsuranceTableData}
        />
      </StyledContentBox>
      <PremiumInsuranceActionMenu
        actionProduct={actionProduct}
        anchorEl={anchorEl}
        handleCloseMenu={handleCloseMenu}
        showModalConfirm={showModalConfirm}
        actionType={actionType}
        showModelEdit={showModelEdit}
      />
      <PremiumInsuranceModal
        currentSubscription={premiumInsuranceData}
        open={displayModal}
        setOpen={setDisplayModal}
        handleClose={handleCloseModal}
        data={editModelData}
        handleSubmit={handleSubmitSubscription}
        handleSubmitRequestToQuote={handleSubmitSubscriptionRequestToQuote}
      />
    </Fragment>
  );
});

type PremiumInsuranceActionMenuProps = {
  actionProduct?: PremiumInsuranceType;
  anchorEl: null | HTMLElement;
  handleCloseMenu: () => void;
  showModalConfirm: (action: string) => void;
  actionType: { [key: string]: any };
  showModelEdit: (actionProduct: PremiumInsuranceType) => void;
};

const statusForActionDisplay: { [key: string]: PremiumPaymentStatus[] } = {
  edit: [PremiumPaymentStatus.REQUESTED],
  active: [PremiumPaymentStatus.QUOTED, PremiumPaymentStatus.CANCELLED],
  cancel: [PremiumPaymentStatus.ACTIVE],
  archive: [PremiumPaymentStatus.ACTIVE, PremiumPaymentStatus.CANCELLED, PremiumPaymentStatus.QUOTED, PremiumPaymentStatus.REQUESTED],
};

const PremiumInsuranceActionMenu = (props: PremiumInsuranceActionMenuProps) => {
  const open = Boolean(props.anchorEl);
  const { actionProduct } = props;

  const actionType: { [key: string]: any } = useMemo(() => props.actionType, [props.actionType]);

  const displayButton = (buttonType: string) =>
    (actionProduct &&
      statusForActionDisplay[buttonType].includes(actionProduct.status)) ||
    false;

  const handleOnClick = (key: string) => {
    props.handleCloseMenu();
    if (key === 'edit' && !!actionProduct) {
      props.showModelEdit(actionProduct);
    } else {
      props.showModalConfirm(key);
    }
  }

  return (
    <Menu
      id="fade-menu"
      key={props.actionProduct && props.actionProduct.id}
      anchorEl={props.anchorEl}
      keepMounted
      open={open}
      onClose={props.handleCloseMenu}
      TransitionComponent={Fade}
    >
      {Object.keys(actionType)
        .filter((key) => displayButton(key))
        .map((key) => (
          <MenuItem
            key={key}
            onClick={() => handleOnClick(key)}
            style={{
              fontFamily: "Graphik Light",
              color: key === "archive" ? "#FF0000" : "inherit",
            }}
          >
            {actionType[key].buttonTitle || capFirst(key)}
          </MenuItem>
        ))}
    </Menu>
  );
};

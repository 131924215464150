import React from 'react';
import styled from "styled-components";
import {Accordion as ExpansionPanel} from "@material-ui/core";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import {TableSortType} from "./BNPTableType";

import {
  StyledChangeDetail,
  StyledExpansionLabel,
  StyledExpansionPanelDetails,
  StyledExpansionPanelSummary,
  StyledHideForDesktop,
  StyledHideForMobile, StyledSpanExpansionEdit,
  StyledTitleExpansion
} from "../styled";
import {
  StyledArrowDropDownIcon,
  StyledArrowDropUpIcon,
  StyledBodyTable,
  StyledCellTable,
  StyledHeaderTable,
  StyledImportExportIcon,
  StyledLinkBodyTable,
  StyledTable
} from "../table-styled";
import { TableSortDataType } from './BNPTableModel';
import moment from 'moment';
import { FE_DATE_FORMAT, FE_MONTH_FORMAT, FE_YEAR_FORMAT } from '../../../cores/utils/format/date-time-format';
import { formatterUSD } from '../../../cores/helpers/format-usd';
import { centToUsd } from '../../../cores/helpers/cent-to-usd';

const StyledOverflowTableWrapper = styled.div`
  width: 100%;
  display: block;
  overflow-x: auto;
  white-space: nowrap;
`;

const StyledOverflowTable = styled.div`
`;

export const StyledDivExport = styled.div`
  font-weight: bold;
  line-height: 16px;
  color: ${props => props.theme.infoColor};
  width: fit-content;
  border: 1px solid ${props => props.theme.infoColor};
  border-radius: 32px;
  padding: 15px 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  .icon {
    padding-right: 4px;
  }

  @media screen and (max-width: 768px) {
    margin-top: 16px;
  }
  cursor: pointer;
`;

type BNPRawTableProps = {
  columns: TableSortType[];
  tableWidth: string;
  tableData: any[];
  tableName?: string;
  handleChangeSortColumn?: (columnId: string) => void;
  cellComponent?: any;
};

function BNPRawTable(props: BNPRawTableProps) {

  const getColumnData = (item: any, col: TableSortType) => {
    if (col.type === TableSortDataType.DATE) {
      return !!item[col.columnId] && moment(item[col.columnId]).format(FE_DATE_FORMAT);
    }
    if (col.type === TableSortDataType.MONTH) {
      return !!item[col.columnId] && moment(item[col.columnId]).format(FE_MONTH_FORMAT);
    }
    if (col.type === TableSortDataType.YEAR) {
      return !!item[col.columnId] && moment(item[col.columnId]).format(FE_YEAR_FORMAT);
    }
    if (col.type === TableSortDataType.CURRENCY) {
      return formatterUSD("currency", "USD").format(centToUsd(item[col.columnId]));
    }
    if (col.type === TableSortDataType.PERCENTAGE) {
      return formatterUSD("percent").format(item[col.columnId]);
    }
    return item[col.columnId];
  }

  const CellComponent = props.cellComponent || StyledCellTable;

  return (
      <>
        <div className="row">
          <div className="col-lg-12 mt-4">
            <StyledHideForMobile>
              <StyledTable>
                <StyledOverflowTableWrapper>
                  <StyledOverflowTable style={{width: props.tableWidth}}>
                    <StyledHeaderTable>
                      {props.columns.map((column, index) => (
                          <CellComponent
                              width={column.width}
                              key={index}
                              className="cur"
                              onClick={() => !!props.handleChangeSortColumn && column.sortColumn && props.handleChangeSortColumn(column.columnId)}
                          >
                            {column.columnName}
                            <span>
                              {!!props.handleChangeSortColumn && column.sortColumn ? (column.sortType ? (
                                  column.sortType === "ASC" ? (
                                      <StyledArrowDropDownIcon/>
                                  ) : (
                                      <StyledArrowDropUpIcon/>
                                  )
                              ) : (
                                  <StyledImportExportIcon/>
                              )) : null}
                            </span>
                          </CellComponent>
                      ))}
                    </StyledHeaderTable>
                    <div>
                      {props.tableData.map((item, i) => (!!item.linkTo ?
                          (<StyledLinkBodyTable
                              to={item.linkTo}
                              key={i}
                          >
                            {props.columns.map((col, j) => (
                                <StyledCellTable key={`bnp_table_row_${i}_${j}`} width={col.width}>
                                  {getColumnData(item, col)}
                                </StyledCellTable>
                            ))}
                          </StyledLinkBodyTable>) :
                          (<StyledBodyTable key={`bnp_table_row_${i}`}>
                            {props.columns.map((col, j) => (
                                <StyledCellTable key={`bnp_table_row_${i}_${j}`} width={col.width}>
                                  {getColumnData(item, col)}
                                </StyledCellTable>
                            ))}
                          </StyledBodyTable>)
                      ))}
                    </div>
                  </StyledOverflowTable>
                </StyledOverflowTableWrapper>
              </StyledTable>
            </StyledHideForMobile>

            <StyledHideForDesktop>
              {props.tableData.map((item, i) => (
                  <ExpansionPanel
                      square
                      defaultExpanded={false}
                      className="mt-2"
                      key={`bnp_mobile_table_row_${i}`}
                  >
                    <StyledExpansionPanelSummary
                        className="m-0"
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                        expandIcon={<ArrowDropUp/>}
                    >
                      <div className="w-100">
                        <StyledTitleExpansion>{props.columns[1].columnName}</StyledTitleExpansion>
                        <div className="mt-2">{item[props.columns[1].columnId]}</div>
                      </div>
                    </StyledExpansionPanelSummary>
                    <StyledExpansionPanelDetails>
                      {props.columns.map((col, j) => (
                          <div key={`bnp_mobile_table_row_${i}_${j}`} className="w-100 mt-2">
                            <StyledTitleExpansion>{col.columnName}</StyledTitleExpansion>
                            <div className="mt-1">{getColumnData(item, col)}</div>
                          </div>))}
                      <hr/>
                    </StyledExpansionPanelDetails>
                    {!!item.linkTo && <div className="text-center">
                      <StyledSpanExpansionEdit>
                        <StyledExpansionLabel>
                          <StyledChangeDetail
                              to={item.linkTo}
                          >
                            Detail
                          </StyledChangeDetail>
                        </StyledExpansionLabel>
                      </StyledSpanExpansionEdit>
                    </div>}
                  </ExpansionPanel>
              ))}
            </StyledHideForDesktop>
          </div>
        </div>
      </>
  );
}

export default BNPRawTable;
import { TRequiredTopUpReport, RequiredTopUpReportTypeDetail } from "./required-topup-report-type"
import { SortTable } from "../../../cores/models/SortTable";

export const requiredTopUpReportColumns: SortTable[] = [
    {
      columnId: "employerId",
      columnName: "ID",
      sortType: null,
      width: 10,
      sortColumn: true,
    },
    {
      columnId: "companyName",
      columnName: "Company Name",
      sortType: null,
      width: 20,
      sortColumn: true,
    },
    {
      columnId: "companyEmail",
      columnName: "Company Email",
      sortType: null,
      width: 20,
      sortColumn: true,
    },
    {
      columnId: "joinedDate",
      columnName: "Joined Date",
      sortType: null,
      width: 15,
      sortColumn: true,
    },
    {
      columnId: "topUpAmount",
      columnName: "Required Top Up Amount",
      sortType: null,
      width: 22,
      sortColumn: true,
    },
    {
      columnId: "topUpAction",
      columnName: "Action",
      sortType: null,
      width: 10,
      sortColumn: false,
    },
]

export const RequiredTopUpReportType: TRequiredTopUpReport<RequiredTopUpReportTypeDetail> = {
    PREFUNDING: {
      type: "prefunding",
      name: "Prefunding",
      columns: requiredTopUpReportColumns,
    },
    INSUFFICIENT_FUND: {
      type: "insufficient_fund",
      name: "Insufficient Fund",
      columns: requiredTopUpReportColumns,
    },
}
import React from "react";
import CompanyManagementComponent from "./company-management-component"
import {RouteChildrenProps} from "react-router";
import {exportEmployersByAdmin, getEmployersByAdmin} from "../../../services/partner-service";
import {TableSortType} from "../../shared/BNPTable/BNPTableType";

type Props = RouteChildrenProps;
type State = {}

export default class AdminCompanyManagement extends React.Component<Props, State> {

    companyOptions = {
        allowSpecifyReferralPartner: true,
    }

    render() {
        return (
            <CompanyManagementComponent
                defaultColumns={columnsHeaderTable}
                fetchData={getEmployersByAdmin}
                exportExcel={exportEmployersByAdmin}
                options={this.companyOptions}
                {...this.props}
            />
        );
    }
}

const columnsHeaderTable: TableSortType[] = [
    {
        columnId: "id",
        columnName: "ID",
        sortColumn: true,
        sortType: null,
        width: 10,
    },
    {
        columnId: "companyName",
        columnName: "Company name",
        sortColumn: true,
        sortType: null,
        width: 20,
    },
    {
        columnId: "companyEmail",
        columnName: "Email",
        sortColumn: true,
        sortType: null,
        width: 20,
    },
    {
        columnId: "noOfEmployees",
        columnName: "No of employees",
        sortColumn: true,
        sortType: null,
        width: 15,
    },
    {
        columnId: "joinedDate",
        columnName: "Joined Date",
        sortColumn: true,
        sortType: null,
        width: 15,
        type: 'DATE'
    },
    {
        columnId: "referralPartnerName",
        columnName: "Partner",
        sortColumn: true,
        sortType: null,
        width: 15,
    },
    {
        columnId: "referralPartnerUserName",
        columnName: "Partner Team Member",
        sortColumn: true,
        sortType: null,
        width: 15,
    },
    {
        columnId: "status",
        columnName: "Status",
        sortColumn: true,
        sortType: null,
        width: 10,
    },
    {
        columnId: "actions",
        columnName: "Actions",
        sortColumn: false,
        sortType: null,
        width: 10
    }
];

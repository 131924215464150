import React, {ChangeEvent} from 'react';
import {WeeklyWorkingHours, WorkingHour} from "../marketplace-type";
import {capitalize} from "lodash";
import BNPTimePicker from "../../../../cores/helpers/date-picker/time-picker";
import {CheckBox} from "../../../../cores/helpers/checkbox/checkbox";

type WorkingHourProps = {
  data: WeeklyWorkingHours,
  handleChangeCheckbox: (event: ChangeEvent<HTMLInputElement>, dayOfWeek: string) => void;
  handleChangeTime: (dateTime: Date | string | null, dayOfWeek: string, name: string) => void;
}

function WorkingHours(props: WorkingHourProps): JSX.Element {
  const handleChangeTime = (date: any, name: string) => {
    const datetime = new Date(date);
    datetime.setSeconds(0, 0);
    props.handleChangeTime(datetime, name.split('-')[0], name.split('-')[1]);
  }

  const workingDateItem = (wokingHour: WorkingHour) => (
    <div className={'row mb-2 align-items-center'} key={`woking-hour-${wokingHour.dayOfWeek}-item`}>
      <div className={'col-4'}>
        <CheckBox
          key={`working-hour-${wokingHour.dayOfWeek}-checkbox`}
          name={wokingHour.dayOfWeek}
          checked={props.data[wokingHour.dayOfWeek].checked}
          onChange={(e) => props.handleChangeCheckbox(e, wokingHour.dayOfWeek)}
          title={
            <div
              className="text-left"
              dangerouslySetInnerHTML={{
                __html: capitalize(props.data[wokingHour.dayOfWeek].dayOfWeek),
              }}
            />
          }
        />
      </div>
      <div className={'col-4'}>
        <BNPTimePicker
          key={`woking-hour-${wokingHour.dayOfWeek}-from`}
          name={`${wokingHour.dayOfWeek}-from`}
          placeholder={'From'}
          value={wokingHour.from}
          onChange={handleChangeTime}
          disabled={!props.data[wokingHour.dayOfWeek].checked}
        />
      </div>
      <div className={'col-4'}>
        <BNPTimePicker
          key={`woking-hour-${wokingHour.dayOfWeek}-to`}
          name={`${wokingHour.dayOfWeek}-to`}
          placeholder={'To'}
          value={wokingHour.to}
          onChange={handleChangeTime}
          disabled={!props.data[wokingHour.dayOfWeek].checked}
        />
      </div>
    </div>
  )

  return (
    <div>
      {Object.values(props.data).map((workingHour: WorkingHour) => {
        return workingDateItem(workingHour);
      })}
    </div>
  );
}

export default WorkingHours;
import React from "react";
import {
  StyledBigTitle,
  StyledButtonSubmit,
  LandingStyledTextValidator,
  StyledPError,
} from "../../shared/styled";
import { ValidatorForm } from "react-material-ui-form-validator";
import {
  LoginReqModel,
  loginAdmin,
  getInitWallet,
} from "../../../services/auth-service";
import { publishMessage } from "../../../cores/utils/message";
import { removeLoading } from "../../../cores/utils/loading";
import { setToken, getToken, isSuperAdmin, isAdminUser, isPartnerUser } from "../../../cores/utils/helpers";
import { RouteChildrenProps } from "react-router";
import {
  homePage,
  claimManagementSearchParamDefault,
  requiredTopUpReportURL,
  forgotPasswordURL,
  partnerDashboardURL
} from "../../../cores/constants/url-config";
import ShowPasswordView from "../../../cores/helpers/input/input";
import { isBeniplusUI } from "../../../cores/config/component/ui-config";
import styled from "styled-components";
import { Link } from "react-router-dom";


const StyledDivForgotPassword = styled(Link)`
  color: ${(theme) => theme.theme.primaryColor};
  font-size: 14px;
  line-height: 22px;
  float: right;
`;

type State = {
  signIn: LoginReqModel;
  isValid: boolean;
  isIncorrectAccount: boolean;
  message: string;
};

type Props = RouteChildrenProps;

export default class SignIn extends React.Component<Props, State> {
  state: State = {
    signIn: {
      username: "",
      password: "",
    },
    isValid: false,
    isIncorrectAccount: false,
    message: "",
  };

  UNSAFE_componentWillMount() {
    if (getToken()) {

      this.directByPermission();

    }
  }

  signIn = () => {
    let signIn: LoginReqModel = Object.assign(this.state.signIn);
    signIn.username = signIn.username.toLowerCase().trim();

    loginAdmin(signIn)
      .then((result: any) => {
        removeLoading();
        setToken(result.data.access_token);

        this.initWalletByPermission();
        this.directByPermission();

      })
      .catch((error) => {
        removeLoading();
        // if err
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          if (error.response.data.status === 500) {
            publishMessage({
              message: "We’ve run into a problem. Please try again later",
              variant: "error",
            });
          } else {
            this.setState({
              isIncorrectAccount: true,
              message: error.response.data.message,
            });
          }
        } else {
          publishMessage({
            message:
              "System error. We apologize for the inconvenience. Please try again.",
            variant: "error",
          });
        }
      });
  };

  directByPermission = () => {
    if (isAdminUser()) {
      if (!isBeniplusUI && !isSuperAdmin()) {
        this.props.history.push(requiredTopUpReportURL);
      } else {
        this.props.history.push(homePage + claimManagementSearchParamDefault);
      }
    }

    if (isPartnerUser()) {
      this.props.history.push(partnerDashboardURL);
    }
    
  }

  initWalletByPermission = () => {
    if (isAdminUser()) {
      getInitWallet();
    }
  }

  handleChange = (event: any) => {
    let signIn = Object.assign(this.state.signIn);

    signIn[event.target.name] = event.target.value;
    this.setState({ signIn: signIn, isIncorrectAccount: false });
  };

  validatorListener = (isValid: boolean) => {
    this.setState({ isValid: isValid && this.validate() });
  };

  validate = (): boolean => {
    if (this.state.signIn.username && this.state.signIn.password) {
      return true;
    }
    return false;
  };

  render() {
    return (
      <div>
        <StyledBigTitle className="mt-5">Sign In</StyledBigTitle>

        <ValidatorForm className="w-100" onSubmit={this.signIn}>
          <div className="row pt-3">
            <div className="col-lg-6 mb-2">
              <LandingStyledTextValidator
                onChange={this.handleChange}
                type="text"
                name="username"
                placeholder="Username/Email"
                value={this.state.signIn.username}
                validators={["required"]}
                errorMessages={["Please enter user name or email."]}
                validatorListener={this.validatorListener}
                tabIndex={-1}
                role="application"
                aria-hidden="true"
              />
            </div>
            <div className="col-lg-6 mb-2">
              <ShowPasswordView
                onChange={this.handleChange}
                name="password"
                placeholder="Password"
                value={this.state.signIn.password}
                validators={["required"]}
                errorMessages={["Please enter password."]}
                validatorListener={this.validatorListener}
                tabIndex={-1}
              />
            </div>
            {this.state.isIncorrectAccount && (
              <StyledPError className="col-lg-12 mb-0">
                {this.state.message}
              </StyledPError>
            )}

            <div className="col-lg-12 mb-2">
              <StyledDivForgotPassword to={forgotPasswordURL}>
                Forgot the password
              </StyledDivForgotPassword>
            </div>

            <div className="mt-3 col-lg-12">
              <div className="col-lg-4 p-0 float-right">
                <StyledButtonSubmit type="submit" submit={this.state.isValid}>
                  Sign in
                </StyledButtonSubmit>
              </div>
            </div>
          </div>
        </ValidatorForm>
      </div>
    );
  }
}

import { getAsync, getFileAsync } from "../../../cores/utils/http-client";
import { AxiosResponse } from "axios";
import { SearchCriteria } from "./deposit-withdraw-transaction-history-type";
import { toUTCTime } from "../../../cores/helpers/to-utc-time";

const URL_WALLET_PARTNER = "manage-wallet-partner/partner/wallet";

const mapQueryString = (model: any): string => {
    let temp: any = { ...model };
    let query = '?';

    Object.keys(model).forEach((key) => {
        const val = temp[key];
        if (val) {
            if (Array.isArray(val)) {
                val.forEach(t => {
                    query += `${key}=${t}&`;
                });
            }
            else {
                if (val instanceof Date) {
                    query += `${key}=${toUTCTime(val)}&`;
                } else {
                    query += `${key}=${val}&`;
                }
            }
        }
    });

    return query;
}

const search = async (model: SearchCriteria): Promise<AxiosResponse> => {
    const query = mapQueryString(model);
    const url = `${URL_WALLET_PARTNER}/history${query.substring(query.length - 1, 0)}`;

    return await getAsync(url);
}

const exportExcel = async (model: SearchCriteria): Promise<AxiosResponse> => {
    const query = mapQueryString(model);
    const url = `${URL_WALLET_PARTNER}/history/export${query.substring(query.length - 1, 0)}`;

    return await getFileAsync(url);
}

export default {
    search,
    exportExcel
};
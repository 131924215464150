import React, {useEffect, useMemo, useState} from 'react'
import { BNPModal } from '../../../shared/BNPModal/BNPModal'
import {StyledButtonSubmit, StyledInputMask, StyledPTitle} from "../../../shared/styled";
import {BNPSelect} from "../../../../cores/helpers/select/select";
import {BNPInput, BNPValidateInput} from "../../../../cores/helpers/input/input";
import {EditVendorType, Language, WeeklyWorkingHours} from "../marketplace-type";
import {BNPMultiSelect} from "../../../../cores/helpers/input/BNPMultiSelect";
import GoogleLocationInput, {ResponseLocation} from "../../../../cores/helpers/google-map-services/google-location";
import MultipleImageUpload from "../../../../cores/helpers/upload-image/image-upload-multiple";
import {Provinces} from "../../company-management/company-management-type";
import {findProvinceIdByName} from "../../company-management/detail/company-management-detail";
import WorkingHours from "./working-hours";
import {getFileUrl} from "../../../../services/file-service";
import {CostRangeOptions, VendorPublishToOptions} from "../marketplace-model";
import {validateEmail} from "../../../../cores/config/config";
import {ValidatorForm} from "react-material-ui-form-validator";
import BNPTextArea from "../../../../cores/helpers/input/BNPTextArea";

type AddVendorModalProps = {
  isEdit: boolean;
  editData: EditVendorType;
  open: boolean;
  handleClose: () => void;
  provinces: Provinces[];
  languages: Language[];
  services: any[];
  handleAddVendor: (model: EditVendorType) => Promise<void>;
  handleEditVendor: (model: EditVendorType) => Promise<void>;
}

type ValidAccessUrlMap = {
  [key: string]: string;
}

function AddVendorModal(props: AddVendorModalProps) {
  const [data, setData] = React.useState<EditVendorType>({...defaultData});
  const [validAccessUrlMap, setValidAccessUrlMap] = useState<ValidAccessUrlMap>({})
  const [thumbnail, setThumbnail] = useState<string>('');

  useEffect(() => {
    if (!props.open) return;
    if (props.isEdit) {
      setData({...props.editData})
      for (const photo of props.editData.photos) {
        getFileUrl(photo).then(res => {
          setValidAccessUrlMap(prevState => ({
            ...prevState,
            [res.data]: photo
          }));
          if (photo === props.editData.coverImageUrl) {
            setThumbnail(res.data);
          }
        })
      }
    } else {
      setData({...defaultData})
    }
  }, [props.isEdit, props.open]);

  const costRangeId = React.useMemo(() => {
    if (!data.costFrom && !data.costTo) return -1;

    const costAvg = (data.costFrom + (data.costTo || data.costFrom))/2;

    for (const range of CostRangeOptions) {
      if (costAvg >= range.costFrom && ((!!range.costTo && costAvg < range.costTo) || !range.costTo)) {
        return range.id
      }
    }

    return -1;
  }, [data.costFrom, data.costTo])

  const isValid = useMemo(() => {
    return !!data.name &&
      !!data.email &&
      !!data.email.match(validateEmail) &&
      !!data.website &&
      !!data.publishTo &&
      !!data.address &&
      !!data.city &&
      !!data.postcode &&
      !!data.provinceId && data.provinceId !== -1 &&
      !!data.lat && !!data.lng &&
      data.spokenLanguageIds.length > 0 &&
      data.serviceIds.length > 0 &&
      // Disable working hours validation
      // Object.values(data.workingHours).filter(item => item.checked && !!item.from && !!item.to).length > 0 &&
      data.photos.length > 2 && data.photos.length < 6
  }, [data])

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    if (name === 'vendorName') {
      setData({...data, name: value});
      return;
    }

    setData({ ...data, [name]: value })
  }

  const handleChangeLanguageSpoken = (event: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, spokenLanguageIds: [...event.target.value] })
  }

  const handleChangeService = (event: React.ChangeEvent<HTMLInputElement>) => {
    const serviceIds = [...event.target.value].map(i => Number(i))
    setData({ ...data, serviceIds })
  }

  const handleChangeSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name === 'costRange') {
      const chosenOption = CostRangeOptions.find(item => item.id == Number(value));
      if (!!chosenOption) {
        setData({ ...data, costFrom: chosenOption.costFrom, costTo: chosenOption.costTo })
      } else {
        setData( { ...data, costFrom: 0, costTo: null })
      }
      return;
    }

    setData({ ...data, [name]: value });
  }

  const setAddress = (address: string) => {
    setData({ ...data, address })
  }

  const setLocation = (location: ResponseLocation) => {
    setData({
      ...data,
      postcode: location.postcode || "",
      city: location.city,
      provinceId: findProvinceIdByName(location.province, props.provinces),
      lat: location.lat || 0,
      lng: location.lng || 0
    })
  };

  const handleChangeDateCheckbox = (event: any, dayOfWeek: string) => {
    const { checked } = event.target;

    const workingHourClone = {...data.workingHours};

    workingHourClone[dayOfWeek].checked = checked;

    if (!checked) {
      workingHourClone[dayOfWeek].from = '';
      workingHourClone[dayOfWeek].to = '';
    }

    setData({ ...data, workingHours: workingHourClone });
  }

  const handleChangeTime = (datetime: Date | string | null, dayOfWeek: string, name: string) => {
    const workingHourClone = {...data.workingHours};
    workingHourClone[dayOfWeek][name] = datetime;

    setData({ ...data, workingHours: workingHourClone });
  }

  const addFile = async (url: string) => {
    let coverImageUrl = '';
    setData(prevState => ({
      ...prevState, photos: [...prevState.photos, url]
    }));
    await getFileUrl(url).then(res => {
      setValidAccessUrlMap(prevState => ({
        ...prevState,
        [res.data]: url}
      ));
      coverImageUrl = res.data;
    })

    if (!thumbnail) {
      setThumbnail(coverImageUrl);
    }
  }

  const removeFile = (url: string) => {
    const photoUrl = validAccessUrlMap[url];
    let validAccessUrlMapClone = {...validAccessUrlMap};
    delete validAccessUrlMapClone[url];
    setValidAccessUrlMap(validAccessUrlMapClone);

    let photosClone = [...data.photos]
    const idx = photosClone.indexOf(photoUrl)
    photosClone.splice(idx, 1);

    setData({ ...data, photos: photosClone });
  }

  const handleChangeThumbnail = (url: string) => {
    setThumbnail(url);
  }

  const handleCloseModal = () => {
    setData({...defaultData});
    setValidAccessUrlMap({});
    setThumbnail('');
    props.handleClose();
  }

  const handleSubmit = async () => {
    const submitData = {...data, coverImageUrl: validAccessUrlMap[thumbnail] || data.photos[0]};
    if (props.isEdit) {
      await props.handleEditVendor(submitData);
    } else {
      await props.handleAddVendor(submitData);
    }
    handleCloseModal();
  }

  const body = () => (<ValidatorForm
      className="w-100"
      onSubmit={handleSubmit}
      debounceTime={500}
    >
    <div className="row">
      <div className="col-md-6 mb-4">
        <StyledPTitle className="mb-2">Vendor Name*</StyledPTitle>
        <BNPValidateInput
          value={data.name || ""}
          name="vendorName"
          onChange={handleChangeInput}
          placeholder="Vendor Name"
          validators={[
            "required"
          ]}
          errorMessages={[
            "Please enter vendor name"
          ]}
          tabIndex={1}
        />
      </div>
      <div className="col-md-6 mb-4">
        <StyledPTitle className="mb-2">Vendor Email*</StyledPTitle>
        <BNPValidateInput
          onChange={handleChangeInput}
          name="email"
          placeholder="Vendor Email"
          value={data.email || ""}
          validators={[
            "required",
            `matchRegexp:${validateEmail}`
          ]}
          errorMessages={[
            "Please enter email",
            "Email is not valid"
          ]}
          tabIndex={2}
        />
      </div>
      <div className="col-12 mb-4">
        <StyledPTitle className="mb-2">Language Spoken*</StyledPTitle>
        <BNPMultiSelect
          options={props.languages}
          value={data.spokenLanguageIds}
          name="languageSpoken"
          onChange={handleChangeLanguageSpoken}
          tabIndex={3}
          placeholder="Language Spoken"
          allowAll={false}
        />
      </div>
      <div className="col-12 mb-4">
        <StyledPTitle className="mb-2">Description</StyledPTitle>
        <BNPTextArea
          value={data.description || ""}
          name="description"
          handleChange={handleChangeInput}
          placeholder="Description"
          rows={5}
          tabIndex={4}
        />
      </div>
      <div className="col-12 mb-4">
        <StyledPTitle className="mb-2">Service*</StyledPTitle>
        <BNPMultiSelect
          options={props.services}
          value={data.serviceIds}
          name="serviceIds"
          onChange={handleChangeService}
          tabIndex={5}
          placeholder="Services"
          allowAll={false}
        />
      </div>
      <div className="col-lg-12 mb-4">
        <StyledPTitle className="mb-2">Address*</StyledPTitle>
        <GoogleLocationInput
          id="address"
          address={data.address || ""}
          setAddress={setAddress}
          setLocation={setLocation}
          placeholder="Address"
          tabIndex={6}
        />
      </div>
      <div className="col-md-4 mb-4">
        <StyledPTitle className="mb-2">Province*</StyledPTitle>
        <BNPSelect
          options={props.provinces.map((province) => ({
            id: province.id,
            name: province.name,
          }))}
          placeholder="Province"
          name="provinceId"
          value={data.provinceId || -1}
          onChange={handleChangeInput}
          tabIndex={7}
        />
      </div>
      <div className="col-md-4 mb-4">
        <StyledPTitle className="mb-2">City*</StyledPTitle>
        <BNPInput
          value={data.city || ""}
          name="city"
          onChange={handleChangeInput}
          placeholder="City"
          tabIndex={8}
        />
      </div>
      <div className="col-md-4 mb-4">
        <StyledPTitle className="mb-2">Postcode*</StyledPTitle>
        <StyledInputMask
          mask="*** ***"
          value={data.postcode ? data.postcode.toUpperCase() : ""}
          onChange={handleChangeInput}
          name="postcode"
          placeholder="Postcode"
          tabIndex={9}
        />
      </div>
      <div className="col-12 mb-4">
        <StyledPTitle className="mb-2">Working Hour</StyledPTitle>
        <WorkingHours
          data={data.workingHours}
          handleChangeCheckbox={handleChangeDateCheckbox}
          handleChangeTime={handleChangeTime}
        />
      </div>
      <div className="col-md-6 mb-4">
        <StyledPTitle className="mb-2">Website*</StyledPTitle>
        <BNPInput
          value={data.website || ""}
          name="website"
          onChange={handleChangeInput}
          placeholder="Website"
          tabIndex={10}
        />
      </div>
      <div className="col-md-6 mb-4">
        <StyledPTitle className="mb-2">Publish To*</StyledPTitle>
        <BNPSelect
          options={VendorPublishToOptions}
          value={data.publishTo || -1}
          name="publishTo"
          placeholder="Publish To"
          onChange={handleChangeSelect}
          tabIndex={11}
        />
      </div>
      <div className="col-md-6 mb-4">
        <StyledPTitle className="mb-2">Cost Range</StyledPTitle>
        <BNPSelect
          options={CostRangeOptions}
          value={costRangeId || -1}
          name="costRange"
          onChange={handleChangeSelect}
          placeholder="Cost Range"
          tabIndex={12}
        />
      </div>
      <div className="col-md-6 mb-4">
        <StyledPTitle className="mb-2">Add Affiliate Link</StyledPTitle>
        <BNPInput
          value={data.affiliateLink || ""}
          name="affiliateLink"
          onChange={handleChangeInput}
          placeholder="Affiliate Link"
          tabIndex={13}
        />
      </div>
      <div className="col-md-6 mb-4">
        <StyledPTitle className="mb-2">Coupon</StyledPTitle>
        <BNPInput
          value={data.couponCode || ""}
          name="couponCode"
          onChange={handleChangeInput}
          placeholder="Coupon"
          tabIndex={14}
        />
      </div>
      <div className="col-md-6 mb-4">
        <StyledPTitle className="mb-2">Discount</StyledPTitle>
        <BNPInput
          value={data.discount || ""}
          name="discount"
          onChange={handleChangeInput}
          placeholder="Discount"
          tabIndex={15}
        />
      </div>
      <div className="col-12 mb-4">
        <StyledPTitle className="mb-2">Photos</StyledPTitle>
        <MultipleImageUpload
          addFile={addFile}
          addBlob={() => {
          }}
          urlImages={Object.keys(validAccessUrlMap)}
          folderName={'vendor-photos'}
          removeFile={removeFile}
          setThumbnail={(url) => handleChangeThumbnail(url)}
          thumbnail={thumbnail}
        />
      </div>
    </div>
  </ValidatorForm>)

  return (
    <BNPModal
      open={props.open}
      title={`${props.isEdit ? 'Edit' : 'Create New'} Vendor`}
      handleClose={handleCloseModal}
      body={body()}
      footer={<div className="col-md-2 p-0">
        <StyledButtonSubmit
          onClick={handleSubmit}
          submit={isValid}
          type="submit"
        >
          Save
        </StyledButtonSubmit>
      </div>}
    />
  )
}

export default AddVendorModal;

const defaultData: EditVendorType = {
  id: 0,
  name: "",
  email: "",
  spokenLanguageIds: [],
  description: "",
  serviceIds: [],
  address: "",
  postcode: "",
  city: "",
  provinceId: null,
  lat: 0,
  lng: 0,
  workingHours: {
    MONDAY: {
      dayOfWeek: 'MONDAY',
      checked: false,
      from: '',
      to: '',
      timezone: ''
    },
    TUESDAY: {
      dayOfWeek: 'TUESDAY',
      checked: false,
      from: '',
      to: '',
      timezone: ''
    },
    WEDNESDAY: {
      dayOfWeek: 'WEDNESDAY',
      checked: false,
      from: '',
      to: '',
      timezone: ''
    },
    THURSDAY: {
      dayOfWeek: 'THURSDAY',
      checked: false,
      from: '',
      to: '',
      timezone: ''
    },
    FRIDAY: {
      dayOfWeek: 'FRIDAY',
      checked: false,
      from: '',
      to: '',
      timezone: ''
    },
    SATURDAY: {
      dayOfWeek: 'SATURDAY',
      checked: false,
      from: '',
      to: '',
      timezone: ''
    },
    SUNDAY: {
      dayOfWeek: 'SUNDAY',
      checked: false,
      from: '',
      to: '',
      timezone: ''
    }
  } as WeeklyWorkingHours,
  website: "",
  costFrom: 0,
  costTo: null,
  publishTo: "",
  couponCode: "",
  discount: "",
  affiliateLink: "",
  photos: [],
  coverImageUrl: ""
}
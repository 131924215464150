import React, {useEffect} from 'react';
import styled from "styled-components";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@material-ui/icons";
import {Option} from "../../../cores/helpers/select/select";

const StyledHorizontalContainer = styled.div`
  width: auto;
  overflow: auto;

  /* width */
  ::-webkit-scrollbar {
    height: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const StyledHorizontalContent = styled.div`
    width: fit-content; /* Double the width for horizontal scrolling */
    white-space: nowrap; /* Prevents content from wrapping */
    transition: transform 0.3s ease;
`;

const StyledHorizontalItem = styled.div`
    display: inline-block;
    width: fit-content;
    min-width: 100px;
    color: ${(props) => props.theme.textColor};
    background-color: ${(props) => props.theme.infoColorLight};
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    cursor: pointer;

    &.active {
        background-color: ${(props) => props.theme.primaryColor};
        color: ${(props) => props.theme.sideBarTextColor};

        :hover {
            background-color: ${(props) => props.theme.primaryColor};
            opacity: 0.8;
        }
    }

    :hover {
        background-color: #e8edf2;
        text-decoration: none;
    }

    text-align: center;
    padding: 20px 15px;
    margin-right: 3px;
`;

const StyledScrollButton = styled.button`
    display: inline-block;
    border: none;
    padding: 10px;
    cursor: pointer;
    background: none;
    color: ${(props) => props.theme.textColor};

    &.scroll-left {
        left: 0;
    }

    &.scroll-right {
        left: 100%;
    }
`

type Props = {
  items: Option[];
  onClick: (item: Option) => void;
  activeItem: Option;
};

export function HorizontalMenu(props: Props) {
  const [showScrollButtons, setShowScrollButtons] = React.useState(false);
  const contentRef = React.createRef<HTMLDivElement>();

  useEffect(() => {
    // Function to check if content overflows horizontally
    const checkOverflow = () => {
      const content = contentRef.current;
      if (content) {
        setShowScrollButtons(content.scrollWidth > content.clientWidth);
      }
    };

    // Call checkOverflow on mount and on resize
    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, []);

  return (
    <div className={"container-fluid d-flex"}>
      {showScrollButtons && <StyledScrollButton
          className={"scroll-left"}
          onClick={() => {
            const content = document.getElementById("horizontal-menu-content");
            if (content) {
              content.scrollLeft -= 200;
            }
          }}
      >
          <KeyboardArrowLeft/>
      </StyledScrollButton>}
      <StyledHorizontalContainer id="horizontal-menu-content">
        <StyledHorizontalContent ref={contentRef}>
          {props.items.map((item) => (
            <StyledHorizontalItem
              key={item.id}
              onClick={() => props.onClick(item)}
              className={item.id === props.activeItem.id ? "active" : ""}
            >
              {item.name}
            </StyledHorizontalItem>
          ))}
        </StyledHorizontalContent>
      </StyledHorizontalContainer>
      {showScrollButtons && <StyledScrollButton
          className={"scroll-right"}
          onClick={() => {
            const content = document.getElementById("horizontal-menu-content");
            if (content) {
              content.scrollLeft += 200;
            }
          }}
      >
          <KeyboardArrowRight/>
      </StyledScrollButton>}
    </div>
  );
};
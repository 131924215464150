import { TextField } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import * as React from 'react';
import { Option } from '../select/select';
import styled from 'styled-components';

const StyledTextField = styled(TextField)`
  font-family: ${(props: any) => props.theme.textFont} !important;
  .MuiSelect-select {
    border-radius: 5px;
  }
  .MuiInputBase-input {
    display: block;
    width: 100%;
    height: 48px;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #2d2d2d;
    background-color: #fff;
    background-clip: padding-box;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-tap-highlight-color: transparent;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .MuiInputBase-root {
    border: 1px solid #ced4da;
    border-radius: 5px;
  }

  .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child {
    padding: 0 0.75rem;
  }
`

const StyledAddNewValueOption = styled.div`
    color: ${(props) => props.theme.infoColor};
`;

const filter = createFilterOptions<BNPAutoCompleteCreatableOptionType>();

type Props = {
    value?: any;
    name?: string;
    onChange?: (event: any, option: Option) => void;
    options: Option[];
    placeholder?: string;
    disabled?: boolean;
    tabIndex?: number;
    className?: string;
    addNewOptionText?: string;
};

export default function BNPAutoCompleteCreatable(props: Props) {

  const onChange = (event: any, newValue: any) => {
    let value: Option;
    if (typeof newValue === 'string') {
      value = {
        id: -1,
        name: newValue,
      };
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      value = {
        id: -1,
        name: newValue.inputValue,
      };
    } else {
      value = newValue;
    }
    props.onChange && props.onChange(event, value);
  }

  return (
    <Autocomplete
      value={props.value}
      onChange={onChange}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.name);
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            name: <StyledAddNewValueOption>{props.addNewOptionText || 'Add'} "{inputValue}"</StyledAddNewValueOption>,
            id: -1,
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="free-solo-with-text-demo"
      options={props.options}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.name || "";
      }}
      renderOption={(props, option) => {
        const { id: key, ...optionProps } = props;
        return (
          <div key={props.id}>
            {props.name}
          </div>
        );
      }}
      freeSolo
      renderInput={(params) => (
        <StyledTextField {...params} placeholder={props.placeholder || "Select or Create"} />
      )}
    />
  );
}

interface BNPAutoCompleteCreatableOptionType {
  inputValue?: string;
  id: number | string;
  name?: number | string | JSX.Element;
}
import {TableSortType} from "../../../../shared/BNPTable/BNPTableType";

export type PremiumInsuranceType = {
  id: number,
  productId: number,
  providerId: number,
  providerName: string,
  productName: string,
  noOfEmployee: number,
  monthlyAmount: number,
  status: PremiumPaymentStatus
}

export type PremiumInsuranceTableType = {

  id: number,
  providerName: string,
  productName: string,
  noOfEmployees: number,
  monthlyAmount: string,
  status: any,
  totalAmount: string,
  actions: any
}

export enum PremiumPaymentStatus {
  REQUESTED = "REQUESTED",
  QUOTED = "QUOTED",
  ACTIVE = "ACTIVE",
  CANCELLED = "CANCELLED"
}

export const PremiumInsuranceTableColumns: TableSortType[] =[
  {
    columnId: "productName",
    columnName: "Product",
    sortType: null,
    width: 30,
    sortColumn: false
  },
  {
    columnId: "noOfEmployees",
    columnName: "Employees",
    sortType: null,
    width: 15,
    sortColumn: false,
  },
  {
    columnId: "monthlyAmount",
    columnName: "Monthly Amount",
    sortType: null,
    width: 20,
    sortColumn: false,
  },
  {
    columnId: "totalAmount",
    columnName: "Total",
    sortType: null,
    width: 15,
    sortColumn: false,
  },
  {
    columnId: "status",
    columnName: "Status",
    sortType: null,
    width: 15,
    sortColumn: false,
  },
  {
    columnId: "actions",
    columnName: "",
    sortType: null,
    width: 5,
    sortColumn: false,
  },
]


export const PremiumInsuranceTableAdminColumns: TableSortType[] =[
  {
    columnId: "productName",
    columnName: "Product",
    sortType: null,
    width: 70,
    sortColumn: false
  },
  {
    columnId: "status",
    columnName: "Status",
    sortType: null,
    width: 20,
    sortColumn: false,
  },
]
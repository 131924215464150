import {
  getAsync,
  deleteAsync,
  postAsync,
  putAsync
} from "../cores/utils/http-client";
import { AxiosResponse } from "axios";
import {
  SearchParamCompany,
  SearchParamEmployee,
  CompanyDataEdit,
  BankingInformation,
  PaymentMethod,
  RequestPlanPreferences, EmployerPlan
} from "../components/pages/company-management/company-management-type";
import { SearchParamClaim } from "../components/pages/claim-management/claim-management-type";
import { ClassBenefit } from "../cores/helpers/add-class-benefits/add-class-benefits";
import { MedicalTravelData } from "../cores/helpers/medical-and-travel-coverage/medical-and-travel-coverage-type";
import { NewCompany } from "../components/pages/company-management/company-management-type";
import { TerminatingRequest } from "../../../src/components/pages/employer/employees/employee-type";
import { EnableStatusEnum } from "../components/HOC/withStatusButtonActions";

const URL_Employer = "/manage-employer/api";

export const getProvinces = (): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/locations/provinces`;

  return getAsync(url);
};

export const getCitiesByProvinceId = (
  provinceId: number
): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/locations/provinces/${provinceId}/cities`;

  return getAsync(url);
};

export const getEmployer = (id: number): Promise<AxiosResponse> => {
  let url = `/manage-employer/api/employers/${id}`;

  return getAsync(url);
};

export const getEmployerPlans = (id: number): Promise<AxiosResponse> => {
  let url = `/manage-employer/api/plan/all-info/${id}`;

  return getAsync(url);
};

export const getEmployeesByEmployer = (
  id: number,
  param: SearchParamEmployee
): Promise<AxiosResponse> => {
  let url = `/manage-employer/api/employers/${id}/list-employees`;

  return getAsync(url, param);
};

export const createEmployeeByAdmin = (
  req: any
): Promise<AxiosResponse> => {
  let url = `/manage-employer/api/employees`;

  return postAsync(url, req);
};

export const createNotT4EmployeeByAdmin = (
    req: any
): Promise<AxiosResponse> => {
  let url = `/manage-employer/api/employees/not-t4-employee`;

  return postAsync(url, req);
};

export const updateEmployeeByAdmin = (
  req: any
): Promise<AxiosResponse> => {
  let url = `/manage-employer/api/employees/`;

  return putAsync(url, req);
};

export const banEmployeeByAdmin = (
  id: number
): Promise<AxiosResponse> => {
  let url = `/manage-employer/api/employees/ban/${id}`;

  return deleteAsync(url);
};

export const deleteEmployer = (id: number): Promise<AxiosResponse> => {
  let url = `/manage-employer/api/employers/${id}`;

  return deleteAsync(url);
};

export const changeEmployerStatus = (id: number, toStatus: EnableStatusEnum): Promise<AxiosResponse> => {
  let url = `/manage-employer/api/employers/change-status/${id}/${toStatus}`;
  return putAsync(url, {});
};

export const getEmployeeInfoById = (id: number): Promise<AxiosResponse> => {
  let url = `/manage-employer/api/employees/${id}`;

  return getAsync(url);
};

export const getEmployeeClaimsById = (
  id: number,
  param: SearchParamClaim
): Promise<AxiosResponse> => {
  let url = `/manage-claim/api/employees/${id}/claims`;

  return getAsync(url, param);
};

export const getClasses = (): Promise<AxiosResponse> => {
  let url = `/manage-employer/api/classes`;

  return getAsync(url);
};

export const getEnrollments = (): Promise<AxiosResponse> => {
  let url = `/manage-employer/api/enrollments`;

  return getAsync(url);
};

//post image
export const postFileToS3 = (model: FormData): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/files`;

  return postAsync(url, model);
};

export const getClassBenefits = (): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/class-benefits`;

  return getAsync(url);
};

export const getMedicalAndTravelCoverage = (): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employers/medical-and-travels`;

  return getAsync(url);
};

export const getEmployerEnrollments = (): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employer-enrollments`;

  return getAsync(url);
};

export const getPaymentMethod = (): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employers/payment-method`;

  return getAsync(url);
};

export const getChequeInfo = (): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employers/cheque-info`;

  return getAsync(url);
};

export const getMedicalAndTravelCoverageDefault = (): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employers/medical-and-travels/default`;

  return getAsync(url);
};

export const putCompanyInfo = (
  param: CompanyDataEdit
): Promise<AxiosResponse> => {
  let url = `manage-employer/api/employers`;

  return putAsync(url, param);
};

export const putPaymentMethod = (
  employerId: number,
  param: PaymentMethod
): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employers/${employerId}/payment-method`;

  return putAsync(url, param);
};

export const putBankingInfo = (
  employerId: number,
  param: PaymentMethod
): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employers/${employerId}/banking-info`;

  return putAsync(url, param);
};

export const postEmployerEnrollments = (
  employerId: number,
  param: { enrollmentId: number }
): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employer-enrollments/${employerId}`;

  return postAsync(url, param);
};

export const deleteEmployerEnrollments = (
  employerId: number,
  id: number
): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employer-enrollments/${employerId}/${id}`;

  return deleteAsync(url);
};

export const updateEmployerEnrollments = (
  employerId: number,
  ids: number[]
): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employer-enrollments/${employerId}`;

  return putAsync(url, ids);
};

////////////////////////
export const postClassBenefitByEmployerId = (
  employerId: number,
  planId: number,
  param: ClassBenefit
): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/class-benefits/${employerId}/plan/${planId}`;

  return postAsync(url, param);
};

export const putClassBenefitByEmployerId = (
  employerId: number,
  param: {
    [p: string]: any;
    coverageAmount: number;
    classId: number;
    noOfEmployees: number;
    employerClassBenefitId: number;
    className: string;
    id: number;
    classBenefitFromDate: string
  }
): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/class-benefits/${employerId}/plan`;

  return putAsync(url, param);
};

export const deleteClassBenefit = (
  employerId: number,
  planId: number,
  employerClassBenefitId: number
): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employer/${employerId}/plan/${planId}/class-benefits/${employerClassBenefitId}`;

  return deleteAsync(url);
};

export const getClassesByEmployerId = (
  employerId: number,
  planId: number
): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/classes/${employerId}/plan/${planId}`;

  return getAsync(url);
};

export const getClassesBenefitByEmployerId = (
  employerId: number,
  planId: number
): Promise<AxiosResponse> => {
  let url = `manage-employer/api/class-benefits/${employerId}/plan/${planId}`;

  return getAsync(url);
};

export const getPlanPreferences = (): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/plan-preferences`;

  return getAsync(url);
};

export const getPlanPreferencesByEmployerId = (
  employerId: number
): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employers/plan-preferences/${employerId}`;

  return getAsync(url);
};

export const putEmployersPlanPreferences = (model: {
  planPreferences: RequestPlanPreferences[];
  employerId: number;
}): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employers/plan-preferences/${model.employerId}`;

  return putAsync(url, model.planPreferences);
};

export const getAgreementBankingInfo = (): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employers/agreement?type=3`;

  return getAsync(url);
};

export const postNewClient = (model: NewCompany): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employers`;

  return postAsync(url, model);
};

export const terminatingEmployees = (
  model: TerminatingRequest
): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employees/admin/terminated`;

  return putAsync(url, model);
};

export const reActivateEmployee = (employeeId: number) => {
  let url = `${URL_Employer}/employees/remove-cancellation-date/${employeeId}`;

  return putAsync(url, {});
}

export const putSuspendEmployee = (
  employeeId: number,
  fromDate: Date | string,
  toDate: Date | string
): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employees/${employeeId}/suspend`;

  return putAsync(url, { fromDate, toDate });
};

export const putUnSuspendEmployee = (
  employeeId: number
): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employees/${employeeId}/unsuspend`;

  return putAsync(url, {});
};

export const getSuspendHistory = (
  employeeId: number
): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employees/suspend-history/${employeeId}`;

  return getAsync(url);
};

export const deleteSuspendHistory = (
  employeeId: number,
  suspendHistoryId: number
): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employees/suspend-history/${employeeId}/${suspendHistoryId}`;

  return deleteAsync(url);
};

export const updateSuspendHistory = (
  employeeId: number,
  suspendHistoryId: number,
  fromDate: Date | string,
  toDate: Date | string
): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employees/suspend-history/${employeeId}`;

  return putAsync(url, {id: suspendHistoryId, fromDate, toDate});
};

export const getEmployerPlan = (
    employerId: number,
): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/plan/${employerId}`;

  return getAsync(url);
};

export const updateEmployerPlan = (
    employerId: number,
    employerPlan: EmployerPlan
): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/plan/${employerId}`;

  return putAsync(url, employerPlan);
};

export const createCustomAdminFee = (model: any) => {
  let url = `${URL_Employer}/admin-fee`;

  return postAsync(url, model);
}

export const fetchCustomAdminFeeSettings = (employerId: number) => {
  let url = `${URL_Employer}/admin-fee?employerId=${employerId}`;

  return getAsync(url);
}

export const archiveCustomAdminFeeSetting = (id: number) => {
  let url = `${URL_Employer}/admin-fee/${id}`;

  return deleteAsync(url);
}

export const getEmployerConfiguration = (): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employers/configuration`;

  return getAsync(url);
};

export const getAllRRSPProviders = () => {
  let url = `${URL_Employer}/rrsp-provider/all`;

  return getAsync(url);
};

export const getRRSPProviderSettings = (employerId: number) => {
  let url = `${URL_Employer}/rrsp-provider/employer?employerId=${employerId}`;

  return getAsync(url);
};

export const createRRSPProvider = (model: any) => {
  let url = `${URL_Employer}/rrsp-provider/employer`;

  return postAsync(url, model);
};

export const getEmployeesByEmployerClassBenefitId = (employerId: number, employerClassBenefitId: number) => {
  let url = `${URL_Employer}/employees/employer/${employerId}/search-by-class-benefit/${employerClassBenefitId}`;

  return getAsync(url);
}

export const putChangeEmployeeClassBenefit = (
  employerId: number,
  request: {
  employerClassBenefitId: number,
  employeeIds: number[],
  classBenefitFromDate: Date | string
}): Promise<AxiosResponse> => {
  let url = `${URL_Employer}/employees/employer/${employerId}/change-class-benefit`;

  return putAsync(url, request);
}
import React, { useEffect, useMemo, useState } from 'react';
import { StyledContentBox, StyledSpanLargerTitle, StyledSpanEditInfo, StyledButtonSubmit } from "../../../../shared/styled";
import BNPRawTable from "../../../../shared/BNPTable/BNPRawTable";
import Pagination, { PagingInfo } from "../../../../../cores/helpers/pagination/pagination";
import { SearchPartnerTransactionParam, TransactionInfo, TransactionTitle } from './TransactionType';
import { formatterUSD } from '../../../../../cores/helpers/format-usd';
import { centToUsd } from '../../../../../cores/helpers/cent-to-usd';
import { convertDateToTimeZone } from '../../../../../cores/utils/helpers';
import BNPDatePicker from '../../../../../cores/helpers/date-picker/date-picker';
import { BNPSelect, Option } from '../../../../../cores/helpers/select/select';
import { statusTransaction } from '../../../company-management/company-management-model';
import DownloadIcon from '@material-ui/icons/GetApp';
import { message } from '../../../../../cores/helpers/message/message';
import { SortTable, SortType } from '../../../../../cores/models/SortTable';
import { TransactionReportHeaderColumns } from './TransactionModel';



type Props = {
  data: TransactionInfo[];
  fetchData: (searchParam: SearchPartnerTransactionParam) => void;
  pagingInfo: PagingInfo;
  handleDownload: (param: SearchPartnerTransactionParam) => void;
  txnTitles?: Option[];
};


export const Transaction = React.memo((props: Props) => {

  const [searchParam, setSearchParam] = useState<SearchPartnerTransactionParam>({
    type: null,
    fromDate: null,
    toDate: null,
    txnCode: null,
    page: 1,
    columnName: "createdDate",
    sortType: "DESC",
    perPage: 10,
  });

  useEffect(() => {
    props.fetchData(searchParam);
  }, [searchParam]);

  const handleChangePage = (page: number) => {
    setSearchParam({ ...searchParam, page });
  }

  const handleChangePerPage = (event: any) => {
    let { value } = event.target;
    let perPage: number = value;
    setSearchParam({ ...searchParam, perPage });
  }

  const handleChangeDate = (date: Date | null, name: string) => {

    if (name === "from") {
      if (searchParam.toDate && date) {
        let currentFrom = new Date(date),
          from = new Date(currentFrom),
          to = new Date(searchParam.toDate);
        if (from > to) {
          message("The from date cannot be after the to date.", "error");
          return;
        }
      }
      let fromDate = date ? new Date(date) : null;
      if (!fromDate || (fromDate instanceof Date && !isNaN(fromDate.getTime()))) {
        setSearchParam({ ...searchParam, fromDate, page: 1 })
      }
    } else {
      if (searchParam.fromDate && date) {
        let from = new Date(searchParam.fromDate);
        let to = new Date(date);
        if (from > to) {
          message("The from date cannot be after the to date.", "error");
          return;
        }
      }
      let toDate = date ? new Date(date) : null;
      if (!toDate || (toDate instanceof Date && !isNaN(toDate.getTime()))) {
        setSearchParam({ ...searchParam, toDate, page: 1 })
      }
    }
  }


  const onChangeTransactionType = (event: any) => {
    let { value } = event.target;

    if (Number(value) === -1) value = null;
    setSearchParam({...searchParam, type: value, page: 1});

  };

  const onChangeTransactionCode = (event: any) => {
    let { value } = event.target;

    if (Number(value) === -1) value = null;
    setSearchParam({...searchParam, txnCode: value, page: 1});

  };

  const handleChangeSortColumn = (columnId: string) => {
    let columnsHeaderTable: SortTable[] = TransactionReportHeaderColumns;
    let sortType: SortType = null;
    let index = columnsHeaderTable.findIndex((column) => {
      return column.columnId === columnId;
    });

    columnsHeaderTable.forEach((column, i) => {
      if (i === index) {
        column.sortType = column.sortType === "ASC" ? "DESC" : "ASC";
        sortType = column.sortType;
        return;
      }
      column.sortType = null;
    });

    setSearchParam({ ...searchParam, columnName: columnId, sortType: sortType });
  }

  const handleDownload = () => {
    props.handleDownload(searchParam);
  }

  const tableData = useMemo(() => props.data.map(item => ({
    ...item,
    clearedAmount: formatterUSD("currency", "USD").format(centToUsd(item.clearedAmount)),
    pendingAmount: formatterUSD("currency", "USD").format(centToUsd(item.pendingAmount)),
    createdDate: item.createdDate
      ? convertDateToTimeZone("Canada/Eastern", new Date(item.createdDate))
      : "",
  })), [props.data]);

  return (
    <StyledContentBox>
      <StyledSpanLargerTitle>
        Transaction
      </StyledSpanLargerTitle>

      <div className="row">
        <div className="col-lg-2 mt-2">
          <BNPDatePicker
            value={searchParam.fromDate}
            onChange={(date) => handleChangeDate(date, "from")}
            name="date"
            maxDate={searchParam.toDate}
            placeholder="From date"
          />
        </div>
        <div className="col-lg-2 mt-2">
          <BNPDatePicker
            value={searchParam.toDate}
            onChange={(date) => handleChangeDate(date, "to")}
            name="date"
            maxDate={new Date()}
            placeholder="To date"
          />
        </div>
        <div className="col-lg-2 mt-2">
          <BNPSelect
            options={statusTransaction}
            placeholder="Filter by transaction type"
            name="transactionType"
            onChange={onChangeTransactionType}
            value={searchParam.type || -1}
          />
        </div>
        {props.txnTitles ?
          <>
            <div className="col-lg-2 mt-2">
              <BNPSelect
                options={props.txnTitles}
                placeholder="Filter by title"
                name="txnCode"
                onChange={onChangeTransactionCode}
                value={searchParam.txnCode || -1}
              />
            </div>
            <div className="col-lg-2 mt-2" />
          </>
          :
          <>
            <div className="col-lg-4 mt-2" />
          </>
        }
        <div className="col-lg-2 mt-2">
          <StyledButtonSubmit
            submit={true}
            onClick={handleDownload}
          >
            <DownloadIcon className="icon" style={{marginRight: "5px"}}/>
            Download
          </StyledButtonSubmit>
        </div>
      </div>

      <BNPRawTable
        columns={TransactionReportHeaderColumns}
        tableWidth={"100%"}
        tableData={tableData}
        handleChangeSortColumn={handleChangeSortColumn}
      />
      <div className="row mt-4">
        <div className="col-12 text-center">
          <label>
            <Pagination
              pagerPagination={props.pagingInfo}
              getPage={handleChangePage}
              onChangeRowsPerPage={handleChangePerPage}
            />
          </label>
        </div>
      </div>
    </StyledContentBox>

  );
});

import React, {useEffect, useMemo, useState} from 'react';
import styled from "styled-components";
import {formatterUSD} from "../../../../../../cores/helpers/format-usd";
import {centToUsd, usdToCent} from "../../../../../../cores/helpers/cent-to-usd";
import {Edit, DeleteOutline} from '@material-ui/icons';
import {BNPInput} from "../../../../../../cores/helpers/input/input";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import {FE_DETAIL_DATE_FORMAT} from "../../../../../../cores/utils/format/date-time-format";
import {message} from "../../../../../../cores/helpers/message/message";
import {StyledButtonOutlined, StyledButtonSubmit, StyledErrorMessage} from "../../../../../shared/styled";
import {EmployerBenefitClass} from "../plan-type";
import BNPAutoCompleteCreatable from "../../../../../../cores/helpers/input/BNPAutoCompleteCreatable";
import BNPDatePicker from "../../../../../../cores/helpers/date-picker/date-picker";

const StyledEmployerClassWrapper = styled.div<{ isEdit: boolean }>`
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: ${props => props.isEdit ? 'end' : 'center'};
    
  @media (max-width: 992px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-around;
  }
`;

const StyledClassTitle = styled.span`
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.5rem;
    
    @media (max-width: 768px) {
        font-size: 1rem;
    }
`;

const StyledClassAmount = styled(StyledClassTitle)`
    padding: 0.25rem 0.5rem;
    margin-left: 0.5rem;
    background-color: ${(props) => props.theme.infoColor};
    border-radius: 8px;
    color: #fff;
`;

const StyledButtonEdit = styled(StyledButtonSubmit)`
    @media (max-width: 992px) {
        width: 100%;
        max-width: 100%;
    }
    padding-left: 1rem;
    padding-right: 1rem;
`;

const StyledButtonDelete = styled(StyledButtonOutlined)`
    @media (max-width: 992px) {
        width: 100%;
        max-width: 100%;
    }
    padding-left: 1rem;
    padding-right: 1rem;
`;

const StyledDeleteIcon = styled(DeleteOutline)`
    margin-right: 0.25rem;
`;

const StyledEditIcon = styled(Edit)`
    margin-right: 0.25rem;
`;

const StyledEditWrapper = styled.div`
    width: 80%;
    @media (max-width: 992px) {
        width: 100%;
    }
`;

const StyledErrorMessageCustom = styled(StyledErrorMessage)`
    font-size: 0.875rem;
    font-weight: 500;
    color: #EE2626;
`;

type Props = {
  classBenefit: EmployerBenefitClass;
  classOptions: any[];
  isEdit: boolean;
  handleEditClass: (id: number) => void;
  handleCancelEditClass: () => void;
  handleSubmitEditClass: (classBenefit: EmployerBenefitClass) => void;
  handleDeleteClass: (employerClassBenefit: EmployerBenefitClass) => void;
};

const EmployerClass = (props: Props) => {
  const {classBenefit} = props;

  const [editClass, setEditClass] = useState<EmployerBenefitClass>(cloneDeep(classBenefit));

  useEffect(() => {
    const cloneClassBenefit = cloneDeep(classBenefit);
    setEditClass({...cloneClassBenefit, classBenefitFromDate: new Date()});
  }, [classBenefit]);

  const getClassName = (classId: number) => {
    const classInfo = props.classOptions.find((c) => c.id === classId);
    return classInfo ? classInfo.name : '';
  };

  const handleChangeClassName = (event: any, option: any) => {
    if (!option) {
      setEditClass({
        ...editClass,
        classId: 0,
        className: ''
      });
      return;
    }

    if (option.id < 0) {
      if (!option.name || option.name.trim().length === 0) {
        message('Please enter a valid class name', 'error');
        return;
      }

      setEditClass({
        ...editClass,
        classId: 0,
        className: option.name
      });
      return;
    }

    setEditClass({
      ...editClass,
      classId: option.id,
      className: option.name
    });
  }

  const handleChangeClassInfo = (e: any) => {
    if (e.target.name === 'coverageAmount') {
      setEditClass({
        ...editClass,
        coverageAmount: usdToCent(e.target.value)
      });
      return;
    }

    setEditClass({
      ...editClass,
      [e.target.name]: e.target.value
    });
  }

  const handleChangeClassBenefitFromDate = (date: Date | null, name: string) => {
    setEditClass({
      ...editClass,
      classBenefitFromDate: date
    });
  }

  const isChangedClassAmount = useMemo(() => {
    return editClass.coverageAmount !== classBenefit.coverageAmount;
  }, [editClass.coverageAmount, classBenefit.coverageAmount]);

  return (
    <StyledEmployerClassWrapper isEdit={props.isEdit}>
      {!props.isEdit || Number(moment().format("MMDD")) <= 115 ?
        <div>
          <div className={"mb-2"}>
            <StyledClassTitle>{props.classBenefit.className}</StyledClassTitle>
            <StyledClassAmount>{formatterUSD("currency").format(centToUsd(props.classBenefit.coverageAmount))}</StyledClassAmount>
          </div>
          <div>
            Last updated: {props.classBenefit.modifiedDate ? moment(props.classBenefit.modifiedDate).format(FE_DETAIL_DATE_FORMAT) : ""}
          </div>
        </div> :
        <StyledEditWrapper className={"row w-100"}>
          <div
            className={"col-12 d-flex flex-lg-row align-items-start align-items-lg-center justify-content-between mb-2"}>
            <div className={"mb-2"}>
              <StyledClassTitle>{!!props.classBenefit.id ? `Edit ${props.classBenefit.className}` : "Add new "} class</StyledClassTitle>
            </div>
            {props.classBenefit.id > 0 && <div>
              Last
              updated: {props.classBenefit.modifiedDate ? moment(props.classBenefit.modifiedDate).format(FE_DETAIL_DATE_FORMAT) : ""}
            </div>}
          </div>
          <div className={`col-lg-3 mt-lg-0 mt-2 ${isChangedClassAmount ? 'mb-2' : 0}`}>
            <BNPAutoCompleteCreatable
              options={props.classOptions}
              name="classId"
              value={{
                id: editClass.classId,
                name: getClassName(editClass.classId) || editClass.className
              }}
              onChange={(event, option) => handleChangeClassName(event, option)}
              addNewOptionText={"Create new class name"}
              placeholder='Select or Create Class Name'
            />
          </div>
          <div className={`col-lg-3 mt-lg-0 mt-2 ${isChangedClassAmount ? 'mb-2' : 0}`}>
            <BNPInput
              type="number"
              name="coverageAmount"
              value={centToUsd(editClass.coverageAmount) || ""}
              onChange={(e: any) => handleChangeClassInfo(e)}
              placeholder="Coverage Amount"
            />
          </div>
          {
            isChangedClassAmount && (
              <div className={`col-lg-2 mt-lg-0 mt-2 ${isChangedClassAmount ? 'mb-2' : 0}`}>
                <BNPDatePicker
                  value={editClass.classBenefitFromDate}
                  onChange={handleChangeClassBenefitFromDate}
                  name="classBenefitFromDate"
                  placeholder="From Date"
                />
              </div>
            )
          }
          <div className="col-lg-2 col-6 mt-lg-0 mt-2">
            <StyledButtonEdit
              disabled={!editClass.coverageAmount || editClass.coverageAmount <= 0 || editClass.coverageAmount < classBenefit.coverageAmount}
              submit={editClass.coverageAmount > 0 && editClass.coverageAmount >= classBenefit.coverageAmount}
              onClick={() => props.handleSubmitEditClass(editClass)}
            >
              {editClass.id === 0 ? 'Save' : 'Update'}
            </StyledButtonEdit>
          </div>
          <div className="col-lg-2 col-6 mt-lg-0 mt-2">
            <StyledButtonDelete submit onClick={props.handleCancelEditClass}>
              Cancel
            </StyledButtonDelete>
          </div>

          <div className="col-12">
            {editClass.coverageAmount < classBenefit.coverageAmount &&
              <StyledErrorMessageCustom>
                {/*Heads up! If the new class amount is lower than the current one, you'll need to cover the difference in*/}
                {/*cost.*/}
                We do not support decreasing the class benefit coverage amount at the moment. Please contact the admin for more information.
              </StyledErrorMessageCustom>
            }
          </div>
        </StyledEditWrapper>
      }
      {(!props.isEdit && Number(moment().format("MMDD")) > 115) && <div className={"d-flex flex-row align-items-center mt-2 mt-lg-0"}>
        <StyledButtonEdit
          submit={true}
          onClick={() => props.handleEditClass(classBenefit.id)}
        >
          <div
            className={"d-flex flex-row align-items-center w-100 justify-content-center"}
          >
            <StyledEditIcon/>
            Edit
          </div>
        </StyledButtonEdit>
        <StyledButtonDelete
          submit={true}
          onClick={() => props.handleDeleteClass(props.classBenefit)}
        >
          <div className={"d-flex flex-row align-items-center w-100 justify-content-center"}>
            <StyledDeleteIcon/>
            Delete
          </div>
        </StyledButtonDelete>
      </div>}
    </StyledEmployerClassWrapper>
  );
};

export default EmployerClass;
import React, {useEffect} from "react";
import {EFTBlacklistResponse, EFTBlacklistSearchParam} from "./eft-blacklist-type";
import {
  StyledButtonSubmit,
  StyledDivTitle,
  StyledSpanTitle,
} from "../../../shared/styled";
import {BNPModal} from "../../../shared/BNPModal/BNPModal";
import BNPRawTable from "../../../shared/BNPTable/BNPRawTable";
import Pagination, {setPagingInfo} from "../../../../cores/helpers/pagination/pagination";
import {
  depositWithdrawTransactionsPendingColumns
} from "./eft-blacklist-model";
import {StyledSpanAction} from "../../employees-management/employee-action";
import {
  declineDeposit,
  declineWithdraw,
  getEmployeeRequestTransactionsPartnerWithPagination,
  getEmployerRequestTransactionsPartnerWithPagination
} from "../../../../services/wallet-service";
import {getInfoByToken} from "../../../../cores/utils/helpers";
import {closeConfirmDialog, openConfirmDialog} from "../../../../cores/utils/confirmDialog";
import {addLoading, removeLoading} from "../../../../cores/utils/loading";
import styled from "styled-components";

const StyledSpanDecline = styled(StyledSpanAction)`
  color: ${(props) => props.theme.errorColor};
`;

type EftBlacklistRemoveModalProps = {
  removeItem: EFTBlacklistResponse | null;
  type: "employeeBlacklist" | "employerBlacklist" | "";
  open: boolean;
  handleClose: () => void;
  handleRemove: (request: EFTBlacklistResponse, type: "employeeBlacklist" | "employerBlacklist") => void;
};

export function EftBlacklistRemoveModal(props: EftBlacklistRemoveModalProps) {
  const [searchParams, setSearchParams] = React.useState<EFTBlacklistSearchParam>({
    searchKey: "",
    page: 1,
    perPage: 10,
    columnName: "",
    sortType: null,
  })
  const [options, setOptions] = React.useState<any[]>([]);
  const [paging, setPaging] = React.useState<any>(setPagingInfo(1, 0, 10));

  useEffect(() => {
    addLoading();
    fetchData().then(() => removeLoading());
  }, [props.open, props.type, searchParams.page, searchParams.perPage]);

  const fetchData = async () => {
    const request = {
      ...searchParams,
      partnerId: getInfoByToken().mainPartnerId,
      userId: props.removeItem ? props.removeItem.userId : -1
    }
    let response;
    if (props.open && props.type === "employeeBlacklist" && props.removeItem) {
      response = await getEmployeeRequestTransactionsPartnerWithPagination(request)
    } else if (props.open && props.type === "employerBlacklist" && props.removeItem) {
      response = await getEmployerRequestTransactionsPartnerWithPagination(request)
    } else {
      return;
    }
    setOptions(convertSearchOptions(response.data.records));
    setPaging(setPagingInfo(searchParams.page, response.data.pageCount, searchParams.perPage));
  }

  const convertSearchOptions = (options: any[]) => {
    return options.map((option) => ({
      ...option,
      name: `${option.firstName} ${option.lastName}`,
      amount: `${Math.abs(option.amount)}`,
      action: <StyledSpanDecline
        onClick={() => handleDeclineTxn(option)}
      >
        Decline
      </StyledSpanDecline>
    }))
  }

  useEffect(() => {
    return () => {
      setSearchParams({
        searchKey: "",
        page: 1,
        perPage: 10,
        columnName: "",
        sortType: null,
      });
      setOptions([]);
      setPaging(setPagingInfo(1, 0, 10));
    }
  }, [props.open, props.type]);

  const setRowsPerPage = (event: any) => {
    const { value } = event.target;
    setSearchParams(prevState => (
      {...prevState, page: 1, perPage: Number(value)}
    ));
  };

  const handleChangePage = (page: number) => {
    setSearchParams(prevState => ({...prevState, page}));
  };

  const handleDeclineTxn = (item: any) => {
    openConfirmDialog({
      id: `decline-eft-of-blacklist-${item.journalId}`,
      open: true,
      title: `Decline ${item.transactionType} request`,
      content: `Are you sure you want to remove ${item.transactionType} request of ${item.firstName} ${item.lastName}?`,
      onClose: (isOk) => handleCloseDeclineTxnConfirm(isOk, item)
    })
  }

  const handleCloseDeclineTxnConfirm = (isOk: boolean, item: any) => {
    if (isOk) {
      addLoading();
      const isWithdraw = item.transactionType === 'Withdraw';
      handleDecline(item.journalId, isWithdraw).then(() => {
        return fetchData()
      }).finally(() => {
        closeConfirmDialog();
        removeLoading()
      });
    } else {
      closeConfirmDialog();
    }
  }

  const handleDecline = async (journalId: number, isWithdraw: boolean) => {
    if (isWithdraw) {
      await declineWithdraw(journalId);
    } else {
      await declineDeposit(journalId);
    }
  };

  const modalBody =
    <div className="row">
      <div className="col-12 mb-4">
        <StyledSpanTitle>
          Please decline unwanted transactions before removing this account from the blacklist.
          Any un-declined transactions will be sent to XP Payments in the next schedule.
        </StyledSpanTitle>
      </div>
      <div className="col-12">
        <StyledDivTitle>Deposit/Withdraw Request</StyledDivTitle>
        <BNPRawTable
          columns={depositWithdrawTransactionsPendingColumns}
          tableWidth='100%'
          tableData={options}
          tableName={"depositWithdrawTransactionsPendingTable"}
        />
        <div className="row mt-4">
          <div className="col-12 text-center">
            <label>
              <Pagination
                pagerPagination={paging}
                getPage={handleChangePage}
                onChangeRowsPerPage={setRowsPerPage}
              />
            </label>
          </div>
        </div>
      </div>

    </div>


  return (
    <BNPModal
      open={props.open}
      title="Remove From Blacklist"
      handleClose={props.handleClose}
      body={modalBody}
      footer={
      <div className="col-md-4 p-0">
        <StyledButtonSubmit
          disabled={!(props.removeItem && props.type)}
          onClick={() => props.removeItem && props.type && props.handleRemove(props.removeItem, props.type)}
          submit={!!(props.removeItem && props.type)}
          type="submit"
        >
          Remove from blacklist
        </StyledButtonSubmit>
      </div>
    }
    />
  );
}

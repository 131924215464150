import React from "react";
import { TaxView } from "./tax-view";
import { TaxResponse, TaxSearchParam } from "./tax-type";
import {
  PagingInfo,
  setPagingInfo,
} from "../../../cores/helpers/pagination/pagination";
import { getReportTax } from "../../../services/wallet-service";
import { RouteChildrenProps } from "react-router";
import { addLoading, removeLoading } from "../../../cores/utils/loading";
import { SortTable, SortType } from "../../../cores/models/SortTable";
import { taxHeader } from "./tax-model";
import { filterData, Filter } from "../../../cores/helpers/filter/filter";
import { Provinces } from "../company-management/company-management-type";
import { getProvinces } from "../../../services/company-service";
import { message } from "../../../cores/helpers/message/message";
import { toUTCTime } from "../../../cores/helpers/to-utc-time";
import TaxModal, { showTaxModal, closeTaxModal } from "./tax-modal";

type Props = RouteChildrenProps;
type State = {
  taxes: TaxResponse[];
  taxesFilter: TaxResponse[];
  pagingInfo: PagingInfo;
  searchParam: TaxSearchParam;
  columns: SortTable[];
  provinces: Provinces[];
};

export default class ReportTax extends React.Component<Props, State> {
  state: State = {
    taxes: [],
    taxesFilter: [],
    searchParam: {
      page: 1,
      perPage: 10,
      dateRange: null,
      from: null,
      provinceId: null,
      to: null,
      columnName: null,
      sortType: null,
    },
    pagingInfo: {
      currentPage: 0,
      totalPages: 0,
      startPage: 0,
      endPage: 0,
      pages: [],
      rowsPerPage: 10,
    },
    columns: [],
    provinces: [],
  };

  async componentDidMount() {
    addLoading();

    let resultProvinces = await getProvinces();

    let provinces: Provinces[] = resultProvinces.data || [];

    let searchParam = this.getSearchByUrl();
    await this.getReportTax(searchParam.searchParam);

    this.setState({
      columns: searchParam.columns,
      provinces: provinces,
    });

    removeLoading();
  }
  async UNSAFE_componentWillReceiveProps() {
    addLoading();

    let searchParam = this.getSearchByUrl();
    await this.getReportTax(searchParam.searchParam);

    this.setState({
      columns: searchParam.columns,
    });

    removeLoading();
  }

  async getReportTax(searchParam: TaxSearchParam) {
    getReportTax(searchParam).then((response) => {
      let responseFilter = filterData(
        Object.assign(response.data ? response.data : []),
        searchParam.page,
        searchParam.perPage,
        this.getParamFilter(searchParam),
        searchParam.columnName,
        searchParam.sortType
      );

      let pagingInfo = setPagingInfo(
        searchParam.page,
        responseFilter.pageCount,
        searchParam.perPage
      );

      this.setState({
        taxes: response.data,
        searchParam: searchParam,
        pagingInfo: pagingInfo,
      });
    });
  }

  getParamFilter = (paramSearch: TaxSearchParam) => {
    let params: Filter[] = [];

    // if (paramSearch.dateRange) {
    //   params.push({
    //     name: "dateRange",
    //     value: paramSearch.dateRange,
    //   });
    // }
    if (paramSearch.provinceId) {
      params.push({
        name: "provinceId",
        value: paramSearch.provinceId,
      });
    }

    return params;
  };

  handleChangePage = (page: number) => {
    let searchParam: TaxSearchParam = Object.assign(this.state.searchParam);
    searchParam.page = page;

    this.setSearchByParam(searchParam);
  };
  setRowsPerPage = (event: any) => {
    const { value } = event.target;

    let searchParam = Object.assign({}, this.state.searchParam);
    searchParam.page = 1;
    searchParam.perPage = Number(value);

    // set url search
    this.setSearchByParam(searchParam);
  };

  getSearchByUrl = () => {
    const urlParams = new URLSearchParams(window.location.search),
      page = urlParams.get("page"),
      pageSize = urlParams.get("pageSize"),
      provinceId = urlParams.get("provinceId"),
      dateRange = urlParams.get("dateRange"),
      columnName = urlParams.get("columnName"),
      sortType: SortType = urlParams.get("sortType") as SortType,
      fromDate = urlParams.get("from"),
      toDate = urlParams.get("to");

    let searchParam = Object.assign({}, this.state.searchParam);

    page ? (searchParam.page = Number(page)) : (searchParam.page = 1);
    pageSize
      ? (searchParam.perPage = Number(pageSize))
      : (searchParam.perPage = 10);
    fromDate ? (searchParam.from = fromDate) : (searchParam.from = null);
    toDate ? (searchParam.to = toDate) : (searchParam.to = null);
    provinceId
      ? (searchParam.provinceId = Number(provinceId))
      : (searchParam.provinceId = null);
    dateRange
      ? (searchParam.dateRange = dateRange)
      : (searchParam.dateRange = "THIS_MONTH");

    if (columnName && sortType) {
      searchParam.columnName = columnName;
      searchParam.sortType = sortType;
    } else {
      searchParam.columnName = null;
      searchParam.sortType = null;
    }

    // set sort table
    let columns: SortTable[] = taxHeader();
    let index = columns.findIndex((column) => {
      return column.columnId === searchParam.columnName;
    });
    if (index > -1) {
      columns[index].sortType = sortType;
    }

    return {
      searchParam,
      columns,
    };
  };

  setSearchByParam = (searchParam: TaxSearchParam) => {
    let url = new URL(window.location.href);

    if (searchParam.page) {
      url.searchParams.set("page", searchParam.page.toString());
    } else {
      url.searchParams.delete("page");
    }
    if (searchParam.perPage) {
      url.searchParams.set("pageSize", searchParam.perPage.toString());
    } else {
      url.searchParams.delete("pageSize");
    }
    if (searchParam.from) {
      url.searchParams.set("from", searchParam.from.toString());
    } else {
      url.searchParams.delete("from");
    }
    if (searchParam.to) {
      url.searchParams.set("to", searchParam.to.toString());
    } else {
      url.searchParams.delete("to");
    }

    if (searchParam.provinceId) {
      url.searchParams.set("provinceId", searchParam.provinceId.toString());
    } else {
      url.searchParams.delete("provinceId");
    }

    if (searchParam.dateRange) {
      url.searchParams.set("dateRange", searchParam.dateRange);
    } else {
      url.searchParams.delete("dateRange");
    }

    if (searchParam.columnName && searchParam.sortType) {
      url.searchParams.set("sortType", searchParam.sortType);
      url.searchParams.set("columnName", searchParam.columnName);
    } else {
      url.searchParams.delete("sortType");
      url.searchParams.delete("columnName");
    }

    this.props.history.push(url.search);
  };

  handleChangeSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (Number(value) === -1) return;

    let searchParam = Object.assign({}, this.state.searchParam);

    if (value === "CUSTOM") {
      showTaxModal();
    } else {
      searchParam.dateRange = value;

      this.setSearchByParam(searchParam);
    }
  };

  handleChangeProvince = (event: any) => {
    let { value } = event.target,
      searchParam = Object.assign(this.state.searchParam);

    if (Number(value) === -1) value = null;
    searchParam.provinceId = value;
    searchParam.page = 1;

    // set url search
    this.setSearchByParam(searchParam);
  };

  handleChangeDate = (date: Date | null, name: string) => {
    let searchParam: TaxSearchParam = Object.assign(this.state.searchParam);

    if (name === "from") {
      if (searchParam.to && date) {
        let currentFrom = new Date(date),
          from = new Date(currentFrom),
          to = new Date(searchParam.to);
        if (from > to) {
          message("The from date cannot be after the to date.", "error");
          return;
        }
      }
      searchParam.from = date;
    } else {
      if (searchParam.from && date) {
        let from = new Date(searchParam.from),
          to = new Date(date);
        if (from > to) {
          message("The from date cannot be after the to date.", "error");
          return;
        }
      }
      searchParam.to = date;
    }
    searchParam.page = 1;

    this.setState({ searchParam });
  };

  handleOk = () => {
    let searchParam: TaxSearchParam = Object.assign(this.state.searchParam);
    searchParam.dateRange = "CUSTOM";

    closeTaxModal();

    this.setSearchByParam(searchParam);
  };

  handleClose = () => {
    let searchParam: TaxSearchParam = Object.assign(this.state.searchParam);
    if (searchParam.dateRange !== "CUSTOM") {
      searchParam.from = null;
      searchParam.to = null;
    }

    closeTaxModal();
    this.setState({ searchParam });
  };

  render() {
    return (
      <>
        <TaxView
          columns={this.state.columns}
          handleChangePage={this.handleChangePage}
          pagingInfo={this.state.pagingInfo}
          searchParam={this.state.searchParam}
          setRowsPerPage={this.setRowsPerPage}
          taxes={this.state.taxes}
          handleChangeSelect={this.handleChangeSelect}
          provinces={this.state.provinces}
          handleChangeProvince={this.handleChangeProvince}
        />
        <TaxModal
          searchParam={this.state.searchParam}
          handleChangeDate={this.handleChangeDate}
          handleOK={this.handleOk}
          handleClose={this.handleClose}
        />
      </>
    );
  }
}

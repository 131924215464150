import React from 'react';
import {StyledTextarea} from "../../../components/shared/styled";
import styled from "styled-components";

const StyledTextArea = styled(StyledTextarea)`
  white-space: pre-wrap;
  overflow-y: scroll;
`

type BnpTextAreaProps = {
  name: string;
  placeholder: string;
  value: string;
  handleChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  disabled?: boolean;
  rows?: number;
  tabIndex?: number;
};

function BNPTextArea({name, placeholder, value, handleChange, disabled, rows = 3, tabIndex = 0}: BnpTextAreaProps) {
  return (
    <StyledTextArea
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={handleChange     }
      disabled={disabled}
      rows={rows}
      tabIndex={tabIndex}
    />
  );
}

export default BNPTextArea;
import {
  StyledButtonCloseModal, StyledButtonSubmit, StyledFormControlLabel,
  StyledHeaderModal,
  StyledHeaderTitleModal,
  StyledIconCloseModal, StyledPTitle
} from "../../../shared/styled";
import {BNPSelect} from "../../../../cores/helpers/select/select";
import {Radio, RadioGroup} from "@material-ui/core";
import React, {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import {getPartnerOptions, getReferralPartnerUserOptions} from "../../../../services/partner-service";
import {PartnerData} from "../partner-management-type";
import { BNPAutoComplete } from "../../../../cores/helpers/input/BNPAutoComplete";
import { BENIPLUS_DEFAULT_OPTION, NA_OPTION_VALUE, getDefaultSelectedPartnerUserId, getRemakedReferralPartnerUsers } from "../../../../cores/utils/helpers";
import { addLoading, removeLoading } from "../../../../cores/utils/loading";

const StyledModalDialog = styled.div`
    max-width: 800px;
    margin: 1.75rem auto;
    position: relative;
    width: auto;
    pointer-events: none;
`

const StyledCancelButton = styled(StyledButtonSubmit)`
    background-color: transparent;
    border: 1px solid ${(props) => props.theme.primaryColor};
    color: ${(props) => props.theme.primaryColor};
`

const StyledCustomSubmitButton = styled(StyledButtonSubmit)`
    width: 100%;
`

const StyledDescription = styled(RadioGroup)`
    flex-direction: row !important;
    width: 100%;

    .MuiFormControlLabel-label {
        font-size: 14px;
    }

    .MuiFormControlLabel-root {
        margin-right: 20px;
        margin-bottom: 0;
    }
`

type Props = {
  handleSubmit: (data: any) => void;
  closePartnerModal: () => void;
  partner: PartnerData;
}

export default function DisablePartnerConfirmationModal(props: Props) {
  const { partner, handleSubmit} = props;
  const [inheritedPartnerId, setInheritedPartnerId] = useState(1);
  const [inheritedPartner, setInheritedPartner] = useState<any>(BENIPLUS_DEFAULT_OPTION);
  const [inheritedPartnerUserId, setInheritedPartnerUserId] = useState<number|null>(null);
  const [partnerOptions, setPartnerOptions] = useState([BENIPLUS_DEFAULT_OPTION])
  const [partnerUserOptions, setPartnerUserOptions] = useState([{
    id: -1, name: "N/A"
  }]);
  const [executePayoutJob, setExecutePayoutJob] = useState(false);

  useEffect(() => {
    addLoading();
    getPartnerOptions().then((response) => {
      let excludedDisabledPartnerOptions =
        response.data.filter((partnerItem: any) => partnerItem.id !== partner.id);
      
      const options: any = [BENIPLUS_DEFAULT_OPTION, ...excludedDisabledPartnerOptions];
      setPartnerOptions(options);

      // set default partner
      let defaultPartner = options.findLast((i: any) => i.isDefaultReferral);
      defaultPartner = defaultPartner ? defaultPartner : BENIPLUS_DEFAULT_OPTION;
      setInheritedPartner(defaultPartner);
      setInheritedPartnerId(defaultPartner.id);
      removeLoading();
    })
  }, [partner.id]);

  useEffect(() => {
    addLoading();
    getReferralPartnerUserOptions(inheritedPartnerId).then((response) => {

      const options = getRemakedReferralPartnerUsers(inheritedPartner, response.data);
      const defaultSelectedPartnerUserId = getDefaultSelectedPartnerUserId(inheritedPartner, options);

      setPartnerUserOptions(options);
      setInheritedPartnerUserId(defaultSelectedPartnerUserId)
      removeLoading();
    });
  }, [inheritedPartnerId]);

  const handleChangeInheritedPartner = useCallback((event: any, option: any) => {
    setInheritedPartner(option)
    setInheritedPartnerId(option.id);
  }, []);

  const handleChangeInheritedPartnerUser = useCallback((event: any, option: any) => {
    setInheritedPartnerUserId(option.id);
  }, []);

  const changeExecutePayoutJob = useCallback((event: any, value: string) => {
    setExecutePayoutJob(value === "true");
  },[]);

  const doSubmit = () => {
    handleSubmit({
      inheritedPartnerId: inheritedPartnerId,
      inheritedPartnerUserId: inheritedPartnerUserId === NA_OPTION_VALUE ? null : inheritedPartnerUserId,
      executePayoutJob
    });
  }


  return (
    <StyledModalDialog role="document">
      <div className="modal-content">
        <StyledHeaderModal className="modal-header">
          <StyledHeaderTitleModal>
            Disable Partner
          </StyledHeaderTitleModal>
          <StyledButtonCloseModal
            type="button"
            onClick={() => {
              props.closePartnerModal();
            }}
          >
            <StyledIconCloseModal>&times;</StyledIconCloseModal>
          </StyledButtonCloseModal>
        </StyledHeaderModal>
        <div className="modal-body">
          <div className="row">
            <p>
              How would you like to transfer client from <strong>{partner.partnerName}</strong> to a live partner?
            </p>
          </div>
          <div className="row">

            <div className="col-md-6 mb-4">
              <StyledPTitle className="mb-2">Partner</StyledPTitle>
              <BNPAutoComplete
                name="inheritedPartnerId"
                options={partnerOptions}
                optionSelectedId={inheritedPartnerId}
                disableClearable={true}
                handleChange={handleChangeInheritedPartner}
              />
            </div>
            <div className="col-md-6 mb-4">
              <StyledPTitle className="mb-2">Who would you like to transfer clients to? </StyledPTitle>
              <BNPAutoComplete
                name="inheritedPartnerUserId"
                options={partnerUserOptions}
                optionSelectedId={inheritedPartnerUserId || 1}
                disableClearable={true}
                handleChange={handleChangeInheritedPartnerUser}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              Would you like to payout pending commission to the disabled partner?
            </div>

            <div className="col-12">
              <StyledDescription
                name="executePayoutJob"
                value={`${executePayoutJob}`}
                onChange={changeExecutePayoutJob}
              >
                <span key="executePayoutJob_yes">
                  <StyledFormControlLabel
                    value={"true"}
                    control={<Radio
                      // checked={executePayoutJob}
                      color="primary" />}
                    label="Yes"
                  />
                </span>
                <span key="executePayoutJob_no">
                  <StyledFormControlLabel
                    value={"false"}
                    control={<Radio
                      // checked={!executePayoutJob}
                      color="primary" />}
                    label="No"
                  />
                </span>
              </StyledDescription>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <div className="row w-100 d-flex flex-column flex-sm-row ">

            <div className="col-sm-2 p-0">
              <StyledCancelButton
                onClick={props.closePartnerModal}
                submit={true}
              >
                Cancel
              </StyledCancelButton>
            </div>
            <div className="col-sm-2 ml-sm-4 mt-2 mt-sm-0 p-0">
              <StyledCustomSubmitButton
                  onClick={doSubmit}
                  type="submit"
                  tabIndex={16}
                  submit={true}
                >
                  Disable Partner
              </StyledCustomSubmitButton>
            </div>
          </div>
        </div>
      </div>
    </StyledModalDialog>
  );
}


export function showDisablePartnerConfirmationModal() {
  ($("#disablePartnerModal") as any).modal({
    show: true,
    keyboard: false,
    backdrop: "static",
  });
}
export function hideDisablePartnerConfirmationModal() {
  ($("#disablePartnerModal") as any).modal("hide");
}
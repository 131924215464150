import { SortType } from "../../../cores/models/SortTable";

export interface EmailHistoryRes {
    id: number;
    fullName: string;
    emailTo: string;
    title: string;
    type: string;
    createdDate: Date;
}

export interface Criteria {
    from: string | Date | null;
    to: string | Date | null;
    filter: string | null;
    page: number;
    perPage: number;
    keyword: string;
    columnName: string[];
    sortType: SortType[];
}

export type UserType = {
  id: string;
  name: string;
};

export const userType: UserType[] = [
  {
    id: "admin",
    name: "Admin",
  },
  {
    id: "employee",
    name: "Employee",
  },
  {
    id: "employer",
    name: "Employer",
  },
];

export const criterialDefault: Criteria = {
    page: 1,
    perPage: 10,
    from: null,
    to: null,
    filter: null,
    keyword: "",
    columnName: ["createdDate"],
    sortType: ["DESC"]
}


import React from "react";
import {
  StyledMainContent,
  StyledBigTitle,
  StyledContentBox,
  StyledHideForMobile,
  StyledHideForDesktop,
  StyledExpansionPanelSummary,
  StyledTitleExpansion,
  StyledExpansionPanelDetails,
  StyledSpanExpansionEdit,
} from "../../shared/styled";
import {
  StyledTable,
  StyledHeaderTable,
  StyledCellTable,
  StyledBodyTable,
  StyledArrowDropDownIcon,
  StyledArrowDropUpIcon,
  StyledImportExportIcon,
} from "../../shared/table-styled";
import { formatterUSD } from "../../../cores/helpers/format-usd";
import { centToUsd } from "../../../cores/helpers/cent-to-usd";
import moment from "moment";
import Pagination, {
  PagingInfo,
} from "../../../cores/helpers/pagination/pagination";
import {
  DepositWithdrawTransactions,
  DepositWithdrawTransactionSearchParams,
} from "../deposit-withdraw-transaction/deposit-withdraw-transaction-type";
import styled from "styled-components";
import {
  ConfirmDialog,
  showConfirmModal,
} from "../../../cores/helpers/confirm-modal/confirm-modal";
import { BNPSelect } from "../../../cores/helpers/select/select";
import { SortTable } from "../../../cores/models/SortTable";
import { BNPInput } from "../../../cores/helpers/input/input";
import SearchIcon from "@material-ui/icons/Search";
import { ExpansionPanel } from "@material-ui/core";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import { convertTimeZone } from "../../../cores/utils/helpers";
import {FE_DATE_FORMAT} from "../../../cores/utils/format/date-time-format";

const StyledButtonApprove = styled.button`
  width: 90px;
  height: 25px;
  position: relative;
  background-color: ${(props) => props.theme.primaryColor};
  color: #ffffff;
  border: 1px solid #ecedf0;
  border-radius: 0.25rem;
  display: inline-block;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  padding: 0;
  font-size: 14px;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  :hover {
    opacity: 0.8;
  }
`;

const StyledButtonDecline = styled(StyledButtonApprove)`
  background-color: red;
`;

export const StyledBNPInput = styled(BNPInput)`
  padding-right: 35px;
`;

export const StyledIconSearch = styled(SearchIcon)`
  position: absolute;
  top: 13px;
  right: 14px;
  opacity: 0.7;
`;

type Props = {
  transactionsFilter: DepositWithdrawTransactions[];
  changePage: (page: number) => void;
  pagingInfo: PagingInfo;
  setRowsPerPage: (event: any) => void;
  searchParam: DepositWithdrawTransactionSearchParams;
  changeFilter: (event: any) => void;
  columns: SortTable[];
  sortTable: (columnId: string) => void;

  handleChangeInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyUp: (event: any) => void;
  searchBySundryNumber: () => void;
  handleDecline: (journalId: number, entry: number, isWithdraw: boolean) => void;
};

export default function RequestDepositWithdrawTransactionView(props: Props) {
  const [
    depositWithdrawTransaction,
    setDepositWithdrawTransaction,
  ] = React.useState<DepositWithdrawTransactions>({
    amount: 0,
    date: new Date(),
    firstName: "",
    lastName: "",
    journalEntryId: 0,
    journalId: 0,
    sundryNumber: "",
    type: "",
    transactionType: ""
  });

  const showModalConfirm = (transaction: DepositWithdrawTransactions) => {
    setDepositWithdrawTransaction(transaction);
    showConfirmModal();
  };

  return (
    <StyledMainContent>
      <StyledBigTitle>Deposit/Withdraw Request</StyledBigTitle>
      <div className="container-fluid p-0 mt-3">
        <StyledContentBox>
          <div className="row">
            <div className="col-8 mt-2"></div>
            <div className="col-lg-2 mt-2">
              <StyledBNPInput
                placeholder="Search by sundry number"
                onChange={props.handleChangeInput}
                value={props.searchParam.searchKey || ""}
                onKeyUp={props.onKeyUp}
                tabIndex={4}
              />
              <StyledIconSearch onClick={props.searchBySundryNumber} />
            </div>

            <div className="col-lg-2 mt-2">
              <BNPSelect
                options={[
                  { id: "Withdraw", name: "Withdraw" },
                  { id: "Deposit", name: "Deposit" },
                ]}
                placeholder="Filter by type"
                name="type"
                onChange={props.changeFilter}
                value={props.searchParam.type || -1}
              />
            </div>
            <div className="col-12 mt-3">
              <StyledHideForMobile>
                <StyledTable>
                  <StyledHeaderTable>
                    {props.columns.map((column, index) => (
                      <StyledCellTable
                        width={column.width}
                        key={index}
                        className="cur"
                        onClick={() => props.sortTable(column.columnId)}
                      >
                        {column.columnName}
                        <span>
                          {column.sortType ? (
                            column.sortType === "ASC" ? (
                              <StyledArrowDropDownIcon />
                            ) : (
                                <StyledArrowDropUpIcon />
                              )
                          ) : (
                              <StyledImportExportIcon />
                            )}
                        </span>
                      </StyledCellTable>
                    ))}
                    <StyledCellTable width={22} title="Approved">
                      Action
                    </StyledCellTable>
                  </StyledHeaderTable>
                  <div>
                    {props.transactionsFilter.map((transaction) => (
                      <StyledBodyTable>
                        <StyledCellTable
                          width={20}
                          title={
                            transaction.firstName + " " + transaction.lastName
                          }
                        >
                          {transaction.firstName + " " + transaction.lastName}
                        </StyledCellTable>
                        <StyledCellTable
                          width={20}
                          title={transaction.transactionType}
                        >
                          {transaction.transactionType}
                        </StyledCellTable>
                        <StyledCellTable
                          width={15}
                          title={formatterUSD("currency").format(
                            centToUsd(Math.abs(transaction.amount))
                          )}
                        >
                          {formatterUSD("currency").format(
                            centToUsd(Math.abs(transaction.amount))
                          )}
                        </StyledCellTable>
                        <StyledCellTable
                          width={15}
                          title={transaction.sundryNumber}
                        >
                          {transaction.sundryNumber}
                        </StyledCellTable>

                        <StyledCellTable
                          width={8}
                          title={
                            transaction.date
                              ? convertTimeZone("Canada/Eastern", transaction.date).format(FE_DATE_FORMAT)
                              : ""
                          }
                        >
                          {transaction.date
                            ? convertTimeZone("Canada/Eastern", transaction.date).format(FE_DATE_FORMAT)
                            : ""}
                        </StyledCellTable>
                        <StyledCellTable width={22}>
                          <StyledButtonDecline
                            onClick={() => showModalConfirm(transaction)}>
                            Decline
                          </StyledButtonDecline>
                        </StyledCellTable>
                      </StyledBodyTable>
                    ))}
                  </div>

                  <div className="text-center mt-4">
                    <label>
                      <Pagination
                        pagerPagination={props.pagingInfo}
                        getPage={(page) => props.changePage(page)}
                        onChangeRowsPerPage={props.setRowsPerPage}
                      />
                    </label>
                  </div>
                </StyledTable>
              </StyledHideForMobile>
              <StyledHideForDesktop>
                {props.transactionsFilter.map((transaction, index) => (
                  <ExpansionPanel
                    square
                    defaultExpanded={false}
                    className="mt-2"
                    key={index}
                  >
                    <StyledExpansionPanelSummary
                      className="m-0"
                      expandIcon={<ArrowDropUp />}
                    >
                      <div className="w-100 ">
                        <StyledTitleExpansion>Name</StyledTitleExpansion>
                        <div className="mt-2">
                          {transaction.firstName + " " + transaction.lastName}
                        </div>
                      </div>
                    </StyledExpansionPanelSummary>
                    <StyledExpansionPanelDetails>
                      <div className="w-100">
                        <StyledTitleExpansion>Type</StyledTitleExpansion>
                        <div className="mt-1">{transaction.transactionType}</div>
                      </div>
                      <div className="w-100 mt-3">
                        <StyledTitleExpansion>Amount</StyledTitleExpansion>
                        <div className="mt-1">
                          {formatterUSD("currency").format(
                            centToUsd(Math.abs(transaction.amount))
                          )}
                        </div>
                      </div>
                      <div className="w-100 mt-3">
                        <StyledTitleExpansion>
                          Sundry Number
                        </StyledTitleExpansion>
                        <div className="mt-1">{transaction.sundryNumber}</div>
                      </div>
                      <div className="w-100 mt-3">
                        <StyledTitleExpansion>Date</StyledTitleExpansion>
                        <div className="mt-1">
                          {transaction.date
                            ? convertTimeZone("Canada/Eastern", transaction.date).format(FE_DATE_FORMAT)
                            : ""}
                        </div>
                      </div>
                      <hr />
                      <div className="text-center">
                        <StyledSpanExpansionEdit>
                          <StyledButtonDecline onClick={() => showModalConfirm(transaction)}>
                            Decline
                          </StyledButtonDecline>
                        </StyledSpanExpansionEdit>
                      </div>
                    </StyledExpansionPanelDetails>
                  </ExpansionPanel>
                ))}
                <div className="row mt-4">
                  <div className="col-12 text-center">
                    <label>
                      <Pagination
                        pagerPagination={props.pagingInfo}
                        getPage={props.changePage}
                        onChangeRowsPerPage={props.setRowsPerPage}
                      />
                    </label>
                  </div>
                </div>
              </StyledHideForDesktop>
            </div>
          </div>
        </StyledContentBox>
      </div>
      <ConfirmDialog
        title={"Decline"}
        content={`Please confirm that you want to declined this ${depositWithdrawTransaction.transactionType.toLowerCase()}`}
        handleOnOk={() =>
          props.handleDecline(depositWithdrawTransaction.journalId,
            depositWithdrawTransaction.journalEntryId,
            depositWithdrawTransaction.transactionType === 'Withdraw')
        }
      />
    </StyledMainContent>
  );
}

import React from "react";
import styled from "styled-components";
import {
  Employee,
  TerminatingRequest,
} from "../company-management/company-management-type";

export const StyledSpanAction = styled.span`
  position: relative;
  top: 0;
  margin-right: 8px;
  font-weight: bold;
  color: ${(props) =>
    props.title === "Archive" ? props.theme.errorColor : props.theme.grey[500]};
  cursor: pointer;
  pointer-events: bounding-box;
`;

export enum employeeActionEnum {
  cancel,
  cancelNow,
  suspend,
  unSuspend,
  archive,
  reActivate,
}

type Props = {
  employee: Employee;

  showModalConfirm: (employee: Employee, confirm: employeeActionEnum) => void;
};

function EmployeeAction(props: Props) {
  const { employee } = props;

  return (
    <>
      {employee.t4Employee &&
        (employee.status === "TERMINATING" || employee.status === "TERMINATED") && (
          <StyledSpanAction
            title="Re-Activate"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              props.showModalConfirm(
                employee,
                employeeActionEnum.reActivate
              )
            }
            }
          >
            Re-Activate
          </StyledSpanAction>
        )}
      {employee.t4Employee && (
        <>
          {employee.status !== "TERMINATED" && <StyledSpanAction
            title="Cancel"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              props.showModalConfirm(
                employee,
                employee.status === "SUSPEND" ||
                employee.status === "TERMINATING"
                  ? employeeActionEnum.cancelNow
                  : employeeActionEnum.cancel
              );
            }}
          >
            Cancel
          </StyledSpanAction>}
          {employee.status === "SUSPEND" && (
            <StyledSpanAction
              title="Un-Suspend"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                props.showModalConfirm(employee, employeeActionEnum.unSuspend);
              }}
            >
              Un-suspend
            </StyledSpanAction>
          )}
          {employee.status === "WORKING" && (
            <StyledSpanAction
              title="Suspend"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                props.showModalConfirm(employee, employeeActionEnum.suspend);
              }}
            >
              Suspend
            </StyledSpanAction>
          )}
        </>
      )}
      <StyledSpanAction
        title="Archive"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          props.showModalConfirm(employee, employeeActionEnum.archive);
        }}
      >
        Archive
      </StyledSpanAction>
    </>
  );
}

export default EmployeeAction;

import React from "react";
import { ClassBenefit } from "./request-a-quote-type";
import IconDelete from "../../../assets/images/RAQ-Step2-fill/bin.png";
import styled from "styled-components";
import {
    StyledHeaderTable,
    StyledImgIconClose,
    StyledRowBodyTable,
    StyledSpanIconClose,
    StyledTable
} from "../../../shared/table-styled";

type Props = {
  defineClassBenefits: ClassBenefit[];
  delete: (classId: number) => void;
  findClassById: (classId: number) => { label: string; value: number };
};

const StyledColumn25Table = styled.div`
  width: 25%;
  padding: 1rem 0.5rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export default function RequestAQuoteTable(props: Props) {
  return (
    <StyledTable>
      <StyledHeaderTable>
        <StyledColumn25Table>Class</StyledColumn25Table>
        <StyledColumn25Table>No of Employee</StyledColumn25Table>
        <StyledColumn25Table>Coverage Amount</StyledColumn25Table>
        {/* <StyledColumn20Table>Plan</StyledColumn20Table> */}
        <StyledColumn25Table>Total</StyledColumn25Table>
      </StyledHeaderTable>
      <div>
        {props.defineClassBenefits.map(
          (defineClassBenefit: ClassBenefit, index: number) => (
            <StyledRowBodyTable key={defineClassBenefit.classId}>
              <StyledColumn25Table>
                {props.findClassById(defineClassBenefit.classId).label}
              </StyledColumn25Table>
              <StyledColumn25Table>
                {defineClassBenefit.noOfEmployees + " person"}
              </StyledColumn25Table>
              <StyledColumn25Table>
                {"$" + defineClassBenefit.coverageAmount}
              </StyledColumn25Table>
              {/* <StyledColumn20Table>Plan 1</StyledColumn20Table> */}
              <StyledColumn25Table className="w-20 font-weight-bold">
                {"$" +
                  (defineClassBenefit.coverageAmount || 0) *
                    (defineClassBenefit.noOfEmployees || 0) }
              </StyledColumn25Table>
              <StyledColumn25Table className="w-5">
                <StyledSpanIconClose style={{top: 0, left: 0}} onClick={() => props.delete(index)}>
                  <StyledImgIconClose
                    className="img-icon-delete"
                    alt="icon-delete"
                    src={IconDelete}
                    title="Delete"
                  />
                </StyledSpanIconClose>
              </StyledColumn25Table>
            </StyledRowBodyTable>
          )
        )}
      </div>
    </StyledTable>
  );
}

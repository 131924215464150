import React, { useMemo } from "react";
import { BNPModal } from "../../../../shared/BNPModal/BNPModal";
import { BNPSelect, Option } from "../../../../../cores/helpers/select/select";
import { StyledButtonSubmit, StyledLargerTitle } from "../../../../shared/styled";
import { AutoDepositPendingType } from "./PayAutoTopUpPendingType";
import BNPRawTable from "../../../../shared/BNPTable/BNPRawTable";
import { TableSortType } from "../../../../shared/BNPTable/BNPTableType";
import { TableSortDataType } from "../../../../shared/BNPTable/BNPTableModel";
import { centToUsd } from "../../../../../cores/helpers/cent-to-usd";
import { formatterUSD } from "../../../../../cores/helpers/format-usd";
import { closeConfirmDialog, openConfirmDialog } from "../../../../../cores/utils/confirmDialog";

type PayAutoTopUpPendingModalProps = {
  open: boolean;
  onClose: () => void;
  pendingTopUp: AutoDepositPendingType[];
  handleSubmit: (productId: string) => Promise<void>;
  availableAmount: number;
};

function PayAutoTopUpPendingModal(props: PayAutoTopUpPendingModalProps) {
  const [selectedProductId, setSelectedProductId] = React.useState<string | number>("");

  const isValid = useMemo(() => {
    return !!selectedProductId && selectedProductId !== -1;
  } , [selectedProductId]);

  const options: Option[] = useMemo(() => {
    return props.pendingTopUp
    .filter((item) => item.amount <= props.availableAmount)
    .map((item) => ({
      id: `${item.depositGroupCode}-${item.productName}`,
      name: item.productName || item.depositGroupName,
    }));
  }, [props.pendingTopUp]);

  const handleChangeSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedProductId(event.target.value);
  };

  const handleSubmit = () => {
    const [depositGroupCode, productName] = selectedProductId.toString().split("-");
    const selectedProduct = props.pendingTopUp.find(
      (item) => item.depositGroupCode === depositGroupCode && item.productName === productName);
    if (!selectedProduct) {
      return;
    }

    openConfirmDialog({
      id: `pay-auto-top-up-${selectedProductId}`,
      open: true,
      title: `Pay Auto Top Up Pending`,
      content: `Are you sure you want to pay for ${selectedProduct.productName} with amount ${formatterUSD("currency", "USD").format(centToUsd(selectedProduct.amount))}?`,
      onClose: (isOk) => handleCloseConfirm(isOk)
    })
  };

  const handleCloseConfirm = (isOk: boolean) => {
    if (isOk) {
      props.handleSubmit(selectedProductId.toString());
    }
    setSelectedProductId("");
    closeConfirmDialog();
    props.onClose();
  };

  const body = (
    <>
      <BNPSelect
        options={options}
        value={selectedProductId || -1}
        name="autoTopUpProductId"
        placeholder="Select Auto Top Up Product"
        onChange={handleChangeSelect}
        tabIndex={1}
      />
      <BNPRawTable
          columns={columns}
          tableWidth="100%"
          tableData={props.pendingTopUp}
      />
    </>
  );

  const footer = (
    <div className="row w-100">
      <div className="col-9 align-content-center">
        <StyledLargerTitle>Balance in Account: {formatterUSD("currency", "USD").format(centToUsd(props.availableAmount))}</StyledLargerTitle>
      </div>
      <div className="col-3">
        <StyledButtonSubmit
          disabled={!isValid}
          onClick={handleSubmit}
          submit={isValid}
          type="submit"
        >
          Pay Auto Top Up
        </StyledButtonSubmit>
      </div>
    </div>
  );

  return (
    <BNPModal
      open={props.open}
      title={"Pay Auto Top Up Pending"}
      handleClose={props.onClose}
      body={body}
      footer={footer}
      modalSize={"md"}
    />
  );
}

export default PayAutoTopUpPendingModal;

const columns: TableSortType[] = [
  {
    columnId: "depositGroupName",
    columnName: "Group Name",
    width: 40,
    sortType: "ASC",
    sortColumn: false
  },
  {
    columnId: "productName",
    columnName: "Product Name",
    width: 30,
    sortType: "ASC",
    sortColumn: false
  },
  {
    columnId: "amount",
    columnName: "Amount",
    width: 30,
    sortType: "ASC",
    sortColumn: false,
    type: TableSortDataType.CURRENCY
  },
];

import { SortTable } from "../../../cores/models/SortTable";

// CUSTOM add select UI;
export const dateRange = [
  {
    id: "THIS_YEAR",
    name: "This Year",
  },
  {
    id: "LAST_YEAR",
    name: "Last Year",
  },
  {
    id: "THIS_QUARTER",
    name: "This Quarter",
  },
  {
    id: "LAST_QUARTER",
    name: "Last Quarter",
  },
  {
    id: "THIS_MONTH",
    name: "This Month",
  },
  {
    id: "LAST_MONTH",
    name: "Last Month",
  },
  {
    id: "CUSTOM",
    name: "Custom",
  },
];

export const taxHeader = (): SortTable[] => {
  return [
    {
      columnId: "province",
      columnName: "province",
      sortType: null,
      width: 20,
    },
    {
      columnId: "gst",
      columnName: "gst",
      sortType: null,
      width: 20,
    },
    {
      columnId: "pst",
      columnName: "pst",
      sortType: null,
      width: 20,
    },
    {
      columnId: "rst",
      columnName: "rst",
      sortType: null,
      width: 20,
    },
    {
      columnId: "premiumTax",
      columnName: "premiumTax",
      sortType: null,
      width: 20,
    },
  ];
};

import {AxiosResponse} from "axios";
import {deleteAsync, getAsync, postAsync, putAsync} from "../cores/utils/http-client";

const URL_Employer_Premium_Payment = "/manage-employer/api/premium-payment";

export const getPremiumPaymentProviders = (productId: number): Promise<AxiosResponse> => {
  let url = `${URL_Employer_Premium_Payment}/providers?productId=${productId}`;

  return getAsync(url);
};

export const getPremiumPaymentProducts = (): Promise<AxiosResponse> => {
  let url = `${URL_Employer_Premium_Payment}/products`;

  return getAsync(url);
};

export const getPremiumPaymentProductProviders = (productId: number, providerId: number): Promise<AxiosResponse> => {
  let url = `${URL_Employer_Premium_Payment}/product/provider?productId=${productId}&providerId=${providerId}`;

  return getAsync(url);
};

export const createPremiumPaymentSubscription = (request: any): Promise<AxiosResponse> => {
  let url = `${URL_Employer_Premium_Payment}/employer/subscription`;

  return postAsync(url, request);
};

export const createPremiumPaymentSubscriptionRequestToQuote = (request: any): Promise<AxiosResponse> => {
  let url = `${URL_Employer_Premium_Payment}/employer/subscription`;

  return putAsync(url, request);
};

export const getPremiumPaymentSubscriptions = (employerId: number): Promise<AxiosResponse> => {
  let url = `${URL_Employer_Premium_Payment}/employer/subscriptions?employerId=${employerId}`;

  return getAsync(url);
};

export const getBriefPremiumPaymentSubscriptions = (employerId: number): Promise<AxiosResponse> => {
  let url = `${URL_Employer_Premium_Payment}/employer/brief-subscriptions?employerId=${employerId}`;

  return getAsync(url);
};

export const updateEmployerPremiumPaymentStatus = (id: number, status: string): Promise<AxiosResponse> => {
  let url = `${URL_Employer_Premium_Payment}/employer/subscription/status?premiumPaymentId=${id}&status=${status}`;

  return postAsync(url, {});
};

export const archiveEmployerPremiumPayment = (id: number): Promise<AxiosResponse> => {
  let url = `${URL_Employer_Premium_Payment}/employer/subscription/${id}`;

  return deleteAsync(url);
};
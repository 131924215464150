import { SummarizeType, TReportWallet, TReportFinancial, TReportCommission } from "./DashboardType";
import ReportSummarized from "./component/ReportSummarized";
import {WalletReportTitleMap, CommissionReportTitleMap} from "./DashboardModel";
import WalletReport from "./wallet/WalletReport";
import FinancialReport from "./financial/FinancialReport";
import CommissionReport from "./commission/CommissionReport";
import {QuoteEngine} from "./quote/QuoteEngine";

export type DashboardSummarizeType = SummarizeType;
export type TReportWallet = TReportWallet<number | string>;
export type TReportFinancial = TReportFinancial<number | string>;
export type TReportCommission = TReportCommission<number | string>;
export const DashboardReportSummarized = ReportSummarized;
export const DashboardWalletReportTitleMap = WalletReportTitleMap;
export const DashboardCommissionReportTitleMap = CommissionReportTitleMap;
export const DashboardWalletReport = WalletReport;
export const DashboardFinancialReport = FinancialReport;
export const DashboardCommissionReport = CommissionReport;
export const DashboardQuoteEngine = QuoteEngine;

import React from "react";
import styled from "styled-components";
import { Dependent, Relationship } from "./employee-type";
import {
  StyledLargerTitle,
  StyledRow,
  StyledLabel,
  StyledButtonSubmit,
  StyledHideForMobile,
} from "../../../shared/styled";
import { BNPInput } from "../../../../cores/helpers/input/input";
import { BNPSelect } from "../../../../cores/helpers/select/select";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { convertTimeZone } from "../../../../cores/utils/helpers";

const StyledDivDash = styled.div`
  width: 40%;
  border-bottom: 1px solid #e4e5eb;
`;
const StyledDivAnd = styled.div`
  width: 20%;
  opacity: 0.5;
  color: #2d2d2d;
  font-size: 12px;
  font-weight: bold;
  line-height: 22px;
  text-align: center;
  position: relative;
  top: 10px;
`;

const StyledDivRemove = styled.div`
  color: ${(props) => props.theme.secondaryColor};
  width: 50%;
`;
const StyledDivAdd = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  width: 100%;
  text-align: right;
  color: ${(props) => props.theme.primaryColor};
`;

const StyledDivDatePicker = styled.div`
  border: 1px solid #ced4da;
  border-radius: 5px;
  .MuiFormControl-marginNormal {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0.5rem;
  }
  .MuiInputBase-input {
    font-size: 14px;
    height: 19px;
  }
`;

type Props = {
  handleChangeDependents: (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => void;
  handleChangeDate: (
    date: Date | null,
    name: string,
    index: number
  ) => void;
  addDependents: () => void;
  removeDependents: (index?:number) => void;
  handleSubmitDependents: () => void;
  dependents: Dependent[];
  relationships: Relationship[];
  isValid: boolean;
  findRelationshipById: (id: string) => string;
};

export default function SetupDependentsView(props: Props) {
  return (
    <div className="row">
      <div className="col-12">
        {props.dependents.map((dependent, index) => (
          <StyledRow key={index}>
            <div className="col-12 col-lg-6 mt-3">
              <StyledLabel>First name</StyledLabel>
              <BNPInput
                onChange={(e) => props.handleChangeDependents(e, index)}
                placeholder="First name"
                value={dependent.firstName || ""}
                name="firstName"
              />
            </div>
            <div className="col-12 col-lg-6 mt-3">
              <StyledLabel>Last name</StyledLabel>
              <BNPInput
                onChange={(e) => props.handleChangeDependents(e, index)}
                placeholder="Last name"
                value={dependent.lastName || ""}
                name="lastName"
              />
            </div>
            <div className="col-12 col-lg-6 mt-3">
            <StyledLabel>Date of birth</StyledLabel>
            <StyledDivDatePicker>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk
                  disableFuture
                  InputAdornmentProps={{ position: "start" }}
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-inline1"
                  placeholder="Date of birth"
                  value={dependent.dateOfBirth ?
                    convertTimeZone("Canada/Eastern", dependent.dateOfBirth)
                    :
                    null}
                  onChange={date => props.handleChangeDate(date, "dateOfBirth", index)}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  tabIndex={4}
                />
              </MuiPickersUtilsProvider>
            </StyledDivDatePicker>
            </div>
            <div className="col-12 col-lg-6 mt-3">
              <StyledLabel>Relationship</StyledLabel>
              <BNPSelect
                onChange={(e: any) => props.handleChangeDependents(e, index)}
                options={props.relationships}
                placeholder="Relationship"
                value={dependent.relationship}
                name="relationship"
              />
            </div>
            <div className="col-12 d-flex mt-3">
              <StyledDivRemove>
                <span onClick={() => props.removeDependents(index)}>Remove</span>
              </StyledDivRemove>
            </div>

            {props.dependents.length > 1 &&
              index < props.dependents.length - 1 && (
                <div className="col-12 d-flex mt-3 mb-3">
                  <StyledDivDash></StyledDivDash>
                  <StyledDivAnd>AND</StyledDivAnd>
                  <StyledDivDash></StyledDivDash>
                </div>
              )}
          </StyledRow>
        ))}

        <div className="row">
          <div className="col-12 mt-3">
            <StyledDivAdd onClick={props.addDependents}>
              Add more Dependent
            </StyledDivAdd>
          </div>
        </div>

        <div className="row mt-5">
          <StyledHideForMobile className="col-lg-2">
            {/* {!props.isModal && <StyledBtnLink
              to={setupBankingURL}
              background={theme.secondaryColor}
            >
              Back
            </StyledBtnLink>} */}
          </StyledHideForMobile>
          <div className="col-lg-7"></div>
          <div className="col-lg-3">
            <StyledButtonSubmit
              onClick={() => {
                props.handleSubmitDependents();
              }}
              submit={props.isValid}
              disabled={!props.isValid}
              id="buttonSaveDependent"
            >
              Save
            </StyledButtonSubmit>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import styled from "styled-components";
import { IOSSwitch } from "../../../../cores/helpers/switch/ios-switch";
import {BNPInput} from "../../../../cores/helpers/input/input";

const StyledSpanSwitchTitle = styled.span`
  position: relative;
  top: 14px;
  max-width: 70%;
`;
const StyledSpanSwitch = styled.span`
  position: absolute;
  right: -8px;
`;

const StyledDivBox = styled.div`
  position: relative;
  margin-bottom: 48px;
`;

const StyledErrorMessage = styled.div`
  font-size: 12px;
  margin-top: 8px;
  color: ${props => props.theme.errorColor};
`;

type Props = {
  isChecked: boolean;
  onSwitchChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  manualAmount: number | null;
  setManualAmount: (amount: number) => void;
  isValid: boolean
};

export default function ManualApproveAmount(props: Props) {

  return (
    <StyledDivBox>
      <div className="d-flex mb-4">
        <StyledSpanSwitchTitle>
          Manual Approve Amount
        </StyledSpanSwitchTitle>
        <StyledSpanSwitch>
          <IOSSwitch
            checked={props.isChecked}
            onChange={props.onSwitchChange}
          />
        </StyledSpanSwitch>
      </div>

      {props.isChecked &&
        <div>
          <BNPInput
              onChange={(e: any) => props.setManualAmount(e.target.value)}
              type="number"
              name="amount"
              value={(props.manualAmount || "")}
          />
          {!props.isValid && <StyledErrorMessage>Input amount is out of limit</StyledErrorMessage>}
        </div>
      }
    </StyledDivBox>
  );
}

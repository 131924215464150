import React, { useEffect, useState } from "react";
import { StyledButtonSubmit, StyledDivTitle } from "../../../shared/styled";
import BNPDatePicker from "../../../../cores/helpers/date-picker/date-picker";
import { TReportComparison } from "../DashboardType";
import {
  WalletComparisonReportColumn,
  WalletReportTitleMap,
} from "../DashboardModel";
import BNPRawTable from "../../../shared/BNPTable/BNPRawTable";
import { getWalletComparisonReport } from "../../../../services/dashboard-service";
import moment from "moment";
import {BE_DATE_FORMAT, FE_DATE_FORMAT} from "../../../../cores/utils/format/date-time-format";
import { addLoading, removeLoading } from "../../../../cores/utils/loading";
import { formatterUSD } from "../../../../cores/helpers/format-usd";
import { centToUsd } from "../../../../cores/helpers/cent-to-usd";
import {getMonthsFromNowDate} from "../../../../cores/utils/helpers";
import {TableSortType} from "../../../shared/BNPTable/BNPTableType";
import { AxiosResponse } from "axios";
import DownloadIcon from "@material-ui/icons/GetApp";

type Props = {
  getWalletComparisonReport: (fromDate: (Date | string), toDate: (Date | string)) => Promise<AxiosResponse>;
  exportWalletComparisonReport: (fromDate: (Date | string), toDate: (Date | string)) => void;
};

export function WalletComparisonReport(props: Props) {
  const [data, setData] = useState<TReportComparison[]>([]);
  const [column, setColumn] = useState<TableSortType[]>(WalletComparisonReportColumn);
  const [fromDate, setFromDate] = useState<Date>(getMonthsFromNowDate(-1));
  const [toDate, setToDate] = useState<Date>(new Date());

  useEffect(() => {
    fetchData().then();
    setColumn(updateColumnName())
  }, [fromDate, toDate]);

  function updateColumnName(): TableSortType[] {
    return WalletComparisonReportColumn.map(item => {
      if (item.columnId === 'earlier')
        return { ...item, columnName: item.columnName.replace('earlier', moment(fromDate).format(FE_DATE_FORMAT))}
      if (item.columnId === 'later')
        return { ...item, columnName: item.columnName.replace('later', moment(toDate).format(FE_DATE_FORMAT))}
      return item
    })
  }

  async function fetchData() {
    addLoading();
    const response = await props.getWalletComparisonReport(fromDate, toDate);
    setData(processData(response.data));
    removeLoading();
  }

  function processData(data: any) {
    return Object.keys(data.earlierDateReport.report).map((key) => {
      const earlier = data.earlierDateReport.report[key];
      const later = data.laterDateReport.report[key];
      return {
        metric: WalletReportTitleMap[key],
        earlier: WalletReportTitleMap[key].includes("Number")
          ? formatterUSD("decimal").format(Number(earlier))
          : formatterUSD("currency", "USD").format(centToUsd(Number(earlier))),
        later: WalletReportTitleMap[key].includes("Number")
          ? formatterUSD("decimal").format(Number(later))
          : formatterUSD("currency", "USD").format(centToUsd(Number(later))),
        comparison: formatterUSD("percent").format(later / earlier),
      };
    });
  }

  function handleChangeDate(date: Date | null, name: string) {
    if (name === "from" && date) setFromDate(date);
    if (name === "to" && date) setToDate(date);
  }

  async function handleExportExcel() {
    addLoading();
    props.exportWalletComparisonReport(fromDate, toDate)
    removeLoading();
  }

  return (
    <>
      <div className="col-lg-3 col-md-4 col-12">
        <StyledDivTitle>Date A</StyledDivTitle>
        <BNPDatePicker
          value={fromDate}
          onChange={(date) => handleChangeDate(date, "from")}
          name="date"
          maxDate={new Date()}
        />
      </div>
      <div className="col-lg-3 col-md-4 col-12">
        <StyledDivTitle>Date B</StyledDivTitle>
        <BNPDatePicker
          value={toDate}
          onChange={(date) => handleChangeDate(date, "to")}
          name="date"
          maxDate={new Date()}
        />
      </div>
      <div className="col-lg-4 col-12" />
      <div className="col-lg-2 col-12">
        <StyledDivTitle style={{height: "26px"}}></StyledDivTitle>
        <StyledButtonSubmit
          submit={true}
          onClick={handleExportExcel}
        >
          <DownloadIcon className="icon" style={{marginRight: "5px"}}/>
          Export To Excel
        </StyledButtonSubmit>
      </div>
      <div className="col-lg-12 pt-3">
        <BNPRawTable
          columns={column}
          tableWidth="100%"
          tableData={data}
        />
      </div>
    </>
  );
}

import React from "react";
import { MenuItem } from "@material-ui/core";
import {
  StyledSelect,
  StyledOption,
  StyledDefaultOption,
} from "../../../components/shared/styled";

type SelectProps = {
  value?: any;
  name?: string;
  onChange?: (event: any) => void;
  options: Option[];
  placeholder?: string;
  disabled?: boolean;
  hidden?: boolean;
  className?: string;
  tabIndex?: number;
  ignore?: boolean;
  defaultValue?: any;
};
export type Option = {
  id: number | string;
  name: number | string;
  disabled?: boolean | undefined;
};

export const BNPSelect = (props: SelectProps) => (
  <StyledSelect
    value={props.value !== null ? props.value : -1}
    onChange={props.onChange}
    name={props.name}
    disabled={props.disabled}
    hidden={props.hidden}
    className={props.className ? props.className : ""}
    inputProps={{ tabIndex: props.tabIndex ? props.tabIndex : 0 }}
    defaultValue={props.defaultValue || -1}
    MenuProps={{
      PaperProps: {
        style: {
          maxHeight: 300,
          marginTop: "8px" // Adjust as needed to control distance from input
        }
      },
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "left"
      },
      getContentAnchorEl: null,
      autoFocus: false
    }}
  >
    {
      !props.ignore && <MenuItem value={-1} key={-1}>
        <StyledDefaultOption title={props.placeholder}>
          {props.placeholder}
        </StyledDefaultOption>
      </MenuItem>
    }

    {props.options.map((option) => (
      <MenuItem value={option.id} key={option.id} disabled={!!option.disabled} >
        <StyledOption title={option.name ? option.name.toString() : ""}>
          {option.name}
        </StyledOption>
      </MenuItem>
    ))}
  </StyledSelect>
);

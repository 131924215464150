import React, { useEffect, useState } from "react";
import { StyledButtonSubmit, StyledDivTitle } from "../../../shared/styled";
import BNPDatePicker from "../../../../cores/helpers/date-picker/date-picker";
import { TReportFinancial, TReportWallet } from "../DashboardType";
import { formatterUSD } from "../../../../cores/helpers/format-usd";
import { centToUsd } from "../../../../cores/helpers/cent-to-usd";
import { addLoading, removeLoading } from "../../../../cores/utils/loading";
import BNPRawTable from "../../../shared/BNPTable/BNPRawTable";
import { FinancialReportColumn } from "../DashboardModel";
import DownloadIcon from "@material-ui/icons/GetApp";

type Props = {
  getFinancialSummaryReport: (date: Date | string) => Promise<TReportFinancial<number>>;
  getWalletSummaryReport: (date: Date | string) => Promise<TReportWallet<number>>;
  exportFinancialSummaryReport: (date: Date | string) => void;
};

export function FinancialSummaryReport(props: Props) {
  const [date, setDate] = useState<Date | string>(new Date());
  const [data, setData] = useState<TReportFinancial<string | number>[]>([]);

  useEffect(() => {
    fetchData().then();
  }, [date]);

  async function fetchData() {
    addLoading();
    const [walletResult, financialResult] = await Promise.all([
      props.getWalletSummaryReport(date),
      props.getFinancialSummaryReport(date),
    ]);
    setData(processSummarizedData(walletResult, financialResult));
    removeLoading();
  }

  function processSummarizedData(
    walletData: TReportWallet<number>,
    financialData: TReportFinancial<number>
  ): TReportFinancial<string | number>[] {
    const { totalSize, averageSize } = walletData;
    const { claims, taxes, adminFees } = financialData;
    return [
      {
        totalWalletSize: formatterUSD("currency", "USD").format(
          centToUsd(Number(totalSize))
        ),
        averageWalletSize: formatterUSD("currency", "USD").format(
          centToUsd(Number(averageSize))
        ),
        claims: formatterUSD("currency", "USD").format(
          centToUsd(Number(claims))
        ),
        taxes: formatterUSD("currency", "USD").format(centToUsd(Number(taxes))),
        adminFees: formatterUSD("currency", "USD").format(
          centToUsd(Number(adminFees))
        ),
      },
    ];
  }

  function handleChangeDate(date: Date | null, name: string) {
    if (date) setDate(date);
  }

  async function handleExportExcel() {
    addLoading();
    props.exportFinancialSummaryReport(date);
    removeLoading();
  }

  return (
    <>
      <div className="col-lg-3 col-md-4 col-12">
        <StyledDivTitle>Date of Report</StyledDivTitle>
        <BNPDatePicker
          value={date}
          onChange={(date) => handleChangeDate(date, "date")}
          name="date"
          maxDate={new Date()}
        />
      </div>
      <div className="col-lg-6 col-12" />
      <div className="col-lg-2 col-12">
        <StyledDivTitle style={{height: "26px"}}></StyledDivTitle>
        <StyledButtonSubmit
          submit={true}
          onClick={handleExportExcel}
        >
          <DownloadIcon className="icon" style={{marginRight: "5px"}}/>
          Export To Excel
        </StyledButtonSubmit>
      </div>
      <div className="col-lg-12 pt-3">
        <BNPRawTable
          columns={FinancialReportColumn}
          tableWidth="100%"
          tableData={data}
        />
      </div>
    </>
  );
}

import {AxiosResponse} from "axios";
import {getAsync, getFileAsync, postAsync} from "../cores/utils/http-client";
import { PartnerTransactionExportParam, SearchPartnerTransactionParam } from "../components/pages/partner-management/partner-detail/transaction/TransactionType";

const URL_WALLET_PARTNER = "/manage-wallet-partner/partner/wallet";
const URL_WALLET_REPORT_PARTNER = "/manage-wallet-partner/partners";
const URL_WALLET_REPORT_ADMIN = "/manage-wallet-partner/partner/report";

export type PartnerAdjustmentType = {
  amount: number;
  targetId: number;
  desc: string;
  adjustmentType: string;
  employerId?: number;
  enrollmentId?: number;
};

export const postAdjustmentPartnerWallet = (
  model: PartnerAdjustmentType
): Promise<AxiosResponse> => {
  let url = `${URL_WALLET_PARTNER}/manual/partner/commission/adjustment`;

  return postAsync(url, model);
};

export const getPartnerCommissionBalances = (
  partnerId: number
): Promise<AxiosResponse> => {
  let url = `${URL_WALLET_PARTNER}/${partnerId}/commission/balance`;

  return getAsync(url, {});
};

export const getPartnerTransactions = (
  partnerId: number,
  param: SearchPartnerTransactionParam
): Promise<AxiosResponse> => {
  let url = `${URL_WALLET_PARTNER}/${partnerId}/transactions/all`;

  return getAsync(url, param);
};

export const getExportPartnerTransactions = (
  partnerId: number,
  param: PartnerTransactionExportParam
): Promise<AxiosResponse> => {
  let url = `${URL_WALLET_PARTNER}/${partnerId}/transactions/all/export`;

  return getFileAsync(url, param);
};

export const getPartnerTransactionTitles = (
  partnerId: number
): Promise<AxiosResponse> => {
  let url = `${URL_WALLET_PARTNER}/${partnerId}/transactions/titles`;

  return getAsync(url);
};

export type PartnerCommissionReportRequestType = {
  partnerUserIds: number[] | string | null;
  fromDate: string;
  toDate: string;
  enrollmentId: number | null;
  reportingType: "SUMMARY" | "MONTH" | "YEAR",
  page: number;
  perPage: number;
  columnName?: string;
  sortType?: 'ASC' | 'DESC';
}

export const getPartnerCommissionReport = (partnerId: number, request: PartnerCommissionReportRequestType): Promise<AxiosResponse> => {
  let url = `${URL_WALLET_REPORT_PARTNER}/${partnerId ? partnerId : ""}/report/commission`;

  if (!!request.partnerUserIds && Array.isArray(request.partnerUserIds)) {
    request.partnerUserIds = request.partnerUserIds.join(',')
  }

  return getAsync(url, request);
}

export const getPartnerMemberCommissionReport = (partnerId: number, request: PartnerCommissionReportRequestType): Promise<AxiosResponse> => {
  let url = `${URL_WALLET_REPORT_PARTNER}/${partnerId ? partnerId : ""}/report/commission/member`;

  if (!!request.partnerUserIds && Array.isArray(request.partnerUserIds)) {
    request.partnerUserIds = request.partnerUserIds.join(',')
  }

  return getAsync(url, request);
}

export type PartnerCommissionReportTotalRequestType = {
  partnerUserIds: number[] | string | null;
  fromDate: string;
  toDate: string;
  enrollmentId: number | null;
}

export const getPartnerCommissionReportTotal = (partnerId: number, request: PartnerCommissionReportTotalRequestType): Promise<AxiosResponse> => {
  let url = `${URL_WALLET_REPORT_PARTNER}/${partnerId ? partnerId : ""}/report/commission/total`;

  if (!!request.partnerUserIds && Array.isArray(request.partnerUserIds)) {
    request.partnerUserIds = request.partnerUserIds.join(',')
  }

  return getAsync(url, request);
}

export const getPartnerMemberCommissionReportTotal = (partnerId: number, request: PartnerCommissionReportTotalRequestType): Promise<AxiosResponse> => {
  let url = `${URL_WALLET_REPORT_PARTNER}/${partnerId ? partnerId : ""}/report/commission/member/total`;

  if (!!request.partnerUserIds && Array.isArray(request.partnerUserIds)) {
    request.partnerUserIds = request.partnerUserIds.join(',')
  }

  return getAsync(url, request);
}

export type AdminCommissionReportRequestType = {
  partnerIds: number[] | string | null;
  fromDate: string;
  toDate: string;
  enrollmentId: number | null;
  reportingType: "SUMMARY" | "MONTH" | "YEAR",
  page: number;
  perPage: number;
  columnName?: string;
  sortType?: 'ASC' | 'DESC';
}

export const getAdminCommissionReport = (request: AdminCommissionReportRequestType): Promise<AxiosResponse> => {
  let url = `${URL_WALLET_REPORT_ADMIN}/commission`;

  if (!!request.partnerIds && Array.isArray(request.partnerIds)) {
    request.partnerIds = request.partnerIds.join(',')
  }

  return getAsync(url, request);
}

export type AdminCommissionReportTotalRequestType = {
  partnerIds: number[] | string | null;
  fromDate: string;
  toDate: string;
  enrollmentId: number | null;
}

export const getAdminCommissionReportTotal = (request: AdminCommissionReportTotalRequestType): Promise<AxiosResponse> => {
  let url = `${URL_WALLET_REPORT_ADMIN}/commission/total`;

  if (!!request.partnerIds && Array.isArray(request.partnerIds)) {
    request.partnerIds = request.partnerIds.join(',')
  }

  return getAsync(url, request);
}

type ExportCommissionReportType = {
  fromDate: string;
  toDate: string;
  enrollmentId: number | null;
  reportingType: "SUMMARY" | "MONTH" | "YEAR",
}
export type ExportAdminCommissionReportType = ExportCommissionReportType & {
  partnerIds: number[] | string | null;
}

export const exportAdminCommissionReport = (request: ExportAdminCommissionReportType): Promise<AxiosResponse> => {
  let url = `${URL_WALLET_REPORT_ADMIN}/commission/export`;

  if (!!request.partnerIds && Array.isArray(request.partnerIds)) {
    request.partnerIds = request.partnerIds.join(',')
  }

  return getFileAsync(url, request);
}

export type ExportPartnerCommissionReportType = ExportCommissionReportType & {
  partnerUserIds: number[] | string | null;
}

export const exportPartnerCommissionReport = (partnerId: number,
                                              request: ExportPartnerCommissionReportType): Promise<AxiosResponse> => {
  let url = `${URL_WALLET_REPORT_PARTNER}/${partnerId ? partnerId : ""}/report/commission/export`;

  if (!!request.partnerUserIds && Array.isArray(request.partnerUserIds)) {
    request.partnerUserIds = request.partnerUserIds.join(',')
  }

  return getFileAsync(url, request);
}

export const exportPartnerMemberCommissionReport = (partnerId: number,
                                              request: ExportPartnerCommissionReportType): Promise<AxiosResponse> => {
  let url = `${URL_WALLET_REPORT_PARTNER}/${partnerId ? partnerId : ""}/report/commission/member/export`;

  if (!!request.partnerUserIds && Array.isArray(request.partnerUserIds)) {
    request.partnerUserIds = request.partnerUserIds.join(',')
  }

  return getFileAsync(url, request);
}

export type CommissionPayoutReportRequestType = {
  partnerIds: number[] | string | null;
  fromDate: string;
  toDate: string;
  reportingType: "SUMMARY" | "MONTH" | "YEAR",
  page: number;
  perPage: number;
  columnName?: string;
  sortType?: 'ASC' | 'DESC';
}

export const getCommissionPayoutReport = (request: CommissionPayoutReportRequestType): Promise<AxiosResponse> => {
  let url = `${URL_WALLET_REPORT_ADMIN}/commission-payout`;

  if (!!request.partnerIds && Array.isArray(request.partnerIds)) {
    request.partnerIds = request.partnerIds.join(',')
  }

  return getAsync(url, request);
}

export type ExportCommissionPayoutReportType = {
  partnerIds: number[] | string | null;
  fromDate: string;
  toDate: string;
  reportingType: "SUMMARY" | "MONTH" | "YEAR",
}

export const exportCommissionPayoutReport = (request: ExportCommissionPayoutReportType): Promise<AxiosResponse> => {
  let url = `${URL_WALLET_REPORT_ADMIN}/commission-payout/export`;

  if (!!request.partnerIds && Array.isArray(request.partnerIds)) {
    request.partnerIds = request.partnerIds.join(',')
  }

  return getFileAsync(url, request);
}
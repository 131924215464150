import React, { useEffect } from "react";
import { EFTInformationView } from "./eft-information-view";
import { EFTRequest } from "./eft-information-type";
import { getEFT, putEFT } from "../../../../services/parner-auth-services";
import { publishMessage } from "../../../../cores/utils/message";
import {addLoading, removeLoading} from "../../../../cores/utils/loading";

type Props = {};

export default function EFTInformation(props: Props) {
  const [eft, setEft] = React.useState<EFTRequest>({
    originatorID: 0,
    destinationDataCentreCode: "",
    originatorLongName: "",
    originatorShortName: "",
    returnAccountNumber: "",
    returnBranchNumber: "",
    returnInstitutionNumber: "",
    url: ""
  })

  useEffect(() => {
    addLoading();
    init().then(() => removeLoading());
  }, [])

  const init = async () => {
    const eftInfoResponse = await getEFT()
    setEft(eftInfoResponse.data)
  }

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let eftInfo: any = { ...eft };

    if (
      name === "returnInstitutionNumber" ||
      name === "returnBranchNumber" ||
      name === "returnAccountNumber"
    ) {
      if (Number(value) >= 0) {
        eftInfo[name] = value;
      }
    } else {
      eftInfo[name] = value;
    }

    setEft(eftInfo);
  };

  const handleSubmit = () => {
    addLoading();
    putEFT(eft).then(() => {
      publishMessage({
        message: "EFT information has been updated successfully.",
        variant: "success",
      });
      return init();
    }).then(() => removeLoading())
    ;
  };

  const validate = (eftInfo: EFTRequest) => {
    if (
      eftInfo.originatorID &&
      eftInfo.destinationDataCentreCode &&
      eftInfo.originatorLongName &&
      eftInfo.originatorShortName &&
      eftInfo.returnAccountNumber &&
      eftInfo.returnBranchNumber &&
      eftInfo.returnInstitutionNumber
    ) {
      return true;
    }

    return false;
  };

  return (
    <EFTInformationView
      handleChangeInput={handleChangeInput}
      handleSubmit={handleSubmit}
      eftInfo={eft}
      validate={validate} />
  );
}

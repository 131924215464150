import React, {useEffect, useState} from "react";
import moment from "moment";
import DownloadIcon from "@material-ui/icons/GetApp";
import {BNPSelect, Option} from "../../../../../cores/helpers/select/select";
import {StyledBigTitle, StyledButtonSubmit, StyledDivTitle} from "../../../../shared/styled";
import {BNPMultiSelect, MultiSelectGeneralOptions} from "../../../../../cores/helpers/input/BNPMultiSelect";
import BNPDatePicker from "../../../../../cores/helpers/date-picker/date-picker";
import {HorizontalMenu} from "../../../../shared/horizontal-menu/HorizontalMenu";
import BNPRawTableWithSubTotal from "../../../../shared/BNPTable/BNPRawTableWithSubTotal";
import {TableSortType} from "../../../../shared/BNPTable/BNPTableType";
import Pagination, {setPagingInfo} from "../../../../../cores/helpers/pagination/pagination";
import {
  getPartnerCommissionEnrollmentOptions,
  getReferralPartnerUserOptions
} from "../../../../../services/partner-service";
import {getInfoByToken} from "../../../../../cores/utils/helpers";
import {addLoading, removeLoading} from "../../../../../cores/utils/loading";
import {
  commissionMonthlyTableColumn,
  commissionTableColumn,
  commissionYearlyTableColumn
} from "./CommissionReportModel";
import {
  exportPartnerCommissionReport,
  exportPartnerMemberCommissionReport,
  getPartnerCommissionBalances,
  getPartnerCommissionReport,
  getPartnerMemberCommissionReport,
  getPartnerCommissionReportTotal,
  getPartnerMemberCommissionReportTotal,
  PartnerCommissionReportRequestType,
  PartnerCommissionReportTotalRequestType,
  ExportPartnerCommissionReportType,
} from "../../../../../services/partner-wallet-service";
import theme from "../../../../../cores/helpers/theme";
import {formatterUSD} from "../../../../../cores/helpers/format-usd";
import {centToUsd} from "../../../../../cores/helpers/cent-to-usd";
import styled, {css} from "styled-components";
import {exportExcel} from "../../../../../cores/helpers/export-file/export-file";
import { UserInfo } from "../../../../../models/login/login-req-model";
import { ROLE_PARTNER_MEMBER } from "../../../../../cores/constants/string-constraint";

const StyledTitleBalance = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 29px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledBalance = styled.div`
  font-size: 26px;
  font-weight: bold;
  line-height: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
    ${(props: { color: string }) =>
    props.color &&
    css`
        color: ${props.color};
      `};
`;

type Props = {};

type CommissionReportSearchParamType = {
  teamMember: (number | string)[];
  period: number;
  reportingType: "SUMMARY" | "MONTH" | "YEAR";
  enrollmentId: number | null;
  fromDate: string;
  toDate: string;
  page: number;
  perPage: number;
};

const periodOptions: Option[] = [
  { id: 1, name: "This Month" },
  { id: 2, name: "Last Month" },
  { id: 3, name: "Quarter To Date" },
  { id: 4, name: "Year To Date" },
  { id: 5, name: "Custom Period" },
];

const reportTypeOptions: Option[] = [
  { id: "SUMMARY", name: "Summary" },
  { id: "MONTH", name: "Month" },
  { id: "YEAR", name: "Year" },
];

export default function CommissionReport(props: Props) {
  const tokenInfo: UserInfo = getInfoByToken();
  const {mainPartnerId: partnerId, partnerId: partnerUserId, authorities, firstName, lastName } = tokenInfo;
  const isTeamMember = authorities.includes(ROLE_PARTNER_MEMBER);
  const {fromDate: defaultFromDate, toDate: defaultToDate} = calculatePeriod(1);
  const [searchParam, setSearchParam] = useState<CommissionReportSearchParamType>({
    teamMember: [],
    period: 1,
    reportingType: "SUMMARY",
    enrollmentId: -1,
    fromDate: defaultFromDate,
    toDate: defaultToDate,
    page: 1,
    perPage: 10,
  });
  const [teamMemberOptions, setTeamMemberOptions] = useState<Option[]>([]);
  const [activeTabOption, setActiveTabOption] = useState<Option>(MultiSelectGeneralOptions.ALL);
  const [productTypeOptions, setProductTypeOptions] = useState<Option[]>([]);
  const [commissionSubTotal, setCommissionSubTotal] = useState<any>({
    companyName: "Total",
    commissionEarn: 0,
    clawBack: 0,
    netCommissionEarn: 0,
  });
  const [columns, setColumns] = useState<TableSortType[]>(commissionTableColumn);
  const [paging, setPaging] = useState<any>(setPagingInfo(1, 1, 10))
  const [data, setData] = useState<any[]>([]);
  const [commissionBalances, setCommissionBalances] = useState({
    commissionPending: 0,
    clawbackPending: 0,
  });
  const [horizontalMenuItems, setHorizontalMenuItems] = useState<Option[]>([]);

  const {
    fetchTableData,
    fetchSubTotalData,
    exportCommissionReport,
  } = isTeamMember ? {
    fetchTableData: getPartnerMemberCommissionReport,
    fetchSubTotalData: getPartnerMemberCommissionReportTotal,
    exportCommissionReport: exportPartnerMemberCommissionReport,
  } : {
    fetchTableData: getPartnerCommissionReport,
    fetchSubTotalData: getPartnerCommissionReportTotal,
    exportCommissionReport: exportPartnerCommissionReport,
  };

  useEffect(() => {
    addLoading();
    if (isTeamMember) {
      Promise.all([
        getTeamMemberOptions(),
        getProductTypeOptions(),
      ]).then(() => removeLoading())
    } else {
      Promise.all([
        getTeamMemberOptions(),
        getProductTypeOptions(),
        loadPartnerCommissionBalances(),
      ]).then(() => removeLoading())
    }
   }, []);

  useEffect(() => {
    addLoading()
    getSubTotalData().then(
        () => removeLoading()
    );
  }, [activeTabOption, searchParam.teamMember, searchParam.enrollmentId, searchParam.reportingType,
    searchParam.fromDate, searchParam.toDate]);

  useEffect(() => {
    if (activeTabOption.id !== null) {
      addLoading()
      getTableData().then(
          () => removeLoading()
      );
    }
  }, [activeTabOption, searchParam.teamMember, searchParam.enrollmentId, searchParam.reportingType,
    searchParam.fromDate, searchParam.toDate, searchParam.page, searchParam.perPage]);

  async function getTeamMemberOptions() {
    if (isTeamMember) {
      const teamMemberOption = {id: partnerUserId, name: `${firstName} ${lastName}`};
      setTeamMemberOptions([teamMemberOption]);
      setSearchParam({...searchParam, teamMember: [partnerUserId]});
      setActiveTabOption(teamMemberOption);
    } else {
      const res = await getReferralPartnerUserOptions(partnerId);
      setTeamMemberOptions([MultiSelectGeneralOptions.ALL, ...res.data]);
      setHorizontalMenuItems([MultiSelectGeneralOptions.ALL, ...res.data]);
      setSearchParam({...searchParam, teamMember: [MultiSelectGeneralOptions.ALL.id, ...res.data.map((item: any) => item.id)]});
    }
  }

  async function getProductTypeOptions() {
    const res = await getPartnerCommissionEnrollmentOptions(partnerId);
    setProductTypeOptions(res.data);
  }

  async function loadPartnerCommissionBalances () {
    const res = await getPartnerCommissionBalances(partnerId);
    setCommissionBalances(res.data);
  }

  async function getSubTotalData() {

    if (searchParam.teamMember.length === 0) {
      return;
    }

    const model: PartnerCommissionReportTotalRequestType = {
      partnerUserIds: getSubmitPartnerUserIds(),
      fromDate: searchParam.fromDate,
      toDate: searchParam.toDate,
      enrollmentId: !!searchParam.enrollmentId && searchParam.enrollmentId <= 0 ? null : searchParam.enrollmentId,
    }
    const res = await fetchSubTotalData(partnerId, model);

    setCommissionSubTotal({
      ...res.data,
      companyName: "TOTAL",
      commissionEarn: res.data.commissionEarn || 0,
      clawBack: res.data.clawBack || 0,
      netCommissionEarn: res.data.netCommissionEarn || 0,
    });
  }

  async function getTableData() {

    if (searchParam.teamMember.length === 0) {
      setData([]);
      return;
    }

    const sortCondition = getSortCondition(searchParam.reportingType);
    const model: PartnerCommissionReportRequestType = {
      partnerUserIds: getSubmitPartnerUserIds(),
      fromDate: searchParam.fromDate,
      toDate: searchParam.toDate,
      enrollmentId: !!searchParam.enrollmentId && searchParam.enrollmentId <= 0 ? null : searchParam.enrollmentId,
      reportingType: searchParam.reportingType,
      page: searchParam.page,
      perPage: searchParam.perPage,
      sortType: sortCondition.sortType,
      columnName: sortCondition.columnName,
    }
    const res = await fetchTableData(partnerId, model);
    setData(res.data.records);
    setPaging(setPagingInfo(searchParam.page, res.data.pageCount, searchParam.perPage));
  }
  
  function getSubmitPartnerUserIds(): number[] | null {
    let partnerUserIds;

    if (activeTabOption.id === MultiSelectGeneralOptions.ALL.id) {
      partnerUserIds = null;
    } else if (activeTabOption.id === MultiSelectGeneralOptions.ALL_SELECTED.id) {
      partnerUserIds = searchParam.teamMember.filter(
        item => item !== MultiSelectGeneralOptions.ALL_SELECTED.id && Number(item) > 0).map((item) => Number(item));
    } else {
      partnerUserIds = [Number(activeTabOption.id)];
    }

    return partnerUserIds;
  }

  function getSortCondition(reportingType: "SUMMARY" | "MONTH" | "YEAR"):  {
    columnName?: string;
    sortType?: 'ASC' | 'DESC';
  } {
    if (reportingType === "MONTH") {
      return {
        columnName: "ledgerCreatedDateMonthYear",
        sortType: "ASC",
      }
    } else if (reportingType === "YEAR") {
      return {
        columnName: "ledgerCreatedDateYear",
        sortType: "ASC",
      }
    } else {
      return {

      }
    }
  }

  function handleChangeTeamMember(event: any) {
    let { value } = event.target;
    value = value.filter((item: any) => item !== MultiSelectGeneralOptions.ALL_SELECTED.id);
    if (value.includes(MultiSelectGeneralOptions.ALL.id)) {
      value = teamMemberOptions.map((item) => item.id);
    } else if (value.length > 1) {
      value = [MultiSelectGeneralOptions.ALL_SELECTED.id, ...value];
    }

    setSearchParam(prevState => {
      if (prevState.teamMember.includes(MultiSelectGeneralOptions.ALL.id) && !value.includes(MultiSelectGeneralOptions.ALL.id)) {
        value = [];
      }
      return {...prevState, teamMember: value};
    });
    setHorizontalMenuItems(getHorizontalMenuItems(value));
    setActiveTabOption({id: value.length > 0 ? value[0] : null, name: ""})
    if(value.length === 0) {
      setData([]);
    }
  }

  function getHorizontalMenuItems(searchParamPartnerIds: (number | string)[]) {
    if (searchParamPartnerIds.includes(MultiSelectGeneralOptions.ALL.id)) { // All selected
      return [...teamMemberOptions];
    } else if (!searchParamPartnerIds.includes(MultiSelectGeneralOptions.ALL.id) &&
     searchParamPartnerIds.length > 1) { // All selected
      return [
        MultiSelectGeneralOptions.ALL_SELECTED,
         ...teamMemberOptions.filter((item) => searchParamPartnerIds.includes(Number(item.id)))
      ]
    }
    return teamMemberOptions.filter((item) => searchParamPartnerIds.includes(Number(item.id)));
  }

  function handleChangeReportingPeriod(event: any) {
    let { value } = event.target;

    const {fromDate, toDate} = calculatePeriod(value);

    setSearchParam({
      ...searchParam,
      period: value,
      fromDate: fromDate,
      toDate: toDate,
    });
  }

  function handleChangeReportingType(event: any) {
    let {name, value} = event.target;

    if (value <=0 ) value = "SUMMARY";

    if (value === "MONTH") {
      setColumns(commissionMonthlyTableColumn);
    } else if (value === "YEAR") {
      setColumns(commissionYearlyTableColumn);
    } else {
      setColumns(commissionTableColumn);
    }

    setSearchParam({
      ...searchParam,
      [name]: value,
    });
  }

  function handleChangeProductType(event: any) {
    let { value } = event.target;

    setSearchParam({...searchParam, enrollmentId: value});
  }

  function handleChangeDate(date: Date | null, name: string) {
    let temp: any = { ...searchParam };

    temp[name] = date ? moment(date).toISOString(true) : null;

    validateAndSetValue(temp);
  }

  function validateAndSetValue(temp: any) {
    if (temp.from && temp.to && temp.from > temp.to) {
      temp.to = temp.from;
    }

    setSearchParam(temp);
  }

  async function handleExportExcel() {
    const model: ExportPartnerCommissionReportType = {
      partnerUserIds: getSubmitPartnerUserIds(),
      fromDate: searchParam.fromDate,
      toDate: searchParam.toDate,
      enrollmentId: !!searchParam.enrollmentId && searchParam.enrollmentId <= 0 ? null : searchParam.enrollmentId,
      reportingType: searchParam.reportingType,
    }
    addLoading();
    const res = await exportCommissionReport(partnerId, model);
    if (res.status === 200) {
      exportExcel(res.data)
    }
    removeLoading();
  }

  function handleChangeActiveTab(item: Option) {
    setActiveTabOption(item);
  }

  function handleChangePage(page: number) {
    setSearchParam({...searchParam, page});
  }

  function handleChangePerPage(event: any) {
    const { value } = event.target;
    setSearchParam({...searchParam, perPage: value, page: 1});
  }

  function calculatePeriod(periodId: number) {
    let fromLocal, toLocal: Date;

    switch (periodId) {
      case 2: //Last month
        fromLocal = moment().month(moment().month()-1).startOf('month').toDate();
        toLocal = moment().month(moment().month()-1).endOf('month').toDate();
        break;
      case 3: //Quarter
        fromLocal = moment().quarter(moment().quarter()).startOf('quarter').toDate();
        toLocal = moment().quarter(moment().quarter()).endOf('quarter').toDate();
        break;
      case 4: //Year
        fromLocal = moment().year(moment().year()).startOf('year').toDate();
        toLocal = moment().year(moment().year()).endOf('year').toDate();
        break;
      default: //Default this month
        fromLocal = moment().month(moment().month()).startOf('month').toDate();
        toLocal = moment().month(moment().month()).endOf('month').toDate();
        break;
    }

    return {
      fromDate: fromLocal && moment(fromLocal).toISOString(true),
      toDate: toLocal && moment(toLocal).toISOString(true),
    };
  }

  return (
    <>
      <div className="row pt-3 d-flex">
        <div className="col-lg-3 col-12">
          <StyledBigTitle>Commission Report</StyledBigTitle>
        </div>
        {!isTeamMember && (<><div className="col-lg-2">
          <StyledTitleBalance title="Balance in Account">
            Commission Pending
          </StyledTitleBalance>
          <StyledBalance
              color={theme.primaryColor}
              title={formatterUSD("currency", "USD").format(
                  centToUsd(commissionBalances.commissionPending)
              )}
          >
            {formatterUSD("currency", "USD").format(
                centToUsd(commissionBalances.commissionPending)
            )}
          </StyledBalance>
        </div>
        <div className="col-lg-2">
          <StyledTitleBalance title="Top Up Pending">
            Clawback Pending
          </StyledTitleBalance>
          <StyledBalance
              color={theme.primaryColor}
              title={formatterUSD("currency", "USD").format(
                  centToUsd(commissionBalances.clawbackPending)
              )}
          >
            {formatterUSD("currency", "USD").format(
                centToUsd(commissionBalances.clawbackPending)
            )}
          </StyledBalance>
        </div>
        <div className="col-lg-2">
          <StyledTitleBalance title="Claim Pending">
            Net Commission Earned
          </StyledTitleBalance>
          <StyledBalance
              color={theme.secondaryColor}
              title={formatterUSD("currency", "USD").format(
                  centToUsd(
                      commissionBalances.commissionPending - commissionBalances.clawbackPending
                  )
              )}
          >
            {formatterUSD("currency", "USD").format(
                centToUsd(
                    commissionBalances.commissionPending - commissionBalances.clawbackPending
                )
            )}
          </StyledBalance>
        </div></>)}
      </div>
      <div className="row pt-5">
        <div className="col-lg-2 col-12">
          <StyledDivTitle>Team Member</StyledDivTitle>
          <BNPMultiSelect
              name={"teamMember"}
              value={searchParam.teamMember}
              options={teamMemberOptions}
              placeholder={"Please select member"}
              onChange={handleChangeTeamMember}
              disabled={isTeamMember}
              allowAll={true}
          />
        </div>
        <div className="col-lg-2 col-12">
          <StyledDivTitle>Reporting Period</StyledDivTitle>
          <BNPSelect
              name={"period"}
              value={searchParam.period}
              options={periodOptions}
              placeholder={"All"}
              onChange={handleChangeReportingPeriod}
          />
        </div>
        <div className="col-lg-2 col-12">
          <StyledDivTitle>Reporting Type</StyledDivTitle>
          <BNPSelect
              name={"reportingType"}
              value={searchParam.reportingType}
              options={reportTypeOptions}
              placeholder={"Reporting Type"}
              onChange={handleChangeReportingType}
          />
        </div>
        <div className="col-lg-2 col-12">
          <StyledDivTitle>Product Type</StyledDivTitle>
          <BNPSelect
              name={"enrollmentId"}
              value={searchParam.enrollmentId}
              options={productTypeOptions}
              placeholder={"All Product"}
              onChange={handleChangeProductType}
          />
        </div>
        <div className="col-lg-2 col-12"/>
        <div className="col-lg-2 col-12">
          <StyledDivTitle style={{height: "26px"}}></StyledDivTitle>
          <StyledButtonSubmit
              submit={true}
              onClick={handleExportExcel}
          >
            <DownloadIcon className="icon" style={{marginRight: "5px"}}/>
            Export To Excel
          </StyledButtonSubmit>
        </div>
      </div>
      {searchParam.period === 5 && (<div className="row pt-3">
        <div className="col-lg-2 col-12">
          <StyledDivTitle>From</StyledDivTitle>
          <BNPDatePicker
            value={searchParam.fromDate}
            onChange={(date) => handleChangeDate(date, "fromDate")}
            name="fromDate"
            maxDate={new Date()}
          />
        </div>
        <div className="col-lg-2 col-12">
          <StyledDivTitle>To</StyledDivTitle>
          <BNPDatePicker
            value={searchParam.toDate}
            onChange={(date) => handleChangeDate(date, "toDate")}
            name="toDate"
          />
        </div>
      </div>)}
      <div className="row pt-5">
        <HorizontalMenu
          items={horizontalMenuItems}
          onClick={handleChangeActiveTab}
          activeItem={activeTabOption}
        />
      </div>

      {activeTabOption.id !== null && (
        <>
          <BNPRawTableWithSubTotal
            columns={columns}
            tableData={data}
            tableSubTotal={commissionSubTotal}
            tableWidth='100%'
          />
          <div className="row mt-4">
            <div className="col-12 text-center">
              <label>
                <Pagination
                  pagerPagination={paging}
                  getPage={handleChangePage}
                  onChangeRowsPerPage={handleChangePerPage}
                />
              </label>
            </div>
          </div>
        </>
      )}
    </>
  );
}

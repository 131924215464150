import React from "react";
import {
  StyledHeaderModal,
  StyledHeaderTitleModal,
  StyledIconCloseModal,
  StyledButtonCloseModal,
  StyledButtonSubmit,
  StyledDivTitle,
  StyledPError,
} from "../../../../shared/styled";
import { BNPInput } from "../../../../../cores/helpers/input/input";
import { Adjustment } from "../../company-management-type";

type Props = {
  adjustment: Adjustment;
  handleChangeInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (e: any) => void;
};
export default function EmployerCreditView(props: Props) {
  const checkNegativeCredit = () => {
    if (props.adjustment.credit < 0) {
      return true;
    }
    return false;
  };

  const checkNegativeDebit = () => {
    if (props.adjustment.debit < 0) {
      return true;
    }
    return false;
  };

  const isSubmit =
    (props.adjustment.credit > 0 && props.adjustment.debit > -1) ||
    (props.adjustment.debit > 0 && props.adjustment.credit > -1)
      ? true
      : false;

  return (
    <div className="modal" id="creditModal" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <StyledButtonCloseModal name="btn-close" onClick={closeCreditModal}>
              <StyledIconCloseModal>&times;</StyledIconCloseModal>
            </StyledButtonCloseModal>
            <StyledHeaderModal>
              <StyledHeaderTitleModal>Adjustment</StyledHeaderTitleModal>
            </StyledHeaderModal>
            <div className="row">
              <div className="col-12">
                <StyledDivTitle>Credit</StyledDivTitle>
                <BNPInput
                  type="number"
                  className="col-12 mt-2"
                  onChange={props.handleChangeInput}
                  name="credit"
                  placeholder="Credit"
                  value={props.adjustment.credit || ""}
                />
                {checkNegativeCredit() ? (
                  <StyledPError>
                    Please do not enter a negative number
                  </StyledPError>
                ) : (
                  ""
                )}
              </div>

              <div className="col-12 mt-3">
                <StyledDivTitle>Debit</StyledDivTitle>
                <BNPInput
                  type="number"
                  className="col-12 mt-2"
                  onChange={props.handleChangeInput}
                  name="debit"
                  placeholder="Debit"
                  value={props.adjustment.debit || ""}
                />
                {checkNegativeDebit() ? (
                  <StyledPError>
                    Please do not enter a negative number
                  </StyledPError>
                ) : (
                  ""
                )}
              </div>
              <div className="col-12 mt-3">
                <StyledDivTitle>Note</StyledDivTitle>
                <BNPInput
                    maxLength={255}
                    className="col-12 mt-2"
                    onChange={props.handleChangeInput}
                    name="note"
                    placeholder="Note"
                    value={props.adjustment.note || ""}
                />
              </div>
            </div>
            <div className="row mt-4">
              <StyledButtonSubmit
                className="col-2 ml-auto"
                onClick={props.handleSubmit}
                submit={isSubmit}
                disabled={!isSubmit}
                type="submit"
              >
                Save
              </StyledButtonSubmit>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export function showCreditModal() {
  ($("#creditModal") as any).modal({
    show: true,
    keyboard: false,
    backdrop: "static",
  });
}
export function closeCreditModal() {
  ($("#creditModal") as any).modal("hide");
}

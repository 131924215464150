import React, {useMemo} from 'react';
import styled from "styled-components";
import CreateIcon from "@material-ui/icons/Create";
import {StyledCell, StyledContentBox, StyledSpanLargerTitle} from "../styled";
import {StyledSpanEditInfo} from "../../pages/company-management/detail/company-management-detail-view";

const StyledLine = styled.div`
  padding: 5px;
  display: flex;
`;

type Props = {
  data: any;
  model: {title: any, param: string, getValue: (v: any) => any}[];
  title: string;
  widthTitle?: string;
  widthParam?: string;
  showEditModal: () => void;
};

export const DetailInformation = React.memo((props: Props) => {

  const data = useMemo(() => {
    return props.model.map((item) => ({
      title: item.title,
      value: item.getValue(item.param ? props.data[item.param] : props.data)
    }))
  }, [props.model, props.data])

  return (
    <StyledContentBox>
      <StyledSpanLargerTitle>
        {props.title}
        <StyledSpanEditInfo onClick={props.showEditModal}>
          <CreateIcon fontSize="small" /> Edit
        </StyledSpanEditInfo>
      </StyledSpanLargerTitle>

      <div className="mt-3">
        {data.map((item, idx) =>
          <StyledLine className={idx % 2 === 0 ? "striped" : ""}>
            <StyledCell width={props.widthTitle || "30%"} className="fw-bold">
              {item.title}
            </StyledCell>
            <StyledCell width={props.widthParam || "70%"}>
              {item.value}
            </StyledCell>
          </StyledLine>
        )}
      </div>
    </StyledContentBox>
  );
});
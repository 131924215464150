import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {
    StyledButtonCloseModal,
    StyledButtonSubmit,
    StyledHeaderModal,
    StyledHeaderTitleModal,
    StyledIconCloseModal,
} from "../../../shared/styled";
import {
    Class,
    ClassBenefit,
    defaultClassBenefit,
    defaultDefineClassBenefit,
    defaultRequestAQuote,
    EmployerClassBenefit,
    RequestAQuoteType
} from "./request-a-quote-type";
import {formatValueInputMask} from "../../../../cores/helpers/format-phone-number";
import {validateEmail} from "../../../../cores/config/config";
import {usdToCent} from "../../../../cores/helpers/cent-to-usd";
import {postCreateAQuote, postGenerateQuote} from "../../../../services/quote-service";
import {addLoading, removeLoading} from "../../../../cores/utils/loading";
import {getClasses, getMedicalAndTravelCoverageDefault} from "../../../../services/company-service";
import {
    MedicalAndTravel,
    MedicalTravelData
} from "../../../../cores/helpers/medical-and-travel-coverage/medical-and-travel-coverage-type";
import {QuoteModalViewPersonalInformation} from "./QuoteModalViewPersonalInformation";
import {QuoteModalViewClassBenefit} from "./QuoteModalViewClassBenefit";
import {publishMessage} from "../../../../cores/utils/message";
import {download} from "../../../../cores/utils/helpers";

const StyledModalDialog = styled.div`
  max-width: 800px;
  margin: 1.75rem auto;
  position: relative;
  width: auto;
  pointer-events: none;
`;

const StyledSpanErrorMessage = styled.span`
  color: ${(props) => props.theme.errorColor};
  font-size: 14px;
`;

type Props = {
    reloadPage: () => void;
};

export default function CreateQuoteModal(props: Props) {
    const [requestAQuote, setRequestAQuote] = useState<RequestAQuoteType>(defaultRequestAQuote);
    const [classes, setClasses] = useState<Class[]>([]);
    const [currentClassBenefit, setCurrentClassBenefit] = useState<ClassBenefit>({
        classId: 0,
        planId: 0,
        coverageAmount: undefined,
        noOfEmployees: undefined,
        className: "",
    } as ClassBenefit);
    const [employerClassBenefits, setEmployerClassBenefits] = useState<EmployerClassBenefit>(
      JSON.parse(JSON.stringify(defaultClassBenefit)));
    const [isValid, setIsValid] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [page, setPage] = useState<number>(0);
    const [isEmailRegistered, setIsEmailRegistered] = useState<boolean>(false);
    const [messageEmail, setMessageEmail] = useState<string>("");
    const [isOtherClass, setIsOtherClass] = useState<boolean>(false);
    const [isValidClassBenefit, setIsValidClassBenefit] = useState<boolean>(false);
    const [haveMedicalTravel, setHaveMedicalTravel] = useState<boolean>(false);
    const [medicalTravels, setMedicalTravels] = useState<MedicalAndTravel[]>([]);

    useEffect(() => {
        fetchData().then();
    }, []);

    const fetchData = async () => {
        addLoading();

        const [classesResponse, medicalTravelsResponse] = await Promise.all([
            getClasses(),
            getMedicalAndTravelCoverageDefault(),
        ]);
        setClasses(classesResponse.data || []);
        setMedicalTravels(medicalTravelsResponse.data || [])

        removeLoading();
    }

    const handleSubmit = async () => {
        if (page === 0) {
            await handleSubmitPersonalInformationData();
        } else {
            await handleSubmitClassBenefits();
        }
    }
    const handleSubmitPersonalInformationData = async () => {
        addLoading();
        const res = await postCreateAQuote(requestAQuote);
        setEmployerClassBenefits(Object.assign(employerClassBenefits, {employerId: res.data.employerId}));
        initCurrentClassBenefit();
        setPage(1);
        setIsValid(false);
        removeLoading();
    }

    const handleSubmitClassBenefits = async () => {
        addLoading();
        let employerClassBenefitsTemp: EmployerClassBenefit = Object.assign({}, employerClassBenefits);

        employerClassBenefitsTemp.classBenefits.forEach((f) => {
            f.coverageAmount = usdToCent(f.coverageAmount || 0);
            if (f.className !== "") {
                f.classId = 0;
            }
        });

        const res = await postGenerateQuote(employerClassBenefitsTemp);

        if (res.status !== 201 || res.data.message) {
            publishMessage({variant: "error", message: res.data.message});
            setPage(0);
        } else {
            download(res.data.url);
            props.reloadPage();
        }
        handleCloseCreateQuoteModal()
        removeLoading()
    };

    const handleCloseCreateQuoteModal = () => {
        setRequestAQuote(defaultRequestAQuote);
        setEmployerClassBenefits(JSON.parse(JSON.stringify(defaultClassBenefit)));
        setPage(0);
        setErrorMessage("");
        setIsValid(false);
        setIsValidClassBenefit(false);
        setIsOtherClass(false);
        setHaveMedicalTravel(false);
        closeCreateQuoteModal();
    }

    const handleChangeInputPersonalInformation = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, checked } = event.target;
        let requestAQuoteTemp: RequestAQuoteType = Object.assign({}, requestAQuote);

        if (name === "soleProprietor") {
            requestAQuoteTemp[name] = checked;
        } else if (name === "companyPhoneNumber") {
            requestAQuoteTemp[name] = formatValueInputMask(value);
        } else if (name === "noOfEmployees") {
            requestAQuoteTemp.noOfEmployees = Number(value);
        } else {
            requestAQuoteTemp[name] = value;
        }
        if (name === "companyEmail") {
            if (!requestAQuoteTemp.companyEmail) requestAQuoteTemp.companyEmail = "";
            if (!requestAQuoteTemp.companyEmail.match(validateEmail)) {
                setIsEmailRegistered(false);
                setMessageEmail("Email is not valid.");
            } else {
                setIsEmailRegistered(true);
            }
        }
        setRequestAQuote(requestAQuoteTemp);
        setIsValid(validateRequestAQuote(requestAQuoteTemp));
    }

    const handleChangeTextAreaPersonalInformation = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        let requestAQuoteTemp = Object.assign({}, requestAQuote);
        requestAQuoteTemp[event.target.name] = event.target.value;
        setRequestAQuote(requestAQuoteTemp);
    }

    const handleChangeSelectClassBenefits = (event: any) => {
        const { name, value } = event.target;
        let defineClassBenefit: ClassBenefit = Object.assign({}, currentClassBenefit);
        defineClassBenefit[name] = value;
        defineClassBenefit["className"] = "";
        setCurrentClassBenefit(defineClassBenefit);
        setIsValidClassBenefit(validateCurrentClassBenefit(isOtherClass, defineClassBenefit));
    }

    const handleChangeInputDefineClassBenefits = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        let defineClassBenefit: ClassBenefit = Object.assign({}, currentClassBenefit);
        const { name, value } = event.target;
        if (name === "coverageAmount") {
            defineClassBenefit.coverageAmount = Number(value);
        } else if (name === "className") {
            defineClassBenefit.className = value;
        } else {
            defineClassBenefit.noOfEmployees = Number(value);
        }

        setCurrentClassBenefit(defineClassBenefit);
        setIsValidClassBenefit(validateCurrentClassBenefit(isOtherClass, defineClassBenefit));
    }

    const getRemainingClasses = () => {
        let selectClasses = employerClassBenefits.classBenefits.map(
            (classBenefit) => classBenefit.classId
        );
        return classes.filter((classItem) =>
            selectClasses.indexOf(classItem.id) === -1
        );
    };

    const addClassBenefit = () => {
        let defineClassBenefits: EmployerClassBenefit = Object.assign({}, employerClassBenefits);
        if (isOtherClass) {
            const cls: Class = {
                id: classes.length + 1,
                name: currentClassBenefit.className,
            };
            setCurrentClassBenefit(Object.assign(currentClassBenefit, {
                classId: cls.id,
            }))
            const newClasses: Class[] = [...classes];
            newClasses.push(cls);
            setClasses(newClasses);
        }
        //push class benefit to data
        defineClassBenefits.classBenefits.push(currentClassBenefit);

        setEmployerClassBenefits(defineClassBenefits);
        setIsOtherClass(false);

        setIsValid(validateEmployerClassBenefits());
        initCurrentClassBenefit();
    };

    const initCurrentClassBenefit = () => {
        const remainingClasses = getRemainingClasses();
        const firstClassId = remainingClasses.length ? remainingClasses[0].id : 0;

        setCurrentClassBenefit({...defaultDefineClassBenefit, classId: firstClassId});
        setIsValidClassBenefit(false);
    }

    const handleChangeCheckbox = (event: any): void => {
        const { checked } = event.target;
        let classBenefit: ClassBenefit = Object.assign({}, currentClassBenefit);
        if (!checked) {
            let remainingClasses = getRemainingClasses();
            classBenefit.classId = remainingClasses.length ? remainingClasses[0].id : 0;
            classBenefit.className = "";
            setCurrentClassBenefit(classBenefit);
        }
        setIsValidClassBenefit(validateCurrentClassBenefit(checked, classBenefit));
        setIsOtherClass(checked)
    };

    const deleteClass = (index: number) => {
        let employerClassBenefitsTemp: EmployerClassBenefit = Object.assign({}, employerClassBenefits);
        employerClassBenefitsTemp.classBenefits.splice(index, 1);

        setEmployerClassBenefits(employerClassBenefitsTemp);

        setIsValid(validateEmployerClassBenefits());
        initCurrentClassBenefit();
    };

    const findClassById = (classId: number): { label: string; value: number } => {
        let valueSelect = { label: "", value: 0 };

        let classItem = classes.find((classBenefit: Class) => {
            return classBenefit.id === classId;
        });
        if (!classItem) {
            return {
                label: "",
                value: 0,
            };
        }
        valueSelect.label = classItem.name;
        valueSelect.value = classItem.id;

        return valueSelect;
    };

    const validateRequestAQuote = (quote: RequestAQuoteType = requestAQuote): boolean => {
        return !!(quote.firstName && quote.lastName &&
          (!!quote.companyEmail && quote.companyEmail.match(validateEmail)) &&
          quote.companyName && quote.noOfEmployees);
    };

    const validateCurrentClassBenefit = (isOther: boolean, classBenefit = currentClassBenefit): boolean => {
        if (
          isOther &&
          classBenefit.coverageAmount &&
          classBenefit.noOfEmployees &&
          classBenefit.className !== ""
        ) {
            return true;
        }

        return !!(classBenefit.coverageAmount &&
          classBenefit.noOfEmployees &&
          classBenefit.classId !== 0 &&
          !isOther);
    };

    const onChangeAdminFee = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        let employerClassBenefitsTemp: any = Object.assign({}, employerClassBenefits);
        employerClassBenefitsTemp.adminFee = Number(value);
        setEmployerClassBenefits(employerClassBenefitsTemp);
    }

    const validateEmployerClassBenefits = (): boolean => {
        return employerClassBenefits.employerId !== 0 &&
            employerClassBenefits.classBenefits.length > 0 &&
            (!!employerClassBenefits.adminFee && employerClassBenefits.adminFee >= 0);
    }

    const onSwitchChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        checked: boolean
    ) => {
        setHaveMedicalTravel(checked);
    };

    const onInputChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        option: string
    ) => {
        // get name and value input
        const { name, value } = event.target;

        let employerClassBenefitsTemp: any = Object.assign({}, employerClassBenefits);

        // cut name pick category and name change value

        // find index medicalTravel
        let index = employerClassBenefitsTemp.medicalTravels.findIndex(
            (medicalTravel: any) => {
                return medicalTravel.category === option;
            }
        );

        // set value
        employerClassBenefitsTemp.medicalTravels[index][name] = Number(value);

        setEmployerClassBenefits(employerClassBenefitsTemp);
    };

    const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;

        let employerClassBenefitsTemp: any = Object.assign({}, employerClassBenefits);

        if (checked) {
            // set default medical travel
            let medicalTravel = {
                category: name,
                noOfEmployees: 0,
                amount: 0,
            };
            // push
            employerClassBenefitsTemp.medicalTravels.push(medicalTravel);
        } else {
            // find index medical travel
            let index = employerClassBenefitsTemp.medicalTravels.findIndex((medicalTravel: any) => {
                return medicalTravel.category === name;
            });

            // if have medical travel then remove
            if (index > -1) {
                employerClassBenefitsTemp.medicalTravels.splice(index, 1);
            }
        }
        setIsValid(validateMedicalTravel(employerClassBenefitsTemp.medicalTravels));
        setEmployerClassBenefits(employerClassBenefitsTemp);
    };

    const validateMedicalTravel = (medicalTravelData: MedicalTravelData[]) => {
        return medicalTravelData.some((medicalTravel) => !medicalTravel.noOfEmployees);
    };

    return (
        <div className="modal text-left" id="createQuoteModal">
            <StyledModalDialog role="document">
                <div className="modal-content">
                    <StyledHeaderModal className="modal-header">
                        <StyledHeaderTitleModal>
                            Create A Quote
                        </StyledHeaderTitleModal>
                        <StyledButtonCloseModal
                            type="button"
                            onClick={handleCloseCreateQuoteModal}
                        >
                            <StyledIconCloseModal>&times;</StyledIconCloseModal>
                        </StyledButtonCloseModal>
                    </StyledHeaderModal>
                    <div className="modal-body">
                        <div>
                            {page === 0 &&
                                <QuoteModalViewPersonalInformation
                                    requestAQuote={requestAQuote}
                                    handleChangeInput={handleChangeInputPersonalInformation}
                                    handleChangeTextArea={handleChangeTextAreaPersonalInformation}
                                    messageEmail={messageEmail}
                                    isEmailRegistered={isEmailRegistered}
                                    isValid={isValid}
                                    onSubmit={handleSubmit}
                                />}
                            {page === 1 &&
                                <QuoteModalViewClassBenefit
                                    employerClassBenefits={employerClassBenefits}
                                    defineClassBenefit={currentClassBenefit}
                                    isValidClassBenefit={isValidClassBenefit}
                                    findClassById={findClassById}
                                    deleteClass={deleteClass}
                                    isOtherClass={isOtherClass}
                                    getRemainingClasses={getRemainingClasses}
                                    addClassBenefit={addClassBenefit}
                                    handleChangeSelect={handleChangeSelectClassBenefits}
                                    handleChangeInput={handleChangeInputDefineClassBenefits}
                                    handleChangeCheckbox={handleChangeCheckbox}
                                    isChecked={haveMedicalTravel}
                                    medicalTravels={medicalTravels}
                                    medicalTravelData={employerClassBenefits.medicalTravels}
                                    onSwitchChange={onSwitchChange}
                                    onCheckboxChange={onCheckboxChange}
                                    onInputChange={onInputChange}
                                    onChangeAdminFee={onChangeAdminFee}
                                />
                            }
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="row w-100">
                            <div className="col-md-8 p-0 d-flex align-items-center">
                                <StyledSpanErrorMessage>{errorMessage}</StyledSpanErrorMessage>
                            </div>
                            <div className="col-md-4 p-0">
                                <StyledButtonSubmit
                                    disabled={!isValid}
                                    onClick={handleSubmit}
                                    submit={isValid}
                                    type="submit"
                                    tabIndex={16}
                                >
                                    {page === 1 ? "Submit" : "Continue"}
                                </StyledButtonSubmit>
                            </div>
                        </div>
                    </div>
                </div>
            </StyledModalDialog>
        </div>
    );
}

export function showCreateQuoteModal() {
    ($("#createQuoteModal") as any).modal({
        show: true,
        keyboard: false,
        backdrop: "static",
    });
}
function closeCreateQuoteModal() {
    ($("#createQuoteModal") as any).modal("hide");
}

import {
  getAsync,
  putAsync,
} from "../../../cores/utils/http-client";
import { AxiosResponse } from "axios";
import {
  Criteria
} from "./email-history-model";

const URL_NOTIFICATION = "manage-notification/api/email/history";

const searchEmailHistory = (
  param: Criteria
): Promise<AxiosResponse> => {
  const url = `${URL_NOTIFICATION}${mapQueryString(param)}`

  return getAsync(url);
};

const resend = (id: number): Promise<AxiosResponse> => {
  let url = `${URL_NOTIFICATION}/resend/${id}`;

  return putAsync(url, {});
};

const sendToAdminEmail = (id: number, email: string): Promise<AxiosResponse> => {
	let url = `${URL_NOTIFICATION}/send-to-admin/${id}/${email}`;
  
	return putAsync(url, {});
  };

const mapQueryString = (model: any): string => {
	let temp: any = { ...model }
	let query = "?"

	Object.keys(model).forEach((key) => {
		const val = temp[key]
		if (val) {
			if (Array.isArray(val)) {
				val.forEach((t) => {
					query += `${key}=${t}&`
				})
			} else query += `${key}=${val}&`
		}
	})

	return query.substring(0, query.length - 1)
}

export default {
  searchEmailHistory,
  resend,
  sendToAdminEmail
}
import React, { Component } from "react";
import { Subject, Subscription } from "rxjs";
import styled from "styled-components";

// The Main Subject/Stream to be listened on.
const loadingSubject = new Subject();

type State = {
  isLoading: boolean;
};
export const addLoading = () => {
  loadingSubject.next(true);
};

export const removeLoading = () => {
  loadingSubject.next(false);
};

const LoaderWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: transparent;
  z-index: 9998;
`;

const Loader = styled.div`
  zoom: 0.3;
  border: 16px solid #93acf1;
  border-radius: 50%;
  border-top: 16px solid ${props => props.theme.primaryColor};
  border-bottom: 16px solid ${props => props.theme.primaryColor};
  width: 120px;
  height: 120px;
  animation: spin 0.5s linear infinite;
  display: inline-block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  right: 0;
  z-index: 9999;

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export class Loading extends Component<any, State> {
  // Used for unsubscribing when our component unmounts

  unsubscribe: Subscription;
  state: State = {
    isLoading: false
  };
  constructor(props: any) {
    super(props);

    this.unsubscribe = loadingSubject.subscribe(isLoading => {
      this.setState({ isLoading: isLoading as boolean });
    });
  }
  componentWillUnmount() {
    this.unsubscribe.unsubscribe();
  }

  render() {
    return (
      // display loading icon if isLoading=true
      this.state.isLoading && <LoaderWrapper
        onClick={(e) => {e.preventDefault(); e.stopPropagation()}}
      >
        <Loader />
      </LoaderWrapper>
    );
  }
}

export type Dependent = {
  id?: number;
  firstName: string;
  lastName: string;
  dateOfBirth: Date | string | null;
  relationship: string;
};

export type Relationship = {
  id: string;
  name: string;
};

export const relationships = [
  { id: "WIFE", name: "Wife" },
  { id: "HUSBAND", name: "Husband" },
  { id: "PARTNER", name: "Partner" },
  { id: "CHILDREN", name: "Child" },
  { id: "OTHER", name: "Other" },
];

export const defaultDependent = (): Dependent => {
  return { firstName: "", lastName: "", relationship: "", dateOfBirth: null };
};

import { SortType } from "../../../cores/models/SortTable";

interface Option {
    id: string;
    name: string;
}

export interface DepositWithdrawRes {
    name: string;
    type: DepositWithdrawType;
    amount: number;
    date: Date;
}

export interface SearchCriteria {
    columnNames: string[];
    sortTypes: SortType[];
    searchKey: string;
    type: DepositWithdrawFilterType;
    from: Date | null;
    to: Date | null;
    page: number;
    perPage: number;
}

export const DepositWithdraw: Option[] = [
    {
        id: "ALL",
        name: "ALL",
    },
    {
        id: "DEPOSIT",
        name: "DEPOSIT",
    },
    {
        id: "WITHDRAW",
        name: "WITHDRAW",
    }
];

export enum DepositWithdrawFilterType {
    ALL = "ALL",
    DEPOSIT = "DEPOSIT",
    WITHDRAW = "WITHDRAW"
}

export enum DepositWithdrawType {
    DEPOSIT = "DEPOSIT",
    WITHDRAW = "WITHDRAW"
}
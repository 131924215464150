import React, { useEffect, useState } from "react";
import moment from "moment";
import { StyledButtonSubmit, StyledDivTitle } from "../../../shared/styled";
import BNPDatePicker from "../../../../cores/helpers/date-picker/date-picker";
import BNPRawTable from "../../../shared/BNPTable/BNPRawTable";
import { addLoading, removeLoading } from "../../../../cores/utils/loading";
import { formatterUSD } from "../../../../cores/helpers/format-usd";
import { centToUsd } from "../../../../cores/helpers/cent-to-usd";
import { WalletDetailReportColumn } from "../DashboardModel";
import {getEndDateOfMonth, getStartDateOfMonth} from "../../../../cores/utils/helpers";
import { AxiosResponse } from "axios";
import DownloadIcon from "@material-ui/icons/GetApp";

type Props = {
  getWalletDetailReport: (fromDate: (Date | string), toDate: (Date | string)) => Promise<AxiosResponse>;
  exportWalletDetailReport: (fromDate: (Date | string), toDate: (Date | string)) => void;
};

export function WalletDetailReport(props: Props) {
  const [data, setData] = useState<any[]>([]);
  const [fromDate, setFromDate] = useState<Date>(getStartDateOfMonth(new Date()));
  const [toDate, setToDate] = useState<Date>(getEndDateOfMonth(new Date()));

  useEffect(() => {
    fetchData().then();
  }, [fromDate, toDate]);

  async function fetchData() {
    addLoading();
    const result = await props.getWalletDetailReport(fromDate, toDate)
    setData(processData(result.data));
    removeLoading();
  }

  function processData(data: any[]) {
    return data.map((item) => ({
      month: moment(item.month).format("YYYY-MM"),
      noOfEmployer: item.noOfEmployer,
      noOfEmployee: item.noOfEmployee,
      totalSize: formatterUSD("currency", "USD").format(
        centToUsd(Number(item.totalSize))
      ),
      averageSize: formatterUSD("currency", "USD").format(
        centToUsd(Number(item.averageSize))
      ),
    }));
  }

  function handleChangeDate(date: Date | null, name: string) {
    if (name === "from" && date) {
      setFromDate(getStartDateOfMonth(date));
    }
    if (name === "to" && date) {
      setToDate(getEndDateOfMonth(date));
    }
  }

  async function handleExportExcel() {
    addLoading();
    props.exportWalletDetailReport(fromDate, toDate)
    removeLoading();
  }

  return (
    <>
      <div className="col-12">
        <StyledDivTitle>Time Period</StyledDivTitle>
      </div>
      <div className="col-lg-3 col-md-4 col-12">
        <BNPDatePicker
          views={["month"]}
          format="MM/yyyy"
          value={fromDate}
          onChange={(date) => handleChangeDate(date, "from")}
          name="date"
          disableFuture={true}
        />
      </div>
      <div className="col-lg-3 col-md-4 col-12">
        <BNPDatePicker
          views={["month"]}
          format="MM/yyyy"
          value={toDate}
          onChange={(date) => handleChangeDate(date, "to")}
          name="date"
        />
      </div>
      <div className="col-lg-4 col-12" />
      <div className="col-lg-2 col-12">
        <StyledDivTitle style={{height: "26px"}}></StyledDivTitle>
        <StyledButtonSubmit
          submit={true}
          onClick={handleExportExcel}
        >
          <DownloadIcon className="icon" style={{marginRight: "5px"}}/>
          Export To Excel
        </StyledButtonSubmit>
      </div>
      <div className="col-lg-12 pt-3">
        <BNPRawTable
          columns={WalletDetailReportColumn}
          tableWidth="100%"
          tableData={data}
        />
      </div>
    </>
  );
}

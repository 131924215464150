import React, { Component } from "react";
import {
  relationships,
  Dependent,
  Relationship,
  defaultDependent,
} from "./employee-type";
import { addLoading, removeLoading } from "../../../../cores/utils/loading";
import {getEmployeeInfoById} from "../../../../services/company-service"
import {putDependents} from "../../../../services/employee-service"
import SetupDependentsView from "./setup-dependents-view";
import { toUTCTime } from "../../../../cores/helpers/to-utc-time";

type State = {
  dependents: Dependent[];
  relationships: Relationship[];
  isValid: boolean;
};

export default class SetupDependentsService extends Component<any, State> {
  state: State = {
    dependents: [
      {
        firstName: "",
        lastName: "",
        dateOfBirth: null,
        relationship: "",
      },
    ],
    relationships: relationships,

    isValid: false,
  };

  urlParams = new URLSearchParams(window.location.search);
  employeeId = this.urlParams.get("id");

  async componentDidMount() {
    //add loading
    addLoading();

    await getEmployeeInfoById(Number(this.employeeId)).then((result) => {
      this.setDataWhenReloadPage(result);
    });

    removeLoading();
  }

  setDataWhenReloadPage = (result: any) => {
    let dependents = result.data.dependents.length
      ? result.data.dependents
      : [defaultDependent()];

    this.setState({
      dependents: dependents,
      isValid: false,
    });
  };

  //#region dependent
  handleChangeDependents = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { name, value } = event.target;

    let dependents = Object.assign(this.state.dependents);

    if (value !== "-1") {
      dependents[index][name] = value;
    } else {
      dependents[index][name] = "";
    }

    let isValid = this.validateAddDependents();
    this.setState({ dependents: dependents, isValid: isValid });
  };

  handleChangeDate = (date: Date | null, name: string, index: number) => {
    let dependents: any = [...this.state.dependents];

    dependents[index][name] = date;

    let isValid = this.validateAddDependents();
    this.setState({ dependents: dependents, isValid: isValid });
  };

  addDependents = () => {
    let dependents: Dependent[] = Object.assign(this.state.dependents);

    let defaultDependents = defaultDependent();
    dependents.push(defaultDependents);

    this.setState({
      dependents: dependents,
      isValid: false,
    });
  };

  validateAddDependents = () => {
    let isValid = true;
    this.state.dependents.forEach((dependent) => {
      if (
        !dependent.firstName ||
        !dependent.lastName ||
        // !dependent.dateOfBirth ||
        !dependent.relationship
      ) {
        isValid = false;
      }
    });
    return isValid;
  };

  removeDependents = (index?: number | undefined) => {
    let dependents: Dependent[] = Object.assign(this.state.dependents);
    const dependentIndex = 'number' === typeof index && index > -1 ? index : dependents.length - 1;

    if (dependents.length > 2) {
      dependents.splice(dependentIndex, 1);
      let isValid = this.validateAddDependents();
      this.setState({ dependents: dependents, isValid: isValid });
    } else if (dependents.length > 1) {
      dependents.splice(dependentIndex, 1);

      let isValid = this.validateDependents(dependents[0]);

      this.setState({ dependents: dependents, isValid: isValid });
    } else {
      dependents[0] = defaultDependent();
      this.setState({ dependents: dependents, isValid: true });
    }
  };

  validateDependents = (dependents: Dependent) => {
    if (
      (dependents.firstName &&
        dependents.lastName &&
        // dependents.dateOfBirth &&
        dependents.relationship) ||
      (!dependents.firstName &&
        !dependents.lastName &&
        !dependents.dateOfBirth &&
        !dependents.relationship)
    ) {
      return true;
    } else {
      return false;
    }
  };

  findRelationshipById = (id: string) => {
    let relationship = this.state.relationships.find((relationship) => {
      return relationship.id === id;
    });

    return relationship ? relationship.name : "Other";
  };

  handleSubmitDependents = () => {
    let dependents: Dependent[] = [...this.state.dependents];
    dependents = dependents.map(dependent => {
      if(dependent.dateOfBirth) {
        dependent.dateOfBirth = toUTCTime(new Date(dependent.dateOfBirth));
      }
      return dependent;
    });
    if (
      dependents.length === 1 &&
      !dependents[0].firstName &&
      !dependents[0].lastName &&
      !dependents[0].dateOfBirth &&
      !dependents[0].relationship
    ) {
      this.putDependents([]);
    } else {
      this.putDependents(dependents);
    }
    this.setState({isValid: false})
  };

  putDependents = (dependents: Dependent[]) => {
    putDependents(Number(this.employeeId), dependents)
      .then(() => {
          this.props.closeDependentModal();
      })
      .catch(() => {
        $("#buttonSaveDependent").removeAttr("disabled");
      });
  };

  //#endRegion dependent

  render() {
    return (
      <SetupDependentsView
        addDependents={this.addDependents}
        handleChangeDependents={this.handleChangeDependents}
        handleChangeDate={this.handleChangeDate}
        handleSubmitDependents={this.handleSubmitDependents}
        removeDependents={this.removeDependents}
        dependents={this.state.dependents}
        relationships={this.state.relationships}
        findRelationshipById={this.findRelationshipById}
        isValid={this.state.isValid}
      />
    );
  }
}

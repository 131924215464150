import React, {useEffect, useState} from 'react';
import {
  StyledBigTitle,
  StyledContentBox,
  StyledMainContent,
} from "../../shared/styled";
import {PagingInfo, setPagingInfo} from "../../../cores/helpers/pagination/pagination";
import {rrspHeaders} from "./RRSPReportModel";
import {TRrspReport} from "./RRSPExportType";
import {addLoading, removeLoading} from "../../../cores/utils/loading";
import {getRrspClaimReport} from "../../../services/report-service";
import BNPTable from "../../shared/BNPTable/BNPTable";
import {TableSearchParamType} from "../../shared/BNPTable/BNPTableType";
import {AxiosResponse} from "axios";
import {convertTimeZone, download} from "../../../cores/utils/helpers";
import {SaveAlt} from "@material-ui/icons";
import styled from "styled-components";
import {FE_DATE_FORMAT, FE_MONTH_FORMAT} from "../../../cores/utils/format/date-time-format";
import moment from 'moment';

const StyledAction = styled.div`
  color: ${(props) => props.theme.infoColor};
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
  padding-left: 16px;
  cursor: pointer;

  :hover {
    text-decoration: none;
  }
  .MuiSvgIcon-root {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
`;

const defaultRrspSearchParam = {
  from: null,
  to: null,
  page: 1,
  perPage: 10,
  columnName: "createdDate",
  sortType: "DESC",
  searchKey: "",
  filter: "",
}

const defaultPaging = {
  currentPage: 0,
  totalPages: 0,
  startPage: 0,
  endPage: 0,
  pages: [],
  rowsPerPage: 0,
}

function RRSPExport() {
  const [rrspReport, setRrspReport] = useState<TRrspReport[]>([]);
  const [searchParam, setSearchParam] = useState<TableSearchParamType>(defaultRrspSearchParam);
  const [paging, setPaging] = useState<PagingInfo>(defaultPaging)

  useEffect(() => {
    getReportData().then();
  }, [searchParam.page, searchParam.columnName, searchParam.sortType, searchParam.perPage])


  const getReportData = async () => {
    addLoading();
    try {
      const result = await getRrspClaimReport(searchParam);
      setRrspReport(convertReportData(result));
      let pagingInfo = setPagingInfo(
        searchParam.page,
        result.data.pageCount,
        searchParam.perPage,
      )
      setPaging(pagingInfo);
    } catch (err) {
    }

    removeLoading();
  }

  const convertReportData = (result: AxiosResponse) => {
    return result.data.records.map((item: any) => ({
      createdDate: item.createdDate ?
          convertTimeZone("Canada/Eastern", item.createdDate).format(FE_DATE_FORMAT) : "-",
      fileName: item.fileName,
      month: moment(item.month).format(FE_MONTH_FORMAT),
      action: <StyledAction
          onClick={() => {
            download(item.filePath)
          }}
      >
        <SaveAlt/>
        Download
      </StyledAction>
    }))
  }

  return (
    <StyledMainContent>
      <StyledBigTitle>Monthly Contribution Export</StyledBigTitle>
      <div className="container-fluid p-0 mt-3">
        <StyledContentBox>
          <div className="row">
            <div className="col-12">
              <BNPTable tableName={"Monthly Contribution Export"}
                        searchParam={searchParam}
                        setSearchParam={setSearchParam}
                        columns={rrspHeaders}
                        tableWidth="100%"
                        tableData={rrspReport}
                        pagingInfo={paging}
                        haveFilterByDate={false}
                        haveSearch={false}/>
            </div>
          </div>
        </StyledContentBox>
      </div>
    </StyledMainContent>
  );
}

export default RRSPExport;
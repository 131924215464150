import React, {useMemo} from 'react';
import styled from "styled-components";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {Popper, TextField} from "@material-ui/core";
import { Option } from '../select/select';
import {StyledOption} from "../../../components/shared/styled";

const StyledAutoComplete = styled(Autocomplete)`
`

const StyledTextField = styled(TextField)`
    font-family: ${(props) => props.theme.textFont} !important;
    .MuiSelect-select {
        border-radius: 5px;
    }
    .MuiInputBase-input {
        display: block;
        width: 100%;
        height: 48px;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        color: #2d2d2d;
        background-color: #fff;
        background-clip: padding-box;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        -webkit-tap-highlight-color: transparent;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .MuiInputBase-root {
        border: 1px solid #ced4da;
        border-radius: 5px;
    }

    .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child {
        padding: 0 0.75rem;
    }
`;

const StyledPopper = styled(Popper)`
    width: fit-content;
    min-width: 250px;
`;

type Props = {
  name: string;
  options: Option[];
  optionSelectedId: string | number;
  handleChange: (event: any, value: any) => void;
  disabled?: boolean;
  disableClearable?: boolean;
  tabIndex?: number;
  placeholder?: string;
}

export const BNPAutoComplete =(props: Props) => {
  const [inputValue, setInputValue] = React.useState<string>('');

  const optionSelected = useMemo(() => {
    const selectedOption = props.options.find((option: any) => option.id === props.optionSelectedId)
    setInputValue(!!selectedOption ? selectedOption.name.toString() : '')
    return selectedOption || null
  }, [props.optionSelectedId, props.options]);

  const renderOption = (option: any) => {
    return (
      <StyledOption>
        {option.name}
      </StyledOption>
    )
  }

  const PopperMy = function (props: any) {
    return <StyledPopper {...props} placement="bottom-start"/>;
  };


  return <StyledAutoComplete
    renderInput={(params) => <StyledTextField {...params} placeholder={props.placeholder || 'Type to search'} />}
    options={props.options}
    getOptionLabel={(option: any) => option.name}
    value={optionSelected}
    inputValue={inputValue}
    onInputChange={(_, newInputValue) => {
      setInputValue(newInputValue)
    }}
    onChange={props.handleChange}
    id={`auto_complete_${props.name}`}
    disabled={props.disabled}
    disableClearable={!!props.disableClearable}
    ListboxProps={{ style: { maxHeight: 200 } }} // with this height we can display max 5 items in box frame, and using scrollbar for more
    tabIndex={props.tabIndex || 0}
    renderOption={renderOption}
    PopperComponent={PopperMy}
  />
}
import React, { useEffect, useState } from "react";
import { PartnerTransactionExportParam, SearchPartnerTransactionParam, TransactionInfo, TransactionTitle } from "../../../partner-management/partner-detail/transaction/TransactionType";
import { PagingInfo, setPagingInfo } from "../../../../../cores/helpers/pagination/pagination";
import moment from "moment";
import { BE_DATE_FORMAT } from "../../../../../cores/utils/format/date-time-format";
import { getExportPartnerTransactions, getPartnerTransactionTitles, getPartnerTransactions } from "../../../../../services/partner-wallet-service";
import { exportExcel } from "../../../../../cores/helpers/export-file/export-file";
import { Option } from "../../../../../cores/helpers/select/select";
import { StyledBigTitle } from "../../../../shared/styled";
import { Transaction } from "../../../partner-management/partner-detail/transaction/Transaction";

type Props = {
  partnerId: number;
};

export default function TransactionReport(props: Props) {

  const [transactions, setTransactions] = useState<TransactionInfo[]>([])
  const [transactionPagination, setTransactionPagination] = useState<PagingInfo>(setPagingInfo(1, 0, 10));
  const [txnTitles, setTxnTitles] = useState<Option[]>([]);

  useEffect(() => {
    getTxnTitles();
  }, [])

  const getTxnTitles = () => {
    getPartnerTransactionTitles(props.partnerId)
    .then(res => res.data)
    .then(data => {
      let titleOptions: Option[] = data.map((item: TransactionTitle) => ({
        id: item.txnCode,
        name: item.title
      }));
      setTxnTitles(titleOptions);
    });
  }

  const getTransactions = async (searchParam: SearchPartnerTransactionParam) => {

    searchParam.fromDate = null != searchParam.fromDate ? moment(searchParam.fromDate).format(BE_DATE_FORMAT) : null;
    searchParam.toDate = null != searchParam.toDate ? moment(searchParam.toDate).format(BE_DATE_FORMAT) : null;

    const res = await getPartnerTransactions(props.partnerId, searchParam);
    setTransactions(res.data.records);
    setTransactionPagination(setPagingInfo(
      searchParam.page,
      res.data.pageCount,
      searchParam.perPage
    ))
  }

  const exportTransactions = async (searchParam: SearchPartnerTransactionParam) => {

    let exportCriteria: PartnerTransactionExportParam = { ...searchParam };
    delete exportCriteria.page;
    delete exportCriteria.perPage;

    exportCriteria.fromDate = null != exportCriteria.fromDate ? moment(exportCriteria.fromDate).format(BE_DATE_FORMAT) : null;
    exportCriteria.toDate = null != exportCriteria.toDate ? moment(exportCriteria.toDate).format(BE_DATE_FORMAT) : null;

    getExportPartnerTransactions(props.partnerId, exportCriteria)
      .then(
        (result) => {
          exportExcel(result.data);
        }
      );
  }


  return (
    <>
      <div className="row pt-3 d-flex">
        <div className="col-lg-9 col-md-8 col-12">
          <StyledBigTitle>Transaction Report</StyledBigTitle>
        </div>
      </div>
      <div className="row pt-3">
        <div className="col-12 mb-3">
          <Transaction
            data={transactions}
            fetchData={getTransactions}
            pagingInfo={transactionPagination}
            handleDownload={exportTransactions}
            txnTitles={txnTitles}
          />
        </div>
      </div>
    </>
  );
}

import { AxiosResponse } from "axios";
import {getAsync, postAsync, putAsync} from "../cores/utils/http-client";
import { EFTRequest } from "../components/pages/eft-export/eft-information/eft-information-type";
import { EFTHistorySearchParam } from "../components/pages/eft-export/eft-history/eft-export-type";

const URL_Partner = "/partner-auth";

export const getEFT = (): Promise<AxiosResponse> => {
  let url = `${URL_Partner}/api/partner-auth/eft`;

  return getAsync(url);
};

export const putEFT = (param: EFTRequest): Promise<AxiosResponse> => {
  let url = `${URL_Partner}/api/partner-auth/eft`;

  return putAsync(url, param);
};

export const getEFTHistory = (
  param: EFTHistorySearchParam
): Promise<AxiosResponse> => {
  let url = `${URL_Partner}/api/partner-auth/eft/history`;

  return getAsync(url, param);
};

export const getDownloadEFTFile = (): Promise<AxiosResponse> => {
  let url = `${URL_Partner}/api/partner-auth/eft/download`;

  return getAsync(url);
};

export const getDownloadEFTFilesAsZip = (): Promise<AxiosResponse> => {
  let url = `${URL_Partner}/api/partner-auth/eft/download-zip`;

  return getAsync(url);
};

export const resendEftToXp = (fileName: string): Promise<AxiosResponse> => {
  let url = `${URL_Partner}/api/partner-auth/eft/resend/xp`;

  return postAsync(url, {fileName});
};

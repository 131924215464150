import React, { useState, useEffect, useMemo, Fragment } from "react";
import styled from "styled-components";
import {
  StyledHeaderModal,
  StyledHeaderTitleModal,
  StyledButtonCloseModal,
  StyledIconCloseModal,
  StyledInputMask,
  StyledButtonSubmit,
  StyledPTitle,
  StyledDivTitle,
  StyledSelect,
  StyledDefaultOption,
  StyledOption,
  StyledFormControlLabel,
} from "../../../shared/styled";
import { BNPInput } from "../../../../cores/helpers/input/input";
import {BNPSelect, Option} from "../../../../cores/helpers/select/select";
import {
  Provinces,
  EmployeeModal,
} from "../company-management-type";
import GoogleLocationInput, {
  ResponseLocation,
} from "../../../../cores/helpers/google-map-services/google-location";
import {
  getProvinces,
  getEmployeeInfoById,
  getEmployerPlans,
} from "../../../../services/company-service";
import { addLoading, removeLoading } from "../../../../cores/utils/loading";
import { MenuItem, Radio, RadioGroup } from "@material-ui/core";
import { getAllRole } from "../../../../services/auth-service";
import { capFirst } from "../../../../cores/helpers/cap-first";
import { planPreferences } from "../detail/employees/employee-model";
import BNPDatePicker from "../../../../cores/helpers/date-picker/date-picker";
import { toUTCTime } from "../../../../cores/helpers/to-utc-time";
import { usdToCent, centToUsd } from "../../../../cores/helpers/cent-to-usd";
import moment from "moment";
import {offsetToFrontEndTimezone} from "../../../../cores/utils/format/date-time-format";
import {validFullNameLength} from "../../../../cores/utils/validation/validate-employee";
import {validateEmail} from "../../../../cores/config/config";
import cloneDeep from "lodash/cloneDeep";
import {EmployerPlanWithSettings} from "../detail/plan/plan-type";
import {formatterUSD} from "../../../../cores/helpers/format-usd";

const StyledModalDialog = styled.div`
  max-width: 800px;
  margin: 1.75rem auto;
  position: relative;
  width: auto;
  pointer-events: none;
`;

const StyledSpanErrorMessage = styled.span`
  color: ${(props) => props.theme.errorColor};
  font-size: 14px;
`;

const StyledDescription = styled(RadioGroup)`
  flex-direction: row !important;
  width: 100%;
  .MuiFormControlLabel-label {
    font-size: 14px;
  }
  .MuiFormControlLabel-root {
    margin-right: 20px;
    margin-bottom: 0;
  }
`;

type Props = {
  data: EmployeeModal;
  isCreate: boolean;
  handleSubmit: (data: EmployeeModal) => Promise<boolean>;
  isT4Employee?: boolean;
  setIsT4Employee?: (val: boolean) => void;
};

type Role = {
  id: number;
  name: string;
};

export const defaultEmployee: EmployeeModal = {
  id: 0,
  firstName: "",
  lastName: "",
  code: "",
  status: "",
  gender: null,
  dateOfBirth: "",
  phoneNumber: "",
  homeAddress: "",
  classId: 0,
  employerPlanId: -1,
  employerClassBenefitId: -1,
  socialInsuranceNumber: null,
  position: "",
  salary: 0,
  eligibilityDate: "",
  hiringDate: "",
  benefitCancellationDate: "",
  prorated: false,
  signUpStatus: "",
  email: "",
  roleIds: [],
  role: [],
  provinceId: 0,
  cityName: "",
  postCode: "",
  terminationDate: "",
  employerId: 0,
  isSetUpDone: null,
  classBenefitFromDate: new Date(),
};

export default function EmployeePopup(props: Props) {
  const [employee, setEmployee] = useState<EmployeeModal | null>(null); //Not edited employee
  const [data, setData] = useState<EmployeeModal>(cloneDeep(defaultEmployee));
  const [provinces, setProvinces] = useState<Provinces[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [employerId, setEmployerId] = useState<number>(0);
  const [add, setAdd] = useState<string>("");
  const [employerPlans, setEmployerPlans] = useState<EmployerPlanWithSettings[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const isEligibilityDateDisabled =  !!data.isSetUpDone;

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    const { firstName, lastName } = data;
    if (!validFullNameLength(firstName, lastName))
      setErrorMessage("Full name length could not be larger than 30 letters.");
    else {
      setErrorMessage("");
    }
  }, [data.firstName, data.lastName]);

  const init = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    let id = urlParams.get("id");
    if (!props.isCreate) {
      const resEmployee = await getEmployeeInfoById(Number(id ? id : "0"));
      setData({
        ...resEmployee.data,
        salary: centToUsd(resEmployee.data.salary),
        classBenefitFromDate: new Date()
      });
      setEmployee({
        ...resEmployee.data,
        salary: centToUsd(resEmployee.data.salary),
        classBenefitFromDate: new Date()
      });
      setAdd(resEmployee.data.homeAddress ? resEmployee.data.homeAddress : "")
      id = resEmployee.data.employerId + "";
    }
    setEmployerId(Number(id ? id : "0"));
    const [
      res,
      resultRole,
      resultEmployerPlans,
    ] = await Promise.all([
      getProvinces(),
      getAllRole(),
      getEmployerPlans(Number(id ? id : "0")),
    ]);

    setProvinces(res.data);
    setRoles(resultRole.data);
    setEmployerPlans(resultEmployerPlans.data || []);
  };

  const currentEmployeeClassBenefit = useMemo(() => {
    if (!employee || !employee.employerPlanId || !employee.employerClassBenefitId) return;
    const employerPlan = employerPlans
      .find(item => (item.employerPlanId === employee.employerPlanId));
    if (!employerPlan) return null;

    return employerPlan.classBenefits.find(item => item.id === employee.employerClassBenefitId)
  }, [employee, employerPlans])

  const findValueByPlanPreferencesByEmployer = (
    planPreferencesByEmployers: any
  ): number | null => {
    let value = null;
    if (planPreferencesByEmployers)
      planPreferencesByEmployers.forEach((planPreferencesByEmployer: any) => {
        let planPreference = planPreferences.find((planPreference) => {
          return (
            planPreference.id === planPreferencesByEmployer.planPreferenceId
          );
        });
        if (planPreference) {
          value = planPreference.value;
        }
      });

    return value;
  };

  const eligibleDate = useMemo(() => {
    const employerPlan = employerPlans.find(
      (employerPlan) => employerPlan.employerPlanId === data.employerPlanId
    );

    if (employerPlan)
      return findValueByPlanPreferencesByEmployer(employerPlan.planPreferences);

    return 0;
  },[employerPlans, data.employerPlanId])

  const handleChangeInput = (event: any) => {
    let { name, value } = event.target;
    let employees: any = { ...props.data, ...data };

    if (name === "roleIds") {
      employees.roleIds = [value];
    } else {
      if (value === -1) value = null;

      employees[name] = value;
    }

    let isValid = validate(employees, props.isT4Employee);
    setData(employees);
    setIsValid(isValid);
  };

  const handleChangeDate = (date: Date | null, name: string) => {
    let temp: any = { ...props.data, ...data };

    if (name === "hiringDate" && !temp.isSetUpDone) {

      const employerPlan = employerPlans.find(
        (employerPlan) => employerPlan.employerPlanId === temp.employerPlanId
      );

      if (date && eligibleDate !== null && !!employerPlan && !temp.isSetUpDone) {
        if (eligibleDate > -1) {
          let eligibilityDate = new Date(date);
          eligibilityDate.setDate(date.getDate() + eligibleDate);
          const planStartDate = employerPlan.planStartDate
            ? new Date(employerPlan.planStartDate)
            : null;
          if (planStartDate && planStartDate > eligibilityDate) {
            eligibilityDate  = planStartDate;
          }
          temp.eligibilityDate = eligibilityDate;
        }
      }
    }
    temp[name] = date;

    let isValid = validate(temp, props.isT4Employee);
    setData(temp);
    setIsValid(isValid);
  };

  const validate = (data: EmployeeModal, isT4Employee: boolean = true) => {
    if (!props.isCreate && !data.id) return false;

    if (props.isCreate && !isT4Employee) {
      return !!(data.email.match(validateEmail) &&
          data.firstName && data.lastName &&
          validFullNameLength(data.firstName, data.lastName) &&
          data.roleIds.length > 0 &&
          data.roleIds[0] > 0
      )}

    return !!(
      data.firstName &&
      data.lastName &&
      (data.gender ? data.gender !== "-1" : true) &&
      data.lastName &&
      (data.provinceId ? data.provinceId > 0 : true) &&
      data.email &&
      data.code &&
      data.hiringDate &&
      data.roleIds &&
      data.roleIds.length > 0 &&
      data.employerPlanId > 0 &&
      data.employerClassBenefitId > 0 &&
      data.eligibilityDate &&
      validFullNameLength(data.firstName, data.lastName)
    );
  };

  const handleChangeT4Employee = (e:any) => {
    if (props.isCreate && props.setIsT4Employee) {
      props.setIsT4Employee(e.target.value == "true");
      const valid = validate(data, e.target.value == "true");
      setIsValid(valid);
    }
  }

  const findProvinceIdByName = (
    provinceName: string,
    provinces: Provinces[]
  ) => {
    let province = provinces.find((f) => f.name === provinceName);
    return province ? province.id : -1;
  };

  const setAddress = (address: string) => {
    let temp: EmployeeModal = { ...data };
    temp.homeAddress = address;

    setData(temp);
    setAdd(address);
  };

  const setLocation = (location: ResponseLocation) => {
    let temp: EmployeeModal = { ...data };

    temp.cityName = location.city;
    temp.postCode = location.postcode ? location.postcode : "";
    temp.provinceId = findProvinceIdByName(location.province, provinces);

    setData(temp);
  };

  const convertEmployeeModalData = (): EmployeeModal => {
    let temp: EmployeeModal = { ...data };
    if (temp.postCode) temp.postCode = temp.postCode.toUpperCase();
    if (temp.salary) temp.salary = usdToCent(temp.salary);
    temp.employerId = employerId;
    if (temp.phoneNumber)
      temp.phoneNumber = temp.phoneNumber
        .split("-")
        .join("")
        .split("_")
        .join("");
    if (props.isCreate) temp.status = "WORKING";
    if (add) temp.homeAddress = add;
    if (temp.dateOfBirth) temp.dateOfBirth = toUTCTime(new Date(temp.dateOfBirth));
    temp.hiringDate = toUTCTime(new Date(temp.hiringDate));
    temp.eligibilityDate = toUTCTime(new Date(temp.eligibilityDate));
    if (temp.benefitCancellationDate)
      temp.benefitCancellationDate = toUTCTime(
        new Date(temp.benefitCancellationDate)
      );
    if (!temp.externalBaseId || temp.externalBaseId === "")
      temp.externalBaseId = undefined;
    if (temp.classBenefitFromDate)
      temp.classBenefitFromDate = toUTCTime(new Date(temp.classBenefitFromDate));

    return temp;
  }

  const handleSubmit = async () => {
    addLoading();
    const temp = convertEmployeeModalData();
    const res = await props.handleSubmit(temp);
    if (res) {
      if (props.isCreate) setData(defaultEmployee);
      closeEmployeeModal();
    } else setData(temp);
    removeLoading();
  };

  const eligibilityMinDate: Date | string | null = useMemo(() => {
    const employerPlan = employerPlans.find(
      (employerPlan) => employerPlan.employerPlanId === data.employerPlanId
    );

    if (employerPlan && employerPlan.planStartDate && data.hiringDate)
      return moment(employerPlan.planStartDate).isAfter(moment(data.hiringDate))
        ? offsetToFrontEndTimezone(employerPlan.planStartDate)
        : offsetToFrontEndTimezone(data.hiringDate);
    if (employerPlan && employerPlan.planStartDate && !data.hiringDate)
      return offsetToFrontEndTimezone(employerPlan.planStartDate);
    if (data.hiringDate && !employerPlan)
      return offsetToFrontEndTimezone(data.hiringDate);
    return null;
  }, [data.hiringDate, employerPlans, data.employerPlanId]);

  const displayT4EmployeeRadio = useMemo(() => {
    if (props.setIsT4Employee) {
      props.setIsT4Employee(true);
    }
    return (
      props.isCreate &&
      data.roleIds.length > 0 &&
      (data.roleIds.includes(1) || data.roleIds.includes(2))
    );
  }, [data.roleIds]);

  const displayT4EmployeeForm = useMemo(() => {
    if (!props.isCreate) return true;
    return (
      data.roleIds.length > 0 &&
      (data.roleIds.includes(3) || props.isT4Employee)
    );
  }, [data.roleIds, props.isT4Employee]);

  const employerPlanOptions: Option[] = useMemo(() => {
    return employerPlans.map((employerPlan) => ({
      id: employerPlan.employerPlanId,
      name: employerPlan.planName,
    }));
  }, [employerPlans]);

  const employerClassBenefitOptions: Option[] = useMemo(() => {
    const employerPlan = employerPlans.find(
      (employerPlan) => employerPlan.employerPlanId === data.employerPlanId
    );
    if (!employerPlan) return [];
    return employerPlan.classBenefits.map((employerClassBenefit) => ({
      id: employerClassBenefit.id,
      name: `${employerClassBenefit.className} - ${formatterUSD("currency", "USD").format(
        centToUsd(employerClassBenefit.coverageAmount)
      )}`,
      disabled: Number(moment().format("MMDD")) <= 115 ?
        false :
        (currentEmployeeClassBenefit ? employerClassBenefit.coverageAmount < currentEmployeeClassBenefit.coverageAmount : undefined)
    }));
  }, [employerPlans, data.employerPlanId, currentEmployeeClassBenefit]);

  return (
    <div className="modal" id="employeeModal">
      <StyledModalDialog role="document">
        <div className="modal-content">
          <StyledHeaderModal className="modal-header">
            <StyledHeaderTitleModal>
              {props.isCreate ? "Create Employee" : "Update Employee"}
            </StyledHeaderTitleModal>
            <StyledButtonCloseModal
              type="button"
              onClick={() => {
                if (props.isCreate) setData(defaultEmployee);
                closeEmployeeModal();
              }}
            >
              <StyledIconCloseModal>&times;</StyledIconCloseModal>
            </StyledButtonCloseModal>
          </StyledHeaderModal>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-6 mb-4">
                <StyledPTitle className="mb-2">First name *</StyledPTitle>
                <BNPInput
                  value={data.firstName}
                  name="firstName"
                  onChange={handleChangeInput}
                  placeholder="First name"
                  tabIndex={1}
                />
              </div>
              <div className="col-md-6 mb-4">
                <StyledPTitle className="mb-2">Last name *</StyledPTitle>
                <BNPInput
                  value={data.lastName}
                  name="lastName"
                  onChange={handleChangeInput}
                  placeholder="Last name"
                  tabIndex={2}
                />
              </div>
              <div className="col-md-6 mb-4">
                <StyledPTitle className="mb-2">Email *</StyledPTitle>
                <BNPInput
                  value={data.email}
                  name="email"
                  onChange={handleChangeInput}
                  placeholder="Email"
                  tabIndex={5}
                />
              </div>
              <div className="col-md-6 mb-4">
                <StyledPTitle className="mb-2">Role *</StyledPTitle>
                <StyledSelect
                  value={
                    data.roleIds && data.roleIds.length ? data.roleIds[0] : -1
                  }
                  onChange={handleChangeInput}
                  name="roleIds"
                  inputProps={{ tabIndex: 15 }}
                >
                  <MenuItem value={-1} key={-1}>
                    <StyledDefaultOption className="p-2">
                      Role (Required)
                    </StyledDefaultOption>
                  </MenuItem>
                  {roles.map((role) => (
                    <MenuItem value={role.id} key={role.id}>
                      <StyledOption className="p-2">
                        {capFirst(role.name)}
                      </StyledOption>
                    </MenuItem>
                  ))}
                </StyledSelect>
              </div>
              {displayT4EmployeeRadio && (
                <div className="col-12">
                  <StyledDescription
                      name="Is this an employee?"
                      value={props.isT4Employee}
                      onChange={(e) =>
                          handleChangeT4Employee(e)
                      }
                  >
                    <div className="d-flex align-items-center mr-3">
                      <StyledPTitle className="mb-0">
                        Is this an employee?
                      </StyledPTitle>
                    </div>
                    <span key="isT4Employee_yes">
                      <StyledFormControlLabel
                        disabled={!props.isCreate}
                        value={true}
                        control={<Radio color="primary" />}
                        label="Yes"
                      />
                    </span>
                    <span key="isT4Employee_no">
                      <StyledFormControlLabel
                        disabled={!props.isCreate}
                        value={false}
                        control={<Radio color="primary" />}
                        label="No"
                      />
                    </span>
                  </StyledDescription>
                </div>
              )}

              {displayT4EmployeeForm && (
                <Fragment>
                  {!props.isCreate &&
                      <>
                          <div className="col-md-6 mb-4">
                              <StyledPTitle className="mb-2">
                                  Gender at Birth
                              </StyledPTitle>
                              <StyledSelect
                                  value={data.gender ? data.gender.toString() : "-1"}
                                  onChange={handleChangeInput}
                                  name="gender"
                                  inputProps={{tabIndex: 3}}
                              >
                                  <MenuItem value="-1">
                                      <StyledDefaultOption>
                                          Gender at Birth
                                      </StyledDefaultOption>
                                  </MenuItem>
                                  <MenuItem value="MALE">
                                      <StyledOption className="p-2">Male</StyledOption>
                                  </MenuItem>
                                  <MenuItem value="FEMALE">
                                      <StyledOption className="p-2">Female</StyledOption>
                                  </MenuItem>
                              </StyledSelect>
                          </div>
                          <div className="col-md-6 mb-4">
                              <StyledPTitle className="mb-2">
                                  Date of birth
                              </StyledPTitle>
                              <BNPDatePicker
                                  value={
                                    data.dateOfBirth
                                  }
                                  name="dateOfBirth"
                                  onChange={handleChangeDate}
                                  placeholder="Date of birth"
                                  tabIndex={4}
                              />
                          </div>
                          <div className="col-md-12 mb-4">
                              <StyledPTitle className="mb-2">Phone number</StyledPTitle>
                              <StyledInputMask
                                  mask="999-999-9999"
                                  value={data.phoneNumber || ""}
                                  onChange={handleChangeInput}
                                  name="phoneNumber"
                                  placeholder="Phone number"
                                  tabIndex={7}
                              />
                          </div>
                          <div className="col-md-12 mb-4">
                              <StyledPTitle className="mb-2">Street address</StyledPTitle>
                              <GoogleLocationInput
                                  id="homeAddress"
                                  address={
                                    data.homeAddress === add ? data.homeAddress : add
                                  }
                                  setAddress={setAddress}
                                  setLocation={setLocation}
                                  placeholder="Street address"
                                  tabIndex={8}
                              />
                          </div>
                          <div className="col-md-4 mb-4">
                              <StyledPTitle className="mb-2">Province</StyledPTitle>
                              <BNPSelect
                                  options={provinces.map((province) => ({
                                    id: province.id,
                                    name: province.name,
                                  }))}
                                  placeholder="Province"
                                  name="provinceId"
                                  value={data.provinceId || -1}
                                  onChange={handleChangeInput}
                                  tabIndex={9}
                              />
                          </div>
                          <div className="col-md-4 mb-4">
                              <StyledPTitle className="mb-2">City</StyledPTitle>
                              <BNPInput
                                  value={data.cityName || ""}
                                  name="cityName"
                                  onChange={handleChangeInput}
                                  placeholder="City"
                                  tabIndex={10}
                              />
                          </div>
                          <div className="col-md-4 mb-4">
                              <StyledPTitle className="mb-2">Postcode</StyledPTitle>
                              <StyledInputMask
                                  mask="*** ***"
                                  value={data.postCode ? data.postCode.toUpperCase() : ""}
                                  onChange={handleChangeInput}
                                  name="postCode"
                                  placeholder="Postcode"
                                  tabIndex={11}
                              />
                          </div>
                      </>
                  }
                  <div className="col-md-12">
                    <div className="mt-3 mb-3 fw-bold">
                      Employment Information
                    </div>
                  </div>
                  <div className="col-12 mb-4">
                    <StyledPTitle className="mb-2">Plan *</StyledPTitle>
                    <BNPSelect
                      options={employerPlanOptions}
                      placeholder="Plan (Required)"
                      name="employerPlanId"
                      value={data.employerPlanId || -1}
                      onChange={handleChangeInput}
                    />
                  </div>
                  <div className="col-md-4 mb-4">
                    <StyledPTitle className="mb-2">ID *</StyledPTitle>
                    <BNPInput
                      value={data.code}
                      name="code"
                      onChange={handleChangeInput}
                      placeholder="ID"
                      tabIndex={12}
                    />
                  </div>
                  <div className="col-md-4 mb-4">
                    <StyledPTitle className="mb-2">Hiring Date *</StyledPTitle>
                    <BNPDatePicker
                      value={
                        data.hiringDate
                      }
                      name="hiringDate"
                      onChange={handleChangeDate}
                      placeholder="Hiring date"
                      tabIndex={13}
                    />
                  </div>
                  <div className="col-md-4 mb-4">
                    <StyledPTitle className="mb-2">Salary</StyledPTitle>
                    <BNPInput
                      value={data.salary !== 0 ? data.salary : undefined}
                      name="salary"
                      onChange={handleChangeInput}
                      placeholder="Salary"
                      tabIndex={14}
                      type="number"
                    />
                  </div>
                  <div className="col-md-4 mb-4">
                    <StyledPTitle className="mb-2">Position</StyledPTitle>
                    <BNPInput
                      value={data.position}
                      name="position"
                      onChange={handleChangeInput}
                      placeholder="Position"
                      tabIndex={16}
                    />
                  </div>
                  {!props.isCreate &&
                      <div className="col-md-4 mb-4">
                          <StyledPTitle className="mb-2">
                              Social insurance number
                          </StyledPTitle>
                          <BNPInput
                              value={
                                (data.socialInsuranceNumber !== null && data.socialInsuranceNumber !== 0)
                                  ? data.socialInsuranceNumber
                                  : undefined
                              }
                              name="socialInsuranceNumber"
                              onChange={handleChangeInput}
                              placeholder="Social insurance number"
                              tabIndex={17}
                          />
                      </div>
                  }
                  <div className="col-md-12">
                    <StyledDivTitle className="mt-3 mb-3">
                      Employment Setup
                    </StyledDivTitle>
                  </div>
                  <div className="col-md-4 mb-4">
                    <StyledPTitle className="mb-2">Class *</StyledPTitle>
                    <BNPSelect
                      options={employerClassBenefitOptions}
                      placeholder="Class (Required)"
                      name="employerClassBenefitId"
                      value={data.employerClassBenefitId || -1}
                      onChange={handleChangeInput}
                    />
                  </div>
                  <div className="col-md-4 mb-4">
                    <StyledPTitle className="mb-2">
                      Eligibility Date*
                    </StyledPTitle>
                    <BNPDatePicker
                      value={
                        data.eligibilityDate
                      }
                      name="eligibilityDate"
                      onChange={handleChangeDate}
                      placeholder="Eligibility Date"
                      tabIndex={19}
                      minDate={!isEligibilityDateDisabled ? eligibilityMinDate : undefined}
                      disabled={isEligibilityDateDisabled}
                    />
                  </div>
                  <div className="col-md-4 mb-4">
                    <StyledPTitle className="mb-2">
                      Benefit Cancellation Date
                    </StyledPTitle>
                    <BNPDatePicker
                      value={
                        data.benefitCancellationDate
                      }
                      name="benefitCancellationDate"
                      onChange={handleChangeDate}
                      placeholder="Benefit Cancellation Date"
                      tabIndex={20}
                      minDate={!props.isCreate && data.status !== 'WORKING' ? undefined : new Date()}
                      disabled={!props.isCreate && data.status !== 'WORKING'}
                    />
                  </div>

                  {!props.isCreate && !!employee && data.employerClassBenefitId !== employee.employerClassBenefitId && (
                    <div className="col-lg-12 mt-3">
                      <StyledPTitle className="mb-1">New Class Effective Date</StyledPTitle>
                      <BNPDatePicker
                        value={data.classBenefitFromDate}
                        onChange={handleChangeDate}
                        name="classBenefitFromDate"
                        placeholder="From Date"
                      />
                    </div>
                  )}
                  {!props.isCreate && (
                    <Fragment>
                      <div className="col-md-12">
                        <StyledDivTitle className="mt-3 mb-3">
                          External IDs
                        </StyledDivTitle>
                      </div>
                      <div className="col-md-4 mb-4">
                        <StyledPTitle className="mb-2">
                          External Base ID
                        </StyledPTitle>
                        <BNPInput
                          value={data.externalBaseId}
                          name="externalBaseId"
                          onChange={handleChangeInput}
                          placeholder="External Base ID"
                          tabIndex={16}
                        />
                      </div>
                    </Fragment>
                  )}
                </Fragment>
              )}
            </div>
          </div>
          <div className="modal-footer">
            <div className="row w-100">
              <div className="col-md-8 p-0 d-flex align-items-center">
                <StyledSpanErrorMessage>{errorMessage}</StyledSpanErrorMessage>
              </div>
              <div className="col-md-4 p-0">
                <StyledButtonSubmit
                  disabled={!isValid}
                  onClick={handleSubmit}
                  submit={isValid}
                  type="submit"
                  tabIndex={16}
                >
                  {props.isCreate ? "Create" : "Save"}
                </StyledButtonSubmit>
              </div>
            </div>
          </div>
        </div>
      </StyledModalDialog>
    </div>
  );
}

export function showEmployeeModal() {
  ($("#employeeModal") as any).modal({
    show: true,
    keyboard: false,
    backdrop: "static",
  });
}

function closeEmployeeModal() {
  ($("#employeeModal") as any).modal("hide");
}

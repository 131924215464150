import React from "react";
import {
  StyledHeaderModal,
  StyledHeaderTitleModal,
  StyledIconCloseModal,
  StyledButtonCloseModal,
  StyledDivTitle,
  StyledPError,
  StyledButtonSubmit
} from "../../../../shared/styled";
import { BNPInput } from "../../../../../cores/helpers/input/input";
import { showConfirmModal } from "../../../../../cores/helpers/confirm-modal/confirm-modal";
import styled from "styled-components";


const StyledButtonMaxBalance = styled.button`
  position: absolute;
  top: 41px;
  right: 14px;
  height: 36px;

  background-color: #f9fbfd;
  border: 1px solid #ecedf0;
  border-radius: 0.25rem;
  display: inline-block;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

type Props = {
  id: number;
  amount: number;
  availableAmount: number;
  handleChangeInputRefund: (event: any) => void;
};
export default function EmployerRefundView(props: Props) {

  return (
    <>
      <div className="modal" role="dialog" id="refund-modal">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <StyledButtonCloseModal name="btn-close" onClick={closeRefundModal}>
                <StyledIconCloseModal>&times;</StyledIconCloseModal>
              </StyledButtonCloseModal>
              <StyledHeaderModal>
                <StyledHeaderTitleModal>Refund</StyledHeaderTitleModal>
              </StyledHeaderModal>
              <div className="row">
                <div className="col-12">
                  <StyledDivTitle>Amount</StyledDivTitle>
                  <BNPInput
                    type="number"
                    className="col-12 mt-2"
                    onChange={props.handleChangeInputRefund}
                    name="amount"
                    placeholder="Amount"
                    value={props.amount.toString()}
                  />
                  <StyledButtonMaxBalance onClick={() => {
                    props.handleChangeInputRefund({
                      target: {
                        value: (props.availableAmount / 100)
                      }
                    })
                  }}>
                    {"Max"}
                  </StyledButtonMaxBalance>
                  {
                    props.amount < 0 ?
                      <StyledPError>Please do not enter a negative number</StyledPError>
                      :
                      <></>
                  }
                </div>
              </div>
              <div className="row mt-4">
                <StyledButtonSubmit
                  className="col-2 ml-auto"
                  onClick={() => {
                    showConfirmModal()
                  }}
                  submit={props.amount > 0 && props.amount <= props.availableAmount / 100}
                  disabled={props.amount <= 0 || props.amount > props.availableAmount / 100}
                  type="submit" >
                  Save
                </StyledButtonSubmit>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export function showRefundModal() {
  ($("#refund-modal") as any).modal({
    show: true,
    keyboard: false,
    backdrop: "static",
  });
}
export function closeRefundModal() {
  ($("#refund-modal") as any).modal("hide");
}
import React, {Fragment} from "react";
import {
  StyledMainContent,
  StyledBigTitle,
  StyledContentBox,
  StyledHideForMobile,
  StyledHideForDesktop,
  StyledExpansionPanelSummary,
  StyledTitleExpansion,
  StyledExpansionPanelDetails,
  StyledSpanExpansionEdit,
} from "../../shared/styled";
import {
  StyledTable,
  StyledHeaderTable,
  StyledCellTable,
  StyledBodyTable,
  StyledArrowDropDownIcon,
  StyledArrowDropUpIcon,
  StyledImportExportIcon,
} from "../../shared/table-styled";
import { formatterUSD } from "../../../cores/helpers/format-usd";
import { centToUsd } from "../../../cores/helpers/cent-to-usd";
import Pagination, {
  PagingInfo,
} from "../../../cores/helpers/pagination/pagination";
import {
  DepositWithdrawTransactions,
  DepositWithdrawTransactionSearchParams,
  TConfirmModalContent,
  TConfirmModalContentDetail,
} from "./deposit-withdraw-transaction-type";
import styled from "styled-components";
import {
  ConfirmDialog,
  showConfirmModal,
} from "../../../cores/helpers/confirm-modal/confirm-modal";
import { BNPSelect } from "../../../cores/helpers/select/select";
import { SortTable } from "../../../cores/models/SortTable";
import { BNPInput } from "../../../cores/helpers/input/input";
import SearchIcon from "@material-ui/icons/Search";
import { ExpansionPanel } from "@material-ui/core";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import {
  Menu,
  IconButton,
  MenuItem,
  Fade
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { requestDepositWithdrawTransactionURL, depositWithdrawTransactionHistoryURL } from "../../../cores/constants/url-config";
import { convertTimeZone } from "../../../cores/utils/helpers";
import {getFileUrl} from "../../../services/file-service";
import {CheckBox} from "../../../cores/helpers/checkbox/checkbox";
import {FE_DATE_FORMAT} from "../../../cores/utils/format/date-time-format";

const StyledLink = styled(Link)`
  text-decoration: unset;
  color: #003049;
  :hover, :focus, :visited, :active {
    text-decoration: unset;
    color: #003049;
  }
`;

const StyledButtonApprove = styled.button`
  width: 90px;
  height: 25px;
  position: relative;
  background-color: ${(props) => props.theme.primaryColor};
  color: #ffffff;
  border: 1px solid #ecedf0;
  border-radius: 0.25rem;
  display: inline-block;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  padding: 0;
  font-size: 14px;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  :hover {
    opacity: 0.8;
  }
`;

const StyledButtonDecline = styled(StyledButtonApprove)`
  background-color: ${(props) => props.theme.errorColor};
`;

const StyledButtonApproveAll = styled(StyledButtonApprove)`
  width: 100%;
  height: 100%;
`;

const StyledButtonDeclineAll = styled(StyledButtonDecline)`
  width: 100%;
  height: 100%;
`;

export const StyledBNPInput = styled(BNPInput)`
  padding-right: 35px;
`;

export const StyledIconSearch = styled(SearchIcon)`
  position: absolute;
  top: 13px;
  right: 14px;
  opacity: 0.7;
`;

type Props = {
  transactions: DepositWithdrawTransactions[];
  transactionsFilter: DepositWithdrawTransactions[];
  changePage: (page: number) => void;
  pagingInfo: PagingInfo;
  setRowsPerPage: (event: any) => void;
  searchParam: DepositWithdrawTransactionSearchParams;
  changeFilter: (event: any) => void;
  handleApprove: (sundryNumber: string) => void;
  handleRenew: (sundryNumber: string) => void;
  columns: SortTable[];
  sortTable: (columnId: string) => void;

  handleChangeInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyUp: (event: any) => void;
  searchBySundryNumber: () => void;
  handleDecline: (journalId: number, entry: number, isWithdraw: boolean) => void;
  handleApproveMultipleTransactions: (transactions: DepositWithdrawTransactions[]) => void;
  handleDeclineMultipleTransactions: (transactions: DepositWithdrawTransactions[]) => void;
};

export default function DepositWithdrawTransactionView(props: Props) {
  const [
    depositWithdrawTransaction,
    setDepositWithdrawTransaction,
  ] = React.useState<DepositWithdrawTransactions>({
    amount: 0,
    date: new Date(),
    firstName: "",
    lastName: "",
    journalEntryId: 0,
    journalId: 0,
    sundryNumber: "",
    type: "",
    transactionType: ""
  });
  const [isDecline, setIsDecline] = React.useState<boolean>(false);
  const [isRenew, setIsRenew] = React.useState<boolean>(false);
  const [isMultiple, setIsMultiple] = React.useState<boolean>(false);
  const [checkedTransactions, setCheckedTransactions] = React.useState<DepositWithdrawTransactions[]>([]);
  const [item, setItem] = React.useState<DepositWithdrawTransactions>({
    type: "",
    firstName: "",
    lastName: "",
    amount: 0,
    date: "",
    sundryNumber: "",
    journalId: 0,
    journalEntryId: 0,
    transactionType: ""
  });

  const ConfirmModalContent: TConfirmModalContent<TConfirmModalContentDetail> = {
    renew: {
      title: "Revert status",
      content: `Do you want to revert status of this ${depositWithdrawTransaction.transactionType.toLowerCase()} back to request?`,
      handleOnOk: () => props.handleRenew(depositWithdrawTransaction.sundryNumber),
    },
    decline: {
      title: "Decline",
      content: `Please confirm that you want to declined this ${depositWithdrawTransaction.transactionType.toLowerCase()}`,
      handleOnOk: () => props.handleDecline(depositWithdrawTransaction.journalId, depositWithdrawTransaction.journalEntryId, depositWithdrawTransaction.transactionType === 'Withdraw'),
    },
    approve: {
      title: "Approve",
      content: `Please confirm that after you approved this request, ` +
        formatterUSD("currency").format(centToUsd(Math.abs(depositWithdrawTransaction.amount))) +
        ` will be send to ${depositWithdrawTransaction.firstName} ${depositWithdrawTransaction.lastName}'s wallet.`,
      handleOnOk: () => props.handleApprove(`${depositWithdrawTransaction.journalId}-${depositWithdrawTransaction.journalEntryId}`),
    },
    approveAll: {
      title: "Approve All",
      content: `Please confirm that you approve ${checkedTransactions.length} requests?`,
      handleOnOk: () => {
        props.handleApproveMultipleTransactions(checkedTransactions);
        setCheckedTransactions([]);
      },
    },
    declineAll: {
      title: "Decline All",
      content: `Please confirm that you decline ${checkedTransactions.length} requests?`,
      handleOnOk: () => {
        props.handleDeclineMultipleTransactions(checkedTransactions);
        setCheckedTransactions([]);
      },
    }
  }

  const confirmModalType = (): string => {
    if (isRenew) return "renew";
    if (isDecline && isMultiple) return "declineAll";
    if (isDecline && !isMultiple) return "decline";
    if (isMultiple) return "approveAll";
    return "approve";
  }

  const showModalConfirmMultiple = (decline: boolean = false) => {
    setIsRenew(false);
    setIsMultiple(true);
    setIsDecline(decline);
    showConfirmModal();
  };

  const checkedCheckbox = (sundryNumber: string) => checkedTransactions.some(item => item.sundryNumber === sundryNumber)

  const handleChangeCheckbox = (event: any) => {
    const { value, checked } = event.target;
    if (!checked) {
      deleteCheckedTransactions(value)
    } else {
      addCheckedTransactions(value)
    }
  }

  const addCheckedTransactions = (sundryNumber: string): void => {
    const checkedTransactionsTemp = [...checkedTransactions]
    const transaction = props.transactionsFilter.find(item => item.sundryNumber === sundryNumber);

    if (transaction) checkedTransactionsTemp.push(transaction);

    setCheckedTransactions(checkedTransactionsTemp)
  }

  const deleteCheckedTransactions = (sundryNumber: string) => {
    const checkedTransactionsTemp = [...checkedTransactions]
    const index = checkExistedCheckedTransactions(sundryNumber);

    if (index > -1) checkedTransactionsTemp.splice(index, 1);

    setCheckedTransactions(checkedTransactionsTemp)
  }

  const resetCheckedTransactions = () => {
    setCheckedTransactions([]);
  }

  const checkExistedCheckedTransactions = (sundryNumber: string): number =>
    checkedTransactions.findIndex(item => item.sundryNumber === sundryNumber);

  const showModalConfirm = (transaction: DepositWithdrawTransactions, decline: boolean = false, renew: boolean = false) => {
    setIsMultiple(false);
    setIsDecline(decline);
    setIsRenew(renew);
    setDepositWithdrawTransaction(transaction);
    showConfirmModal();
  };

  // Grouping pages view request/history deposit/withdraw transaction
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>, other: boolean = false, item?: DepositWithdrawTransactions) => {
    setIsRenew(other);
    if (item) setItem(item);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getFileURL = (url: string, type: string) => {
    getFileUrl( url ).then( (response) => {
      return response.data;
    }).then(url => {
      if(type === 'open') {
        window.open(url, "_blank");
      }
      if(type === 'download') {
        // handle download
      }
    });
  };

  const openTab = (url: string, type = 'open') => {
    getFileURL(url, type)
  }

  const displayTopUpTransferType = (transferType?: string) => {
    const lowerVal = (transferType && transferType.toLowerCase()) || '';
    switch (lowerVal) {
      case 'etransfer':
        return 'E-Transfer or Bill Pay';
      default:
        return transferType;
    }
  }

  return (
    <StyledMainContent>
      <StyledBigTitle>Deposit/Withdraw Transaction</StyledBigTitle>
      <div className="container-fluid p-0 mt-3">
        <StyledContentBox>
          <div className="row">
            {checkedTransactions.length > 0 ? (<Fragment>
              <div className="col-4 mt-2 d-flex align-items-center">
              <div className="p-2">
                <CheckBox
                  classSpan="top-0"
                  title={checkedTransactions.length === 1 ? `1 transaction is selected` : `${checkedTransactions.length} transactions are selected`}
                  value={0}
                  checked={true}
                  onChange={resetCheckedTransactions}
                />
              </div>
            </div>
              <div className="col-lg-2 mt-2 d-flex flex-row-reverse">
              <StyledButtonDeclineAll onClick={() => showModalConfirmMultiple(true)}>
              Decline All
              </StyledButtonDeclineAll>
              </div>
              <div className="col-lg-2 mt-2 d-flex flex-row-reverse">
              <StyledButtonApproveAll onClick={() => showModalConfirmMultiple()}>
              Approve All
              </StyledButtonApproveAll>
              </div>
            </Fragment>) : <div className="col-lg-8" />}
            <div className="col-lg-2 mt-2 d-flex flex-row-reverse">
              <StyledBNPInput
                placeholder="Search by name or sundry number"
                onChange={props.handleChangeInput}
                value={props.searchParam.searchKey || ""}
                onKeyUp={props.onKeyUp}
                tabIndex={4}
                className="w-90" />
              <StyledIconSearch onClick={props.searchBySundryNumber} />
            </div>

            <div className="col-lg-2 mt-2 d-flex flex-row">
              <BNPSelect
                options={[
                  { id: "Withdraw", name: "Withdraw" },
                  { id: "Deposit", name: "Deposit" },
                ]}
                placeholder="Filter by type"
                name="type"
                onChange={props.changeFilter}
                value={props.searchParam.type || -1} />

              <IconButton
                aria-controls="fade-menu"
                aria-haspopup="true"
                onClick={handleClick}>
                <MoreVertIcon style={{ color: '#003049' }} />
              </IconButton>
            </div>
            <div className="col-12 mt-3">
              <StyledHideForMobile className="custom-width set-scroll min-width-1500">
                <StyledTable>
                  <StyledHeaderTable>
                    <StyledCellTable width={3} />
                    {props.columns.map((column, index) => (
                      <StyledCellTable
                        width={column.width}
                        key={index}
                        className="cur"
                        onClick={() => props.sortTable(column.columnId)} >
                        {column.columnName}
                        <span>
                          {
                            column.sortType ?
                              column.sortType === "ASC" ? <StyledArrowDropDownIcon /> : <StyledArrowDropUpIcon /> :
                              <StyledImportExportIcon />
                          }
                        </span>
                      </StyledCellTable>
                    ))}
                    <StyledCellTable width={15}>ACTION</StyledCellTable>
                    <StyledCellTable width={5}>OTHER</StyledCellTable>
                  </StyledHeaderTable>
                  <div>
                    {props.transactionsFilter.map((transaction) => (
                      <StyledBodyTable key={`${transaction.transactionType}_${transaction.sundryNumber}`}>
                        <StyledCellTable
                          width={3}
                          title={
                            transaction.sundryNumber
                          }>
                          <CheckBox
                            value={transaction.sundryNumber}
                            checked={checkedCheckbox(transaction.sundryNumber)}
                            onChange={handleChangeCheckbox}
                          />
                        </StyledCellTable>
                        <StyledCellTable
                          width={15}
                          title={
                            transaction.firstName + " " + transaction.lastName
                          }>
                          {transaction.firstName + " " + transaction.lastName}
                        </StyledCellTable>
                        <StyledCellTable
                          width={10}
                          title={transaction.transactionType} >
                          {transaction.transactionType}
                        </StyledCellTable>
                        <StyledCellTable
                          width={10}
                          title={formatterUSD("currency").format(
                            centToUsd(Math.abs(transaction.amount))
                          )} >
                          {formatterUSD("currency").format(
                            centToUsd(Math.abs(transaction.amount))
                          )}
                        </StyledCellTable>
                        <StyledCellTable
                            width={12}
                            title={transaction.sundryNumber} >
                          {transaction.sundryNumber}
                        </StyledCellTable>
                        <StyledCellTable
                          width={12}
                          title={displayTopUpTransferType(transaction.transferType)} >
                          {displayTopUpTransferType(transaction.transferType)}
                        </StyledCellTable>

                        <StyledCellTable
                            width={12}
                            title={transaction.confirmImage} >
                          {
                            transaction.confirmImage && (
                                <StyledButtonApprove onClick={() => openTab(transaction.confirmImage || '')}>View</StyledButtonApprove>
                            )
                          }
                        </StyledCellTable>

                        <StyledCellTable
                          width={8}
                          title={
                            transaction.date
                              ? convertTimeZone("Canada/Eastern", transaction.date).format(FE_DATE_FORMAT)
                              : ""
                          }>
                          {transaction.date
                            ? convertTimeZone("Canada/Eastern", transaction.date).format(FE_DATE_FORMAT)
                            : ""}
                        </StyledCellTable>
                        <StyledCellTable width={15}>
                          <StyledButtonDecline
                            onClick={() => showModalConfirm(transaction, true)}>
                            Decline
                          </StyledButtonDecline>
                          <StyledButtonApprove
                            onClick={() => showModalConfirm(transaction)}>
                            Approve
                          </StyledButtonApprove>
                        </StyledCellTable>
                        <StyledCellTable
                          style={{ padding: "unset" }}
                          className="d-flex align-items-center justify-content-end"
                          width={5}>
                          {transaction.transferType !== 'ETRANSFER' && <IconButton
                            aria-controls="fade-menu"
                            aria-haspopup="true"
                            onClick={(event) => handleClick(event, true, transaction)}>
                            <MoreHorizIcon style={{ color: '#003049' }} />
                          </IconButton>}
                        </StyledCellTable>
                      </StyledBodyTable>
                    ))}
                  </div>

                  <div className="text-center mt-4">
                    <label>
                      <Pagination
                        pagerPagination={props.pagingInfo}
                        getPage={(page) => props.changePage(page)}
                        onChangeRowsPerPage={props.setRowsPerPage}
                      />
                    </label>
                  </div>
                </StyledTable>
              </StyledHideForMobile>
              <StyledHideForDesktop>
                {props.transactionsFilter.map((transaction, index) => (
                  <ExpansionPanel
                    square
                    defaultExpanded={false}
                    className="mt-2"
                    key={index} >
                    <StyledExpansionPanelSummary
                      className="m-0"
                      expandIcon={<ArrowDropUp />} >
                      <div className="w-100 ">
                        <StyledTitleExpansion>Name</StyledTitleExpansion>
                        <div className="mt-2">
                          {`${transaction.firstName} ${transaction.lastName}`}
                        </div>
                      </div>
                    </StyledExpansionPanelSummary>
                    <StyledExpansionPanelDetails>
                      <div className="w-100">
                        <StyledTitleExpansion>Type</StyledTitleExpansion>
                        <div className="mt-1">{transaction.transactionType}</div>
                      </div>
                      <div className="w-100 mt-3">
                        <StyledTitleExpansion>Amount</StyledTitleExpansion>
                        <div className="mt-1">
                          {formatterUSD("currency").format(centToUsd(Math.abs(transaction.amount)))}
                        </div>
                      </div>
                      <div className="w-100 mt-3">
                        <StyledTitleExpansion>
                          Sundry Number
                        </StyledTitleExpansion>
                        <div className="mt-1">{transaction.sundryNumber}</div>
                      </div>
                      <StyledCellTable
                          width={15}
                          title={displayTopUpTransferType(transaction.transferType)} >
                        {displayTopUpTransferType(transaction.transferType)}
                      </StyledCellTable>

                      <StyledCellTable
                          width={15}
                          title={transaction.confirmImage} >
                        {transaction.confirmImage}
                        {
                          transaction.confirmImage && (
                              <StyledButtonApprove onClick={() => openTab(transaction.confirmImage || '')}>View</StyledButtonApprove>
                          )
                        }
                      </StyledCellTable>
                      <div className="w-100 mt-3">
                        <StyledTitleExpansion>Date</StyledTitleExpansion>
                        <div className="mt-1">
                          {transaction.date
                            ? convertTimeZone("Canada/Eastern", transaction.date).format(FE_DATE_FORMAT)
                            : ""}
                        </div>
                      </div>
                      <hr />
                      <div className="text-center">
                        <StyledSpanExpansionEdit>
                          <StyledButtonDecline onClick={() => showModalConfirm(transaction, false, true)}>
                            Revert status
                          </StyledButtonDecline>
                        </StyledSpanExpansionEdit>
                        <StyledSpanExpansionEdit>
                          <StyledButtonDecline onClick={() => showModalConfirm(transaction, true)}>
                            Decline
                          </StyledButtonDecline>
                        </StyledSpanExpansionEdit>
                        <StyledSpanExpansionEdit>
                          <StyledButtonApprove onClick={() => showModalConfirm(transaction)}>
                            Approve
                          </StyledButtonApprove>
                        </StyledSpanExpansionEdit>
                      </div>
                    </StyledExpansionPanelDetails>
                  </ExpansionPanel>
                ))}
                <div className="row mt-4">
                  <div className="col-12 text-center">
                    <label>
                      <Pagination
                        pagerPagination={props.pagingInfo}
                        getPage={props.changePage}
                        onChangeRowsPerPage={props.setRowsPerPage}
                      />
                    </label>
                  </div>
                </div>
              </StyledHideForDesktop>
            </div>
          </div>
        </StyledContentBox>
      </div>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        style={{ marginTop: "50px" }}
        TransitionComponent={Fade}>
        {
          isRenew ?
              <MenuItem
                onClick={() => {
                  handleClose();
                  showModalConfirm(item, false, true);
                }}
                style={{ fontFamily: 'Graphik Light' }}>
                Revert status
              </MenuItem>
            :
            <div>
              <StyledLink to={requestDepositWithdrawTransactionURL}>
                <MenuItem style={{ fontFamily: 'Graphik Light' }}>
                  Request
                </MenuItem>
              </StyledLink>
              <StyledLink to={depositWithdrawTransactionHistoryURL}>
                <MenuItem style={{ fontFamily: 'Graphik Light' }}>
                  History
                </MenuItem>
              </StyledLink>
            </div>
        }
      </Menu>
      <ConfirmDialog
        title={ConfirmModalContent[confirmModalType()].title}
        content={ConfirmModalContent[confirmModalType()].content}
        handleOnOk={ConfirmModalContent[confirmModalType()].handleOnOk} />
      {/* <AdviceModal data={data} /> */}
    </StyledMainContent>
  );
}
import React from "react";
import styled, { css } from "styled-components";
import {
  StyledHeaderModal,
  StyledHeaderTitleModal,
  StyledButtonCloseModal,
  StyledIconCloseModal,
  StyledButtonSubmit,
  StyledPTitle, StyledDivDatePicker,
} from "../../../shared/styled";
import { BankingInformation, Agreement } from "../company-management-type";
import { payments } from "../company-management-model";
import ImageUpload from "../../../../cores/helpers/upload-image/image-upload";
import { BNPInput } from "../../../../cores/helpers/input/input";
import { CheckBox } from "../../../../cores/helpers/checkbox/checkbox";
import Cheque from "../../../../cores/config/component/images/cheque.jpg";
import { showTermsAndConditionsModal } from "../../../../cores/helpers/terms-and-conditions/terms-and-conditions";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {convertTimeZone} from "../../../../cores/utils/helpers";

const StyledModalDialog = styled.div`
  max-width: 1000px;
  margin: 1.75rem auto;
  position: relative;
  width: auto;
  pointer-events: none;
`;

const StyledModalBody = styled.div`
  min-height: 300px;
`;

const StyledMenuItem = styled.div`
  border: 1px solid #f2f2f2 !important;
  border-radius: 5px !important;
  :hover {
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.08);
  }
  padding-left: 16px;
  padding-right: 16px;
  width: auto;
  overflow: hidden;
  font-size: 1rem;
  box-sizing: border-box;
  min-height: 48px;
  font-weight: 400;
  line-height: 1.75;
  padding-top: 4px;
  white-space: nowrap;
  letter-spacing: 0.00938em;
  padding-bottom: 4px;

  ${(props: { active: boolean }) =>
    props.active &&
    css`
      border-color: ${(props) => props.theme.primaryColor + "!important"};
    `}
`;
const StyledDivBoxCheque = styled.div`
  background-color: #f4f5f9;
  padding: 21px 17px;
  border-radius: 5px;
  margin-top: 1.5rem;
`;

const StyledImgCheque = styled.img`
  max-height: 100%;
  max-width: 100%;
`;

const StyledSpan = styled.span`
  font-size: 12px !important;
`;

type Props = {
  paymentMethod: number;
  bankingInfoEdit: BankingInformation;
  handleChangePaymentMethod: (id: number) => void;
  handleChangeInputChequeInfo: (event: any) => void;
  handleChangeVoidCheque: (urlImage: string) => void;
  handleOCRCheque: (urlBlob: string) => void;
  handleChangeDate: (date: Date | null, name: string) => void;
  validateBankingInfo: () => boolean;
  handleSubmitBankingInfo: () => void;
  handleChangeCheckbox: (event: any) => void;
  agreementData: Agreement[];
  fileUrlEdit: string;
  enablePresetBankingInformation: boolean;
};

export default function UpdateBankingInformationModal(props: Props) {
  const findChecked = (agreementId: number) => {
    let isAgree = props.bankingInfoEdit.agreements.some((agree) => {
      return agree === agreementId;
    });

    return isAgree;
  };

  React.useEffect(() => {
    let $termsAndConditions = document.getElementById("termsAndConditions");

    if ($termsAndConditions) {
      $termsAndConditions.removeEventListener(
        "click",
        showTermsAndConditionsModal
      );
      $termsAndConditions.addEventListener(
        "click",
        showTermsAndConditionsModal
      );
    }
  });

  let isValid = props.validateBankingInfo();

  if (props.enablePresetBankingInformation) {
    // Do not show anything about banking information when enable pre-set banking information
    return <></>
  }

  return (
    <div className="modal" id="updateBankingInformationModal">
      <StyledModalDialog role="document">
        <div className="modal-content">
          <StyledHeaderModal className="modal-header">
            <StyledHeaderTitleModal>Banking Information</StyledHeaderTitleModal>
            <StyledButtonCloseModal
              type="button"
              onClick={closeUpdateBankingInformationModal}
            >
              <StyledIconCloseModal>&times;</StyledIconCloseModal>
            </StyledButtonCloseModal>
          </StyledHeaderModal>
          <StyledModalBody className="modal-body">
            <div className="p-2">
              <div className="row">
                <div className="col-12">
                  <div>How do you want to process payment?</div>

                  <div className="row">
                    {payments.map((payment, index) => (
                      <div className="col-lg-4 mt-3" key={`payment-information-${payment.id}`}>
                        <StyledMenuItem
                          key={index}
                          active={
                            props.paymentMethod === payment.id ? true : false
                          }
                          onClick={() =>
                            payment.id === 2 &&
                            props.handleChangePaymentMethod(payment.id)
                          }
                        >
                          <div
                            className={
                              "p-2" + (payment.id !== 2 ? " op-0-5" : "")
                            }
                          >
                            {payment.name}
                          </div>
                        </StyledMenuItem>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {props.paymentMethod === 2 && (
                <StyledDivBoxCheque>
                  <div className="row">
                    <div className="col-lg-5">
                      <div className="col-lg-12 mt-4">
                        <StyledPTitle className="mb-2">
                          Void Cheque
                        </StyledPTitle>
                        <div className="mt-2">
                          <ImageUpload
                            addFile={props.handleChangeVoidCheque}
                            addBlob={props.handleOCRCheque}
                            urlImage={props.fileUrlEdit}
                            folderName="Employer/Cheque"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7">
                      <div className="row">
                        <div className="col-lg-6 mt-4">
                          <StyledPTitle className="mb-2">
                            Account Number
                          </StyledPTitle>
                          <BNPInput
                            maxLength={12}
                            name="accountNumber"
                            className="mt-2"
                            value={props.bankingInfoEdit.accountNumber}
                            onChange={props.handleChangeInputChequeInfo}
                            tabIndex={1}
                          />
                        </div>
                        <div className="col-lg-6 mt-4">
                          <StyledPTitle className="mb-2">
                            Transit Number
                          </StyledPTitle>

                          <BNPInput
                            maxLength={5}
                            className="mt-2"
                            value={props.bankingInfoEdit.transitNumber}
                            onChange={props.handleChangeInputChequeInfo}
                            name="transitNumber"
                            tabIndex={2}
                          />
                        </div>
                        <div className="col-lg-6 mt-4">
                          <StyledPTitle className="mb-2">
                            Institution Number
                          </StyledPTitle>
                          <BNPInput
                            maxLength={3}
                            name="bankNumber"
                            className="mt-2"
                            value={props.bankingInfoEdit.bankNumber}
                            onChange={props.handleChangeInputChequeInfo}
                            tabIndex={3}
                          />
                        </div>
                        <div className="col-lg-6 mt-4">
                          <StyledPTitle className="mb-2">
                            E-signature{" "}
                            <StyledSpan>
                              (Please type name to accept)
                            </StyledSpan>
                          </StyledPTitle>
                          <BNPInput
                            name="eSignature"
                            className="mt-2"
                            value={props.bankingInfoEdit.eSignature || ""}
                            onChange={props.handleChangeInputChequeInfo}
                            tabIndex={4}
                          />
                        </div>

                        <div className="col-lg-6 col-6 mt-4">
                          <StyledPTitle className="mb-2">Date</StyledPTitle>
                          <StyledDivDatePicker>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                autoOk
                                disableFuture
                                InputAdornmentProps={{ position: "start" }}
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="date-picker-inline1"
                                placeholder="Date"
                                name="date"
                                value={props.bankingInfoEdit.date ?
                                  convertTimeZone("Canada/Eastern", props.bankingInfoEdit.date)
                                  : ''
                                }
                                onChange={(date) =>
                                  props.handleChangeDate(date, "eSignatureDate")
                                }
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                tabIndex={24}
                                role="application"
                                aria-hidden="true"
                              />
                            </MuiPickersUtilsProvider>
                          </StyledDivDatePicker>
                        </div>

                        <div className="col-12 mt-3 text-center">
                          <label>
                            <StyledImgCheque src={Cheque} alt="cheque" />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {props.agreementData.map((agreement, index) => (
                    <div className="col-lg-12 mt-4">
                      <CheckBox
                        key={index}
                        title={
                          <div
                            className="text-left"
                            dangerouslySetInnerHTML={{
                              __html: agreement.title,
                            }}
                          />
                        }
                        checked={findChecked(agreement.id)}
                        name={agreement.id.toString()}
                        onChange={props.handleChangeCheckbox}
                      />
                    </div>
                  ))}
                </StyledDivBoxCheque>
              )}
            </div>
          </StyledModalBody>
          <div className="modal-footer">
            <div className="col-md-4 p-0">
              <StyledButtonSubmit
                disabled={!isValid}
                onClick={props.handleSubmitBankingInfo}
                submit={isValid}
                type="submit"
              >
                Save & Update
              </StyledButtonSubmit>
            </div>
          </div>
        </div>
      </StyledModalDialog>
    </div>
  );
}

export function showUpdateBankingInformationModal() {
  ($("#updateBankingInformationModal") as any).modal({
    show: true,
    keyboard: false,
    backdrop: "static",
  });
}
export function closeUpdateBankingInformationModal() {
  ($("#updateBankingInformationModal") as any).modal("hide");
}

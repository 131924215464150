import React from "react";
import {
  StyledMainContent,
  StyledBigTitle,
  StyledContentBox,
} from "../../shared/styled";
import styled from "styled-components";
import { BNPInput } from "../../../cores/helpers/input/input";

const StyledDiv = styled.div`
  font-size: 16px;
`;

//const StyledBox = styled(StyledContentBox)``;

export default function Contact() {
  React.useEffect(() => {
    document.body.classList.add("contact");

    return () => {
      document.body.classList.remove("contact");
    };
  }, []);

  return (
    <StyledMainContent>
      <StyledBigTitle>Contact</StyledBigTitle>
      <StyledContentBox className="mt-4">
        <div className="row">
          <div className="col-lg-6">
            <StyledDiv>
              Are you having issues with your{" "}
              <strong>BeniPlus Benefit Wallet</strong>? Would you like to speak
              with one of our experts to get assistance?
            </StyledDiv>
            <StyledDiv className="mt-2">
              We have a number of ways you can contact us. You can submit your
              details in our online form, you can use our online chat, you can
              email us at <strong>support@beniplus.ca</strong> or you can phone
              us at <strong>1-888-859-3579</strong> during regular office hours.{" "}
            </StyledDiv>
          </div>

          <div className="col-lg-6">
            {/* <div className="row">
              <div className="col-12">
                <StyledSpanTitle>Company name</StyledSpanTitle>
                <BNPInput />
              </div>
            </div> */}
          </div>
        </div>
      </StyledContentBox>
    </StyledMainContent>
  );
}

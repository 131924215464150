import React, { useEffect, useState } from "react";
import { addLoading, removeLoading } from "../../../../cores/utils/loading";
import moment from "moment";
import { StyledButtonSubmit, StyledDivTitle } from "../../../shared/styled";
import BNPDatePicker from "../../../../cores/helpers/date-picker/date-picker";
import BNPRawTable from "../../../shared/BNPTable/BNPRawTable";
import { FinancialDetailReportColumn } from "../DashboardModel";
import {
  getEndDateOfMonth,
  getStartDateOfMonth,
} from "../../../../cores/utils/helpers";
import { formatterUSD } from "../../../../cores/helpers/format-usd";
import { centToUsd } from "../../../../cores/helpers/cent-to-usd";
import { AxiosResponse } from "axios";
import DownloadIcon from "@material-ui/icons/GetApp";

type Props = {
  getWalletDetailReport: (fromDate: (Date | string), toDate: (Date | string)) => Promise<AxiosResponse>;
  getFinancialDetailReport: (fromDate: (Date | string), toDate: (Date | string)) => Promise<AxiosResponse>;
  exportFinancialDetailReport: (fromDate: (Date | string), toDate: (Date | string)) => void;
};

export function FinancialDetailReport(props: Props) {
  const [data, setData] = useState<any[]>([]);
  const [fromDate, setFromDate] = useState<Date>(
    getStartDateOfMonth(new Date())
  );
  const [toDate, setToDate] = useState<Date>(getEndDateOfMonth(new Date()));

  useEffect(() => {
    fetchData().then();
  }, [fromDate, toDate]);

  async function fetchData() {
    addLoading();
    const [walletResult, financialResult] = await Promise.all([
      props.getWalletDetailReport(fromDate, toDate),
      props.getFinancialDetailReport(fromDate, toDate),
    ]);
    setData(processData(walletResult.data, financialResult.data));
    removeLoading();
  }

  function processData(walletData: any[], financialData: any[]) {
    return financialData.map((item) => {
      const { month, claims, taxes, adminFees } = item;
      const { totalSize, averageSize } = walletData.find(
        (walletItem) => walletItem.month === item.month
      );
      return {
        month: moment(month).format("YYYY-MM"),
        totalWalletSize: formatterUSD("currency", "USD").format(
          centToUsd(Number(totalSize))
        ),
        averageWalletSize: formatterUSD("currency", "USD").format(
          centToUsd(Number(averageSize))
        ),
        claims: formatterUSD("currency", "USD").format(
          centToUsd(Number(claims))
        ),
        taxes: formatterUSD("currency", "USD").format(centToUsd(Number(taxes))),
        adminFees: formatterUSD("currency", "USD").format(
          centToUsd(Number(adminFees))
        ),
      };
    });
  }

  function handleChangeDate(date: Date | null, name: string) {
    if (name === "from" && date) {
      setFromDate(getStartDateOfMonth(date));
    }
    if (name === "to" && date) {
      setToDate(getEndDateOfMonth(date));
    }
  }

  async function handleExportExcel() {
    addLoading();
    props.exportFinancialDetailReport(fromDate, toDate);
    removeLoading();
  }

  return (
    <>
      <div className="col-12">
        <StyledDivTitle>Time Period</StyledDivTitle>
      </div>
      <div className="col-lg-3 col-md-4 col-12">
        <BNPDatePicker
          views={["month"]}
          format="MM/yyyy"
          value={fromDate}
          onChange={(date) => handleChangeDate(date, "from")}
          name="date"
          disableFuture={true}
        />
      </div>
      <div className="col-lg-3 col-md-4 col-12">
        <BNPDatePicker
          views={["month"]}
          format="MM/yyyy"
          value={toDate}
          onChange={(date) => handleChangeDate(date, "to")}
          name="date"
          minDate={fromDate}
        />
      </div>
      <div className="col-lg-4 col-12" />
      <div className="col-lg-2 col-12">
        <StyledDivTitle style={{height: "26px"}}></StyledDivTitle>
        <StyledButtonSubmit
          submit={true}
          onClick={handleExportExcel}
        >
          <DownloadIcon className="icon" style={{marginRight: "5px"}}/>
          Export To Excel
        </StyledButtonSubmit>
      </div>
      <div className="col-lg-12 pt-3">
        <BNPRawTable
          columns={FinancialDetailReportColumn}
          tableWidth="100%"
          tableData={data}
        />
      </div>
    </>
  );
}

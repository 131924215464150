import React, { useEffect, useState } from "react"
import service from "./email-history-service";
import { Criteria, criterialDefault, EmailHistoryRes, userType } from "./email-history-model";
import {
	StyledMainContent,
	StyledBigTitle,
	StyledContentBox,
	StyledHideForDesktop,
	StyledExpansionPanelSummary,
	StyledTitleExpansion,
	StyledExpansionPanelDetails,
	StyledHideForMobile,
	StyledSpanExpansionEdit,
	StyledExpansionLabel,
	StyledHeaderModal,
	StyledHeaderTitleModal,
	StyledButtonCloseModal,
	StyledIconCloseModal,
} from "../../shared/styled";
import { BNPInput } from "../../../cores/helpers/input/input"
import {
	StyledTable,
	StyledHeaderTable,
	StyledCellTable,
	StyledArrowDropDownIcon,
	StyledArrowDropUpIcon,
	StyledImportExportIcon,
	StyledLinkBodyTable,
} from "../../shared/table-styled";
import styled from "styled-components";
import {
	Menu,
	IconButton,
	MenuItem,
	Fade,
	ExpansionPanel,
	Modal,
} from "@material-ui/core";
import Pagination, {
	PagingInfo,
	setPagingInfo
} from "../../../cores/helpers/pagination/pagination"
import SearchIcon from "@material-ui/icons/Search"
import SendIcon from "@material-ui/icons/Send"
import ArrowDropUp from "@material-ui/icons/ArrowDropUp"
import { SortTable } from "../../../cores/models/SortTable"
import { validateEmail } from "../../../cores/config/config";
import { publishMessage } from "../../../cores/utils/message"
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import "./email-history.css"
import { convertTimeZone } from "../../../cores/utils/helpers";
import {FE_DATE_FORMAT} from "../../../cores/utils/format/date-time-format";
import { addLoading, removeLoading } from "../../../cores/utils/loading";
import BNPDatePicker from "../../../cores/helpers/date-picker/date-picker";
import { BNPSelect } from "../../../cores/helpers/select/select";
import { message } from "../../../cores/helpers/message/message";

const StyledIconSearch = styled(SearchIcon)`
  position: absolute;
  top: 13px;
  right: 14px;
  opacity: 0.7;
`

const StyledIconSend = styled(SendIcon)`
  position: absolute;
  cursor: pointer;
  top: 13px;
  right: 14px;
  opacity: 0.8;
  fill: ${props => props.color === "action" && props.theme.primaryColor}!important;
  :hover {
	  ${props => props.color === "action" && "opacity: 0.6"}
  }
  
`;

const StyledBNPInput = styled(BNPInput)`
  padding-right: 35px;
`

const StyledButtonApprove = styled.button`
  width: 90px;
  height: 25px;
  position: relative;
  background-color: ${(props) => props.theme.primaryColor};
  color: #ffffff;
  border: 1px solid #ecedf0;
  border-radius: 0.25rem;
  display: inline-block;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  padding: 0;
  font-size: 14px;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  :hover {
    opacity: 0.8;
  }
`

const StyledButtonOutlined = styled.button`
  width: fit-content;
  height: 25px;
  position: relative;
  background-color: #ffffff;
  color: ${(props) => props.theme.primaryColor};
  border: 1px solid ${(props) => props.theme.primaryColor};
  border-radius: 0.25rem;
  display: inline-block;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  padding: 0 4px;
  font-size: 14px;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  :hover {
    opacity: 0.8;
  }
`;

const StyledModalDialog = styled.div`
  max-width: 400px;
  margin: 3rem auto;
  position: relative;
  width: auto;
  pointer-events: none;
`;

const columns: SortTable[] = [
	{
		columnId: "fullName",
		columnName: "Full Name",
		width: 20,
		sortType: null
	},
	{
		columnId: "emailTo",
		columnName: "Email",
		width: 20,
		sortType: null
	},
	{
		columnId: "type",
		columnName: "Type",
		width: 10,
		sortType: null
	},
	{
		columnId: "title",
		columnName: "Title",
		width: 20,
		sortType: null
	},
	{
		columnId: "createdDate",
		columnName: "Date",
		width: 20,
		sortType: null
	}
]

export default function EmailHistory() {
	const [data, setData] = useState<EmailHistoryRes[]>([])
	const [criteria, setCriteria] = useState<Criteria>(criterialDefault)
	const [paging, setPaging] = useState<PagingInfo>({
		currentPage: 1,
		totalPages: 1,
		startPage: 1,
		endPage: 1,
		pages: [1],
		rowsPerPage: 10
	})
	const [id, setId] = useState<number>(0)

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
	const [resendToAdmin, setResendToAdmin] = React.useState<boolean>(false)
	const open = Boolean(anchorEl)

	useEffect(() => {
		search()
	}, [criteria])

	const search = async () => {
		addLoading();
		await service.searchEmailHistory(criteria).then(res => {
			if (res.status === 200) {
				setData(res.data.records)
				setPaging(setPagingInfo(
					criteria.page,
					res.data.pageCount,
					criteria.perPage
				))
			}
		});
		removeLoading();
	}

	const handleChangeInputSearch = (event: any) => {
		const { value } = event.target;

		const temp = { ...criteria };
		temp.keyword = value;

		setCriteria(temp);
	}

	const handleChangeSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		const temp = { ...criteria };

		if (Number(value) !== -1) temp.filter = value;
		else temp.filter = null;

		setCriteria(temp)
	};

	const handleChangeDate = (date: Date | null, name: string) => {
		const temp = {...criteria};

		if (name === "from") {
			if (temp.to && date) {
					let from = new Date(date),
						to = new Date(temp.to)
				if (from > to) {
					message("The from date cannot be after the to date.", "error");
					return;
				}
			}
			temp.from = date;
		} else {
			if (temp.from && date) {
			let from = new Date(temp.from),
				to = new Date(date);
			if (from > to) {
				message("The from date cannot be after the to date.", "error");
				return;
			}
			}
			temp.to = date;
		}

		setCriteria(temp);
	};

	const onKeyUp = (event: any) => {
		if (event.keyCode === 13) search();
	};

	const sortTable = (columnId: string) => {
		let temp = { ...criteria };

		let index = temp.columnName.findIndex(i => i === columnId)
		if (index === -1) {
			temp.columnName.push(columnId)
			temp.sortType.push("ASC")
		} else if (temp.sortType[index] === "ASC") {
			temp.sortType[index] = "DESC"
		} else {
			temp.columnName.splice(index, 1)
			temp.sortType.splice(index, 1)
		}

		setCriteria(temp)
	};

	const handleChangePage = (page: number) => {
		let temp = { ...criteria }
		temp.page = page

		setCriteria(temp)
	};

	const setRowsPerPage = (event: any) => {
		const { value } = event.target;

		let temp = { ...criteria }
		temp.page = 1
		temp.perPage = Number(value)

		setCriteria(temp)
	};

	const readTableHeader = (columnId: string): string | null => {
		const index = criteria.columnName.findIndex(i => i === columnId)

		if (index === -1) return null

		return criteria.sortType[index]
	}

	const handleClick = (event: React.MouseEvent<HTMLElement>, id: number) => {
		if (id) setId(id)
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleResend = async (id: number) => {
		const res = await service.resend(id)
		if (res.status === 204)
			publishMessage({
				message: "Resend email successfully.",
				variant: "success"
			});
	}

	const handleSendToAdminEmail =async (email: string) => {
		const res = await service.sendToAdminEmail(id, email);
		if (res.status === 204)
			setResendToAdmin(false);
			publishMessage({
				message: "Resend email successfully.",
				variant: "success"
			});
	}

	return <>
		<StyledMainContent>
			<StyledBigTitle>Email History</StyledBigTitle>
			<div className="container-fluid p-0 mt-3">
				<StyledContentBox>
					<div className="row">
						<div className="col-lg-12 mt-4">
							<div className="row">
								<div className="col-lg-2 mt-4">
									<StyledBNPInput
										placeholder="Search by name, email or title"
										onChange={handleChangeInputSearch}
										value={criteria.keyword}
										onKeyUp={onKeyUp}
										tabIndex={1}
									/>
									<StyledIconSearch onClick={search} />
								</div>
								<div className="col-lg-2 mt-4">
									<BNPDatePicker
										onChange={handleChangeDate}
										value={criteria.from ?
											convertTimeZone("Canada/Eastern", criteria.from).toDate()
											:
											""
										}
										placeholder="From date"
										name="from"
										tabIndex={2}
									/>
								</div>
								<div className="col-lg-2 mt-4">
									<BNPDatePicker
										onChange={handleChangeDate}
										value={criteria.to ?
											convertTimeZone("Canada/Eastern", criteria.to).toDate()
											:
											""
										}
										placeholder="To date"
										name="to"
										tabIndex={3}
									/>
								</div>
								<div className="col-lg-2 mt-4">
									<BNPSelect
										options={userType}
										value={criteria.filter}
										name="type"
										placeholder="Filter by Type"
										onChange={handleChangeSelect}
										tabIndex={4}
									/>
								</div>
							</div>
						</div>

						<div className="col-lg-12 mt-4">
							<StyledHideForMobile>
								<StyledTable>
									<StyledHeaderTable>
										{
											columns.map((column, index) => {
												let col = readTableHeader(column.columnId)
												return (
													<StyledCellTable
														width={column.width}
														key={index}
														className="cur"
														onClick={() => sortTable(column.columnId)} >
														{column.columnName}
														<span>
															{
																col ?
																	col === "ASC" ?
																		<StyledArrowDropDownIcon /> : <StyledArrowDropUpIcon />
																	: <StyledImportExportIcon />
															}
														</span>
													</StyledCellTable>
												)
											})
										}
										<StyledCellTable width={10}>
										</StyledCellTable>
									</StyledHeaderTable>
									<div>
										{data.map((i, index) => (
											<StyledLinkBodyTable
												to=""
												onClick={(e) => e.preventDefault()}
												key={index} >
												<StyledCellTable width={20}>
													{i.fullName}
												</StyledCellTable>
												<StyledCellTable width={20}>
													{i.emailTo}
												</StyledCellTable>
												<StyledCellTable width={10}>
													{i.type}
												</StyledCellTable>
												<StyledCellTable width={20}>
													{i.title}
												</StyledCellTable>
												<StyledCellTable width={20}>
													{convertTimeZone("Canada/Eastern", i.createdDate).format(FE_DATE_FORMAT)}
												</StyledCellTable>
												<StyledCellTable width={10}>
													<IconButton
														aria-controls="fade-menu"
														aria-haspopup="true"
														id="email-history-menu-icon"
														onClick={(event) => handleClick(event, i.id)}>
														<MoreHorizIcon style={{ color: '#003049' }} />
													</IconButton>
												</StyledCellTable>
											</StyledLinkBodyTable>
										))}
									</div>
									<div className="row mt-4">
										<div className="col-12 text-center">
											<label>
												<Pagination
													pagerPagination={paging}
													getPage={handleChangePage}
													onChangeRowsPerPage={setRowsPerPage}
												/>
											</label>
										</div>
									</div>
								</StyledTable>
							</StyledHideForMobile>
						</div>

						<div className="col-lg-12 mt-4">
							<StyledHideForDesktop>
								{data.map((i, index) => (
									<ExpansionPanel
										square
										defaultExpanded={false}
										className="mt-2"
										key={index} >
										<StyledExpansionPanelSummary
											className="m-0"
											aria-controls="panel1d-content"
											id="panel1d-header"
											expandIcon={<ArrowDropUp />}>
											<div className="w-100 ">
												<StyledTitleExpansion>Full Name</StyledTitleExpansion>
												<div className="mt-2">{i.fullName}</div>
											</div>
										</StyledExpansionPanelSummary>
										<StyledExpansionPanelDetails>
											<div className="w-100">
												<StyledTitleExpansion>Email</StyledTitleExpansion>
												<div className="mt-1">{i.emailTo}</div>
											</div>
											<div className="w-100 mt-3">
												<StyledTitleExpansion>Type</StyledTitleExpansion>
												<div className="mt-1">
													{i.type}
												</div>
											</div>
											<div className="w-100 mt-3">
												<StyledTitleExpansion>Title</StyledTitleExpansion>
												<div className="mt-1">
													{i.title}
												</div>
											</div>
											<div className="w-100 mt-3">
												<StyledTitleExpansion>Date</StyledTitleExpansion>
												<div className="mt-1">
													{convertTimeZone("Canada/Eastern", i.createdDate).format(FE_DATE_FORMAT)}
												</div>
											</div>
											<hr />
											<div className="text-center">
												<StyledSpanExpansionEdit>
													<StyledExpansionLabel>
														<StyledButtonApprove onClick={() => handleResend(i.id)}>
															Resend
                          								</StyledButtonApprove>
													</StyledExpansionLabel>
												</StyledSpanExpansionEdit>
											</div>
											<div className="text-center">
												<StyledSpanExpansionEdit>
													<StyledExpansionLabel>
														<StyledButtonOutlined onClick={() => (setResendToAdmin(true), setId(i.id))}>
															Send to my email
														</StyledButtonOutlined>
													</StyledExpansionLabel>
												</StyledSpanExpansionEdit>
											</div>
										</StyledExpansionPanelDetails>
									</ExpansionPanel>
								))}
								<div className="row mt-4">
									<div className="col-12 text-center">
										<label>
											<Pagination
												pagerPagination={paging}
												getPage={handleChangePage}
												onChangeRowsPerPage={setRowsPerPage}
											/>
										</label>
									</div>
								</div>
							</StyledHideForDesktop>
						</div>
					</div>
				</StyledContentBox>
			</div>
		</StyledMainContent>
		<Menu
			id="fade-menu"
			anchorEl={anchorEl}
			keepMounted
			open={open}
			onClose={handleClose}
			style={{ marginTop: "50px" }}
			TransitionComponent={Fade}>
			<MenuItem
				onClick={() => {
					handleClose()
					handleResend(id)
				}}
				style={{ fontFamily: 'Graphik Light' }}>
				Resend
			</MenuItem>
			<MenuItem
			onClick={() => {
				handleClose();
				setResendToAdmin(true);
			}}
			style={{ fontFamily: 'Graphik Light' }}>
			Send to my email
			</MenuItem>
		</Menu>
		<EmailInput
			open={resendToAdmin}
			setOpen={setResendToAdmin}
			handleSendToAdminEmail={handleSendToAdminEmail}
		 />
	</>
}

const EmailInput = (props: any) => {
	const [inputEmail, setInputEmail] = React.useState<string>("");
	const [isValid, setValid] = React.useState<boolean>(false);

	const handleChangeInput = (e: any) => {
		e.stopPropagation();
		checkValidEmail(e.target.value);
		setInputEmail(e.target.value);
	}

	const checkValidEmail = (email: string) => {
		if (email.match(validateEmail)) {
			setValid(true)
		} else {
			setValid(false)
		}
	}

	return (
	  <Modal
		open={props.open}
	  >
		<StyledModalDialog>
		  <div className="modal-content">
			{/* header */}
			<StyledHeaderModal className="modal-header">
			  <StyledHeaderTitleModal className="text-left">
				Input Email
			  </StyledHeaderTitleModal>
			  <StyledButtonCloseModal
				name="btn-close"
				onClick={() => {
					setInputEmail("");
					props.setOpen(false);
				}}
			  >
				<StyledIconCloseModal>&times;</StyledIconCloseModal>
			  </StyledButtonCloseModal>
			</StyledHeaderModal>
			{/* end header */}
			{/* body */}
			<div className="modal-body text-left">
			  <div className="row">
				  <div className="col-12">
					<BNPInput
						placeholder="Email"
						onChange={handleChangeInput}
						value={inputEmail}
						tabIndex={1}
					/>
					<StyledIconSend 
						color={!isValid ? "disabled" : "action"}
						onClick={() => {
							isValid && props.handleSendToAdminEmail(inputEmail)
						}}
					/>
				  </div>
			  </div>
			</div>
			{/* end body */}
		  </div>
		</StyledModalDialog>
	  </Modal>
)}